import { Box, Typography } from '@mui/material';
import React, { useState, useRef, useEffect } from 'react';
import { theme, useLoadedDepartmentInfoContext, RankBadge, useFlipAnimation, DragIcon } from '@stationwise/component-module';
import { HiringEngineSettingsInfo, Rank } from '@stationwise/share-types';

interface HiringEngineVacancyFillOrdersProps {
  hiringEngineSettingsInfo: HiringEngineSettingsInfo;
  setHiringEngineSettingsInfo: React.Dispatch<React.SetStateAction<HiringEngineSettingsInfo | null>>;
  clickable: boolean;
  selectedVacancyRank?: Rank | null;
  setSelectedVacancyRank?: (rank: Rank) => void;
  dragIndicator?: boolean;
  width?: string;
}

export const HiringEngineVacancyFillOrders: React.FC<HiringEngineVacancyFillOrdersProps> = ({
  clickable,
  selectedVacancyRank,
  setSelectedVacancyRank,
  hiringEngineSettingsInfo,
  setHiringEngineSettingsInfo,
  dragIndicator = false,
  width,
}) => {
  const [orders, setOrders] = useState<number[]>(hiringEngineSettingsInfo.autoHireDefaultRankProcessOrder);
  const {
    state: { departmentInfo },
  } = useLoadedDepartmentInfoContext();
  const dragItem = useRef<number | null>(null);
  const dragOverItem = useRef<number | null>(null);
  const [clickedRankId, setClickedRankId] = useState<number>(orders[0]);

  // Destructure both the flipRef callback and the updatePositions callback from the hook.
  const { flipRef, updatePositions } = useFlipAnimation(orders, 300, 'ease');

  const onClickRank = (rank: Rank) => {
    setClickedRankId(rank.id);
    setSelectedVacancyRank?.(rank);
  };

  const handleSort = (dragItem: React.MutableRefObject<number | null>, dragOverItem: React.MutableRefObject<number | null>) => {
    if (dragItem.current !== null && dragOverItem.current !== null && hiringEngineSettingsInfo) {
      const updatedOrders = [...hiringEngineSettingsInfo.autoHireDefaultRankProcessOrder];
      const draggedItemContent = updatedOrders.splice(dragItem.current, 1)[0];
      updatedOrders.splice(dragOverItem.current, 0, draggedItemContent);
      dragItem.current = null;
      dragOverItem.current = null;
      setOrders(updatedOrders);
      setHiringEngineSettingsInfo({
        ...hiringEngineSettingsInfo,
        autoHireDefaultRankProcessOrder: updatedOrders,
      });
    }
  };

  useEffect(() => {
    setClickedRankId(selectedVacancyRank?.id || orders[0]);
  }, [selectedVacancyRank, orders]);

  return (
    <Box sx={{ flex: 1, overflow: 'auto', pr: theme.spacing(1) }}>
      <Box sx={{ display: 'flex', alignItems: 'center', paddingY: theme.spacing(2) }}>
        <Typography
          sx={{
            width: '20px',
            textAlign: 'center',
            color: theme.palette.gray[500],
          }}
        >
          #
        </Typography>
        <Typography
          sx={{
            width: '20px',
            textAlign: 'center',
            mr: theme.spacing(1),
            ml: theme.spacing(2),
          }}
        >
          Rank
        </Typography>
      </Box>
      {orders.map((rankId, index) => {
        const rank = departmentInfo.ranks.find((r) => r.id === rankId);
        if (!rank) return null;
        return (
          <Box
            key={rankId}
            ref={flipRef(rankId)}
            display="flex"
            flexDirection="row"
            alignItems="center"
            sx={{
              willChange: 'transform',
            }}
          >
            <Typography sx={{ width: '20px', textAlign: 'center', mr: theme.spacing(1) }}>{index + 1}</Typography>
            <Box
              draggable
              onDragStart={() => {
                // Update positions to reflect the current layout before any drag-drop change.
                updatePositions();
                dragItem.current = index;
              }}
              onDragEnter={() => (dragOverItem.current = index)}
              onDragEnd={() => handleSort(dragItem, dragOverItem)}
              onDragOver={(e) => e.preventDefault()}
              onClick={() => onClickRank(rank)}
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                backgroundColor: clickedRankId === rank.id ? theme.palette.blue[200] : theme.palette.common.white,
                paddingY: theme.spacing(2),
                paddingX: theme.spacing(2),
                border: '1px solid',
                borderColor: clickedRankId === rank.id ? theme.palette.blue[500] : theme.palette.gray[200],
                transition: 'background-color 0.1s ease-in-out',
                '&:hover': {
                  backgroundColor: theme.palette.gray[300],
                  cursor: clickable ? 'pointer' : 'default',
                },
                width: width || '417px',
              }}
            >
              <Box
                sx={{
                  ml: theme.spacing(1),
                  display: 'flex',
                  alignItems: 'center',
                  gap: theme.spacing(3),
                }}
              >
                <RankBadge rank={rank} /> {rank.name}
                <Typography sx={{ flex: 1, ml: theme.spacing(1.5) }} />
              </Box>
              <DragIcon dragIndicator={dragIndicator} />
            </Box>
          </Box>
        );
      })}
    </Box>
  );
};
