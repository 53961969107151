import { useEffect } from 'react';
import { useShiftPlanContext } from '../ShiftPlanContext';

export const ShiftPlanCloseDialogResetContextEffect = () => {
  const { setSavingAction } = useShiftPlanContext();

  useEffect(() => {
    // Reset these values after the parent dialog is animating away.
    return () => {
      setSavingAction(null);
    };
  }, [setSavingAction]);

  return null;
};
