import { RosterDataSource, ShiftPlanStationAPIData, ShiftPlanStation, ShiftPlanStruct } from '@stationwise/share-types';

export const initializeStations = (stations: ShiftPlanStationAPIData[]) => {
  const map = new Map<string, ShiftPlanStation>();
  stations.forEach((station) => {
    map.set(`${station.stationId}`, {
      ...station,
      stationId: `${station.stationId}`,
      dataSource: RosterDataSource.STATION,
      apparatuses: station.apparatuses.map((apparatus) => ({
        ...apparatus,
        id: `${apparatus.id}`,
        dataSource: RosterDataSource.STATION,
        positions: apparatus.positions.map((position) => ({
          ...position,
          id: `${position.id}`,
          dataSource: RosterDataSource.STATION,
          startDateTime: new Date(position.startDateTime),
          endDateTime: new Date(position.endDateTime),
          employees: [],
        })),
      })),
    });
  });
  map.set('floater-station', {
    stationId: 'floater-station',
    stationName: 'Floaters',
    dataSource: RosterDataSource.FLOATER,
    copyOfId: null,
    isDraft: false,
    certificationRequirements: [],
    apparatuses: [
      {
        id: 'floater-apparatus',
        dataSource: RosterDataSource.FLOATER,
        copyOfId: null,
        positions: [],
        name: '',
        isForShiftLeader: false,
        certificationRequirements: [],
      },
    ],
  });
  return map;
};

export const findCopyStruct = (
  newStations: Map<string, ShiftPlanStation>,
  prevStruct: ShiftPlanStruct,
  fallback: 'POSITION' | null = null,
) => {
  // `newStations`: Stations returned by Save As Draft response.
  // `prevStruct`: Struct before the Save As Draft.
  // This method finds the `newStruct` in `newStations` that matches `prevStruct`.
  // If `prevStruct` was draft or `newStruct` is not draft, "matches" is by id: `prevStruct.id === newStruct.id`.
  // Else, "matches" is by copy of id: `prevStruct.id === newStruct.copyOfId`.
  const { station: prevStation, apparatus: prevApparatus, position: prevPosition } = prevStruct;

  const compareIds = (prevId: string, newId: string, newCopyOfId: number | null, newIsDraft: boolean) => {
    return prevStation?.isDraft || !newIsDraft ? prevId === newId : prevId === `${newCopyOfId}`;
  };

  const copyStation =
    prevStation &&
    Array.from(newStations.values()).find((s) => compareIds(prevStation.stationId, s.stationId, s.copyOfId, s.isDraft));

  const copyApparatus =
    prevApparatus && copyStation?.apparatuses.find((a) => compareIds(prevApparatus.id, a.id, a.copyOfId, !!copyStation?.isDraft));

  let copyPosition =
    prevPosition && copyApparatus?.positions.find((p) => compareIds(prevPosition.id, p.id, p.copyOfId, !!copyStation?.isDraft));
  if (prevApparatus && prevPosition && !copyPosition && fallback === 'POSITION') {
    // If we couldn't find the copy of the previnal position (perhaps the user removed it),
    // try to find the copy of the position adjacent to the previnal position.
    const prevPositionIndex = prevApparatus.positions.findIndex((p) => p.id === prevPosition.id);
    const prevAdjPosition = prevApparatus.positions[prevPositionIndex - 1] || prevApparatus.positions[prevPositionIndex + 1];
    copyPosition =
      prevAdjPosition &&
      copyApparatus?.positions.find((p) => compareIds(prevAdjPosition.id, p.id, p.copyOfId, !!copyStation?.isDraft));
  }

  return { station: copyStation, apparatus: copyApparatus, position: copyPosition };
};
