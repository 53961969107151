import { Box } from '@mui/material';
import { XCloseIcon16 } from '../../../assets';
import { ItemLayout } from './ItemLayout';

interface LinkProps {
  link: string;
  setLink: (link: string) => void;
  removalAllowed?: boolean;
}

export const Link = ({ link, setLink, removalAllowed }: LinkProps) => {
  return (
    <ItemLayout>
      <>
        <Box sx={(theme) => ({ color: theme.palette.gray[500], typography: 'tagline1' })}>LINK</Box>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          sx={(theme) => ({
            width: '89%',
            marginTop: '-6px',
            '& path': {
              stroke: theme.palette.common.white,
            },
            '&:hover': {
              borderRadius: '8px',
              cursor: 'pointer',
              background: removalAllowed ? theme.palette.gray[100] : 'inherit',
              pl: removalAllowed ? '8px' : 'unset',
              '& path': {
                stroke: theme.palette.gray[400],
              },
            },
            color: theme.palette.gray[900],
          })}
        >
          <Box sx={{ typography: 'bodySRegular' }} data-testid="link-container">
            {link}
          </Box>
          {removalAllowed && (
            <Box sx={{ mr: '10px', display: 'flex' }} onClick={() => setLink('')}>
              <XCloseIcon16 />
            </Box>
          )}
        </Box>
      </>
    </ItemLayout>
  );
};
