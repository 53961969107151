import { Global } from '@emotion/react';
import { Box, TextField } from '@mui/material';
import { useJsApiLoader, Autocomplete as GoogleMapsAutocomplete } from '@react-google-maps/api';
import React, { useState, useRef, useCallback } from 'react';

const PacContainerStyle: React.FC = () => (
  <Global
    styles={`
      .pac-container {
        z-index: 9999 !important;
        position:fixed !important;
      }
    `}
  />
);

const libraries: 'places'[] = ['places'];

type AddressAutocompleteProps = {
  defaultValue?: string;
  required?: boolean;
  disabled?: boolean;
  onChange: (value: string) => void;
};

export const AddressAutocomplete: React.FC<AddressAutocompleteProps> = ({ defaultValue, required, disabled, onChange }) => {
  const [inputValue, setInputValue] = useState(defaultValue ?? '');

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: process.env['GOOGLE_ADDRESS_KEY'] || '',
    libraries,
  });

  const autocompleteRef = useRef<google.maps.places.Autocomplete | null>(null);

  const handleOnLoad = useCallback((autocomplete: google.maps.places.Autocomplete) => {
    autocompleteRef.current = autocomplete;
  }, []);

  const handleOnPlaceChanged = useCallback(() => {
    if (!autocompleteRef.current) return;
    const place = autocompleteRef.current.getPlace();
    const formattedAddress = place.formatted_address || '';
    setInputValue(formattedAddress);
    onChange(formattedAddress);
  }, [onChange]);

  if (!isLoaded) {
    return <TextField label="Loading Google Maps..." value="" disabled fullWidth />;
  }

  return (
    <Box sx={{ width: '100%' }}>
      <PacContainerStyle />
      <GoogleMapsAutocomplete
        onLoad={handleOnLoad}
        onPlaceChanged={handleOnPlaceChanged}
        options={{
          componentRestrictions: { country: 'us' },
          fields: ['formatted_address', 'geometry', 'name'],
          types: ['address'],
        }}
      >
        <TextField
          required={required}
          disabled={disabled}
          fullWidth
          placeholder="Enter an address"
          value={inputValue}
          sx={{ width: '100%' }}
          onChange={(e) => {
            setInputValue(e.target.value);
            onChange(e.target.value);
          }}
        />
      </GoogleMapsAutocomplete>
    </Box>
  );
};
