import { Box, FormHelperText, MenuItem, Modal, TextField, Typography } from '@mui/material';
import React, { useState, useEffect } from 'react';
import { Button, getRankColors, Input, theme } from '@stationwise/component-module';
import { AllowedColors, allowedColorsList } from '@stationwise/share-types';

interface EditWorkGroupModalProps {
  name?: string | undefined;
  description?: string;
  color?: AllowedColors;
  title: string;
  showModal: boolean;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  setTitle?: React.Dispatch<React.SetStateAction<string>>;
  handleEdit?: (name: string, description: string, color: AllowedColors) => void;
  handleCreate?: (name: string, description: string, color: AllowedColors) => void;
  workGroupNames?: string[];
  onCardCreate?: (name: string, description: string, color: AllowedColors) => void;
}

export const EditWorkGroupModal = ({
  name,
  description,
  color,
  title,
  showModal,
  setShowModal,
  setTitle,
  handleEdit,
  handleCreate,
  workGroupNames,
  onCardCreate,
}: EditWorkGroupModalProps) => {
  const isCreate = title === 'New work group';
  const [newName, setNewName] = useState(isCreate ? '' : name);
  const [newDescription, setNewDescription] = useState(isCreate ? '' : description ?? '');
  const [newColor, setNewColor] = useState(color ?? ('gray' as AllowedColors));

  const colorItems = allowedColorsList.map((color) => ({
    label: color.slice(0, 1).toUpperCase() + color.slice(1),
    value: color,
    color: color,
  }));

  useEffect(() => {
    if (title === 'New work group') {
      setNewName('');
      setNewDescription('');
      setNewColor('gray' as AllowedColors);
    } else {
      setNewName(name ?? '');
      setNewDescription(description ?? '');
      setNewColor(color ?? ('gray' as AllowedColors));
    }
  }, [name, description, color, showModal, title]);

  const handleSave = () => {
    if (!newName) return;
    if (onCardCreate) {
      onCardCreate(newName, newDescription, newColor);
    } else if (isCreate && handleCreate) {
      handleCreate(newName, newDescription, newColor);
    } else if (handleEdit) {
      handleEdit(newName, newDescription, newColor);
    }
    handleClose();
  };

  const handleClose = () => {
    setNewColor('gray' as AllowedColors);
    setShowModal(false);
    setTitle && setTitle('New work group');
    setNewDescription(description ?? '');
    setNewName(name);
  };

  return (
    <Modal open={showModal}>
      <Box
        sx={{
          display: 'flex',
          width: '100%',
          height: '90vh',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Box
          sx={(theme) => ({
            backgroundColor: theme.palette.common.white,
            justifyContent: 'center',
            borderRadius: theme.spacing(1.5),
            p: theme.spacing(3),
            width: '496px',
            display: 'flex',
            flexDirection: 'column',
          })}
        >
          <Box
            sx={{
              typography: 'bodyXLSemibold',
            }}
          >
            {title}
          </Box>

          <Box
            sx={(theme) => ({
              marginRight: theme.spacing(1),
              width: '100%',
            })}
          >
            <Box
              sx={(theme) => ({
                typography: 'bodySMedium',
                textAlign: 'left',
                paddingTop: theme.spacing(2),
                color: theme.palette.gray[700],
              })}
            >
              Name
            </Box>

            <Box
              sx={(theme) => ({
                py: theme.spacing(1),
              })}
            >
              <Input
                data-cy="work-group-name-input"
                value={newName}
                onChange={(event) => {
                  setNewName(event.currentTarget.value);
                }}
              />
              <FormHelperText>
                {workGroupNames && newName && workGroupNames.includes(newName) ? 'This group already exists.' : ''}
              </FormHelperText>
            </Box>
          </Box>
          <Box
            sx={{
              width: '100%',
            }}
          >
            <Box
              sx={(theme) => ({
                typography: 'bodySMedium',
                textAlign: 'left',
                paddingTop: theme.spacing(2),
                color: theme.palette.gray[700],
              })}
            >
              Description (optional)
            </Box>

            <Box
              sx={(theme) => ({
                py: theme.spacing(1),
              })}
            >
              <TextField
                data-cy="work-group-description-input"
                value={newDescription}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setNewDescription(event.target.value);
                }}
                sx={{
                  width: '100%',
                }}
                multiline
                rows={3}
              />
            </Box>
            <TextField
              label="Color"
              onChange={(event) => {
                setNewColor(event.target.value as AllowedColors);
              }}
              select
              value={newColor}
              sx={{
                width: '100%',
                mt: theme.spacing(2),
              }}
            >
              {colorItems.map((color) => (
                <MenuItem key={color.value} value={color.value}>
                  <Typography
                    sx={{
                      color: getRankColors({ theme, color: String(color.label), dark: false }).color,
                      textAlign: 'left',
                      paddingRight: 1,
                    }}
                  >
                    {color.label}
                  </Typography>
                </MenuItem>
              ))}
            </TextField>
          </Box>

          <Box
            sx={(theme) => ({
              justifyContent: 'space-between',
              display: 'flex',
              mt: theme.spacing(5),
              width: '100%',
            })}
          >
            <Button variant="outlined" size="large" sx={{ width: '216px' }} onClick={() => handleClose()}>
              Cancel
            </Button>
            <Button
              data-cy="save-work-group-button"
              variant="contained"
              size="large"
              sx={{ width: '216px' }}
              onClick={() => handleSave()}
              disabled={
                newName === '' ||
                (newName === name && newColor === color && (newDescription === description || newDescription === '')) ||
                (!!workGroupNames && !!newName && workGroupNames.includes(newName))
              }
            >
              Save
            </Button>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};
