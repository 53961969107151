import { Box, Typography, Divider } from '@mui/material';
import { DialogXButton, AutoHireInfoAlert, Modal, Button, theme } from '@stationwise/component-module';
import { HiringEngineSettingsInfo } from '@stationwise/share-types';
import { HiringEngineVacancyFillOrders } from '../DepartmentSettings/components/HiringEngineVacancyFillOrders';
import { AutoHireIntervalPicker } from './AutoHire/AutoHireIntervalPicker';

interface VacancyDayFillOrderModalProps {
  open: boolean;
  onClose: () => void;
  hiringEngineSettingsInfo: HiringEngineSettingsInfo;
  setHiringEngineSettingsInfo: React.Dispatch<React.SetStateAction<HiringEngineSettingsInfo | null>>;
}

export const VacancyDayFillOrderModal = ({
  open,
  onClose,
  hiringEngineSettingsInfo,
  setHiringEngineSettingsInfo,
}: VacancyDayFillOrderModalProps) => {
  return (
    <Modal open={open} setOpen={() => onClose()} hideCloseIcon={true}>
      <Box
        sx={(theme) => ({
          backgroundColor: theme.palette.common.white,
          borderRadius: '16px',
          position: 'relative',
          width: '552px',
          maxHeight: '90vh',
          display: 'flex',
          flexDirection: 'column',
        })}
      >
        <Box
          sx={(theme) => ({
            padding: theme.spacing(3),
            display: 'flex',
            flexDirection: 'column',
            gap: theme.spacing(2),
            overflowY: 'auto',
          })}
        >
          <Box sx={{ typography: 'bodyXLSemibold' }}>Hiring Engine Day Settings</Box>
          <DialogXButton onClose={onClose} />

          <AutoHireInfoAlert message="These settings are specific for this day." />
          <Typography variant="bodyMMedium">Round Duration</Typography>
          <Divider />

          <AutoHireIntervalPicker
            value={hiringEngineSettingsInfo.autoHireDefaultNotificationInterval?.toString()}
            onChange={(newValue) => {
              if (newValue) {
                const totalMinutes = parseInt(newValue);
                setHiringEngineSettingsInfo({
                  ...hiringEngineSettingsInfo,
                  autoHireDefaultNotificationInterval: totalMinutes,
                });
              }
            }}
            sx={{
              width: '153px',
              '& .MuiInputBase-root': {
                backgroundColor: theme.palette.common.white,
                borderRadius: '8px',
              },
            }}
          />
          <AutoHireInfoAlert message="Auto hire sends notifications to rank groups in the order shown below. Each round starts after the specified interval." />
          <Typography variant="bodyMMedium">Vacancy fill order</Typography>
          <Divider />
          <HiringEngineVacancyFillOrders
            clickable={false}
            hiringEngineSettingsInfo={hiringEngineSettingsInfo}
            setHiringEngineSettingsInfo={setHiringEngineSettingsInfo}
            width="464px"
            dragIndicator={true}
          />
        </Box>
        <Box
          sx={{
            bottom: 0,
            backgroundColor: theme.palette.common.white,
            borderTop: `1px solid ${theme.palette.gray[100]}`,
            display: 'flex',
            justifyContent: 'flex-end',
            gap: 1,
            p: 3,
          }}
        >
          <Button variant="outlined" size="medium" onClick={() => onClose()} sx={{ height: '36px' }}>
            Cancel
          </Button>
          <Button variant="contained" size="medium" onClick={onClose} sx={{ height: '36px' }}>
            Save
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};
