import { Box, useTheme } from '@mui/material';
import React from 'react';

interface EventCardWrapperProps {
  children: React.ReactNode;
}

export const EventCardWrapper: React.FC<EventCardWrapperProps> = ({ children }) => {
  const theme = useTheme();

  return (
    <Box sx={{ mx: 2 }}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          alignSelf: 'stretch',
          borderRadius: theme.spacing(2),
          border: `1px solid ${theme.palette.divider}`,
          overflow: 'hidden',
        }}
      >
        {children}
      </Box>
    </Box>
  );
};
