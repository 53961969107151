import { BasicEmployee } from '../views/employee/models';
import { DetailCode, PayCode } from '../views/payroll/models';
import { ShiftPlanAssignmentAPIData, ShiftPlanChangeLogAPIData, ShiftPlanTeam } from '../views/shift/getShiftPlan';
import { ShiftPlanCandidateAPIData } from '../views/shift/getShiftPlanCandidates';
import { RosterStation, RosterApparatus, RosterPosition } from './roster';

export enum ShiftPlanChipType {
  ROSTER_EDITOR = 'ROSTER_EDITOR',
  STAFFING_PATTERNS = 'STAFFING_PATTERNS',
}

export enum ShiftPlanAction {
  UPSERT_STATION = 'UPSERT_STATION',
  REMOVE_STATION = 'REMOVE_STATION',
  UPSERT_APPARATUS = 'UPSERT_APPARATUS',
  TOGGLE_APPARATUS_MODE = 'TOGGLE_APPARATUS_MODE',
  MOVE_APPARATUS = 'MOVE_APPARATUS',
  REMOVE_APPARATUS = 'REMOVE_APPARATUS',
  UPSERT_POSITION = 'UPSERT_POSITION',
  WARN_EDIT_POSITION = 'WARN_EDIT_POSITION',
  REMOVE_POSITION = 'REMOVE_POSITION',
  UPSERT_STAFFING_PATTERN = 'UPSERT_STAFFING_PATTERN',
  VIEW_CANDIDATE = 'VIEW_CANDIDATE',
  ADD_ASSIGNMENT = 'ADD_ASSIGNMENT',
  EDIT_ASSIGNMENT = 'EDIT_ASSIGNMENT',
  REMOVE_ASSIGNMENT = 'REMOVE_ASSIGNMENT',
  GET_CHANGE_LOG = 'GET_CHANGE_LOG',
  GET_PUBLISH_PREVIEW = 'GET_PUBLISH_PREVIEW',
  DISCARD_DRAFT = 'DISCARD_DRAFT',
  PUBLISH_DRAFT = 'PUBLISH_DRAFT',
}

export enum ShiftPlanTeamType {
  SHIFT_TEAM = 'SHIFT',
  EVENT_TEAM = 'EVENT',
  NON_SHIFT_TEAM = 'NON_SHIFT',
  LIGHT_DUTY_TEAM = 'LIGHT_DUTY',
  EXTENDED_LEAVE_TEAM = 'EXTENDED_LEAVE',
}

export interface ShiftPlanPosition extends RosterPosition {
  copyOfId: number | null;
}

export interface ShiftPlanApparatus extends Omit<RosterApparatus, 'positions'> {
  copyOfId: number | null;
  positions: ShiftPlanPosition[];
}

export interface ShiftPlanStation extends Omit<RosterStation, 'apparatuses'> {
  copyOfId: number | null;
  isDraft: boolean;
  apparatuses: ShiftPlanApparatus[];
}

export interface ShiftPlanStruct {
  station?: ShiftPlanStation;
  apparatus?: ShiftPlanApparatus;
  position?: ShiftPlanPosition;
}

export interface ShiftPlanAssignment {
  id: number;
  employee: ShiftPlanAssignmentAPIData['employee'];
  activationDate: Date;
  deactivationDate: Date | null;
  copyOfId: number | null;
  isDraft: boolean;
  startDateTime: Date;
  endDateTime: Date;
  payCodes: PayCode[];
  detailCodes: DetailCode[];
  priority: number;
  fullShift: boolean;
  team: ShiftPlanTeam;
  reference: ShiftPlanAssignmentAPIData['reference'];
}

export interface ShiftPlanAssignmentGroups {
  positionAssignments: Map<string, ShiftPlanAssignment[]>;
  excessAssignments: Map<string, ShiftPlanAssignment[]>;
  floaterAssignments: ShiftPlanAssignment[];
}

export interface ShiftPlanChangeLog {
  hasChanges: boolean;
  changes: ShiftPlanChangeLogAPIData['changes'];
  removedStructs: ShiftPlanStation[];
  upsertedStructs: ShiftPlanStation[];
  removedAssignmentGroups: Map<string, ShiftPlanAssignment[]>;
  upsertedAssignmentGroups: Map<string, ShiftPlanAssignment[]>;
  affectedEmployees: Array<ShiftPlanAssignment['employee']>;
  readonlyEmployeeIds: Set<string>;
}

export interface ShiftPlanPublishPreviewCancelledRequest {
  id: number;
  startDateTime: string;
  endDateTime: string;
  reason?: string;
  stationName?: string;
  apparatusName?: string;
  senderName?: string;
  receiverName?: string;
}

export interface ShiftPlanPublishPreviewEmployeeGroupItem {
  employee: BasicEmployee;
  cancelledShiftTrades: ShiftPlanPublishPreviewCancelledRequest[];
  cancelledTimeOffs: ShiftPlanPublishPreviewCancelledRequest[];
}

export interface ShiftPlanPublishPreview {
  unassignedEmployeeGroup: ShiftPlanPublishPreviewEmployeeGroupItem[];
  diffDatesEmployeeGroup: ShiftPlanPublishPreviewEmployeeGroupItem[];
  sameDatesEmployeeGroup: ShiftPlanPublishPreviewEmployeeGroupItem[];
  indirectlyAffectedEmployeeGroup: ShiftPlanPublishPreviewEmployeeGroupItem[];
}

export interface ShiftPlanCandidate extends Omit<ShiftPlanCandidateAPIData, 'assignments'> {
  assignments: ShiftPlanAssignment[];
}
