import { ReactComponent as DragIndicatorSymbol } from '@material-symbols/svg-400/outlined/drag_indicator.svg';
import { Box, useTheme } from '@mui/material';
import { MutableRefObject } from 'react';
import { CardCertBadges, RankBadge, SvgIcon, TeamBadge, useLoadedDepartmentInfoContext } from '@stationwise/component-module';
import { ShiftPlanTeam } from '@stationwise/share-types';
import { filterNonRankCertifications } from '@stationwise/shift-summary-helper';
import { Switch } from '../../../../../../components/Common';
import { TeamPatternDays } from '../../Calendar';
import { BorderedBoxes } from '../../Grid';
import { TeamGroupedShiftPlanAssignment } from './UpsertAssignmentForm';

interface AdjustPatternPriorityContentRowProps {
  futureTeamAssignments: TeamGroupedShiftPlanAssignment;
  selectedMonth: Date;
  teams: ShiftPlanTeam[];
  dragItem: MutableRefObject<number | null>;
  dragOverItem: MutableRefObject<number | null>;
  handleSort: () => void;
  updateFullShiftForAssignment: (assignment: TeamGroupedShiftPlanAssignment, checked: boolean) => void;
  isLast: boolean;
}

export const AdjustPatternPriorityContentRow = ({
  futureTeamAssignments,
  dragItem,
  dragOverItem,
  isLast,
  selectedMonth,
  handleSort,
  updateFullShiftForAssignment,
  teams,
}: AdjustPatternPriorityContentRowProps) => {
  const { state: departmentInfoState } = useLoadedDepartmentInfoContext();
  const team = teams.find((t) => t.id === futureTeamAssignments.teamId);
  const theme = useTheme();
  return (
    <Box
      draggable
      key={'future_assignment' + futureTeamAssignments.teamId}
      onDragStart={() => (dragItem.current = futureTeamAssignments.priority)}
      onDragEnter={() => (dragOverItem.current = futureTeamAssignments.priority)}
      onDragEnd={handleSort}
      sx={{
        '&:hover': {
          backgroundColor: theme.palette.gray[300],
          cursor: 'move',
        },
        borderBottomLeftRadius: isLast ? `${theme.shape.borderRadius}px` : '0px',
        borderBottomRightRadius: isLast ? `${theme.shape.borderRadius}px` : '0px',
      }}
    >
      {futureTeamAssignments.assignments.map((assignment, index) => (
        <BorderedBoxes key={'priority_row' + assignment.team?.id}>
          <Box
            sx={{
              flex: 1,
              px: 0.45,
              typography: 'bodySMedium',
              justifyContent: 'center',
              alignItems: 'center',
              borderBottom: 'none',
            }}
          >
            {index === 0 ? futureTeamAssignments.priority.toString() : ''}
          </Box>
          <Box sx={{ flex: 1, px: 0.5, typography: 'bodySMedium', justifyContent: 'center', alignItems: 'center' }}>
            {index === 0 ? <SvgIcon component={DragIndicatorSymbol} /> : null}
          </Box>
          <Box sx={{ flex: 1.5, px: 1, typography: 'bodySMedium', justifyContent: 'center', alignItems: 'center' }}>
            {index === 0 ? (
              <Switch
                checked={futureTeamAssignments.fullShift}
                onChange={(_event, checked) => updateFullShiftForAssignment(futureTeamAssignments, checked)}
              />
            ) : null}
          </Box>
          <Box sx={{ flex: 4, px: 1.5 }}>
            {assignment.reference.position ? (
              <Box sx={{ typography: 'bodySMedium', display: 'flex', alignItems: 'center', gap: 1 }}>
                {assignment.reference.position.rank ? <RankBadge rank={assignment.reference.position?.rank} /> : null}
                <Box sx={{ display: 'inline' }}>
                  {assignment.reference.station?.name}, {assignment.reference.apparatus?.name}
                </Box>
                {assignment.reference.position.rank ? (
                  <CardCertBadges
                    certs={filterNonRankCertifications(
                      departmentInfoState.departmentInfo,
                      assignment.reference.position.certifications,
                      assignment.reference.position.rank,
                    )}
                  />
                ) : null}
              </Box>
            ) : null}
          </Box>
          <Box sx={{ flex: 2, px: 1.5 }}>{team ? <TeamBadge team={team} /> : null}</Box>
          <BorderedBoxes sx={{ border: 0, width: '700px', minWidth: '60%' }}>
            <TeamPatternDays month={selectedMonth} assignments={[assignment]} team={team} />
          </BorderedBoxes>
        </BorderedBoxes>
      ))}
    </Box>
  );
};
