import { useDroppable } from '@dnd-kit/core';
import { Box, Theme, useMediaQuery } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { RosterEmployee } from '@stationwise/share-types';
import { COLLISION_REGION } from '@stationwise/shift-summary-helper';
import { useAuthUserCapabilities } from '../../../../utils/capabilities';
import { DraggableCard } from '../DraggableCard';
import { getLastChangesErrorPopoverAnchorProps } from '../LastChangesErrorPopover';
import { useRosterContext } from '../RosterContext';
import { FloaterCard } from './FloaterCard';
import { PlaceholderFloaterCard } from './PlaceholderFloaterCard';

interface FloatersBarProps {
  unassignedCards: RosterEmployee[];
}

export const FloatersBar = ({ unassignedCards }: FloatersBarProps) => {
  const isAdminApp = useMediaQuery((theme: Theme) => theme.breakpoints.up('lg'));
  const capabilities = useAuthUserCapabilities();
  const { isReadonly, selectedFilledPositionState } = useRosterContext();
  const droppable = useDroppable({ id: COLLISION_REGION.FLOATERS_BAR });

  const ref = useRef<HTMLDivElement | null>();
  const [height, setHeight] = useState('72px');
  useEffect(() => {
    if (!isAdminApp || !ref.current) {
      return;
    }

    const observer = new ResizeObserver((entries) => {
      const rect = entries[0]?.contentRect;
      rect && setHeight(`${rect.bottom - rect.top}px`);
    });

    observer.observe(ref.current);

    return () => observer.disconnect();
  }, [isAdminApp]);

  const isCreateStrikeTeamButtonShown = !isReadonly && capabilities.EDIT_EVENTS;

  const bar = (
    <Box
      ref={ref}
      sx={(theme) => ({
        lg: {
          display: 'inline-flex',
          alignItems: 'center',
          gap: 1,
          width: 'max-content',
          maxWidth: '100%',
          pr: isCreateStrikeTeamButtonShown ? '228px' : '48px',
          [theme.breakpoints.down('lg')]: {
            pr: isCreateStrikeTeamButtonShown ? '125px' : '48px',
          },
        },
      })}
    >
      <Box ref={droppable.setNodeRef} sx={{ flex: 1, minWidth: '1px' }}>
        <Box
          {...getLastChangesErrorPopoverAnchorProps(COLLISION_REGION.FLOATERS_BAR)}
          sx={(theme) => ({
            background: droppable.isOver ? theme.palette.gray[300] : theme.palette.gray[200],
            borderRadius: theme.spacing(2),
            border: `2px solid ${theme.palette.gray[300]}`,
            boxShadow: '0px 25px 50px -12px rgba(0, 0, 0, 0.25)',
            display: 'flex',
            gap: 0.5,
            p: '2px',
            whiteSpace: 'nowrap',
            overflowX: 'auto',
            scrollbarWidth: 'none',
            '&::-webkit-scrollbar': { height: 0 },
            ...(isAdminApp && { flexWrap: 'wrap', whiteSpace: undefined, overflowX: undefined }),
          })}
        >
          {unassignedCards.length > 0 ? (
            <>
              {unassignedCards.map((card) => (
                <DraggableCard
                  key={card.activeId}
                  activeId={card.activeId}
                  disabled={!capabilities.EDIT_ROSTER_ASSIGNMENTS}
                  sx={{ width: 'auto' }}
                >
                  <Box
                    onClick={
                      !isAdminApp ? undefined : (event) => selectedFilledPositionState.setEmployee(card, event.currentTarget)
                    }
                    sx={{ height: '100%' }}
                  >
                    <FloaterCard card={card} />
                  </Box>
                </DraggableCard>
              ))}
              {droppable.isOver && <PlaceholderFloaterCard />}
            </>
          ) : (
            <Box
              sx={(theme) => ({
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                color: theme.palette.gray[400],
                typography: 'bodyMMedium',
                p: theme.spacing(0.25, 3),
              })}
            >
              No available floaters
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
  return (
    <>
      <Box
        sx={(theme) => ({
          position: 'fixed',
          left: '50%',
          transform: 'translateX(-50%)',
          bottom: 0,
          maxWidth: '100%',
          zIndex: theme.zIndex.appBar,
          ...(isAdminApp && { p: theme.spacing(0, 2, 1.5, 10) }),
        })}
      >
        {bar}
      </Box>
      <Box sx={{ height }} />
    </>
  );
};
