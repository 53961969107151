import { DayCellContentArg } from '@fullcalendar/core';
import { Box } from '@mui/material';

export const CustomDayCellContent = ({
  cellContent,
  isEqualToSelectedDaysAhead,
  selectedDaysAhead,
}: {
  cellContent: DayCellContentArg;
  isEqualToSelectedDaysAhead: boolean;
  selectedDaysAhead: string;
}) => {
  return (
    <Box display="flex" justifyContent="space-between">
      <Box
        display="flex"
        sx={(theme) => ({
          backgroundColor: cellContent.isToday ? theme.palette.common.black : 'unset',
          color: cellContent.isToday ? theme.palette.common.white : 'unset',
          width: '32px',
          height: '32px',
          borderRadius: '50%',
          textAlign: cellContent.isToday ? 'center' : 'unset',
          pt: cellContent.isToday ? '3px' : 'unset',
          justifyContent: cellContent.isToday ? 'center' : 'unset',
          typography: 'bodyMRegular',
        })}
      >
        {cellContent.dayNumberText}
      </Box>
      {cellContent.isToday && (
        <Box
          sx={(theme) => ({
            color: theme.palette.gray[400],
            mt: '5px',
            mr: '5px',
            typography: 'tagline1',
            fontSize: '8px',
          })}
        >
          TODAY
        </Box>
      )}
      {selectedDaysAhead !== '0' && isEqualToSelectedDaysAhead && (
        <Box
          sx={(theme) => ({
            mt: '5px',
            ml: '80px',
            color: theme.palette.gray[400],
            typography: 'tagline1',
          })}
        >
          {`${selectedDaysAhead}D AHEAD`}
        </Box>
      )}
    </Box>
  );
};
