import { Box } from '@mui/material';
import { ChevronRightIcon16 } from '@stationwise/component-module';
import { CATEGORY_CHOICES, NotificationCategory } from './utils';

interface NotificationsProps {
  setClicked: (category: NotificationCategory) => void;
}

export const Notifications = ({ setClicked }: NotificationsProps) => {
  return (
    <Box
      sx={(theme) => ({
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        gap: theme.spacing(3),
        width: '100%',
      })}
    >
      <Box
        sx={(theme) => ({
          color: theme.palette.gray[900],
          typography: 'bodyLSemibold',
          height: theme.spacing(3),
        })}
      >
        Notifications
      </Box>
      <Box
        sx={(theme) => ({
          display: 'flex',
          p: theme.spacing(2),
          width: 'inherit',
          flexDirection: 'column',
          alignItems: 'flex-start',
          gap: theme.spacing(2),
          borderRadius: theme.spacing(1.5),
          background: theme.palette.common.white,
          boxShadow: '0px 1px 2px 0px rgba(10, 14, 22, 0.05)',
        })}
      >
        {CATEGORY_CHOICES.map((category, index) => (
          <Box
            sx={{
              width: 'inherit',
            }}
            key={category[0]}
          >
            <Box
              sx={(theme) => ({
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                alignSelf: 'stretch',
                cursor: 'pointer',
                width: 'inherit',
                height: '100%',
                typography: 'bodyMRegular',
                '&:hover': {
                  cursor: 'pointer',
                  color: theme.palette.purple[500],
                },
              })}
              onClick={() => setClicked(category[0])}
            >
              <Box>{category[1]}</Box>
              <ChevronRightIcon16 />
            </Box>
            {index !== CATEGORY_CHOICES.length - 1 && (
              <Box
                sx={(theme) => ({
                  mt: theme.spacing(2),
                  backgroundColor: theme.palette.gray[200],
                  height: '1px',
                  width: 'inherit',
                  alignSelf: 'stretch',
                })}
              ></Box>
            )}
          </Box>
        ))}
      </Box>
    </Box>
  );
};
