import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDocumentTitle, CAPABILITIES, useAuthUserCapabilities } from '@stationwise/component-module';
import { ROUTES } from '../../../core/Routes';
import { AppPageLayout } from '../../AppPageLayout';
import { EmployeesTab } from '../EmployeesTab';
import { TeamSettings } from './components/TeamSettings';

export const TeamPage = () => {
  useDocumentTitle('Employees');

  const capabilities = useAuthUserCapabilities();
  const canViewTeamSettings = capabilities.VIEW_EMPLOYEES_PAGE;
  const canViewStaffingList = capabilities.VIEW_STAFFING_LIST;
  const navigate = useNavigate();

  // TODO: remove this once we have a proper way to check if the user has only the staffing list capability
  // https://app.asana.com/0/1208964381923959/1209535840331242

  useEffect(() => {
    if (!canViewTeamSettings && canViewStaffingList) {
      navigate(ROUTES.STAFFING_LISTS.fullRoute);
    }
  }, [canViewTeamSettings, canViewStaffingList, navigate]);

  return (
    <AppPageLayout
      sx={(theme) => ({ background: theme.palette.common.white })}
      requiredCapability={CAPABILITIES.VIEW_EMPLOYEES_PAGE}
    >
      <EmployeesTab defaultTab="allEmployees">
        <TeamSettings />
      </EmployeesTab>
    </AppPageLayout>
  );
};
