import { Box, Typography } from '@mui/material';
import { format } from 'date-fns';
import { CheckIcon16 as CheckIcon, ClockIcon16 } from '@stationwise/component-module';

export const FixedBoxAtBottom = ({
  markAsCompleted,
  isCompleted,
  completedAt,
}: {
  markAsCompleted: () => void;
  isCompleted: boolean;
  completedAt: Date | null;
}) => {
  return (
    <Box
      sx={(theme) => ({
        position: 'fixed',
        bottom: 0,
        left: 0,
        width: '100%',
        height: '72px',
        padding: theme.spacing(2),
        pl: isCompleted ? theme.spacing(1) : theme.spacing(2),
        boxShadow: '0px -4px 8px rgba(0, 0, 0, 0.1)',
        display: 'flex',
        justifyContent: isCompleted ? 'space-between' : 'center',
        alignItems: 'center',
        backgroundColor: theme.palette.common.white,
        zIndex: theme.zIndex.drawer,
      })}
    >
      <Box
        sx={(theme) => ({
          backgroundColor: isCompleted ? theme.palette.green[500] : 'transparent',
          borderRadius: theme.spacing(8),
          border: `1px dashed ${theme.palette.green[500]}`,
          pr: theme.spacing(0.5),
          pl: theme.spacing(0.5),
          width: isCompleted ? '181px' : '95%',
          height: '48px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          color: isCompleted ? theme.palette.common.white : 'inherit',
        })}
        onClick={!isCompleted ? () => markAsCompleted() : undefined}
      >
        {isCompleted && <CheckIcon />}
        <Typography
          variant="bodyMMedium"
          sx={(theme) => ({
            color: isCompleted ? theme.palette.common.white : theme.palette.green[500],
            padding: theme.spacing(0.5),
          })}
        >
          {isCompleted ? 'Complete' : 'Mark as complete'}
        </Typography>
      </Box>
      {isCompleted && completedAt && (
        <Box sx={(theme) => ({ display: 'flex', mr: theme.spacing(1), color: theme.palette.gray[400] })}>
          <Typography variant="bodyMMedium" sx={{ mr: '6px' }}>
            {format(completedAt, 'MMM dd, yyyy')}
          </Typography>
          <Box sx={{ mt: '2px', mr: '2px' }}>
            <ClockIcon16 />
          </Box>
          <Typography variant="bodyMMedium">{format(completedAt, 'HHmm')}</Typography>
        </Box>
      )}
    </Box>
  );
};
