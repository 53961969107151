import { Box, GlobalStyles, Dialog } from '@mui/material';
import { PrintDaySchedule } from '../PrintDaySchedule/PrintDaySchedule';
import { PrintDayScheduleModalFooter } from './PrintDayScheduleModalFooter';

interface PrintDayScheduleModalProps {
  open: boolean;
  handleClose: () => void;
}

export const PrintDayScheduleModal = ({ open, handleClose }: PrintDayScheduleModalProps) => {
  return (
    <Dialog
      className="PrintDayScheduleModal"
      keepMounted={true}
      open={open}
      onClose={handleClose}
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        oveflow: 'hidden',
        '& .MuiPaper-root': { maxWidth: '100%' },
      }}
    >
      <Box
        sx={(theme) => ({
          background: theme.palette.common.white,
          borderRadius: theme.spacing(1.5),
          padding: theme.spacing(2),
          width: 'auto',
          maxHeight: '95vh',
          overflow: 'hidden',
        })}
      >
        <Box
          sx={{
            overflowY: 'auto',
            maxHeight: 'calc(95vh - 112px)',
          }}
        >
          <GlobalStyles
            styles={{
              '@media print': {
                '*': {
                  colorAdjust: 'exact',
                },
              },
            }}
          />
          <PrintDaySchedule />
        </Box>
        <PrintDayScheduleModalFooter handleClose={handleClose} />
      </Box>
    </Dialog>
  );
};
