import { EventInput } from '@fullcalendar/core/index.js';
import { EventImpl } from '@fullcalendar/core/internal';
import { Box, Divider } from '@mui/material';
import { useEffect } from 'react';
import {
  SHIFT_OVERVIEW_OPTIONS,
  Loader,
  CalendarShiftCard,
  Pencil01Icon24,
  XCloseIcon20,
  ChevronRightIcon24,
  useAuthUserCapabilities,
} from '@stationwise/component-module';
import { GetIncidentOverview } from '../../../../../libs/share-types/src/views';
import { useFetchRequestOverview } from '../RequestSharedComponents/hooks/useFetchRequestOverview';

interface IncidentOverviewContentProps {
  shift: EventInput | EventImpl;
  handleSelect: (option: string) => void;
  setSelectedIncident: (incident: GetIncidentOverview) => void;
  setIsCloseIncident: (isCloseIncident: boolean) => void;
}

export const IncidentOverviewContent = ({
  handleSelect,
  shift,
  setSelectedIncident,
  setIsCloseIncident,
}: IncidentOverviewContentProps) => {
  const fetchUrl = `/incident/incidents/${shift.id}/`;
  const { isLoading, isError, error, data: overview } = useFetchRequestOverview<GetIncidentOverview>(fetchUrl);
  const capabilities = useAuthUserCapabilities();
  const canManageIncidents = capabilities.MANAGE_INCIDENT_REPORT;

  useEffect(() => {
    if (overview) {
      setSelectedIncident(overview);
    }
  }, [overview, setSelectedIncident]);

  return (
    <Box>
      {isLoading && (
        <Box
          sx={(theme) => ({
            p: theme.spacing(3),
            mt: theme.spacing(3),
          })}
        >
          <Loader />
        </Box>
      )}
      {isError && <div>{`Something went wrong please try again later ${error}}`}</div>}
      {!isLoading && !isError && overview && (
        <>
          <CalendarShiftCard shift={shift} showIcon={false} mini={true} />
          <Box
            sx={(theme) => ({
              pr: theme.spacing(2),
              pl: theme.spacing(2),
            })}
          >
            <>
              {canManageIncidents && (
                <>
                  <Box
                    data-cy="edit-incident-details-button"
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    onClick={() => handleSelect(SHIFT_OVERVIEW_OPTIONS.EDIT_INCIDENT)}
                    sx={(theme) => ({
                      py: theme.spacing(2),
                      cursor: 'pointer',
                    })}
                  >
                    <Box display="flex">
                      <Box
                        sx={(theme) => ({
                          '& svg': {
                            '& path': {
                              stroke: theme.palette.gray[400],
                            },
                          },
                        })}
                      >
                        <Pencil01Icon24 />
                      </Box>
                      <Box
                        sx={(theme) => ({
                          ml: theme.spacing(2),
                          color: theme.palette.gray[700],
                          typography: 'bodyLMedium',
                        })}
                      >
                        {(overview.isActive ? 'Edit' : 'View') + ' Incident details'}
                      </Box>
                    </Box>
                    <Box sx={(theme) => ({ color: theme.palette.gray[400] })}>
                      <ChevronRightIcon24 />
                    </Box>
                  </Box>
                  <Divider />
                  {overview.isActive && (
                    <Box
                      data-cy="close-incident-button"
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                      onClick={() => {
                        handleSelect(SHIFT_OVERVIEW_OPTIONS.CLOSE_INCIDENT);
                        setIsCloseIncident(true);
                      }}
                      sx={(theme) => ({
                        py: theme.spacing(2),
                        cursor: 'pointer',
                      })}
                    >
                      <Box display="flex">
                        <Box
                          sx={(theme) => ({
                            '& svg': {
                              '& path': {
                                stroke: theme.palette.gray[400],
                              },
                            },
                          })}
                        >
                          <XCloseIcon20 />
                        </Box>
                        <Box
                          sx={(theme) => ({
                            ml: theme.spacing(2),
                            color: theme.palette.gray[700],
                            typography: 'bodyLMedium',
                          })}
                        >
                          Close Incident
                        </Box>
                      </Box>
                      <Box sx={(theme) => ({ color: theme.palette.gray[400] })}>
                        <ChevronRightIcon24 />
                      </Box>
                    </Box>
                  )}

                  <Divider />
                </>
              )}
              {/* NOTE: This action is not currently supported but could be added back if needed from Napa County FD */}
              {/* <Box
                  data-cy="request-additional-paid-time-button"
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  onClick={() => handleSelect(ShiftOverViewOptions.ADDITIONAL_PAID_TIME)}
                  sx={(theme) => ({
                    py: theme.spacing(2),
                  })}
                >
                  <Box display="flex">
                    <Box
                      sx={(theme) => ({
                        '& svg': {
                          '& path': {
                            stroke: theme.palette.gray[400],
                          },
                        },
                      })}
                    >
                      <BankNoteIcon24 />
                    </Box>
                    <Box
                      sx={(theme) => ({
                        ml: theme.spacing(2),
                        color: theme.palette.gray[700],
                        typography: 'bodyLMedium',
                      })}
                    >
                      Request additional paid time
                    </Box>
                  </Box>
                  <Box sx={(theme) => ({ color: theme.palette.gray[400] })}>
                    <ChevronRightIcon24 />
                  </Box>
                </Box> */}
            </>
          </Box>
        </>
      )}
    </Box>
  );
};
