import { useDocumentTitle, CAPABILITIES } from '@stationwise/component-module';
import { AppPageLayout } from '../../AppPageLayout';
import { EmployeesTab } from '../EmployeesTab';
import { WorkGroupsPage } from './components/WorkGroupsPage';

export const WorkGroups = () => {
  useDocumentTitle('Work Groups');

  return (
    <AppPageLayout
      sx={(theme) => ({ background: theme.palette.common.white })}
      requiredCapability={CAPABILITIES.VIEW_EMPLOYEES_PAGE}
    >
      <EmployeesTab defaultTab="workGroups">
        <WorkGroupsPage />
      </EmployeesTab>
    </AppPageLayout>
  );
};
