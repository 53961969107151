import { Dialog, DialogProps } from '@mui/material';
import { DialogXButton } from '@stationwise/component-module';
import { ShiftPlanAction } from '@stationwise/share-types';
import { useShiftPlanContext } from '../ShiftPlanContext';
import { ShiftPlanCloseDialogResetContextEffect } from './ShiftPlanCloseDialogResetContextEffect';

interface ShiftPlanDialogProps extends Omit<DialogProps, 'open'> {
  action: ShiftPlanAction;
  open?: boolean;
}

export const ShiftPlanDialog = ({ action, children, ...props }: ShiftPlanDialogProps) => {
  const { selectedAction, cancelSelectedAction } = useShiftPlanContext();

  return (
    <Dialog open={action === selectedAction} onClose={cancelSelectedAction} {...props}>
      <ShiftPlanCloseDialogResetContextEffect />
      <DialogXButton onClose={cancelSelectedAction} />
      {children}
    </Dialog>
  );
};
