import { Box, DialogTitle, useTheme } from '@mui/material';
import { ReactNode } from 'react';
import { ExclamationMarkIcon24 } from '@stationwise/component-module';
import { IconCircle } from './IconCircle';

interface WarningMessageProps {
  primaryText: ReactNode;
  secondaryText?: ReactNode;
}

export const WarningMessage = (props: WarningMessageProps) => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        px: 3,
        pt: 3,
        textAlign: 'center',
        width: '495px',
        maxWidth: '100%',
        minHeight: '1px',
      }}
    >
      <IconCircle sx={{ color: theme.palette.orange[500], mb: 3 }}>
        <ExclamationMarkIcon24 />
      </IconCircle>
      <DialogTitle sx={{ px: 0, pt: 0, pb: props.secondaryText ? 2 : 0, typography: 'bodyXLSemibold' }}>
        {props.primaryText}
      </DialogTitle>
      {props.secondaryText}
    </Box>
  );
};
