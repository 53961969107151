import { Box } from '@mui/material';
import { ReactNode } from 'react';

interface StateCardProps {
  primaryText: string;
  secondaryText: string;
  children: ReactNode;
}
export const StateCard = ({ primaryText, secondaryText, children }: StateCardProps) => {
  return (
    <Box
      sx={{
        textAlign: 'center',
        mt: '10%',
      }}
    >
      {children}
      <Box sx={{ mt: 3, typography: 'heading4' }}>{primaryText}</Box>
      <Box
        sx={(theme) => ({
          color: theme.palette.gray[500],
          maxWidth: '286px',
          m: theme.spacing(2.5, 'auto', 0, 'auto'),
          typography: 'bodyMRegular',
        })}
      >
        {secondaryText}
      </Box>
    </Box>
  );
};
