import { Box } from '@mui/material';
import { ChevronLeftIcon16, ChevronRightIcon16 } from '@stationwise/component-module';

interface DateSelectorProps {
  title: string;
  prevHandle: () => void;
  nextHandle: () => void;
}

export const DateSelector = ({ title, prevHandle, nextHandle }: DateSelectorProps) => {
  return (
    <Box
      data-testid="calendar-date-selector"
      sx={{
        display: 'flex',
        alignItems: 'center',
        minWidth: 0,
        gap: 2,
      }}
    >
      <Box
        data-cy="prev-month-button"
        sx={(theme) => ({
          color: theme.palette.gray[400],
          cursor: 'pointer',
          display: 'flex',
          alignContent: 'center',
          '&:hover': {
            color: theme.palette.gray[700],
          },
        })}
      >
        <ChevronLeftIcon16 onClick={prevHandle} />
      </Box>

      <Box
        data-cy="month-selector"
        sx={{
          typography: 'bodyMMedium',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          minWidth: '0',
        }}
      >
        {title}
      </Box>
      <Box
        data-cy="next-month-button"
        sx={(theme) => ({
          color: theme.palette.gray[400],
          cursor: 'pointer',
          display: 'flex',
          alignContent: 'center',
          '&:hover': {
            color: theme.palette.gray[700],
          },
        })}
      >
        <ChevronRightIcon16 onClick={nextHandle} />
      </Box>
    </Box>
  );
};
