import { Box } from '@mui/material';
import { CardCertBadges, GenericDrawerOrModal, TeamBadge } from '@stationwise/component-module';
import { Employee } from '@stationwise/share-types';
import { shortenEmployeeName } from '@stationwise/share-utils';

interface ShiftTradeReceiversModalProps {
  showModal: boolean;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  employees: Employee[];
}

export const ShiftTradeReceiversModal = ({ showModal, setShowModal, employees }: ShiftTradeReceiversModalProps) => {
  return (
    <GenericDrawerOrModal
      anchor="bottom"
      drawerOpen={showModal}
      handleOnClose={() => {
        setShowModal(false);
      }}
      loading={false}
      showHeader={true}
      headerTitle={'Request Receivers'}
      disableFooter={true}
      noBorderOnHeader={false}
      dividerBorder={true}
      sxProps={{
        '& .MuiDialog-paper': {
          display: 'flex',
          alignItems: 'flex-start',
          alignSelf: 'stretch',
          flexDirection: 'column',
          borderBottom: '1px solid',
          borderColor: 'divider',
        },
      }}
    >
      <Box sx={(theme) => ({ px: theme.spacing(2) })}>
        <Box
          sx={(theme) => ({
            display: 'flex',
            height: theme.spacing(7),
            alignItems: 'flex-start',
            alignSelf: 'stretch',
            borderBottom: '1px solid',
            borderColor: 'divider',
          })}
        >
          <Box
            sx={(theme) => ({
              display: 'flex',
              alignItems: 'center',
              alignSelf: 'stretch',
              flex: '1 0 0',
              gap: theme.spacing(1.25),
              typography: 'body2',
              color: theme.palette.text.primary,
            })}
          >
            Name
          </Box>
          <Box
            sx={(theme) => ({
              display: 'flex',
              alignItems: 'center',
              alignSelf: 'stretch',
              flex: '1 0 0',
              gap: theme.spacing(1.25),
              typography: 'body2',
              color: theme.palette.text.primary,
            })}
          >
            Shift
          </Box>
          <Box
            sx={(theme) => ({
              display: 'flex',
              alignItems: 'flex-start',
              alignSelf: 'stretch',
              flex: '1 0 0',
              flexDirection: 'column',
              justifyContent: 'center',
              gap: theme.spacing(1.25),
              typography: 'body2',
              color: theme.palette.text.primary,
            })}
          >
            Certifications
          </Box>
        </Box>
        {employees.map((employee: Employee, index) => (
          <Box
            key={index}
            sx={(theme) => ({
              display: 'flex',
              height: theme.spacing(7),
              alignItems: 'flex-start',
              alignSelf: 'stretch',
              borderBottom: '1px solid',
              borderColor: 'divider',
            })}
          >
            <Box
              sx={(theme) => ({
                display: 'flex',
                alignItems: 'center',
                alignSelf: 'stretch',
                flex: '1 0 0',
                gap: theme.spacing(1.25),
                typography: 'body2',
              })}
            >
              {shortenEmployeeName(employee.name)}
            </Box>
            <Box
              sx={(theme) => ({
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'flex-start',
                alignSelf: 'stretch',
                flex: '1 0 0',
                gap: theme.spacing(1.25),
              })}
            >
              {employee.team ? <TeamBadge team={employee.team} /> : '-'}
            </Box>
            <Box
              sx={(theme) => ({
                display: 'flex',
                alignItems: 'center',
                alignSelf: 'stretch',
                flex: '1 0 0',
                gap: theme.spacing(0.5),
              })}
            >
              {employee.certifications ? <CardCertBadges certs={employee.certifications}></CardCertBadges> : '-'}
            </Box>
          </Box>
        ))}
      </Box>
    </GenericDrawerOrModal>
  );
};
