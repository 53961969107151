import { Box } from '@mui/material';
import { DEFAULT_RANK } from '@stationwise/share-types';
import { RankBadge } from '../Badge';
import { BlankCard } from '../BlankCard';
import { ActingAsIndicator } from './ActingAsIndicator';
import { CardProps } from './Card';
import { DriverIndicator } from './DriverIndicator';

type CardPropNames = 'rank' | 'nameLabel' | 'borderColor' | 'type' | 'isDriver' | 'actingAsRank' | 'indicators' | 'cardSxProps';

export const CollapsedCard = ({
  rank,
  nameLabel,
  borderColor = 'gray',
  type = 'default',
  isDriver = false,
  actingAsRank,
  indicators,
  cardSxProps,
}: Pick<CardProps, CardPropNames>) => {
  return (
    <BlankCard
      type={type}
      borderColor={borderColor}
      sxProps={[
        { display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 1, whiteSpace: 'nowrap' },
        ...(Array.isArray(cardSxProps) ? cardSxProps : cardSxProps ? [cardSxProps] : []),
      ]}
    >
      <Box sx={{ display: 'inline-flex', alignItems: 'center', gap: 0.5 }}>
        {isDriver && <DriverIndicator />}
        {actingAsRank && actingAsRank.id !== DEFAULT_RANK.id && <ActingAsIndicator rank={actingAsRank} />}
        {rank.id !== DEFAULT_RANK.id && <RankBadge rank={rank} />}
      </Box>
      <Box sx={(theme) => ({ flex: 1, minWidth: '1px', color: theme.palette.gray[900] })}>
        <Box
          sx={(theme) => ({
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            typography: 'bodyLMedium',
            [theme.breakpoints.up('sm')]: { typography: 'bodySMedium' },
          })}
        >
          {nameLabel}
        </Box>
      </Box>
      {indicators}
    </BlankCard>
  );
};
