import { EligibleEmployeeForHiringEngine, StaffingListItem, HiringEngineVacancy } from '@stationwise/share-types';
import { getMissingCertifications } from '@stationwise/shift-summary-helper';

interface MatchParams {
  staffingListItems: StaffingListItem[];
  vacancies: HiringEngineVacancy[];
  selectedTeamOptions: Set<string>;
  selectedBattalionOptions: Set<string>;
  rankOrders: Record<number, { rankId: number; isSelected: boolean }[]>;
}

export interface VacancyEligibilityDetailed {
  vacancyIndex: number;
  vacancy: HiringEngineVacancy;
  eligibleEmployees: EligibleEmployeeForHiringEngine[];
}

export function matchEmployeesToVacancies({
  staffingListItems,
  vacancies,
  selectedTeamOptions,
  selectedBattalionOptions,
  rankOrders,
}: MatchParams): VacancyEligibilityDetailed[] {
  const filteredStaffingListItems = staffingListItems.filter((item) => {
    if (selectedTeamOptions.size && !selectedTeamOptions.has(item.employee.team?.name || '')) return false;
    if (selectedBattalionOptions.size && !selectedBattalionOptions.has(item.employee.battalion?.name || '')) return false;
    return true;
  });

  return vacancies.map((vacancy, vIndex) => {
    const currentRankOrder = rankOrders[vacancy.rank.id];

    const eligibleWithCerts = filteredStaffingListItems.filter(
      (staffingListItem) =>
        getMissingCertifications(vacancy.certifications, staffingListItem.employee.certifications).length === 0,
    );

    const selectedRankIds = new Set(currentRankOrder.filter((rank) => rank.isSelected).map((rank) => rank.rankId));

    const sortedEligible = eligibleWithCerts
      .filter((staffingListItem) => selectedRankIds.has(staffingListItem.employee.rank.id))
      .map((staffingListItem, index) => ({
        staffingListItem,
        originalIndex: index,
        rankPosition: currentRankOrder.findIndex((r) => r.rankId === staffingListItem.employee.rank.id),
      }))
      .sort((a, b) => {
        if (a.rankPosition !== b.rankPosition) {
          return a.rankPosition - b.rankPosition;
        }
        return a.originalIndex - b.originalIndex;
      })
      .map(({ staffingListItem }) => ({
        id: staffingListItem.employee.id,
        name: staffingListItem.employee.name,
        rank: staffingListItem.employee.rank,
        certifications: staffingListItem.employee.certifications,
        plannedShiftDetails: [],
      }));

    return {
      vacancyIndex: vIndex,
      vacancy: vacancy,
      eligibleEmployees: sortedEligible,
    };
  });
}
