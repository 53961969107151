import { SxProps, Theme } from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import { DesktopTimePicker } from '@mui/x-date-pickers/DesktopTimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

export const TimePicker = ({
  sxProps,
  value,
  setValue,
  disabled,
  minTime,
}: {
  sxProps?: SxProps<Theme>;
  value: Date | null;
  setValue: (value: Date | null) => void;
  disabled?: boolean;
  minTime?: Date;
}) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DesktopTimePicker
        disabled={disabled}
        value={value}
        onChange={(value: Date | null) => setValue(value)}
        ampm={false}
        timeSteps={{ hours: 1, minutes: 15 }}
        minTime={minTime}
        slotProps={{
          inputAdornment: {
            position: 'start',
          },
        }}
        sx={[
          (theme) => ({
            width: '96px',
            '& .MuiInputBase-input': {
              pr: 0,
              color: theme.palette.gray[600],
              typography: 'bodyMRegular',
            },
            '& .MuiButtonBase-root:hover': {
              backgroundColor: 'inherit',
            },
            '& .MuiInputAdornment-root': {
              m: 0,
              '& svg': {
                height: '16px',
                width: '16px',
              },
            },
            '& .MuiSvgIcon-root': {
              color: theme.palette.gray[300],
            },
          }),
          ...(Array.isArray(sxProps) ? sxProps : sxProps ? [sxProps] : []),
        ]}
      />
    </LocalizationProvider>
  );
};
