import { Box, Tab, Tabs, useTheme } from '@mui/material';
import { captureException } from '@sentry/react';
import { useEffect, useState } from 'react';
import {
  useLoadedAuthUserContext,
  useLoadedDepartmentInfoContext,
  Mail01Icon16 as MailIcon,
  CheckCircleBrokenIcon16 as CheckIcon,
  useGetConversations,
  Loader,
  GenericDrawerOrModal,
  ErrorPage,
  InfiniteScroll,
} from '@stationwise/component-module';
import { client } from '@stationwise/share-api';
import { ConversationListView, ConversationCategory } from '@stationwise/share-types';
import { makeTestIdentifier, PUSHER_EVENT_TYPES, PUSHER_UPDATE_MESSAGE, RefreshEventCallback } from '@stationwise/share-utils';
import { Message } from './Message/';
import { MessageHeader } from './Message/MessageHeader';
import { MessageTopBar } from './Message/MessageTopBar';
import { Recipients } from './Message/Recipients';
import { SelectedConversation } from './Message/SelectedConversation';
import { CONVERSATION_CATEGORIES } from './Message/constants';
import { MessageComposer } from './MessageComposer';
import { NoMessages } from './NoMessages';

const conversationCategories: ConversationCategory[] = [
  { name: 'All', id: '0' },
  { name: 'General', id: '1' },
  { name: 'To-Do', id: '2' },
];

const getCategoryIcon = (category: string) => {
  switch (category) {
    case CONVERSATION_CATEGORIES.GENERAL:
      return <MailIcon />;
    case CONVERSATION_CATEGORIES.TO_DO:
      return <CheckIcon />;
    default:
      return;
  }
};

interface MessagesPanelProps {
  isCreatingMessage: boolean;
  setIsCreatingMessage: (value: boolean) => void;
  canSendMessages: boolean;
}

export const MessagesPanel = ({ isCreatingMessage, setIsCreatingMessage, canSendMessages }: MessagesPanelProps) => {
  const theme = useTheme();
  const [selectedCategory, setSelectedCategory] = useState(0);
  const [selectedConversation, setSelectedConversation] = useState<ConversationListView | null>(null);
  const [showRecipients, setShowRecipients] = useState(false);
  const { state: authUserState, dispatch: dispatchAuthUserState } = useLoadedAuthUserContext();
  const { state: departmentInfoState } = useLoadedDepartmentInfoContext();
  const refreshTriggerChannel = departmentInfoState.refreshTriggerChannel;

  const {
    data: conversations,
    isLoading,
    isError,
    forceRefetch,
    loadMore,
    hasNextPage,
    searchConversation,
  } = useGetConversations();

  useEffect(() => {
    if (!refreshTriggerChannel) return;

    const handlePusherUpdate: RefreshEventCallback = (data) => {
      if (data.triggerAll) {
        forceRefetch();
      } else if (data.message === PUSHER_UPDATE_MESSAGE) {
        if (data.employeeIdList.some((id) => `${id}` === authUserState.employee.id)) {
          forceRefetch();
        }
      }
    };

    const EVENT_TYPES_LISTENED = [PUSHER_EVENT_TYPES.MESSAGE, PUSHER_EVENT_TYPES.REVIEW];

    refreshTriggerChannel.bind_many(EVENT_TYPES_LISTENED, handlePusherUpdate);

    return () => {
      if (refreshTriggerChannel) {
        refreshTriggerChannel.unbind_many(EVENT_TYPES_LISTENED);
      }
    };
  }, [authUserState.employee.id, forceRefetch, refreshTriggerChannel]);

  const handleClose = () => {
    setShowRecipients(false);
  };

  const handleChange = (_event: React.SyntheticEvent, newValue: number) => {
    setSelectedCategory(newValue);
    searchConversation('', conversationCategories[newValue].name);
  };
  // toggleRead is true when we are reading/unreading a message
  // markAsCompleted is true when we are marking as completed a TO-DO message
  const updateConversation = async (conversationId: number, toggleRead: boolean, markAsCompleted: boolean) => {
    const conversationToUpdate = conversations.find((conversation) => conversation.id === conversationId);
    const recipientToUpdate = conversationToUpdate?.recipients.find((r) => `${r.employee.id}` === authUserState.employee.id);
    if (recipientToUpdate) {
      await updateConversationRecipient(recipientToUpdate.id, toggleRead, markAsCompleted);

      // updates unread message ids in context
      if (toggleRead) {
        const newUnreadMessageIds = new Set(authUserState.employee.unreadMessages);
        if (newUnreadMessageIds.has(conversationId)) {
          newUnreadMessageIds.delete(conversationId);
        } else {
          newUnreadMessageIds.add(conversationId);
        }
        dispatchAuthUserState({ type: 'SET_USER_UNREAD_MESSAGES.SUCCESS', payload: [...newUnreadMessageIds] });
      }
    }
  };

  const updateConversationRecipient = async (conversationRecipientId: number, toggleRead: boolean, markAsCompleted: boolean) => {
    try {
      await client.put(`conversation/conversation-recipient/${conversationRecipientId}/`, {
        toggleRead: toggleRead,
        markAsCompleted: markAsCompleted,
      });
    } catch (error) {
      captureException(error);
    }
  };

  const handleChevronRightClick = () => {
    setShowRecipients(true);
  };

  const selectConversation = (conversation: ConversationListView) => {
    setSelectedConversation(conversation);
  };

  const tabSxProps = {
    textTransform: 'none',
    fontFamily: 'Inter',
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '24px',
    textAlign: 'center',
    padding: '4px 12px',
    margin: '0px 4px',
    borderRadius: '28px',
    color: theme.palette.gray[900],
    backgroundColor: theme.palette.gray[100],
    mt: theme.spacing(2),
    mb: theme.spacing(2),
    minHeight: '24px',
    minWidth: '24px',
    '&.Mui-selected': {
      color: theme.palette.gray[100],
      backgroundColor: theme.palette.gray[900],
    },
  };

  return (
    <Box sx={(theme) => ({ background: theme.palette.common.white, flex: 1, position: 'relative' })}>
      {!isCreatingMessage && !selectedConversation && (
        <Box>
          <Tabs
            value={selectedCategory}
            onChange={handleChange}
            variant="scrollable"
            scrollButtons="auto"
            sx={{
              borderBottom: 'none',
              pl: theme.spacing(1.5),
              pr: theme.spacing(1.5),
              '& .MuiTabs-indicator': {
                backgroundColor: 'transparent',
              },
            }}
          >
            {conversationCategories.map((cat) => (
              <Tab
                key={cat.id}
                data-cy={`tab-${makeTestIdentifier(cat.name)}`}
                sx={tabSxProps}
                icon={getCategoryIcon(cat.name)}
                iconPosition="start"
                label={cat.name}
              />
            ))}
          </Tabs>
        </Box>
      )}
      {isError && <ErrorPage />}
      {!isCreatingMessage && isLoading && (
        <Box sx={{ mt: '200px' }}>
          <Loader />
        </Box>
      )}
      {/* //Inbox regular view */}
      {!isCreatingMessage && !selectedConversation && !isLoading && !isError && (
        <Box>
          {conversations.length === 0 ? (
            <NoMessages selectedCategory={conversationCategories[selectedCategory].name} />
          ) : (
            <InfiniteScroll
              onLoadMore={loadMore}
              loader={hasNextPage && <Loader sx={{ my: 2 }} />}
              sx={{
                overflowY: 'scroll',
                '&::-webkit-scrollbar': {
                  width: '6px',
                  ml: '16px',
                },
                '&::-webkit-scrollbar-track': {
                  background: 'transparent',
                },
                '&::-webkit-scrollbar-thumb': {
                  background: theme.palette.gray[300],
                  borderRadius: '6px',
                },
                '&::-webkit-scrollbar-thumb:hover': {
                  background: theme.palette.gray[400],
                },
              }}
              observerOptions={{ root: null }}
            >
              {conversations.map((conversation, index) => (
                <Box
                  key={`box_${conversation.category}_${conversation.id}`}
                  data-cy={`conversation-${index}`}
                  sx={(theme) => ({
                    borderBottom: `solid 1px ${theme.palette.gray[200]}`,
                    pb: '10px',
                    ml: theme.spacing(2),
                    mr: theme.spacing(2),
                  })}
                >
                  <Message
                    key={`${conversation.category}_${conversation.id}`}
                    conversation={conversation}
                    updateConversation={updateConversation}
                    selectConversation={selectConversation}
                    refetchConversations={forceRefetch}
                  />
                </Box>
              ))}
            </InfiniteScroll>
          )}
        </Box>
      )}
      {/* Selected message */}
      {!isCreatingMessage && selectedConversation && (
        <>
          <MessageTopBar
            selectedConversation={selectedConversation}
            setSelectedConversation={setSelectedConversation}
            updateConversation={updateConversation}
            refetchConversations={forceRefetch}
          />
          <MessageHeader selectedConversation={selectedConversation} handleChevronRightClick={handleChevronRightClick} />
          <SelectedConversation selectedConversation={selectedConversation} updateConversation={updateConversation} />

          <GenericDrawerOrModal
            anchor="bottom"
            drawerOpen={showRecipients}
            handleOnClose={handleClose}
            loading={false}
            showHeader={true}
            disableFooter={true}
            headerTitle={'Recipients'}
            sxProps={() => ({ '& .MuiDrawer-paper': { height: '100%' } })}
          >
            <Box sx={{ mb: theme.spacing(2) }}>
              <Recipients recipients={selectedConversation.recipients} />
            </Box>
          </GenericDrawerOrModal>
        </>
      )}

      {isCreatingMessage && canSendMessages && (
        <MessageComposer refetchConversations={forceRefetch} setIsCreatingMessage={setIsCreatingMessage} />
      )}
    </Box>
  );
};
