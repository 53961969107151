import { Box, Typography, Divider } from '@mui/material';
import { DialogXButton, AutoHireInfoAlert, Modal, RankBadge, Button } from '@stationwise/component-module';
import { HiringEngineSettingsInfo, Rank } from '@stationwise/share-types';
import { HiringEngineCallOrders } from '../DepartmentSettings/components/HiringEngineCallOrders';

interface DayRankCallOrderModalProps {
  open: boolean;
  onClose: () => void;
  hiringEngineSettingsInfo: HiringEngineSettingsInfo;
  setHiringEngineSettingsInfo: React.Dispatch<React.SetStateAction<HiringEngineSettingsInfo | null>>;
  selectedVacancyRank: Rank;
}

export const DayRankCallOrderModal = ({
  open,
  onClose,
  hiringEngineSettingsInfo,
  setHiringEngineSettingsInfo,
  selectedVacancyRank,
}: DayRankCallOrderModalProps) => {
  return (
    <Modal open={open} setOpen={() => onClose()} hideCloseIcon={true}>
      <Box
        sx={(theme) => ({
          backgroundColor: theme.palette.common.white,
          borderRadius: '16px',
          position: 'relative',
          width: '552px',
          maxHeight: '90vh',
          display: 'flex',
          flexDirection: 'column',
        })}
      >
        <Box
          sx={(theme) => ({
            padding: theme.spacing(1),
            display: 'flex',
            flexDirection: 'column',
            gap: theme.spacing(2),
            overflowY: 'auto',
          })}
        >
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
            <Box sx={{ typography: 'bodyXLSemibold' }}>Call Order of</Box>
            <RankBadge
              rank={selectedVacancyRank}
              rankField="name"
              sx={{
                typography: 'body1',
                borderRadius: '36px',
                padding: '8px 16px',
              }}
            />
          </Box>
          <DialogXButton onClose={onClose} />

          <AutoHireInfoAlert message="These settings are specific for this day." />
          <AutoHireInfoAlert message="Hiring Engine will notify your selected rank group in the order they are listed below." />
          <Typography variant="bodyMMedium">Rank call order</Typography>
          <Divider />

          <HiringEngineCallOrders
            hiringEngineSettingsInfo={hiringEngineSettingsInfo}
            selectedVacancyRank={selectedVacancyRank}
            setHiringEngineSettingsInfo={setHiringEngineSettingsInfo}
            dragIndicator
            width="500px"
          />
        </Box>
        <Box
          sx={(theme) => ({
            bottom: 0,
            backgroundColor: theme.palette.common.white,
            borderTop: `1px solid ${theme.palette.gray[100]}`,
            display: 'flex',
            justifyContent: 'flex-end',
            gap: 1,
            p: 3,
          })}
        >
          <Button variant="outlined" size="medium" onClick={() => onClose()} sx={{ height: '36px' }}>
            Cancel
          </Button>
          <Button variant="contained" size="medium" onClick={onClose} sx={{ height: '36px' }}>
            Save
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};
