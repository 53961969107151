import { Box, useTheme } from '@mui/material';
import { format } from 'date-fns';
import { Dispatch, SetStateAction } from 'react';
import { RankBadge } from '@stationwise/component-module';
import {
  ShiftPlanPublishPreview,
  ShiftPlanPublishPreviewEmployeeGroupItem,
  ShiftPlanPublishPreviewCancelledRequest,
} from '@stationwise/share-types';
import { shortenEmployeeName } from '@stationwise/share-utils';
import { useShiftPlanContext } from '../ShiftPlanContext';
import { ShiftPlanDialogActions, WarningMessage } from '../ShiftPlanDialog';

interface PublishPreviewDetailProps {
  publishPreview: ShiftPlanPublishPreview;
  publishEmployeeIds: Set<string>;
  setPublishStep: Dispatch<SetStateAction<number>>;
}

export const PublishPreviewDetail = ({ publishPreview, ...props }: PublishPreviewDetailProps) => {
  const theme = useTheme();
  const { publish } = useShiftPlanContext();

  const onCancel = () => props.setPublishStep((prevStep) => prevStep - 1);

  const onSave = () => publish({ employeeIds: Array.from(props.publishEmployeeIds) });

  const renderCancelledRequests = (title: string, requests: ShiftPlanPublishPreviewCancelledRequest[], hideReason?: boolean) => {
    if (!requests.length) {
      return null;
    }

    return (
      <Box sx={{ pt: 1, pl: 1.5 }}>
        <Box sx={{ typography: 'bodySSemibold' }}>{title}</Box>
        <ul>
          {requests.map((request) => (
            <li key={request.id}>
              {format(request.startDateTime, 'MM/dd/yyyy')} {format(request.startDateTime, 'HH:mm')}
              {' - '}
              {format(request.endDateTime, 'HH:mm')}
              <br />
              {request.senderName !== undefined && request.receiverName !== undefined && (
                <>
                  {`${request.senderName} <- ${request.receiverName}`}
                  <br />
                </>
              )}
              {request.stationName !== undefined && request.apparatusName !== undefined
                ? `${request.stationName} / ${request.apparatusName}`
                : 'Excess capacity or floater'}
              {request.reason && !hideReason && (
                <>
                  <br />
                  {request.reason}
                </>
              )}
            </li>
          ))}
        </ul>
      </Box>
    );
  };

  const renderEmployeeGroup = (title: string, group: ShiftPlanPublishPreviewEmployeeGroupItem[]) => {
    if (!group.length) {
      return null;
    }

    // Hide the reason because it's redundant. E.g. we don't want to display "X is unassigned" for people in the Unassigned group.
    const hideReason = group === publishPreview.unassignedEmployeeGroup || group === publishPreview.diffDatesEmployeeGroup;

    return (
      <>
        <Box sx={{ typography: 'bodyMSemibold', pt: 3 }}>{title}</Box>
        {group.map((item) => (
          <Box key={item.employee.id}>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, pt: 1 }}>
              <Box sx={{ typography: 'bodyMMedium' }}>{shortenEmployeeName(item.employee.name)}</Box>
              <RankBadge rank={item.employee.rank} />
            </Box>
            {renderCancelledRequests('Time-offs to be cancelled:', item.cancelledTimeOffs, hideReason)}
            {renderCancelledRequests('Shift trades to be cancelled:', item.cancelledShiftTrades, hideReason)}
          </Box>
        ))}
      </>
    );
  };

  const isEmpty = Object.values(publishPreview).every((employees) => !employees.length);

  const detail = (
    <Box
      sx={{
        border: `1px solid ${theme.palette.divider}`,
        borderRadius: 1,
        background: theme.palette.gray[50],
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        minHeight: '1px',
        overflow: 'hidden',
        width: '100%',
      }}
    >
      {isEmpty && (
        <Box sx={{ px: 1.5, py: 4, textAlign: 'center', typography: 'bodyMSemibold' }}>No employees will be affected.</Box>
      )}
      {!isEmpty && (
        <Box
          sx={{
            flex: 1,
            minHeight: '1px',
            overflowY: 'auto',
            p: 1.5,
            textAlign: 'left',
            '& ul': { listStyleType: 'disc', m: 0, p: theme.spacing(0, 0, 0, 2) },
            '& li': { m: theme.spacing(0.5, 0, 0, 0), typography: 'bodySRegular' },
          }}
        >
          <Box>The following employees will be affected.</Box>
          {renderEmployeeGroup('Employees completely unassigned:', publishPreview.unassignedEmployeeGroup)}
          {renderEmployeeGroup('Employees with changed assigned dates:', publishPreview.diffDatesEmployeeGroup)}
          {renderEmployeeGroup('Employees with unchanged assigned dates:', publishPreview.sameDatesEmployeeGroup)}
          {renderEmployeeGroup('Indirectly affected employees:', publishPreview.indirectlyAffectedEmployeeGroup)}
        </Box>
      )}
    </Box>
  );

  return (
    <>
      <WarningMessage primaryText="Changes preview" secondaryText={detail} />
      <ShiftPlanDialogActions cancelText="Back" saveText="Publish" onCancel={onCancel} onSave={onSave} />
    </>
  );
};
