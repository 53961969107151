import { EventInput } from '@fullcalendar/core';
import { EventImpl } from '@fullcalendar/core/internal';
import { Box } from '@mui/material';
import { format } from 'date-fns';
import React from 'react';
import { theme } from '@stationwise/component-module';
import { EventCardLine } from './EventCardLine';
import { EventCardTitleSection } from './EventCardTitleSection';
import { findDurationAsHourAndMinute, processTitle } from './EventCardUtils';
import { EventCardWrapper } from './EventCardWrapper';

export const KellyDayCard = ({ shift }: { shift: EventInput | EventImpl; children?: React.ReactNode }) => {
  const { hour: durationHour, minute: durationMin } = findDurationAsHourAndMinute(shift);

  return (
    <EventCardWrapper>
      <EventCardTitleSection
        title={processTitle(shift, false)}
        backgroundColor={theme.palette.gray[500]}
        titleColor={theme.palette.common.white}
        truncate
      />

      <Box
        sx={(theme) => ({
          display: 'flex',
          px: theme.spacing(2),
          flexDirection: 'column',
          alignItems: 'flex-start',
          alignSelf: 'stretch',
          '&:last-child': {
            borderBottom: 'none',
          },
        })}
      >
        <EventCardLine
          title={'Start Date'}
          value={shift.start ? format(shift.start as Date, 'MMM d, HH:mm') : '-'}
        ></EventCardLine>
        <EventCardLine title={'End Date'} value={shift.end ? format(shift.end as Date, 'MMM d, HH:mm') : '-'}></EventCardLine>

        <EventCardLine
          title={'Duration'}
          value={shift.end && shift.start ? durationHour + ' hrs ' + durationMin + ' mins' : '-'}
          isLastLine={true}
        ></EventCardLine>
      </Box>
    </EventCardWrapper>
  );
};
