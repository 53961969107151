import { Box, useTheme } from '@mui/material';
import { format, setMinutes } from 'date-fns';
import { TeamBadge } from '@stationwise/component-module';
import { ShiftPlanAssignment } from '@stationwise/share-types';
import { compareEndDates } from '@stationwise/share-utils';
import { formatAssignment } from '../../../helpers/readAssignments';
import { WarningMessage } from '../../ShiftPlanDialog';

interface UpsertAssignmentConflictsMessageProps {
  startDate: Date;
  endDate: Date | null;
  conflictingAssignments: ShiftPlanAssignment[];
}

export const UpsertAssignmentConflictsMessage = ({ startDate, endDate, ...props }: UpsertAssignmentConflictsMessageProps) => {
  const theme = useTheme();
  const willEndDateString = format(setMinutes(startDate, startDate.getMinutes() - 24 * 60), 'yyyy-MM-dd');
  const willStartDateString = endDate && format(setMinutes(endDate, endDate.getMinutes() + 24 * 60), 'yyyy-MM-dd');

  const willEndAssignments = props.conflictingAssignments.filter((assignment) => assignment.activationDate < startDate);
  const willRemoveAssignments = props.conflictingAssignments.filter(
    (assignment) => assignment.activationDate >= startDate && compareEndDates(assignment.deactivationDate, endDate) <= 0,
  );
  const willStartAssignments = props.conflictingAssignments.filter(
    (assignment) => compareEndDates(assignment.deactivationDate, endDate) > 0,
  );

  const renderAssignment = (assignment: ShiftPlanAssignment, assignmentIndex: number) => {
    const assignmentTexts = formatAssignment(assignment);

    return (
      <li key={assignmentIndex}>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, typography: 'bodySRegular' }}>
          <TeamBadge team={assignment.team} />
          {`${assignmentTexts.location}, ${assignmentTexts.startTime} - ${assignmentTexts.endTime}`}
        </Box>
      </li>
    );
  };

  const renderSection = (title: string, sectionAssignments: ShiftPlanAssignment[]) => {
    if (!sectionAssignments.length) {
      return null;
    }

    return (
      <Box
        sx={{
          border: `1px solid ${theme.palette.divider}`,
          background: theme.palette.gray[50],
          p: 1.5,
          mt: 1.5,
          textAlign: 'left',
          width: '100%',
          '& ul': { listStyleType: 'disc', m: 0, p: theme.spacing(0, 0, 0, 2) },
          '& li': { m: theme.spacing(0.5, 0, 0, 0) },
        }}
      >
        <Box sx={{ typography: 'bodyMSemibold' }}>{title}</Box>
        <ul>{sectionAssignments.map(renderAssignment)}</ul>
      </Box>
    );
  };

  return (
    <WarningMessage
      primaryText="Confirm changes."
      secondaryText={
        <>
          These assignments will change to avoid overlap with the new assignment.
          {renderSection(`These assignments will end ${willEndDateString}`, willEndAssignments)}
          {renderSection('These assignments will be removed', willRemoveAssignments)}
          {renderSection(`These assignments will start ${willStartDateString}`, willStartAssignments)}
        </>
      }
    />
  );
};
