import { Box } from '@mui/material';
import { XCloseIcon20, SelectCategory, SelectCategoryItem } from '@stationwise/component-module';
import { SubMenu } from './SubMenu';

interface DrawerHeaderProps {
  categories: SelectCategoryItem[];
  category: string;
  setCategory: (category: string) => void;
  handleOnCloseDrawer: () => void;
  onCancelConversation?: (id: number) => void;
  conversationId?: number;
  subject: string;
  isCancelled: boolean;
}
export const DrawerHeader = ({
  categories,
  category,
  setCategory,
  handleOnCloseDrawer,
  onCancelConversation,
  subject,
  conversationId,
  isCancelled,
}: DrawerHeaderProps) => {
  return (
    <Box
      display="flex"
      justifyContent="space-between"
      sx={(theme) => ({
        pl: '16px',
        pt: '22px',
        height: '68px',
        borderBottom: `1px solid ${theme.palette.gray[100]}`,
      })}
    >
      <Box
        sx={{
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          maxWidth: '270px',
          typography: 'bodyLSemiBold',
        }}
      >
        {subject}
      </Box>

      <Box sx={{ mt: '2px', position: 'absolute', right: '65px' }}>
        <SelectCategory
          items={categories}
          onChange={(selectedOption: string) => setCategory(selectedOption)}
          selected={category}
          isEditable={!conversationId}
        />
      </Box>
      {!conversationId && (
        <Box
          onClick={handleOnCloseDrawer}
          sx={(theme) => ({
            width: '40px',
            height: '40px',
            borderRadius: '20px',
            border: `1px solid ${theme.palette.gray[200]}`,
            color: theme.palette.gray[500],
            textAlign: 'center',
            p: '9px',
            mr: '12px',
            mt: '-5px',
            '&:hover': {
              cursor: 'pointer',
            },
          })}
        >
          <XCloseIcon20 />
        </Box>
      )}
      {!!conversationId && !isCancelled && (
        <SubMenu conversationId={conversationId} onCancelConversation={onCancelConversation} />
      )}
    </Box>
  );
};
