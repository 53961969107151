import { useMediaQuery, Theme } from '@mui/material';
import { useEffect, useState } from 'react';
import { matchPath, useLocation } from 'react-router-dom';
import { INavMenu, navigationItems } from '../../app/AppPageLayout';
import { MenuIconTypes } from '../Navigation/SideBar/MenuIcon';

export function useSelectMenu() {
  const location = useLocation();
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
  const [selectedMenu, setSelectedMenu] = useState<MenuIconTypes | null>(navigationItems[0].type);

  useEffect(() => {
    let isAnyMatching = false;

    navigationItems.forEach((navItem: INavMenu) => {
      const match = navItem.match.some((path) => {
        return matchPath(path, location.pathname);
      });

      if (match) {
        isAnyMatching = true;
        setSelectedMenu(navItem.type);
      }
    });

    if (!isAnyMatching) {
      setSelectedMenu(null);
    }
  }, [location.pathname]);

  const finalSelectedMenu =
    isMobile && selectedMenu === 'you' ? (location.pathname === '/app/calendar' ? 'calendar' : 'my-dashboard') : selectedMenu;

  return {
    selectedMenu: finalSelectedMenu,
  };
}
