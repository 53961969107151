import { Box, Typography } from '@mui/material';

export const EventCardLine = ({
  title,
  value,
  isLastLine,
}: {
  title: string;
  value: string | React.ReactNode;
  isLastLine?: boolean;
}) => {
  return (
    <Box
      sx={(theme) => ({
        display: 'flex',
        alignItems: 'flex-end',
        alignSelf: 'stretch',
        borderBottom: isLastLine ? 'none' : `1px solid ${theme.palette.divider}`,
      })}
    >
      <Box
        sx={(theme) => ({
          display: 'flex',
          py: theme.spacing(1.5),
          alignItems: 'flexStart',
          flex: '1 0 0',
          alignSelf: 'stretch',
        })}
      >
        <Typography sx={{ typography: 'body2', color: 'text.secondary' }}>{title}</Typography>
      </Box>
      <Box
        sx={(theme) => ({
          display: 'flex',
          py: theme.spacing(1.5),
          alignItems: 'center',
          flex: '1 0 0',
          gap: theme.spacing(1.25),
          overflowWrap: 'anywhere',
          pr: theme.spacing(1),
        })}
      >
        <Typography sx={{ typography: 'body2', color: 'text.primary' }}>{value}</Typography>
      </Box>
    </Box>
  );
};
