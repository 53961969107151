import { Box, Typography } from '@mui/material';
import { format, parseISO } from 'date-fns';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { LetterAvatar, useAuthUserContext, sanitizeMessage } from '@stationwise/component-module';
import { ConversationListView } from '@stationwise/share-types';
import { FixedBoxAtBottom } from './FixedBoxAtBottom';
import { CONVERSATION_CATEGORIES } from './constants';

export const SelectedConversation = ({
  selectedConversation,
  updateConversation,
}: {
  selectedConversation: ConversationListView;
  updateConversation: (conversationId: number, toggleRead: boolean, markAsCompleted: boolean) => void;
}) => {
  const { state } = useAuthUserContext();
  const { employee } = state;
  const navigate = useNavigate();

  const conversationRecipient = selectedConversation.recipients.find(
    (recipient) => recipient.employee.id.toString() === employee?.id,
  );

  const [completed, setCompleted] = useState<boolean>(() => conversationRecipient?.isCompleted || false);
  const [completedAt, setCompletedAt] = useState<Date | null>(() => conversationRecipient?.completedAt || null);

  const markAsCompleted = () => {
    updateConversation(selectedConversation.id, false, true);
    setCompleted(true);
    setCompletedAt(new Date());
  };

  const handleMessageClick = (e: React.MouseEvent) => {
    const target = e.target as HTMLElement;
    const link = target.closest('a');

    if (link?.dataset.internalLink === 'true') {
      e.preventDefault();
      navigate(link.getAttribute('href') || '/');
    }
  };

  return (
    <>
      <Box sx={(theme) => ({ pb: completed ? '72px' : theme.spacing(2) })}>
        {selectedConversation.messages.map((message, index) => (
          <Box key={`${message.sender.id}_${index}`} sx={(theme) => ({ margin: theme.spacing(2), display: 'flex' })}>
            <Box sx={(theme) => ({ width: '50px', mr: theme.spacing(2) })}>
              <LetterAvatar firstName={message.sender.firstName} color={message.sender.team?.color ?? 'gray'} />
            </Box>

            <Box
              sx={(theme) => ({
                width: '100%',
                padding: theme.spacing(2),
                backgroundColor: theme.palette.gray[100],
                borderRadius: '16px',
              })}
            >
              <Box sx={(theme) => ({ display: 'flex', justifyContent: 'space-between', mb: theme.spacing(2) })}>
                <Box>
                  <Typography variant="bodyMSemibold">{`${message.sender.firstName} ${message.sender.lastName}`}</Typography>
                </Box>

                <Box>
                  <Typography sx={(theme) => ({ color: theme.palette.gray[400] })} variant="bodyMRegular">
                    {`${format(parseISO(message.sentAt), 'MMM dd')} at ${format(parseISO(message.sentAt), 'HHmm')}`}
                  </Typography>
                </Box>
              </Box>

              <Box>
                <Box
                  data-cy="message-body"
                  onClick={handleMessageClick}
                  sx={{ variant: 'bodyMRegular' }}
                  dangerouslySetInnerHTML={{
                    __html: sanitizeMessage(message.body),
                  }}
                />
              </Box>
            </Box>
          </Box>
        ))}
      </Box>
      {selectedConversation.category === CONVERSATION_CATEGORIES.TO_DO && (
        <FixedBoxAtBottom markAsCompleted={markAsCompleted} isCompleted={completed} completedAt={completedAt} />
      )}
    </>
  );
};
