import { Box, useMediaQuery, Theme } from '@mui/material';
import { format, isAfter, parseISO } from 'date-fns';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { asDepartmentDateTime, ErrorPage, Modal, theme, useLoadedDepartmentInfoContext } from '@stationwise/component-module';
import { client, isAxiosError } from '@stationwise/share-api';
import { HiringEngineVacancy } from '@stationwise/share-types';
import { ROUTES } from '../../../../../core/Routes';
import { getFormattedTimes } from '../../AutoHire/utils';
import { AutoHireSignUpView } from '../types';
import { SignUpModalContent } from './SignUpModalContent';
import { AutoHireSignUpTopBar } from './TopBar';
import { VacanciesInfo } from './VacanciesInfo';

interface AutoHireSignUpContentProps {
  autoHireId: string;
  data: AutoHireSignUpView;
  forceRefetch: () => void;
}

export const AutoHireSignUpContent = ({ autoHireId, data, forceRefetch }: AutoHireSignUpContentProps) => {
  const [applyBorderStyles, setApplyBorderStyles] = useState(false);
  const [vacantPosition, setVacantPosition] = useState<HiringEngineVacancy>(data.autoHireVacancies[0]);
  const [openModal, setModalOpen] = useState(false);
  const [isError, setIsError] = useState(false);
  const navigate = useNavigate();
  const { state: departmentContext } = useLoadedDepartmentInfoContext();

  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

  const onPressVacancy = (autoHireVacany: HiringEngineVacancy) => {
    setVacantPosition(autoHireVacany);
    setModalOpen(true);
  };

  const handleScroll = () => {
    setApplyBorderStyles(window.scrollY > 0);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  let startTimeFormatted = '';
  let endTimeFormatted = '';
  let difference = '';
  let hasStarted = false;

  if (vacantPosition) {
    const now = asDepartmentDateTime(departmentContext, new Date());

    if (isAfter(now, vacantPosition.startDateTime)) {
      hasStarted = true;
    }
    const formattedTimes = getFormattedTimes(vacantPosition);
    startTimeFormatted = formattedTimes.startTimeFormatted;
    endTimeFormatted = formattedTimes.endTimeFormatted;

    if (hasStarted) {
      const remainingVacancy: HiringEngineVacancy = {
        ...vacantPosition,
        startDateTime: now,
      };
      const remainingTimes = getFormattedTimes(remainingVacancy);
      const hoursPart = remainingTimes.hoursDifference ? `${Math.floor(remainingTimes.hoursDifference)}hrs` : '';
      const minutesPart = remainingTimes.minutesDifference ? `${Math.floor(remainingTimes.minutesDifference)}min` : '';
      difference = `${hoursPart}${hoursPart && minutesPart ? ' ' : ''}${minutesPart}`;
    } else {
      const hoursPart = formattedTimes.hoursDifference ? `${Math.floor(formattedTimes.hoursDifference)}hrs` : '';
      const minutesPart = formattedTimes.minutesDifference ? `${Math.floor(formattedTimes.minutesDifference)}min` : '';
      difference = `${hoursPart}${hoursPart && minutesPart ? ' ' : ''}${minutesPart}`;
    }
  }

  const onclickSignUp = async () => {
    if (!vacantPosition) {
      return;
    }

    try {
      const params = {
        autoHireId: autoHireId,
        positionId: vacantPosition.id,
        startTime: vacantPosition.startDateTime,
        endTime: vacantPosition.endDateTime,
      };
      await client.post('/auto-hire/sign-up/', params);
      forceRefetch();
      navigate(`${ROUTES.PERSONAL_ROSTER.fullRoute}/?date=${format(parseISO(data.autoHire.shiftDate), 'yyyy-MM-dd')}`);
    } catch (error) {
      isAxiosError(error);
      setIsError(true);
    } finally {
      setModalOpen(false);
    }
  };

  const onclickCancel = () => {
    setModalOpen(false);
  };

  if (isError) {
    return <ErrorPage />;
  }

  return (
    <Box
      sx={{
        width: '100%',
        minHeight: '100%',
        display: 'flex',
        position: 'relative',
        flexDirection: 'column',
        alignItems: 'center',
        backgroundColor: theme.palette.gray[100],
      }}
    >
      <Box
        sx={{
          display: 'flex',
          width: '100%',
          height: '70px',
        }}
      >
        <AutoHireSignUpTopBar
          shiftName={data.autoHire.teamName}
          shiftColor={data.autoHire.shiftColor}
          applyBorderStyles={applyBorderStyles}
        />
      </Box>
      <Box
        sx={{
          display: 'flex',
          width: '100%',
          height: '1px',
          background: `linear-gradient(to right, ${theme.palette.common.white} 0%, ${theme.palette.common.white} 4%, ${theme.palette.gray[100]} 4%, ${theme.palette.gray[100]} 96%, ${theme.palette.common.white} 96%, ${theme.palette.common.white} 100%)`,
        }}
      />
      <Box
        sx={{
          flex: 1,
          display: 'flex',
          width: '100%',
          alignItems: 'center',
          flexDirection: 'column',
          minHeight: '1px',
          overflowY: 'auto',
        }}
      >
        <VacanciesInfo
          numberOfVacancies={data.autoHireVacancies.length}
          vacancies={data.autoHireVacancies}
          shiftDate={data.autoHire.shiftDate}
          onPressVacancy={onPressVacancy}
        />
      </Box>
      <Modal
        open={openModal}
        setOpen={setModalOpen}
        hideCloseIcon={false}
        margin={theme.spacing(2)}
        minWidth={isMobile ? '90%' : undefined}
      >
        <SignUpModalContent
          vacantPosition={vacantPosition}
          shiftDate={data.autoHire.shiftDate}
          startTimeFormatted={startTimeFormatted}
          endTimeFormatted={endTimeFormatted}
          difference={difference}
          hasStarted={hasStarted}
          onClickSignUp={onclickSignUp}
          onClickCancel={onclickCancel}
        />
      </Modal>
    </Box>
  );
};
