import { Box } from '@mui/material';
import { EmptyMailbox } from '@stationwise/component-module';

export const NoMessages = ({ selectedCategory }: { selectedCategory: string }) => {
  return (
    <Box
      sx={{
        mt: '20%',
        textAlign: 'center',
        '& svg': { width: '120px', height: '120px' },
      }}
    >
      <EmptyMailbox />
      <Box sx={{ mt: 3, typography: 'bodyXXLSemibold' }}>
        <Box>{selectedCategory === 'All' ? 'You have no messages' : `You have no ${selectedCategory} messages`}</Box>
      </Box>
      <Box
        sx={(theme) => ({
          color: theme.palette.gray[500],
          maxWidth: '300px',
          m: theme.spacing(2, 'auto', 0, 'auto'),
          typography: 'bodyMRegular',
        })}
      >
        Nothing here yet, but your inbox is ready and waiting!
      </Box>
    </Box>
  );
};
