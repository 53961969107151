import { Theme } from '@mui/material';
import { PayrollCode } from '@stationwise/share-types';

export interface AvailableCompTime {
  timeEntryId: number | null;
  overtimeDate: string;
  overtimeHours: number;
  overtimeCode: string;
  convertRatio: number;
}

export interface CompTimeRequestDto {
  overtimeDate: Date;
  overtimeCode: string;
  requestedHours: number;
  timeEntryId: number | null;
}

export const getColorForPayCodeType = (payCodeType: PayrollCode['payCodeType'], theme: Theme) => {
  switch (payCodeType) {
    case 'OVERTIME':
      return theme.palette.purple[500];
    case 'REGULAR':
      return theme.palette.blue[500];
    case 'COMP TIME':
      return theme.palette.teal[500];
    default:
      return theme.palette.gray[400];
  }
};

// TODO : Move the following to department settings.
export const compTimeDurationOptions: { label: string; value: number }[] = [
  { label: '0 hrs', value: 0 },
  { label: '12 hrs', value: 12 },
  { label: '24 hrs', value: 24 },
  { label: '36 hrs', value: 36 },
];
