import { ShiftPlanAction } from '@stationwise/share-types';
import { useShiftPlanContext } from '../ShiftPlanContext';
import { ShiftPlanDialog, ShiftPlanDialogActions, WarningMessage } from '../ShiftPlanDialog';
import { MoveApparatusForm } from './MoveApparatusForm';
import { RemoveStructWarning } from './RemoveStructWarning';
import { ToggleApparatusModeWarning } from './ToggleApparatusModeWarning';
import { UpsertApparatusForm } from './UpsertApparatusForm';
import { UpsertPositionForm } from './UpsertPositionForm';
import { UpsertStationForm } from './UpsertStationForm';

export const StructDialogs = () => {
  const { setSelectedAction } = useShiftPlanContext();

  const onConfirmEditPosition = () => {
    setSelectedAction(ShiftPlanAction.UPSERT_POSITION);
  };

  return (
    <>
      <ShiftPlanDialog action={ShiftPlanAction.UPSERT_STATION}>
        <UpsertStationForm />
      </ShiftPlanDialog>
      <ShiftPlanDialog action={ShiftPlanAction.REMOVE_STATION}>
        <RemoveStructWarning />
      </ShiftPlanDialog>
      <ShiftPlanDialog action={ShiftPlanAction.UPSERT_APPARATUS}>
        <UpsertApparatusForm />
      </ShiftPlanDialog>
      <ShiftPlanDialog action={ShiftPlanAction.TOGGLE_APPARATUS_MODE}>
        <ToggleApparatusModeWarning />
      </ShiftPlanDialog>
      <ShiftPlanDialog action={ShiftPlanAction.MOVE_APPARATUS}>
        <MoveApparatusForm />
      </ShiftPlanDialog>
      <ShiftPlanDialog action={ShiftPlanAction.REMOVE_APPARATUS}>
        <RemoveStructWarning />
      </ShiftPlanDialog>
      <ShiftPlanDialog action={ShiftPlanAction.UPSERT_POSITION}>
        <UpsertPositionForm />
      </ShiftPlanDialog>
      <ShiftPlanDialog action={ShiftPlanAction.WARN_EDIT_POSITION}>
        <WarningMessage
          primaryText="To edit a position it must be empty."
          secondaryText="All assigned employees of all teams will be moved to the floater station."
        />
        <ShiftPlanDialogActions saveText="Confirm" onSave={onConfirmEditPosition} />
      </ShiftPlanDialog>
      <ShiftPlanDialog action={ShiftPlanAction.REMOVE_POSITION}>
        <RemoveStructWarning />
      </ShiftPlanDialog>
    </>
  );
};
