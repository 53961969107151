import { Box, useTheme } from '@mui/material';
import { startOfMonth } from 'date-fns';
import { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';
import { asDepartmentDateTime, useLoadedDepartmentInfoContext } from '@stationwise/component-module';
import { ShiftPlanTeam } from '@stationwise/share-types';
import { MonthDays, MonthSelector, renderMonthDayOverlay, renderToday } from '../../Calendar';
import { BorderedBoxes } from '../../Grid';
import { AdjustPatternPriorityContentRow } from './AdjustPatternPriorityContentRow';
import { TeamGroupedShiftPlanAssignment } from './UpsertAssignmentForm';

interface AdjustPatternPriorityContentProps {
  teams: ShiftPlanTeam[];
  setTeamPriorities: Dispatch<SetStateAction<{ [index: number]: { priority: number; fullShift: boolean } }>>;
  futureTeamAssignments: TeamGroupedShiftPlanAssignment[];
  setFutureAssignmentsByTeam: Dispatch<SetStateAction<TeamGroupedShiftPlanAssignment[]>>;
}

export const AdjustPatternPriorityContent = ({
  teams,
  setTeamPriorities,
  futureTeamAssignments,
  setFutureAssignmentsByTeam,
}: AdjustPatternPriorityContentProps) => {
  const theme = useTheme();
  const { state: departmentInfoState } = useLoadedDepartmentInfoContext();
  const [selectedMonth, setSelectedMonth] = useState(startOfMonth(asDepartmentDateTime(departmentInfoState, new Date())));
  const dragItem = useRef<number | null>(null);
  const dragOverItem = useRef<number | null>(null);

  const handleSort = () => {
    const oldPrio = dragItem.current;
    const newPrio = dragOverItem.current;

    if (oldPrio === null || newPrio === null) {
      return;
    }

    const result = futureTeamAssignments.map((assignment) => ({ ...assignment }));
    // Find the item that needs to be moved
    const itemToMove = result.find((item) => item.priority === oldPrio);
    if (!itemToMove) {
      setFutureAssignmentsByTeam(result);
      return;
    }
    if (oldPrio < newPrio) {
      result.forEach((item) => {
        if (item.priority > oldPrio && item.priority <= newPrio) {
          item.priority--;
        }
      });
    } else if (oldPrio > newPrio) {
      result.forEach((item) => {
        if (item.priority >= newPrio && item.priority < oldPrio) {
          item.priority++;
        }
      });
    }
    itemToMove.priority = newPrio;
    setFutureAssignmentsByTeam(result.sort((a, b) => a.priority - b.priority));
  };

  const updateFullShiftForAssignment = (assignment: TeamGroupedShiftPlanAssignment, checked: boolean) => {
    const result = futureTeamAssignments.map((asnmn) => ({ ...asnmn }));
    for (const futureTeamAssignments of result) {
      if (futureTeamAssignments.teamId === assignment.teamId) {
        futureTeamAssignments.fullShift = checked;
        for (const oas of futureTeamAssignments.assignments) {
          oas.fullShift = checked;
        }
        break;
      }
    }
    setFutureAssignmentsByTeam(result);
  };

  useEffect(() => {
    const prios: { [index: number]: { priority: number; fullShift: boolean } } = {};
    for (const assignmentGroup of futureTeamAssignments) {
      prios[assignmentGroup.teamId] = { priority: assignmentGroup.priority, fullShift: assignmentGroup.fullShift };
    }
    setTeamPriorities(prios);
  }, [futureTeamAssignments, setTeamPriorities]);

  return (
    <Box
      sx={{
        background: theme.palette.common.white,
        position: 'sticky',
        top: 0,
        left: 0,
        width: '100%',
        mt: 2,
        pr: 3,
        minWidth: '1142px',
        overflow: 'hidden',
      }}
    >
      <BorderedBoxes
        sx={{
          borderTopLeftRadius: `${theme.shape.borderRadius}px`,
          borderTopRightRadius: `${theme.shape.borderRadius}px`,
        }}
      >
        <Box sx={{ flex: 1, px: 1.5, typography: 'bodySMedium' }}>Assignment</Box>
        <Box sx={{ justifyContent: 'center', px: 1.5, width: '700px', minWidth: '60%' }}>
          <MonthSelector value={selectedMonth} onChange={(newValue) => setSelectedMonth(newValue)} />
        </Box>
      </BorderedBoxes>
      <BorderedBoxes sx={{ borderBottom: 0 }}>
        <Box sx={{ flex: 2, px: 1, typography: 'bodySMedium' }}>Priority</Box>
        <Box sx={{ flex: 1.5, px: 1, typography: 'bodySMedium' }}>Full Shift</Box>
        <Box sx={{ flex: 4, px: 1.5, typography: 'bodySMedium' }}>Position</Box>
        <Box sx={{ flex: 2, px: 1.5, typography: 'bodySMedium' }}>Pattern</Box>
        <BorderedBoxes sx={{ border: 0, width: '700px', minWidth: '60%', zIndex: 1 }}>
          <MonthDays month={selectedMonth} renderOverlay={renderMonthDayOverlay} renderToday={renderToday} />
        </BorderedBoxes>
      </BorderedBoxes>
      <Box>
        {futureTeamAssignments.map((oabt, index) => (
          <AdjustPatternPriorityContentRow
            key={'future_assignment_key' + oabt.teamId}
            futureTeamAssignments={oabt}
            selectedMonth={selectedMonth}
            teams={teams}
            dragItem={dragItem}
            dragOverItem={dragOverItem}
            handleSort={handleSort}
            updateFullShiftForAssignment={updateFullShiftForAssignment}
            isLast={index === futureTeamAssignments.length - 1}
          />
        ))}
      </Box>
    </Box>
  );
};
