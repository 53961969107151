import { useEffect, useState } from 'react';
import { RosterEmployee } from '@stationwise/share-types';
import { updateBoardEmployeeNote } from '@stationwise/shift-summary-helper';
import { Pencil01Icon20 } from '../../../../../assets';
import { useLoadedAuthUserContext } from '../../../../Auth';
import { OverrideModal } from '../../../../Override';
import { useRosterContext } from '../../RosterContext';
import { notifyEmployeeNoteChanged } from './snackbarHelper';

interface EditNoteModalProps {
  employee: RosterEmployee;
  isOpen: boolean;
  onClose: () => void;
}

export const EditNoteModal = ({ employee, isOpen, onClose }: EditNoteModalProps) => {
  const { state: authUserState } = useLoadedAuthUserContext();
  const { shiftSummaryHelper, setShiftSummaryHelper, setUserHasMadeChanges } = useRosterContext();
  const [note, setNote] = useState(employee.noteOverride?.note || '');

  useEffect(() => {
    setNote(employee.noteOverride?.note || '');
  }, [employee]);

  const onCancel = () => {
    setNote(employee.noteOverride?.note || '');
    onClose();
  };

  const onApply = () => {
    if (!isOpen) return;
    setShiftSummaryHelper(updateBoardEmployeeNote(shiftSummaryHelper, note, employee.id, authUserState.employee));
    setUserHasMadeChanges(true);
    notifyEmployeeNoteChanged(employee, note);
    onClose();
  };

  return (
    <OverrideModal
      isOpen={isOpen}
      onApply={onApply}
      onCancel={onCancel}
      icon={<Pencil01Icon20 />}
      overrideNote={note}
      setOverrideNote={setNote}
      title={employee.noteOverride ? 'Edit note' : 'Add note'}
    />
  );
};
