import { Alert, Box } from '@mui/material';
import { captureException } from '@sentry/react';
import { Dispatch, useEffect, useState } from 'react';
import {
  FeedbackMessage,
  GenericDrawerOrModal,
  SnackbarService,
  useLoadedDepartmentInfoContext,
} from '@stationwise/component-module';
import { client } from '@stationwise/share-api';
import { CancelRequest } from '../CancelRequest';

interface CancelCommonRequestProps {
  handleOnClose: () => void;
  drawerOpen: boolean;
  setRefetchEvents: Dispatch<React.SetStateAction<boolean>>;
  url: string;
  title: string;
}

interface RefundInfo {
  maxLimit: number | null;
  fullRefund: number | null;
  duration: number | null;
}

export const CancelCommonRequest = ({ url, handleOnClose, drawerOpen, setRefetchEvents, title }: CancelCommonRequestProps) => {
  const { state: departmentInfoState } = useLoadedDepartmentInfoContext();
  const isAccrualsEnabled = departmentInfoState.departmentInfo.settings.accrualLogicEnabled;
  const [isLoading, setIsLoading] = useState(false);
  const [isTOF, setIsTOF] = useState(false);
  const [refundInfo, setRefundInfo] = useState<RefundInfo>({
    maxLimit: null,
    fullRefund: null,
    duration: null,
  });

  useEffect(() => {
    if (isAccrualsEnabled) setIsTOF(url.includes('time-off-request'));
  }, [isAccrualsEnabled, url]);

  const getRefundInfo = async () => {
    try {
      setIsLoading(true);
      const response = await client.get(url + 'refund/');
      if (response.data) {
        setRefundInfo({
          maxLimit: response.data.maxLimit,
          fullRefund: response.data.fullRefund,
          duration: response.data.duration,
        });
        return response.data;
      }
    } catch (error) {
      setIsLoading(false);
      captureException(error);
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    if (isTOF) {
      getRefundInfo();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isTOF]);

  const cancelRequest = async () => {
    let success = false;
    try {
      setIsLoading(true);
      await client.patch(url, {
        status: 'USER_CANCELLED',
      });
      success = true;
      setRefetchEvents(true);
    } catch (error) {
      setIsLoading(false);
      success = false;
      captureException(error);
    } finally {
      handleOnClose();
      SnackbarService.notify({
        content: success
          ? `Your ${title.toLowerCase()} request has been successfully canceled.`
          : 'There was an issue processing your request. ',
        severity: success ? 'success' : 'error',
        ...(success
          ? { showCloseButton: true, onClose: () => handleOnClose() }
          : {
              onCallToAction: () => handleRequest(),
              actionButtonText: 'Try again',
            }),
        duration: 5000,
      });
    }
  };

  const handleRequest = () => {
    cancelRequest();
  };

  return (
    <GenericDrawerOrModal
      anchor="bottom"
      drawerOpen={drawerOpen}
      handleOnClose={() => handleOnClose()}
      loading={false}
      showHeader={false}
      disableFooter={true}
      headerTitle={title}
    >
      <div>
        <Box>
          <FeedbackMessage status="question" firstMessage="Do you really wish to cancel this request?" secondMessage="" />
          {refundInfo.fullRefund && refundInfo.maxLimit && refundInfo.fullRefund > refundInfo.maxLimit && refundInfo.duration && (
            <Alert severity={'warning'} sx={(theme) => ({ mx: theme.spacing(2) })}>
              {`You will not get a full refund for cancelling this time off request.`}
              <br />
              {`Since you would be surpassing maximum limit.`}
              <br />
              {`You will get a refund of ${refundInfo.duration - refundInfo.fullRefund + refundInfo.maxLimit} hours.`}
            </Alert>
          )}

          <CancelRequest handleOnClose={handleOnClose} handleRequest={handleRequest} isLoading={isLoading} />
        </Box>
      </div>
    </GenericDrawerOrModal>
  );
};
