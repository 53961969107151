import { object, string, TypeOf, number } from 'zod';

export const createDutyFormSchema = object({
  note: string({ required_error: 'Note is required' }),
  startTime: number({ required_error: 'Start date is required' }),
  duration: number({ required_error: 'Duration is required' }),
  additionalPaidTimeDate: string({
    required_error: 'Shift date is required',
  }),
  additionalPaidTimeType: string({ required_error: 'Additional Paid Time Type Is Required' }),
  payCode: string(),
  eventLocation: string().nullable(),
});

export type CreateDutyFormDTO = TypeOf<typeof createDutyFormSchema>;
