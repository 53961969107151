import { Box, SxProps, Theme, useTheme } from '@mui/material';
import { Rank } from '@stationwise/share-types';
import { RankBadge } from '../Badge';
import { CardTooltip } from './CardTooltip';
import { Chip } from './Chip';

interface ActingAsIndicatorProps {
  rank: Pick<Rank, 'name' | 'code' | 'color'>;
  sx?: SxProps<Theme>;
}

interface ActingAsChipProps {
  sx?: SxProps<Theme>;
  fillColor?: string;
}

export const ActingAsChip = ({ sx, fillColor }: ActingAsChipProps) => {
  const theme = useTheme();
  const svg = `<svg fill="none" height="20" viewBox="0 0 21 20" width="21" xmlns="http://www.w3.org/2000/svg"><path d="m0 3c0-1.65685 1.34315-3 3-3h8.9174c1.3052 0 2.5284.636844 3.2769 1.70615l4.2 6c.9641 1.37728.9641 3.21045 0 4.58765l-4.2 6c-.7485 1.0694-1.9717 1.7062-3.2769 1.7062h-8.9174c-1.65685 0-3-1.3431-3-3z" fill="${fillColor || theme.palette.gray[500]}"/></svg>`;
  return (
    <Box
      sx={[
        {
          backgroundImage: `url('data:image/svg+xml,${encodeURIComponent(svg)}')`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'contain',
          color: theme.palette.common.white,
          display: 'inline-flex',
          alignItems: 'center',
          pl: '4px',
          width: '21px',
          height: '20px',
          typography: 'bodyXSRegular',
          lineHeight: 1,
        },
        ...(Array.isArray(sx) ? sx : sx ? [sx] : []),
      ]}
    >
      A
    </Box>
  );
};

export const ActingAsIndicator = ({ rank, sx }: ActingAsIndicatorProps) => {
  return (
    <CardTooltip
      anchorSx={sx}
      anchorContent={<ActingAsChip />}
      popoverSx={{ gap: 1, p: '12px 14px' }}
      popoverContent={
        <>
          <Chip label="Acting as" />
          <RankBadge rank={rank} rankField="name" />
        </>
      }
    />
  );
};
