import { Box, BoxProps, useTheme } from '@mui/material';

type IconCircleProps = BoxProps<'div'>;

export const IconCircle = ({ sx, children, ...props }: IconCircleProps) => {
  const theme = useTheme();

  return (
    <Box
      {...props}
      sx={[
        {
          background: theme.palette.common.white,
          borderRadius: '50%',
          border: `1px solid ${theme.palette.gray[50]}`,
          boxShadow: '0px 4px 6px -1px rgba(10, 14, 22, 0.10), 0px 2px 4px -1px rgba(10, 14, 22, 0.06)',
          display: 'inline-flex',
          p: 2,
          '& svg path': { stroke: 'currentColor' },
        },
        ...(Array.isArray(sx) ? sx : sx ? [sx] : []),
      ]}
    >
      {children}
    </Box>
  );
};
