import {
  Autocomplete as MuiAutocomplete,
  AutocompleteProps,
  Box,
  Chip,
  TextField,
  MenuItem,
  SxProps,
  Theme,
} from '@mui/material';
import { ReactElement } from 'react';
import { CheckIcon16, ChevronDownIcon16, XIcon16 } from '../../assets';
import { AdminSelectItem } from './AdminSelect';

interface AdminAutocompleteProps<Multiple extends boolean | undefined>
  extends Omit<AutocompleteProps<AdminSelectItem, Multiple, false, false>, 'renderInput'> {
  placeholder: string;
  endAdornment?: ReactElement;
  getDisplayedOption?: (option: AdminSelectItem) => AdminSelectItem;
  getChipSx?: (option: AdminSelectItem) => SxProps<Theme>;
  renderOptionValue?: (option: AdminSelectItem) => ReactElement;
}

export const AdminAutocomplete = <Multiple extends boolean | undefined>({
  placeholder,
  endAdornment,
  getDisplayedOption = (option) => option,
  getChipSx,
  renderOptionValue,
  multiple,
  sx,
  ...props
}: AdminAutocompleteProps<Multiple>) => {
  return (
    <MuiAutocomplete
      disablePortal
      multiple={multiple}
      {...props}
      sx={[
        (theme) => ({
          '& + .MuiAutocomplete-popper .MuiAutocomplete-option[aria-selected="true"]': {
            backgroundColor: theme.palette.common.white,
            borderRadius: '8px',
            border: `1px solid ${theme.palette.gray[900]}`,
          },
          '& button.MuiButtonBase-root': {
            visibility: 'visible',
          },
          '& .MuiOutlinedInput-root': { p: theme.spacing(0.5) },
        }),
        ...(Array.isArray(sx) ? sx : sx ? [sx] : []),
      ]}
      disableCloseOnSelect={multiple}
      getOptionLabel={(option) => getDisplayedOption(option).label}
      isOptionEqualToValue={(option, selected) => option.value === selected.value}
      renderInput={(params) => (
        <TextField
          {...params}
          placeholder={placeholder}
          sx={(theme) => ({
            typography: 'bodySRegular',
            maxHeight: '220px',
            '& .MuiOutlinedInput-root': { p: '4px 9px' },
            '& .MuiIconButton-root': { mr: '4px' },
            color: theme.palette.gray[500],
          })}
          InputProps={
            !multiple && endAdornment
              ? {
                  ...params.InputProps,
                  endAdornment: (
                    <Box>
                      {endAdornment}
                      {params.InputProps.endAdornment}
                    </Box>
                  ),
                }
              : { ...params.InputProps }
          }
        />
      )}
      renderTags={
        multiple
          ? (value: readonly AdminSelectItem[], getTagProps) =>
              value.map((option: AdminSelectItem, index: number) => (
                <Chip
                  variant="outlined"
                  label={getDisplayedOption(option).value}
                  deleteIcon={<XIcon16 />}
                  {...getTagProps({ index })}
                  key={index}
                  sx={(() => {
                    const sx = getChipSx?.(option);
                    return [
                      (theme) => ({
                        backgroundColor: theme.palette.gray[100],
                        border: 'none',
                        height: '24px',
                      }),
                      ...(Array.isArray(sx) ? sx : sx ? [sx] : []),
                    ];
                  })()}
                />
              ))
          : undefined
      }
      renderOption={(props, option, state) => (
        <MenuItem {...props} key={option.value} sx={(theme) => ({ mx: theme.spacing(1.5), borderRadius: '8px' })}>
          <Box
            sx={(theme) => ({
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              gap: 1,
              width: '100%',
              pr: !multiple || state.selected ? 0 : 2,
              '& svg path': { stroke: theme.palette.gray[900] },
            })}
          >
            {renderOptionValue?.(option)}
            {!renderOptionValue && (
              <Box sx={{ flex: 1, display: 'flex', alignItems: 'center', justifyContent: 'space-between', gap: 1 }}>
                <Box sx={{ typography: 'bodyMRegular' }}>{getDisplayedOption(option).label}</Box>
                {getDisplayedOption(option).value !== getDisplayedOption(option).label && (
                  <Box sx={(theme) => ({ color: theme.palette.gray[500], typography: 'bodySMedium' })}>
                    {getDisplayedOption(option).value}
                  </Box>
                )}
              </Box>
            )}
            {state.selected && <CheckIcon16 />}
          </Box>
        </MenuItem>
      )}
      popupIcon={<ChevronDownIcon16 />}
    />
  );
};
