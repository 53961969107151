import { Box, Menu, MenuItem } from '@mui/material';
import { useState } from 'react';
import { DotsHorizontalIcon16, useLoadedAuthUserContext } from '@stationwise/component-module';
import { ConversationListView } from '@stationwise/share-types';
import { makeTestIdentifier } from '@stationwise/share-utils';

interface UpdateMessageProps {
  updateConversation: (conversationId: number, toggleRead: boolean, markAsCompleted: boolean) => Promise<void>;
  conversation: ConversationListView;
  refetchConversations?: () => void;
}
export const UpdateMessage = ({ updateConversation, conversation, refetchConversations }: UpdateMessageProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const { state: authUserState } = useLoadedAuthUserContext();
  const isRead = !authUserState.employee.unreadMessages.includes(conversation.id);
  const markReadTitle = isRead ? 'Mark as Unread' : 'Mark as Read';

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleUpdateMessage = async () => {
    handleClose();
    await updateConversation(conversation.id, true, false);
    refetchConversations?.();
  };

  return (
    <Box
      sx={(theme) => ({
        height: '32px',
        width: '32px',
        borderRadius: '32px',
        backgroundColor: open ? theme.palette.gray[100] : 'transparent',
        '& svg path': {
          stroke: theme.palette.gray[500],
        },
      })}
    >
      <Box
        data-cy="conversation-actions-button"
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          width: '32px',
          height: '32px',
        }}
        onClick={handleClick}
      >
        <DotsHorizontalIcon16 />
      </Box>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem data-cy={`${makeTestIdentifier(markReadTitle)}-button`} onClick={handleUpdateMessage}>
          {markReadTitle}
        </MenuItem>
      </Menu>
    </Box>
  );
};
