import { Box, ButtonBase, useTheme } from '@mui/material';
import { captureException } from '@sentry/react';
import { useState } from 'react';
import { Button, SnackbarService, useLoadedDepartmentInfoContext } from '@stationwise/component-module';
import { client, isAxiosError } from '@stationwise/share-api';
import { ShiftPlanAction, ShiftPlanChangeLog, ShiftPlanPublishPreview, ShiftPlanChipType } from '@stationwise/share-types';
import { getChangeLog } from '../../helpers/readChangeLog';
import { ChangeLogSelect } from '../Publish/ChangeLogSelect';
import { PublishEffectsDetail } from '../Publish/PublishEffectsDetail';
import { PublishPreviewDetail } from '../Publish/PublishPreviewDetail';
import { useShiftPlanContext } from '../ShiftPlanContext';
import { ShiftPlanDialog } from '../ShiftPlanDialog';

export const TopBar = () => {
  const theme = useTheme();
  const { state: departmentInfoState } = useLoadedDepartmentInfoContext();
  const { departmentInfo } = departmentInfoState;
  const { hasDraft, selectedChipType, setSelectedChipType, setSelectedAction, savingAction, setSavingAction, saveAsDraft } =
    useShiftPlanContext();
  const [publishEmployeeIds, setPublishEmployeeIds] = useState(new Set<string>());
  const [publishStep, setPublishStep] = useState(0);
  const [changeLog, setChangeLog] = useState<ShiftPlanChangeLog | null>(null);
  const [publishPreview, setPublishPreview] = useState<ShiftPlanPublishPreview | null>(null);

  const chips = [
    { type: ShiftPlanChipType.ROSTER_EDITOR, label: 'Roster editor' },
    { type: ShiftPlanChipType.STAFFING_PATTERNS, label: 'Staffing patterns' },
  ];

  const onDiscard = () => {
    if (savingAction) return;
    setSavingAction(ShiftPlanAction.DISCARD_DRAFT);
    const payload = { action: 'DISCARD_DRAFT' };
    saveAsDraft(payload, () => setSavingAction(null));
  };

  const onPublishStart = async () => {
    if (savingAction) return;
    try {
      setSavingAction(ShiftPlanAction.GET_CHANGE_LOG);
      const response = await client.get('/shift/shift-plan-change-log/');
      const changeLog = getChangeLog(departmentInfo, response.data);
      setChangeLog(changeLog);
      setPublishEmployeeIds(new Set(changeLog.affectedEmployees.map((e) => e.id)));
      setSavingAction(null);
      setPublishStep(changeLog.hasChanges ? 1 : 2);
      setSelectedAction(ShiftPlanAction.PUBLISH_DRAFT);
    } catch (error) {
      !isAxiosError(error) && captureException(error);
      setSavingAction(null);
      SnackbarService.notify({
        content: 'There was an issue loading your changes. Please try again.',
        severity: 'error',
        duration: 5000,
      });
    }
  };

  const onPublishPreview = async () => {
    try {
      setSavingAction(ShiftPlanAction.GET_PUBLISH_PREVIEW);
      const response = await client.get('/shift/shift-plan-publish-preview/', {
        params: { employeeIds: Array.from(publishEmployeeIds) },
      });
      setPublishPreview(response.data);
      setSavingAction(null);
      setPublishStep(3);
    } catch (error) {
      !isAxiosError(error) && captureException(error);
      setSavingAction(null);

      let message = 'There was an issue previewing your changes. Please try again.';
      if (isAxiosError(error)) {
        message = error.response?.data?.nonFieldErrors?.[0] || message;
      }

      SnackbarService.notify({ content: message, severity: 'error', duration: 5000 });
    }
  };

  return (
    <Box
      sx={{
        background: theme.palette.common.white,
        borderBottom: `1px solid ${theme.palette.divider}`,
        display: 'flex',
        alignItems: 'center',
        gap: 3,
        p: theme.spacing(1, 1.5),
        overflowY: 'auto',
        scrollbarGutter: 'stable',
        '& .MuiButton-root': { minHeight: '40px' },
      }}
    >
      <Box sx={{ flex: 1, display: 'flex' }}>
        {chips.map((chip) => (
          <ButtonBase
            key={chip.type}
            disableRipple={chip.type === selectedChipType}
            onClick={() => setSelectedChipType(chip.type)}
            sx={{
              background: chip.type === selectedChipType ? theme.palette.action.selected : 'transparent',
              borderRadius: '9999px',
              cursor: chip.type === selectedChipType ? 'default' : 'pointer',
              p: theme.spacing(1, 2),
              typography: 'bodyMRegular',
            }}
          >
            {chip.label}
          </ButtonBase>
        ))}
      </Box>
      {selectedChipType !== ShiftPlanChipType.STAFFING_PATTERNS && (
        <Box sx={{ display: 'flex', gap: 1 }}>
          <Button
            variant="outlined"
            disabled={!hasDraft || savingAction === ShiftPlanAction.GET_CHANGE_LOG}
            loading={savingAction === ShiftPlanAction.DISCARD_DRAFT}
            onClick={onDiscard}
            data-cy="discard"
          >
            Discard
          </Button>
          <Button
            variant="contained"
            disabled={!hasDraft || savingAction === ShiftPlanAction.DISCARD_DRAFT}
            loading={savingAction === ShiftPlanAction.GET_CHANGE_LOG}
            onClick={onPublishStart}
            data-cy="publish"
          >
            Publish
          </Button>
          <ShiftPlanDialog action={ShiftPlanAction.PUBLISH_DRAFT}>
            {publishStep === 1 && changeLog && (
              <ChangeLogSelect
                changeLog={changeLog}
                publishEmployeeIds={publishEmployeeIds}
                setPublishEmployeeIds={setPublishEmployeeIds}
                setPublishStep={setPublishStep}
              />
            )}
            {publishStep === 2 && changeLog && (
              <PublishEffectsDetail changeLog={changeLog} onPublishPreview={onPublishPreview} setPublishStep={setPublishStep} />
            )}
            {publishStep === 3 && publishPreview && (
              <PublishPreviewDetail
                publishPreview={publishPreview}
                publishEmployeeIds={publishEmployeeIds}
                setPublishStep={setPublishStep}
              />
            )}
          </ShiftPlanDialog>
        </Box>
      )}
    </Box>
  );
};
