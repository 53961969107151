import { ReactComponent as CheckSymbol } from '@material-symbols/svg-400/outlined/check.svg';
import { Box, useTheme } from '@mui/material';
import { format } from 'date-fns';
import { SvgIcon, getTeamColors } from '@stationwise/component-module';
import { ShiftPlanAssignment, ShiftPlanTeam } from '@stationwise/share-types';
import { useShiftPlanContext } from '../ShiftPlanContext';
import { MonthDays } from './MonthDays';

interface TeamPatternDaysProps {
  assignments?: ShiftPlanAssignment[];
  team?: ShiftPlanTeam;
  month: Date;
}

export const TeamPatternDays = (props: TeamPatternDaysProps) => {
  const theme = useTheme();
  const { background } = getTeamColors({ theme, color: props.team?.color });
  const startOfMonthString = format(new Date(props.month.getFullYear(), props.month.getMonth(), 1), 'yyyy-MM-dd');
  const endOfMonthString = format(new Date(props.month.getFullYear(), props.month.getMonth() + 1, 0), 'yyyy-MM-dd');
  const { getTeamPatternDates } = useShiftPlanContext();
  let patternDates = new Set();

  if (props.assignments) {
    patternDates = new Set(
      props.assignments.flatMap((assignment) => {
        return Array.from(getTeamPatternDates(assignment.team, startOfMonthString, endOfMonthString, assignment));
      }),
    );
  } else if (props.team) {
    patternDates = new Set(Array.from(getTeamPatternDates(props.team, startOfMonthString, endOfMonthString)));
  }

  const renderOverlay = (date: Date) => {
    const isTeamDay = patternDates.has(format(date, 'yyyy-MM-dd'));

    return (
      <Box
        sx={{
          background: isTeamDay ? background : theme.palette.common.white,
          color: theme.palette.common.white,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          height: '100%',
          'div:last-of-type > &': { right: '1px' },
          '.SWShiftPlan-switching-month &': { background: theme.palette.common.white },
        }}
      >
        {isTeamDay && <SvgIcon component={CheckSymbol} sx={{ fontSize: '16px' }} />}
      </Box>
    );
  };

  return <MonthDays month={props.month} renderOverlay={renderOverlay} />;
};
