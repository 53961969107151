import { Box, useTheme } from '@mui/material';
import { Dispatch, SetStateAction } from 'react';
import { ShiftPlanChangeLog } from '@stationwise/share-types';
import { useShiftPlanContext } from '../ShiftPlanContext';
import { ShiftPlanDialogActions, WarningMessage } from '../ShiftPlanDialog';

interface PublishEffectsDetailProps {
  changeLog: ShiftPlanChangeLog;
  onPublishPreview: () => void;
  setPublishStep: Dispatch<SetStateAction<number>>;
}

export const PublishEffectsDetail = (props: PublishEffectsDetailProps) => {
  const theme = useTheme();
  const { cancelSelectedAction } = useShiftPlanContext();

  const onCancel = () => {
    if (props.changeLog.hasChanges) {
      props.setPublishStep((prevStep) => prevStep - 1);
    } else {
      cancelSelectedAction();
    }
  };

  const onSave = () => props.onPublishPreview();

  const detail = (
    <Box
      sx={{
        border: `1px solid ${theme.palette.divider}`,
        borderRadius: 1,
        background: theme.palette.gray[50],
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        minHeight: '1px',
        overflow: 'hidden',
        width: '100%',
      }}
    >
      <Box
        sx={{
          flex: 1,
          minHeight: '1px',
          overflowY: 'auto',
          p: 1.5,
          textAlign: 'left',
          '& ul': { listStyleType: 'disc', m: 0, p: theme.spacing(0, 0, 0, 2) },
          '& li': { m: theme.spacing(0.5, 0, 0, 0), typography: 'bodySSemibold' },
          '& li li': { typography: 'bodySRegular' },
        }}
      >
        <Box>Changes on shift plan can affect employees directly or indirectly.</Box>
        <Box sx={{ typography: 'bodyMSemibold', pt: 3 }}>Directly affected employees:</Box>
        <ul>
          <li>
            Employees completely unassigned from the shift plan, or with changed assigned dates.
            <ul>
              <li>Event assignments will remain the same.</li>
              <li>Time-offs, Light Duties, and Extended Leaves will be cancelled.</li>
              <li>Shift trades will be cancelled and the hours refunded.</li>
              <li>Full and partial override assignments will be deleted.</li>
            </ul>
          </li>
          <li>
            Employees with unchanged assigned dates, but changed locations or times.
            <ul>
              <li>Light Duties, Extended Leaves, Overtimes, and Event assignments will remain the same.</li>
              <li>
                Shift trades will be cancelled and the hours refunded if:
                <ul>
                  <li>The receiver will not be certified to cover the planned positions of the sender OR</li>
                  <li>The planned assignments of the sender will not fully cover the shift trade duration.</li>
                </ul>
              </li>
              <li>
                Time-offs will be cancelled if the planned assignments of the employee will not fully cover the time off duration.
              </li>
              <li>Full and partial override assignments will be deleted.</li>
            </ul>
          </li>
        </ul>
        <Box sx={{ typography: 'bodyMSemibold', pt: 3 }}>Indirectly affected employees:</Box>
        <ul>
          <li>
            Employees with invalid override assignments due to removed position, changed position requirements, or reserved
            apparatus.
            <ul>
              <li>
                They will be relocated to their planned assignments, or floater at times not covered by their planned assignments.
              </li>
            </ul>
          </li>
          <li>Employees receiving a shift trade that was cancelled due to changed planned assignments of the sender.</li>
        </ul>
      </Box>
    </Box>
  );

  return (
    <>
      <WarningMessage primaryText="You are about to publish a new shift plan" secondaryText={detail} />
      <ShiftPlanDialogActions
        cancelText={props.changeLog.hasChanges ? 'Back' : undefined}
        saveText="Preview"
        onCancel={onCancel}
        onSave={onSave}
      />
    </>
  );
};
