import { Box, ButtonBase, useTheme } from '@mui/material';
import { Dispatch, SetStateAction } from 'react';
import { CertBadge, MinusIcon20, PlusIcon20 } from '@stationwise/component-module';
import { CertificationRequirement, DepartmentInfo } from '@stationwise/share-types';

interface CertificationRequirementCounterProps {
  certificationRequirement: CertificationRequirement;
  setCertificationRequirements: Dispatch<SetStateAction<CertificationRequirement[]>>;
}

export const makeCertificationRequirements = (departmentInfo: DepartmentInfo, certReqs?: CertificationRequirement[]) => {
  const newCertReqs = new Map<string, CertificationRequirement>();
  departmentInfo.certifications.forEach((c) => {
    newCertReqs.set(c.code, { id: c.id, name: c.name, code: c.code, color: c.color, amount: 0 });
  });
  certReqs?.forEach((certReq) => newCertReqs.set(certReq.code, certReq));
  return Array.from(newCertReqs.values());
};

export const CertificationRequirementCounter = (props: CertificationRequirementCounterProps) => {
  const theme = useTheme();

  const updateAmount = (newAmount: number) => {
    props.setCertificationRequirements((prevCertReqs) => {
      return prevCertReqs.map((prevCertReq) => {
        const isThisCertReq = prevCertReq.id === props.certificationRequirement.id;
        return isThisCertReq ? { ...prevCertReq, amount: Math.max(0, newAmount) } : prevCertReq;
      });
    });
  };

  return (
    <Box sx={{ borderBottom: `1px solid ${theme.palette.gray[100]}`, display: 'flex', alignItems: 'center', gap: 2, p: 1 }}>
      <Box sx={{ color: theme.palette.gray[500], typography: 'bodySRegular' }}>{props.certificationRequirement.name}</Box>
      <CertBadge cert={props.certificationRequirement} />
      <Box
        sx={{
          color: props.certificationRequirement.amount > 0 ? theme.palette.gray[900] : theme.palette.gray[400],
          flex: 1,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-end',
          '& button': { background: theme.palette.gray[100], borderRadius: '50%', height: '20px', width: '20px' },
          '& svg path': { stroke: theme.palette.gray[400] },
        }}
      >
        <ButtonBase onClick={() => updateAmount(props.certificationRequirement.amount - 1)}>
          <MinusIcon20 />
        </ButtonBase>
        <Box sx={{ width: '36px', textAlign: 'center' }}>{props.certificationRequirement.amount}</Box>
        <ButtonBase onClick={() => updateAmount(props.certificationRequirement.amount + 1)}>
          <PlusIcon20 />
        </ButtonBase>
      </Box>
    </Box>
  );
};
