import { Box } from '@mui/material';
import { RankBadge, WhiteStationIcon20, useLoadedAuthUserContext } from '@stationwise/component-module';
import { RosterEmployee, RosterStation, RosterApparatus } from '@stationwise/share-types';

interface ShiftDetailsProps {
  employee: RosterEmployee;
  shiftDateRange: string;
  station?: RosterStation;
  apparatus?: RosterApparatus;
}

export const ShiftDetails = ({ employee, shiftDateRange, station, apparatus }: ShiftDetailsProps) => {
  const { state: authUserState } = useLoadedAuthUserContext();
  const isMe = employee.id === authUserState.employee.id;

  return (
    <Box
      sx={(theme) => ({
        border: `1px dashed ${theme.palette.gray[100]}`,
        boxShadow: '0px 1px 2px 0px rgba(10, 14, 22, 0.05)',
        p: theme.spacing(1, 2),
        backgroundColor: theme.palette.common.white,
        '&:last-child': { borderRadius: `0 0 ${2 * theme.shape.borderRadius}px ${2 * theme.shape.borderRadius}px` },
      })}
    >
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Box display="flex" alignItems="center">
          <RankBadge rank={employee.rank} />
          <Box sx={(theme) => ({ color: theme.palette.gray[900], ml: theme.spacing(1), typography: 'bodyLMedium' })}>
            {employee.name}{' '}
            {isMe && (
              <Box component="span" sx={{ fontWeight: 500, color: '#A3ADB5' }}>
                {'(You)'}
              </Box>
            )}
          </Box>
        </Box>

        <Box sx={(theme) => ({ color: theme.palette.gray[400], typography: 'bodyMRegular' })}>{shiftDateRange}</Box>
      </Box>

      {isMe && station && apparatus && (
        <Box display="flex" justifyContent="space-between" alignItems="center" mt={1}>
          <Box display="flex" alignItems="center">
            <Box sx={{ marginRight: 1, display: 'flex', alignItems: 'center' }}>
              <WhiteStationIcon20 />
            </Box>
            <Box sx={(theme) => ({ color: theme.palette.gray[500], typography: 'bodyMRegular' })}>
              {station.stationName + ' | ' + apparatus.name}
            </Box>
          </Box>
        </Box>
      )}
    </Box>
  );
};
