import { Box, useTheme, Select, FormControl, InputLabel, MenuItem } from '@mui/material';
import { HiringEngineServices, hiringEngineServicesInfo } from '@stationwise/share-types';
import { Button } from '../../../Button';
import { HiringEngineFeatureCard } from './HiringEngineFeatureCard';

interface HirinEngineOptionsProps {
  handleHiringEngineAction: (type: HiringEngineServices) => void;
  isPopover?: boolean;
}

export const HirinEngineOptions = ({ handleHiringEngineAction, isPopover = false }: HirinEngineOptionsProps) => {
  const theme = useTheme();
  const HIRING_ENGINE_OPTIONS = [
    {
      title: hiringEngineServicesInfo[HiringEngineServices.HIRING_FLOW].title,
      description: hiringEngineServicesInfo[HiringEngineServices.HIRING_FLOW].description,
      disabled: false, // TODO: disable when no flow is selected
      buttonText: 'Start With Selected',
      icon: 'flow' as const,
      hideInPopover: true,
      onClick: () => handleHiringEngineAction(HiringEngineServices.HIRING_FLOW),
    },
    {
      title: hiringEngineServicesInfo[HiringEngineServices.AUTO_HIRE].title,
      description: hiringEngineServicesInfo[HiringEngineServices.AUTO_HIRE].description,
      icon: 'autoHire' as const,
      onClick: () => handleHiringEngineAction(HiringEngineServices.AUTO_HIRE),
    },
    {
      title: hiringEngineServicesInfo[HiringEngineServices.INSTANT_HIRE].title,
      description: hiringEngineServicesInfo[HiringEngineServices.INSTANT_HIRE].description,
      icon: 'instantHire' as const,
      onClick: () => handleHiringEngineAction(HiringEngineServices.INSTANT_HIRE),
    },
    {
      title: hiringEngineServicesInfo[HiringEngineServices.REQUEST_VOLUNTEERS].title,
      description: hiringEngineServicesInfo[HiringEngineServices.REQUEST_VOLUNTEERS].description,
      icon: 'requestVolunteers' as const,
      onClick: () => handleHiringEngineAction(HiringEngineServices.REQUEST_VOLUNTEERS),
    },
  ];

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing(1),
      }}
    >
      {HIRING_ENGINE_OPTIONS.filter((option) => !isPopover || !option.hideInPopover).map((option) => (
        <HiringEngineFeatureCard
          key={option.title}
          title={option.title}
          icon={option.icon}
          description={option.description}
          buttonContainer={
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
              {option.title === 'Hiring Flow' && (
                <FormControl fullWidth sx={{ mb: 1 }}>
                  <InputLabel shrink>Hiring Flow</InputLabel>
                  <Select
                    label="Hiring Flow"
                    variant="outlined"
                    notched
                    displayEmpty
                    defaultValue=""
                    sx={{
                      '& .MuiSelect-select': {
                        backgroundColor: 'background.paper',
                      },
                    }}
                  >
                    <MenuItem value="Flow1">Flow 1</MenuItem>
                    <MenuItem value="Flow2">Flow 2</MenuItem>
                    <MenuItem value="Flow3">Flow 3</MenuItem>
                  </Select>
                </FormControl>
              )}
              <Button variant="contained" size="medium" color="primary" disabled={option.disabled} onClick={option.onClick}>
                {option.buttonText || 'Add New'}
              </Button>
            </Box>
          }
        />
      ))}
    </Box>
  );
};
