import { Dialog, DialogContent, DialogTitle, Box, Typography, useTheme, Tooltip } from '@mui/material';
import { format, parseISO } from 'date-fns';
import {
  Card,
  ResponseBadgePending20,
  ResponseBadgeApproved20,
  useLoadedDepartmentInfoContext,
  DialogXButton,
} from '@stationwise/component-module';
import { InstantHireView } from '@stationwise/share-types';
import { formatShiftDuration } from '@stationwise/shift-summary-helper';

interface InstantHireHistoryDetailsProps {
  instantHire: InstantHireView;
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export const InstantHireHistoryDetails = ({ instantHire, open, setOpen }: InstantHireHistoryDetailsProps) => {
  const theme = useTheme();
  const { state: departmentInfoState } = useLoadedDepartmentInfoContext();
  const numberOfAcknowledged = instantHire.positions.filter((position) => position.isAcknowledged).length;
  const shiftStart = departmentInfoState.departmentInfo.shiftStart;
  const [hours, minutes] = shiftStart.split(':').map(Number);
  const shiftStartTime = new Date();
  shiftStartTime.setHours(hours, minutes, 0, 0);

  const closeModal = () => {
    setOpen(false);
  };

  return (
    <Dialog
      open={open}
      onClose={closeModal}
      PaperProps={{
        sx: {
          width: '600px',
          maxHeight: '80vh',
        },
      }}
    >
      <DialogTitle>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
          <Box sx={{ typography: 'h6' }}>Instant Hire Details</Box>
          <Typography variant="bodyMRegular">{format(instantHire.shiftDate, 'MMM d, yyyy')}</Typography>
        </Box>
        <DialogXButton onClose={closeModal} />
      </DialogTitle>
      <DialogContent>
        <Typography
          variant="bodyMSemibold"
          sx={(theme) => ({
            color: theme.palette.gray[800],
          })}
        >
          {instantHire.positions.length} {instantHire.positions.length === 1 ? 'Person' : 'People'}
          {' Staffed '}
          <Typography component="span" variant="bodyMRegular" sx={{ display: 'inline' }}>
            on
          </Typography>{' '}
          {instantHire.createdAt ? format(parseISO(instantHire.createdAt), 'MMM dd, HH:mm aa') : 'N/A'}
        </Typography>
        <Box
          sx={(theme) => ({
            display: 'flex',
            flexDirection: 'row',
            gap: theme.spacing(1),
            marginTop: theme.spacing(2.5),
            marginBottom: theme.spacing(2.5),
          })}
        >
          <Box
            sx={(theme) => ({
              display: 'flex',
              alignItems: 'center',
              gap: theme.spacing(1),
            })}
          >
            <ResponseBadgeApproved20 />
            <Typography variant="bodySRegular">{numberOfAcknowledged} Acknowledged</Typography>
          </Box>
          <Box
            sx={(theme) => ({
              display: 'flex',
              alignItems: 'center',
              gap: theme.spacing(1),
            })}
          >
            <ResponseBadgePending20 />
            <Typography variant="bodySRegular">{instantHire.positions.length - numberOfAcknowledged} Not Acknowledged</Typography>
          </Box>
        </Box>
        {instantHire.positions.map((position) => {
          const startDateTime = new Date(shiftStartTime);
          startDateTime.setMinutes(startDateTime.getMinutes() + position.startTime);

          const endDateTime = new Date(shiftStartTime);
          endDateTime.setMinutes(endDateTime.getMinutes() + position.endTime);

          const durationMinutes = position.endTime - position.startTime;

          return (
            <Box
              key={position.id}
              sx={{
                display: 'flex',
                backgroundColor: theme.palette.gray[200],
                marginTop: theme.spacing(1),
                borderRadius: 2.5,
                flexDirection: 'column',
                padding: 1.5,
              }}
            >
              <Typography variant="bodyMRegular">{`${position.stationName} | ${position.apparatusName}`}</Typography>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  marginTop: 1,
                }}
              >
                <Card
                  rank={position.employee.rank}
                  durationLabel={formatShiftDuration({
                    startTime: startDateTime,
                    endTime: endDateTime,
                  })}
                  certs={position.employee.certifications}
                  durationMinutes={durationMinutes}
                  nameLabel={`${position.employee.firstName} ${position.employee.lastName}`}
                  actions={
                    position.isAcknowledged ? (
                      <Tooltip title="Acknowledged">
                        <Box>
                          <ResponseBadgeApproved20 />
                        </Box>
                      </Tooltip>
                    ) : (
                      <Tooltip title="Not Acknowledged">
                        <Box>
                          <ResponseBadgePending20 />
                        </Box>
                      </Tooltip>
                    )
                  }
                />
              </Box>
            </Box>
          );
        })}
      </DialogContent>
    </Dialog>
  );
};
