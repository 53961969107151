import { Box, Skeleton, Typography } from '@mui/material';

interface LoadingRowProps {
  selectedId: number;
  index: number;
}

export const LoadingRow = ({ selectedId, index }: LoadingRowProps) => {
  return (
    <Box key={selectedId} sx={{ marginTop: 1 }}>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          gap: 2,
          paddingY: 1,
        }}
      >
        <Typography variant="bodyXXLMedium" sx={{ color: '#B4B4B4' }}>
          #{index + 1}
        </Typography>
        <Box sx={{ flex: 1, display: 'flex', alignItems: 'center', gap: 2 }}>
          <Skeleton variant="rectangular" height={40} animation="wave" sx={{ flex: 1, borderRadius: 1 }} />
          <Skeleton variant="rectangular" width={40} height={40} animation="wave" sx={{ borderRadius: 1 }} />
          <Box sx={{ flex: 1 }} />
          <Skeleton variant="rectangular" width={80} height={40} animation="wave" sx={{ borderRadius: 1 }} />
        </Box>
      </Box>
    </Box>
  );
};
