import { Box, Divider, Typography } from '@mui/material';
import { captureException } from '@sentry/react';
import { format } from 'date-fns';
import { useEffect, useRef, useState } from 'react';
import { Button, LottieLogo } from '@stationwise/component-module';
import { client } from '@stationwise/share-api';
import { DetailFieldsStaffingList } from '@stationwise/share-types';
import { useFetchStaffingListsForHiringEngine } from '../../hooks/useFetchStaffingListsForHiringEngine';
import { LoadingRow } from './StaffingListLoadingRow';
import { StaffingListRow } from './StaffingListRow';
import { useFlipAnimation } from './utils';

interface HiringEngineStaffingListControllerProps {
  selectedDate: Date;
  isDisabled?: boolean;
}

export const HiringEngineStaffingListController = ({
  selectedDate,
  isDisabled = false,
}: HiringEngineStaffingListControllerProps) => {
  const { staffingLists, isLoading } = useFetchStaffingListsForHiringEngine({ skip: false });
  const [loadingItems, setLoadingItems] = useState<boolean>(false);
  const [selectedStaffingListIds, setSelectedStaffingListIds] = useState<number[]>([]);
  const [staffingListDetails, setStaffingListDetails] = useState<DetailFieldsStaffingList[]>([]);
  const [filteredStaffingListDetails, setFilteredStaffingListDetails] = useState<DetailFieldsStaffingList[]>([]);
  const [rowLoading, setRowLoading] = useState<boolean>(false);
  const [isOptionChanging, setIsOptionChanging] = useState<boolean>(false);

  const firstRender = useRef(true);
  const dragItem = useRef<number | null>(null);
  const dragOverItem = useRef<number | null>(null);

  const { flipRef, updatePositions } = useFlipAnimation(selectedStaffingListIds);

  useEffect(() => {
    if (firstRender.current && staffingLists.length !== 0 && selectedStaffingListIds.length === 0 && selectedDate) {
      handleAddStaffingList();
      firstRender.current = false;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [staffingLists, selectedDate]);

  const handleAddStaffingList = async () => {
    if (loadingItems || staffingLists.length === selectedStaffingListIds.length) {
      return;
    }

    const availableItems = staffingLists.filter((item) => !selectedStaffingListIds.includes(item.id));
    if (availableItems.length > 0) {
      const newStaffingListId = availableItems[0].id;
      setRowLoading(true);
      try {
        const response = await client.get(
          `/staffing-list/staffing-lists/${newStaffingListId}/?date=${format(selectedDate, 'MM/dd/yyyy')}`,
        );
        setSelectedStaffingListIds((prev) => [...prev, newStaffingListId]);
        setStaffingListDetails((prev) => [...prev, response.data]);
        setFilteredStaffingListDetails((prev) => [...prev, response.data]);
      } catch (error) {
        captureException(error);
      } finally {
        setRowLoading(false);
        setLoadingItems(false);
      }
    }
  };

  const handleSort = () => {
    if (dragItem.current !== null && dragOverItem.current !== null) {
      const updatedStaffingListIds = [...selectedStaffingListIds];
      const draggedItemId = updatedStaffingListIds.splice(dragItem.current, 1)[0];
      updatedStaffingListIds.splice(dragOverItem.current, 0, draggedItemId);

      const updatedStaffingListDetails = [...staffingListDetails];
      const draggedDetail = updatedStaffingListDetails.splice(dragItem.current, 1)[0];
      updatedStaffingListDetails.splice(dragOverItem.current, 0, draggedDetail);

      const updatedFilteredStaffingListDetails = [...filteredStaffingListDetails];
      const draggedFilteredDetail = updatedFilteredStaffingListDetails.splice(dragItem.current, 1)[0];
      updatedFilteredStaffingListDetails.splice(dragOverItem.current, 0, draggedFilteredDetail);

      setSelectedStaffingListIds(updatedStaffingListIds);
      setStaffingListDetails(updatedStaffingListDetails);
      setFilteredStaffingListDetails(updatedFilteredStaffingListDetails);

      dragItem.current = null;
      dragOverItem.current = null;
    }
  };

  const renderContent = () => {
    if (isLoading) {
      return (
        <Box display="flex" alignItems="center" justifyContent="center" sx={{ height: '100%', width: '100%' }}>
          <LottieLogo height="200px" width="200px" />
        </Box>
      );
    }

    return (
      <Box sx={{ padding: 2 }}>
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: 2 }}>
          <Typography>Select Employees From:</Typography>
          <Button variant="outlined" onClick={() => {}}>
            View Employees
          </Button>
        </Box>
        <Divider />

        {selectedStaffingListIds.map((selectedId, index) => {
          return (
            <Box
              key={selectedId}
              ref={flipRef(selectedId)}
              sx={{ marginTop: 1 }}
              draggable={!isDisabled}
              onDragStart={() => {
                updatePositions();
                dragItem.current = index;
              }}
              onDragEnter={() => (dragOverItem.current = index)}
              onDragEnd={handleSort}
              onDragOver={(e) => e.preventDefault()}
            >
              <StaffingListRow
                selectedDate={selectedDate}
                index={index}
                selectedId={selectedId}
                staffingLists={staffingLists}
                selectedStaffingListIds={selectedStaffingListIds}
                setSelectedStaffingListIds={setSelectedStaffingListIds}
                items={staffingListDetails[index]?.items || []}
                setStaffingListDetails={setStaffingListDetails}
                filteredItems={filteredStaffingListDetails[index]?.items || []}
                setFilteredStaffingListDetails={setFilteredStaffingListDetails}
                isOptionChanging={isOptionChanging}
                setIsOptionChanging={setIsOptionChanging}
                isDisabled={isDisabled}
              />
              {rowLoading && <LoadingRow selectedId={selectedId} index={index + 1} />}
            </Box>
          );
        })}

        <Button
          variant="outlined"
          onClick={handleAddStaffingList}
          disabled={isDisabled || staffingLists.length === selectedStaffingListIds.length}
          sx={{ width: '100%', marginTop: 1 }}
        >
          Add More Staffing List Configuration
        </Button>
      </Box>
    );
  };

  return <Box sx={{ display: 'flex', flexDirection: 'column' }}>{renderContent()}</Box>;
};
