import { Autocomplete, Box, Dialog, FormControl, FormHelperText, TextField } from '@mui/material';
import { captureException } from '@sentry/react';
import { useEffect, useMemo, useState } from 'react';
import { Button, useDepartmentInfoContext, MultipleSelect } from '@stationwise/component-module';
import { client } from '@stationwise/share-api';
import { ShiftMark, WorkGroup } from '@stationwise/share-types';
import { usePatchRequest } from './PatchRequestProvider';

interface EditGroupInfoProps {
  employeeWorkGroups: WorkGroup[];
  shiftMarks: ShiftMark[];
  showModal: boolean;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
}

export const EditGroupInfo = ({ showModal, setShowModal, employeeWorkGroups, shiftMarks }: EditGroupInfoProps) => {
  const initialWorkGroups = employeeWorkGroups.map((wg) => wg.name);
  const [selectedShiftMarks, setSelectedShiftMarks] = useState<ShiftMark[]>(shiftMarks);
  const [selectedWorkGroups, setSelectedWorkGroups] = useState(initialWorkGroups);
  const { state: department } = useDepartmentInfoContext();
  const [departmentShiftMarks, setDepartmentShiftMarks] = useState<ShiftMark[]>([]);

  const { handlePatchRequest } = usePatchRequest();

  const workGroupOptions = department.departmentInfo?.workGroups.map((wg) => ({
    label: wg.name,
    value: wg.name,
    color: wg.color,
  }));

  useEffect(() => {
    const fetchShiftMarks = async () => {
      try {
        const response = await client.get('employee/team/department-shift-marks/');
        setDepartmentShiftMarks(response.data);
      } catch (error) {
        captureException(error);
      }
    };

    fetchShiftMarks();
  }, []);

  const handleSave = () => {
    const data = {
      shiftMarkIds: selectedShiftMarks ? selectedShiftMarks.map((mark) => mark.id) : [],
      workGroups: selectedWorkGroups,
    };
    handlePatchRequest(data);
    setShowModal(false);
  };

  const handleClose = () => {
    setShowModal(false);
    setSelectedShiftMarks(shiftMarks);
    setSelectedWorkGroups(initialWorkGroups);
  };

  const isDisabled = useMemo(() => {
    const selectedSet = new Set(selectedWorkGroups);
    const workGroupSet = new Set(initialWorkGroups);

    return (
      selectedSet.size === workGroupSet.size &&
      [...selectedSet].every((item) => workGroupSet.has(item)) &&
      selectedShiftMarks === shiftMarks
    );
  }, [initialWorkGroups, selectedShiftMarks, selectedWorkGroups, shiftMarks]);

  return (
    <Dialog open={showModal} onClose={handleClose} data-cy="edit-groups-modal">
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Box
          sx={(theme) => ({
            backgroundColor: theme.palette.common.white,
            justifyContent: 'center',
            borderRadius: theme.spacing(1.5),
            p: theme.spacing(3),
            width: '496px',
            display: 'flex',
            flexDirection: 'column',
          })}
        >
          <Box
            sx={{
              typography: 'bodyXLSemibold',
              textAlign: 'left',
            }}
          >
            Groups
          </Box>

          <Box
            sx={(theme) => ({
              typography: 'bodyLMedium',
              textAlign: 'left',
              paddingTop: theme.spacing(2),
              paddingBottom: theme.spacing(1),
            })}
          >
            Work groups
          </Box>

          <Box
            sx={(theme) => ({
              py: theme.spacing(1),
              display: 'block',
            })}
          >
            {workGroupOptions && (
              <MultipleSelect
                items={workGroupOptions}
                selectedItems={selectedWorkGroups}
                setSelectedItems={setSelectedWorkGroups}
                placeholder="Add work groups"
                hideValue={true}
              />
            )}
          </Box>

          {departmentShiftMarks && (
            <Box
              sx={{
                width: '100%',
              }}
            >
              <Box
                sx={(theme) => ({
                  typography: 'bodySMedium',
                  textAlign: 'left',
                  paddingTop: theme.spacing(2),
                  color: theme.palette.gray[700],
                  pb: theme.spacing(1),
                })}
              >
                Employee Shift Marks
              </Box>
              <FormControl>
                <Autocomplete
                  multiple
                  options={departmentShiftMarks}
                  getOptionLabel={(option) => option.detailedName}
                  value={selectedShiftMarks ?? []}
                  onChange={(_event, value) => {
                    setSelectedShiftMarks(value);
                  }}
                  renderInput={(params) => <TextField {...params} />}
                  sx={{
                    width: '100%',
                    minWidth: '100%',
                    '.MuiFormControl-root': {
                      mb: 0,
                      '.MuiInputLabel-root:not(.Mui-focused)': {
                        top: 0,
                      },
                    },
                  }}
                />
                <FormHelperText>
                  Select or type to find the appropriate shift mark for the employee. Ensure it aligns with their role and
                  department requirements.
                </FormHelperText>
              </FormControl>
            </Box>
          )}

          <Box
            sx={(theme) => ({
              justifyContent: 'space-between',
              display: 'flex',
              mt: theme.spacing(5),
              width: '100%',
            })}
          >
            <Button variant="outlined" size="large" sx={{ width: '216px' }} onClick={() => handleClose()}>
              Cancel
            </Button>
            <Button variant="contained" size="large" sx={{ width: '216px' }} onClick={() => handleSave()} disabled={isDisabled}>
              Save
            </Button>
          </Box>
        </Box>
      </Box>
    </Dialog>
  );
};
