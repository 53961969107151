import { useDocumentTitle, CAPABILITIES } from '@stationwise/component-module';
import { AppPageLayout } from '../../AppPageLayout';
import { EmployeesTab } from '../EmployeesTab';
import { PayrollMain } from './components/PayrollMain';

export const Payroll = () => {
  useDocumentTitle('Payroll');
  return (
    <AppPageLayout
      sx={(theme) => ({ background: theme.palette.common.white })}
      requiredCapability={CAPABILITIES.VIEW_ADMIN_PAYROLL}
    >
      <EmployeesTab defaultTab="payroll">
        <PayrollMain />
      </EmployeesTab>
    </AppPageLayout>
  );
};
