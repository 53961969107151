import { Box } from '@mui/material';
import { isValid, setMinutes } from 'date-fns';
import { useEffect, useState } from 'react';
import { Trash02Icon20, useRosterContext, MultipleSelect, AdminSelect, AdminSelectItem } from '@stationwise/component-module';
import { DEFAULT_RANK } from '@stationwise/share-types';
import { diffCycleMinutes } from '@stationwise/shift-summary-helper';
import { IPersonRequirements } from '../..';
import { TimePicker } from '../../../../../../../components/Common';

const checkIsDurationValid = (startMinutes: number, endMinutes: number) => {
  return startMinutes >= 0 && startMinutes <= 24 * 60 && endMinutes > startMinutes && endMinutes <= 24 * 60;
};

export const checkIsPersonValid = (person: IPersonRequirements) => {
  return !!person.rank && checkIsDurationValid(person.startMinutes, person.endMinutes);
};

export const makeDefaultPerson = (): IPersonRequirements => ({
  rank: DEFAULT_RANK.code,
  specialities: [],
  startMinutes: 0,
  endMinutes: 12 * 60,
});

export const copyPerson = (person: IPersonRequirements) => ({ ...person, specialities: [...person.specialities] });

export const Person = ({
  currentPerson,
  ranks,
  specialities,
  removePerson,
  updatePerson,
  currentPersonNumber,
}: {
  currentPerson: IPersonRequirements;
  ranks: AdminSelectItem[];
  specialities: AdminSelectItem[];
  removePerson: (currentPerson: IPersonRequirements) => void;
  updatePerson: (currentPerson: IPersonRequirements, newPerson: Partial<IPersonRequirements>) => void;
  currentPersonNumber: number;
}) => {
  const { shiftSummaryHelper } = useRosterContext();
  const { shiftDuration } = shiftSummaryHelper;

  const [selectedRank, setSelectedRank] = useState<string>(currentPerson.rank);
  const [selectedSpecialities, setSelectedSpecialities] = useState<string[]>(currentPerson.specialities);
  const [startTime, setStartTime] = useState<Date | null>(() => {
    return setMinutes(shiftDuration.startTime, shiftDuration.startTime.getMinutes() + currentPerson.startMinutes);
  });
  const [endTime, setEndTime] = useState<Date | null>(() => {
    return setMinutes(shiftDuration.startTime, shiftDuration.startTime.getMinutes() + currentPerson.endMinutes);
  });

  let durationMinutes = 0;
  if (isValid(startTime) && isValid(endTime)) {
    durationMinutes = diffCycleMinutes(endTime, startTime) || shiftDuration.hours * 60;
  }

  const startMinutes = !isValid(startTime) ? 0 : diffCycleMinutes(startTime, shiftDuration.startTime);
  const endMinutes = startMinutes + durationMinutes;

  useEffect(() => {
    updatePerson(currentPerson, {
      rank: selectedRank,
      specialities: selectedSpecialities,
      startMinutes,
      endMinutes,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRank, selectedSpecialities, startMinutes, endMinutes]);

  return (
    <Box
      sx={(theme) => ({
        mt: theme.spacing(2.5),
        borderRadius: '12px',
        border: `1px solid ${theme.palette.gray[200]}`,
        p: theme.spacing(1.5),
      })}
    >
      <Box display="flex" justifyContent="space-between">
        <Box sx={(theme) => ({ typography: 'bodyLMedium', color: theme.palette.gray[700] })}>Person {currentPersonNumber}</Box>
        <Box
          sx={(theme) => ({ '& svg path': { stroke: theme.palette.gray[400] }, cursor: 'pointer' })}
          onClick={() => removePerson(currentPerson)}
        >
          <Trash02Icon20 />
        </Box>
      </Box>
      <Box sx={(theme) => ({ display: 'flex', gap: theme.spacing(2), mt: theme.spacing(2.5) })}>
        <Box sx={{ flex: 1, minWidth: '1px' }}>
          <Box sx={(theme) => ({ mb: theme.spacing(1), typography: 'bodySMedium' })}>Rank</Box>
          <AdminSelect
            placeholder="Choose rank"
            items={ranks}
            selectedItem={selectedRank}
            setSelectedItem={setSelectedRank}
            secondLabelPosition="start"
            secondLabelStyles={{ height: '24px', width: '56px', ml: 1.5 }}
          />
        </Box>
        <Box sx={{ flex: 1, minWidth: '1px' }}>
          <Box sx={(theme) => ({ mb: theme.spacing(1), typography: 'bodySMedium' })}>Specialities</Box>
          <MultipleSelect
            placeholder="No additional requirements"
            items={specialities}
            selectedItems={selectedSpecialities}
            setSelectedItems={setSelectedSpecialities}
          />
        </Box>
      </Box>
      <Box sx={(theme) => ({ display: 'flex', gap: theme.spacing(2), mt: theme.spacing(1.5) })}>
        <Box sx={(theme) => ({ flex: 1, display: 'flex', gap: theme.spacing(2) })}>
          <Box>
            <Box sx={(theme) => ({ mb: theme.spacing(1), typography: 'bodySMedium' })}>Start time</Box>
            <TimePicker
              value={startTime}
              setValue={setStartTime}
              sxProps={{ width: '106px', '& .MuiInputBase-input': { pl: 0 } }}
            />
          </Box>
          <Box>
            <Box sx={(theme) => ({ mb: theme.spacing(1), typography: 'bodySMedium' })}>End time</Box>
            <TimePicker value={endTime} setValue={setEndTime} sxProps={{ width: '106px', '& .MuiInputBase-input': { pl: 0 } }} />
          </Box>
        </Box>
        <Box sx={(theme) => ({ flex: 1, typography: 'bodySRegular', whiteSpace: 'nowrap', pt: theme.spacing(5) })}>
          <Box component="span" sx={(theme) => ({ color: theme.palette.gray[500], mr: theme.spacing(1) })}>
            Duration:
          </Box>
          {(() => {
            if (!checkIsDurationValid(startMinutes, endMinutes)) {
              return (
                <Box component="span" sx={(theme) => ({ color: theme.palette.rose[700] })}>
                  Invalid
                </Box>
              );
            }

            const durationHH = Math.floor(durationMinutes / 60);
            const durationMM = durationMinutes - durationHH * 60;
            return <Box component="span">{`${`${durationHH}`.padStart(2, '0')} h ${`${durationMM}`.padStart(2, '0')} m`}</Box>;
          })()}
        </Box>
      </Box>
    </Box>
  );
};
