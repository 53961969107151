import { useEffect } from 'react';
import { HiringEnginePositionController } from '@stationwise/component-module';
import { HiringEngineVacancy } from '@stationwise/share-types';
import { useVacancyFilters } from '../hooks/useVacancyFilters';

interface VacancyPositionControllerProps {
  groupedVacancies: Record<number, HiringEngineVacancy[]>;
  isDisabled?: boolean;
  onVacanciesFiltered: (filteredVacancies: Record<number, HiringEngineVacancy[]>) => void;
}

export const VacancyPositionController = ({
  groupedVacancies,
  isDisabled,
  onVacanciesFiltered,
}: VacancyPositionControllerProps) => {
  const filters = useVacancyFilters(groupedVacancies);

  useEffect(() => {
    onVacanciesFiltered(filters.filteredVacancies);
  }, [filters.filteredVacancies, onVacanciesFiltered]);

  return (
    <HiringEnginePositionController
      vacantStations={filters.vacantStations}
      vacantApparatuses={filters.vacantApparatuses}
      vacantBattalions={filters.vacantBattalions}
      selectedBattalions={filters.selectedBattalions}
      selectedStations={filters.selectedStations}
      selectedApparatuses={filters.selectedApparatuses}
      onSelectedStationsChange={filters.setSelectedStations}
      onSelectedApparatusesChange={filters.setSelectedApparatuses}
      onSelectedBattalionsChange={filters.setSelectedBattalions}
      isDisabled={isDisabled}
    />
  );
};
