import { Dialog } from '@mui/material';
import { DialogXButton, useRosterContext } from '@stationwise/component-module';
import { TemporaryNonShiftType } from '@stationwise/share-types';
import { MoveToTemporaryNonShiftStepper } from './MoveToTemporaryNonShiftStepper';

interface MoveToTemporaryNonShiftModalProps {
  temporaryNonShiftType: TemporaryNonShiftType;
}

export const MoveToTemporaryNonShiftModal = ({ temporaryNonShiftType }: MoveToTemporaryNonShiftModalProps) => {
  const { selectedFilledPositionState } = useRosterContext();

  return (
    <Dialog
      open={selectedFilledPositionState.selectedMoveToTemporaryNonShiftType === temporaryNonShiftType}
      onClose={() => selectedFilledPositionState.setSelectedMoveToTemporaryNonShiftType(null)}
    >
      <DialogXButton onClose={() => selectedFilledPositionState.setSelectedMoveToTemporaryNonShiftType(null)} />
      <MoveToTemporaryNonShiftStepper temporaryNonShiftType={temporaryNonShiftType} />
    </Dialog>
  );
};
