import { useState } from 'react';
import { SnackbarService } from '@stationwise/component-module';
import { nowrap } from '@stationwise/share-utils';
import { useShiftPlanContext } from '../ShiftPlanContext';
import { ShiftPlanDialogActions, WarningMessage } from '../ShiftPlanDialog';

export const RemoveStructWarning = () => {
  const { selectedStruct, setSelectedAction, saveAsDraft } = useShiftPlanContext();
  const [{ station, apparatus, position }] = useState(selectedStruct);

  let structName = '';
  let structType = '';
  let structId = '';
  if (station && apparatus && position) {
    structName = `${nowrap(position.rank.code)} from ${nowrap(apparatus.name)}`;
    structType = 'position';
    structId = position.id;
  } else if (station && apparatus) {
    structName = `${nowrap(apparatus.name)} from ${nowrap(station.stationName)}`;
    structType = 'apparatus';
    structId = apparatus.id;
  } else if (station) {
    structName = nowrap(station.stationName);
    structType = 'station';
    structId = station.stationId;
  }

  const onRemoveStruct = () => {
    if (structType && structId) {
      const payload = { action: 'REMOVE_STRUCT', structType, structId };
      saveAsDraft(payload, () => {
        setSelectedAction(null);
        SnackbarService.notify({ content: `Removed ${structName}`, severity: 'success', duration: 5000 });
      });
    }
  };

  return (
    <>
      <WarningMessage
        primaryText={`You are about to remove ${structName}.`}
        secondaryText="All assigned employees of all teams will be moved to the floater station."
      />
      <ShiftPlanDialogActions saveText="Confirm" onSave={onRemoveStruct} />
    </>
  );
};
