import { Box, InputAdornment, Link } from '@mui/material';
import { captureException } from '@sentry/react';
import { FormEvent, useState } from 'react';
import { client, isAxiosError } from '@stationwise/share-api';
import { CircleWarningIcon20, Lock01Icon24, Mail01Icon24 } from '../../../assets';
import { useNextURLQueryParam } from '../../../hooks';
import { Button } from '../../Button';
import { ErrorBanner } from '../../ErrorBanner';
import { Input } from '../../Input';
import { MagicLinkPageLayout } from './MagicLinkPageLayout';

export const MagicLinkPage = () => {
  const [email, setEmail] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [wasSubmitted, setWasSubmitted] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const nextUrl = useNextURLQueryParam();

  const setAnError = (message: string) => {
    setErrorMessage(message);
    !wasSubmitted && setEmail('');
  };

  const handleSubmit = async () => {
    if (isSubmitting) {
      return;
    }

    setIsSubmitting(true);
    try {
      await client.post('/auth/login/magic-link/', { email: email, next: nextUrl });
      setWasSubmitted(true);
    } catch (err) {
      const message = isAxiosError(err) ? err.response?.data.error : '';
      if (!message) {
        captureException(err);
      }
      setAnError(message || 'Something went wrong please try again later');
    }
    setIsSubmitting(false);
  };

  const handleFormSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    await handleSubmit();
  };

  const errorBanner = !isSubmitting && !!errorMessage && <ErrorBanner sx={{ mt: -1, mb: 2 }}>{errorMessage}</ErrorBanner>;

  if (wasSubmitted) {
    return (
      <MagicLinkPageLayout
        icon={<Mail01Icon24 />}
        title="Check your email"
        subtitle={
          <span>
            {isSubmitting ? "We're sending a magic link to" : "We've sent a magic link to"}
            <br />
            <Box component="span" sx={{ fontWeight: 600 }}>
              {email}
            </Box>
          </span>
        }
        body={
          <Box>
            {errorBanner}
            <Box
              sx={(theme) => ({
                color: theme.palette.gray[600],
                mb: 5,
                typography: 'bodyMRegular',
                textAlign: 'center',
              })}
            >
              <Box component="span" sx={{ verticalAlign: 'middle' }}>
                {"Didn't receive the email? "}
              </Box>
              <Link
                component="button"
                underline="none"
                onClick={handleSubmit}
                sx={(theme) => ({
                  color: theme.palette.blue[600],
                  fontWeight: 600,
                  verticalAlign: 'middle',
                })}
              >
                Click to resend
              </Link>
            </Box>
          </Box>
        }
      />
    );
  }

  return (
    <MagicLinkPageLayout
      icon={<Lock01Icon24 />}
      title={
        <span>
          Sign in with
          <br />
          magic link
        </span>
      }
      subtitle="Enter the email address associated with your account and we will send you a magic link."
      body={
        <Box component="form" onSubmit={handleFormSubmit}>
          {errorBanner}
          <Box sx={{ mb: 5 }}>
            <Input
              disabled={isSubmitting}
              error={!!errorMessage}
              label="Email"
              autoComplete="email"
              placeholder="you@example.gov"
              type="email"
              value={email}
              onChange={(event) => {
                setEmail(event.currentTarget.value);
                setErrorMessage('');
              }}
              endAdornment={
                !errorMessage ? undefined : (
                  <InputAdornment position="end">
                    <CircleWarningIcon20 />
                  </InputAdornment>
                )
              }
            />
          </Box>
          <Button
            variant="contained"
            size="large"
            type="submit"
            disabled={!email}
            loading={isSubmitting}
            sx={(theme) => ({
              mb: 2,
              width: '100%',
              [theme.breakpoints.up('sm')]: { mb: 5 },
            })}
          >
            Continue
          </Button>
        </Box>
      }
    />
  );
};
