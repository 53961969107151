import { Box } from '@mui/material';
import React from 'react';
import { Team, WorkGroup } from '@stationwise/share-types';
import { CategoryChip } from './CategoryChip';
import { ConversationProps, Conversation } from './Conversation';
import { Header, HeaderProps } from './Header';
import { Progress } from './Progress';
import { Recipients, RecipientsProps } from './Recipients';
import { TimeLabel } from './TimeLabel';

export interface ConversationCardProps extends RecipientsProps {
  conversationId: number;
  headerProps: Pick<HeaderProps, 'color' | 'personName' | 'personRole'>;
  messageProps: Pick<ConversationProps, 'message' | 'subject'>;
  progress: number;
  tasks: number;
  category: string;
  time: string;
  isMessageOpen?: boolean;
  onConversationClick?: (id: number) => void;
  onCancelConversation?: (id: number) => void;
  isCancelled?: boolean;
  team: Team[];
  workGroups: WorkGroup[];
}

export const ConversationCard = ({
  headerProps,
  isLoading,
  messageProps,
  recipients,
  progress,
  tasks,
  category,
  time,
  conversationId,
  isCancelled,
  onConversationClick,
  isMessageOpen,
  onCancelConversation,
  team,
  workGroups,
}: ConversationCardProps) => {
  return (
    <Box
      sx={(theme) => ({
        display: 'flex',
        flexDirection: 'column',
        borderRadius: theme.spacing(1.5),
        border: (() => {
          if (isMessageOpen) {
            return `1px solid ${theme.palette.gray[900]}`;
          } else {
            return '1px solid transparent';
          }
        })(),
        backgroundColor: isMessageOpen ? theme.palette.gray[100] : 'inherit',
        padding: '8px 12px 12px',
        '&:hover': {
          border: `1px solid ${theme.palette.gray[900]}`,
          backgroundColor: theme.palette.gray[100],
          cursor: 'pointer',
        },
        '&:focus': {
          border: `1px solid ${theme.palette.gray[900]}`,
          backgroundColor: theme.palette.gray[100],
          cursor: 'pointer',
        },
        '&:hover .message-header-more-icon': {
          visibility: 'visible',
        },
        mb: theme.spacing(1),
        opacity: isCancelled ? 0.6 : 1,
      })}
    >
      <Header
        personName={headerProps.personName}
        personRole={headerProps.personRole}
        color={headerProps.color}
        isLoading={isLoading}
        conversationId={conversationId}
        sendFollowUp={onConversationClick}
        onCancelConversation={onCancelConversation}
        isCancelled={isCancelled}
      />
      <Box sx={(theme) => ({ mt: theme.spacing(0.5) })}>
        <Conversation subject={messageProps.subject} message={messageProps.message} isLoading={isLoading} />
      </Box>
      <Box mt={0.5}>
        <TimeLabel isLoading={isLoading} time={time} />
      </Box>
      <Box display="flex" mt={1} justifyContent="space-between" gap={3}>
        <Recipients recipients={recipients} isLoading={isLoading} workGroups={workGroups} team={team} />
        {category === 'To-Do' && <Progress progress={progress} tasks={tasks} isLoading={isLoading} />}
        <CategoryChip category={category} isLoading={isLoading} />
      </Box>
    </Box>
  );
};
