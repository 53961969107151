import { ReactComponent as CheckSymbol } from '@material-symbols/svg-400/outlined/check.svg';
import { Box, ButtonBase } from '@mui/material';
import React from 'react';
import { SvgIcon } from '../SvgIcon';

interface CheckboxProps {
  checked?: boolean;
  onClick?: () => void;
}

export const Checkbox = ({ checked, onClick }: CheckboxProps) => {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
      onClick={onClick}
    >
      <ButtonBase>
        <Box
          sx={(theme) => ({
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            borderRadius: '6px',
            width: '20px',
            height: '20px',
            border: `1px solid ${checked ? theme.palette.lightBlue[300] : theme.palette.gray[200]}`,
            boxShadow: `0px 1px 2px 0px rgba(10, 14, 22, 0.05)`,
            cursor: 'pointer',
            backgroundColor: checked ? theme.palette.blue[600] : theme.palette.common.white,
            '&:hover': {
              backgroundColor: checked ? theme.palette.blue[500] : theme.palette.gray[100],
            },
          })}
        >
          {!!checked && (
            <SvgIcon
              component={CheckSymbol}
              sx={(theme) => ({
                color: theme.palette.common.white,
                width: '16px',
                height: '16px',
              })}
            />
          )}
        </Box>
      </ButtonBase>
    </Box>
  );
};
