import { ReactComponent as AddSymbol } from '@material-symbols/svg-400/outlined/add.svg';
import { Box } from '@mui/material';
import { Dispatch, SetStateAction } from 'react';
import { Button, ChevronRightIcon24, SvgIcon } from '@stationwise/component-module';
import { HeaderOption } from './HeaderOption';

interface HeaderProps {
  options: { label: string }[];
  selectedOption: number;
  onClick?: (index: number) => void;
  showCompose?: boolean;
  onComposeClick: () => void;
  setOpen: Dispatch<SetStateAction<boolean>>;
}

export const Header = ({ options, selectedOption, onClick, showCompose = false, onComposeClick, setOpen }: HeaderProps) => {
  const handleClickHeader = (index: number) => {
    if (onClick) {
      onClick(index);
    }
  };

  return (
    <Box display="flex" justifyContent="space-between" alignItems="center" sx={{ pb: '22px', pl: '16px' }}>
      <Box display="flex" gap="28px">
        {options.map((option, index) => (
          <HeaderOption
            key={option.label}
            label={option.label}
            isSelected={index === selectedOption}
            onClick={() => (onClick ? handleClickHeader(index) : undefined)}
          />
        ))}
      </Box>
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        {showCompose && (
          <Box>
            <Button
              data-cy="compose-button"
              variant="contained"
              startIcon={<SvgIcon component={AddSymbol} />}
              onClick={onComposeClick}
            >
              Compose
            </Button>
          </Box>
        )}
        <Box
          sx={(theme) => ({
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: '65px',
            height: '68px',
            ml: theme.spacing(1),
            '&:hover': {
              backgroundColor: theme.palette.gray[200],
            },
            cursor: 'pointer',
          })}
          onClick={() => setOpen(false)}
        >
          <ChevronRightIcon24 />
        </Box>
      </Box>
    </Box>
  );
};
