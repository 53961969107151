import { Box } from '@mui/material';
import { format } from 'date-fns';
import { Fragment } from 'react';
import { ActingAsChip, OvalChip, useRosterContext, theme, useLoadedDepartmentInfoContext } from '@stationwise/component-module';
import { RosterApparatus, RosterPosition, RosterEmployee, EmployeeOffView, RosterStation } from '@stationwise/share-types';
import { shortenEmployeeName, makeFirstLettersCapital } from '@stationwise/share-utils';
import {
  IShiftSummaryHelper,
  checkHasOvertimePayCode,
  checkIsActingAsRank,
  filterNonRankCertifications,
} from '@stationwise/shift-summary-helper';
import { Cell } from './Cell';
import { hasEmployeeNotes } from './PrintDaySchedule';
import { Row } from './Row';

interface PositionRowsProps {
  position: RosterPosition;
  rowNumber: number;
  splits: { vacancy: RosterPosition; employee: RosterEmployee | null }[];
}

export const PositionRows = ({ position, rowNumber, splits }: PositionRowsProps) => {
  const { shiftSummaryHelper } = useRosterContext();
  const {
    state: { departmentInfo },
  } = useLoadedDepartmentInfoContext();

  const getRowClasses = (splitItem: RosterPosition | RosterEmployee, splitIndex: number) => {
    const classes: string[] = [];
    if (splitItem === splits[splitIndex].vacancy) {
      classes.push('SWPrintDaySchedule-vacant-row');
    }
    if (splitIndex > 0) {
      classes.push('SWPrintDaySchedule-split-row');
    }
    return classes.join(' ');
  };

  const renderEmployeeName = (employee: RosterEmployee) => {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Box sx={{ fontSize: '12px', fontWeight: 500 }}>{shortenEmployeeName(employee.name)}</Box>
      </Box>
    );
  };

  const renderTimeLabel = (splitItem: RosterPosition | RosterEmployee) => {
    return (
      <Box>
        <Box component="span">{format(splitItem.startDateTime, 'HHmm')}</Box>
        {'-'}
        <Box component="span">{format(splitItem.endDateTime, 'HHmm')}</Box>
      </Box>
    );
  };

  const renderEmployeeShiftType = (employee: RosterEmployee) => {
    const label = checkHasOvertimePayCode(employee) ? 'OT' : employee.trade ? 'TRD' : '';

    return (
      label && (
        <Box sx={{ display: 'inline-flex', justifyContent: 'center', alignItems: 'center' }}>
          <OvalChip label={label} backgroundColor={theme.palette.gray[900]} />
        </Box>
      )
    );
  };

  const renderEmployeeNotes = (employee: RosterEmployee) => {
    return (
      <Box sx={{ display: 'flex', flexDirection: 'row', gap: 0.5, pl: 0.5, alignItems: 'center' }}>
        {renderEmployeeShiftType(employee)}
        {position.isTemporary && <OvalChip label="EXC" backgroundColor={theme.palette.gray[900]} />}
        {employee.trade && <Box sx={{ typography: 'bodyXSRegular' }}>{shortenEmployeeName(employee.trade.requester.name)}</Box>}
        {splits.length > 1 && (
          <Box sx={{ typography: 'bodyXSRegular', color: theme.palette.gray[900] }}>{renderTimeLabel(employee)}</Box>
        )}
        {employee.noteOverride?.note && (
          <Box sx={{ typography: 'bodyXSRegular', color: theme.palette.gray[900] }}>{employee.noteOverride.note}</Box>
        )}
      </Box>
    );
  };

  const renderVacantRow = (vacancy: RosterPosition, splitIndex: number) => {
    return (
      <Row
        key={`vacant:${position.id}:${vacancy.startDateTime.getTime()}:${vacancy.endDateTime.getTime()}`}
        className={getRowClasses(vacancy, splitIndex)}
        sx={{ background: 'white', color: 'black' }}
      >
        <Cell colIndex={0}>{rowNumber}</Cell>
        <Cell colIndex={1}>
          <Box sx={{ color: '#A7A7A7' }}>Vacant</Box>
        </Cell>
        <Cell colIndex={2}>{position.rank.code}</Cell>
        <Cell colIndex={3} />
      </Row>
    );
  };

  const renderAssignedRow = (employee: RosterEmployee, splitIndex: number) => {
    const hasNotes = hasEmployeeNotes(employee, position, splits.length);

    return (
      <Fragment key={`assigned:${position.id}:${employee.activeId}`}>
        <Row
          className={getRowClasses(employee, splitIndex)}
          sx={{
            borderBottom: hasNotes ? 'none' : undefined,
          }}
        >
          <Cell colIndex={0}>{rowNumber + splitIndex}</Cell>
          <Cell colIndex={1}>{renderEmployeeName(employee)}</Cell>
          <Cell colIndex={2}>{renderPositionRank(position.rank.code, employee)}</Cell>
          <Cell colIndex={3}>{renderEmployeeCertifications(shiftSummaryHelper, employee)}</Cell>
        </Row>
        {hasNotes && (
          <Row className={getRowClasses(employee, splitIndex)} sx={{ alignItems: 'flex-start', justifyContent: 'flex-start' }}>
            <Cell colIndex={0} sx={{ justifySelf: 'flex-start', whiteSpace: 'nowrap' }}>
              <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                <Box sx={{ typography: 'bodyXSRegular', color: theme.palette.gray[900] }}>Notes:</Box>
                {renderEmployeeNotes(employee)}
              </Box>
            </Cell>
          </Row>
        )}
      </Fragment>
    );
  };

  const renderPositionRank = (rankCode: string, employee: RosterEmployee) => {
    return (
      <Box sx={{ display: 'inline-flex', justifyContent: 'flex-start', alignItems: 'center', gap: '2px' }}>
        {departmentInfo.settings.actingRolesEnabled && checkIsActingAsRank(shiftSummaryHelper, position, employee) && (
          <Box sx={{ display: 'inline-flex', alignItems: 'center', gap: '2px' }}>
            {employee.rank.code}
            <Box sx={{ display: 'inline-flex', alignItems: 'center' }}>
              <ActingAsChip fillColor={theme.palette.gray[900]} sx={{ backgroundSize: '14px 20px', width: '14px', pl: '2px' }} />
            </Box>
          </Box>
        )}
        <Box sx={{ display: 'inline-flex', alignItems: 'center' }}>{rankCode}</Box>
      </Box>
    );
  };

  return splits.map(({ vacancy, employee }, splitIndex) => {
    if (employee) {
      return renderAssignedRow(employee, splitIndex);
    } else {
      return renderVacantRow(vacancy, splitIndex);
    }
  });
};

export const renderAbsentPersonnel = (positionEmployeesOffMap: Map<string, EmployeeOffView[]>) => {
  if (positionEmployeesOffMap.size === 0) {
    return null;
  }
  let rowCounter = 1;
  return (
    <Box sx={{ paddingBottom: '8px' }}>
      <Row
        sx={(theme) => ({
          background: theme.palette.gray[900],
          borderColor: theme.palette.gray[900],
        })}
      >
        <Cell colIndex={1} sx={{ py: 0.5, width: '100%' }}>
          <Box sx={{ pl: '8px', color: theme.palette.common.white }}>Absent Personnel</Box>
        </Cell>
      </Row>
      {Array.from(positionEmployeesOffMap.values()).map((employeesOff) =>
        employeesOff.map((employee) => {
          const currentRow = rowCounter++;
          return (
            <Row key={`absent:${employee.requestType}:${employee.id}`}>
              <Cell colIndex={0}>{currentRow}</Cell>
              <Cell colIndex={1}>
                <Box>{shortenEmployeeName(`${employee.employeeOff.firstName} ${employee.employeeOff.lastName}`)}</Box>
              </Cell>
              <Cell colIndex={2}>{employee.employeeOff.rank.code}</Cell>
              <Cell colIndex={3} sx={{ pl: 1 }}>
                {makeFirstLettersCapital(employee.title.toLowerCase())}
              </Cell>
            </Row>
          );
        }),
      )}
    </Box>
  );
};

export const renderOvertimeHires = (overtimeEmployees: OvertimeEmployeeAssignment[]) => {
  let rowCounter = 1;
  if (overtimeEmployees.length === 0) return null;

  return (
    <Fragment>
      <Row
        sx={(theme) => ({
          background: theme.palette.gray[900],
          borderColor: theme.palette.gray[900],
        })}
      >
        <Cell colIndex={1} sx={{ py: 0.5, width: '100%', backgroundColor: theme.palette.gray[900] }}>
          <Box sx={{ pl: '8px', color: theme.palette.common.white }}>Overtime Hires</Box>
        </Cell>
      </Row>
      {overtimeEmployees.map(({ employee, position, apparatus, station }) => {
        const currentRow = rowCounter++;
        return (
          <Fragment key={`overtime:${employee.activeId}`}>
            <Row>
              <Cell colIndex={0}>{currentRow}</Cell>
              <Cell colIndex={1}>
                <Box sx={{ fontSize: '12px', fontWeight: 400 }}>{`${shortenEmployeeName(employee.name)}`}</Box>
              </Cell>
              <Cell colIndex={2}>{position.rank.code}</Cell>
              <Cell colIndex={3}></Cell>
            </Row>
            <Row sx={{ alignItems: 'flex-start', justifyContent: 'flex-start' }}>
              <Cell colIndex={0} sx={{ pl: 1 }}>
                <Box sx={{ display: 'flex', flexDirection: 'row', whiteSpace: 'nowrap' }}>
                  <Box sx={{ typography: 'bodyXSRegular', color: theme.palette.gray[900] }}>Notes:</Box>
                  <Box
                    sx={{ typography: 'bodyXSRegular', color: theme.palette.gray[900], display: 'flex', flexDirection: 'row' }}
                  >{`${station.stationName} > ${apparatus.name}`}</Box>
                </Box>
              </Cell>
            </Row>
          </Fragment>
        );
      })}
    </Fragment>
  );
};

export const renderFloaters = (shiftSummaryHelper: IShiftSummaryHelper, floaters: RosterEmployee[]) => {
  let rowCounter = 1;
  if (floaters.length === 0) return null;

  return (
    <Box sx={{ paddingBottom: '8px' }}>
      <Row
        sx={(theme) => ({
          background: theme.palette.gray[900],
          borderColor: theme.palette.gray[900],
        })}
      >
        <Cell colIndex={1} sx={{ py: 0.5, width: '100%', backgroundColor: theme.palette.gray[900] }}>
          <Box sx={{ pl: '8px', color: theme.palette.common.white }}>Floaters</Box>
        </Cell>
      </Row>
      {floaters.map((floater) => {
        const currentRow = rowCounter++;
        return (
          <Row key={`floater:${floater.activeId}`}>
            <Cell colIndex={0}>{currentRow}</Cell>
            <Cell colIndex={1}>{shortenEmployeeName(floater.name)}</Cell>
            <Cell colIndex={2}>{floater.rank.code}</Cell>
            <Cell colIndex={3}>{renderEmployeeCertifications(shiftSummaryHelper, floater)}</Cell>
          </Row>
        );
      })}
    </Box>
  );
};

const renderEmployeeCertifications = (shiftSummaryHelper: IShiftSummaryHelper, employee: RosterEmployee) => {
  const nonRankCerts = filterNonRankCertifications(shiftSummaryHelper.departmentInfo, employee.certifications, employee.rank);
  const displayCerts = nonRankCerts.slice(0, 2);

  return (
    <Box
      sx={{
        display: 'inline-flex',
        maxWidth: '130px',
        overflow: 'hidden',
        flexDirection: 'row',
        flexWrap: 'wrap',
        gap: 0.5,
        whiteSpace: 'normal',
      }}
    >
      {displayCerts.map((cert, index) => (
        <Box key={`${index}-${cert.code}`}>{cert.code}</Box>
      ))}
    </Box>
  );
};

export const findOvertimeEmployees = (stations: RosterStation[]): OvertimeEmployeeAssignment[] => {
  const overtimeEmployees: OvertimeEmployeeAssignment[] = [];

  // Iterate through stations to find overtime employees
  for (const station of stations) {
    for (const apparatus of station.apparatuses) {
      apparatus.positions.forEach((position) => {
        position.employees.forEach((employee) => {
          if (checkHasOvertimePayCode(employee)) {
            overtimeEmployees.push({
              employee,
              position,
              apparatus,
              station,
            });
          }
        });
      });
    }
  }
  return overtimeEmployees;
};
export interface OvertimeEmployeeAssignment {
  employee: RosterEmployee;
  position: RosterPosition;
  apparatus: RosterApparatus;
  station: RosterStation;
}
