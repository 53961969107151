import { EventInput } from '@fullcalendar/core';
import { Box, Typography } from '@mui/material';
import { useMemo, useState, useEffect } from 'react';
import {
  MenuAltIcon,
  HourglassIcon,
  Loader,
  formatDate,
  useLoadedAuthUserContext,
  useAuthUserCapabilities,
} from '@stationwise/component-module';
import { DetailFieldsStaffingList } from '@stationwise/share-types';
import { OvertimeList } from './OvertimeList';

export const OvertimeCard = ({
  shift,
  displayOvertimeInfo,
}: {
  displayOvertimeInfo: (dateStr: string) => Promise<DetailFieldsStaffingList>;
  shift: EventInput;
}) => {
  const { state: authUserState } = useLoadedAuthUserContext();
  const capabilities = useAuthUserCapabilities();
  const [overtimeInfoError, setOvertimeInfoError] = useState('');
  const [isError, setIsError] = useState(false);
  const [overtimeInfoLoading, setOvertimeInfoLoading] = useState(false);
  const [overtimeInfo, setOvertimeInfo] = useState<DetailFieldsStaffingList | null>(null);

  const { meItem, meTeam, meTeamPosition } = useMemo(() => {
    const items = overtimeInfo?.items || [];
    const meItem = items.find((item) => item.employee.id === authUserState.employee.id);
    const meTeam = meItem?.employee.team;

    let meTeamPosition = 0;
    if (meTeam) {
      meTeamPosition = items.filter((item) => item.employee.team?.id === meTeam.id && item.order < meItem.order).length + 1;
    }

    return { meItem, meTeam, meTeamPosition };
  }, [overtimeInfo?.items, authUserState.employee.id]);

  useEffect(() => {
    fetchOvertime();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shift]);

  const fetchOvertime = () => {
    if (!capabilities.VIEW_MY_STAFFING_LISTS && !capabilities.VIEW_STAFFING_LIST) {
      return;
    }

    setOvertimeInfoLoading(true);
    if (shift.start) {
      const formattedDate = formatDate(new Date(shift.start.toString()));
      displayOvertimeInfo(formattedDate)
        .then((response) => {
          setOvertimeInfo(response);
          setOvertimeInfoLoading(false);
          setIsError(false);
          setOvertimeInfoError('');
        })
        .catch((err) => {
          setIsError(true);
          setOvertimeInfoError(err);
          setOvertimeInfoLoading(false);
        });
    }
  };
  return (
    <>
      {overtimeInfoLoading && !overtimeInfo && <Loader sx={{ mt: 2, mb: 1 }} />}
      {isError && <div>{`Something went wrong please try again later ${overtimeInfoError}`}</div>}
      {!overtimeInfoLoading && !isError && overtimeInfo && (
        <>
          <Box
            display="flex"
            justifyContent="space-between"
            sx={(theme) => ({
              borderTop: `solid 1px ${theme.palette.gray[100]}`,
              pt: '16px',
            })}
          >
            <Typography
              variant="bodyLMedium"
              sx={(theme) => ({
                color: theme.palette.gray[900],
              })}
            >
              {shift.title ? shift.title.charAt(0).toUpperCase() + shift.title.slice(1).toLowerCase() : ''}
            </Typography>
          </Box>
          <Box display="flex" alignItems="center" justifyContent="space-between">
            <Box display="flex">
              <Box sx={{ marginTop: '2px' }}>
                <HourglassIcon />
              </Box>
              <Typography
                variant="bodyMMedium"
                sx={(theme) => ({
                  color: theme.palette.gray[900],
                  ml: '16px',
                })}
              >
                {`${meTeam?.name || ''} position`}
              </Typography>
            </Box>
            <Box
              display="flex"
              sx={{
                textAlign: 'right',
              }}
            >
              <Typography
                variant="bodyMSemibold"
                sx={(theme) => ({
                  color: theme.palette.gray[900],
                })}
              >
                {meTeamPosition}
              </Typography>
            </Box>
          </Box>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            sx={(theme) => ({
              borderBottom: `solid 1px ${theme.palette.gray[100]}`,
              pb: '16px',
            })}
          >
            <Box display="flex">
              <Box sx={{ marginTop: '2px' }}>
                <MenuAltIcon />
              </Box>
              <Typography
                variant="bodyMMedium"
                sx={(theme) => ({
                  color: theme.palette.gray[900],
                  ml: '16px',
                })}
              >
                Overall position
              </Typography>
            </Box>
            <Box
              display="flex"
              sx={{
                textAlign: 'right',
              }}
            >
              <Typography
                variant="bodyMSemibold"
                sx={(theme) => ({
                  color: theme.palette.gray[900],
                })}
              >
                {meItem?.order ?? 0}
              </Typography>
            </Box>
          </Box>
          {overtimeInfo.items.length > 0 && <OvertimeList overtimeInfo={overtimeInfo} />}
        </>
      )}
    </>
  );
};
