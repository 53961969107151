import { theme } from '@stationwise/component-module';

export interface Activity {
  name: string;
  activityType: string;
  timestamp: Date;
  comments?: string;
  target?: {
    subject: string;
    action: string;
    url?: string;
  };
}

export function getTypeColor(type: string) {
  switch (type) {
    case 'Request':
      return theme.palette.teal[500];
    case 'Review':
      return theme.palette.blue[500];
    case 'Staffing':
      return theme.palette.yellow[500];
    case 'Message':
      return theme.palette.lightBlue[500];
    case 'Cancellation':
      return theme.palette.red[500];
    case 'Bulk Update':
      return theme.palette.purple[500];
    default:
      return theme.palette.green[500];
  }
}
