import { Typography, Box } from '@mui/material';
import { LoginOptionCardProps } from '../types';
import { StepIndicator } from './StepIndicator';

export const LoginOptionCard = ({
  height,
  width,
  borderRadius,
  stepIndicatorTitle,
  title,
  backgroundColor,
  children,
  mb,
}: LoginOptionCardProps) => {
  return (
    <Box
      sx={(theme) => ({
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        backgroundColor: backgroundColor ? backgroundColor : theme.palette.common.white,
        width: width,
        height: height,
        borderRadius: borderRadius,
        mb: mb ? mb : 0,
      })}
    >
      <Box
        sx={(theme) => ({
          alignContent: 'center',
          mt: '-20px',
          backgroundColor: theme.palette.electric[400],
          height: '40px',
          paddingLeft: 1,
          paddingRight: 2,
          borderRadius: '40px',
          position: 'absolute',
        })}
      >
        <Box>
          <StepIndicator number={stepIndicatorTitle.number} text={stepIndicatorTitle.text} />
        </Box>
      </Box>
      <Typography
        sx={(theme) => ({
          color: theme.palette.gray[900],
          typography: 'bodyXXLMedium',
          textAlign: 'center',
          mt: 6,
        })}
      >
        {title}
      </Typography>

      {children}
    </Box>
  );
};
