import { Box, Dialog, DialogActions, DialogContent, DialogTitle, Tooltip } from '@mui/material';
import { captureException } from '@sentry/react';
import { Dispatch, useState, useEffect, useMemo } from 'react';
import { Button, SnackbarService, useDepartmentInfoContext, DialogXButton } from '@stationwise/component-module';
import { client } from '@stationwise/share-api';
import { AutoHireInfoView, EligibleEmployeeForHiringEngine, HiringEngineVacancy } from '@stationwise/share-types';
import { AutoHireHistory } from './AutoHireHistory';
import { AutoHireVacancies } from './AutoHireVacancies';
import { generateRankOrder } from './utils';

interface AutoHireMainProps {
  autoHireModalOpen: boolean;
  setAutoHireModalOpen: Dispatch<React.SetStateAction<boolean>>;
  isDayLockedForHiring: boolean;
  autoHireInfo: AutoHireInfoView | null;
  groupedVacancies: Record<number, HiringEngineVacancy[]>;
  forceRefetch: () => void;
  selectedBattalionId: number;
  selectedDate: string;
  hasRosterChanges: boolean;
  forceRefetchHiringEngine: () => void;
  isRefetching: boolean;
}

function useLoadingStateMachine(isRefetching: boolean, autoHireRequestLoading: boolean, overrideLoading: boolean) {
  const [isLoading, setIsLoading] = useState(false);
  const [hasRefetchingBeenTrue, setHasRefetchingBeenTrue] = useState(false);
  const [hasAutoHireLoadingBeenTrue, setHasAutoHireLoadingBeenTrue] = useState(false);

  useEffect(() => {
    if (overrideLoading) {
      setIsLoading(true);
      return;
    }

    // Track when each loading state becomes true
    if (isRefetching && !hasRefetchingBeenTrue) {
      setHasRefetchingBeenTrue(true);
    }
    if (autoHireRequestLoading && !hasAutoHireLoadingBeenTrue) {
      setHasAutoHireLoadingBeenTrue(true);
    }

    // Set loading true if any loading state is true
    if (isRefetching || autoHireRequestLoading) {
      setIsLoading(true);
      return;
    }

    // Reset loading when all states are false
    if (!isRefetching && !autoHireRequestLoading) {
      setIsLoading(false);
      setHasRefetchingBeenTrue(false);
      setHasAutoHireLoadingBeenTrue(false);
    }
  }, [isRefetching, autoHireRequestLoading, overrideLoading, hasRefetchingBeenTrue, hasAutoHireLoadingBeenTrue]);

  return isLoading;
}

export const AutoHireMain = ({
  autoHireModalOpen,
  setAutoHireModalOpen,
  isDayLockedForHiring,
  autoHireInfo,
  groupedVacancies,
  forceRefetch,
  selectedBattalionId,
  selectedDate,
  hasRosterChanges,
  forceRefetchHiringEngine,
  isRefetching,
}: AutoHireMainProps) => {
  const { state: department } = useDepartmentInfoContext();

  const [autoHireRequestLoading, setAutoHireRequestLoading] = useState(false);
  const [rankOrders, setRankOrders] = useState<Record<number, { rankId: number; isSelected: boolean }[]>>(() =>
    Object.keys(groupedVacancies).reduce<Record<number, { rankId: number; isSelected: boolean }[]>>((acc, rankIdStr) => {
      const rankId = Number(rankIdStr);
      const autoHireOrder = autoHireInfo?.autoHireDefaultRankOrders[rankId];
      acc[rankId] = autoHireOrder ? autoHireOrder : generateRankOrder(rankId, department.departmentInfo?.ranks || []);
      return acc;
    }, {}),
  );
  const [vacancyRankCallOrder, setVacancyRankCallOrder] = useState<number[]>([]);
  const [roundDuration, setRoundDuration] = useState(0);

  const [disabled, setDisabled] = useState(false);
  const [autoHireHistoryOpen, setAutoHireHistoryOpen] = useState<boolean>(false);
  const [autoHireHistoryRankId, setAutoHireHistoryRankId] = useState<number | null>(null);
  const [currentEligibleEmployees, setCurrentEligibleEmployees] = useState<Record<number, EligibleEmployeeForHiringEngine[]>>({});
  const [selectedStaffingListId, setSelectedStaffingListId] = useState<number | null>(null);
  const [excludedPositionIds, setExcludedPositionIds] = useState<string[]>([]);

  const initialCheckedRanks = useMemo(
    () =>
      Object.keys(groupedVacancies).reduce<Record<number, boolean>>((acc, rankIdStr) => {
        const rankId = Number(rankIdStr);
        acc[rankId] = false;
        return acc;
      }, {}),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [groupedVacancies, autoHireInfo],
  );

  const [checkedRanks, setCheckedRanks] = useState<Record<number, boolean>>(initialCheckedRanks);
  let isLoading = useLoadingStateMachine(isRefetching, autoHireRequestLoading, false);
  useEffect(() => {
    setCheckedRanks(initialCheckedRanks);
  }, [initialCheckedRanks]);

  useEffect(() => {
    if (autoHireInfo?.autoHireRankProcessOrder) {
      setVacancyRankCallOrder(autoHireInfo.autoHireRankProcessOrder);
    }
  }, [autoHireInfo]);

  const handleAutoHireHistoryOpen = (rankId: number) => {
    setAutoHireHistoryRankId(rankId);
    setAutoHireHistoryOpen(true);
  };

  const handleAutoHireHistoryClose = () => {
    setAutoHireHistoryOpen(false);
  };

  const onCancel = () => {
    setAutoHireModalOpen(false);
    setRankOrders({});
  };

  const onRestartAutoHire = async (autoHireId: string) => {
    try {
      setAutoHireRequestLoading(true);
      setDisabled(true);
      if (selectedDate) {
        const params = {
          auto_hire_id: autoHireId,
          rank_call_orders: Object.entries(rankOrders).map(([rankId, ranks]) => ({
            rank_id: Number(rankId),
            ranks_id_list: ranks.filter((rankObj) => rankObj.isSelected).map((rankObj) => rankObj.rankId),
          })),
          notification_interval: roundDuration,
          excluded_position_ids: excludedPositionIds,
        };

        const response = await client.post('auto-hire/restart-auto-hire/', params);
        if (response.status === 200) {
          SnackbarService.notify({
            content: 'Auto Hire restarted successfully',
            severity: 'success',
            duration: 10000,
          });
        }
      }
      forceRefetchHiringEngine();
    } catch (error) {
      SnackbarService.notify({
        content: 'Auto Hire failed to restart',
        severity: 'error',
        duration: 10000,
      });
      captureException(error);
      setAutoHireRequestLoading(false);
      console.error('An error occurred while trying to start auto hire', error);
    } finally {
      setAutoHireRequestLoading(false);
      setDisabled(false);
    }
  };

  const onSubmitAutoHire = async () => {
    try {
      setAutoHireRequestLoading(true);
      setDisabled(true);
      if (selectedDate) {
        const params = {
          shift_date: selectedDate,
          battalion_id: selectedBattalionId || 0,
          rank_call_orders: Object.entries(rankOrders)
            .filter(([rankId]) => checkedRanks[Number(rankId)])
            .map(([rankId, ranks]) => ({
              rank_id: Number(rankId),
              ranks_id_list: ranks.filter((rankObj) => rankObj.isSelected).map((rankObj) => rankObj.rankId),
              eligible_employee_ids: currentEligibleEmployees[Number(rankId)].map((employee) => employee.id),
            })),
          excluded_position_ids: excludedPositionIds,
          staffing_list_id: selectedStaffingListId,
          rank_process_order: vacancyRankCallOrder.filter((rankId) => rankOrders[rankId]),
          notification_interval: roundDuration,
        };

        const response = await client.post('auto-hire/', params);
        let snackbar_message = 'Auto Hire Started successfully';
        if (response.status === 200) {
          snackbar_message = 'Auto Hire already started';
        }
        SnackbarService.notify({
          content: snackbar_message,
          severity: 'success',
          duration: 10000,
        });
      }
      forceRefetchHiringEngine();
    } catch (error) {
      SnackbarService.notify({
        content: 'Auto Hire failed to start',
        severity: 'error',
        duration: 10000,
      });
      captureException(error);
      setAutoHireRequestLoading(false);
      console.error('An error occurred while trying to start auto hire', error);
    } finally {
      setAutoHireRequestLoading(false);
      setDisabled(false);
    }
  };

  return (
    <Dialog
      open={autoHireModalOpen}
      onClose={onCancel}
      sx={{
        '& .MuiDialog-paper': {
          width: '400px',
        },
      }}
    >
      <DialogTitle>
        <Box display="flex" justifyContent="space-between">
          <Box sx={{ typography: 'bodyXLSemibold', textAlign: 'center' }}>Auto Hire</Box>
          <DialogXButton onClose={onCancel} />
        </Box>
      </DialogTitle>
      <DialogContent>
        {autoHireInfo && (
          <AutoHireVacancies
            groupedVacancies={groupedVacancies}
            rankOrders={rankOrders}
            setRankOrders={setRankOrders}
            setDisabled={setDisabled}
            autoHireInfo={autoHireInfo}
            handleAutoHireHistoryOpen={handleAutoHireHistoryOpen}
            vacancyRankCallOrder={vacancyRankCallOrder}
            setVacancyRankCallOrder={setVacancyRankCallOrder}
            isDayLockedForHiring={isDayLockedForHiring}
            loading={isLoading || autoHireRequestLoading || isRefetching}
            setCurrentEligibleEmployees={setCurrentEligibleEmployees}
            checkedRanks={checkedRanks}
            setCheckedRanks={setCheckedRanks}
            setSelectedStaffingListId={setSelectedStaffingListId}
            roundDuration={roundDuration}
            setRoundDuration={setRoundDuration}
            onRestartAutoHire={onRestartAutoHire}
            excludedPositionIds={excludedPositionIds}
            setExcludedPositionIds={setExcludedPositionIds}
          />
        )}
        {autoHireInfo && autoHireHistoryRankId && (
          <AutoHireHistory
            open={autoHireHistoryOpen}
            onClose={handleAutoHireHistoryClose}
            autoHireInfo={autoHireInfo}
            rankId={autoHireHistoryRankId}
            forceRefetch={forceRefetch}
          />
        )}
      </DialogContent>
      <DialogActions>
        <Tooltip title={hasRosterChanges ? 'You should save your roster changes before starting auto hire' : ''}>
          <span style={{ width: '100%' }}>
            <Button
              onClick={onSubmitAutoHire}
              variant="contained"
              size="large"
              sx={{ width: '100%' }}
              disabled={disabled || isLoading || hasRosterChanges}
              loading={autoHireRequestLoading}
            >
              Start Auto Hire
            </Button>
          </span>
        </Tooltip>
      </DialogActions>
    </Dialog>
  );
};
