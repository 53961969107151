import { Theme, useMediaQuery } from '@mui/material';
import { Dispatch, SetStateAction, useEffect, useMemo } from 'react';
import { RosterAdministrationStation, RosterTemporaryNonShiftStation } from '@stationwise/share-types';
import { useLocalStorageState } from '../../../hooks/useLocalStorageState';
import { useLoadedDepartmentInfoContext } from '../../Department';

// Change this to one-time clear the local storage values.
const VERSION = '2024-10-06';

interface UseRosterPreferencesInput {
  selectedBattalionState: [number | undefined, Dispatch<SetStateAction<number | undefined>>];
  administrationStations: RosterAdministrationStation[];
  temporaryNonShiftStation: RosterTemporaryNonShiftStation;
  allActiveStations: {
    id: number;
    name: string;
    battalionId: number;
  }[];
}

export const useRosterPreferences = ({
  selectedBattalionState,
  administrationStations,
  temporaryNonShiftStation,
  allActiveStations,
}: UseRosterPreferencesInput) => {
  const { state: departmentInfoState } = useLoadedDepartmentInfoContext();
  const isMobileApp = useMediaQuery((theme: Theme) => theme.breakpoints.down('lg'));

  const [version, setVersion] = useLocalStorageState({
    key: 'roster.preference.version',
    parse: (value) => (typeof value === 'string' ? value : 'NONE'),
  });

  const isLatestVersion = version === VERSION;

  useEffect(() => {
    setVersion(VERSION);
  }, [setVersion]);

  const [isCollapsed, setIsCollapsed] = useLocalStorageState({
    key: 'roster.preference.isCollapsed',
    parse: (value) => (isLatestVersion && typeof value === 'boolean' ? value || isMobileApp : isMobileApp),
  });

  const { battalions, validMap } = useMemo(() => {
    const battalions = departmentInfoState.departmentInfo.battalions.map((battalion, battalionIndex) => {
      const stations = allActiveStations
        .filter((s) => s.battalionId === battalion.id)
        .map((s) => ({ id: `${s.id}`, name: s.name }));
      stations.push({ id: '', name: 'Event Groups' });
      if (battalionIndex === 0) {
        administrationStations.forEach((administrationStation) => {
          stations.push({ id: administrationStation.stationId, name: administrationStation.stationName });
        });
      }
      stations.push({ id: temporaryNonShiftStation.stationId, name: temporaryNonShiftStation.stationName });

      return { id: battalion.id, name: battalion.name, stations };
    });

    const validMap = new Map<number, Set<string>>();

    battalions.forEach((battalion) => {
      const validStations = new Set<string>();
      battalion.stations.forEach((station) => validStations.add(station.name));
      allActiveStations
        .filter((station) => station.battalionId === battalion.id && !validStations.has(station.name))
        .forEach((station) => {
          validStations.add(station.name);
        });
      validMap.set(battalion.id, validStations);
    });

    return { battalions, validMap };
  }, [departmentInfoState.departmentInfo.battalions, administrationStations, temporaryNonShiftStation, allActiveStations]);

  const [selectedBattalionId, setSelectedBattalionId] = selectedBattalionState;

  const [selectedStationsMap, setSelectedStationsMap] = useLocalStorageState({
    key: 'roster.preference.selectedStationsMap',
    parse: (value) => {
      const inputMap = new Map<number, Map<string, boolean>>();

      const parsedValue = Array.isArray(value) ? value : [];
      parsedValue.forEach((entry) => {
        if (entry && typeof entry.battalionId === 'number') {
          let stationStates;
          try {
            stationStates = new Map(entry.stationStates);
          } catch {
            stationStates = new Map();
          }
          inputMap.set(entry.battalionId, stationStates);
        }
      });

      Array.from(validMap.entries()).forEach(([battalionId, stations]) => {
        if (!isLatestVersion || !inputMap.has(battalionId)) {
          inputMap.set(battalionId, new Map());
        }

        const inputStationStates = inputMap.get(battalionId);

        Array.from(stations).forEach((stationName: string) => {
          if (!inputStationStates?.has(stationName)) {
            inputStationStates?.set(stationName, true);
          }
        });
      });

      return inputMap;
    },
    stringify: (value) => {
      return JSON.stringify(
        Array.from(value).map(([battalionId, stations]) => {
          return {
            battalionId,
            stationStates: Array.from(stations),
          };
        }),
      );
    },
  });

  return {
    isCollapsed,
    setIsCollapsed,
    battalions,
    selectedBattalionId,
    setSelectedBattalionId,
    selectedStationsMap,
    setSelectedStationsMap,
  };
};
