import { SxProps, Theme } from '@mui/material';
import { DepartmentInfo } from '@stationwise/share-types';

export const BANK_HOURS_THRESHOLD = 12;

export const getTooltipProps = (sx?: SxProps<Theme>) => ({
  tooltip: {
    sx: [
      (theme: Theme) => ({
        backgroundColor: theme.palette.gray[800],
        borderRadius: 2,
        p: '10px 12px',
        typography: 'bodySMedium',
        '& .MuiTooltip-arrow': { color: theme.palette.gray[800] },
      }),
      ...(Array.isArray(sx) ? sx : sx ? [sx] : []),
    ],
  },
});
export const getVisibleTags = (departmentInfo: DepartmentInfo | null, place: string, contentType: string) => {
  if (!departmentInfo) {
    return [];
  }
  return (
    departmentInfo.visibilityCustomizations.find(
      (customization) => customization.place === place && customization.contentType === contentType,
    )?.tags || []
  );
};
