import { Box, Popover } from '@mui/material';
import { HirinEngineOptions } from '@stationwise/component-module';
import { HiringEngineServices } from '@stationwise/share-types';

interface HiringOptionsPopoverProps {
  open: boolean;
  anchorEl: HTMLElement | null;
  onClose: () => void;
  handleHiringEngineAction: (type: HiringEngineServices) => void;
}

export const HiringOptionsPopover = ({ open, anchorEl, onClose, handleHiringEngineAction }: HiringOptionsPopoverProps) => {
  return (
    <Popover
      open={open}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 50,
        horizontal: 40,
      }}
      slotProps={{
        paper: {
          sx: {
            width: '384px',
            borderRadius: '12px',
            boxShadow: '0px 4px 16px rgba(0, 0, 0, 0.1)',
          },
        },
      }}
    >
      <Box sx={{ p: 1 }}>
        <HirinEngineOptions handleHiringEngineAction={handleHiringEngineAction} isPopover={true} />
      </Box>
    </Popover>
  );
};
