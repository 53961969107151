import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import { Box, Typography, Dialog, Alert } from '@mui/material';
import { captureException } from '@sentry/react';
import { useState, useRef, useEffect } from 'react';
import { theme, Button, SnackbarService } from '@stationwise/component-module';
import { client } from '@stationwise/share-api';
import { RankComplete } from '@stationwise/share-types';

interface RankOrderModalProps {
  ranks: RankComplete[];
  onSaveSuccess: () => void;
  setShowModal: (show: boolean) => void;
  showModal: boolean;
}

export const RankOrderModal = ({ ranks, onSaveSuccess, setShowModal, showModal }: RankOrderModalProps) => {
  const [orders, setOrders] = useState<number[]>([]);
  const dragItem = useRef<number | null>(null);
  const dragOverItem = useRef<number | null>(null);
  const [initialOrders, setInitialOrders] = useState<number[]>([]);
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    setOrders(ranks.map((rank) => rank.sortOrder) || []);
    setInitialOrders(ranks.map((rank) => rank.sortOrder) || []);
  }, [ranks]);

  const handleSaveSettings = async () => {
    setIsSubmitting(true);
    try {
      await client.post('organization/ranks/save-rank-sort-order/', {
        rank_sort_order: orders,
      });

      setInitialOrders([...orders]);
      onSaveSuccess();
      SnackbarService.notify({
        content: 'Rank sort order saved successfully',
        severity: 'success',
        duration: 5000,
      });
    } catch (error) {
      captureException(error);
      SnackbarService.notify({
        content: 'Failed to save rank sort order',
        severity: 'error',
        duration: 5000,
      });
      console.error('Failed to save rank sort order:', error);
    } finally {
      setIsSubmitting(false);
      setShowModal(false);
    }
  };

  const handleSort = () => {
    if (dragItem.current !== null && dragOverItem.current !== null) {
      const updatedOrders = [...orders];
      const draggedItemContent = updatedOrders.splice(dragItem.current, 1)[0];
      updatedOrders.splice(dragOverItem.current, 0, draggedItemContent);
      dragItem.current = null;
      dragOverItem.current = null;
      setOrders(updatedOrders);
    }
  };

  const hasChanges = () => {
    if (orders.length !== initialOrders.length) return true;
    const ordersChanged = orders.some((order, index) => order !== initialOrders[index]);
    return ordersChanged;
  };

  const handleClose = () => {
    setIsSubmitting(false);
    setShowModal(false);
  };

  return (
    <Dialog open={showModal} onClose={handleClose}>
      <Box sx={{ height: '100%', p: theme.spacing(2) }}>
        <Box sx={{ maxWidth: '1200px', width: '100%' }}>
          <Box sx={{ gap: theme.spacing(3), justifyContent: 'space-between' }}>
            <Typography variant="h6" sx={{ mb: theme.spacing(1) }}>
              Rank Sort Order
            </Typography>
            <Box sx={{ maxWidth: '1200px', mb: theme.spacing(1) }}>
              <Alert severity="info" sx={(theme) => ({ mt: 1, mb: 1, mx: 1, backgroundColor: theme.palette.blue[200] })}>
                The higher on the list the more senior the rank is (Fire Chief is first, Deputy Chief second, Battalion Chief
                third, etc.)
              </Alert>
            </Box>
            <Box
              sx={(theme) => ({
                p: theme.spacing(2),
                borderRadius: theme.spacing(1),
                backgroundColor: theme.palette.common.white,
                border: `1px solid ${theme.palette.gray[200]}`,
                minHeight: '400px',
              })}
            >
              <Typography
                sx={(theme) => ({
                  color: theme.palette.gray[600],
                  typography: 'bodySRegular',
                  mb: theme.spacing(2),
                })}
              >
                Drag and drop to reorder the ranks. Rank hierarchy will be in this order.
              </Typography>
              <Box sx={{ flex: 1, overflow: 'auto', pr: theme.spacing(1) }}>
                {orders.map((rankId, index) => {
                  const rank = ranks.find((r) => r.sortOrder === rankId);
                  return (
                    <Box
                      key={index}
                      draggable
                      onDragStart={() => (dragItem.current = index)}
                      onDragEnter={() => (dragOverItem.current = index)}
                      onDragEnd={handleSort}
                      onDragOver={(e) => e.preventDefault()}
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        mt: 0.5,
                        backgroundColor: theme.palette.gray[50],
                        p: theme.spacing(1),
                        borderRadius: theme.spacing(1),
                        border: '1px solid',
                        borderColor: dragItem.current === index ? theme.palette.primary.light : 'transparent',
                        opacity: 1,
                        transition: 'all 0.2s ease-in-out',
                        '&:hover': {
                          backgroundColor: theme.palette.gray[300],
                          cursor: 'move',
                        },
                      }}
                    >
                      <DragIndicatorIcon
                        sx={{
                          visibility: 'visible',
                          marginRight: theme.spacing(1),
                          color: theme.palette.gray[500],
                          '&:hover': {
                            color: theme.palette.primary.dark,
                          },
                        }}
                      />
                      <Typography sx={{ width: '20px', textAlign: 'center', color: theme.palette.gray[500] }}>
                        {index + 1}
                      </Typography>
                      <Typography sx={{ flex: 1, ml: theme.spacing(1.5) }}>{rank?.name || 'Unknown Rank'}</Typography>
                    </Box>
                  );
                })}
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  mt: 2,
                }}
              >
                <Button
                  variant="contained"
                  size="large"
                  onClick={handleSaveSettings}
                  disabled={!hasChanges()}
                  loading={isSubmitting}
                >
                  Save Settings
                </Button>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Dialog>
  );
};
