import { format, max, parseISO, differenceInCalendarDays, addDays, startOfDay } from 'date-fns';
import { ShiftPlanAssignment, ShiftPlanTeam } from '@stationwise/share-types';
import { compareEndDates } from '@stationwise/share-utils';

export const parseTeamPattern = (patternString: string) => {
  const pattern: boolean[] = [];
  if (!patternString) {
    return pattern;
  }

  patternString.split(',').forEach((part) => {
    const partMatch = part
      .trim()
      .toLowerCase()
      .match(/^(\d+)\s*(on|off)$/);
    if (partMatch) {
      let repeat = Number(partMatch[1]);
      while (repeat > 0) {
        pattern.push(partMatch[2] === 'on');
        repeat -= 1;
      }
    }
  });

  return pattern;
};

export const getRepeatingTeamPatternDates = (
  team: ShiftPlanTeam,
  fromDateString: string,
  toDateString: string,
  assignment?: ShiftPlanAssignment,
) => {
  const pattern = parseTeamPattern(team.pattern);
  const fromDate = parseISO(fromDateString);
  const toDate = parseISO(toDateString);

  const patternStartDate = team.patternStartDate ? parseISO(team.patternStartDate) : fromDate;

  const patternDates = new Set<string>();

  let patternIndex = 0;
  if (patternStartDate < fromDate) {
    patternIndex = differenceInCalendarDays(fromDate, patternStartDate) % pattern.length;
    if (patternIndex < 0) patternIndex += pattern.length;
  }

  let date = max([patternStartDate, fromDate]);

  while (startOfDay(date) <= startOfDay(toDate)) {
    if (
      (!assignment || (date >= assignment.activationDate && compareEndDates(date, assignment.deactivationDate) <= 0)) &&
      pattern[patternIndex]
    ) {
      patternDates.add(format(date, 'yyyy-MM-dd'));
    }

    patternIndex = (patternIndex + 1) % pattern.length;
    date = addDays(date, 1);
  }

  return patternDates;
};

export const prettifyTeamPatternString = (patternString: string) => {
  // E.g. "2on,4off" -> "2 on, 4 off"
  const parts = patternString.split(',').map((part) => {
    return part
      .trim()
      .toLowerCase()
      .replace(/^(\d+)\s*(on|off)$/, (_match, $1, $2) => `${$1} ${$2}`);
  });
  return parts.join(', ');
};

export const booleanArrayToStringPattern = (arr: boolean[]) => {
  let result = '';
  let count = 1;

  arr.forEach((val, i) => {
    if (val === arr[i + 1]) {
      count++;
    } else {
      result += `${count}${val ? 'on' : 'off'}`;
      if (i < arr.length - 1) result += ',';
      count = 1;
    }
  });

  return result;
};
