import Box from '@mui/material/Box';
import { useState } from 'react';
import { ShiftMark, WorkGroup } from '@stationwise/share-types';
import { EditGroupInfo } from './Edit/EditGroupInfo';
import { InfoCard, InfoCardField } from './InfoCard';

type GroupsSectionProps = {
  shiftMarks: ShiftMark[];
  workGroups: WorkGroup[];
};

export const GroupsSection = (props: GroupsSectionProps) => {
  const [showModal, setShowModal] = useState(false);
  const fieldDefs: InfoCardField[][] = [];

  const handleEditModal = () => {
    setShowModal(true);
  };

  const groupsSection: InfoCardField[] = [
    {
      label: 'Work groups',
      field: {
        type: 'custom',
        extra: {
          custom: (
            <Box
              sx={(theme) => ({
                display: 'flex',
                flexDirection: 'column',
                gap: theme.spacing(1),
                flexWrap: 'wrap',
              })}
            >
              {props.workGroups.length === 0 && <Box> None </Box>}
              {props.workGroups.map((wg) => (
                <Box
                  display="flex"
                  key={`${wg.id}-license-class`}
                  sx={(theme) => ({
                    gap: theme.spacing(1),
                    alignItems: 'center',
                  })}
                >
                  <Box sx={{ typography: 'bodyMMedium' }}>{wg.name}</Box>
                </Box>
              ))}
            </Box>
          ),
        },
      },
    },
  ];

  const shiftMarkSection: InfoCardField = {
    label: 'Shift Marks',
    field: {
      type: 'custom',
      extra: {
        custom: (
          <Box
            sx={(theme) => ({
              display: 'flex',
              gap: theme.spacing(1),
              flexWrap: 'wrap',
            })}
          >
            {props.shiftMarks.length > 0 ? (
              props.shiftMarks.map((mark, index) => (
                <Box
                  display="flex"
                  key={`${index}-shift-mark`}
                  sx={(theme) => ({
                    gap: theme.spacing(1),
                    alignItems: 'center',
                  })}
                >
                  <Box sx={{ typography: 'bodyMRegular' }}>{mark.name}</Box>
                  {index !== props.shiftMarks!.length - 1 && (
                    <Box
                      sx={(theme) => ({
                        color: theme.palette.gray[300],
                        typography: 'bodySMedium',
                      })}
                    >
                      |
                    </Box>
                  )}
                </Box>
              ))
            ) : (
              <Box
                sx={() => ({
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  textAlign: 'center',
                  typography: 'bodyMRegular',
                })}
              >
                {'-'}
              </Box>
            )}
          </Box>
        ),
      },
    },
  };

  if (props.shiftMarks) {
    groupsSection.push(shiftMarkSection);
  }
  fieldDefs.push(groupsSection);

  return (
    <>
      <EditGroupInfo
        showModal={showModal}
        setShowModal={setShowModal}
        employeeWorkGroups={props.workGroups}
        shiftMarks={props.shiftMarks}
      />
      <Box
        width="100%"
        sx={(theme) => ({
          backgroundColor: theme.palette.common.white,
          borderRadius: theme.spacing(2),
          border: `1px solid ${theme.palette.gray[200]}`,
          p: theme.spacing(4, 3, 3, 3),
          gap: theme.spacing(3),
        })}
      >
        <InfoCard fieldDefs={fieldDefs} title="Groups" handleEditModal={handleEditModal} />
      </Box>
    </>
  );
};
