import { Box, Pagination } from '@mui/material';
import { captureException } from '@sentry/react';
import React, { useDeferredValue, useEffect, useRef, useState } from 'react';
import {
  exportData,
  Button,
  LottieLogo,
  SearchAndFilter,
  SearchInput,
  useLoadedDepartmentInfoContext,
  SnackbarService,
} from '@stationwise/component-module';
import { client, isAxiosError } from '@stationwise/share-api';
import {
  Certification,
  DetailCode,
  PayCodeComplete,
  RankComplete,
  CapabilityPermission,
  TimeOffAccruals,
} from '@stationwise/share-types';
import { camelCaseToTitle, capitalize, makeTestIdentifier } from '@stationwise/share-utils';
import { CapabilityPermissionModal } from './CapabilityPermission/CapabilityPermissionModal';
import { CapabilityPermissionTable } from './CapabilityPermission/CapabilityPermissionTable';
import { CertificationModal } from './Certifications/CertificationsModal';
import { CertificationsTable } from './Certifications/CertificationsTable';
import { DepartmentSettingsTabProps, ElementTypes, FilterOptions } from './DepartmentSettingsUtil';
import { DetailCodeModal } from './DetailCode/DetailCodeModal';
import { DetailCodeTable } from './DetailCode/DetailCodeTable';
import { PayCodeModal } from './PayCode/PayCodeModal';
import { PayCodeTable } from './PayCode/PayCodeTable';
import { RankModal } from './Rank/RankModal';
import { RankOrderModal } from './Rank/RankOrderModal';
import { RanksTable } from './Rank/RanksTable';
import { RemoveTOFModal } from './TimeOffAccruals/TOFRemoveModal';
import { TimeOffAccrualsModal } from './TimeOffAccruals/TimeOffAccrualsModal';
import { TimeOffAccrualsTable } from './TimeOffAccruals/TimeOffAccrualsTable';

export const DepartmentSettingsTab = ({
  elements,
  selectedElement,
  setElements,
  setSelectedElement,
  createUpdateUrl,
  elementType,
  isFilterLowerCase,
  exportUrl,
  hasFilters = true,
}: DepartmentSettingsTabProps) => {
  const {
    state: {
      departmentInfo: {
        certifications: certificationOptions,
        payPeriodTypes,
        payCodeOptions,
        payCodes: existingPayCodes,
        ranks: rankOptions,
      },
    },
  } = useLoadedDepartmentInfoContext();
  const { dispatch } = useLoadedDepartmentInfoContext();

  const [showEditModal, setShowEditModal] = useState(false);
  const [showRankOrderModal, setShowRankOrderModal] = useState(false);

  const [searchInput, setSearchInput] = useState('');
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const deferredSearchInput = useDeferredValue(searchInput);
  const abortControllerRef = useRef<AbortController | null>(null);
  const [selectedFilters, setSelectedFilters] = useState<Record<string, string>>({});
  const [filterOptions, setFilterOptions] = useState<FilterOptions>({});
  const [refetchCounter, setRefetchCounter] = useState(0);
  //todo move delete banks to time off accruals
  const [deleteAccruals, setDeleteAccruals] = useState<TimeOffAccruals | undefined>(undefined);
  const [showRemoveModal, setShowRemoveModal] = useState(false);
  const header = `Do you really wish to remove ${deleteAccruals?.name ?? ''}?`;
  const subtext =
    'This action cannot be undone. Your accrual bank, along with all accrued hours for every employee, will be deleted. ' +
    'If you want to restore the accrued amounts, you will need to create a new bank with the same accrued amount for each employee.';
  const [isDeleting, setIsDeleting] = useState(false);

  useEffect(() => {
    const fetchFilterOptions = async () => {
      try {
        const response = await client.get(createUpdateUrl + 'filter_options/');
        if (response.data) {
          setFilterOptions(response.data);
        }
      } catch (error) {
        captureException(error);
      }
    };
    if (elementType !== ElementTypes.CERTIFICATION && elementType !== ElementTypes.CAPABILITY_PERMISSION && hasFilters)
      fetchFilterOptions();
  }, [createUpdateUrl, elementType, hasFilters]);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      abortControllerRef.current?.abort();
      abortControllerRef.current = new AbortController();
      try {
        const filterParams = Object.fromEntries(Object.entries(selectedFilters).filter(([_, value]) => value));
        const params = {
          page: page,
          ...(deferredSearchInput && { search: deferredSearchInput }),
          ...filterParams,
        };
        const response = await client.get(createUpdateUrl, {
          params,
          signal: abortControllerRef.current.signal,
        });
        if (response.data.results && Array.isArray(response.data.results)) {
          setElements(response.data.results);
          setTotalPages(Math.ceil(response.data.count / 100));
        }
        setIsLoading(false);
      } catch (error) {
        const isCanceled = isAxiosError(error) && error.code === 'ERR_CANCELED';
        !isCanceled && captureException(error);
        !isCanceled && setIsLoading(false);
      }
    };
    fetchData();
  }, [page, deferredSearchInput, refetchCounter, selectedFilters, createUpdateUrl, setElements]);

  const handlePageChange = (_event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };

  const handleFilterChange = (filterName: string, value: string) => {
    setPage(1);
    setSelectedFilters((prev) => ({ ...prev, [filterName]: value }));
  };

  const handleExport = async () => {
    if (!exportUrl) return;
    await exportData(exportUrl, {}, elementType.toLowerCase().replace(/ /g, '_') + 's.csv');
  };
  const handleDelete = async (accrual: TimeOffAccruals) => {
    setIsDeleting(true);
    try {
      await client.delete(`/payroll/time-off-accruals/${accrual?.id}/`);
      setRefetchCounter((prev) => prev + 1);
      dispatch({ type: 'REFETCH_DEPARTMENT_INFO' });

      SnackbarService.notify({
        content: `The ${accrual?.name} list was deleted successfully.`,
        severity: 'success',
        duration: 3000,
      });
    } catch (error) {
      captureException(error);
      SnackbarService.notify({
        content: `An error occurred, accrual could not be deleted.`,
        severity: 'error',
        duration: 5000,
      });
    } finally {
      setIsDeleting(false);
      setShowRemoveModal(false);
    }
  };

  const filters = hasFilters
    ? Object.entries(filterOptions).map(([filterName, options]) => ({
        name: camelCaseToTitle(capitalize(filterName)),
        options: options.map((option) => ({
          label: (() => {
            return elementType === ElementTypes.PAY_CODE || elementType === ElementTypes.DETAIL_CODE
              ? option
              : option.toUpperCase();
          })(),
          value: (() => {
            return isFilterLowerCase ? option.toLowerCase() : capitalize(option);
          })(),
        })),
        selected: selectedFilters[filterName],
        onChange: (value: string) => handleFilterChange(filterName, value),
      }))
    : null;

  const clearAllFilters = () => {
    setSelectedFilters({});
  };

  return (
    <Box>
      <Box
        sx={(theme) => ({
          m: theme.spacing(1),
        })}
      >
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start' }}>
          {filters ? (
            <SearchAndFilter
              searchText={searchInput}
              setSearchText={setSearchInput}
              filters={filters}
              clearAllFilters={clearAllFilters}
              searchPlaceholder={`Search for ${elementType}`}
            />
          ) : (
            <SearchInput value={searchInput} setValue={setSearchInput} color="white" placeHolder={`Search for ${elementType}`} />
          )}
          <Box sx={{ display: 'flex', gap: 1 }}>
            {elementType === ElementTypes.RANK && Object.keys(selectedFilters).length === 0 && (
              <Button
                variant="outlined"
                size="small"
                onClick={() => {
                  setShowRankOrderModal(true);
                  setSelectedElement(undefined);
                }}
              >
                {`Edit Rank Order`}
              </Button>
            )}
            {elementType !== ElementTypes.CAPABILITY_PERMISSION && (
              <Button
                variant="outlined"
                size="small"
                onClick={() => {
                  setShowEditModal(true);
                  setSelectedElement(undefined);
                }}
                data-cy={`create-new-${makeTestIdentifier(elementType)}`}
              >
                {`+ Create new ${elementType}`}
              </Button>
            )}
            {exportUrl && (
              <Button variant="outlined" size="small" onClick={handleExport}>
                {`Export ${elementType} CSV`}
              </Button>
            )}
          </Box>
        </Box>
        {isLoading ? (
          <Box display="flex" alignItems="center" justifyContent="center" sx={{ height: '100%', width: '100%' }}>
            <LottieLogo height="200px" width="200px" />
          </Box>
        ) : (
          (() => {
            switch (elementType) {
              case ElementTypes.RANK:
                return (
                  <RanksTable
                    ranks={elements}
                    handleEditAction={(rank: RankComplete) => {
                      setShowEditModal(true);
                      setSelectedElement(rank);
                    }}
                  />
                );
              case ElementTypes.CERTIFICATION:
                return (
                  <CertificationsTable
                    certifications={elements}
                    handleEditAction={(certification: Certification) => {
                      setShowEditModal(true);
                      setSelectedElement(certification);
                    }}
                  />
                );
              case ElementTypes.DETAIL_CODE:
                return (
                  <DetailCodeTable
                    detailCodes={elements}
                    handleEditAction={(detailCode: DetailCode) => {
                      setShowEditModal(true);
                      setSelectedElement(detailCode);
                    }}
                  />
                );
              case ElementTypes.PAY_CODE:
                return (
                  <PayCodeTable
                    existingPayCodes={existingPayCodes}
                    payCodes={elements}
                    handleEditAction={(payCode: PayCodeComplete) => {
                      setShowEditModal(true);
                      setSelectedElement(payCode);
                    }}
                  />
                );
              case ElementTypes.CAPABILITY_PERMISSION:
                return (
                  <CapabilityPermissionTable
                    capabilityPermissions={elements}
                    handleEditAction={(capabilityPermission: CapabilityPermission) => {
                      setShowEditModal(true);
                      setSelectedElement(capabilityPermission);
                    }}
                  />
                );
              case ElementTypes.TIME_OFF_ACCRUAL:
                return (
                  <TimeOffAccrualsTable
                    existingPayCodes={existingPayCodes}
                    accruals={elements}
                    handleEditAction={(accrual: TimeOffAccruals) => {
                      setSelectedElement(accrual);
                      setShowEditModal(true);
                    }}
                    handleDeleteAction={(accrual: TimeOffAccruals) => {
                      setDeleteAccruals(accrual);
                      setShowRemoveModal(true);
                    }}
                  />
                );
              default:
                return null;
            }
          })()
        )}
      </Box>

      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        {totalPages > 1 && !isLoading && <Pagination count={totalPages} page={page} onChange={handlePageChange} sx={{ my: 2 }} />}
      </Box>
      {showEditModal &&
        (() => {
          switch (elementType) {
            case ElementTypes.RANK:
              return (
                <RankModal
                  certifications={certificationOptions}
                  onSaveSuccess={() => {
                    setRefetchCounter((prev) => prev + 1);
                  }}
                  payPeriodTypes={payPeriodTypes}
                  selectedRank={selectedElement ? elements.find((rank) => rank.id === selectedElement.id) : undefined}
                  setShowModal={setShowEditModal}
                  showModal={showEditModal}
                  maxSortOrder={Math.max(...rankOptions.map((rank) => rank.sortOrder))}
                />
              );
            case ElementTypes.CERTIFICATION:
              return (
                <CertificationModal
                  onSaveSuccess={() => {
                    setRefetchCounter((prev) => prev + 1);
                  }}
                  selectedCertification={
                    selectedElement ? elements.find((certification) => certification.id === selectedElement.id) : undefined
                  }
                  setShowModal={setShowEditModal}
                  showModal={showEditModal}
                />
              );
            case ElementTypes.DETAIL_CODE:
              return (
                <DetailCodeModal
                  onSaveSuccess={() => {
                    setRefetchCounter((prev) => prev + 1);
                  }}
                  payCodes={payCodeOptions}
                  selectedDetailCode={
                    selectedElement ? elements.find((detailCode) => detailCode.id === selectedElement.id) : undefined
                  }
                  setShowModal={setShowEditModal}
                  showModal={showEditModal}
                />
              );
            case ElementTypes.PAY_CODE:
              return (
                <PayCodeModal
                  extendedPayCodes={existingPayCodes}
                  onSaveSuccess={() => {
                    setRefetchCounter((prev) => prev + 1);
                  }}
                  payCodes={payCodeOptions}
                  existingPayCodes={(selectedElement
                    ? existingPayCodes.filter((element) => element.id !== selectedElement.id)
                    : existingPayCodes
                  ).map((element) => element.code)}
                  selectedPayCode={selectedElement ? elements.find((payCode) => payCode.id === selectedElement.id) : undefined}
                  setShowModal={setShowEditModal}
                  showModal={showEditModal}
                />
              );
            case ElementTypes.CAPABILITY_PERMISSION:
              return (
                <CapabilityPermissionModal
                  selectedCapabilityPermission={
                    selectedElement ? elements.find((element) => element.id === selectedElement.id) : undefined
                  }
                  onSaveSuccess={() => {
                    setRefetchCounter((prev) => prev + 1);
                  }}
                  setShowModal={setShowEditModal}
                  showModal={showEditModal}
                />
              );

            case ElementTypes.TIME_OFF_ACCRUAL:
              return (
                <TimeOffAccrualsModal
                  onSaveSuccess={() => {
                    setRefetchCounter((prev) => prev + 1);
                  }}
                  existingPayCodes={existingPayCodes}
                  selectedAccrual={selectedElement ? elements.find((accrual) => accrual.id === selectedElement.id) : undefined}
                  setShowModal={setShowEditModal}
                  showModal={showEditModal}
                />
              );
            default:
              return null;
          }
        })()}

      {showRankOrderModal && (
        <RankOrderModal
          ranks={elements as RankComplete[]}
          onSaveSuccess={() => {
            setRefetchCounter((prev) => prev + 1);
          }}
          setShowModal={setShowRankOrderModal}
          showModal={showRankOrderModal}
        />
      )}
      {deleteAccruals && (
        <RemoveTOFModal
          handleRemove={() => handleDelete(deleteAccruals)}
          showModal={showRemoveModal}
          setShowModal={setShowRemoveModal}
          header={header}
          subtext={subtext}
          isLoading={isDeleting}
        />
      )}
    </Box>
  );
};
