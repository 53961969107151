import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, styled, tableCellClasses } from '@mui/material';
import { format } from 'date-fns';
import { PayrollData } from '@stationwise/share-types';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  textAlign: 'center',
  whiteSpace: 'nowrap',
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.gray[900],
    color: theme.palette.common.white,
    borderTop: `1px solid ${theme.palette.gray[600]}`,
    borderBottom: `1px solid ${theme.palette.gray[600]}`,
    borderRight: `1px solid ${theme.palette.gray[600]}`,
    '&:first-of-type': {
      borderLeft: `1px solid ${theme.palette.gray[600]}`,
    },
  },
  [`&.${tableCellClasses.body}`]: {
    backgroundColor: theme.palette.common.white,
    borderBottom: `1px solid ${theme.palette.gray[200]}`,
    borderRight: `1px solid ${theme.palette.gray[200]}`,

    '&:first-of-type': {
      backgroundColor: theme.palette.gray[50],
      borderLeft: `1px solid ${theme.palette.gray[50]}`,
      textAlign: 'left',
    },
  },
}));

interface PayrollTableProps {
  days: Date[];
  payroll: PayrollData;
}

export const PayrollTable = ({ days, payroll }: PayrollTableProps) => {
  return (
    <TableContainer>
      <Table
        sx={(theme) => ({
          borderRadius: theme.spacing(1.5),
          overflow: 'hidden',
        })}
      >
        <TableHead>
          <TableRow>
            <StyledTableCell></StyledTableCell>
            <StyledTableCell sx={{ typography: 'bodySRegular' }}>TOTAL</StyledTableCell>
            {days.map((day) => {
              return (
                <StyledTableCell key={day.toDateString()} sx={{ typography: 'bodySRegular' }}>
                  <Box>{format(day, 'E').toUpperCase()}</Box>
                  <Box sx={(theme) => ({ color: theme.palette.gray[400] })}>{format(day, 'MM/dd')}</Box>
                </StyledTableCell>
              );
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          {payroll.typicalWorkingPayroll.map((row) => (
            <TableRow key={row.name}>
              <StyledTableCell>
                <Box sx={{ typography: 'bodyMSemibold' }}>{row.code}</Box>
                {row.name !== row.code && (
                  <Box sx={(theme) => ({ color: theme.palette.gray[400], typography: 'bodySMedium' })}>{row.name}</Box>
                )}
              </StyledTableCell>
              <StyledTableCell sx={{ typography: 'bodySRegular' }}>{row.total.toFixed(1)}</StyledTableCell>
              {days.map((day) => {
                const dayHours = row.dayHours.find((dayHours) => dayHours.date === format(day, 'yyyy-MM-dd'))?.hours || 0;
                return (
                  <StyledTableCell
                    key={day.toDateString()}
                    sx={(theme) => ({
                      color: theme.palette.gray[500],
                      typography: 'bodySRegular',
                    })}
                  >
                    {dayHours !== undefined ? dayHours.toFixed(1) : 'N/A'}
                  </StyledTableCell>
                );
              })}
            </TableRow>
          ))}
          {payroll.nonTypicalPayroll.map((row) => (
            <TableRow key={row.name}>
              <StyledTableCell>
                <Box sx={{ typography: 'bodyMSemibold', fontWeight: 600 }}>{row.code}</Box>
                <Box sx={(theme) => ({ color: theme.palette.gray[400], typography: 'bodySMedium' })}>{row.name}</Box>
              </StyledTableCell>
              <StyledTableCell sx={{ typography: 'bodySRegular' }}>{row.total.toFixed(1)}</StyledTableCell>
              {days.map((day) => {
                const dayHours = row.dayHours.find((dayHours) => dayHours.date === format(day, 'yyyy-MM-dd'))?.hours || 0;
                return (
                  <StyledTableCell
                    key={day.toDateString()}
                    sx={(theme) => ({
                      color: theme.palette.gray[500],
                      typography: 'bodySRegular',
                    })}
                  >
                    {dayHours !== undefined ? dayHours.toFixed(1) : 'N/A'}
                  </StyledTableCell>
                );
              })}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
