import { Box } from '@mui/material';
import { format, setMinutes } from 'date-fns';
import { useEffect, useState } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { client } from '@stationwise/share-api';
import { ShiftDetails, TradesOwedDetails, EmployeeDetails } from '@stationwise/share-types';
import { ShiftTradeDecision } from './ShiftTradeDecision';
import { ShiftTradeRequester } from './ShiftTradeRequester';
import { ShiftTradeResult } from './ShiftTradeResult';
import { ShiftTradeSummary } from './ShiftTradeSummary';

export const ShiftTradeContent = () => {
  const [shiftTradeResponse, setShiftTradeResponse] = useState<boolean>(false);
  const [madeDecision, setMadeDecision] = useState<boolean>(false);
  const [employeeDetails, setEmployeeDetails] = useState<EmployeeDetails>();
  const [shiftDetails, setShiftDetails] = useState<ShiftDetails>();
  const [tradesOwedDetails, setTradesOwedDetails] = useState<TradesOwedDetails>();
  const [requestId, setRequestId] = useState<number>();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const tradeRequestId = searchParams.get('trade_request_id');

  useEffect(() => {
    getShiftTradeRequest().then((requestId) => {
      if (!requestId) {
        return;
      }
      markShiftTradeRequestAsRead(requestId);
      setRequestId(requestId);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const markShiftTradeRequestAsRead = async (requestId: number) => {
    try {
      await client.put(`request/shift-trade-request/${requestId}/message-read/`);
    } catch (error) {
      console.error(error);
    }
  };

  const getShiftTradeRequest = async () => {
    try {
      const response = await client.get(`request/shift-trade-request/${tradeRequestId}/`);
      const request = response.data;
      if (request.status !== 'USER_MESSAGE_SENT' && request.status !== 'USER_MESSAGE_READ') {
        throw new Error('This request is not pending anymore.');
      }

      const requestStartDateTime = new Date(`${request.startDate}T${request.startTimeStr}`);
      const requestEndDateTime = setMinutes(requestStartDateTime, requestStartDateTime.getMinutes() + request.duration);
      setShiftDetails({
        start: format(requestStartDateTime, "yyyy-MM-dd'T'HH:mm:ss"),
        end: format(requestEndDateTime, "yyyy-MM-dd'T'HH:mm:ss"),
        duration: (request.duration / 60).toFixed(2),
        type: {
          name: request.team.name ?? '',
          color: request.team.color ?? 'gray',
        },
      });

      setEmployeeDetails({
        name: request.sender.name,
        rank: request.sender.rank,
        team: request.team.name,
      });

      setTradesOwedDetails({
        owedMinutes: request.minutesOwed,
        owed: request.owesToResponder,
      });

      return request.id;
    } catch (error) {
      // If the trade request isn't pending anymore, backend will return a 404 so we redirect to home page
      navigate('/');
      console.log(error);
      return null;
    }
  };

  const handleAcceptTradeShift = async () => {
    try {
      await client.put(`request/shift-trade-request/${requestId}/accept/`);
    } catch (error) {
      console.error(error);
    }

    setShiftTradeResponse(true);
    setMadeDecision(true);
  };
  const handleDenyTradeShift = () => {
    setShiftTradeResponse(false);
    setMadeDecision(true);
  };

  return (
    <Box
      sx={(theme) => ({
        background: theme.palette.common.white,
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100%',
        pt: theme.spacing(2),
      })}
    >
      {shiftDetails && employeeDetails && tradesOwedDetails && (
        <>
          <ShiftTradeRequester employee={employeeDetails} />
          <ShiftTradeSummary
            employee={employeeDetails}
            tradesOwed={tradesOwedDetails.owedMinutes !== 0 ? tradesOwedDetails : undefined}
            shift={shiftDetails as ShiftDetails}
          />
        </>
      )}
      {madeDecision ? (
        <ShiftTradeResult
          isAccepted={shiftTradeResponse}
          name={employeeDetails?.name as string}
          requestId={requestId as number}
          date={shiftDetails?.start as string}
        />
      ) : (
        !!requestId && (
          <ShiftTradeDecision handleAcceptTradeShift={handleAcceptTradeShift} handleDenyTradeShift={handleDenyTradeShift} />
        )
      )}
    </Box>
  );
};
