import { Box, IconButton, Popover } from '@mui/material';
import { useState } from 'react';
import { InfoCircle24, CertBadge } from '@stationwise/component-module';
import { EligibleEmployeeForHiringEngine } from '@stationwise/share-types';
import { shortenEmployeeName } from '@stationwise/share-utils';
import { RankBadge } from '../../../Badge';

interface HiringEngineEmployeeInfoCardProps {
  employee: EligibleEmployeeForHiringEngine;
  startDate: string;
  endDate: string;
}

export const HiringEngineEmployeeInfoCard = ({ employee, startDate, endDate }: HiringEngineEmployeeInfoCardProps) => {
  const [showModal, setShowModal] = useState(false);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const infoRows = [
    {
      label: 'Rank',
      value: <RankBadge rankField="name" rank={employee.rank} />,
    },
    {
      label: 'Default Station',
      value:
        employee.plannedShiftDetails && employee.plannedShiftDetails.length > 0
          ? employee.plannedShiftDetails[0].station
          : 'Unassigned',
    },
    {
      label: 'Default Apparatus',
      value:
        employee.plannedShiftDetails && employee.plannedShiftDetails.length > 0
          ? employee.plannedShiftDetails[0].apparatus
          : 'Unassigned',
    },
    {
      label: 'Certifications',
      value: (
        <Box sx={{ display: 'flex', gap: 0.5, flexWrap: 'wrap' }}>
          {employee.certifications.map((cert, index) => (
            <CertBadge key={`${index}-${cert.code}`} cert={cert} />
          ))}
        </Box>
      ),
    },
    {
      label: 'Start Date',
      value: startDate,
    },
    {
      label: 'End Date',
      value: endDate,
    },
  ];

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          width: 180,
          justifyContent: 'flex-end',
          alignItems: 'center',
          gap: '10px',
        }}
      >
        <Box sx={{ typography: 'bodySRegular' }}>{shortenEmployeeName(employee.name)}</Box>
        <RankBadge rank={employee.rank} />
        <IconButton
          onClick={(event) => {
            setAnchorEl(event.currentTarget);
            setShowModal(true);
          }}
          size="small"
        >
          <InfoCircle24 />
        </IconButton>
      </Box>

      <Popover
        open={showModal}
        anchorEl={anchorEl}
        onClose={() => {
          setShowModal(false);
          setAnchorEl(null);
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Box
          sx={{
            bgcolor: 'background.paper',
            borderRadius: 2,
            boxShadow: 24,
            display: 'flex',
            width: '370px',
            padding: '24px',
            flexDirection: 'column',
            alignItems: 'flex-start',
            gap: '18px',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              width: '100%',
            }}
          >
            <Box sx={{ typography: 'h5', mb: 2 }}>Employee Information</Box>

            {infoRows.map((row) => (
              <Box
                key={row.label}
                sx={(theme) => ({
                  py: theme.spacing(1.5),
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  gap: theme.spacing(2),
                  borderBottom: `1px solid ${theme.palette.gray[200]}`,
                })}
              >
                <Box sx={{ typography: 'body2', color: 'gray.700', flex: 1 }}>{row.label}</Box>
                <Box sx={{ typography: 'body2', flex: 1 }}>{row.value}</Box>
              </Box>
            ))}
          </Box>
        </Box>
      </Popover>
    </>
  );
};
