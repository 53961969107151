import { Box, useTheme } from '@mui/material';
import { format } from 'date-fns';
import {
  BlankCard,
  CardCertBadges,
  CircleHigherIcon20,
  RankBadge,
  ShiftDurationIndicator,
  asDepartmentDateTime,
  useLoadedDepartmentInfoContext,
} from '@stationwise/component-module';
import { RosterPosition, ShiftPlanAssignment } from '@stationwise/share-types';
import { differenceInUTCMinutes, shortenEmployeeName } from '@stationwise/share-utils';
import { formatShiftDuration } from '@stationwise/shift-summary-helper';
import { getDisplayedEmployeeCertifications } from '../../helpers/readCertifications';

interface ShiftPlanAssignmentCardProps {
  assignment: ShiftPlanAssignment;
  position?: RosterPosition;
  onClickAssignment?: () => void;
}

export const ShiftPlanAssignmentCard = ({ assignment, position, ...props }: ShiftPlanAssignmentCardProps) => {
  const theme = useTheme();
  const { state: departmentInfoState } = useLoadedDepartmentInfoContext();
  const { employee, reference } = assignment;
  const durationMinutes = differenceInUTCMinutes(assignment.endDateTime, assignment.startDateTime);
  const isPartial = !!position && durationMinutes < differenceInUTCMinutes(position.endDateTime, position.startDateTime);
  const isFuture = assignment.activationDate > asDepartmentDateTime(departmentInfoState, new Date());

  return (
    <BlankCard
      type="border"
      borderColor={assignment.team?.color}
      onClick={props.onClickAssignment}
      sxProps={{ cursor: props.onClickAssignment ? 'pointer' : undefined, gap: 1 }}
    >
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <RankBadge rank={employee.rank} />
        <Box sx={{ mx: 0.5, typography: 'bodySMedium' }}>{shortenEmployeeName(employee.name)}</Box>
        <Box sx={{ flex: 1 }} />
        {isFuture && (
          <Box sx={{ color: theme.palette.warning.main, typography: 'bodyXSRegular' }}>
            Starts {format(assignment.activationDate, 'M/d/yy')}
          </Box>
        )}
        {!isFuture && (!position || isPartial) && (
          <ShiftDurationIndicator
            durationLabel={formatShiftDuration({ startTime: assignment.startDateTime, endTime: assignment.endDateTime })}
            durationMinutes={durationMinutes}
          />
        )}
      </Box>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <CardCertBadges certs={getDisplayedEmployeeCertifications(departmentInfoState.departmentInfo, position, employee)} />
        <Box sx={{ flex: 1 }} />
        {reference.apparatus && !reference.position && <CircleHigherIcon20 />}
      </Box>
    </BlankCard>
  );
};
