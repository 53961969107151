import { Box } from '@mui/material';
import { useLoadedDepartmentInfoContext } from '@stationwise/component-module';
import { RosterPosition, RosterEmployee, DEFAULT_RANK } from '@stationwise/share-types';
import { DriverIcon20 } from '../../assets';
import { RankBadge, CertBadge } from '../Badge';
import { Chip } from '../Card';

interface PreviewHeaderProps {
  position: RosterPosition | null;
  employee: RosterEmployee;
  addNoteButton?: React.ReactNode;
  defaultStaffingSection?: React.ReactNode;
  actions?: React.ReactNode;
}

export const PreviewHeader = ({ position, employee, addNoteButton, defaultStaffingSection, actions }: PreviewHeaderProps) => {
  const {
    state: { departmentInfo },
  } = useLoadedDepartmentInfoContext();

  const isActingAsRank = position && position.rank.id !== DEFAULT_RANK.id ? position.rank.code !== employee.rank.code : false;
  return (
    <Box sx={(theme) => ({ backgroundColor: theme.palette.gray[800], p: 2 })}>
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
        {position && position.driver && (
          <Box component={DriverIcon20} sx={(theme) => ({ color: theme.palette.gray[400], mr: 0.5 })} />
        )}
        {departmentInfo.settings.actingRolesEnabled && isActingAsRank && <Chip label="Acting as" />}
        {position && position.rank.id !== DEFAULT_RANK.id && (
          <RankBadge
            rank={!departmentInfo.settings.actingRolesEnabled && isActingAsRank ? employee.rank : position.rank}
            rankField="name"
            maxLength={32}
            dark={true}
          />
        )}
        <Box sx={{ flex: 1 }} />
        {addNoteButton}
      </Box>
      <Box
        sx={(theme) => ({
          mt: 2,
          color: theme.palette.common.white,
          typography: 'bodyLMedium',
        })}
      >
        {employee.name}
      </Box>
      {!!employee.certifications.length && (
        <Box sx={{ display: 'flex', gap: 0.5, flexWrap: 'wrap', maxWidth: '290px', mt: 2 }}>
          {employee.certifications.map((certification, index) => (
            <CertBadge key={index} cert={certification} dark={true} />
          ))}
        </Box>
      )}
      {defaultStaffingSection}
      {actions}
    </Box>
  );
};
