import { Box, useTheme } from '@mui/material';
import { useAuthUserCapabilities } from '@stationwise/component-module';
import { formatDurationMinutes } from '@stationwise/shift-summary-helper';
import { Button } from '../../../Button';
import { Reason } from './Reason';
import { SplitActions } from './SplitActions';
import { SplitErrorBanner } from './SplitErrorBanner';
import { SplitTime } from './SplitTime';
import { SplitTitle } from './SplitTitle';
import { splitSplit, updateSplitDateTime } from './actions';
import { SplitProps } from './types';

const NULL_DATE = new Date('NULL_DATE');

export const Split = (props: SplitProps) => {
  const theme = useTheme();
  const { split, splitStatus } = props;
  const prevSplitStatus = props.splitStatuses[props.index - 1];
  const nextSplitStatus = props.splitStatuses[props.index + 1];
  const hasDurationError = !!splitStatus.errors.get('DURATION');
  const capabilities = useAuthUserCapabilities();

  return (
    <Box
      sx={{
        background: split.reference.type === 'ASSIGNMENT' ? theme.palette.common.white : theme.palette.gray[50],
        border: `1px ${split.reference.type === 'ASSIGNMENT' ? 'solid' : 'dashed'} ${theme.palette.gray[400]}`,
        borderRadius: '16px',
        p: 2,
        mt: 2,
      }}
    >
      <Box sx={{ display: 'flex', alignItems: 'flex-start', gap: 1 }}>
        <Box sx={{ flex: 1 }}>
          <SplitTitle {...props} />
        </Box>
        <SplitActions {...props} />
      </Box>
      {split.reference.type === 'TIME_OFF_REQUEST' && capabilities.EDIT_OFF_ROSTER && <Reason {...props} />}
      <SplitErrorBanner errors={splitStatus.errors} />
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1.5, mt: 3 }}>
        <SplitTime
          disabled={splitStatus.isStartDateTimeDisabled || !!(props.isPlannedEmployee && prevSplitStatus?.isEndDateTimeDisabled)}
          label="Start"
          value={split.startDateTime === NULL_DATE ? null : split.startDateTime}
          onChange={(value) => updateSplitDateTime(props, 'startDateTime', value || NULL_DATE)}
          slotProps={{ textField: { error: hasDurationError } }}
        />
        <SplitTime
          disabled={splitStatus.isEndDateTimeDisabled || !!(props.isPlannedEmployee && nextSplitStatus?.isStartDateTimeDisabled)}
          label="End"
          value={split.endDateTime === NULL_DATE ? null : split.endDateTime}
          onChange={(value) => updateSplitDateTime(props, 'endDateTime', value || NULL_DATE)}
          slotProps={{ textField: { error: hasDurationError } }}
        />
        <Box sx={{ flex: 1, whiteSpace: 'nowrap', color: hasDurationError ? theme.palette.error.main : undefined }}>
          Duration
          <br />
          {formatDurationMinutes(split)}
        </Box>
        {split.reference.type !== 'SHIFT_TRADE_REQUEST' && (
          <Button variant="outlined" onClick={() => splitSplit(props)}>
            Split
          </Button>
        )}
      </Box>
    </Box>
  );
};
