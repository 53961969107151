import { Autocomplete as MuiAutocomplete, Box, TextField } from '@mui/material';
import { createFilterOptions } from '@mui/material/Autocomplete';
import { useEffect, useState } from 'react';
import { useRosterContext } from '@stationwise/component-module';
import { checkIsEvent, DEFAULT_EVENT_STATION } from '@stationwise/shift-summary-helper';
import { ITeamDefinition } from '../..';

export const TeamDefinition = ({
  teamDefinition,
  setNextDisabled,
  setTeamDefinition,
  completeStep,
}: {
  teamDefinition: ITeamDefinition;
  setNextDisabled: (disabled: boolean) => void;
  setTeamDefinition: (teamDefinition: ITeamDefinition) => void;
  completeStep: () => void;
}) => {
  const { shiftSummaryHelper } = useRosterContext();

  interface StationOption {
    inputValue?: string;
    station: string;
  }

  const stationOptions: StationOption[] = (() => {
    const result = new Set<string>();
    result.add(DEFAULT_EVENT_STATION);
    shiftSummaryHelper.allStationCards.forEach((station) => {
      if (checkIsEvent(station)) {
        result.add(station.stationName);
      }
    });

    return Array.from(result).map((station) => ({ station }));
  })();

  const [name, setName] = useState(teamDefinition.name);
  const [station, setStation] = useState(teamDefinition.station);

  useEffect(() => {
    if (station && name) {
      setNextDisabled(false);
      setTeamDefinition({ name: name, station: station });

      completeStep();
    } else {
      setNextDisabled(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [name, station]);

  const filter = createFilterOptions<StationOption>();

  return (
    <Box sx={{ width: '428px', maxWidth: '100%' }}>
      <Box sx={(theme) => ({ mt: theme.spacing(2.5), mb: theme.spacing(1), typography: 'bodySMedium' })}>Event group</Box>
      <MuiAutocomplete
        options={stationOptions}
        value={station ?? DEFAULT_EVENT_STATION}
        onChange={(_event, newValue) => {
          if (typeof newValue === 'string') {
            setStation(newValue);
          } else if (newValue && newValue.inputValue) {
            // Create a new value from the user input
            setStation(newValue.inputValue);
          } else {
            setStation(newValue.station);
          }
        }}
        filterOptions={(options, params) => {
          const filtered = filter(options, params);

          const { inputValue } = params;
          // Suggest the creation of a new value
          const isExisting = options.some((option) => inputValue === option.station);
          if (inputValue !== '' && !isExisting) {
            filtered.push({
              inputValue,
              station: `Add "${inputValue}"`,
            });
          }

          return filtered;
        }}
        freeSolo
        selectOnFocus
        clearOnBlur
        handleHomeEndKeys
        getOptionLabel={(option) => {
          // Value selected with enter, right from the input
          if (typeof option === 'string') {
            return option;
          }
          // Add "xxx" option created dynamically
          if (option.inputValue) {
            return option.inputValue;
          }
          // Regular option
          return option.station;
        }}
        renderOption={(itemProps, option) => (
          <li {...itemProps} key={itemProps.key}>
            {option.station}
          </li>
        )}
        disableClearable
        forcePopupIcon={true}
        renderInput={(params) => (
          <TextField
            {...params}
            sx={{
              '& .MuiOutlinedInput-root': { padding: '2.5px 3px 2.5px 12px' },
            }}
          />
        )}
      />
      <Box sx={(theme) => ({ mt: theme.spacing(2.5), typography: 'bodySMedium' })}>Name</Box>
      <TextField
        value={name}
        onChange={(event) => setName(event.target.value)}
        sx={(theme) => ({
          width: '100%',
          mt: theme.spacing(1),
          '& ::placeholder': {
            typography: 'bodyMRegular',
            color: theme.palette.gray[900],
          },
        })}
        placeholder="Event name"
      />
    </Box>
  );
};
