import { Box, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { Dispatch, SetStateAction } from 'react';
import { Button, DialogXButton } from '@stationwise/component-module';
import { VacantStation } from '@stationwise/share-types';
import { Vacancies } from '../Vacancies';

interface RequestVolunteersMainProps {
  isOpen: boolean;
  vacantStations: VacantStation[];
  requestVolunteersIsLoading: boolean;
  setRequestVolunteersIsOpen: Dispatch<SetStateAction<boolean>>;
  setShowRequestVolunteersEligibleEmployees: Dispatch<SetStateAction<boolean>>;
}

export const RequestVolunteersMain = ({
  isOpen,
  vacantStations,
  requestVolunteersIsLoading,
  setRequestVolunteersIsOpen,
  setShowRequestVolunteersEligibleEmployees,
}: RequestVolunteersMainProps) => {
  const openEligibleEmployeesModal = () => {
    setShowRequestVolunteersEligibleEmployees(true);
  };
  const handleCancelRequestVolunteers = () => {
    setRequestVolunteersIsOpen(false);
  };
  return (
    <Dialog open={isOpen} onClose={handleCancelRequestVolunteers}>
      <DialogTitle>
        <Box display="flex" justifyContent="space-between">
          <Box sx={{ typography: 'bodyXLSemibold', textAlign: 'center' }}>Request volunteers</Box>
          <DialogXButton onClose={handleCancelRequestVolunteers} />
        </Box>
      </DialogTitle>
      <DialogContent>
        <Vacancies vacantStations={vacantStations} />
      </DialogContent>
      <DialogActions>
        <Button
          onClick={openEligibleEmployeesModal}
          variant="contained"
          size="large"
          sx={{ width: '100%' }}
          loading={requestVolunteersIsLoading}
        >
          Request volunteers to fill vacancies
        </Button>
      </DialogActions>
    </Dialog>
  );
};
