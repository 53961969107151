import { Box, Typography, useTheme } from '@mui/material';
import { HIRING_ENGINE_ICONS, HiringEngineIconType } from '../../constants';

interface HiringEngineFeatureCardProps {
  title: string;
  description: string;
  buttonContainer: React.ReactNode;
  icon: HiringEngineIconType;
}

export const HiringEngineFeatureCard = ({ title, description, buttonContainer, icon }: HiringEngineFeatureCardProps) => {
  const theme = useTheme();
  const IconComponent = HIRING_ENGINE_ICONS[icon];

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        border: `1px solid ${theme.palette.divider}`,
        borderRadius: 1,
        bgcolor: theme.palette.background.paper,
        p: 2,
        gap: 2,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          borderRadius: 1,
          height: '48px',
          width: '48px',
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: icon === 'flow' ? theme.palette.blue[600] : '#F2F2F2',
        }}
      >
        <IconComponent />
      </Box>

      <Box sx={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
        <Typography variant="bodyLMedium">{title}</Typography>
        <Typography variant="body2" color="text.secondary" sx={{ mb: 2 }}>
          {description}
        </Typography>
        {buttonContainer}
      </Box>
    </Box>
  );
};
