import { Box } from '@mui/material';
import { format, differenceInMinutes } from 'date-fns';
import { useState } from 'react';
import { GenericDrawerOrModal, getVisibleTags, useDepartmentInfoContext } from '@stationwise/component-module';
import { CreateDutyFormDTO, PayCode } from '@stationwise/share-types';
import { client, isAxiosError } from '../../../../../libs/share-api/src/lib/axios';
import { parseHourAndMinute } from '../../../../../libs/share-utils/src/lib/requests';
import { PayCodeSelect } from '../AdditionalPaidTime/AdditionalPaidTimePayCodeSelector';
import { DutyFormContent } from './DutyFormContent';

interface DutyFormProps {
  handleOnClose: () => void;
  drawerOpen: boolean;
  selectedDate: string;
  handleDutyFormRequest: (responseMessage: string, error: boolean) => void;
}

export const DutyForm = ({ handleOnClose, drawerOpen, selectedDate, handleDutyFormRequest }: DutyFormProps) => {
  const { state: department } = useDepartmentInfoContext();
  const [selectPayCodeOpen, setSelectPayCodeOpen] = useState(false);
  const [selectedPayCode, setSelectedPayCode] = useState<PayCode | null>(null);
  const departmentPayCodes = department?.departmentInfo?.payCodes || [];
  const visibleTags = getVisibleTags(department?.departmentInfo, 'DUTY_FORM', 'paycode');

  // Filter pay codes based on visible tags
  const filteredPayCodes =
    visibleTags.length > 0
      ? departmentPayCodes.filter((payCode) => payCode.tags.some((tag) => visibleTags.includes(tag)))
      : departmentPayCodes;

  const handleSelectPayCodeOpen = () => {
    setSelectPayCodeOpen(true);
  };

  const handleSelectPayCodeClose = () => {
    setSelectPayCodeOpen(false);
  };

  const handleClose = () => {
    setSelectedPayCode(null);
    handleOnClose();
  };

  const {
    state: { departmentInfo },
  } = useDepartmentInfoContext();

  const prepareRequest = (additionalPaidTime: { start: Date; end: Date }): [number, number, string] => {
    const [departmentStartHours, departmentStartMinutes] = departmentInfo
      ? parseHourAndMinute(departmentInfo.shiftStart)
      : [0, 0];
    const hour = additionalPaidTime.start.getHours();
    const minute = additionalPaidTime.start.getMinutes();
    const startTimeFromDepartmentShiftStart = (hour - departmentStartHours) * 60 + (minute - departmentStartMinutes);
    const minutesDiff = differenceInMinutes(additionalPaidTime.end, additionalPaidTime.start);
    const shiftDate = format(additionalPaidTime.start, 'yyyy-MM-dd');
    return [startTimeFromDepartmentShiftStart, minutesDiff, shiftDate];
  };

  const handleRequest = (additionalPaidTime: { start: Date; end: Date }, note: string, eventLocation: string) => {
    const [startTime, duration, shiftDate] = prepareRequest(additionalPaidTime);
    const requestDto = {
      note: note,
      startTime: startTime,
      duration: duration,
      additionalPaidTimeDate: shiftDate,
      payCode: selectedPayCode?.code ?? '',
      additionalPaidTimeType: 'DUTY_ENTRY',
      eventLocation: eventLocation,
    };

    handleSubmit(requestDto);
  };

  const handleSubmit = async (requestDto: CreateDutyFormDTO) => {
    let error = false;
    let responseMessage = '';
    try {
      await client.post('/event/duty-form/', requestDto);
      responseMessage = 'Your duty form has been successfully submitted';
    } catch (err: unknown) {
      error = true;
      if (isAxiosError(err) && typeof err.response?.data.detail === 'string') {
        responseMessage = err.response.data.detail;
      } else if (isAxiosError(err) && typeof err.response?.data.nonFieldErrors?.[0] === 'string') {
        responseMessage = err.response.data.nonFieldErrors[0];
      } else {
        responseMessage = 'We were unable to process your duty form. Please try again later.';
      }
    } finally {
      handleDutyFormRequest(responseMessage, error);
    }
  };

  const handleDutySave = (additionalPaidTime: { start: Date; end: Date }, note: string, eventLocation: string) => {
    handleRequest(additionalPaidTime, note, eventLocation);
  };

  return (
    <>
      <GenericDrawerOrModal
        anchor="bottom"
        drawerOpen={drawerOpen}
        handleOnClose={handleClose}
        loading={false}
        showHeader={true}
        disableFooter={true}
        headerTitle={'Station duty entry'}
      >
        <Box>
          <DutyFormContent
            handleClose={handleClose}
            selectedDate={selectedDate}
            handleDutySave={handleDutySave}
            handleSelectPayCodeOpen={handleSelectPayCodeOpen}
            payCode={selectedPayCode}
          />
        </Box>
      </GenericDrawerOrModal>

      <PayCodeSelect
        handleOnClose={handleSelectPayCodeClose}
        drawerOpen={selectPayCodeOpen}
        options={filteredPayCodes}
        setPayCode={setSelectedPayCode}
        header="Duty Type"
      />
    </>
  );
};
