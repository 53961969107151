import { Theme } from '@mui/material';
import {
  HornIcon16,
  MinusCircleIcon16,
  RibbonIcon16,
  SwitchHorizontalIcon16,
  ThermometerIcon16,
  UmbrellaIcon16,
  ClockCompTimeIcon16,
} from '../../assets';

interface Request {
  requestType: string;
  timeOffType?: string | null;
}

export const getRequestTypeIcon = (request: Request, theme: Theme) => {
  if (request.requestType === 'ShiftTradeRequest') {
    return { backgroundColor: theme.palette.lightBlue[500], icon: <SwitchHorizontalIcon16 /> };
  } else if (request.timeOffType?.toLowerCase().includes('bereavement')) {
    return { icon: <RibbonIcon16 /> };
  } else if (request.timeOffType?.toLowerCase().includes('emergency')) {
    return { icon: <HornIcon16 /> };
  } else if (request.timeOffType?.toLowerCase().includes('sick')) {
    return { icon: <ThermometerIcon16 /> };
  } else if (request.timeOffType?.toLowerCase().includes('vacation')) {
    return { icon: <UmbrellaIcon16 /> };
  } else if (request.requestType === 'CompTimeRequest') {
    return { icon: <ClockCompTimeIcon16 /> };
  } else {
    return { icon: <MinusCircleIcon16 /> };
  }
};
