import { Box } from '@mui/material';
import { format, parseISO } from 'date-fns';
import { RosterShiftDuration, StrikeTeamCandidate } from '@stationwise/share-types';
import { ExclamationMarkIcon24 } from '../../../../assets';
import { Button } from '../../../Button';
import { useRosterContext } from '../RosterContext';

interface AssignmentConfirmationProps {
  candidate: StrikeTeamCandidate;
  maxConsecutiveHourErrors: RosterShiftDuration[];
  onCancel: () => void;
  onConfirm?: () => void;
}

export const AssignmentConfirmation = ({
  candidate,
  maxConsecutiveHourErrors,
  onCancel,
  onConfirm,
}: AssignmentConfirmationProps) => {
  const { shiftSummaryHelper } = useRosterContext();
  const maxConsecutiveHours = shiftSummaryHelper.departmentInfo.settings.maxConsecutiveHoursRule.consecutiveHours;

  const { blockers, vacancyDates } = candidate.conflicts;

  const blockerList = !blockers.length ? null : (
    <Box>
      <Box>{"This person's schedule conflicts with the event on the following dates."}</Box>
      <ul>
        {blockers.map((blocker, i) => (
          <li key={i}>
            {format(parseISO(blocker.date), 'MMM dd, yyyy')}
            {': '}
            {blocker.reason}
          </li>
        ))}
      </ul>
    </Box>
  );

  const nonBlockerList = (
    <Box sx={{ '& > * + *': { mt: 4 } }}>
      {maxConsecutiveHourErrors.length > 0 && typeof maxConsecutiveHours === 'number' && (
        <Box>
          <Box>{`This person will exceed the maximum ${maxConsecutiveHours} consecutive hours on the following dates.`}</Box>
          <ul>
            {maxConsecutiveHourErrors.map((duration, i) => (
              <li key={i}>
                {format(duration.startTime, 'MMM dd, yyyy')}
                {' - '}
                {format(duration.endTime, 'MMM dd, yyyy')}
                {' : '}
                {`${duration.hours} hours`}
              </li>
            ))}
          </ul>
        </Box>
      )}
      {vacancyDates.length > 0 && (
        <Box>
          <Box>Vacancies will be created on the following dates.</Box>
          <ul>
            {vacancyDates.map((date, i) => (
              <li key={i}>{format(parseISO(date), 'MMM dd, yyyy')}</li>
            ))}
          </ul>
        </Box>
      )}
    </Box>
  );

  return (
    <Box sx={{ p: 3, width: '444px' }}>
      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        <Box
          sx={(theme) => ({
            border: `1px solid ${theme.palette.gray[50]}`,
            borderRadius: '50%',
            boxShadow: '0px 2px 4px -1px #0A0E160F, 0px 4px 6px -1px #0A0E161A',
            display: 'inline-flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: '56px',
            height: '56px',
            '& svg path': { stroke: theme.palette.orange[500] },
          })}
        >
          <ExclamationMarkIcon24 />
        </Box>
      </Box>
      <Box
        sx={(theme) => ({
          py: 3,
          textAlign: 'center',
          '& ul': {
            display: 'inline-flex',
            flexDirection: 'column',
            listStyleType: 'disc',
            m: 0,
            p: theme.spacing(0, 0, 0, 2),
          },
          '& li': { m: theme.spacing(0.5, 0, 0, 0), textAlign: 'left' },
        })}
      >
        {blockerList || nonBlockerList}
      </Box>
      <Box sx={{ display: 'flex', gap: 2 }}>
        <Button onClick={onCancel} size="large" variant="outlined" sx={{ flex: 1 }}>
          Cancel
        </Button>
        <Button onClick={onConfirm} disabled={!!blockers.length} size="large" variant="contained" sx={{ flex: 1 }}>
          Confirm
        </Button>
      </Box>
    </Box>
  );
};
