import { useState } from 'react';
import { nowrap } from '@stationwise/share-utils';
import { useShiftPlanContext } from '../ShiftPlanContext';
import { ShiftPlanDialogActions, WarningMessage } from '../ShiftPlanDialog';

export const ToggleApparatusModeWarning = () => {
  const { selectedStruct, setSelectedAction, saveAsDraft } = useShiftPlanContext();
  const [{ apparatus }] = useState(selectedStruct);

  let primaryText = '';
  let secondaryText = '';
  if (apparatus) {
    if (apparatus.isReserved) {
      primaryText = `You are about to activate ${nowrap(apparatus.name)}.`;
    } else {
      primaryText = `You are about to put ${nowrap(apparatus.name)} on reserve.`;
      secondaryText = 'All assigned employees of all teams will be moved to the floater station.';
    }
  }

  const onToggleApparatusMode = () => {
    if (apparatus) {
      const payload = {
        action: 'UPSERT_APPARATUS',
        apparatusId: apparatus.id,
        isReserved: !apparatus.isReserved,
      };
      saveAsDraft(payload, () => setSelectedAction(null));
    }
  };

  return (
    <>
      <WarningMessage primaryText={primaryText} secondaryText={secondaryText} />
      <ShiftPlanDialogActions saveText="Confirm" onSave={onToggleApparatusMode} />
    </>
  );
};
