import { Box, SxProps, Theme, useTheme } from '@mui/material';
import { Team } from '@stationwise/share-types';
import { capitalize } from '@stationwise/share-utils';
import { getTeamColors } from '../../utils';

interface TeamBadgeProps {
  team: Pick<Team, 'name' | 'color'>;
  maxLength?: number;
  dark?: boolean;
  sx?: SxProps<Theme>;
}

export const TeamBadge = (props: TeamBadgeProps) => {
  const theme = useTheme();
  const { team, maxLength = 15, dark = false, sx } = props;

  return (
    <Box
      sx={[
        {
          ...getTeamColors({ theme, color: team.color, dark }),
          borderRadius: '10px',
          display: 'inline-flex',
          padding: '2px 6px',
          typography: 'tagline1',
          textWrap: 'nowrap',
        },
        ...(Array.isArray(sx) ? sx : sx ? [sx] : []),
      ]}
    >
      {capitalize(team.name).slice(0, maxLength)}
    </Box>
  );
};
