import { Box, CircularProgress } from '@mui/material';
import { CircleTickIcon } from '@stationwise/component-module';

interface ProgressProps {
  // This value must be between 0 and 100
  // this is the percentage
  progress: number;
  tasks: number;
  isLoading?: boolean;
}

export const Progress = ({ progress, tasks, isLoading }: ProgressProps) => {
  if (isLoading) {
    return null;
  }

  if (progress === 100) {
    return (
      <Box display="flex" gap={1} alignItems="center">
        <Box
          display="flex"
          alignContent="center"
          sx={(theme) => ({
            height: '14px',
            width: '14px',
            '& path': {
              fill: theme.palette.teal[500],
            },
          })}
        >
          <CircleTickIcon />
        </Box>
        <Box sx={(theme) => ({ color: theme.palette.green[500], typography: 'bodySRegular' })}>All completed</Box>
      </Box>
    );
  }

  return (
    <Box display="flex" gap={1} alignItems={'center'}>
      <Box display="flex" position="relative" alignItems={'center'}>
        <Box display="flex" position="absolute" alignItems={'center'}>
          <CircularProgress
            variant="determinate"
            value={100}
            size={18}
            thickness={8}
            sx={(theme) => ({
              color: theme.palette.gray[200],
            })}
          />
        </Box>
        <CircularProgress
          variant="determinate"
          value={progress}
          size={18}
          thickness={8}
          sx={(theme) => ({
            color: theme.palette.gray[500],
          })}
        />
      </Box>
      <Box sx={(theme) => ({ color: theme.palette.gray[400], typography: 'bodySRegular' })}>{tasks} completed</Box>
    </Box>
  );
};
