import * as amplitude from '@amplitude/analytics-browser';
import { LoggedInEmployeeDataView } from '@stationwise/share-types';

export const useAmplitude = () => {
  if (process.env['AMPLITUDE_API_KEY']) {
    try {
      amplitude.init(process.env['AMPLITUDE_API_KEY'], { defaultTracking: true });
    } catch (e) {
      console.error(e);
    }
  }
};

export function setAmplitudeUser(employee: LoggedInEmployeeDataView) {
  amplitude.setUserId(employee.id);
  const identify = new amplitude.Identify();
  identify.set('department', employee.departmentDomain);
  identify.set('email', employee.email);
  identify.set('rank', employee.rank.name);
  amplitude.identify(identify);
}
