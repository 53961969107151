import { Box, LinearProgress, linearProgressClasses } from '@mui/material';

export interface TimeOffProps {
  label: string;
  remaining: number;
  pending: number;
  maxAccrualLimit: number;
  barColor: string;
  pendingBarColor: string;
}

export const TimeOff = ({ label, remaining, pending, maxAccrualLimit, barColor, pendingBarColor }: TimeOffProps) => {
  return (
    <Box
      sx={(theme) => ({
        boxShadow: '0px 1px 2px 0px rgba(10, 14, 22, 0.05)',
        borderRadius: 2,
        pt: theme.spacing(2),
      })}
    >
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Box
          sx={(theme) => ({
            color: theme.palette.gray[700],
            typography: 'bodyMSemibold',
            width: '60%',
            mr: theme.spacing(1),
          })}
        >
          {label}
        </Box>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Box sx={(theme) => ({ color: theme.palette.gray[400], mr: 1, typography: 'bodySMedium' })}>remaining</Box>
          <Box data-testid="max-accrual-limit" sx={(theme) => ({ color: theme.palette.gray[700], typography: 'bodySMedium' })}>
            {(remaining - pending).toFixed(2)} of {maxAccrualLimit >= Number.MAX_SAFE_INTEGER ? 'Infinite' : maxAccrualLimit} hrs
          </Box>
        </Box>
      </Box>
      <Box sx={(theme) => ({ mt: theme.spacing(3) })}>
        <LinearProgress
          variant="buffer"
          value={((remaining - pending) * 100) / maxAccrualLimit}
          valueBuffer={(remaining * 100) / maxAccrualLimit}
          color="primary"
          sx={(theme) => ({
            height: '6px',
            borderRadius: '23px',
            [`&.${linearProgressClasses.colorPrimary}`]: {
              backgroundColor: theme.palette.gray[100],
              [`& .${linearProgressClasses.bar}`]: {
                borderRadius: 5,
                backgroundColor: barColor,
              },
              [`& .${linearProgressClasses.bar2Buffer}`]: {
                borderRadius: 5,
                backgroundColor: pendingBarColor,
              },
            },
            '& .MuiLinearProgress-dashed': {
              backgroundColor: theme.palette.gray[100],
              backgroundImage: 'none',
              animation: 'none',
            },
          })}
        />
      </Box>
    </Box>
  );
};
