import { Certification } from '../certification';
import { Rank, Team } from '../organization/models';
import { PayCode } from '../payroll/models';
import { EmployeeShiftDetails } from './getEmployeeProfileDetails';

export interface BasicEmployee {
  id: string;
  name: string;
  rank: Rank;
}

export interface ManagerStatusEmployee extends BasicEmployee {
  isManager?: boolean;
}

export interface EmployeeDefaults {
  arrivedEarlyPayCode: PayCode;
  heldOverPayCode: PayCode;
  overtimeAssignmentPayCode: PayCode;
  regularAssignmentPayCode: PayCode;
}

export interface EmployeeMaybeConsecutiveWorkDuration {
  start: string;
  end: string;
}

export interface Candidate {
  id: number;
  name: string;
  rank: Rank;
  certifications: Certification[];
  team: Team | null;
  defaults: { regularAssignmentPayCode: Pick<PayCode, 'id'>; overtimeAssignmentPayCode: Pick<PayCode, 'id'> };
  startDateTime?: Date;
  endDateTime?: Date;
  partialPlannedPositions?: CandidatePartialPlannedPosition[];
}

export interface ResponsibilityEmployee {
  id: number;
  name: string;
  rank: Rank;
}

export interface Responsibility {
  id?: number;
  name?: string;
  employee?: ResponsibilityEmployee;
}

export interface CandidatePartialPlannedPosition {
  startDateTime?: Date;
  endDateTime?: Date;
}

export type EligibleEmployee = {
  id: string;
  firstName: string;
  lastName: string;
  minutesOwed: number;
  owesToResponder: boolean;
  certifications: Certification[];
  rank: Rank;
  defaults: Pick<EmployeeDefaults, 'regularAssignmentPayCode' | 'overtimeAssignmentPayCode'>;
  team: Team | null;
};

export interface EligibleEmployeeForHiringEngine {
  id: string;
  rank: Rank;
  name: string;
  certifications: Certification[];
  plannedShiftDetails: EmployeeShiftDetails[];
}

export enum TimePreferenceChoice {
  ANY = 'ANY',
  ONLY_FULL = 'ONLY_FULL',
  ONLY_PARTIAL = 'ONLY_PARTIAL',
}
