import { EventInput } from '@fullcalendar/core';
import { EventImpl } from '@fullcalendar/core/internal';
import { Box, Divider, useMediaQuery } from '@mui/material';
import { format } from 'date-fns';
import React from 'react';
import { GetIncidentOverview } from '@stationwise/share-types';
import { makeTestIdentifier } from '@stationwise/share-utils';
import { BigArrowRightIcon, ChevronDownIcon24, ChevronRightIcon24 } from '../../assets';
import { BlankCard } from '../BlankCard';
import { Chip } from '../Card/Chip';
import { CALENDAR_EVENT_TYPES, SHIFT_TITLES } from '../EmployeeCalendar';
import { APTEventCard } from './EventCards/APTEventCard';
import { truncateTitle } from './EventCards/EventCardUtils';
import { ExemptionCard } from './EventCards/ExemptionCard';
import { IncidentCard } from './EventCards/IncidentCard';
import { KellyDayCard } from './EventCards/KellyDayCard';
import { OvertimeEventCard } from './EventCards/OvertimeEventCard';
import { RegularEventCard } from './EventCards/RegularEventCard';
import { ShiftTradeEventCard } from './EventCards/ShiftTradeEventCard';
import { TimeOffRequestEventCard } from './EventCards/TimeOffRequestEventCard';

export const CalendarShiftCard = ({
  shift,
  children,
  handleClick,
  statusBadge,
  selectedView,
  showIcon = true,
  mini = true,
  todayEvents,
  handleSelect,
  handleShiftCardOverviewClose,
  setSelectedIncident,
}: {
  shift: EventInput | EventImpl;
  children?: React.ReactNode;
  handleClick?: (event: React.MouseEvent<HTMLElement>, shift: EventInput | EventImpl, isEventCard?: boolean) => void;
  statusBadge?: React.ReactNode;
  selectedView?: string;
  showIcon?: boolean;
  mini?: boolean;
  todayEvents?: EventInput[];
  handleSelect?: (option: string) => void;
  handleShiftCardOverviewClose?: () => void;
  setSelectedIncident?: (incident: GetIncidentOverview) => void;
}) => {
  const isSmallScreen = useMediaQuery('(max-width:400px)');
  if (mini) {
    return (
      <Box
        data-cy={`event-card-${makeTestIdentifier(shift.title || '')}`}
        className="shift-card-box"
        sx={{
          mx: 2,
          pb: { xs: selectedView === 'dayGridMonth' ? 2 : '', lg: '' },
        }}
        onClick={(event) => handleClick && handleClick(event, shift)}
      >
        <BlankCard sxProps={{ padding: '12px', pr: '6px' }}>
          <Box sx={{ display: 'flex' }}>
            <Box sx={(theme) => ({ flexGrow: 1, mr: theme.spacing(1) })}>
              <Box display="flex" justifyContent="space-between">
                <Box display="flex">
                  <Chip
                    label={truncateTitle((shift.title || '').toUpperCase(), isSmallScreen ? 32 : 37)}
                    chipSxProps={() => ({
                      background: shift.backgroundColor,
                      height: '24px',
                      p: '4px 8px',
                      borderRadius: '7px',
                      textOverflow: 'ellipsis',
                      overflow: 'hidden',
                      alignItems: 'flex-start',
                    })}
                    variant="eyebrow"
                    typographyStyles={{
                      color: shift.textColor ? shift.textColor : 'inherit',
                    }}
                  />
                </Box>
                {statusBadge && <Box>{statusBadge}</Box>}
              </Box>

              <Box sx={(theme) => ({ mt: theme.spacing(1), display: 'flex', typography: 'bodyMRegular' })}>
                {shift.start && <Box>{format(shift.start as Date, 'M/d/yy')}</Box>}
                <Box sx={{ ml: '9px', mr: '9px', mt: '2px' }}>
                  <BigArrowRightIcon />
                </Box>
                {shift.end && <Box>{format(shift.end as Date, 'M/d/yy')}</Box>}
                <Box
                  display="flex"
                  sx={(theme) => ({
                    ml: '10px',
                    color: theme.palette.gray[400],
                  })}
                >
                  {shift.start && <Box>{format(shift.start as Date, 'HH:mm')}</Box>}
                  <Box sx={{ ml: '3px', mr: '3px' }}>-</Box>
                  {shift.end && <Box>{format(shift.end as Date, 'HH:mm')}</Box>}
                </Box>
              </Box>
            </Box>
            {showIcon && (
              <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <Divider orientation="vertical" flexItem />
                <Box
                  sx={(theme) => ({
                    pl: theme.spacing(0.5),
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    '& svg': { color: theme.palette.gray[500] },
                  })}
                >
                  {shift.title === 'REGULAR' ? <ChevronDownIcon24 /> : <ChevronRightIcon24 />}
                </Box>
              </Box>
            )}
          </Box>
          {children}
        </BlankCard>
      </Box>
    );
  }
  return (
    <Box
      data-cy={`shift-event-card-${makeTestIdentifier(shift.title || '')}`}
      className="shift-event-card-box"
      sx={(theme) => ({
        mb: theme.spacing(2),
      })}
    >
      {(shift.title === SHIFT_TITLES.REGULAR || shift.title === SHIFT_TITLES.SHIFT_TRADE) && (
        <RegularEventCard
          shift={shift}
          setClickedEventAsCalendarSelectedElement={handleClick}
          todayEvents={todayEvents}
          openActionModal={handleSelect}
          handleShiftCardOverviewClose={handleShiftCardOverviewClose}
        ></RegularEventCard>
      )}
      {(shift.title === SHIFT_TITLES.OVERTIME ||
        (shift.extendedProps && shift.extendedProps['eventType'] === 'STAFFING_LIST_MARK')) && (
        <OvertimeEventCard
          shift={shift}
          setClickedEventAsCalendarSelectedElement={handleClick}
          openActionModal={handleSelect}
          handleShiftCardOverviewClose={handleShiftCardOverviewClose}
        ></OvertimeEventCard>
      )}
      {(shift.title === SHIFT_TITLES.ADDITIONAL_PAID_TIME ||
        shift.title === SHIFT_TITLES.ADDITIONAL_PAID_TIME_REQUEST ||
        shift.title === SHIFT_TITLES.DUTY_FORM_ENTRY ||
        (shift.title && shift.title.includes(SHIFT_TITLES.INCIDENT_PAY))) && (
        <APTEventCard
          shift={shift}
          setClickedEventAsCalendarSelectedElement={handleClick}
          openActionModal={handleSelect}
          handleShiftCardOverviewClose={handleShiftCardOverviewClose}
        ></APTEventCard>
      )}
      {shift.title === SHIFT_TITLES.KELLY_DAY && <KellyDayCard shift={shift}></KellyDayCard>}
      {shift.extendedProps && shift.extendedProps['eventType'] === CALENDAR_EVENT_TYPES.STAFFING_LIST_EXEMPTION && (
        <ExemptionCard shift={shift} />
      )}
      {shift.extendedProps && shift.extendedProps['eventType'] === CALENDAR_EVENT_TYPES.INCIDENT && (
        <IncidentCard
          shift={shift}
          setClickedEventAsCalendarSelectedElement={handleClick}
          openActionModal={handleSelect}
          handleShiftCardOverviewClose={handleShiftCardOverviewClose}
          setSelectedIncident={setSelectedIncident}
        ></IncidentCard>
      )}

      {shift.extendedProps && shift.extendedProps['eventType'] === CALENDAR_EVENT_TYPES.TIME_OFF_REQUEST && (
        <TimeOffRequestEventCard
          shift={shift}
          setClickedEventAsCalendarSelectedElement={handleClick}
          openActionModal={handleSelect}
          handleShiftCardOverviewClose={handleShiftCardOverviewClose}
        />
      )}

      {shift.extendedProps && shift.extendedProps['eventType'] === CALENDAR_EVENT_TYPES.SHIFT_TRADE_REQUEST && (
        <ShiftTradeEventCard
          shift={shift}
          setClickedEventAsCalendarSelectedElement={handleClick}
          openActionModal={handleSelect}
          handleShiftCardOverviewClose={handleShiftCardOverviewClose}
        />
      )}
    </Box>
  );
};
