import { Box, IconButton } from '@mui/material';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import {
  Logo,
  useLoadedAuthUserContext,
  useLogout,
  useRequests,
  Menu06Icon24 as FullMenuIcon,
  REQUEST_CARD_STATUS,
  useLoadedDepartmentInfoContext,
} from '@stationwise/component-module';
import { PUSHER_EVENT_TYPES, PUSHER_UPDATE_MESSAGE, RefreshEventCallback } from '@stationwise/share-utils';
import { INavMenu } from '../../../app/AppPageLayout';
import { ROUTES } from '../../../core/Routes';
import { useSelectMenu } from '../../hooks/useSelectMenu';
import { DrawerMenu } from './DrawerMenu';
import { MessageNotification } from './MessageNotification';

export const calendarOptions = ['Month', 'List'];
export const calendarPaths = calendarOptions.map((option) => `${ROUTES.CALENDAR.fullRoute}?display=${option.toLowerCase()}`);

interface TopBarProps {
  navItems: INavMenu[];
}

export const TopBar = ({ navItems }: TopBarProps) => {
  const [mobileOpen, setMobileOpen] = useState(false);

  const { state: authUserState, dispatch } = useLoadedAuthUserContext();
  const { state: departmentInfoState } = useLoadedDepartmentInfoContext();
  const refreshTriggerChannel = departmentInfoState.refreshTriggerChannel;
  const logout = useLogout();

  const { selectedMenu } = useSelectMenu();
  const requests = useRequests(0);

  useEffect(() => {
    if (!refreshTriggerChannel) return;

    const handlePusherUpdate: RefreshEventCallback = (data) => {
      if (data.triggerAll || data.message === PUSHER_UPDATE_MESSAGE) {
        dispatch({ type: 'REFETCH_USER_INFO' });
      }
    };

    const EVENT_TYPES_LISTENED = [PUSHER_EVENT_TYPES.MESSAGE, PUSHER_EVENT_TYPES.REVIEW];

    refreshTriggerChannel.bind_many(EVENT_TYPES_LISTENED, handlePusherUpdate);

    return () => {
      if (refreshTriggerChannel) {
        refreshTriggerChannel.unbind_many(EVENT_TYPES_LISTENED);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authUserState.employee.id, refreshTriggerChannel]);

  useEffect(() => {
    const pendingRequest = requests[REQUEST_CARD_STATUS.PENDING];
    pendingRequest.initialize();
  }, [requests]);

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  const handleSelectItem = (item: INavMenu) => {
    if (item.type === 'logout') {
      logout();
    }

    if (item.type === 'support') {
      window.Intercom?.('show');
    }
  };

  return (
    <Box
      data-cy="app-top-bar"
      sx={(theme) => ({
        display: { xs: 'flex', lg: 'none' },
        flexDirection: 'row',
        width: '100%',
        height: '56px',
        justifyContent: 'space-between',
        alignItems: 'center',
        background: theme.palette.common.white,
        p: '12px 12px 12px 10px',
        position: 'sticky',
        top: 0,
        left: 0,
        zIndex: theme.zIndex.drawer,
        borderStyle: 'dashed',
        borderColor: theme.palette.gray[100],
        borderWidth: '1px 0px',
      })}
    >
      <Box
        component={Link}
        to="/"
        sx={{
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'row',
          my: 0.5,
          gap: 1,
        }}
      >
        <Logo size="sm" />
      </Box>
      <Box
        sx={{
          display: 'flex',
        }}
      >
        <Box data-testid="messages" component={Link} to={ROUTES.INBOX.fullRoute}>
          <MessageNotification
            count={authUserState.employee.unreadMessages.length + requests[REQUEST_CARD_STATUS.PENDING].data.length}
          />
        </Box>
        <Box
          sx={(theme) => ({
            ml: theme.spacing(2),
            mr: '-8px',
          })}
        >
          <IconButton
            data-cy="sidenav"
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 1 }}
          >
            <FullMenuIcon />
          </IconButton>
        </Box>
        <DrawerMenu
          navItems={navItems}
          selectedMenu={selectedMenu}
          handleSelectItem={handleSelectItem}
          mobileOpen={mobileOpen}
          handleDrawerToggle={handleDrawerToggle}
        />
      </Box>
    </Box>
  );
};
