import { Box, Typography, Alert } from '@mui/material';
import { format, parseISO } from 'date-fns';
import { useState } from 'react';
import { Button, theme } from '@stationwise/component-module';
import { HiringEngineVacancy } from '@stationwise/share-types';

interface SignUpModalContentProps {
  vacantPosition: HiringEngineVacancy;
  shiftDate: string;
  startTimeFormatted: string;
  endTimeFormatted: string;
  difference: string;
  hasStarted: boolean;
  onClickSignUp: () => void;
  onClickCancel: () => void;
}

export const SignUpModalContent = ({
  vacantPosition,
  shiftDate,
  startTimeFormatted,
  endTimeFormatted,
  difference,
  hasStarted,
  onClickSignUp,
  onClickCancel,
}: SignUpModalContentProps) => {
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSignUp = () => {
    setIsSubmitting(true);
    onClickSignUp();
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Typography variant="bodyXLSemibold">You are committing to work for the following position.</Typography>
      <Typography
        variant="bodyMRegular"
        sx={{
          marginTop: theme.spacing(1),
        }}
      >
        Warning: This cannot be undone
      </Typography>

      {hasStarted && (
        <Alert
          severity="warning"
          sx={{
            marginTop: theme.spacing(2),
            '& .MuiAlert-message': {
              width: '100%',
              padding: '4px 0',
            },
            '& .MuiAlert-icon': {
              padding: '4px 0',
            },
            padding: '0 16px',
          }}
        >
          This position has already started. Your shift will be counted as starting from the current time.
        </Alert>
      )}

      <Box
        sx={{
          marginTop: theme.spacing(1),
          textAlign: 'left',
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Typography variant="bodyMRegular">
          • {vacantPosition.rank.name} at {vacantPosition.stationName}, {vacantPosition.apparatusName}
        </Typography>
        <Typography variant="bodyMRegular">
          • {format(parseISO(shiftDate), 'MMM dd')} from {startTimeFormatted} - {endTimeFormatted} ({difference}){' '}
        </Typography>
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-end',
          marginTop: theme.spacing(2),
          gap: theme.spacing(2),
        }}
      >
        <Button variant="outlined" size="large" onClick={onClickCancel} disabled={isSubmitting}>
          Cancel
        </Button>
        <Button variant="contained" size="large" onClick={handleSignUp} disabled={isSubmitting}>
          Sign Up
        </Button>
      </Box>
    </Box>
  );
};
