import { Box } from '@mui/material';
import { memo } from 'react';
import { ResponsibilityEmployee } from '@stationwise/share-types';
import { RankBadge } from '../Badge';

interface Props {
  candidate: ResponsibilityEmployee;
  isSelected: boolean;
  onClick?: (responsibilityCandidate: ResponsibilityEmployee) => void;
  subdued?: boolean;
}

export const ResponsibilityCandidateCard = memo(({ candidate, isSelected, onClick, subdued }: Props) => {
  return (
    <Box
      component="button"
      type="button"
      onClick={() => (onClick ? onClick(candidate) : undefined)}
      sx={(theme) => ({
        backgroundColor: 'transparent',
        border: `1px solid ${isSelected ? theme.palette.gray[900] : theme.palette.gray[100]}`,
        boxShadow: '0px 1px 2px 0px #0A0E160D',
        borderRadius: 3,
        cursor: 'pointer',
        display: 'flex',
        alignItems: 'center',
        gap: 1.5,
        mt: 1,
        p: theme.spacing(1, 1.5),
        opacity: subdued ? 0.5 : undefined,
        textAlign: 'left',
        width: '100%',
        '&:first-of-type': { mt: 0 },
        '&:hover': {
          borderColor: isSelected ? theme.palette.gray[900] : theme.palette.gray[400],
        },
      })}
    >
      {candidate.rank && <RankBadge rank={candidate.rank} />}
      <Box sx={{ flex: 1, typography: 'bodyMMedium' }}>{candidate.name}</Box>
    </Box>
  );
});

ResponsibilityCandidateCard.displayName = 'Memo(ResponsibilityCandidateCard)';
