import { Box, Grid2, IconButton, ListItem, Menu, MenuItem } from '@mui/material';
import { parseISO } from 'date-fns';
import { useId, useState } from 'react';
import { Badge, Pencil01Icon24, useAuthUserCapabilities } from '@stationwise/component-module';
import { AllowedColors } from '@stationwise/share-types';
import { makeTestIdentifier } from '@stationwise/share-utils';

type InfoCardValueProps = {
  label?: string;
  value?: string | null;
  extra?: {
    icon?: React.ReactNode;
    badgeColor?: AllowedColors;
    labelColor?: string;
    custom?: React.ReactElement;
  };
  type?: 'text' | 'badge' | 'label' | 'date' | 'custom';
};

export type InfoCardField = {
  label?: string;
  field: InfoCardValueProps | InfoCardValueProps[];
};

type ButtonBeforeEdit = {
  onClick: () => void;
  buttonText: string;
};

type InfoCardProps = {
  fieldDefs: InfoCardField[][];
  title: string;
  handleEditModal?: () => void;
  editMenuButtons?: ButtonBeforeEdit[] | null;
  editMenuTitle?: string;
  isPayroll?: boolean;
};

const InfoCardValue = (field: InfoCardValueProps) => {
  switch (field.type) {
    case 'badge':
      return field.extra?.custom ?? <Badge label={field.value as string} isCustom isRound color={field.extra?.badgeColor} />;
    case 'label':
      return (
        <Box
          sx={(theme) => ({
            color: theme.palette.common.white,
            backgroundColor: theme.palette.lightBlue[500],
            borderRadius: '4px',
            padding: theme.spacing(0.5, 1),
            typography: 'bodySMedium',
          })}
        >
          {field.value}
        </Box>
      );
    case 'date':
      return (
        <Box
          sx={(theme) => ({
            color: theme.palette.gray[900],
            typography: 'bodyMMedium',
          })}
        >
          {parseISO(field.value as string).toLocaleDateString('en-US', {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
          })}
        </Box>
      );

    default:
      return (
        <Box
          sx={(theme) => ({
            color: theme.palette.gray[900],
            typography: 'bodySMedium',
            padding: '0px 0px',
            margin: '0px 0px',
            maxWidth: '120px',
          })}
        >
          {field.value?.trim()}
        </Box>
      );
  }
};

export const InfoCard = ({ fieldDefs, title, handleEditModal, editMenuButtons, editMenuTitle, isPayroll }: InfoCardProps) => {
  const id = useId();
  const capabilities = useAuthUserCapabilities();
  const canEditEmployeeProfile = capabilities.EDIT_EMPLOYEE_PROFILE;
  const [editMenuAnchorEl, setEditMenuAnchorEl] = useState<HTMLElement | null>(null);
  const hasEditMenuButtons = editMenuButtons && editMenuButtons.length > 0;

  const onEditIconClick = (target: HTMLElement) => {
    if (hasEditMenuButtons) {
      setEditMenuAnchorEl(target);
    } else if (handleEditModal) {
      handleEditModal();
    }
  };

  return (
    <Box>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        sx={(theme) => ({
          gap: theme.spacing(2),
          typography: 'bodyXXLMedium',
        })}
      >
        {title}
        {handleEditModal && canEditEmployeeProfile && (
          <Box sx={{ display: 'flex' }} onMouseLeave={() => setEditMenuAnchorEl(null)}>
            <IconButton data-cy={`edit-${makeTestIdentifier(title)}`} onClick={(event) => onEditIconClick(event.currentTarget)}>
              <Pencil01Icon24 />
            </IconButton>
            {hasEditMenuButtons && (
              <Menu
                open={!!editMenuAnchorEl}
                id={`${id}edit-menu`}
                anchorEl={editMenuAnchorEl}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                transformOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                onClose={() => setEditMenuAnchorEl(null)}
                MenuListProps={{ 'aria-labelledby': `${id}postpone-button` }}
                sx={(theme) => ({
                  '& .MuiMenu-paper': {
                    border: `1px solid ${theme.palette.gray[100]}`,
                    borderRadius: '12px',
                  },
                  '& .MuiMenu-list': {
                    p: theme.spacing(1.5),
                  },
                  '& .MuiListItem-root': {
                    color: theme.palette.gray[400],
                    p: theme.spacing(1, 1.5),
                    typography: 'bodySMedium',
                  },
                  '& .MuiMenuItem-root': {
                    borderRadius: theme.spacing(1),
                    color: theme.palette.gray[900],
                    p: theme.spacing(1, 1.5),
                    typography: 'bodyMRegular',
                  },
                  '& .MuiMenuItem-root:hover': {
                    backgroundColor: theme.palette.gray[100],
                  },
                })}
              >
                {editMenuTitle && <ListItem>{editMenuTitle}</ListItem>}
                {editMenuButtons.map((item, index) => (
                  <MenuItem
                    key={index}
                    onClick={item.onClick}
                    data-cy={`edit-${makeTestIdentifier(title)}-${makeTestIdentifier(item.buttonText)}`}
                  >
                    {item.buttonText}
                  </MenuItem>
                ))}
              </Menu>
            )}
          </Box>
        )}
      </Box>
      {fieldDefs.map((row, rowIndex) => (
        <Grid2
          key={`${rowIndex}-row`}
          container
          spacing={5}
          sx={(theme) => ({
            my: theme.spacing(2),
            alignItems: 'flex-start',
          })}
        >
          {row.map((field, fieldIndex) => {
            const itemProps = row.length > 1 && !isPayroll ? { size: { xs: 8, md: 4 } } : {};
            return (
              <Grid2 size={{ xs: 4 }} key={`${fieldIndex}-field`} {...itemProps}>
                <Box display="flex" flexDirection="column" gap={1.5}>
                  {field.label && (
                    <Box
                      sx={(theme) => ({
                        color: theme.palette.gray[500],
                        typography: 'bodySMedium',
                      })}
                    >
                      {field.label}
                    </Box>
                  )}
                  {Array.isArray(field.field) ? (
                    <Box display="flex" flexWrap="wrap" flexDirection="column" gap={1}>
                      {field.field.map((field, index) => (
                        <Box key={`${index}-field`} display="flex" flexDirection="column" gap={1}>
                          {field.label && (
                            <Box
                              sx={(theme) => ({
                                color: theme.palette.gray[500],
                                typography: 'bodySMedium',
                              })}
                            >
                              {field.label}
                            </Box>
                          )}
                          {field.value && (
                            <Box display="flex">
                              {field.extra?.icon && field.extra.icon}
                              <InfoCardValue {...field} />
                            </Box>
                          )}
                        </Box>
                      ))}
                    </Box>
                  ) : field.field.extra?.custom ? (
                    field.field.extra.custom
                  ) : (
                    <Box display="flex" flexDirection="column" gap={1}>
                      {field.field.label && (
                        <Box
                          sx={(theme) => ({
                            color: theme.palette.gray[500],
                            typography: 'bodySMedium',
                          })}
                        >
                          {field.field.label}
                        </Box>
                      )}
                      {field.field.value && (
                        <Box
                          display="flex"
                          alignItems="center"
                          sx={(theme) => ({ minWidth: 'max-content', gap: theme.spacing(1), mr: theme.spacing(1) })}
                        >
                          {field.field.extra?.icon && field.field.extra.icon}
                          <InfoCardValue {...field.field} />
                        </Box>
                      )}
                    </Box>
                  )}
                </Box>
              </Grid2>
            );
          })}
        </Grid2>
      ))}
    </Box>
  );
};
