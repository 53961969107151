import { Box, Checkbox, Typography } from '@mui/material';
import React, { useState, useRef, useEffect } from 'react';
import { theme, useLoadedDepartmentInfoContext, RankBadge, useFlipAnimation, DragIcon } from '@stationwise/component-module';
import { HiringEngineSettingsInfo, Rank } from '@stationwise/share-types';
import { generateRankOrder } from '../../HiringEngine/AutoHire/utils';

interface HiringEngineCallOrdersProps {
  hiringEngineSettingsInfo: HiringEngineSettingsInfo;
  selectedVacancyRank: Rank;
  setHiringEngineSettingsInfo: React.Dispatch<React.SetStateAction<HiringEngineSettingsInfo | null>>;
  width?: string;
  dragIndicator?: boolean;
}

export const HiringEngineCallOrders: React.FC<HiringEngineCallOrdersProps> = ({
  hiringEngineSettingsInfo,
  selectedVacancyRank,
  setHiringEngineSettingsInfo,
  width,
  dragIndicator = false,
}) => {
  const {
    state: { departmentInfo },
  } = useLoadedDepartmentInfoContext();

  const [orders, setOrders] = useState<{ rankId: number; isSelected: boolean }[]>(
    hiringEngineSettingsInfo.hiringEngineDefaultRankCallOrders[selectedVacancyRank.id]
      ? hiringEngineSettingsInfo.hiringEngineDefaultRankCallOrders[selectedVacancyRank.id]
      : generateRankOrder(selectedVacancyRank.id, departmentInfo?.ranks || []),
  );
  const [allChecked, setAllChecked] = useState<boolean>(false);
  const dragItem = useRef<number | null>(null);
  const dragOverItem = useRef<number | null>(null);

  const { flipRef, updatePositions } = useFlipAnimation(
    orders.map((order) => order.rankId),
    300,
    'ease',
  );

  useEffect(() => {
    const newOrders =
      hiringEngineSettingsInfo.hiringEngineDefaultRankCallOrders[selectedVacancyRank.id] ||
      generateRankOrder(selectedVacancyRank.id, departmentInfo?.ranks || []);
    setOrders(newOrders);
    setAllChecked(newOrders.every((order) => order.isSelected));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedVacancyRank]);

  useEffect(() => {
    setAllChecked(orders.every((order) => order.isSelected));
  }, [orders]);

  const handleSort = () => {
    if (dragItem.current !== null && dragOverItem.current !== null) {
      const updatedOrders = [...orders];
      const draggedItemContent = updatedOrders.splice(dragItem.current, 1)[0];
      updatedOrders.splice(dragOverItem.current, 0, draggedItemContent);
      dragItem.current = null;
      dragOverItem.current = null;
      setOrders(updatedOrders);
      setHiringEngineSettingsInfo({
        ...hiringEngineSettingsInfo,
        hiringEngineDefaultRankCallOrders: {
          ...hiringEngineSettingsInfo.hiringEngineDefaultRankCallOrders,
          [selectedVacancyRank.id]: updatedOrders,
        },
      });
    }
  };

  const handleCheckboxToggle = (rankId: number) => {
    const updatedOrders = orders.map((order) => (order.rankId === rankId ? { ...order, isSelected: !order.isSelected } : order));
    setOrders(updatedOrders);
    setHiringEngineSettingsInfo({
      ...hiringEngineSettingsInfo,
      hiringEngineDefaultRankCallOrders: {
        ...hiringEngineSettingsInfo.hiringEngineDefaultRankCallOrders,
        [selectedVacancyRank.id]: updatedOrders,
      },
    });
  };

  const handleSelectAllToggle = () => {
    const newCheckedState = !allChecked;
    const updatedOrders = orders.map((order) => ({ ...order, isSelected: newCheckedState }));
    setAllChecked(newCheckedState);
    setOrders(updatedOrders);
    setHiringEngineSettingsInfo({
      ...hiringEngineSettingsInfo,
      hiringEngineDefaultRankCallOrders: {
        ...hiringEngineSettingsInfo.hiringEngineDefaultRankCallOrders,
        [selectedVacancyRank.id]: updatedOrders,
      },
    });
  };

  return (
    <Box sx={{ flex: 1, overflow: 'auto', pr: theme.spacing(1) }}>
      <Box sx={{ display: 'flex', alignItems: 'center', paddingY: theme.spacing(2) }}>
        <Typography sx={{ width: '20px', textAlign: 'center', color: theme.palette.gray[500] }}>#</Typography>
        <Checkbox sx={{ marginLeft: theme.spacing(3) }} checked={allChecked} onChange={handleSelectAllToggle} />
        <Typography>Select All</Typography>
      </Box>
      {orders.map(({ rankId, isSelected }, index) => {
        const rank = departmentInfo.ranks.find((r) => r.id === rankId);
        return rank ? (
          <Box key={rankId} ref={flipRef(rankId)} display="flex" flexDirection="row" alignItems="center">
            <Typography
              sx={{
                width: '20px',
                textAlign: 'center',
                mr: theme.spacing(1),
                color: theme.palette.gray[500],
              }}
            >
              {index + 1}
            </Typography>
            <Box
              draggable
              onDragStart={() => {
                updatePositions();
                dragItem.current = index;
              }}
              onDragEnter={() => (dragOverItem.current = index)}
              onDragEnd={handleSort}
              onDragOver={(e) => e.preventDefault()}
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                backgroundColor: theme.palette.common.white,
                paddingY: theme.spacing(1.5),
                paddingX: theme.spacing(2),
                border: '1px solid',
                borderColor: theme.palette.gray[200],
                opacity: 1,
                transition: 'all 0.2s ease-in-out',
                '&:hover': {
                  backgroundColor: theme.palette.gray[300],
                },
                width: width || '417px',
              }}
            >
              <Box display="flex" flexDirection="row" alignItems="center">
                <Checkbox
                  checked={isSelected}
                  indeterminate={false}
                  size="medium"
                  color="primary"
                  onChange={() => handleCheckboxToggle(rankId)}
                  sx={{ padding: theme.spacing(1) }}
                />
                <Box
                  sx={{
                    ml: theme.spacing(1),
                    display: 'flex',
                    alignItems: 'center',
                    gap: theme.spacing(3),
                  }}
                >
                  <RankBadge rank={rank} /> {rank.name}
                  <Typography sx={{ flex: 1, ml: theme.spacing(1.5), gap: theme.spacing(3.5) }}></Typography>
                </Box>
              </Box>

              <DragIcon dragIndicator={dragIndicator} />
            </Box>
          </Box>
        ) : null;
      })}
    </Box>
  );
};
