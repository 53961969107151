import { InputLabel as MUIInputLabel, InputLabelProps } from '@mui/material';

export const InputLabel = ({ children, sx, ...props }: InputLabelProps) => {
  return (
    <MUIInputLabel
      {...props}
      sx={[
        (theme) => ({
          color: theme.palette.gray[700],
          display: 'inline-flex',
          pb: 0.5,
          typography: 'bodySMedium',
        }),
        ...(Array.isArray(sx) ? sx : sx ? [sx] : []),
      ]}
    >
      {children}
    </MUIInputLabel>
  );
};
