import { Box, Grid } from '@mui/material';
import { format } from 'date-fns';
import { Dispatch, SetStateAction } from 'react';
import { Select } from '@stationwise/component-module';
import { StartTime } from '@stationwise/share-types';

export const StartTimes = ({
  originalStartDateTime,
  startDateTime,
  setStartDateTime,
  startOptions,
  setDuration,
}: {
  originalStartDateTime: Date;
  startDateTime: Date;
  setStartDateTime: Dispatch<SetStateAction<Date>>;
  startOptions: StartTime[];
  setDuration: Dispatch<SetStateAction<number>>;
}) => {
  const handleClick = (time: string) => {
    const originalStartDate = format(originalStartDateTime, 'yyyy-MM-dd');
    const newStartDateTime = new Date(`${originalStartDate}T${time}`);
    newStartDateTime < originalStartDateTime && newStartDateTime.setHours(newStartDateTime.getHours() + 24);
    setStartDateTime(newStartDateTime);
    setDuration(0);
  };

  return (
    <Box
      sx={(theme) => ({
        display: 'flex',
        alignItems: 'center',
        mt: theme.spacing(1),
      })}
    >
      <Grid container spacing={1}>
        <Grid item xs={6}>
          <Box>
            <Box
              sx={(theme) => ({
                display: 'flex',
                alignItems: 'center',
                width: '100%',
                border: `solid 1px ${theme.palette.gray[200]}`,
                p: theme.spacing(1, 1.5, 1, 1.5),
                borderRadius: '8px',
                color: theme.palette.gray[500],
                typography: 'bodyMRegular',
              })}
            >
              {format(startDateTime, 'MMM dd')}
            </Box>
          </Box>
        </Grid>
        <Grid item xs={6}>
          <Box>
            <Select items={startOptions} selected={format(startDateTime, 'HH:mm')} onChange={(item) => handleClick(item)} />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};
