import { Box, Theme, useTheme } from '@mui/material';
import { getCertColors, MinusIcon20, PlusIcon20 } from '@stationwise/component-module';
import { CertificationRequirement } from '@stationwise/share-types';

interface CertificationRequirementCounterProps {
  currentCertificationRequirement: CertificationRequirement;
  setCertRequirements: (certRequirements: CertificationRequirement[]) => void;
  certRequirements: CertificationRequirement[];
}

const amountControlsStyles = (theme: Theme) => {
  return {
    backgroundColor: theme.palette.gray[100],
    borderRadius: '50%',
    height: '20px',
    width: '20px',
    cursor: 'pointer',
  };
};

export const CertificationRequirementCounter = ({
  currentCertificationRequirement,
  setCertRequirements,
  certRequirements,
}: CertificationRequirementCounterProps) => {
  const theme = useTheme();
  const color = getCertColors({ theme, color: currentCertificationRequirement.color }).color;

  const updateAmount = (newAmount: number) => {
    setCertRequirements(
      certRequirements.map((requirement) =>
        requirement.id !== currentCertificationRequirement.id
          ? requirement
          : { ...requirement, amount: newAmount < 0 ? 0 : newAmount },
      ),
    );
  };

  return (
    <Box
      sx={(theme) => ({
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        gap: 1.5,
        p: theme.spacing(1),
        borderBottom: `1px solid ${theme.palette.gray[100]}`,
      })}
    >
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1.5 }}>
        <Box sx={(theme) => ({ typography: 'bodyMRegular', color: theme.palette.gray[500] })}>
          {currentCertificationRequirement.name}
        </Box>
        <Box sx={{ typography: 'bodyMRegular', color }}>{currentCertificationRequirement.code}</Box>
      </Box>
      <Box>
        <Box
          sx={(theme) => ({
            color: theme.palette.gray[400],
            display: 'flex',
            alignItems: 'center',
            '& svg path': { stroke: theme.palette.gray[400] },
          })}
        >
          <Box
            sx={(theme) => amountControlsStyles(theme)}
            onClick={() => updateAmount(currentCertificationRequirement.amount - 1)}
          >
            <MinusIcon20 />
          </Box>
          <Box
            sx={(theme) => ({
              color: currentCertificationRequirement.amount > 0 ? theme.palette.gray[900] : 'inherit',
              textAlign: 'center',
              width: '36px',
            })}
          >
            {currentCertificationRequirement.amount}
          </Box>
          <Box
            sx={(theme) => amountControlsStyles(theme)}
            onClick={() => updateAmount(currentCertificationRequirement.amount + 1)}
          >
            <PlusIcon20 />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
