import { MingCuteMagicIcon24, ElectricBoltIcon24, PersonRaiseHandIcon, FlowChartIcon24 } from '../../assets';

export type HiringEngineIconType = 'flow' | 'autoHire' | 'instantHire' | 'requestVolunteers';

export const HIRING_ENGINE_ICONS = {
  flow: FlowChartIcon24,
  autoHire: MingCuteMagicIcon24,
  instantHire: ElectricBoltIcon24,
  requestVolunteers: PersonRaiseHandIcon,
} as const;
