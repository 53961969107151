import { Box, Theme, useTheme } from '@mui/material';
import { Dispatch, SetStateAction, useState } from 'react';
import { ChevronDownIcon20, getRequestTypeIcon } from '@stationwise/component-module';
import { PayCode, TimeOffLimit } from '@stationwise/share-types';
import { TimeOffTypeSelector } from './TimeOffTypeSelector';

interface TimeOffLimitSelectProps {
  timeOffLimits: TimeOffLimit[];
  selectedTimeOffLimit: TimeOffLimit | undefined;
  setSelectedTimeOffLimit: Dispatch<SetStateAction<TimeOffLimit | undefined>>;
  isAccrualsEnabled: boolean;
  selectedPayCode: PayCode | undefined;
  setSelectedPayCode: Dispatch<SetStateAction<PayCode | undefined>>;
  payCodes: PayCode[];
}

export const getTimeOffIcon = (timeOffLimit: TimeOffLimit, theme: Theme) => {
  return getRequestTypeIcon(
    {
      requestType: 'TimeOffRequest',
      timeOffType: timeOffLimit.accrualBank,
    },
    theme,
  ).icon;
};

export const TimeOffLimitSelect = ({
  timeOffLimits,
  selectedTimeOffLimit,
  setSelectedTimeOffLimit,
  isAccrualsEnabled,
  selectedPayCode,
  setSelectedPayCode,
  payCodes,
}: TimeOffLimitSelectProps) => {
  const theme = useTheme();
  const [selectionDrawerOpen, setSelectionDrawerOpen] = useState<boolean>(false);
  const handleSelectionDrawerClose = () => {
    setSelectionDrawerOpen(false);
  };

  return (
    <>
      <Box>
        <Box
          sx={(theme) => ({
            color: theme.palette.gray[900],
            typography: 'bodyLMedium',
          })}
        >
          Time off type
        </Box>
        <Box
          data-cy="time-off-type-select"
          sx={(theme) => ({
            display: 'flex',
            alignItems: 'center',
            width: '100%',
            border: `1px solid ${theme.palette.gray[200]}`,
            borderRadius: '8px',
            p: theme.spacing(1),
            mt: theme.spacing(1),
            justifyContent: 'space-between',
          })}
          onClick={() => setSelectionDrawerOpen(true)}
        >
          {!selectedTimeOffLimit && (
            <Box sx={(theme) => ({ typography: 'bodyMRegular', color: theme.palette.gray[500] })}>
              Please select a type of Time Off
            </Box>
          )}
          {selectedTimeOffLimit && selectedPayCode && (
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <Box
                sx={(theme) => ({
                  display: 'flex',
                  '& svg': {
                    '& path': {
                      stroke: theme.palette.gray[100],
                    },
                  },
                  backgroundColor: theme.palette.gray[400],
                  borderRadius: '50%',
                  p: theme.spacing(0.5),
                })}
              >
                {getTimeOffIcon(selectedTimeOffLimit, theme)}
              </Box>
              <Box
                sx={(theme) => ({
                  ml: theme.spacing(1),
                  typography: 'bodyMRegular',
                })}
              >
                {selectedPayCode.name}
              </Box>
            </Box>
          )}
          <Box
            sx={(theme) => ({
              display: 'flex',
              alignItems: 'center',
              mr: theme.spacing(1),
              color: theme.palette.gray[400],
            })}
          >
            <ChevronDownIcon20 />
          </Box>
        </Box>
        {selectedTimeOffLimit && selectedPayCode && !selectedTimeOffLimit.isUnlimited && isAccrualsEnabled && (
          <Box
            sx={(theme) => ({
              color: theme.palette.gray[500],
              mt: theme.spacing(1),
              typography: 'bodyMRegular',
            })}
          >
            {(selectedTimeOffLimit.accruedTimeOff - selectedTimeOffLimit.pending).toFixed(2)} {selectedPayCode.name.toLowerCase()}{' '}
            hours remaining
          </Box>
        )}
      </Box>
      <TimeOffTypeSelector
        handleOnClose={handleSelectionDrawerClose}
        drawerOpen={selectionDrawerOpen}
        options={timeOffLimits}
        payCodeOptions={payCodes}
        setSelectedTimeOffLimit={setSelectedTimeOffLimit}
        setSelectedPayCode={setSelectedPayCode}
        isAccrualsEnabled={isAccrualsEnabled}
      />
    </>
  );
};
