import { Box, Theme, useMediaQuery } from '@mui/material';
import { useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { ToggleButton, ToggleButtonGroup } from '@stationwise/component-module';
import { makeTestIdentifier } from '@stationwise/share-utils';

export const CalendarDisplayOptions = ({ calendarOptions }: { calendarOptions: string[] }) => {
  const [selectedOptionIndex, setSelectedOptionIndex] = useState<number>();
  const [searchParams, setSearchParams] = useSearchParams();
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

  useEffect(() => {
    const display = searchParams.get('display');

    if (!display) {
      setSelectedOptionIndex(0);
    } else {
      const selectedIndex = calendarOptions.findIndex((option) => option.toLowerCase() === display);
      setSelectedOptionIndex(selectedIndex);
    }
  }, [searchParams, calendarOptions]);

  const handleSelectOption = (index: number) => {
    setSelectedOptionIndex(index);
    setSearchParams((prevSearchParams) => {
      const newSearchParams = new URLSearchParams(prevSearchParams);
      newSearchParams.set('display', calendarOptions[index].toLowerCase());
      return newSearchParams;
    });
  };

  if (isMobile) {
    return (
      <>
        {calendarOptions.map((displayOption, index) => (
          <Box
            data-cy={`calendar-display-option-${makeTestIdentifier(displayOption)}`}
            onClick={() => handleSelectOption(index)}
            key={displayOption}
            display="flex"
            justifyContent="center"
            alignItems="center"
            sx={(theme) => ({
              width: '40px',
              height: '40px',
              borderRadius: '8px',
              pt: '4px',
              color: index === selectedOptionIndex ? theme.palette.gray[900] : theme.palette.gray[400],
              typography: 'bodySMedium',
              fontWeight: index === selectedOptionIndex ? '600' : '500',
            })}
          >
            {displayOption[0]}
          </Box>
        ))}
      </>
    );
  }

  return (
    <ToggleButtonGroup
      exclusive
      value={selectedOptionIndex}
      onChange={(_event, newValue) => newValue !== null && handleSelectOption(newValue)}
      aria-label="Calendar display options"
      sx={(theme) => ({
        '& .MuiToggleButton-root': {
          px: theme.spacing(2),
          py: theme.spacing(1),
          typography: 'bodySMedium',
          width: '70px',
        },
        '& .MuiToggleButton-root.Mui-selected': {
          color: theme.palette.gray[900],
        },
      })}
    >
      {calendarOptions.map((displayOption, index) => (
        <ToggleButton key={displayOption} value={index} data-cy={`calendar-display-option-${makeTestIdentifier(displayOption)}`}>
          {displayOption[0]}
        </ToggleButton>
      ))}
    </ToggleButtonGroup>
  );
};
