import { Box } from '@mui/material';
import { format, parseISO } from 'date-fns';
import { CalendarIcon16 } from '@stationwise/component-module';
import { EmployeeTimeOff } from '@stationwise/share-types';

export const TimeOffList = ({ title, timeOffs }: { title: string; timeOffs: EmployeeTimeOff[] }) => (
  <Box sx={(theme) => ({ my: theme.spacing(2) })}>
    <Box sx={(theme) => ({ typography: 'bodySRegular', color: theme.palette.gray[500] })}>{title}</Box>
    {timeOffs.length === 0 && (
      <Box
        sx={(theme) => ({
          pt: theme.spacing(2),
          typography: 'bodySMedium',
          color: theme.palette.gray[500],
        })}
      >
        No time off
      </Box>
    )}
    {timeOffs?.map((timeOff) => (
      <Box
        key={timeOff.id}
        sx={(theme) => ({
          mt: theme.spacing(1),
          display: 'flex',
          justifyContent: 'space-between',
        })}
      >
        <Box sx={{ display: 'flex' }}>
          <Box
            sx={(theme) => ({
              mr: theme.spacing(1),
              display: 'flex',
              alignItems: 'center',
              '& svg': {
                '& path': {
                  stroke: theme.palette.gray[400],
                },
              },
            })}
          >
            <CalendarIcon16 />
          </Box>

          <Box>{format(parseISO(timeOff.startDate), 'MMM d, yyyy')}</Box>
        </Box>
        <Box
          sx={(theme) => ({
            color: theme.palette.gray[500],
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            display: 'block',
            maxWidth: '108px',
          })}
        >
          {timeOff?.payCode?.name}
        </Box>
      </Box>
    ))}
  </Box>
);
