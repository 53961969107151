import { ReactComponent as CancelOutlinedSymbol } from '@material-symbols/svg-400/outlined/cancel.svg';
import { ReactComponent as GroupAddOutlinedSymbol } from '@material-symbols/svg-400/outlined/group_add.svg';
import { Box } from '@mui/material';
import { SvgIcon } from '@stationwise/component-module';

interface SubMenuProps {
  sendFollowUp?: (id: number) => void;
  conversationId: number;
  onCancelConversation?: (id: number) => void;
}

export const SubMenu = ({ sendFollowUp, conversationId, onCancelConversation }: SubMenuProps) => {
  const menuItems = [
    {
      label: 'Send follow-up',
      icon: GroupAddOutlinedSymbol,
      action: () => {
        if (sendFollowUp) {
          sendFollowUp(conversationId);
        }
      },
    },
    {
      label: 'Cancel message',
      icon: CancelOutlinedSymbol,
      action: () => {
        if (onCancelConversation) {
          onCancelConversation(conversationId);
        }
      },
    },
  ];

  return (
    <Box
      sx={(theme) => ({
        padding: 1.5,
        borderRadius: 3,
        border: `1px solid ${theme.palette.gray[100]}`,
        backgroundColor: theme.palette.common.white,
        boxShadow: '0px 4px 6px -2px rgba(10, 14, 22, 0.05), 0px 10px 15px -3px rgba(10, 14, 22, 0.10);',
      })}
    >
      {menuItems.map((item, index) => {
        const Icon = item.icon;
        return (
          <Box
            key={index}
            onClick={item.action}
            sx={(theme) => ({
              // actual width is 256
              // but it is better to keep it 250px
              // to avoid horizontal scroll
              // on mobil devices in the feature
              width: '250px',
              padding: '8px 12px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-start',
              gap: 1.5,
              borderRadius: theme.spacing(1),
              color: theme.palette.gray[900],
              typography: 'bodyMRegular',
              '&:hover': {
                backgroundColor: theme.palette.gray[100],
                cursor: 'pointer',
              },
            })}
          >
            <SvgIcon component={Icon} height="16px" width="16px" sx={(theme) => ({ color: theme.palette.gray[400] })} />
            {item.label}
          </Box>
        );
      })}
    </Box>
  );
};
