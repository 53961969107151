import { ReactComponent as RadioButtonUncheckedSymbol } from '@material-symbols/svg-400/outlined/radio_button_unchecked.svg';
import CircleCheckedFilled from '@mui/icons-material/CheckCircle';
import { Box, Checkbox, useTheme } from '@mui/material';
import { SvgIcon } from '@stationwise/component-module';

interface PatternDayCheckProps {
  dayNumber: number;
  checked: boolean;
  onToggleDay: (day: number, checked: boolean) => void;
  totalDays: number;
  color: string;
  disabled: boolean;
}

export const PatternDayCheck = ({ dayNumber, checked, onToggleDay, totalDays, color, disabled }: PatternDayCheckProps) => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        border: `0.5px solid ${theme.palette.gray[300]}`,
        borderLeft: dayNumber === 1 ? `0.5px solid ${theme.palette.gray[300]}` : 'none',
        width: `${100 / totalDays}%`,
      }}
    >
      <Box
        sx={{
          typography: 'bodyXSRegular',
          color: theme.palette.gray[500],
          textAlign: 'center',
          borderBottom: `0.5px solid ${theme.palette.gray[300]}`,
          py: 1,
          px: 1,
        }}
      >
        Day
        <br />
        {`${dayNumber}`}
      </Box>
      <Box sx={{ textAlign: 'center', backgroundColor: checked ? color : 'inherit' }}>
        <Checkbox
          checked={checked}
          disabled={disabled}
          onChange={() => onToggleDay(dayNumber, !checked)}
          icon={
            <SvgIcon
              component={RadioButtonUncheckedSymbol}
              sx={{
                color: theme.palette.gray[400],
                stroke: theme.palette.common.white,
              }}
            />
          }
          checkedIcon={
            <SvgIcon
              component={CircleCheckedFilled}
              sx={{
                color: theme.palette.common.white,
                '&.Mui-checked': {
                  color: theme.palette.common.white,
                },
              }}
            />
          }
        />
      </Box>
    </Box>
  );
};
