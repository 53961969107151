import { Box, Typography } from '@mui/material';
import { InboxGif } from '@stationwise/component-module';

export const MessagesInboxAlert = ({ messages }: { messages: number }) => {
  return (
    <>
      <Box sx={{ textAlign: 'center', mt: '35px' }}>
        <img src={InboxGif} alt="loading..." height="160" width="160" />
      </Box>
      <Box
        sx={(theme) => ({ textAlign: 'center', mt: theme.spacing(5) })}
        display="flex"
        alignItems="center"
        justifyContent="center"
        gap="8px"
      >
        <Typography variant="heading4">You have</Typography>
        <Box
          data-testid="unread-messages-count"
          sx={(theme) => ({
            color: theme.palette.common.black,
            backgroundColor: theme.palette.electric[400],
            borderRadius: '20px',
            width: '32px',
            height: '32px',
            padding: '2px, 6px, 2px, 6px',
          })}
        >
          <Typography variant="bodyXLSemibold">{messages}</Typography>
        </Box>
        <Typography variant="heading4">new</Typography>
      </Box>
      <Box sx={{ textAlign: 'center' }}>
        <Typography variant="heading4">{messages > 1 ? 'messages' : 'message'}</Typography>
      </Box>
      <Box
        sx={(theme) => ({
          textAlign: 'center',
          color: theme.palette.gray[500],
          mt: theme.spacing(2),
          mb: '35px',
        })}
      >
        <Typography variant="bodyLRegular" component="div">
          Please read your messages first <br /> before proceeding to your dashboard
        </Typography>
      </Box>
    </>
  );
};
