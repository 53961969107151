import { useCallback, useEffect, useLayoutEffect, useRef } from 'react';
import { RosterStation, VacantStation, VacantApparatus, Vacancy } from '@stationwise/share-types';
import { checkIsShift, getVacancies } from '@stationwise/shift-summary-helper';

export const getVacantStations = (stations: RosterStation[]) => {
  const vacantStations: VacantStation[] = [];
  stations.forEach((station) => {
    if (!checkIsShift(station)) {
      return;
    }

    const vacantApparatuses: VacantApparatus[] = [];
    station.apparatuses.forEach((apparatus) => {
      const vacancies: Vacancy[] = [];
      apparatus.positions.forEach((position) => {
        vacancies.push(...getVacancies(position));
      });

      if (vacancies.length > 0) {
        vacancies.sort((a, b) => a.rank.sortOrder - b.rank.sortOrder);
        vacantApparatuses.push({ ...apparatus, vacancies });
      }
    });

    if (vacantApparatuses.length > 0) {
      vacantStations.push({ ...station, apparatuses: vacantApparatuses });
    }
  });

  return vacantStations;
};

export function useFlipAnimation<T>(
  items: T[],
  duration = 300,
  easing = 'ease',
): {
  flipRef: (item: T) => (node: HTMLElement | null) => void;
  updatePositions: () => void;
} {
  const itemRefs = useRef<Record<string, HTMLElement | null>>({});
  const positions = useRef<Record<string, number>>({});
  const isInitialMount = useRef(true);

  const updatePositions = useCallback(() => {
    Object.keys(itemRefs.current).forEach((key) => {
      const node = itemRefs.current[key];
      if (node) {
        positions.current[key] = node.getBoundingClientRect().top;
      }
    });
  }, []);

  useLayoutEffect(() => {
    const currentKeys = new Set(items.map(String));
    Object.keys(itemRefs.current).forEach((key) => {
      if (!currentKeys.has(key)) {
        const node = itemRefs.current[key];
        if (node) {
          node.style.transition = '';
          node.style.transform = '';
        }
        delete itemRefs.current[key];
        delete positions.current[key];
      }
    });
  }, [items]);

  useLayoutEffect(() => {
    if (isInitialMount.current) {
      items.forEach((item) => {
        const key = String(item);
        const node = itemRefs.current[key];
        if (node) {
          positions.current[key] = node.getBoundingClientRect().top;
        }
      });
      isInitialMount.current = false;
      return;
    }

    let deltas: number[] = [];
    items.forEach((item) => {
      const key = String(item);
      const node = itemRefs.current[key];
      if (node && positions.current[key] !== undefined) {
        const newTop = node.getBoundingClientRect().top;
        const delta = positions.current[key] - newTop;
        deltas.push(delta);
      }
    });

    const skipAnimation = deltas.length > 0 && deltas.every((d) => Math.abs(d - deltas[0]) < 1);

    items.forEach((item) => {
      const key = String(item);
      const node = itemRefs.current[key];
      if (node) {
        const newTop = node.getBoundingClientRect().top;
        const prevTop = positions.current[key];
        if (prevTop !== undefined && !skipAnimation) {
          const delta = prevTop - newTop;
          if (delta !== 0) {
            node.style.transition = 'none';
            node.style.transform = `translateY(${delta}px)`;

            void node.offsetHeight;

            requestAnimationFrame(() => {
              node.style.transition = `transform ${duration}ms ${easing}`;
              node.style.transform = '';

              const handleTransitionEnd = () => {
                node.style.transition = '';
                node.removeEventListener('transitionend', handleTransitionEnd);
              };
              node.addEventListener('transitionend', handleTransitionEnd);
            });
          }
        }
        positions.current[key] = newTop;
      }
    });
  }, [items, duration, easing]);

  const setRef = useCallback(
    (item: T) => (node: HTMLElement | null) => {
      itemRefs.current[String(item)] = node;
    },
    [],
  );

  useEffect(() => {
    const currentItemNodes = Object.values(itemRefs.current);
    return () => {
      currentItemNodes.forEach((node) => {
        if (node) {
          node.style.transition = '';
          node.style.transform = '';
        }
      });
    };
  }, []);

  return { flipRef: setRef, updatePositions };
}
