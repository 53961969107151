import { Box, useMediaQuery, useTheme } from '@mui/material';
import Paper from '@mui/material/Paper';
import { useState, useEffect, useMemo, memo } from 'react';
import { ConversationRecipient, Team, WorkGroup } from '@stationwise/share-types';
import {
  SelectRecipients,
  SelectContactOptionProps,
  SHIFTS,
  WORK_GROUPS,
  ALL_CONTACTS,
  ISelectedRecipients,
} from '../../../components';
import { useGetRecipientOptions } from '../../../hooks';
import { Recipient, IRecipientUI } from './Recipient';
import { RecipientGroups } from './RecipientGroups';

interface ToProps {
  isEditable: boolean;
  setRecipients: (selection: ISelectedRecipients) => void;
  recipients: ISelectedRecipients;
  resetRecipients: boolean;
  conversationRecipients?: ConversationRecipient[];
  conversationWorkGroups?: WorkGroup[];
  conversationTeams?: Team[];
  rosterMessage?: boolean;
}

export const To = memo(
  ({
    isEditable,
    setRecipients,
    recipients,
    resetRecipients,
    conversationRecipients,
    conversationWorkGroups,
    conversationTeams,
    rosterMessage,
  }: ToProps) => {
    const [selectedRecipients, setSelectedRecipients] = useState<IRecipientUI[]>([
      { id: -1, name: 'All working employees', chipLabel: '', icon: null },
    ]);

    const { data } = useGetRecipientOptions();
    const recipientOptions = useMemo(() => {
      const result = {
        [ALL_CONTACTS]: [] as SelectContactOptionProps[],
        [WORK_GROUPS]: [] as SelectContactOptionProps[],
        [SHIFTS]: [] as SelectContactOptionProps[],
      };
      if (data.employees) {
        result[ALL_CONTACTS] = data.employees.map((recipient) => {
          return {
            id: [recipient.id],
            category: ALL_CONTACTS,
            chipBackgroundColor: recipient?.team?.color || 'gray',
            chipLabel: recipient?.team?.name || '',
            name: recipient.fullName,
            email: recipient.email,
            userImg: recipient.avatar || '',
          };
        });
      }
      if (data.teams) {
        result[SHIFTS] = data.teams.map((team) => {
          return {
            id: [team.id],
            category: SHIFTS,
            name: team.name,
            email: '',
            chipBackgroundColor: team.color,
            chipLabel: '',
            filter: SHIFTS,
            count: team.count,
          };
        });
      }
      if (data.workGroups) {
        result[WORK_GROUPS] = data.workGroups.map((workGroup) => {
          return {
            id: [workGroup.id],
            category: WORK_GROUPS,
            name: workGroup.name,
            email: '',
            chipBackgroundColor: workGroup.color,
            chipLabel: '',
            filter: WORK_GROUPS,
            count: workGroup.count,
          };
        });
      }
      return result;
    }, [data]);

    useEffect(() => {
      if (conversationRecipients) {
        const mappedRecipients: IRecipientUI[] = conversationRecipients.map((recipient) => {
          return {
            id: recipient.id,
            name: recipient.employee.firstName + ' ' + recipient.employee.lastName,
            memberIds: [recipient.id],
            userImg: recipient.employee.image || '',
            chipBgColor: recipient.employee.rank.color || 'red',
            chipLabel: '',
            inGroup: recipient.inGroup,
            icon: (() => {
              return null;
            })(),
          };
        });
        setSelectedRecipients(mappedRecipients);
      }
    }, [conversationRecipients]);

    useEffect(() => {
      if (rosterMessage) setSelectedRecipients([{ id: -1, name: 'All working employees', chipLabel: '', icon: null }]);
      else {
        setSelectedRecipients([]);
      }
    }, [resetRecipients, rosterMessage]);

    const handleEditRecipients = (selection: ISelectedRecipients) => {
      //filters individual recipients that are not present in any selected group/shift

      //memberIds are empty for all not sure whether we want to add them since it is not leading to duplicates anyway
      const individuals = selection[ALL_CONTACTS]?.filter(
        (individual) =>
          !selection[WORK_GROUPS]?.some((group) => group.memberIds?.includes(individual.id)) &&
          !selection[SHIFTS]?.some((group) => group.memberIds?.includes(individual.id)),
      );

      //filters only recipients that were not previously added
      const filteredRecipients = (selection[SHIFTS] || []).concat(selection[WORK_GROUPS] || []).concat(individuals || []);

      setSelectedRecipients(filteredRecipients);

      //calls server to send message
      setRecipients(selection);
    };

    const handleRemoveRecipient = (category: string | undefined, id: number) => {
      const recipientToRemove = selectedRecipients.find((recipient) => recipient.id === id && recipient.category === category);
      if (recipientToRemove?.checked && recipientToRemove?.onClick) {
        recipientToRemove.checked = false;

        //calls remove item function from contact component
        recipientToRemove?.onClick();

        // Remove recipient from the sending message
        if (category && recipients) {
          const updatedCategoryRecipients = recipients[category]?.filter((recipient) => recipient.id !== id);
          const updatedRecipients = {
            ...recipients,
            [category]: updatedCategoryRecipients || [],
          };
          setRecipients(updatedRecipients);
        }
      }

      const newRecipients = selectedRecipients.filter((recipient) => recipient.id !== id || recipient.category !== category);
      setSelectedRecipients(newRecipients);
    };

    const recipientHeight = 48 * Math.min(4.5, Math.ceil(selectedRecipients.length / 3));
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

    return (
      <Box sx={{ display: 'flex', alignItems: 'flex-start' }}>
        <SelectRecipients
          reset={resetRecipients}
          data={recipientOptions}
          isEditable={isEditable}
          editRecipients={handleEditRecipients}
        />
        <Box
          sx={(theme) => ({
            height: `${recipientHeight}px`,
            width: '100%',
            overflowY: 'scroll',
            maxHeight: isSmallScreen ? theme.spacing(15) : `${recipientHeight}px`,
          })}
        >
          <Paper
            sx={(theme) => ({
              display: 'grid',
              gridTemplateColumns: 'repeat(auto-fill, minmax(170px, 1fr))',
              justifyContent: 'flex-start',
              gridGap: theme.spacing(1),
              listStyle: 'none',
              p: 0.2,
              m: 0,
              boxShadow: 'none',
              border: 'none',
              ml: theme.spacing(1),
              mt: '-2px',
            })}
            component="ul"
          >
            {selectedRecipients
              .filter((recipient) => !recipient.inGroup)
              .map((recipient) => {
                return (
                  <Recipient
                    key={`${recipient.category}-${recipient.id}`}
                    removeRecipient={handleRemoveRecipient}
                    recipient={recipient}
                    isEditable={isEditable}
                  />
                );
              })}
            {conversationWorkGroups?.map((wg) => {
              return <RecipientGroups key={wg.id} workGroup={wg} />;
            })}
            {conversationTeams?.map((t) => {
              return <RecipientGroups key={t.id} team={t} />;
            })}
          </Paper>
        </Box>
      </Box>
    );
  },
);

To.displayName = 'Memo(To)';
