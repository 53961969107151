import { Box, useTheme } from '@mui/material';
import { DetailsPopover } from '../Card';

export const FieldDetails = ({ fields }: { fields: { label: string; value: string }[] }) => {
  const theme = useTheme();

  return (
    <DetailsPopover
      anchorContent={
        <Box
          sx={{
            color: theme.palette.gray[900],
            typography: 'bodySRegular',
            mt: 0.5,
            mr: 1,
            width: '100%',
            ml: '50%',
            pl: 2.5,
            cursor: 'default',
          }}
        >
          More...
        </Box>
      }
      popoverContent={
        <Box
          sx={{
            border: `1px solid ${theme.palette.gray[400]}`,
            borderRadius: '8px',
            p: 2,
            py: 1,
            minWidth: '400px',
          }}
        >
          {fields.map((field, index) => (
            <Box
              key={`popover-${field.label}`}
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                gap: 1.5,
                width: '100%',
                borderBottom: index !== fields.length - 1 ? `1px solid ${theme.palette.gray[200]}` : 'none',
                pb: 1,
                py: 1,
              }}
            >
              <Box
                sx={{
                  color: theme.palette.gray[400],
                  whiteSpace: 'nowrap',
                  typography: 'bodySRegular',
                  mt: 0.5,
                  mr: 1,
                  minWidth: '50%',
                }}
              >
                {field.label}
              </Box>
              <Box
                sx={{
                  color: theme.palette.gray[900],
                  typography: 'bodySRegular',
                  mt: '5px',
                  minWidth: '50%',
                }}
              >
                {field.value}
              </Box>
            </Box>
          ))}
        </Box>
      }
    />
  );
};
