import { Box, Divider } from '@mui/material';
import { useState } from 'react';
import { Button } from '@stationwise/component-module';
import { EmployeeTimeOffs } from '@stationwise/share-types';
import { TimeOffList } from './TimeOffList';
import { TimeOffModal } from './TimeOffModal';

export const TimeOffSection = ({ timeOffs }: { timeOffs: EmployeeTimeOffs }) => {
  const [showModal, setShowModal] = useState(false);

  return (
    <Box
      sx={(theme) => ({
        backgroundColor: theme.palette.common.white,
        borderRadius: 2,
        border: `1px solid ${theme.palette.gray[100]}`,
        p: theme.spacing(2),
        gap: theme.spacing(3),
        width: '100%',
      })}
    >
      <TimeOffModal timeOffs={timeOffs} showModal={showModal} setShowModal={setShowModal} />
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Box sx={{ typography: 'heading6' }}>Time offs</Box>
        <Button onClick={() => setShowModal(!showModal)} data-cy="see-details-time-offs">
          See Details
        </Button>
      </Box>
      <TimeOffList title="Upcoming" timeOffs={timeOffs?.futureTimeOffs} />
      <Divider />
      <TimeOffList title="Past" timeOffs={timeOffs?.pastTimeOffs} />
    </Box>
  );
};
