import { ReactComponent as MoreHorizFillSymbol } from '@material-symbols/svg-400/outlined/more_horiz.svg';
import { Box, Skeleton, Popper, ClickAwayListener } from '@mui/material';
import React, { useState } from 'react';
import { SvgIcon, TeamFlag } from '@stationwise/component-module';
import { AVATAR_SIZE } from '../constants';
import { SubMenu } from './SubMenu';

export interface HeaderProps {
  personName: string;
  personRole: string;
  color: string;
  isLoading?: boolean;
  conversationId: number;
  sendFollowUp?: (id: number) => void;
  onCancelConversation?: (id: number) => void;
  isCancelled?: boolean;
}

export const Header = ({
  personName,
  personRole,
  color,
  isCancelled,
  isLoading,
  onCancelConversation,
  sendFollowUp,
  conversationId,
}: HeaderProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const handleClickAway = () => {
    setAnchorEl(null);
  };

  if (isLoading) {
    return (
      <Box display="flex" gap={0.5}>
        <Skeleton variant="circular" width={AVATAR_SIZE} height={AVATAR_SIZE} />
        <Skeleton variant="text" width={100} height={20} />
        <Skeleton variant="text" width={100} height={20} />
      </Box>
    );
  }

  return (
    <>
      {!isCancelled && (
        <ClickAwayListener onClickAway={handleClickAway}>
          <Popper id={'message-popper'} open={Boolean(anchorEl)} anchorEl={anchorEl}>
            <SubMenu sendFollowUp={sendFollowUp} conversationId={conversationId} onCancelConversation={onCancelConversation} />
          </Popper>
        </ClickAwayListener>
      )}
      <Box display="flex" justifyContent="space-between">
        <Box display="flex" gap={0.5} alignItems="center">
          <TeamFlag color={color} sx={(theme) => ({ background: theme.palette.common.white, width: '20px', height: '20px' })} />
          <Box sx={(theme) => ({ color: theme.palette.gray[900], typography: 'bodySMedium' })}>{personName}</Box>
          <Box sx={(theme) => ({ color: theme.palette.gray[400], typography: 'bodySRegular' })}>{personRole}</Box>
        </Box>
        <Box
          sx={(theme) => ({
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            gap: theme.spacing(2),
          })}
        >
          {isCancelled && (
            <Box
              sx={(theme) => ({
                color: theme.palette.common.white,
                background: theme.palette.gray[400],
                padding: '2px 8px',
                borderRadius: '16px',
                typography: 'tagline1',
              })}
            >
              CANCELLED
            </Box>
          )}
          {!isCancelled && (
            <Box
              className="message-header-more-icon"
              sx={(theme) => ({
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                width: '20px',
                height: '20px',
                borderRadius: '8px',
                padding: '2px',
                backgroundColor: theme.palette.gray[200],
                visibility: 'hidden',
              })}
              onClick={handleClick}
            >
              <SvgIcon
                component={MoreHorizFillSymbol}
                sx={{
                  width: '14px',
                  height: '14px',
                  stroke: 'currentColor',
                  strokeWidth: 70,
                }}
              />
            </Box>
          )}
        </Box>
      </Box>
    </>
  );
};
