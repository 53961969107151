import { format, parse, isValid } from 'date-fns';
import { useEffect, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';

export const useDateQueryParam = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const dateParam = searchParams.get('date');

  const selectedDateAsDate = useMemo(() => {
    const date = dateParam ? parse(dateParam, 'yyyy-MM-dd', new Date()) : new Date();
    return isValid(date) ? date : new Date();
  }, [dateParam]);

  const selectedDate = format(selectedDateAsDate, 'MM/dd/yyyy');

  useEffect(() => {
    const newDateParam = format(selectedDateAsDate, 'yyyy-MM-dd');
    if (dateParam !== newDateParam) {
      setSearchParams((prevSearchParams) => {
        const newSearchParams = new URLSearchParams(prevSearchParams);
        newSearchParams.set('date', newDateParam);
        return newSearchParams;
      });
    }
  }, [dateParam, selectedDateAsDate, setSearchParams]);

  const onDateChange = (date: Date | null) => {
    if (date && isValid(date)) {
      setSearchParams((prevSearchParams) => {
        const newSearchParams = new URLSearchParams(prevSearchParams);
        newSearchParams.set('date', format(date, 'yyyy-MM-dd'));
        return newSearchParams;
      });
    }
  };

  return {
    selectedDate,
    selectedDateAsDate,
    onDateChange,
  };
};
