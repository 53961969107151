import { Box, Divider, ListItem, Menu, MenuItem, Tooltip, Typography, useMediaQuery, Theme } from '@mui/material';
import { addDays, format, isAfter, isToday, isTomorrow, parse, startOfDay } from 'date-fns';
import { MouseEvent, ReactElement, cloneElement, useId, useState } from 'react';
import { EmployeeNextShiftView } from '@stationwise/share-types';
import {
  ChevronDownIcon16 as ChevronDown,
  ClockFastForwardIcon16,
  ClockFastForwardIcon24,
  ClockIcon16,
  ExclamationMarkCircleIcon16,
} from '../../../assets';
import { Button } from '../../Button';
import { GenericDrawerOrModal } from '../../GenericDrawerOrModal';
import { theme } from '../../ThemeProvider';
import { RequestCardRequest } from './types';

const formatExpiresAt = (date: Date): string => {
  const time = format(date, 'HHmm');
  if (isToday(date)) {
    return `Today ${time}`;
  } else if (isTomorrow(date)) {
    return `Tomorrow ${time}`;
  }
  return format(date, 'yyyy-MM-dd HHmm');
};

interface ActionButtonsProps {
  handleApprove: () => void;
  handleDeny: () => void;
  handlePostpone: (value: Date | null) => void;
  request: RequestCardRequest;
  nextShiftData: EmployeeNextShiftView | null;
}

export const ActionButtons = ({ handleApprove, handleDeny, handlePostpone, request, nextShiftData }: ActionButtonsProps) => {
  const id = useId();

  const expiresAt = new Date(request.expiresAt);
  const canApprove = !isAfter(new Date(), expiresAt);
  const canPostpone = !isAfter(new Date(), startOfDay(addDays(expiresAt, -1)));

  const postponedUntil = request.postponedUntil ? new Date(request.postponedUntil) : null;
  const isPostponed = !!postponedUntil;
  const isOverdue = isPostponed && new Date() > postponedUntil;

  const nextShift = nextShiftData?.employee?.nextShift || null;
  const nextShiftDate = nextShift && parse(`${nextShift.startDate} ${nextShift.startTime}`, 'MM/dd/yyyy HH:mm', new Date());

  const [postponeAnchorEl, setPostponeAnchorEl] = useState<HTMLElement | null>(null);

  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

  const openPostponeMenu = (event: MouseEvent<HTMLButtonElement>) => {
    setPostponeAnchorEl(event.currentTarget);
  };
  const closePostponeMenu = () => setPostponeAnchorEl(null);
  const postponeUntilNextShift = () => {
    handlePostpone(nextShiftDate);
    closePostponeMenu();
  };
  const postponeUntilTomorrow = () => {
    handlePostpone(addDays(new Date(), 1));
    closePostponeMenu();
  };
  const postponeUntilCustomTime = () => {
    handlePostpone(null);
    closePostponeMenu();
  };

  const approveButton = (
    <Button data-cy="request-approve-button" onClick={handleApprove} size="small" variant="contained">
      Approve
    </Button>
  );

  const denyButton = (
    <Button data-cy="request-deny-button" onClick={handleDeny} size="small" color="error" variant="outlined">
      Deny
    </Button>
  );

  const [drawerOpen, setDrawerOpen] = useState(false);

  const handleOnClose = () => {
    setDrawerOpen(false);
  };

  const postponeButton = !isPostponed && !(request.requestType === 'CompTimeRequest') && (
    <Button
      size="small"
      variant="outlined"
      onClick={openPostponeMenu}
      sx={(theme) => ({
        gap: theme.spacing(1),
        '&[aria-expanded="true"] svg': { transform: 'rotate(180deg)' },
        '&.Mui-disabled svg path': { stroke: 'currentColor' },
      })}
    >
      Postpone
      <ChevronDown />
    </Button>
  );

  const renderTooltip = (title: string | ReactElement, trigger: ReactElement) => {
    return (
      <Tooltip
        arrow
        title={<Box sx={(theme) => ({ color: theme.palette.gray[50], typography: 'bodySMedium' })}>{title}</Box>}
        placement="top"
        PopperProps={{
          sx: (theme) => ({
            '& .MuiTooltip-tooltip': {
              backgroundColor: theme.palette.gray[800],
              padding: theme.spacing(1.5),
              borderRadius: theme.spacing(1),
            },
            '& .MuiTooltip-arrow': {
              color: theme.palette.gray[800],
            },
          }),
        }}
      >
        {trigger}
      </Tooltip>
    );
  };

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
      }}
    >
      <Box
        sx={(theme) => ({
          display: 'flex',
          gap: theme.spacing(1),
        })}
      >
        {!canApprove &&
          renderTooltip(
            'Too late to approve.',
            <Box sx={{ display: 'flex' }}>{cloneElement(approveButton, { disabled: true, onClick: null })}</Box>,
          )}
        {canApprove && approveButton}
        {denyButton}
        {!canPostpone &&
          postponeButton &&
          renderTooltip(
            'Too late to postpone.',
            <Box sx={{ display: 'flex' }}>{cloneElement(postponeButton, { disabled: true, onClick: null })}</Box>,
          )}
        {canPostpone &&
          postponeButton &&
          cloneElement(postponeButton, {
            id: `${id}postpone-button`,
            'aria-controls': postponeAnchorEl ? `${id}postpone-menu` : undefined,
            'aria-haspopup': 'true',
            'aria-expanded': postponeAnchorEl ? 'true' : undefined,
          })}
        {canPostpone && postponeButton && (
          <Menu
            id={`${id}postpone-menu`}
            anchorEl={postponeAnchorEl}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            transformOrigin={{ vertical: -8, horizontal: 'right' }}
            open={!!postponeAnchorEl}
            onClose={closePostponeMenu}
            MenuListProps={{ 'aria-labelledby': `${id}postpone-button` }}
            sx={(theme) => ({
              '& .MuiMenu-paper': {
                border: `1px solid ${theme.palette.gray[100]}`,
                borderRadius: '12px',
              },
              '& .MuiMenu-list': {
                p: theme.spacing(1.5),
              },
              '& .MuiListItem-root': {
                color: theme.palette.gray[400],
                p: theme.spacing(1, 1.5),
                typography: 'bodySMedium',
              },
              '& .MuiMenuItem-root': {
                borderRadius: theme.spacing(1),
                color: theme.palette.gray[900],
                p: theme.spacing(1, 1.5),
                typography: 'bodyMRegular',
              },
              '& .MuiMenuItem-root:hover': {
                backgroundColor: theme.palette.gray[100],
              },
            })}
          >
            <ListItem>Postpone request</ListItem>
            {nextShiftDate && <MenuItem onClick={postponeUntilNextShift}>Beginning of my next shift</MenuItem>}
            <MenuItem onClick={postponeUntilTomorrow}>Tomorrow same time</MenuItem>
            <Divider component="li" />
            <MenuItem onClick={postponeUntilCustomTime}>Custom time</MenuItem>
          </Menu>
        )}
      </Box>
      {isPostponed &&
        renderTooltip(
          <Box
            sx={(theme) => ({
              color: theme.palette.gray[50],
              display: 'flex',
              alignItems: 'center',
            })}
          >
            <Box sx={{ mr: 2 }}>
              {isOverdue ? 'Overdue since ' : 'Due '}
              {format(postponedUntil, 'MMM dd, yyyy')}
            </Box>
            <ClockIcon16 />
            <Box sx={{ ml: 0.5 }}>{format(postponedUntil, 'HHmm')}</Box>
          </Box>,
          <Box
            sx={(theme) => ({
              backgroundColor: isOverdue ? theme.palette.rose[500] : theme.palette.gray[200],
              color: isOverdue ? theme.palette.common.white : theme.palette.gray[700],
              padding: theme.spacing(1, 1.5),
              borderRadius: theme.spacing(100),
              display: 'flex',
              alignItems: 'center',
              gap: theme.spacing(1),
              typography: 'bodySRegular',
              '& svg path': { stroke: isOverdue ? theme.palette.common.white : theme.palette.gray[900] },
            })}
            onClick={isMobile ? () => setDrawerOpen(true) : undefined}
          >
            <ClockFastForwardIcon16 />
            Postponed
          </Box>,
        )}
      {request.requestType === 'CompTimeRequest' && isToday(expiresAt) && !isPostponed && (
        <Box
          sx={(theme) => ({
            backgroundColor: isOverdue ? theme.palette.rose[500] : theme.palette.yellow[200],
            color: isOverdue ? theme.palette.common.white : theme.palette.yellow[700],
            padding: theme.spacing(1, 1.5),
            borderRadius: theme.spacing(100),
            display: 'flex',
            alignItems: 'center',
            gap: theme.spacing(1),
            typography: 'bodySRegular',
            '& svg path': { stroke: isOverdue ? theme.palette.common.white : theme.palette.yellow[900] },
          })}
          onClick={isMobile ? () => setDrawerOpen(true) : undefined}
        >
          <ExclamationMarkCircleIcon16 />
          {formatExpiresAt(expiresAt)}
        </Box>
      )}

      {isMobile && (
        <GenericDrawerOrModal
          anchor="bottom"
          drawerOpen={drawerOpen}
          handleOnClose={handleOnClose}
          loading={false}
          showHeader={false}
          disableFooter={true}
          sxProps={{
            '.MuiDrawer-paper': {
              borderBottomLeftRadius: 0,
              borderBottomRightRadius: 0,
            },
          }}
        >
          <Box
            sx={(theme) => ({
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'column',
              padding: theme.spacing(4, 2, 1, 2),
            })}
          >
            <Box
              sx={(theme) => ({
                padding: theme.spacing(1.5),
                mb: theme.spacing(2),
                width: '48px',
                height: '48px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                borderRadius: '48px',
                boxShadow: '0px 4px 6px -1px rgba(10, 14, 22, 0.10), 0px 2px 4px -1px rgba(10, 14, 22, 0.06)',
              })}
            >
              <ClockFastForwardIcon24 color={isOverdue ? theme.palette.rose[500] : theme.palette.gray[900]} />
            </Box>

            {request.postponedUntil && (
              <Typography sx={{ typography: 'bodyMRegular' }}>
                {' '}
                {isOverdue ? 'Postpone overdue since ' : 'Due '}{' '}
                <span style={{ fontWeight: 600 }}>
                  {' '}
                  {format(request.postponedUntil, 'MMM dd, yyyy')} at {format(request.postponedUntil, 'HHmm')}{' '}
                </span>{' '}
              </Typography>
            )}

            <Box
              onClick={handleOnClose}
              sx={(theme) => ({
                mb: theme.spacing(3),
                mt: theme.spacing(3),
                display: 'flex',
                width: '342px',
                height: '44px',
                padding: theme.spacing(1, 2),
                justifyContent: 'center',
                alignItems: 'center',
                flexShrink: 0,
                borderRadius: '6px',
                backgroundColor: theme.palette.common.white,
                border: '1px solid',
                borderColor: theme.palette.gray[300],
                '&:hover': {
                  backgroundColor: theme.palette.gray[50],
                },
              })}
            >
              <Typography sx={{ typography: 'bodyMMedium' }} color={theme.palette.gray[700]}>
                Close
              </Typography>
            </Box>
          </Box>
        </GenericDrawerOrModal>
      )}
    </Box>
  );
};
