import { Box } from '@mui/material';
import { Badge, CustomTable, Edit02Icon16, TableProps } from '@stationwise/component-module';
import { DetailCode } from '@stationwise/share-types';
import { makeTestIdentifier } from '../../../../../libs/share-utils/src/lib/string';

interface DetailCodeTableProps {
  detailCodes: DetailCode[];
  handleEditAction: (detailCode: DetailCode) => void;
}

export const DetailCodeTable = ({ detailCodes, handleEditAction }: DetailCodeTableProps) => {
  const columns: TableProps['columns'] = [
    {
      id: 'name',
      label: 'NAME',
      minWidth: 100,
      align: 'left',
    },
    {
      id: 'code',
      label: 'CODE',
      minWidth: 100,
      align: 'left',
    },
    {
      id: 'payCodeType',
      label: 'PAY CODE TYPE',
      minWidth: 100,
      align: 'left',
    },
    {
      id: 'actions',
      label: 'ACTIONS',
      minWidth: 100,
      align: 'left',
    },
  ];

  const data: TableProps['data'] = detailCodes.map((detailCode: DetailCode) => ({
    name: <Box sx={(theme) => ({ color: theme.palette.gray[900], typography: 'bodySRegular' })}>{detailCode.name}</Box>,
    code: (
      <Box display="inline-flex">
        <Badge label={detailCode.code} isRound type="sm" />
      </Box>
    ),
    payCodeType: (
      <Box display="inline-flex">
        <Badge label={detailCode.payCodeType} isRound type="sm" />
      </Box>
    ),

    actions: (
      <Box>
        <Box
          onClick={() => handleEditAction(detailCode)}
          sx={(theme) => ({
            color: theme.palette.gray[500],
            cursor: 'pointer',
            display: 'flex',
            alignItems: 'center',
            '&:hover': {
              color: theme.palette.gray[800],
            },
          })}
          title="Edit Detail Code"
          data-cy={`edit-detail-code-${makeTestIdentifier(detailCode.name)}`}
        >
          <Edit02Icon16 />
        </Box>
      </Box>
    ),
  }));
  return <CustomTable columns={columns} data={data} />;
};
