import { captureException } from '@sentry/react';
import { useEffect, useState } from 'react';
import { client } from '@stationwise/share-api';

export const useFetchRequestOverview = <T,>(url: string) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [data, setData] = useState<T | null>(null);

  useEffect(() => {
    setIsLoading(true);
    setIsError(false);
    setError(null);
    setData(null);

    if (url !== '' && !!url) {
      client
        .get(url)
        .then((data) => {
          setData(data.data);
        })
        .catch((error) => {
          setIsError(true);
          setError(error);
          captureException(error);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [url]);

  return {
    isLoading,
    isError,
    error,
    data,
  };
};
