import { Box, Divider, FormControl, InputLabel, MenuItem, Select, Typography } from '@mui/material';

interface HiringEnginePositionControllerProps {
  vacantBattalions: number[];
  vacantStations: string[];
  vacantApparatuses: string[];
  selectedBattalions: number[];
  selectedStations: string[];
  selectedApparatuses: string[];
  onSelectedBattalionsChange: (selected: number[]) => void;
  onSelectedStationsChange: (selected: string[]) => void;
  onSelectedApparatusesChange: (selected: string[]) => void;
  isDisabled?: boolean;
}

export const HiringEnginePositionController = ({
  vacantBattalions,
  vacantStations,
  vacantApparatuses,
  selectedBattalions,
  selectedStations,
  selectedApparatuses,
  onSelectedBattalionsChange,
  onSelectedStationsChange,
  onSelectedApparatusesChange,
  isDisabled = false,
}: HiringEnginePositionControllerProps) => {
  const getDisplayValue = (selected: (string | number)[], options: (string | number)[]) => {
    if (selected.length === 0) return 'No filter applied';
    if (selected.length === options.length) return 'All';
    return selected.join(', ');
  };

  return (
    <Box sx={{ padding: 2 }}>
      <Typography sx={{ marginBottom: 1 }}>Filter Positions by:</Typography>
      <Divider sx={{ marginBottom: 2 }} />
      <Box sx={{ display: 'flex', gap: 2 }}>
        <FormControl fullWidth sx={{ flex: 1 }} disabled={isDisabled}>
          <InputLabel shrink>Battalion</InputLabel>
          <Select
            label="Battalion"
            variant="outlined"
            notched
            displayEmpty
            multiple
            value={selectedBattalions}
            onChange={(e) => onSelectedBattalionsChange(e.target.value as number[])}
            renderValue={() => getDisplayValue(selectedBattalions, vacantBattalions)}
            disabled
          >
            {vacantBattalions.map((battalion) => (
              <MenuItem key={battalion} value={battalion}>
                {battalion}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl fullWidth sx={{ flex: 1 }} disabled={isDisabled}>
          <InputLabel shrink>Station</InputLabel>
          <Select
            label="Station"
            variant="outlined"
            notched
            displayEmpty
            multiple
            value={selectedStations}
            onChange={(e) => onSelectedStationsChange(e.target.value as string[])}
            renderValue={() => getDisplayValue(selectedStations, vacantStations)}
            disabled={isDisabled}
          >
            {vacantStations.map((station) => (
              <MenuItem key={station} value={station}>
                {station}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl fullWidth sx={{ flex: 1 }} disabled={isDisabled}>
          <InputLabel shrink>Apparatus</InputLabel>
          <Select
            label="Apparatus"
            variant="outlined"
            notched
            displayEmpty
            multiple
            value={selectedApparatuses}
            onChange={(e) => onSelectedApparatusesChange(e.target.value as string[])}
            renderValue={() => getDisplayValue(selectedApparatuses, vacantApparatuses)}
          >
            {vacantApparatuses.map((apparatus) => (
              <MenuItem key={apparatus} value={apparatus}>
                {apparatus}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
    </Box>
  );
};
