import { Box } from '@mui/material';
import { differenceInHours, differenceInMinutes } from 'date-fns';
import { useMemo } from 'react';
import { ChevronDownIcon20 } from '@stationwise/component-module';

interface AdditionalPaidTimeDurationProps {
  additionalPaidTime?: { start: Date; end: Date };
  disabled?: boolean;
  header: string;
  openTimeSelectDrawer: () => void;
  time: Date;
}

export const AdditionalPaidTimeDuration = ({
  additionalPaidTime,
  disabled = false,
  header,
  openTimeSelectDrawer,
  time,
}: AdditionalPaidTimeDurationProps) => {
  const hours = useMemo(() => {
    if (additionalPaidTime) {
      return differenceInHours(additionalPaidTime.end, additionalPaidTime.start, { roundingMethod: 'floor' });
    }
    return time?.getHours();
  }, [additionalPaidTime, time]);
  const minutes = useMemo(() => {
    if (additionalPaidTime) {
      return differenceInMinutes(additionalPaidTime.end, additionalPaidTime.start) % 60;
    }
    return time?.getMinutes();
  }, [additionalPaidTime, time]);
  return (
    <>
      <Box
        sx={(theme) => ({
          p: theme.spacing(2),
          mt: theme.spacing(2),
          typography: 'bodyLMedium',
        })}
      >
        {header}
      </Box>
      <Box
        sx={(theme) => ({
          display: 'flex',
          px: theme.spacing(2),
          width: '100%',
        })}
      >
        <Box
          data-cy="duration-hours-select"
          display="flex"
          justifyContent="center"
          sx={(theme) => ({
            border: hours < 0 || hours > 24 ? `1px solid ${theme.palette.red[400]}` : `1px solid ${theme.palette.gray[200]}`,
            borderRadius: theme.spacing(1),
            p: '10px 13px 10px',
            width: '50%',
            cursor: 'pointer',
            justifyContent: 'space-between',
            alignItems: 'center',
            typography: 'bodySRegular',
            backgroundColor: disabled ? theme.palette.gray[100] : theme.palette.common.white,
          })}
          onClick={() => (disabled ? undefined : openTimeSelectDrawer())}
        >
          <Box>{hours}</Box>
          <Box
            sx={(theme) => ({
              color: theme.palette.gray[500],
              '& svg': {
                color: theme.palette.gray[400],
              },
              display: 'flex',
              alignItems: 'center',
              gap: theme.spacing(0.5),
            })}
          >
            HRS {!disabled && <ChevronDownIcon20 />}
          </Box>
        </Box>
        <Box
          data-cy="duration-minutes-select"
          sx={(theme) => ({
            border:
              minutes < 0 || (minutes > 0 && hours >= 24)
                ? `1px solid ${theme.palette.red[400]}`
                : `1px solid ${theme.palette.gray[200]}`,
            borderRadius: theme.spacing(1),
            p: '10px 13px 10px',
            width: '50%',
            cursor: 'pointer',
            display: 'flex',
            marginLeft: theme.spacing(2),
            justifyContent: 'space-between',
            typography: 'bodySRegular',
            backgroundColor: disabled ? theme.palette.gray[100] : theme.palette.common.white,
          })}
          onClick={() => (disabled ? undefined : openTimeSelectDrawer())}
        >
          <Box>{minutes}</Box>
          <Box
            sx={(theme) => ({
              color: theme.palette.gray[500],
              '& svg': {
                color: theme.palette.gray[400],
              },
              display: 'flex',
              alignItems: 'center',
              gap: theme.spacing(0.5),
            })}
          >
            MIN {!disabled && <ChevronDownIcon20 />}
          </Box>
        </Box>
      </Box>
    </>
  );
};
