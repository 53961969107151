import { Box, ButtonBase } from '@mui/material';
import { makeTestIdentifier } from '@stationwise/share-utils';

interface ChipProps {
  label: string;
  isSelected?: boolean;
  onClick?: () => void;
}

export const Chip = ({ label, isSelected = false, onClick }: ChipProps) => {
  return (
    <ButtonBase data-cy={`chip-button-${makeTestIdentifier(label)}`} onClick={onClick}>
      <Box
        sx={(theme) => ({
          color: isSelected ? theme.palette.gray[900] : theme.palette.gray[300],
          background: isSelected ? theme.palette.gray[100] : theme.palette.gray[800],
          border: isSelected ? '1px solid transparent' : `1px solid ${theme.palette.gray[300]}`,
          borderRadius: '100px',
          p: '6px 16px',
          display: 'flex',
          justifyContent: 'center',
          cursor: 'pointer',
          typography: 'bodySRegular',
          '&:hover': {
            background: theme.palette.gray[600],
            border: `1px solid ${theme.palette.gray[600]}`,
            color: theme.palette.common.white,
          },
        })}
      >
        {label}
      </Box>
    </ButtonBase>
  );
};
