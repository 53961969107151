import { Box } from '@mui/material';
import { Dispatch, SetStateAction } from 'react';
import { Button } from '@stationwise/component-module';

interface DutyEntryProps {
  setDutyEntry: Dispatch<SetStateAction<boolean>>;
  selectedDutyDate: string;
  setSelectedDutyDate: Dispatch<SetStateAction<string>>;
  setDutyFormOpen: Dispatch<SetStateAction<boolean>>;
}

export const DutyEntry = ({ setDutyEntry, selectedDutyDate, setSelectedDutyDate, setDutyFormOpen }: DutyEntryProps) => {
  const iosWebViewPaddingBottom = 0;

  const handleOnClose = () => {
    setDutyEntry(false);
  };

  const handleDutyEntry = () => {
    handleOnClose();
    setDutyFormOpen(true);
  };

  // The `spacer` ensures the `content` doesn't overlap with whatever is rendered above this component.
  // The height of the `spacer` is roughly the height of the `content` when `See all` is in the collapsed state.
  const spacer = <Box sx={{ height: '148px' }} />;
  const content = (
    <Box
      sx={(theme) => ({
        position: 'fixed',
        bottom: iosWebViewPaddingBottom,
        width: 'calc(100% - 16px)',
        margin: theme.spacing(1),
        border: '1px solid #DEE3ED',
        borderRadius: '16px',
        boxShadow: '0px -10px 15px -3px rgba(10, 14, 22, 0.1)',
        background: theme.palette.common.white,
        zIndex: theme.zIndex.modal,
        maxWidth: '768px',
        left: '50%',
        display: 'flex',
        alignItems: 'center',
        transform: 'translateX(-50%)',
      })}
    >
      <Box
        sx={(theme) => ({
          p: theme.spacing(2),
          typography: 'bodyMMedium',
        })}
      >
        Select the date you would like to fill out the duty form.
      </Box>

      <Box
        sx={(theme) => ({
          width: '100%',
          p: theme.spacing(1),
          justifyContent: 'center',
          display: 'flex',
        })}
      >
        <Box
          sx={(theme) => ({
            width: '50%',
            m: theme.spacing(1),
          })}
        >
          <Button
            variant="outlined"
            onClick={() => {
              setSelectedDutyDate('');
              handleOnClose();
            }}
            sx={{ width: '100%' }}
          >
            Cancel
          </Button>
        </Box>
        <Box sx={(theme) => ({ width: '50%', m: theme.spacing(1) })}>
          <Button disabled={!selectedDutyDate} variant="contained" onClick={() => handleDutyEntry()} sx={{ width: '100%' }}>
            Submit
          </Button>
        </Box>
      </Box>
    </Box>
  );
  return (
    <>
      {spacer}
      {content}
    </>
  );
};
