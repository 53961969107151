import { Autocomplete, Box, Dialog, FormControl, FormHelperText, MenuItem, TextField, Typography } from '@mui/material';
import { useState } from 'react';
import {
  Button,
  CertBadge,
  getCertColors,
  SnackbarService,
  theme,
  useLoadedDepartmentInfoContext,
} from '@stationwise/component-module';
import { client, isAxiosError } from '@stationwise/share-api';
import { AllowedColors, allowedColorsList, Certification } from '@stationwise/share-types';

interface CertificationModalProps {
  onSaveSuccess: () => void;
  selectedCertification?: Certification;
  setShowModal: (show: boolean) => void;
  showModal: boolean;
}

const newCertification: Certification = {
  id: -1,
  name: '',
  code: '',
  color: 'gray' as AllowedColors,
  childCertifications: [],
};

export const CertificationModal = ({
  onSaveSuccess,
  selectedCertification,
  setShowModal,
  showModal,
}: CertificationModalProps) => {
  const {
    state: { departmentInfo },
    dispatch,
  } = useLoadedDepartmentInfoContext();
  const existingCertifications = departmentInfo.certifications
    .map((certification) => certification.code.toLowerCase())
    .filter((certification) => certification !== selectedCertification?.code.toLowerCase());
  const colorItems = allowedColorsList.map((color) => ({
    label: color.slice(0, 1).toUpperCase() + color.slice(1),
    value: color,
    color: color,
  }));
  const title = selectedCertification ? 'Edit Certification Details' : 'Create New Certification';
  const [certification, setCertification] = useState<Certification>(
    selectedCertification ? selectedCertification : newCertification,
  );
  const [error, setError] = useState({ duplicateCode: false });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const disabled = !certification.code || Object.values(error).some((e) => e) || isSubmitting || !certification.name?.trim();
  const hiddenParts = false;

  const handleClose = () => {
    setIsSubmitting(false);
    setShowModal(false);
    setCertification(newCertification);
  };

  const handleSuccess = () => {
    handleClose();
    onSaveSuccess();
    dispatch({ type: 'REFETCH_DEPARTMENT_INFO' });
  };

  const handleSave = async () => {
    setIsSubmitting(true);
    if (selectedCertification) {
      //  UPDATE
      try {
        const response = await client.put(`/certification/certifications/${certification.id}/`, {
          ...certification,
          childCertificationIds: certification.childCertifications.map((cert) => cert.id),
        });
        SnackbarService.notify({
          content: `Successfully updated Certification: ${response.data.name}`,
          severity: 'success',
          duration: 5000,
        });
        handleSuccess();
      } catch (error) {
        SnackbarService.notify({
          content: `Failed to save Certification: ${isAxiosError(error) ? error?.response?.data ?? error.message : ''}`,
          severity: 'error',
          duration: 5000,
        });
        handleClose();
      }
    } else {
      // CREATE
      try {
        const response = await client.post('/certification/certifications/', { ...certification, id: undefined });
        SnackbarService.notify({
          content: `Successfully added new Certification: ${response.data.name}`,
          severity: 'success',
          duration: 5000,
        });
        handleSuccess();
      } catch (error) {
        SnackbarService.notify({
          content: `Failed to save new Certification: ${isAxiosError(error) ? error?.response?.data ?? error.message : ''}`,
          severity: 'error',
          duration: 5000,
        });
        handleClose();
      }
    }
  };
  return (
    <Dialog open={showModal} onClose={handleClose}>
      <Box
        sx={{
          display: 'flex',
          width: '100%',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Box
          sx={(theme) => ({
            backgroundColor: theme.palette.common.white,
            justifyContent: 'center',
            borderRadius: theme.spacing(1.5),
            p: theme.spacing(3),
            width: '496px',
            display: 'flex',
            flexDirection: 'column',
            '.MuiFormControl-root': {
              mb: 4,
              '.MuiInputLabel-root': {
                top: '-5px',
              },
            },
          })}
        >
          <Box
            sx={{
              typography: 'bodyXLSemibold',
              mb: 4,
            }}
          >
            {title}
          </Box>

          <TextField
            label="Name"
            onChange={(event) => {
              setCertification({ ...certification, name: event.currentTarget.value });
            }}
            required
            value={certification.name}
            data-cy="name"
            error={!certification.name?.trim()}
          />

          <TextField
            error={error.duplicateCode}
            label="Code"
            helperText={error.duplicateCode ? 'This code already exists.' : ''}
            onChange={(event) => {
              setCertification({ ...certification, code: event.currentTarget.value });
              if (existingCertifications.includes(event.currentTarget.value.toLowerCase())) {
                setError({ ...error, duplicateCode: true });
              } else if (error.duplicateCode) {
                setError({ ...error, duplicateCode: false });
              }
            }}
            required
            value={certification.code}
            data-cy="code"
          />

          <TextField
            label="Color"
            onChange={(event) => {
              setCertification({ ...certification, color: event.target.value as AllowedColors });
            }}
            select
            value={certification.color}
          >
            {colorItems.map((color) => (
              <MenuItem key={color.value} value={color.value}>
                <Typography
                  sx={{
                    color: getCertColors({ theme, color: String(color.label), dark: false }).color,
                    textAlign: 'left',
                    paddingRight: 1,
                  }}
                >
                  {color.label}
                </Typography>
              </MenuItem>
            ))}
          </TextField>

          {existingCertifications?.length > 0 && hiddenParts && (
            <FormControl>
              <Autocomplete
                multiple
                value={certification.childCertifications.filter((cert) => cert.id !== -1)}
                options={departmentInfo.certifications.filter(
                  (cert) => !certification.childCertifications.some((childCert) => childCert.code === cert.code),
                )}
                getOptionLabel={(option) => option.code}
                onChange={(_event, value) =>
                  setCertification({
                    ...certification,
                    childCertificationIds: value.map((cert) => cert.id),
                    childCertifications: value,
                  })
                }
                renderInput={(params) => (
                  <TextField {...params} label="Child Certifications" placeholder={'Select Child Certifications'} />
                )}
                renderTags={(tagValue) => tagValue.map((option) => <CertBadge key={option.code} cert={option} />)}
                sx={{
                  '.MuiFormControl-root': {
                    mb: 0,
                    '.MuiInputLabel-root:not(.Mui-focused)': {
                      top: 0,
                    },
                  },
                }}
              />
              <FormHelperText>A Certification can have multiple child certifications</FormHelperText>
            </FormControl>
          )}

          <Box
            sx={{
              justifyContent: 'space-between',
              display: 'flex',
              width: '100%',
            }}
          >
            <Button variant="outlined" size="large" sx={{ width: '216px' }} onClick={() => handleClose()}>
              Cancel
            </Button>
            <Button
              variant="contained"
              size="large"
              sx={{ width: '216px' }}
              onClick={() => handleSave()}
              disabled={disabled}
              loading={isSubmitting}
              data-cy="save-certification-button"
            >
              Save
            </Button>
          </Box>
        </Box>
      </Box>
    </Dialog>
  );
};
