import { Box } from '@mui/material';

export const AddNewObjectPlaceholder = ({ text, onClick }: { text: string; onClick: () => void }) => {
  return (
    <Box
      sx={(theme) => ({
        background: theme.palette.gray[100],
        borderRadius: '16px',
        border: `1px solid ${theme.palette.gray[300]}`,
        color: theme.palette.gray[500],
        textAlign: 'center',
        cursor: 'pointer',
        height: '100px',
        padding: '13px 25px 13px 23px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        typography: 'buttonL',
        '&:hover': {
          background: theme.palette.gray[200],
        },
      })}
      onClick={onClick}
    >
      {text}
    </Box>
  );
};
