import { captureException } from '@sentry/react';
import { useEffect, useState } from 'react';
import { client } from '@stationwise/share-api';
import { PayPeriod } from '@stationwise/share-types';

interface UseFetchPayPeriodsProps {
  employeeId?: string;
  currentPayPeriod?: PayPeriod;
  setCurrentPayPeriodIndex?: React.Dispatch<React.SetStateAction<number>>;
}

export const useFetchPayPeriods = (props: UseFetchPayPeriodsProps = {}) => {
  const { employeeId, currentPayPeriod, setCurrentPayPeriodIndex } = props;
  const [payPeriods, setPayPeriods] = useState<PayPeriod[]>([]);
  const [isLoaded, setIsLoaded] = useState(false);
  const [currentYear, setCurrentYear] = useState(new Date().getFullYear());

  useEffect(() => {
    if (!employeeId) return;
    const fetchPayPeriods = async () => {
      try {
        const params = {
          startDate: `${currentYear - 1}-01-01`,
          endDate: `${currentYear + 1}-12-31`,
          ...(employeeId && { employeeId }),
        };
        const response = await client.get(`/payroll/personal/get-pay-periods/`, { params });
        setPayPeriods(
          response.data.map((item: { startDate: string; endDate: string; id: number }) => ({
            startDate: item.startDate,
            endDate: item.endDate,
            id: item.id,
          })),
        );
        if (setCurrentPayPeriodIndex) {
          const currentPayPeriodIndex = response.data.findIndex(
            (period: { startDate: string | undefined }) => period.startDate === currentPayPeriod?.startDate,
          );
          setCurrentPayPeriodIndex(currentPayPeriodIndex);
        }
      } catch (error) {
        captureException(error);
      } finally {
        setIsLoaded(true);
      }
    };

    fetchPayPeriods();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentYear, employeeId]);

  return { payPeriods, isLoaded, currentYear, setCurrentYear };
};
