import { EventInput, EventSourceFuncArg } from '@fullcalendar/core/index.js';
import { EventImpl } from '@fullcalendar/core/internal';
import { Box } from '@mui/material';
import { startOfMonth, endOfMonth } from 'date-fns';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import {
  useLoadedAuthUserContext,
  SHIFT_TITLES,
  Calendar,
  getDisplayOptionByName,
  formatDate,
  isAdditionalPaidTimeEvent,
  parseDateParam,
  useLoadedDepartmentInfoContext,
  SnackbarService,
  isTimeOffEvent,
  SHIFT_OVERVIEW_OPTIONS,
  PayPeriodDateInfo,
  fetchEvents,
  fetchPayPeriods,
} from '@stationwise/component-module';
import { GetIncidentOverview, ListFieldsStaffingList } from '@stationwise/share-types';
import { PUSHER_EVENT_TYPES, PUSHER_UPDATE_MESSAGE, RefreshEventCallback } from '@stationwise/share-utils';
import { AdditionalPaidTime } from './AdditionalPaidTime';
import { AdditionalPaidTimeResponse } from './AdditionalPaidTime/AdditionalPaidTimeResponse';
import { CreateIncident } from './CreateIncident';
import { DrawerContent } from './DrawerContent';
import { DutyEntry } from './DutyForm/DutyEntry';
import { DutyForm } from './DutyForm/DutyForm';
import { IncidentOverview } from './Incident/IncidentOverview';
import { RequestOvertime } from './RequestOvertime';
import { CancelOvertimeRequest } from './RequestOvertime/CancelOvertimeRequest';
import { RequestOverview } from './RequestSharedComponents/RequestOverview';
import { ShiftOverview } from './ShiftOverview';
import { TimeOffRequest } from './TimeOffRequest';
import { RequestMultipleDays } from './TimeOffRequest/RequestMultipleDays';
import { TradeShift } from './TradeShift';

export const CalendarContent = () => {
  const { state: authUserState } = useLoadedAuthUserContext();
  const { state: departmentInfoState } = useLoadedDepartmentInfoContext();
  const refreshTriggerChannel = departmentInfoState.refreshTriggerChannel;
  const overtimeRequestEnabled = departmentInfoState.departmentInfo.settings.overtimeRequestEnabled;
  const incidentFormEnabled = departmentInfoState.departmentInfo.settings.incidentFormEnabled;

  const [searchParams] = useSearchParams();
  const [selectedDate, setSelectedDate] = useState(() => parseDateParam(searchParams.get('date') || ''));
  const [selectedView, setSelectedView] = useState(() => getDisplayOptionByName(searchParams.get('display') || '').value);
  const [todayEvents, setTodayEvents] = useState<EventInput[]>([]);
  const [createIncidentOpen, setCreateIncidentOpen] = useState<boolean>(false);
  const [drawerOpen, setDrawerOpen] = useState<boolean>(false);

  const [shiftOverviewOpen, setShiftOverviewOpen] = useState<boolean>(false);
  const [tradeShiftOpen, setTradeShiftOpen] = useState<boolean>(false);
  const [overviewOpen, setOverviewOpen] = useState<boolean>(false);
  const [additionalPaidTimeOpen, setAdditionalPaidTimeOpen] = useState<boolean>(false);
  const [incidentOverviewOpen, setIncidentOverviewOpen] = useState<boolean>(false);
  const [selectedEvent, setSelectedEvent] = useState<EventInput | EventImpl>({});
  const [selectedOvertimeDates, setSelectedOvertimeDates] = useState<string[]>([]);
  const [selectedTimeOffDates, setSelectedTimeOffDates] = useState<string[]>([]);
  const [selectedDutyDate, setSelectedDutyDate] = useState<string>('');
  const [selectedIncidentDate, setSelectedIncidentDate] = useState<string>('');
  const [timeOffRequestOpen, setTimeOffRequestOpen] = useState<boolean>(false);
  const [dutyFormOpen, setDutyFormOpen] = useState<boolean>(false);
  const [showAdditionalPaidTimeResult, setShowAdditionalPaidTimeResult] = useState<boolean>(false);
  const [errorAdditionalPaidTime, setErrorAdditionalPaidTime] = useState<boolean>(false);
  const [messageAdditionalPaidTime, setMessageAdditionalPaidTime] = useState('');
  const [cancelOvertimeRequestOpen, setCancelOvertimeRequestOpen] = useState(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [refetchEvents, setRefetchEvents] = useState<boolean>(false);
  const [createIncident, setCreateIncident] = useState<boolean>(incidentFormEnabled && !overtimeRequestEnabled);
  const [incidentFormOpen, setIncidentFormOpen] = useState(false);
  const [selectedIncident, setSelectedIncident] = useState<GetIncidentOverview>();
  const [isCloseIncident, setIsCloseIncident] = useState(false);
  const [selectedStaffingList, setSelectedStaffingList] = useState<ListFieldsStaffingList | null>(null);
  const [requestMultipleDaysOff, setRequestMultipleDaysOff] = useState<boolean>(false);
  const [dutyEntry, setDutyEntry] = useState<boolean>(false);
  const [calendarHeight, setCalendarHeight] = useState(0);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [payPeriodDates, setPayPeriodDates] = useState<PayPeriodDateInfo[]>([]);
  const [showCancelModal, setShowCancelModal] = useState(false);

  useEffect(() => {
    const calculateCalendarHeight = () => {
      const windowHeight = window.innerHeight;
      const fixedBoxHeight = 0;
      const heightLeft = windowHeight - fixedBoxHeight - 121;
      setCalendarHeight(heightLeft);
    };
    calculateCalendarHeight();
    window.addEventListener('resize', calculateCalendarHeight);
    return () => window.removeEventListener('resize', calculateCalendarHeight);
  }, []);

  useEffect(() => {
    const fetchDates = async () => {
      try {
        const dates = await fetchPayPeriodDates(startOfMonth(selectedDate), endOfMonth(selectedDate));
        setPayPeriodDates(dates);
      } catch {
        setPayPeriodDates([]);
      }
    };

    fetchDates();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    handleShiftOverviewClose();
    setDrawerOpen(false);
  }, [selectedView]);

  useEffect(() => {
    if (!refreshTriggerChannel) return;

    const handlePusherUpdate: RefreshEventCallback = (data) => {
      if (
        data.triggerAll ||
        (data.message === PUSHER_UPDATE_MESSAGE &&
          data.employeeIdList.some((id) => id.toString() === authUserState.employee.id.toString()))
      ) {
        setRefetchEvents(true);
      }
    };

    const EVENT_TYPES_LISTENED = [PUSHER_EVENT_TYPES.REVIEW, PUSHER_EVENT_TYPES.STAFFING, PUSHER_EVENT_TYPES.CANCELLATION];

    refreshTriggerChannel.bind_many(EVENT_TYPES_LISTENED, handlePusherUpdate);

    return () => {
      if (refreshTriggerChannel) {
        refreshTriggerChannel.unbind_many(EVENT_TYPES_LISTENED);
      }
    };
  }, [refreshTriggerChannel, authUserState.employee.id]);

  const handleShiftOverviewClose = () => {
    setShiftOverviewOpen(false);
    setAnchorEl(null);
  };

  const handleDutyFormClose = () => {
    setDutyFormOpen(false);
    setSelectedDutyDate('');
  };

  const handleAdditionalPaidTimeClose = () => {
    setAdditionalPaidTimeOpen(false);
    setIncidentOverviewOpen(false);
    setSelectedIncident(undefined);
  };

  const handleShiftOverviewOpen = (
    event: React.MouseEvent<HTMLElement>,
    shift: EventInput | EventImpl,
    isEventCard?: boolean,
  ) => {
    if (isEventCard && shift.title) {
      setAnchorEl(event.currentTarget);
      setSelectedEvent(shift);
    } else if (shift.title) {
      setSelectedEvent(shift);
      setAnchorEl(event.currentTarget);
      if (isAdditionalPaidTimeEvent(shift)) {
        setOverviewOpen(true);
      } else if (shift.title.includes(SHIFT_TITLES.INCIDENT)) {
        setIncidentOverviewOpen(true);
      } else if (isTimeOffEvent(shift)) {
        setOverviewOpen(true);
      } else if (shift.title === SHIFT_TITLES.TRADE_REQUESTED || shift.title === SHIFT_TITLES.OFF_TRADE) {
        setOverviewOpen(true);
      } else if (
        shift.title === SHIFT_TITLES.KELLY_DAY ||
        shift.title.includes('Exemption') ||
        shift.title === SHIFT_TITLES.DENIED_TRADE_REQUEST
      ) {
        // pass
      } else {
        setShiftOverviewOpen(true);
      }
    }
  };
  const handleShiftCardOverviewClose = () => {
    setAnchorEl(null);
    setShiftOverviewOpen(false);
  };

  const handleSelect = (option: string) => {
    setShiftOverviewOpen(false);

    switch (option) {
      case SHIFT_OVERVIEW_OPTIONS.TRADE_SHIFT:
        setTradeShiftOpen(true);
        break;
      case SHIFT_OVERVIEW_OPTIONS.REQUEST_TIME_OFF:
        setTimeOffRequestOpen(true);
        break;
      case SHIFT_OVERVIEW_OPTIONS.ADDITIONAL_PAID_TIME:
        setAdditionalPaidTimeOpen(true);
        break;
      case SHIFT_OVERVIEW_OPTIONS.CANCEL_OVERTIME_REQUEST:
        setCancelOvertimeRequestOpen(true);
        break;
      case SHIFT_OVERVIEW_OPTIONS.EDIT_INCIDENT:
        setIncidentFormOpen(true);
        break;
      case SHIFT_OVERVIEW_OPTIONS.CLOSE_INCIDENT:
        setIsCloseIncident(true);
        setIncidentFormOpen(true);
        break;
      case SHIFT_OVERVIEW_OPTIONS.CANCEL_EVENT:
        setOverviewOpen(true);
        setShowCancelModal(true);
        break;
      default:
        break;
    }
  };

  const handleAdditionalPaidTimeRequest = (responseMessage: string, error: boolean) => {
    setAdditionalPaidTimeOpen(false);
    setIncidentOverviewOpen(false);
    setShowAdditionalPaidTimeResult(true);
    setRefetchEvents(true);
    setErrorAdditionalPaidTime(error);
    setMessageAdditionalPaidTime(responseMessage);
  };

  const handleIncidentRequest = (responseMessage: string, error: boolean) => {
    setCreateIncidentOpen(false);
    setIncidentOverviewOpen(false);
    setSelectedIncidentDate('');
    setRefetchEvents(true);
    SnackbarService.notify({
      content: responseMessage,
      showCloseButton: true,
      duration: 5000,
      severity: error ? 'error' : 'success',
    });
    setSelectedIncident(undefined);
  };

  const handleDutyFormRequest = (responseMessage: string, error: boolean) => {
    setDutyFormOpen(false);
    setSelectedIncidentDate('');
    setRefetchEvents(true);
    SnackbarService.notify({
      content: responseMessage,
      showCloseButton: true,
      duration: 5000,
      severity: error ? 'error' : 'success',
    });
  };

  const handleRequestOvertimeClick = (staffingList: ListFieldsStaffingList) => {
    setCreateIncident(false);
    setSelectedStaffingList(staffingList);
  };

  const handleCreateIncidentClick = () => {
    setCreateIncidentOpen(true);
    setSelectedIncident(undefined);
  };

  const handleEmptyDayAdditionalPaidTimeClick = (event: EventInput | EventImpl) => {
    setSelectedEvent(event);
    setAdditionalPaidTimeOpen(true);
  };

  const displayDayEvents = (dayEvents: EventInput[]) => {
    setTodayEvents(dayEvents);
  };

  const getDateIntervalEvents = (fetchInfo: EventSourceFuncArg) => {
    const formattedStartDate = formatDate(fetchInfo.start);
    const formattedEndDate = formatDate(fetchInfo.end);
    return fetchEvents({
      startDate: formattedStartDate,
      endDate: formattedEndDate,
    });
  };

  const fetchPayPeriodDates = async (startDate: Date, endDate: Date): Promise<PayPeriodDateInfo[]> => {
    return fetchPayPeriods({ startDate, endDate });
  };

  return (
    <>
      <Box
        sx={(theme) => ({
          background: theme.palette.common.white,
          display: 'flex',
        })}
      >
        <Box
          sx={{
            width: drawerOpen ? 'calc(100% - 400px)' : '100%',
            height: '100%',
          }}
        >
          <Calendar
            displayDayEvents={displayDayEvents}
            getDateIntervalEvents={getDateIntervalEvents}
            handleRequestOvertimeClick={handleRequestOvertimeClick}
            handleCreateIncidentClick={handleCreateIncidentClick}
            setSelectedOvertimeDates={setSelectedOvertimeDates}
            setSelectedIncidentDate={setSelectedIncidentDate}
            setSelectedTimeOffDates={setSelectedTimeOffDates}
            setSelectedDutyDate={setSelectedDutyDate}
            selectedStaffingList={selectedStaffingList}
            createIncidentOpen={createIncidentOpen}
            setCreateIncidentOpen={setCreateIncidentOpen}
            selectedDate={selectedDate}
            selectedOvertimeDates={selectedOvertimeDates}
            selectedIncidentDate={selectedIncidentDate}
            selectedTimeOffDates={selectedTimeOffDates}
            selectedDutyDate={selectedDutyDate}
            selectedView={selectedView}
            refetchEvents={refetchEvents}
            setRefetchEvents={setRefetchEvents}
            setSelectedDate={setSelectedDate}
            setSelectedEvent={setSelectedEvent}
            setSelectedView={setSelectedView}
            heightOfCalendar={calendarHeight}
            isLoading={isLoading}
            setIsLoading={setIsLoading}
            createIncident={createIncident}
            setCreateIncident={setCreateIncident}
            requestMultipleDaysOff={requestMultipleDaysOff}
            setRequestMultipleDaysOff={setRequestMultipleDaysOff}
            setDutyEntry={setDutyEntry}
            dutyEntry={dutyEntry}
            setDrawerOpen={setDrawerOpen}
            handleShiftOverviewOpen={handleShiftOverviewOpen}
            fetchPayPeriods={fetchPayPeriodDates}
          />
          {drawerOpen && (
            <DrawerContent
              handleShiftOverviewOpen={handleShiftOverviewOpen}
              setDrawerOpen={setDrawerOpen}
              selectedDate={selectedDate}
              todayEvents={todayEvents}
              selectedView={selectedView}
              handleEmptyDayAdditionalPaidTimeClick={handleEmptyDayAdditionalPaidTimeClick}
              payPeriodDates={payPeriodDates}
              handleSelect={handleSelect}
              handleShiftCardOverviewClose={handleShiftCardOverviewClose}
              setSelectedIncident={setSelectedIncident}
            />
          )}
        </Box>
      </Box>
      {overtimeRequestEnabled && selectedStaffingList && (
        <RequestOvertime
          setRefetchEvents={setRefetchEvents}
          selectedOvertimeDates={selectedOvertimeDates}
          setSelectedOvertimeDates={setSelectedOvertimeDates}
          selectedStaffingList={selectedStaffingList}
          setSelectedStaffingList={setSelectedStaffingList}
        />
      )}
      {requestMultipleDaysOff && (
        <RequestMultipleDays
          setRequestMultipleDaysOff={setRequestMultipleDaysOff}
          selectedTimeOffDates={selectedTimeOffDates}
          setSelectedTimeOffDates={setSelectedTimeOffDates}
          setTimeOffRequestOpen={setTimeOffRequestOpen}
        />
      )}
      {dutyEntry && (
        <DutyEntry
          setDutyEntry={setDutyEntry}
          selectedDutyDate={selectedDutyDate}
          setSelectedDutyDate={setSelectedDutyDate}
          setDutyFormOpen={setDutyFormOpen}
        />
      )}
      <DutyForm
        selectedDate={selectedDutyDate}
        handleOnClose={handleDutyFormClose}
        drawerOpen={dutyFormOpen}
        handleDutyFormRequest={handleDutyFormRequest}
      />

      <CreateIncident
        handleIncidentRequest={handleIncidentRequest}
        incidentFormOpen={incidentFormOpen}
        open={createIncidentOpen}
        onClose={() => {
          setCreateIncident(false);
          setCreateIncidentOpen(false);
          setSelectedIncidentDate('');
        }}
        selectedDate={selectedIncidentDate}
        selectedIncident={selectedIncident}
        setSelectedIncident={setSelectedIncident}
        setIncidentFormOpen={setIncidentFormOpen}
        isCloseIncident={isCloseIncident}
        setIsCloseIncident={setIsCloseIncident}
      />
      {shiftOverviewOpen && (
        <ShiftOverview
          anchorEl={anchorEl}
          shift={selectedEvent}
          handleSelect={handleSelect}
          todayEvents={todayEvents}
          handleShiftCardOverviewClose={handleShiftCardOverviewClose}
        />
      )}
      {selectedEvent.start && (
        <TradeShift open={tradeShiftOpen} setOpen={setTradeShiftOpen} shift={selectedEvent} setRefetchEvents={setRefetchEvents} />
      )}
      {overviewOpen && selectedEvent.id && (
        <RequestOverview
          anchorEl={anchorEl}
          shift={selectedEvent}
          handleOnClose={() => {
            setAnchorEl(null);
            setOverviewOpen(false);
          }}
          drawerOpen={overviewOpen}
          setRefetchEvents={setRefetchEvents}
          showCancelModal={showCancelModal}
          setShowCancelModal={setShowCancelModal}
        />
      )}
      {incidentOverviewOpen && (
        <IncidentOverview
          anchorEl={anchorEl}
          handleOnClose={() => {
            setIncidentOverviewOpen(false);
            setAnchorEl(null);
          }}
          drawerOpen={incidentOverviewOpen}
          shift={selectedEvent}
          handleSelect={handleSelect}
          setSelectedIncident={setSelectedIncident}
          setIsCloseIncident={setIsCloseIncident}
        />
      )}
      {selectedEvent.start && (
        <TimeOffRequest
          shift={selectedEvent}
          setOpen={setTimeOffRequestOpen}
          open={timeOffRequestOpen}
          setRefetchEvents={setRefetchEvents}
          selectedTimeOffDates={selectedTimeOffDates}
          setSelectedTimeOffDates={setSelectedTimeOffDates}
        />
      )}
      <AdditionalPaidTime
        handleOnClose={handleAdditionalPaidTimeClose}
        drawerOpen={additionalPaidTimeOpen}
        shift={selectedEvent}
        handleAdditionalPaidTimeRequest={handleAdditionalPaidTimeRequest}
        forceManual={
          !todayEvents.some((event) => [SHIFT_TITLES.REGULAR, SHIFT_TITLES.SHIFT_TRADE].includes(event.title ?? '')) ||
          authUserState.employee.isNonShift
        }
      />
      <CancelOvertimeRequest
        handleOnClose={setCancelOvertimeRequestOpen}
        drawerOpen={cancelOvertimeRequestOpen}
        shift={selectedEvent}
        setRefetchEvents={setRefetchEvents}
      />
      {showAdditionalPaidTimeResult && (
        <AdditionalPaidTimeResponse
          error={errorAdditionalPaidTime}
          message={messageAdditionalPaidTime}
          onClose={setShowAdditionalPaidTimeResult}
        />
      )}
    </>
  );
};
