import { ReactComponent as CancelSymbol } from '@material-symbols/svg-400/outlined/cancel.svg';
import { Box } from '@mui/material';
import React from 'react';
import { ChipFilter, ChipFilterProps, SearchInput, SvgIcon } from '../../../components';

export interface FiltersProps {
  setSearchValue: React.Dispatch<React.SetStateAction<string>>;
  searchValue?: string;
  filters?: ChipFilterProps[];
  onClear?: () => void;
}

export const Filters = ({ setSearchValue, searchValue, filters, onClear }: FiltersProps) => {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      <SearchInput value={searchValue || ''} setValue={setSearchValue} color="white" />
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Box
          sx={(theme) => ({
            display: 'flex',
            gap: { xs: theme.spacing(0.5), sm: theme.spacing(1) },
            padding: '24px 0px',
          })}
        >
          {filters?.map((filter, index) => (
            <ChipFilter
              key={`${filter.label}-${index}`}
              action={filter.action}
              amount={filter.amount}
              icon={filter.icon}
              isSelected={filter.isSelected}
              label={filter.label}
              highlight={filter.highlight}
            />
          ))}
        </Box>
        <Box
          sx={(theme) => ({
            display: 'flex',
            gap: theme.spacing(0.5),
            justifyContent: 'center',
            alignItems: 'center',
            padding: '24px 0px',
            color: theme.palette.blue[600],
            cursor: 'pointer',
            typography: theme.typography.bodySMedium,
            '&:hover': {
              color: theme.palette.blue[300],
            },
          })}
          onClick={onClear}
        >
          Clear
          <SvgIcon
            component={CancelSymbol}
            sx={(theme) => ({
              width: theme.spacing(2),
              height: theme.spacing(2),
            })}
          />
        </Box>
      </Box>
    </Box>
  );
};
