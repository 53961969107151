import { Box, BoxProps } from '@mui/material';

export const COLUMNS = [
  { name: '#', width: '12px', fontWeight: '400' },
  { name: 'Name', width: '90px' },
  { name: 'Rank', width: '70px' },
  { name: 'Certifications', width: '90px' },
];

interface CellProps extends BoxProps<'div'> {
  colIndex: number;
}

export const Cell = ({ children, colIndex, sx, ...props }: CellProps) => {
  const column = COLUMNS[colIndex];
  return (
    <Box
      {...props}
      sx={[
        (theme) => ({
          alignSelf: 'center',
          flex: column.width === 'auto' ? '1' : undefined,
          width: column.width,
          p: theme.spacing(0, 0.5),
          typography: 'bodyXSRegular',
          lineHeight: 1,
          fontWeight: column.fontWeight || '500',
          whiteSpace: 'auto',
          textAlign: 'left',
          '&:first-of-type': { pl: 0 },
          '&:last-of-type': { pr: 0 },
        }),
        ...(Array.isArray(sx) ? sx : sx ? [sx] : []),
      ]}
    >
      {children}
    </Box>
  );
};
