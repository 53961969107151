import { Box, Drawer, Tooltip, useTheme } from '@mui/material';
import { captureException } from '@sentry/react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import {
  Link03Icon16,
  ConversationButtons,
  ConversationInformation,
  EmbedLink,
  SelectCategoryItem,
  SHIFTS,
  WORK_GROUPS,
  ALL_CONTACTS,
  ISelectedRecipients,
  TextEditor,
  ConversationCategories,
} from '@stationwise/component-module';
import { ConversationInput, ConversationListView, ConversationUpdateInput } from '@stationwise/share-types';
import { BubbleMessage } from './BubbleMessage';
import { CancelConversationBanner } from './CancelConversationBanner';
import { DrawerHeader } from './DrawerHeader';
import { SendingMessageSkeleton } from './SendingMessageSkeleton';

export const Conversation = ({
  openDrawer,
  setOpenDrawer,
  conversation,
  setSelectedConversation,
  openAudience,
  setOpenAudience,
  onCancelConversation,
  refetchConversations,
  handleCreateConversation,
  handleUpdateConversation,
  rosterMessage,
  selectedBattalionId,
  date,
}: {
  openDrawer: boolean;
  setOpenDrawer: (open: boolean) => void;
  conversation: ConversationListView | null;
  setSelectedConversation: (conversation: ConversationListView | null) => void;
  openAudience?: boolean;
  setOpenAudience?: (open: boolean) => void;
  onCancelConversation?: (id: number) => void;
  refetchConversations: () => void;
  handleCreateConversation: (conversation: ConversationInput) => Promise<ConversationListView | null>;
  handleUpdateConversation: (id: number, conversation: ConversationUpdateInput) => Promise<ConversationListView>;
  rosterMessage?: boolean;
  selectedBattalionId?: number;
  date?: string;
}) => {
  const theme = useTheme();

  // @TODO https://app.clickup.com/t/9006090230/SW-3268
  // get categories from server
  const categories: SelectCategoryItem[] = ConversationCategories();
  const [openEmbedLink, setOpenEmbedLink] = useState<boolean>(false);
  const [link, setLink] = useState<string>('');
  const [tempLink, setTempLink] = useState<string>('');

  const [loading, setLoading] = useState<boolean>(false);
  const [category, setCategory] = useState<string>(categories[0].value);
  const [subject, setSubject] = useState<string>('');
  const [messageContent, setMessageContent] = useState<string>('');
  const [editorKey, setEditorKey] = useState<number>(1);
  const [resetRecipients, setResetRecipients] = useState<boolean>(false);
  const [recipients, setRecipients] = useState<ISelectedRecipients>({});

  const isSelectedRecipientsEmpty = useMemo((): boolean => {
    if (!recipients) {
      return true;
    }

    if (Object.keys(recipients).length === 0) {
      return true;
    }

    return Object.values(recipients).every((arr) => Array.isArray(arr) && arr.length === 0);
  }, [recipients]);

  const clearEditor = useCallback(() => {
    // resets editor
    const newKey = editorKey * 43;
    setEditorKey(newKey);
  }, [editorKey]);

  const resetFields = useCallback(() => {
    setMessageContent('');
    setSubject('');
    setLink('');
    setTempLink('');
    setOpenEmbedLink(false);
    setCategory(categories[0].value);
    setResetRecipients(!resetRecipients);

    clearEditor();
  }, [categories, resetRecipients, clearEditor]);

  const handleOnCloseDrawer = useCallback(() => {
    resetFields();
    setSelectedConversation(null);
    setOpenDrawer(false);
  }, [resetFields, setSelectedConversation, setOpenDrawer]);

  const handleOnEmbedLink = useCallback(() => {
    setOpenEmbedLink(true);
  }, [setOpenEmbedLink]);

  const handleOnCloseEmbedLink = useCallback(() => {
    setOpenEmbedLink(false);
    setTempLink('');
  }, [setOpenEmbedLink, setTempLink]);

  const handleOnSaveLink = useCallback(() => {
    if (tempLink) {
      setOpenEmbedLink(false);
      setLink(tempLink);
      setTempLink('');
    }
  }, [tempLink, setLink, setOpenEmbedLink, setTempLink]);

  const maxHeightMessageContainer = useMemo(() => (link ? 'calc(100% - 200px)' : 'calc(100% - 168px)'), [link]);

  const sendMessage = useCallback(async () => {
    // @TODO: https://app.clickup.com/t/9006090230/SW-3269
    // open pop up when some field is missing (validation)
    if (conversation?.id) {
      // UPDATE CONVERSATION
      setLoading(true);
      const updatedConversation: ConversationUpdateInput = {
        link,
        messages: [
          {
            body: messageContent,
          },
        ],
      };
      resetFields();
      try {
        const sentConversation = await handleUpdateConversation(conversation.id, updatedConversation);
        setLoading(false);
        setSelectedConversation(sentConversation);
        if (setOpenAudience) {
          setOpenAudience(true);
        }
        refetchConversations();
      } catch (error) {
        captureException(error);
      }
    } else if (messageContent && subject) {
      // CREATE CONVERSATION
      setLoading(true);
      const newConversation: ConversationInput = {
        subject,
        link,
        category,
        status: 'Sent',
        messages: [
          {
            body: messageContent,
          },
        ],
        recipients: recipients && recipients[ALL_CONTACTS] ? recipients[ALL_CONTACTS].map((ind) => ({ employee: ind.id })) : [],
        workGroups: recipients && recipients[WORK_GROUPS] ? recipients[WORK_GROUPS].map((wg) => ({ id: wg.id })) : [],
        shifts: recipients && recipients[SHIFTS] ? recipients[SHIFTS].map((sh) => ({ id: sh.id })) : [],
        rosterMessage: rosterMessage,
        selectedBattalion: selectedBattalionId,
        date: date,
      };
      resetFields();

      try {
        const sentConversation = await handleCreateConversation(newConversation);
        setLoading(false);
        setSelectedConversation(sentConversation);
        setResetRecipients(!resetRecipients);
        if (setOpenAudience) {
          setOpenAudience(true);
        }
        refetchConversations();
      } catch (error) {
        setSelectedConversation(null);
        setLoading(false);
        if (setOpenAudience) {
          setOpenAudience(false);
        }
        captureException(error);
      }
    }
  }, [
    category,
    conversation?.id,
    refetchConversations,
    handleCreateConversation,
    handleUpdateConversation,
    link,
    messageContent,
    resetRecipients,
    resetFields,
    setOpenAudience,
    setSelectedConversation,
    subject,
    recipients,
    date,
    rosterMessage,
    selectedBattalionId,
  ]);

  useEffect(() => {
    if (!openAudience) {
      // NOTE: if audience is closed, reset fields. otherwise, we risk showing
      // recipients from the previously selected conversation when  composing
      // a new conversation
      resetFields();
    }
    // clears the editor when there is content in it and a different conversation is selected
    if (messageContent) {
      clearEditor();
    }
    // this eslint disable is on purpose to avoid excessive re-rendering
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openAudience, conversation]);

  return (
    <Drawer
      PaperProps={{
        sx: {
          display: 'flex',
          flexDirection: 'column',
          overflowY: 'hidden',
          zIndex: theme.zIndex.tooltip,
          width: openAudience ? (rosterMessage ? '671px' : '33%') : '671px',
          mr: openAudience ? (rosterMessage ? '470px' : '935px') : rosterMessage ? '0px' : '500px',
        },
      }}
      open={openDrawer}
      anchor="right"
      variant="persistent"
    >
      <DrawerHeader
        categories={categories}
        category={conversation?.category ? conversation?.category : category}
        setCategory={setCategory}
        handleOnCloseDrawer={handleOnCloseDrawer}
        onCancelConversation={onCancelConversation}
        conversationId={conversation ? conversation.id : undefined}
        subject={conversation ? conversation.subject : 'New message'}
        isCancelled={!!(conversation && conversation.cancelledAt && conversation.cancelledBy && conversation.cancelledNote)}
      />
      <ConversationInformation
        link={conversation?.link ? conversation.link : link}
        subject={conversation ? conversation.subject : subject}
        setSubject={setSubject}
        isEditable={!conversation}
        setLink={setLink}
        setRecipients={setRecipients}
        recipients={recipients}
        resetRecipients={resetRecipients}
        conversationRecipients={conversation?.recipients}
        conversationWorkGroups={conversation?.workGroups}
        conversationTeam={conversation?.team}
        rosterMessage={rosterMessage}
      />
      <Box
        sx={{
          height: maxHeightMessageContainer,
          maxHeight: maxHeightMessageContainer,
          overflowY: 'auto',
        }}
      >
        {conversation?.cancelledAt && conversation.cancelledAt !== null && conversation.cancelledBy && (
          <CancelConversationBanner
            cancelDate={conversation?.cancelledAt.toString() || ''}
            cancelPerson={conversation?.cancelledBy}
            cancelNote={conversation?.cancelledNote}
          />
        )}
        <Box
          sx={{
            height: conversation || loading ? '65%' : '0px',
            maxHeight: conversation || loading ? '65%' : '0px',
            overflowY: 'auto',
            overflowX: 'hidden',
          }}
        >
          {loading && <SendingMessageSkeleton />}
          {conversation?.messages?.length &&
            conversation.messages.length > 0 &&
            conversation.messages.map((messageItem, index) => (
              <BubbleMessage
                message={messageItem.body}
                key={`message_${index}`}
                author={messageItem.sender.firstName + ' ' + messageItem.sender.lastName}
                messageDateTime={messageItem.sentAt}
              />
            ))}
        </Box>
        {!conversation?.cancelledAt && (
          <Box
            sx={{
              height: !conversation && !loading ? '100%' : '35%',
              maxHeight: !conversation && !loading ? '100%' : '35%',
              overflowY: 'auto',
              overflowX: 'hidden',
              boxShadow:
                conversation && !loading
                  ? '0px -4px 6px -2px rgba(10, 14, 22, 0.05), 0px -10px 15px -3px rgba(10, 14, 22, 0.1)'
                  : 'unset',
            }}
          >
            <TextEditor
              placeholder={(() => {
                if (!conversation && !loading) {
                  return 'Write your message';
                } else if (loading) {
                  return '';
                } else {
                  return 'Write your follow up message';
                }
              })()}
              messageContent={messageContent}
              setMessageContent={setMessageContent}
            />
          </Box>
        )}
      </Box>
      {openEmbedLink && (
        <EmbedLink
          link={tempLink}
          setLink={setTempLink}
          handleOnCloseEmbedLink={handleOnCloseEmbedLink}
          handleOnSaveLink={handleOnSaveLink}
        />
      )}
      {!conversation && (
        <Tooltip
          title="Embed link"
          arrow
          slotProps={{
            tooltip: {
              sx: {
                bgcolor: theme.palette.gray[800],
                fontFamily: 'Inter',
                fontSize: '14px',
                fontWeight: 500,
                lineHeight: '20px',
                '& .MuiTooltip-arrow': {
                  color: theme.palette.gray[800],
                },
              },
            },
          }}
        >
          <Box
            data-testid="link-icon"
            sx={(theme) => ({
              cursor: 'pointer',
              position: 'absolute',
              bottom: '12px',
              left: '220px',
              zIndex: theme.zIndex.mobileStepper,
            })}
            onClick={handleOnEmbedLink}
          >
            <Link03Icon16 />
          </Box>
        </Tooltip>
      )}
      {!conversation?.cancelledAt && (
        <ConversationButtons
          loading={loading}
          handleOnCloseDrawer={handleOnCloseDrawer}
          handleSubmitClick={sendMessage}
          disableSubmit={!messageContent || (!conversation && (!subject || (!rosterMessage && isSelectedRecipientsEmpty)))}
          isFollowUp={conversation !== null}
          rosterMessage={rosterMessage}
        />
      )}
    </Drawer>
  );
};
