import { Box, Typography } from '@mui/material';
import { BigArrowRightIcon } from '@stationwise/component-module';

interface ShiftDetailsProps {
  startDate: string;
  endDate: string;
  startTime: string;
  endTime: string;
}

export const ShiftDetails = ({ startDate, endDate, startTime, endTime }: ShiftDetailsProps) => {
  return (
    <Box
      sx={(theme) => ({
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        gap: theme.spacing(1),
      })}
    >
      <Box
        sx={(theme) => ({
          display: 'flex',
          alignItems: 'center',
          gap: theme.spacing(1),
          color: theme.palette.gray[900],
        })}
      >
        <Typography
          variant="bodyMRegular"
          sx={(theme) => ({
            color: theme.palette.gray[900],
          })}
        >
          {startDate}
        </Typography>
        <BigArrowRightIcon />
        <Typography
          variant="bodyMRegular"
          sx={(theme) => ({
            color: theme.palette.gray[900],
          })}
        >
          {' '}
          {endDate}
        </Typography>
      </Box>
      <Box
        sx={(theme) => ({
          display: 'flex',
          alignItems: 'center',
          gap: theme.spacing(1),
          color: theme.palette.gray[900],
        })}
      >
        <Typography
          variant="bodyMRegular"
          sx={(theme) => ({
            color: theme.palette.gray[400],
            textAlign: 'right',
          })}
        >
          {startTime} - {endTime}
        </Typography>
      </Box>
    </Box>
  );
};
