import { Box } from '@mui/material';
import { StaffingPatternsHeader } from './StaffingPatternsHeader';
import { StaffingPatternsPanel } from './StaffingPatternsPanel';

export const StaffingPatterns = () => {
  return (
    <Box sx={{ display: 'flex', height: '100%' }}>
      <Box sx={{ width: '100%', height: '100%', position: 'relative', overflowY: 'auto', scrollbarGutter: 'stable' }}>
        <StaffingPatternsHeader />
        <StaffingPatternsPanel />
      </Box>
    </Box>
  );
};
