import { useMemo, useState, useEffect } from 'react';
import { HiringEngineVacancy } from '@stationwise/share-types';

export interface VacancyFilters {
  selectedBattalions: number[];
  selectedStations: string[];
  selectedApparatuses: string[];
  vacantBattalions: number[];
  vacantStations: string[];
  vacantApparatuses: string[];
  filteredVacancies: Record<number, HiringEngineVacancy[]>;
  setSelectedBattalions: (battalions: number[]) => void;
  setSelectedStations: (stations: string[]) => void;
  setSelectedApparatuses: (apparatuses: string[]) => void;
}

export const useVacancyFilters = (groupedVacancies: Record<number, HiringEngineVacancy[]>): VacancyFilters => {
  const [selectedBattalions, setSelectedBattalions] = useState<number[]>([]);
  const [selectedStations, setSelectedStations] = useState<string[]>([]);
  const [selectedApparatuses, setSelectedApparatuses] = useState<string[]>([]);

  const vacantStations = useMemo(() => {
    const allStations = Object.values(groupedVacancies).flatMap((vacancies) => vacancies.map((vacancy) => vacancy.stationName));
    return Array.from(new Set(allStations));
  }, [groupedVacancies]);

  const vacantApparatuses = useMemo(() => {
    const allApparatuses = Object.values(groupedVacancies).flatMap((vacancies) =>
      vacancies
        .filter((vacancy) => selectedStations.length === 0 || selectedStations.includes(vacancy.stationName))
        .map((vacancy) => vacancy.apparatusName),
    );
    return Array.from(new Set(allApparatuses));
  }, [groupedVacancies, selectedStations]);

  const vacantBattalions = useMemo(() => [], []);

  // Reset apparatus selection when stations change
  useEffect(() => {
    setSelectedApparatuses((prev) => {
      const validApparatuses = new Set(vacantApparatuses);
      return prev.filter((apparatus) => validApparatuses.has(apparatus));
    });
  }, [vacantApparatuses]);

  const filteredVacancies = useMemo(() => {
    const newFiltered: Record<number, HiringEngineVacancy[]> = {};

    Object.entries(groupedVacancies).forEach(([rankId, vacancies]) => {
      const filtered = vacancies.filter((vacancy) => {
        const stationMatch = selectedStations.length === 0 || selectedStations.includes(vacancy.stationName);
        const apparatusMatch = selectedApparatuses.length === 0 || selectedApparatuses.includes(vacancy.apparatusName);
        return stationMatch && apparatusMatch;
      });

      if (filtered.length > 0) {
        newFiltered[Number(rankId)] = filtered;
      }
    });

    return newFiltered;
  }, [groupedVacancies, selectedStations, selectedApparatuses]);

  return {
    selectedBattalions,
    selectedStations,
    selectedApparatuses,
    vacantBattalions,
    vacantStations,
    vacantApparatuses,
    filteredVacancies,
    setSelectedBattalions,
    setSelectedStations,
    setSelectedApparatuses,
  };
};
