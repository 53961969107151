import { Typography, Box } from '@mui/material';
import QRCode from 'react-qr-code';
import { LoginWithQRProps } from '../types';
import { LoginOptionCard } from './LoginOptionCard';

export const LoginWithQR = ({ activationCode, activationUrl }: LoginWithQRProps) => {
  const children = (
    <Box sx={{ mt: 1 }}>
      <Typography
        sx={(theme) => ({
          color: theme.palette.gray[900],
          typography: 'bodyLRegular',
          textAlign: 'center',
          maxWidth: 320,
          mb: 3,
        })}
      >
        Scan this QR code with your mobile camera to log in instantly
      </Typography>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <QRCode value={activationUrl + '?code=' + activationCode} size={160} />
      </Box>
    </Box>
  );
  return (
    <LoginOptionCard
      height={360}
      width={626}
      borderRadius={'16px'}
      stepIndicatorTitle={{ number: '1', text: 'QR CODE' }}
      title="Log in with QR code"
      mb={6}
    >
      {children}
    </LoginOptionCard>
  );
};
