import { Theme } from '@mui/material';

export interface GetComponentColorsProps {
  theme: Theme;
  color?: string | null;
  dark?: boolean;
}

export const getPalette = ({ theme, color: colorName }: GetComponentColorsProps) => {
  switch ((colorName || 'gray').toLowerCase()) {
    case 'rose':
    case 'red':
      return theme.palette.red;
    case 'pink':
      return theme.palette.pink;
    case 'orange':
      return theme.palette.orange;
    case 'gold':
    case 'yellow':
      return theme.palette.electric;
    case 'teal':
    case 'green':
      return theme.palette.green;
    case 'cyan':
      return theme.palette.cyan;
    case 'lightblue':
      return theme.palette.lightBlue;
    case 'blue':
      return theme.palette.blue;
    case 'indigo':
      return theme.palette.indigo;
    case 'violet':
    case 'purple':
      return theme.palette.purple;
    case 'gray':
    default:
      return theme.palette.gray;
  }
};

export const getRankColors = ({ theme, color: colorName, dark = false }: GetComponentColorsProps) => {
  const palette = getPalette({ theme, color: colorName });
  const values = {
    background: dark ? palette[900] : palette[100],
    color: dark ? palette[300] : palette[800],
  };
  switch (palette) {
    case theme.palette.electric:
      values.background = dark ? palette[800] : palette[300];
      values.color = dark ? palette[200] : palette[800];
      return values;
    case theme.palette.indigo:
    case theme.palette.purple:
      values.color = dark ? palette[200] : palette[800];
      return values;
    case theme.palette.gray:
      values.background = dark ? palette[700] : palette[100];
      return values;
    default:
      return values;
  }
};

export const getCertColors = ({ theme, color: colorName, dark = false }: GetComponentColorsProps) => {
  const palette = getPalette({ theme, color: colorName });
  const values = {
    background: dark ? 'transparent' : theme.palette.common.white,
    color: dark ? palette[300] : palette[500],
  };
  switch (palette) {
    case theme.palette.red:
    case theme.palette.pink:
    case theme.palette.orange:
      values.color = dark ? palette[400] : palette[500];
      return values;
    case theme.palette.electric:
      values.color = dark ? palette[300] : palette[700];
      return values;
    case theme.palette.green:
    case theme.palette.cyan:
      values.color = dark ? palette[400] : palette[600];
      return values;
    case theme.palette.gray:
      values.color = dark ? palette[300] : palette[600];
      return values;
    default:
      return values;
  }
};

export const getTeamColors = ({ theme, color: colorName, dark = false }: GetComponentColorsProps) => {
  const palette = getPalette({ theme, color: colorName });
  const values = {
    background: dark ? palette[400] : palette[500],
    color: dark ? theme.palette.gray[900] : theme.palette.common.white,
  };
  switch (palette) {
    case theme.palette.electric:
      values.background = dark ? palette[300] : palette[600];
      return values;
    case theme.palette.green:
    case theme.palette.gray:
      values.background = dark ? palette[300] : palette[500];
      return values;
    default:
      return values;
  }
};

export const getAvatarColors = ({ theme, color: colorName, dark = false }: GetComponentColorsProps) => {
  const palette = getPalette({ theme, color: colorName });
  const values = getTeamColors({ theme, color: colorName, dark });
  switch (palette) {
    case theme.palette.cyan:
      values.background = dark ? palette[400] : palette[700];
      return values;
    default:
      return values;
  }
};
