import { ReactComponent as ArrowForwardIosSymbol } from '@material-symbols/svg-400/outlined/arrow_forward_ios.svg';
import { Box, ButtonBase } from '@mui/material';
import React from 'react';
import { SvgIcon } from '@stationwise/component-module';

interface TitleProps {
  title: string;
  onClick?: () => void;
}

export const Title = ({ title, onClick }: TitleProps) => {
  return (
    <Box
      sx={{
        display: 'flex',
        width: '100%',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '14px 24px',
      }}
    >
      <Box sx={{ typography: 'bodyMSemibold' }}>{title}</Box>
      <ButtonBase
        sx={{
          borderRadius: '360px',
        }}
      >
        <Box
          sx={(theme) => ({
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            borderRadius: '360px',
            width: '40px',
            height: '40px',
            border: `1px solid ${theme.palette.gray[200]}`,
            cursor: 'pointer',
            '&:hover': {
              backgroundColor: theme.palette.gray[50],
            },
          })}
          onClick={onClick}
        >
          <SvgIcon
            component={ArrowForwardIosSymbol}
            sx={(theme) => ({
              height: '16px',
              width: '16px',
              color: theme.palette.gray[500],
            })}
          />
        </Box>
      </ButtonBase>
    </Box>
  );
};
