import { Box, Theme, useMediaQuery } from '@mui/material';
import { useDocumentTitle } from '@stationwise/component-module';
import { SubNavigation } from '../../../components/Navigation/SubNavigation/SubNavigation';
import { AppPageLayout } from '../../AppPageLayout';
import { PersonalTabs } from '../PersonalTabs';
import { CalendarContent } from './components/CalendarContent';

export const Calendar = () => {
  useDocumentTitle('Calendar');
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

  const content = (
    <Box sx={{ height: '100%', width: '100%', maxWidth: '100%', margin: '0 auto' }}>
      <SubNavigation />
      <CalendarContent />
    </Box>
  );

  return <AppPageLayout>{isMobile ? content : <PersonalTabs defaultTab="calendar">{content}</PersonalTabs>}</AppPageLayout>;
};
