import { Box } from '@mui/material';
import { isValid, startOfDay } from 'date-fns';
import { useEffect, useState } from 'react';
import { DatePicker, useRosterContext } from '@stationwise/component-module';
import { getPythonWeekday } from '@stationwise/shift-summary-helper';
import { IPerformanceDates } from '../..';
import { Switch, WeekdayPicker } from '../../../../../../../components/Common';

export const PerformanceDates = ({
  performanceDates,
  setNextDisabled,
  completeStep,
  setPerformanceDates,
}: {
  performanceDates: IPerformanceDates;
  setNextDisabled: (disabled: boolean) => void;
  completeStep: () => void;
  setPerformanceDates: (performanceDates: IPerformanceDates) => void;
}) => {
  const { shiftSummaryHelper } = useRosterContext();
  const { shiftDuration } = shiftSummaryHelper;
  const currentDayNumber = getPythonWeekday(shiftDuration.startTime);

  const startDate = shiftDuration.startTime;
  const [endDate, setEndDate] = useState<Date | null>(performanceDates.endDate);
  const [isRecurring, setIsRecurring] = useState<boolean>(() => startOfDay(performanceDates.endDate) > startOfDay(startDate));
  const [repeatOn, setRepeatOn] = useState<number[]>(performanceDates.repeatOn);

  const getDisabledTooltipText = (dayNumber: number) => {
    return dayNumber !== currentDayNumber ? '' : 'The date you are creating the event must be included';
  };

  const handleIsRecurringChange = () => {
    setIsRecurring(!isRecurring);
    setRepeatOn(isRecurring ? [] : [currentDayNumber]);
  };

  const handleRepeatOnClick = (dayNumber: number) => {
    if (repeatOn.includes(dayNumber)) {
      setRepeatOn(repeatOn.filter((value) => value !== dayNumber));
    } else {
      setRepeatOn([...repeatOn, dayNumber]);
    }
  };

  useEffect(() => {
    if (endDate && isValid(endDate) && endDate >= startDate && (!isRecurring || repeatOn.length > 0)) {
      setNextDisabled(false);
      setPerformanceDates({ endDate, repeatOn });
      completeStep();
    } else {
      setNextDisabled(true);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDate, endDate, isRecurring, repeatOn]);

  return (
    <Box
      sx={(theme) => ({
        mt: theme.spacing(2.5),
        width: '428px',
        maxWidth: '100%',
      })}
    >
      <Box
        sx={(theme) => ({
          pb: theme.spacing(1),
        })}
      >
        <Box
          display="flex"
          sx={(theme) => ({
            width: '126px',
            height: '44px',
            border: `1px solid ${theme.palette.gray[200]}`,
            borderRadius: '8px',
          })}
        >
          <Box sx={(theme) => ({ typography: 'bodySRegular', my: '10px', pl: theme.spacing(1.5) })}>Recurring</Box>
          <Box sx={{ mt: '14px', ml: '10px' }}>
            <Switch checked={isRecurring} onChange={handleIsRecurringChange} />
          </Box>
        </Box>
      </Box>
      {isRecurring && (
        <>
          <Box sx={(theme) => ({ typography: 'bodySMedium', mb: theme.spacing(0.5) })}>Repeat on</Box>
          <WeekdayPicker
            activeWeekdays={repeatOn}
            getDisabledTooltipText={getDisabledTooltipText}
            onSelect={handleRepeatOnClick}
            sx={(theme) => ({ mb: theme.spacing(2.5) })}
          />
        </>
      )}
      <Box sx={(theme) => ({ typography: 'bodySMedium', mb: theme.spacing(0.5) })}>Start date</Box>
      <Box sx={(theme) => ({ mb: theme.spacing(2.5) })}>
        <DatePicker disabled value={startDate} sx={{ width: '100%' }} />
      </Box>
      <Box sx={(theme) => ({ typography: 'bodySMedium', mb: theme.spacing(0.5) })}>End date</Box>
      <Box>
        <DatePicker
          onChange={(newValue) => setEndDate(newValue)}
          value={endDate}
          minDate={startDate}
          disabled={!isRecurring}
          sx={{ width: '100%' }}
        />
      </Box>
    </Box>
  );
};
