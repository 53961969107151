import { Box, Pagination, Link as MuiLink } from '@mui/material';
import { captureException } from '@sentry/react';
import { format } from 'date-fns';
import React, { useDeferredValue, useEffect, useRef, useState } from 'react';
import { CustomTable, LetterAvatar, TableProps, SearchAndFilter, LottieLogo } from '@stationwise/component-module';
import { client, isAxiosError } from '@stationwise/share-api';
import { capitalize, camelCaseToTitle, snakeCaseToTitle } from '@stationwise/share-utils';
import { Activity, getTypeColor } from '../utils';

interface FilterOptions {
  [key: string]: string[];
}

export const ActivityHistoryMain: React.FC = () => {
  const [activities, setActivities] = useState<Activity[]>([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [searchInput, setSearchInput] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [filterOptions, setFilterOptions] = useState<FilterOptions>({});
  const [selectedFilters, setSelectedFilters] = useState<Record<string, string>>({});
  const abortControllerRef = useRef<AbortController | null>(null);
  const deferredSearchInput = useDeferredValue(searchInput);

  useEffect(() => {
    const fetchFilterOptions = async () => {
      try {
        const response = await client.get('organization/activity-feed/filter_options/');
        if (response.data) {
          setFilterOptions(response.data);
        }
      } catch (error) {
        captureException(error);
      }
    };
    fetchFilterOptions();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      abortControllerRef.current?.abort();
      abortControllerRef.current = new AbortController();
      try {
        const filterParams = Object.fromEntries(Object.entries(selectedFilters).filter(([_, value]) => value));
        const params = {
          page: page,
          latest: true,
          ...(deferredSearchInput && { search: deferredSearchInput }),
          ...filterParams,
        };

        const response = await client.get('organization/activity-feed/', {
          params,
          signal: abortControllerRef.current.signal,
        });
        if (response.data.results && Array.isArray(response.data.results)) {
          setActivities(response.data.results.filter((activity: Activity) => activity.target != null));
          setTotalPages(Math.ceil(response.data.count / 100));
        }
        setIsLoading(false);
      } catch (error) {
        const isCanceled = isAxiosError(error) && error.code === 'ERR_CANCELED';
        !isCanceled && captureException(error);
        !isCanceled && setIsLoading(false);
      }
    };
    fetchData();
  }, [page, deferredSearchInput, selectedFilters]);

  const handlePageChange = (_event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };

  const handleFilterChange = (filterName: string, value: string) => {
    setPage(1);
    setSelectedFilters((prev) => ({ ...prev, [filterName]: value }));
  };

  const handleSearchChange = (value: string) => {
    setPage(1);
    setSearchInput(value);
  };

  const filters = Object.entries(filterOptions).map(([filterName, options]) => ({
    name: camelCaseToTitle(capitalize(filterName)),
    options: options.map((option) => ({
      label: snakeCaseToTitle(option),
      value: capitalize(option),
    })),
    selected: selectedFilters[filterName],
    onChange: (value: string) => handleFilterChange(filterName, value),
  }));

  const columns: TableProps['columns'] = [
    { id: 'name', label: 'USER', minWidth: 200, align: 'left' },
    { id: 'action', label: 'ACTION', minWidth: 300 },
    { id: 'timestamp', label: 'TIMESTAMP', minWidth: 150, align: 'center' },
    { id: 'type', label: 'TYPE', minWidth: 10, align: 'center' },
    { id: 'comments', label: 'COMMENTS', minWidth: 10, align: 'center' },
    { id: 'url', label: 'URL', minWidth: 10, align: 'right' },
  ];

  const data: TableProps['data'] = activities.map((activity) => ({
    name: (
      <Box
        sx={(theme) => ({
          display: 'flex',
          gap: theme.spacing(1),
          alignItems: 'center',
        })}
      >
        <LetterAvatar firstName={activity.name} color="lightblue" />
        {activity.name}
      </Box>
    ),

    action:
      activity.target?.subject && activity.target?.action.includes(activity.target?.subject) ? (
        <Box sx={(theme) => ({ color: theme.palette.gray[600], typography: 'bodySRegular' })}>
          {activity.target?.action.substring(0, activity.target?.action.indexOf(activity.target?.subject))}{' '}
          <Box component="span" sx={{ fontWeight: 600 }}>
            {activity.target?.subject}
          </Box>{' '}
          {activity.target?.action.substring(
            activity.target?.action.indexOf(activity.target?.subject) + activity.target?.subject.length,
          )}
        </Box>
      ) : (
        <Box sx={(theme) => ({ color: theme.palette.gray[600], typography: 'bodySRegular' })}>{activity.target?.action}</Box>
      ),
    timestamp: format(activity.timestamp, 'MMM dd, HH:mm aaa').replace(/am|pm/i, (match) => match.toUpperCase()),
    type: (
      <Box
        sx={(theme) => ({
          backgroundColor: getTypeColor(activity.activityType),
          color: theme.palette.common.white,
          borderRadius: '20px',
          padding: theme.spacing(0.25, 1),
          typography: 'bodySRegular',
        })}
      >
        {activity.activityType}
      </Box>
    ),
    url: activity.target?.url ? (
      <MuiLink href={activity.target.url} target="_blank" rel="noopener noreferrer">
        See Details
      </MuiLink>
    ) : (
      'N/A'
    ),
    comments: (
      <Box
        sx={{
          opacity: activity.comments ? undefined : '20%',
          textAlign: 'left',
          typography: 'bodySRegular',
          whiteSpace: 'pre',
        }}
      >
        {activity.comments || 'N/A'}
      </Box>
    ),
  }));

  const clearAllFilters = () => {
    setSelectedFilters({});
  };

  return (
    <Box>
      <Box sx={{ px: 5, pt: 4, pb: 8 }}>
        <Box component="h1" sx={{ m: 0, typography: 'heading4' }}>
          Change Log
        </Box>
      </Box>
      <Box sx={{ minWidth: '550px', width: '80%', px: 5, mx: 'auto' }}>
        <SearchAndFilter
          searchText={searchInput}
          setSearchText={handleSearchChange}
          filters={filters}
          clearAllFilters={clearAllFilters}
          searchPlaceholder="Search for people or comments"
        />
        {!isLoading ? (
          <CustomTable columns={columns} data={data} />
        ) : (
          <Box display="flex" alignItems="center" justifyContent="center" sx={{ height: '100%', width: '100%' }}>
            <LottieLogo height="200px" width="200px" />
          </Box>
        )}
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        {totalPages > 1 && !isLoading && <Pagination count={totalPages} page={page} onChange={handlePageChange} sx={{ my: 2 }} />}
      </Box>
    </Box>
  );
};
