import {
  Alert,
  Autocomplete,
  Box,
  Dialog,
  FormControl,
  FormControlLabel,
  FormHelperText,
  MenuItem,
  Switch,
  TextField,
  Typography,
} from '@mui/material';
import { useState } from 'react';
import {
  Button,
  CertBadge,
  getRankColors,
  SnackbarService,
  theme,
  useLoadedDepartmentInfoContext,
} from '@stationwise/component-module';
import { client, isAxiosError } from '@stationwise/share-api';
import { AllowedColors, allowedColorsList, Certification, RankComplete } from '@stationwise/share-types';

interface RankModalProps {
  certifications: Certification[];
  onSaveSuccess: () => void;
  payPeriodTypes: { value: string; label: string }[];
  selectedRank?: RankComplete;
  setShowModal: (show: boolean) => void;
  showModal: boolean;
  maxSortOrder: number;
}

const newRank: RankComplete = {
  id: -1,
  name: '',
  code: '',
  color: 'gray' as AllowedColors,
  sortOrder: 0,
  isOfficer: false,
  payPeriodType: 'MONTHLY',
  canActCertification: null,
  defaultCertifications: [],
};

export const RankModal = ({
  certifications,
  onSaveSuccess,
  payPeriodTypes,
  selectedRank,
  setShowModal,
  showModal,
  maxSortOrder,
}: RankModalProps) => {
  const {
    state: { departmentInfo },
    dispatch,
  } = useLoadedDepartmentInfoContext();
  const existingRankCodes = departmentInfo.ranks
    .map((rank) => rank.code.toLowerCase())
    .filter((rank) => rank !== selectedRank?.code.toLowerCase());
  const colorItems = allowedColorsList.map((color) => ({
    label: color.slice(0, 1).toUpperCase() + color.slice(1),
    value: color,
    color: color,
  }));
  const title = selectedRank ? 'Edit Rank Details' : 'Create New Rank';
  const [rank, setRank] = useState<RankComplete>(selectedRank ? selectedRank : newRank);
  const [canActCertEnabled, setCanActCertEnabled] = useState(!!selectedRank?.canActCertification);
  const [error, setError] = useState({ duplicateCode: false });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const disabled = !rank.code || Object.values(error).some((e) => e) || isSubmitting || !rank.name.trim();

  const handleClose = () => {
    setIsSubmitting(false);
    setShowModal(false);
    setRank(newRank);
  };

  const handleSuccess = () => {
    handleClose();
    onSaveSuccess();
    dispatch({ type: 'REFETCH_DEPARTMENT_INFO' }); // this will update the DeparmentInfo.rank data
  };

  const handleSave = async () => {
    setIsSubmitting(true);
    const rankData = {
      ...rank,
      defaultCertificationIds: rank.defaultCertifications?.map((cert) => cert.id) || [],
    };

    if (selectedRank) {
      //  UPDATE
      try {
        const response = await client.put(`/organization/ranks/${rank.id}/`, rankData);
        SnackbarService.notify({
          content: `Successfully updated Rank: ${response.data.name}`,
          severity: 'success',
          duration: 2500,
        });
        handleSuccess();
      } catch (error) {
        SnackbarService.notify({
          content: `Failed to save Rank: ${isAxiosError(error) ? error.message : ''}`,
          severity: 'error',
          duration: 5000,
        });
        handleClose();
      }
    } else {
      // CREATE
      try {
        const response = await client.post('/organization/ranks/', { ...rankData, sortOrder: maxSortOrder + 10 });
        SnackbarService.notify({
          content: `Successfully added new Rank: ${response.data.name}`,
          severity: 'success',
          duration: 5000,
        });
        handleSuccess();
      } catch (error) {
        SnackbarService.notify({
          content: `Failed to save new Rank: ${isAxiosError(error) ? error.message : ''}`,
          severity: 'error',
          duration: 5000,
        });
        handleClose();
      }
    }
  };

  return (
    <Dialog open={showModal} onClose={handleClose}>
      <Box
        sx={{
          display: 'flex',
          width: '100%',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Box
          sx={(theme) => ({
            backgroundColor: theme.palette.common.white,
            justifyContent: 'center',
            borderRadius: theme.spacing(1.5),
            p: theme.spacing(3),
            width: '496px',
            display: 'flex',
            flexDirection: 'column',
            '.MuiFormControl-root': {
              mb: 4,
              '.MuiInputLabel-root': {
                top: '-5px',
              },
            },
          })}
        >
          <Box
            sx={{
              typography: 'bodyXLSemibold',
              mb: 2,
            }}
          >
            {title}
          </Box>

          {!selectedRank && (
            <Alert severity="info" sx={(theme) => ({ mb: 3, backgroundColor: theme.palette.blue[200] })}>
              {"Please check your hierarchy by clicking 'edit rank order' button after creating a new rank."}
            </Alert>
          )}

          <TextField
            label="Name"
            onChange={(event) => {
              setRank({ ...rank, name: event.currentTarget.value });
            }}
            required
            value={rank.name}
            data-cy="name"
            error={!rank.name?.trim()}
          />

          <TextField
            error={error.duplicateCode}
            label="Code"
            helperText={error.duplicateCode ? 'This code already exists.' : ''}
            onChange={(event) => {
              setRank({ ...rank, code: event.currentTarget.value });
              if (existingRankCodes.includes(event.currentTarget.value.toLowerCase())) {
                setError({ ...error, duplicateCode: true });
              } else if (error.duplicateCode) {
                setError({ ...error, duplicateCode: false });
              }
            }}
            required
            value={rank.code}
            data-cy="code"
          />

          <TextField
            label="Color"
            onChange={(event) => {
              setRank({ ...rank, color: event.target.value as AllowedColors });
            }}
            select
            value={rank.color}
          >
            {colorItems.map((color) => (
              <MenuItem key={color.value} value={color.value}>
                <Typography
                  sx={{
                    color: getRankColors({ theme, color: String(color.label), dark: false }).color,
                    textAlign: 'left',
                    paddingRight: 1,
                  }}
                >
                  {color.label}
                </Typography>
              </MenuItem>
            ))}
          </TextField>

          <Box sx={{ mb: canActCertEnabled ? 0 : 2, '.MuiFormControl-root': { mb: 2 } }}>
            <FormControl>
              <FormControlLabel
                control={
                  <Switch
                    checked={canActCertEnabled}
                    onChange={() => {
                      setCanActCertEnabled(!canActCertEnabled);
                      if (canActCertEnabled) {
                        setRank({ ...rank, canActCertification: null });
                      }
                    }}
                  />
                }
                label="Allows acting out of rank?"
                sx={(theme) => ({
                  color: theme.palette.gray[700],
                  ml: 0,
                  '> div': { mr: 2 },
                })}
              />
              <FormHelperText>Choose this option if other ranks are able to act as this one in your department.</FormHelperText>
            </FormControl>
          </Box>

          {canActCertEnabled && (
            <FormControl>
              <Autocomplete
                options={certifications}
                getOptionLabel={(option) => option.name}
                value={rank.canActCertification}
                onChange={(_event, value) => {
                  setRank({ ...rank, canActCertification: value });
                }}
                renderInput={(params) => <TextField {...params} label='"Act as" Certification' />}
                sx={{
                  '.MuiFormControl-root': {
                    mb: 0,
                    '.MuiInputLabel-root:not(.Mui-focused)': {
                      top: 0,
                    },
                  },
                }}
              />
              <FormHelperText>{'An Employee can "Act Up" into this Rank when they hold this Certification'}</FormHelperText>
            </FormControl>
          )}

          <Box sx={{ mb: 2 }}>
            <FormControl>
              <FormControlLabel
                control={
                  <Switch
                    checked={rank.isOfficer}
                    onChange={() => {
                      setRank({ ...rank, isOfficer: !rank.isOfficer });
                    }}
                  />
                }
                label="Is Officer?"
                sx={(theme) => ({
                  color: theme.palette.gray[700],
                  ml: 0,
                  '> div': { mr: 2 },
                })}
              />
              <FormHelperText>
                We recommend enabling this for every officer rank, typically starting from Lieutenant or Captain and up.
              </FormHelperText>
            </FormControl>
          </Box>

          <TextField
            label="Pay Period"
            disabled={!!selectedRank}
            onChange={(event) => {
              setRank({ ...rank, payPeriodType: event.target.value });
            }}
            select
            value={rank.payPeriodType ?? ''}
          >
            {payPeriodTypes.map((ppt) => (
              <MenuItem key={ppt.value} value={ppt.value}>
                {ppt.label}
              </MenuItem>
            ))}
          </TextField>

          <FormControl>
            <Autocomplete
              data-cy="default-certifications-autocomplete"
              multiple
              options={certifications}
              getOptionLabel={(option) => option.name}
              value={rank.defaultCertifications ?? []}
              onChange={(_event, value) => {
                setRank({ ...rank, defaultCertifications: value });
              }}
              renderInput={(params) => <TextField {...params} label="Default Certifications" />}
              renderTags={(tagValue) => tagValue.map((option) => <CertBadge key={option.code} cert={option} />)}
              renderOption={(props, option) => (
                <li {...props} data-value={option.name}>
                  {option.name}
                </li>
              )}
              sx={{
                '.MuiFormControl-root': {
                  mb: 1,
                  '.MuiInputLabel-root:not(.Mui-focused)': {
                    top: 0,
                  },
                },
              }}
            />
            <FormHelperText>Certifications that are automatically granted to employees with this rank</FormHelperText>
          </FormControl>
          <Box
            sx={{
              justifyContent: 'space-between',
              display: 'flex',
              width: '100%',
            }}
          >
            <Button variant="outlined" size="large" sx={{ width: '216px' }} onClick={() => handleClose()}>
              Cancel
            </Button>
            <Button
              data-cy="save-rank-button"
              variant="contained"
              size="large"
              sx={{ width: '216px' }}
              onClick={() => handleSave()}
              disabled={disabled}
              loading={isSubmitting}
            >
              Save
            </Button>
          </Box>
        </Box>
      </Box>
    </Dialog>
  );
};
