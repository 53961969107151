import { Box, useTheme, Typography, Theme } from '@mui/material';
import {
  CheckIcon16 as CompletedIcon,
  Mail01Icon16 as MailIcon,
  CheckCircleBrokenIcon16,
  TeamFlag,
  useLoadedAuthUserContext,
} from '@stationwise/component-module';
import { ConversationListView } from '@stationwise/share-types';
import { shortenEmployeeName } from '@stationwise/share-utils';
import { UpdateMessage } from './UpdateMessage';
import { CONVERSATION_CATEGORIES } from './constants';
import { getMessageAge } from './messageHelper';

export const getCategoryColor = (category: string, theme: Theme) => {
  switch (category) {
    case CONVERSATION_CATEGORIES.GENERAL:
      return theme.palette.lightBlue[500];
    case CONVERSATION_CATEGORIES.TO_DO:
      return theme.palette.purple[500];
    default:
      return theme.palette.gray[500];
  }
};
export const getCategoryColoredIcon = (category: string, theme: Theme) => {
  const categoryColor = getCategoryColor(category, theme);
  switch (category) {
    case CONVERSATION_CATEGORIES.TO_DO:
      return <CheckCircleBrokenIcon16 style={{ color: categoryColor }} />;
    default:
      return <MailIcon style={{ color: categoryColor }} />;
  }
};

export const Message = ({
  conversation,
  updateConversation,
  selectConversation,
  refetchConversations,
}: {
  conversation: ConversationListView;
  updateConversation: (conversationId: number, toggleRead: boolean, markAsCompleted: boolean) => Promise<void>;
  selectConversation?: (msg: ConversationListView) => void;
  refetchConversations?: () => void;
}) => {
  const theme = useTheme();
  const { state: authUserState } = useLoadedAuthUserContext();
  const conversationRecipient = conversation.recipients.find((r) => `${r.employee.id}` === authUserState.employee.id);
  const isRead = !authUserState.employee.unreadMessages.includes(conversation.id);
  const isCompleted = conversationRecipient?.isCompleted;

  const handleSelectConversation = () => {
    if (selectConversation) {
      selectConversation(conversation);

      if (!isRead) {
        updateConversation(conversation.id, true, false);
      }
    }
  };

  return (
    <Box
      sx={{
        pt: theme.spacing(2),
        pb: theme.spacing(2),
      }}
    >
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <TeamFlag color={conversation.messages[0].sender.team?.color} />
          <Box onClick={handleSelectConversation}>
            <Typography
              variant="bodyMRegular"
              sx={{
                fontWeight: isRead ? 400 : 600,
                color: isRead ? theme.palette.gray[600] : theme.palette.gray[900],
                pl: theme.spacing(1),
              }}
            >
              {shortenEmployeeName(`${conversation.messages[0].sender.firstName} ${conversation.messages[0].sender.lastName}`)}
            </Typography>
            <Typography variant="bodyMRegular" sx={{ color: theme.palette.gray[400], pl: theme.spacing(1) }}>
              {conversation.messages[0].sender.rank.name}
            </Typography>
          </Box>
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <UpdateMessage
            refetchConversations={refetchConversations}
            updateConversation={updateConversation}
            conversation={conversation}
          />
        </Box>
      </Box>

      <Box data-cy="conversation-subject" onClick={handleSelectConversation} sx={{ pt: theme.spacing(1), display: 'flex' }}>
        <Typography
          variant="bodyMRegular"
          sx={{ fontWeight: isRead ? 400 : 600, color: isRead ? theme.palette.gray[600] : theme.palette.gray[900] }}
        >
          {conversation.subject}
        </Typography>
      </Box>

      <Box
        onClick={handleSelectConversation}
        className="message-body"
        sx={(theme) => ({
          pt: theme.spacing(0.5),
          color: theme.palette.gray[500],
          overflow: 'hidden',
          whiteSpace: 'pre-wrap',
        })}
      >
        <Typography
          variant="bodyMRegular"
          sx={{
            '&:after':
              conversation.messages[0].bodyPlainText.length > 80
                ? {
                    position: 'absolute',
                    width: '15px',
                    marginRight: '0px',
                    content: '"..."',
                  }
                : 'unset',
            fontWeight: isRead ? 400 : 500,
            color: isRead ? theme.palette.gray[500] : theme.palette.gray[900],
          }}
        >
          {conversation.messages[0].bodyPlainText.length > 80
            ? conversation.messages[0].bodyPlainText.slice(0, 80)
            : conversation.messages[0].bodyPlainText}
        </Typography>
      </Box>

      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', mt: theme.spacing(1) }}>
        <Box
          sx={(theme) => ({
            right: !isRead ? '34px' : theme.spacing(2),
            color: !isRead ? theme.palette.gray[500] : theme.palette.gray[400],
            pt: theme.spacing(1),
          })}
        >
          <Typography
            sx={{
              fontWeight: isRead ? 400 : 500,
              color: !isRead ? theme.palette.gray[600] : theme.palette.gray[400],
            }}
            variant="bodySRegular"
          >
            {getMessageAge(conversation.messages[0].sentAt)}
          </Typography>
        </Box>

        <Box display="flex" alignItems="center" justifyContent="space-between">
          {conversation.category === CONVERSATION_CATEGORIES.TO_DO && (
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              sx={(theme) => ({
                height: '28px',
                borderRadius: '28px',
                border: isCompleted ? 'none' : `1px dashed ${theme.palette.green[500]}`,
                background: isCompleted ? theme.palette.green[500] : 'inherit',
                px: theme.spacing(1),
                mr: theme.spacing(1),
                mt: theme.spacing(1.5),
              })}
            >
              {isCompleted && <CompletedIcon style={{ color: theme.palette.common.white }} />}
              <Typography
                variant="bodySMedium"
                sx={{
                  color: isCompleted ? theme.palette.common.white : theme.palette.green[500],
                  ml: theme.spacing(0.5),
                }}
              >
                {isCompleted ? 'Complete' : 'Incomplete'}
              </Typography>
            </Box>
          )}

          <Box
            sx={(theme) => ({
              color: theme.palette.gray[500],
              borderRadius: theme.spacing(1),
              border: `1px solid ${theme.palette.gray[200]}`,
              textAlign: 'center',
              boxShadow: '0 0 4px rgba(0, 0, 0, 0.1)',
              height: '28px',
              p: '6px',
              pt: '1px',
              mt: theme.spacing(1.5),
            })}
          >
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              {getCategoryColoredIcon(conversation.category, theme)}
              <Typography
                variant="bodyMMedium"
                sx={{
                  color: getCategoryColor(conversation.category, theme),
                  textTransform: 'uppercase',
                  pl: theme.spacing(0.5),
                }}
              >
                {conversation.category}
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
