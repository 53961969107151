import { Box, SxProps, Theme, useTheme } from '@mui/material';
import { Rank } from '@stationwise/share-types';
import { getRankColors } from '../../utils';

interface RankBadgeProps {
  rank: Pick<Rank, 'name' | 'code' | 'color'>;
  rankField?: 'name' | 'code';
  maxLength?: number;
  dark?: boolean;
  sx?: SxProps<Theme>;
}

export const RankBadge = (props: RankBadgeProps) => {
  const theme = useTheme();
  const { rank, rankField = 'code', maxLength = 12, dark = false, sx } = props;

  if (!rank) {
    return;
  }
  return (
    <Box
      sx={[
        {
          ...getRankColors({ theme, color: rank.color, dark }),
          borderRadius: '10px',
          display: 'inline-flex',
          padding: '2px 6px',
          typography: 'tagline1',
          textWrap: 'nowrap',
        },
        ...(Array.isArray(sx) ? sx : sx ? [sx] : []),
      ]}
    >
      {rank[rankField].slice(0, maxLength)}
    </Box>
  );
};
