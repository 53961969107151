import { ReactComponent as CancelOutlinedSymbol } from '@material-symbols/svg-400/outlined/cancel.svg';
import { Box } from '@mui/material';
import { LetterAvatar, SvgIcon } from '@stationwise/component-module';
import { ConversationCancellerEmployee } from '@stationwise/share-types';

export interface BannerProps {
  cancelDate: string;
  cancelPerson: ConversationCancellerEmployee;
  cancelNote?: string;
}

export const Banner = ({ cancelDate, cancelNote, cancelPerson }: BannerProps) => {
  return (
    <Box
      sx={(theme) => ({
        display: 'flex',
        flexDirection: 'column',
        color: theme.palette.yellow[700],
        padding: '16px 24px',
        gap: '16px',
        background: theme.palette.yellow[50],
      })}
    >
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Box sx={{ display: 'flex' }}>
          <Box sx={{ display: 'flex', gap: 1, typography: 'tagline1' }}>
            <SvgIcon
              component={CancelOutlinedSymbol}
              sx={{
                height: '16px',
                width: '16px',
                stroke: 'currentColor',
              }}
            />
            CANCELLED WITH A NOTE
          </Box>
        </Box>
        <Box sx={{ display: 'flex', gap: 1, typography: 'bodySRegular' }}>
          <LetterAvatar size="xxsmall" firstName={cancelPerson.firstName} color="red" />
          <Box sx={{ typography: 'bodySMedium' }}>
            {cancelPerson.firstName} {cancelPerson.lastName}
          </Box>
          {cancelDate}
        </Box>
      </Box>
      <Box
        sx={(theme) => ({
          height: `1px solid ${theme.palette.yellow[100]}`,
        })}
      />
      <Box sx={{ typography: 'bodySRegular' }}>{cancelNote}</Box>
    </Box>
  );
};
