import { Box } from '@mui/material';
import { ErrorPage, LottieLogo, useDocumentTitle, useFetchRosterData } from '@stationwise/component-module';
import { AppPageLayout } from '../../../AppPageLayout';
import { ScheduleContent } from './ScheduleContent';

export const ScheduleMain = () => {
  useDocumentTitle('Roster');
  const {
    isLoading,
    isError,
    selectedBattalionState,
    isErrorStaffingLists,
    shiftSummary,
    staffingLists,
    currentDate,
    forceRefetchAll,
    forceRefetchHiringEngine,
    administrationStations,
    eventStations,
    temporaryNonShiftStation,
    dataEmployeesOff,
    requestVolunteers,
    autoHireInfo,
    isLoadingHiringEngine,
    isErrorHiringEngine,
    instantHires,
  } = useFetchRosterData();

  const renderContent = () => {
    const hasAllData = !!(
      shiftSummary &&
      administrationStations &&
      eventStations &&
      temporaryNonShiftStation &&
      dataEmployeesOff
    );
    if (isLoading && !hasAllData) {
      return (
        <Box display="flex" alignItems="center" justifyContent="center" sx={{ height: '100%', width: '100%' }}>
          <LottieLogo height="200px" width="200px" />
        </Box>
      );
    }

    if (isError || isErrorHiringEngine || !hasAllData) {
      return <ErrorPage />;
    }

    return (
      <ScheduleContent
        selectedBattalionState={selectedBattalionState}
        shiftSummary={shiftSummary}
        administrationStations={administrationStations}
        eventStations={eventStations}
        temporaryNonShiftStation={temporaryNonShiftStation}
        currentDate={currentDate}
        staffingListsResponse={{ data: staffingLists, isError: isErrorStaffingLists }}
        forceRefetch={forceRefetchAll}
        employeesOff={dataEmployeesOff}
        hiringEngineResponse={{
          isLoading: isLoadingHiringEngine,
          requestVolunteers: requestVolunteers,
          autoHireInfo: autoHireInfo,
          instantHires: instantHires,
          forceRefetchHiringEngine: forceRefetchHiringEngine,
        }}
      />
    );
  };

  return (
    <AppPageLayout className="SWSchedule-main">
      <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%', width: '100%' }}>{renderContent()}</Box>
    </AppPageLayout>
  );
};
