import { Navigate, useLocation } from 'react-router-dom';
import { getDepartmentFeatureFlagValue, useLoadedDepartmentInfoContext } from '@stationwise/component-module';
import { ShiftTemplateMain } from './components/ShiftTemplateMain';

export const ShiftTemplate = () => {
  const { state: departmentInfoState } = useLoadedDepartmentInfoContext();
  const location = useLocation();
  if (getDepartmentFeatureFlagValue(departmentInfoState, 'SHIFT_PLAN')) {
    return <Navigate to="/app/shift-planning" state={location.state} />;
  }

  return <ShiftTemplateMain />;
};
