import { EventInput } from '@fullcalendar/core';
import { EventImpl } from '@fullcalendar/core/internal';
import { Box, useMediaQuery } from '@mui/material';
import { format } from 'date-fns';
import React from 'react';
import { Button, SHIFT_OVERVIEW_OPTIONS, STATUS_TITLES, StatusBadge, theme } from '@stationwise/component-module';
import { getStatus } from '../../../../../../../app/pages/Calendar/components/RequestSharedComponents/StatusCard';
import { determineDateRange } from '../../../../../../../app/pages/Calendar/components/ShiftOverview/ShiftOverviewOptions';
import { EventCardLine } from './EventCardLine';
import { EventCardTitleSection } from './EventCardTitleSection';
import { executeSelectedAction, findDurationAsHourAndMinute, processTitle } from './EventCardUtils';
import { EventCardWrapper } from './EventCardWrapper';

export const TimeOffRequestEventCard = ({
  shift,
  setClickedEventAsCalendarSelectedElement,
  openActionModal,
  handleShiftCardOverviewClose,
}: {
  shift: EventInput | EventImpl;
  setClickedEventAsCalendarSelectedElement?: (
    event: React.MouseEvent<HTMLElement>,
    shift: EventInput | EventImpl,
    design?: boolean,
  ) => void;
  openActionModal?: (option: string) => void;
  handleShiftCardOverviewClose?: () => void;
}) => {
  const isSmallScreen = useMediaQuery('(max-width:400px)');
  const { hour: durationHour, minute: durationMin } = findDurationAsHourAndMinute(shift);
  const isApproved = (shift as EventInput).status === STATUS_TITLES.APPROVED;
  const isDenied = (shift as EventInput).status === STATUS_TITLES.DENIED;
  const isPending = !isApproved && !isDenied;
  const status = getStatus((shift as EventInput).status);
  const dateRange = determineDateRange(shift);
  const processedTitle = processTitle(shift, false);
  const titleColor = isPending ? theme.palette.green[800] : theme.palette.common.white;

  return (
    <EventCardWrapper>
      <EventCardTitleSection
        title={processedTitle}
        backgroundColor={shift.backgroundColor}
        titleColor={titleColor}
        truncate
        maxLength={isSmallScreen ? 32 : 37}
      />

      <Box
        sx={(theme) => ({
          display: 'flex',
          px: theme.spacing(2),
          flexDirection: 'column',
          alignItems: 'flex-start',
          alignSelf: 'stretch',
          '&:last-child': {
            borderBottom: 'none',
          },
        })}
      >
        <EventCardLine
          title={'Start Date'}
          value={shift.start ? format(shift.start as Date, 'MMM d, HH:mm') : '-'}
        ></EventCardLine>
        <EventCardLine title={'End Date'} value={shift.end ? format(shift.end as Date, 'MMM d, HH:mm') : '-'}></EventCardLine>

        <EventCardLine
          title={'Duration'}
          value={shift.end && shift.start ? durationHour + ' hrs ' + durationMin + ' mins' : '-'}
        ></EventCardLine>

        <EventCardLine
          title={'Pay Code'}
          value={
            shift.extendedProps && shift.extendedProps['eventPayCodes'] && shift.extendedProps['eventPayCodes'][0]
              ? shift.extendedProps['eventPayCodes'][0].code + ' | ' + shift.extendedProps['eventPayCodes'][0].name
              : '-'
          }
        ></EventCardLine>
        <EventCardLine title={'Status'} value={status ? <StatusBadge status={status} /> : '-'}></EventCardLine>
        {!isPending && (
          <EventCardLine title={'Note'} value={shift.extendedProps ? shift.extendedProps['eventNote'] : '-'}></EventCardLine>
        )}
        {!isPending && (
          <EventCardLine
            title={isApproved ? 'Approved by' : 'Denied by'}
            value={shift.extendedProps ? shift.extendedProps['eventReviewedBy'] : '-'}
          ></EventCardLine>
        )}

        {!isPending && (
          <EventCardLine
            title={isApproved ? 'Approval Date' : 'Denial Date'}
            value={shift.extendedProps ? shift.extendedProps['eventReviewedAt'] : '-'}
          ></EventCardLine>
        )}

        {!isPending && (
          <EventCardLine
            isLastLine={!((isPending || isApproved) && dateRange === 'future')}
            title={'Review Note'}
            value={shift.extendedProps ? shift.extendedProps['reviewNote'] : '-'}
          ></EventCardLine>
        )}
      </Box>
      <Box
        sx={(theme) => ({
          display: 'flex',
          p: openActionModal ? theme.spacing(2) : '',
          gap: theme.spacing(1),
          alignSelf: 'stretch',
          alignItems: 'center',
        })}
      >
        {(isPending || isApproved) && dateRange === 'future' && openActionModal && (
          <Button
            sx={(theme) => ({
              display: 'flex',
              py: theme.spacing(0.75),
              px: theme.spacing(2),
              justifyContent: 'center',
              alignItems: 'center',
              flex: '1 0 0',
            })}
            data-cy="cancel-time-off-request-button"
            color="error"
            size="medium"
            variant="outlined"
            onClick={(event: React.MouseEvent<HTMLElement>) => {
              executeSelectedAction(
                SHIFT_OVERVIEW_OPTIONS.CANCEL_EVENT,
                event,
                shift,
                setClickedEventAsCalendarSelectedElement,
                openActionModal,
                handleShiftCardOverviewClose,
              );
            }}
          >
            Cancel Request
          </Button>
        )}
      </Box>
    </EventCardWrapper>
  );
};
