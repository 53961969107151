import { Box, TextField } from '@mui/material';
import { memo } from 'react';
import { ItemLayout } from './ItemLayout';

export const Subject = memo(
  ({ subject, setSubject, isEditable }: { subject: string; setSubject: (subject: string) => void; isEditable: boolean }) => {
    return (
      <ItemLayout>
        <Box width={'100%'}>
          <TextField
            data-cy="message-subject"
            InputProps={{
              disableUnderline: true,
              style: {
                fontWeight: !isEditable ? 600 : 400,
                marginTop: '-9px',
              },
            }}
            value={subject}
            onChange={(event) => {
              setSubject(event.currentTarget.value);
            }}
            variant="standard"
            fullWidth
            inputProps={{
              maxLength: 78,
            }}
            disabled={!isEditable}
            placeholder="SUBJECT"
            sx={(theme) => ({
              '& .MuiInputBase-input.Mui-disabled': {
                WebkitTextFillColor: theme.palette.gray[900],
              },
            })}
          />
        </Box>
      </ItemLayout>
    );
  },
);

Subject.displayName = 'Memo(Subject)';
