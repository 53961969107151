import { Box, InputAdornment, Theme, useMediaQuery } from '@mui/material';
import { FormEvent, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { ArrowLeftIcon20, CircleWarningIcon20, StationwiseTextLogo } from '../../../assets';
import { useDocumentTitle } from '../../../hooks';
import { Button } from '../../Button';
import { ErrorBanner } from '../../ErrorBanner';
import { Input } from '../../Input';
import { Logo } from '../../Logo';

export const SSOPage = () => {
  useDocumentTitle('Single Sign-On Login');
  const [email, setEmail] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const isMobileApp = useMediaQuery((theme: Theme) => theme.breakpoints.down('lg'));

  useEffect(() => {
    if (!isMobileApp) {
      window.location.href = '/sso/login';
    }
  }, [isMobileApp]);

  if (!isMobileApp) {
    return null;
  }

  const handleSubmit = async () => {
    if (isSubmitting) {
      return;
    }

    setIsSubmitting(true);
    window.location.href = `/sso/login?email=${encodeURIComponent(email)}`;
    setIsSubmitting(false);
  };

  const handleFormSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    await handleSubmit();
  };

  return (
    <Box
      sx={(theme) => ({
        [theme.breakpoints.up('sm')]: {
          backgroundColor: theme.palette.gray[200],
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          height: '100vh',
          overflow: 'auto',
        },
      })}
    >
      <Box
        component="form"
        onSubmit={handleFormSubmit}
        sx={(theme) => ({
          backgroundColor: theme.palette.common.white,
          px: 2,
          py: 4,
          [theme.breakpoints.up('sm')]: {
            m: 'auto',
            px: 12,
            pt: 6,
            pb: 11,
          },
        })}
      >
        <Box sx={{ mx: 'auto', width: 328, maxWidth: '100%' }}>
          <Box
            sx={(theme) => ({
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              gap: 1,
              mb: 5,
              [theme.breakpoints.up('sm')]: {
                mb: 9,
              },
            })}
          >
            <Logo />
            <StationwiseTextLogo />
          </Box>
          <Box
            component="h1"
            sx={(theme) => ({
              color: theme.palette.gray[900],
              mt: 0,
              mb: 4,
              typography: 'heading4',
              textAlign: 'center',
              [theme.breakpoints.up('sm')]: {
                fontSize: '32px',
                mb: 6,
              },
            })}
          >
            Single Sign-On
          </Box>
          {!isSubmitting && !!errorMessage && <ErrorBanner sx={{ mt: -1, mb: 2 }}>{errorMessage}</ErrorBanner>}
          <Box sx={{ mb: 3 }}>
            <Input
              data-cy="email"
              disabled={isSubmitting}
              error={!!errorMessage}
              label="Email"
              autoComplete="email"
              placeholder="you@example.gov"
              type="text"
              value={email}
              onChange={(event) => {
                setEmail(event.currentTarget.value);
                setErrorMessage('');
              }}
              endAdornment={
                !errorMessage ? undefined : (
                  <InputAdornment position="end">
                    <CircleWarningIcon20 />
                  </InputAdornment>
                )
              }
            />
          </Box>
          <Button
            data-cy="submit-login-form"
            variant="contained"
            size="large"
            type="submit"
            disabled={!email}
            loading={isSubmitting}
            sx={{ width: '100%' }}
          >
            Sign in
          </Button>
          <Button size="large" component={Link} to="/" startIcon={<ArrowLeftIcon20 />} sx={{ mt: 2.5, width: '100%' }}>
            Back to log in
          </Button>
        </Box>
      </Box>
    </Box>
  );
};
