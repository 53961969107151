import { EventInput } from '@fullcalendar/core';
import { EventImpl } from '@fullcalendar/core/internal';
import { Box, Typography } from '@mui/material';
import { captureException } from '@sentry/react';
import { differenceInDays, formatISO, startOfDay } from 'date-fns';
import { Dispatch, useEffect, useMemo, useState } from 'react';
import {
  Button,
  ChevronRightIcon24,
  Loader,
  useLoadedAuthUserContext,
  useLoadedDepartmentInfoContext,
  XCircleIcon24,
  STATUS_TITLES,
  isTimeOffEvent,
} from '@stationwise/component-module';
import { client } from '@stationwise/share-api';
import { RequestOverview as IRequestOverview, Waitlist } from '@stationwise/share-types';
import { TimeOffWaitlist } from '../TimeOffRequest/TimeOffWaitlist';
import { WaitlistWarning } from '../TimeOffRequest/warning/WaitlistWarning';
import { OverviewCard } from './OverviewCard';
import { getOverviewArgs } from './RequestOverview';
import { StatusCard } from './StatusCard';
import { useFetchRequestOverview } from './hooks/useFetchRequestOverview';

interface RequestOverviewContentProps {
  handleOnClose: () => void;
  eventColor?: string;
  shift: EventInput | EventImpl;
  setShowCancelModal: Dispatch<React.SetStateAction<boolean>>;
}

export const RequestOverviewContent = ({ setShowCancelModal, handleOnClose, shift }: RequestOverviewContentProps) => {
  const overviewInfo = useMemo(() => getOverviewArgs(shift), [shift]);
  const { isLoading, isError, error, data: overview } = useFetchRequestOverview<IRequestOverview>(overviewInfo.fetchUrl);
  const [showWaitlist, setShowWaitlist] = useState(false);
  const [waitlist, setWaitlist] = useState<Waitlist | null>(null);
  const { state: authUserState } = useLoadedAuthUserContext();
  const {
    state: { departmentInfo },
  } = useLoadedDepartmentInfoContext();

  const isStatusValid = overview
    ? ![STATUS_TITLES.APPROVED, STATUS_TITLES.DENIED, STATUS_TITLES.USER_CANCELLED].includes(overview.status)
    : false;

  let isCancellableApprovedTimeOff = false;
  if (isTimeOffEvent(shift) && overview) {
    const cancellationNoticeDays = departmentInfo.settings.timeOffCancellationNoticeDays;
    const today = startOfDay(new Date());
    const shiftDate = startOfDay(shift.start as Date);
    const daysUntilShift = differenceInDays(shiftDate, today);
    isCancellableApprovedTimeOff =
      daysUntilShift >= cancellationNoticeDays && ![STATUS_TITLES.DENIED, STATUS_TITLES.USER_CANCELLED].includes(overview.status);
  }

  useEffect(() => {
    if (!departmentInfo.settings.timeOffWaitlistEnabled || !overview) return;
    if (overview.payCode?.payCodeType !== 'TIME_OFF') return;
    if (!shift.start) return;
    const shiftDate = formatISO(shift.start as Date, { representation: 'date' });
    const fetchWaitlist = async () => {
      try {
        const response = await client.get('/request/time-off-waitlist/', {
          params: {
            date: shiftDate,
            rank: authUserState.employee.rank.id,
            payCode: overview.payCode.code,
          },
        });
        setWaitlist(response.data === '' ? null : response.data);
      } catch (error) {
        captureException(error);
      }
    };
    fetchWaitlist();
  }, [shift, overview, authUserState.employee.rank.id, departmentInfo.settings.timeOffWaitlistEnabled]);

  const handleCancelRequest = () => {
    setShowCancelModal(true);
  };

  return (
    <Box>
      <TimeOffWaitlist setOpen={setShowWaitlist} isOpen={showWaitlist} waitlist={waitlist} />
      {isLoading && (
        <Box
          sx={(theme) => ({
            p: theme.spacing(3),
            mt: theme.spacing(3),
          })}
        >
          <Loader />
        </Box>
      )}
      {isError && <div>{`Something went wrong please try again later ${error}}`}</div>}
      {!isLoading && !isError && overview && (
        <Box
          sx={(theme) => ({
            p: `0 ${theme.spacing(2)}`,
          })}
        >
          <OverviewCard overview={overview} eventColor={shift.backgroundColor} />
          <Box sx={(theme) => ({ mt: theme.spacing(2) })}>
            <StatusCard
              overview={overview}
              eventColor={shift.backgroundColor}
              waitlist={waitlist}
              employee={authUserState.employee}
            />
            {isStatusValid && <WaitlistWarning setOpen={setShowWaitlist} waitlist={waitlist} alreadyOnWaitlist={true} />}
          </Box>
          {isCancellableApprovedTimeOff || isStatusValid ? (
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              onClick={() => handleCancelRequest()}
              sx={(theme) => ({
                pb: theme.spacing(2),
                pt: theme.spacing(4),
              })}
            >
              <Box display="flex" data-cy="cancel-request-button">
                <Box
                  sx={(theme) => ({
                    color: theme.palette.rose[400],
                  })}
                >
                  <XCircleIcon24 />
                </Box>
                <Box sx={(theme) => ({ ml: theme.spacing(2) })}>
                  <Typography
                    variant="bodyLMedium"
                    sx={(theme) => ({
                      color: theme.palette.rose[600],
                    })}
                  >
                    Cancel request
                  </Typography>
                </Box>
              </Box>

              <Box sx={(theme) => ({ color: theme.palette.gray[400] })}>
                <ChevronRightIcon24 />
              </Box>
            </Box>
          ) : (
            <Box sx={(theme) => ({ p: `${theme.spacing(2)} 0` })}>
              <Button onClick={handleOnClose} variant="outlined" size="large" sx={{ width: '100%' }}>
                Close
              </Button>
            </Box>
          )}
        </Box>
      )}
    </Box>
  );
};
