import { Box, DialogTitle, useTheme } from '@mui/material';
import { useEffect, useState } from 'react';
import {
  User01Icon24,
  MultipleSelect,
  AdminSelect,
  SnackbarService,
  getCertColors,
  useLoadedDepartmentInfoContext,
} from '@stationwise/component-module';
import { nowrap } from '@stationwise/share-utils';
import { useShiftPlanContext } from '../ShiftPlanContext';
import { IconCircle, ShiftPlanDialogActions } from '../ShiftPlanDialog';

export const UpsertPositionForm = () => {
  const theme = useTheme();
  const { state: departmentInfoState } = useLoadedDepartmentInfoContext();
  const { selectedStruct, setSelectedAction, saveAsDraft } = useShiftPlanContext();
  const { station: selectedStation, apparatus: selectedApparatus, position: selectedPosition } = selectedStruct;

  const [rankCode, setRankCode] = useState(selectedPosition?.rank.code || '');
  const [certCodes, setCertCodes] = useState(selectedPosition?.certifications.map((c) => c.code) || []);

  const rankOptions = departmentInfoState.departmentInfo.ranks.map((r) => ({ label: r.name, value: r.code }));

  const certOptions = departmentInfoState.departmentInfo.certifications.map((c) => {
    const { color } = getCertColors({ theme, color: c.color });
    return { label: c.name, value: c.code, color };
  });

  const isSaveDisabled = !rankCode;

  useEffect(() => {
    const selectedRank = departmentInfoState.departmentInfo.ranks.find((r) => r.code === rankCode);

    if (selectedRank?.defaultCertifications.length) {
      const newCerts = selectedRank.defaultCertifications.filter((cert) => !certCodes.includes(cert.code)).map((c) => c.code);

      setCertCodes([...certCodes, ...newCerts]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rankCode]);

  const onSave = () => {
    const rank = departmentInfoState.departmentInfo.ranks.find((r) => r.code === rankCode);
    const certifications = departmentInfoState.departmentInfo.certifications.filter((c) => certCodes.includes(c.code));
    if (selectedStation && selectedApparatus && rank) {
      const payload = {
        action: 'UPSERT_POSITION',
        positionId: selectedPosition?.id,
        apparatusId: selectedApparatus.id,
        actingRankId: rank.id,
        startTime: 0,
        endTime: 1440,
        certificationRequirements: certifications.map((c) => ({ certificationId: c.id })),
      };
      saveAsDraft(payload, () => {
        setSelectedAction(null);
        if (!selectedPosition) {
          SnackbarService.notify({
            content: `Added ${nowrap(rank.code)} to ${nowrap(selectedApparatus.name)}`,
            severity: 'success',
            duration: 5000,
          });
        }
      });
    }
  };

  return (
    <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column', width: '495px', maxWidth: '100%', minHeight: '1px' }}>
      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', px: 3, pt: 3, pb: 2 }}>
        {!selectedPosition && (
          <IconCircle sx={{ color: theme.palette.indigo[600], mb: 3 }}>
            <User01Icon24 />
          </IconCircle>
        )}
        <DialogTitle sx={{ p: 0, textAlign: 'center' }}>
          <Box component="span" sx={{ display: 'block', typography: 'bodyXLSemibold' }}>
            {selectedPosition ? 'Edit position' : 'Add position'}
          </Box>
          {!selectedPosition && (
            <Box component="span" sx={{ display: 'block', mt: 0.5, typography: 'bodyMRegular' }}>
              {`to ${nowrap(selectedApparatus?.name || '')}`}
            </Box>
          )}
        </DialogTitle>
      </Box>
      <Box sx={{ flex: 1, minHeight: '1px', overflowY: 'auto', px: 3, pt: 1 }}>
        <Box sx={{ color: theme.palette.gray[700], typography: 'bodySMedium', mb: 1 }}>Rank</Box>
        <AdminSelect items={rankOptions} selectedItem={rankCode} setSelectedItem={setRankCode} placeholder="Choose rank" />
        <Box sx={{ color: theme.palette.gray[700], typography: 'bodySMedium', mt: 3, mb: 1 }}>Requirements</Box>
        <MultipleSelect
          items={certOptions}
          selectedItems={certCodes}
          setSelectedItems={setCertCodes}
          placeholder="Add certification"
        />
      </Box>
      <ShiftPlanDialogActions isSaveDisabled={isSaveDisabled} onSave={onSave} />
    </Box>
  );
};
