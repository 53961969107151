import { Box, DialogContent, DialogTitle } from '@mui/material';
import { captureException } from '@sentry/react';
import { isAxiosError } from 'axios';
import { isBefore, parseISO, startOfDay, startOfMonth, format } from 'date-fns';
import { useState, useEffect } from 'react';
import { asDepartmentDateTime, useLoadedDepartmentInfoContext, SnackbarService } from '@stationwise/component-module';
import { client } from '@stationwise/share-api';
import { ShiftPlanAction, ShiftPlanTeam, AllowedColors, ShiftPlanTeamType } from '@stationwise/share-types';
import { parseTeamPattern, booleanArrayToStringPattern } from '../../../helpers/parseTeamPattern';
import { useShiftPlanContext } from '../../ShiftPlanContext';
import { ShiftPlanDialog, ShiftPlanDialogActions } from '../../ShiftPlanDialog';
import { PatternDefinition } from './PatternDefinition';
import { PatternGeneralInfo } from './PatternGeneralInfo';
import { PatternPreview } from './PatternPreview';

const buildShiftPlanTeam = (today: Date) => {
  return {
    name: '',
    color: 'purple' as AllowedColors,
    id: 0,
    pattern: '1on,1off',
    patternStartDate: today.toISOString(),
    patternDates: [],
    teamType: ShiftPlanTeamType.SHIFT_TEAM,
  };
};

interface StaffingPatternDialogProps {
  selectedShiftPlanTeam: ShiftPlanTeam | null;
  initialMonth: Date;
  currentShiftPlanTeamNames: string[];
}

export const StaffingPatternDialog = ({
  selectedShiftPlanTeam,
  initialMonth,
  currentShiftPlanTeamNames,
}: StaffingPatternDialogProps) => {
  const { state: departmentInfoState } = useLoadedDepartmentInfoContext();
  const { selectedAction, setSelectedAction, setSavingAction, softRefetch } = useShiftPlanContext();
  const today = startOfDay(asDepartmentDateTime(departmentInfoState, new Date()));
  const initialShiftPlanTeam = buildShiftPlanTeam(today);
  const [shiftPlanTeam, setShiftPlanTeam] = useState<ShiftPlanTeam>(initialShiftPlanTeam);
  const [patternLength, setPatternLength] = useState<number>(1);
  const [patternDays, setPatternDays] = useState<{ day: number; checked: boolean }[]>([]);
  const minPatternStartDate = selectedShiftPlanTeam ? parseISO(selectedShiftPlanTeam.patternStartDate) : today;
  const [selectedMonth, setSelectedMonth] = useState(startOfMonth(today));
  const [error, setError] = useState({ duplicatedTeam: false });

  useEffect(() => {
    const currentShiftPlanTeam = selectedShiftPlanTeam || initialShiftPlanTeam;

    setShiftPlanTeam(currentShiftPlanTeam);
    // Team pattern will be null when the simple description isn't enough.
    if (currentShiftPlanTeam.pattern) {
      setPatternLength(parseTeamPattern(currentShiftPlanTeam.pattern).length || 1);
      setPatternDays(() => getPatternDaysFromPattern(currentShiftPlanTeam.pattern));
    } else {
      setPatternLength(0);
    }
    setSelectedMonth(initialMonth);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedShiftPlanTeam, selectedAction]);

  useEffect(() => {
    setPatternDays((prev) => {
      if (patternLength < prev.length) {
        return prev.slice(0, patternLength);
      }

      if (patternLength > prev.length) {
        const newDays = [...prev];

        for (let i = prev.length; i < patternLength; i++) {
          newDays.push({ day: i + 1, checked: false });
        }
        return newDays;
      }

      return prev;
    });
  }, [patternLength]);

  useEffect(() => {
    if (!selectedShiftPlanTeam && shiftPlanTeam.patternStartDate) {
      setSelectedMonth(parseISO(shiftPlanTeam.patternStartDate));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shiftPlanTeam.patternStartDate]);

  useEffect(() => {
    const newPattern = booleanArrayToStringPattern(patternDays.map((item) => item.checked));
    setShiftPlanTeam((prev) => ({ ...prev, pattern: newPattern }));
  }, [patternDays]);

  const getPatternDaysFromPattern = (pattern: string) => {
    const parsedTeamPattern = parseTeamPattern(pattern);
    return parsedTeamPattern.map((isActive, index) => ({ day: index + 1, checked: isActive }));
  };

  const saveShiftPlanTeam = async () => {
    setSavingAction(selectedAction);

    try {
      if (selectedShiftPlanTeam) {
        await client.put(`/shift/staffing-patterns/${selectedShiftPlanTeam?.id}/`, {
          name: shiftPlanTeam.name,
        });
      } else {
        await client.post(`/shift/staffing-patterns/`, {
          ...shiftPlanTeam,
          patternStartDate: format(parseISO(shiftPlanTeam.patternStartDate), 'yyyy-MM-dd'),
        });
      }
      await softRefetch();
      SnackbarService.notify({
        content: 'Staffing pattern was successfully saved.',
        severity: 'success',
        duration: 5000,
      });
    } catch (error) {
      const message = isAxiosError(error) ? error.response?.data?.error || error.message : `Error saving staffing pattern`;

      SnackbarService.notify({ content: message, severity: 'error' });
      captureException(error);
    } finally {
      setSelectedAction(null);
    }
  };

  const isDisabled = () => {
    return (
      error.duplicatedTeam ||
      (selectedShiftPlanTeam && !selectedShiftPlanTeam.name) ||
      (!selectedShiftPlanTeam &&
        (!shiftPlanTeam.name ||
          !shiftPlanTeam.pattern ||
          isBefore(parseISO(shiftPlanTeam.patternStartDate), minPatternStartDate) ||
          !shiftPlanTeam.color ||
          !shiftPlanTeam.teamType))
    );
  };

  return (
    <ShiftPlanDialog action={ShiftPlanAction.UPSERT_STAFFING_PATTERN} maxWidth="lg">
      <DialogTitle sx={{ typography: 'heading6' }}>
        {selectedShiftPlanTeam ? 'Edit staffing pattern' : 'Create staffing pattern'}
      </DialogTitle>
      <DialogContent>
        <Box>
          <Box
            sx={{
              justifyContent: 'center',
              borderRadius: '12px',
              display: 'flex',
              flexDirection: 'column',
              '.MuiFormControl-root': {
                mb: 2,
                width: '100%',
                '.MuiInputLabel-root': {
                  top: '-5px',
                },
              },
            }}
          >
            <PatternGeneralInfo
              shiftPlanTeam={shiftPlanTeam}
              setShiftPlanTeam={setShiftPlanTeam}
              error={error}
              setError={setError}
              selectedShiftPlanTeam={selectedShiftPlanTeam}
              currentShiftPlanTeamNames={currentShiftPlanTeamNames}
            />
            <PatternDefinition
              shiftPlanTeam={shiftPlanTeam}
              setShiftPlanTeam={setShiftPlanTeam}
              setPatternLength={setPatternLength}
              patternLength={patternLength}
              selectedShiftPlanTeam={selectedShiftPlanTeam}
              minPatternStartDate={minPatternStartDate}
              patternDays={patternDays}
              setPatternDays={setPatternDays}
            />
          </Box>
          <PatternPreview
            patternDays={patternDays}
            selectedShiftPlanTeam={selectedShiftPlanTeam}
            shiftPlanTeam={shiftPlanTeam}
            selectedMonth={selectedMonth}
            setSelectedMonth={setSelectedMonth}
          />
        </Box>
      </DialogContent>
      <ShiftPlanDialogActions isSaveDisabled={isDisabled()} onSave={saveShiftPlanTeam} />
    </ShiftPlanDialog>
  );
};
