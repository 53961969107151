import { Box } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import {
  RankBadge,
  CertBadge,
  CustomTable,
  TeamBadge,
  TableProps,
  useRosterContext,
  StaffingListMainControls,
  useStaffingListFilterGroup,
  Loader,
  ErrorBanner,
  HiringEngineStaffinListFilterPopover,
} from '@stationwise/component-module';
import { StaffingListItem, StaffingListItemEmployee, StaffingListEmployeeStatus } from '@stationwise/share-types';
interface EligibleEmployeesContentProps {
  setEligibleEmployees: (eligibleEmployees: StaffingListItemEmployee[]) => void;
}
export const EligibleEmployeesContent = ({ setEligibleEmployees }: EligibleEmployeesContentProps) => {
  const [filterPopoverAnchorEl, setFilterPopoverAnchorEl] = useState<HTMLElement | null>(null);
  const [selectedTeamOptions, setSelectedTeamOptions] = useState(new Set<string>());
  const [selectedBattalionOptions, setSelectedBattalionOptions] = useState(new Set<string>());
  const [selectedStationOptions, setSelectedStationOptions] = useState(new Set<string>());
  const filterStates = [
    useStaffingListFilterGroup(selectedTeamOptions, setSelectedTeamOptions),
    useStaffingListFilterGroup(selectedBattalionOptions, setSelectedBattalionOptions),
    useStaffingListFilterGroup(selectedStationOptions, setSelectedStationOptions),
  ];

  const { staffingListsState } = useRosterContext();
  const operators = staffingListsState.availableStaffingListItems;
  const eligibleEmployees = useMemo(() => {
    const filterStaffingListItems = (staffingListItems: StaffingListItem[]): StaffingListItemEmployee[] => {
      const filteredStaffingListItems = staffingListItems.filter((item) => {
        if (item.employee.status === StaffingListEmployeeStatus.UNAVAILABLE) {
          return false;
        }
        if (selectedTeamOptions.size && !selectedTeamOptions.has(item.employee.team?.name || '')) {
          return false;
        }
        if (selectedBattalionOptions.size && !selectedBattalionOptions.has(item.employee.battalion?.name || '')) {
          return false;
        }
        return true;
      });

      return filteredStaffingListItems.map((staffingListItem) => staffingListItem.employee);
    };
    return filterStaffingListItems(operators);
  }, [operators, selectedTeamOptions, selectedBattalionOptions]);

  useEffect(() => {
    setEligibleEmployees(eligibleEmployees);
  }, [eligibleEmployees, setEligibleEmployees]);

  const columns: TableProps['columns'] = [
    { id: 'name', label: 'Name', minWidth: 200, align: 'left' },
    { id: 'rank', label: 'Rank', minWidth: 64, align: 'center' },
    { id: 'certificates', label: 'Certificates', minWidth: 150, align: 'left' },
    { id: 'shift', label: 'Shift', minWidth: 150, align: 'left' },
  ];

  const data: TableProps['data'] = eligibleEmployees.map((employee) => ({
    name: (
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          typography: 'bodySMedium',
        }}
      >
        {employee.name}
      </Box>
    ),
    rank: <RankBadge rank={employee.rank} />,
    certificates: (
      <Box
        sx={(theme) => ({
          display: 'flex',
          gap: theme.spacing(1),
          typography: 'bodySMedium',
        })}
      >
        {employee.certifications.map((cert) => (
          <CertBadge key={cert.id} cert={cert} />
        ))}
      </Box>
    ),
    shift: (
      <TeamBadge
        team={{
          name: employee.team?.name || '',
          color: employee.team?.color || 'gray',
        }}
      />
    ),
  }));
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '828px',
      }}
    >
      <Box sx={{ display: 'flex', flexDirection: 'column', paddingTop: 1.5 }}>
        <StaffingListMainControls
          setFilterPopoverAnchorEl={setFilterPopoverAnchorEl}
          filterStates={filterStates}
          isHiringEngine={true}
        />
        <HiringEngineStaffinListFilterPopover
          items={operators}
          filterPopoverAnchorEl={filterPopoverAnchorEl}
          setFilterPopoverAnchorEl={setFilterPopoverAnchorEl}
          filterStates={filterStates}
        />

        {staffingListsState.isLoading && <Loader />}
        {!staffingListsState.isLoading && staffingListsState.isError && (
          <ErrorBanner>{'Unable to load staffing list.'}</ErrorBanner>
        )}
        {!staffingListsState.isLoading && !staffingListsState.isError && data.length === 0 && (
          <Box sx={{ p: 2, textAlign: 'center' }}>No eligible employees found</Box>
        )}
        {!staffingListsState.isLoading && !staffingListsState.isError && data.length > 0 && (
          <Box>{<CustomTable columns={columns} data={data} />}</Box>
        )}
      </Box>
    </Box>
  );
};
