import { Box, useTheme } from '@mui/material';
import { RankBadge, TeamBadge, useLoadedDepartmentInfoContext } from '@stationwise/component-module';
import { ShiftPlanAction, ShiftPlanCandidate, ShiftPlanAssignment } from '@stationwise/share-types';
import { shortenEmployeeName } from '@stationwise/share-utils';
import { formatAssignment, initializeAssignments } from '../../../helpers/readAssignments';
import { useShiftPlanContext } from '../../ShiftPlanContext';
import { WarningMessage, ShiftPlanDialogActions } from '../../ShiftPlanDialog';

interface RemoveAssignmentFormProps {
  person: ShiftPlanCandidate | ShiftPlanAssignment | null;
  setCandidateAssignments: (candidateId: string, assignments: ShiftPlanAssignment[]) => void;
}

export const RemoveAssignmentForm = ({ person, ...props }: RemoveAssignmentFormProps) => {
  const theme = useTheme();
  const { state: departmentInfoState } = useLoadedDepartmentInfoContext();
  const { departmentInfo } = departmentInfoState;
  const { selectedAction, setSelectedAction, saveAsDraft } = useShiftPlanContext();

  const assignment = person && 'employee' in person ? (person as ShiftPlanAssignment) : null;

  if (selectedAction !== ShiftPlanAction.REMOVE_ASSIGNMENT || !assignment) {
    return null;
  }

  const onSave = () => {
    const payload = { action: 'REMOVE_ASSIGNMENT', assignmentId: assignment.id };
    saveAsDraft(payload, (response) => {
      if (response.data.employeeAssignments) {
        props.setCandidateAssignments(
          assignment.employee.id,
          initializeAssignments(departmentInfo, response.data.employeeAssignments),
        );
      }
      setSelectedAction(null);
    });
  };

  const renderAssignment = () => {
    const assignmentTexts = formatAssignment(assignment);

    return (
      <Box
        sx={{
          border: `1px solid ${theme.palette.divider}`,
          background: theme.palette.gray[50],
          p: 1.5,
          textAlign: 'left',
          width: '100%',
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
          <RankBadge rank={assignment.employee.rank} />
          <Box sx={{ typography: 'bodySMedium' }}>{shortenEmployeeName(assignmentTexts.employee)}</Box>
        </Box>
        <Box sx={{ display: 'flex' }}>
          <TeamBadge team={assignment.team} sx={{ mt: 1, mb: 0.5 }} />
        </Box>
        {`Effective ${assignmentTexts.activationDate} - ${assignmentTexts.deactivationDate}`}
        <br />
        {`${assignmentTexts.location}, ${assignmentTexts.startTime} - ${assignmentTexts.endTime}`}
        <br />
        {`Pay codes: ${assignmentTexts.payCodes}`}
        <br />
        {`Detail codes: ${assignmentTexts.detailCodes}`}
      </Box>
    );
  };

  return (
    <>
      <WarningMessage primaryText="You are about to remove this assignment." secondaryText={renderAssignment()} />
      <ShiftPlanDialogActions saveText="Confirm" onSave={onSave} />
    </>
  );
};
