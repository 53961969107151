import { Box, useTheme } from '@mui/material';
import { useAuthUserCapabilities, useDocumentTitle, useLoadedAuthUserContext } from '@stationwise/component-module';
import { EmployeeTypes } from '@stationwise/share-types';
import { NoMatch } from '../../NoMatch';
import { PersonalStaffingListsContent } from './PersonalStaffingListsContent';

export const PersonalStaffingListsMain = () => {
  useDocumentTitle('Staffing Lists');
  const theme = useTheme();
  const canViewMyStaffingLists = useAuthUserCapabilities().VIEW_MY_STAFFING_LISTS;
  const { state: authUserState } = useLoadedAuthUserContext();
  const isNonShift = authUserState.employee.isNonShift;
  const isVolunteer = authUserState.employee.employeeType === EmployeeTypes.VOLUNTEER;
  const haveCapability = !isVolunteer && !isNonShift && canViewMyStaffingLists;
  return (
    <>
      {haveCapability && (
        <Box sx={{ background: theme.palette.common.white, height: '100%', width: '100%', maxWidth: '768px', margin: '0 auto' }}>
          <Box sx={{ background: theme.palette.common.white, p: theme.spacing(3.5, 2, 4.5, 2) }}>
            <Box component="h1" sx={{ typography: 'bodyXXLSemibold', m: theme.spacing(0, 0, 1, 0) }}>
              My Staffing Lists
            </Box>
            <PersonalStaffingListsContent />
          </Box>
        </Box>
      )}
      {!haveCapability && <NoMatch />}
    </>
  );
};
