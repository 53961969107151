import { ReactComponent as CloseSymbol } from '@material-symbols/svg-400/outlined/close.svg';
import { Box, ButtonBase } from '@mui/material';
import React from 'react';
import { SvgIcon } from '../../SvgIcon';

interface HeaderProps {
  title: string;
  onClose?: () => void;
}

export const Header = ({ title, onClose }: HeaderProps) => {
  return (
    <Box sx={{ display: 'flex', justifyContent: 'space-between', pt: 1 }}>
      <Box sx={(theme) => ({ color: theme.palette.gray[900], typography: 'bodyXLSemibold' })}>{title}</Box>
      <Box
        sx={{
          cursor: 'pointer',
          mt: -1,
        }}
        onClick={onClose}
      >
        <ButtonBase
          sx={{
            borderRadius: '50%',
          }}
        >
          <SvgIcon
            component={CloseSymbol}
            sx={(theme) => ({
              width: '24px',
              height: '24px',
              color: theme.palette.gray[400],
              borderRadius: '50%',
              '&:hover': {
                backgroundColor: theme.palette.gray[100],
              },
            })}
          />
        </ButtonBase>
      </Box>
    </Box>
  );
};
