import { Box, Checkbox, Typography, useTheme } from '@mui/material';
import { format } from 'date-fns';
import { ReactNode } from 'react';
import { Badge, CardCertBadges, CustomTable, TableProps, Button } from '@stationwise/component-module';
import { HiringEngineVacancy, Rank } from '@stationwise/share-types';

interface HiringEngineVacancyTableProps {
  groupedVacancies: HiringEngineVacancy[];
  selectedVacancies?: string[];
  onVacancySelect?: (vacancy: Pick<HiringEngineVacancy, 'id' | 'startDateTime'>) => void;
  onSelectAllRankGroup?: (selected: boolean) => void;
  vacancyGroupNumber?: number;
  hideCheckbox?: boolean;
  lastColumnLabel?: string;
  lastColumnValues?: ReactNode[];
  checkDisabled?: boolean;
  onEditCallOrderClick?: (rank: Rank) => void;
}

export const HiringEngineVacancyTable = ({
  groupedVacancies,
  vacancyGroupNumber,
  selectedVacancies,
  onVacancySelect,
  onSelectAllRankGroup: onSelectAll,
  hideCheckbox = true,
  lastColumnLabel,
  lastColumnValues,
  checkDisabled = false,
  onEditCallOrderClick,
}: HiringEngineVacancyTableProps) => {
  const theme = useTheme();
  const lastLabel = lastColumnLabel || 'Occupancy';

  const allSelected =
    groupedVacancies.length > 0 &&
    groupedVacancies.every((vacancy) => selectedVacancies?.includes(`${vacancy.id}_${vacancy.startDateTime.getTime()}`));

  const columns: TableProps['columns'] = [
    ...(!hideCheckbox
      ? [
          {
            id: 'checkbox',
            label: (
              <Checkbox
                data-cy="employee-checkbox"
                checked={allSelected}
                color="primary"
                onClick={() => onSelectAll?.(!allSelected)}
                disabled={checkDisabled}
              />
            ),
            width: 70,
          },
        ]
      : []),
    {
      id: 'battalion',
      label: 'Battalion',
      align: 'left',
      font: 'bodyMMedium',
      fontcolor: theme.palette.gray[900],
      width: 138,
    },
    {
      id: 'station',
      label: 'Station',
      width: 138,
      align: 'left',
      font: 'bodyMMedium',
      fontcolor: theme.palette.gray[900],
    },
    {
      id: 'apparatus',
      label: 'Apparatus',
      width: 138,
      align: 'left',
      font: 'bodyMMedium',
      fontcolor: theme.palette.gray[900],
    },
    {
      id: 'start_time',
      label: 'Start Time',
      width: 120,
      align: 'left',
      font: 'bodyMMedium',
      fontcolor: theme.palette.gray[900],
    },
    {
      id: 'end_time',
      label: 'End Time',
      width: 150,
      align: 'left',
      font: 'bodyMMedium',
      fontcolor: theme.palette.gray[900],
    },
    {
      id: 'certifications',
      label: 'Certifications',
      width: 138,
      align: 'left',
      font: 'bodyMMedium',
      fontcolor: theme.palette.gray[900],
    },
    {
      id: 'occupancy',
      label: lastLabel,
      width: 138,
      align: 'right',
      font: 'bodyMMedium',
      fontcolor: theme.palette.gray[900],
    },
  ];

  const data: TableProps['data'] = groupedVacancies.map((vacancy, index) => ({
    ...(!hideCheckbox && {
      checkbox: (
        <Checkbox
          checked={selectedVacancies?.includes(`${vacancy.id}_${vacancy.startDateTime.getTime()}`)}
          color="primary"
          onClick={() =>
            onVacancySelect?.({
              id: vacancy.id,
              startDateTime: vacancy.startDateTime,
            })
          }
          disabled={checkDisabled}
        />
      ),
    }),
    // #TODO: Remove mock battalion when hiring engine v2 is implemented
    battalion: (
      <Box>
        <Typography variant="bodyMRegular">{'Mock Battalion'}</Typography>
      </Box>
    ),
    station: (
      <Box>
        <Typography variant="bodyMRegular">{vacancy.stationName}</Typography>
      </Box>
    ),
    apparatus: (
      <Box>
        <Typography variant="bodyMRegular">{vacancy.apparatusName}</Typography>
      </Box>
    ),
    start_time: (
      <Box>
        <Typography variant="bodyMRegular">{format(vacancy.startDateTime, 'HH:mm')}</Typography>
      </Box>
    ),
    end_time: (
      <Box>
        <Typography variant="bodyMRegular">
          {format(vacancy.endDateTime, 'HH:mm')}
          {vacancy.endDateTime.getDate() > vacancy.startDateTime.getDate() && ' (next day)'}
        </Typography>
      </Box>
    ),
    certifications: vacancy.certifications ? (
      <CardCertBadges certs={vacancy.certifications} />
    ) : (
      <Box>
        <Typography variant="bodyMRegular">N/A</Typography>
      </Box>
    ),
    occupancy: lastColumnValues?.[index] || (
      <Box
        sx={{
          display: 'flex',
          padding: '6px 12px',
          border: `1px dashed ${theme.palette.gray[300]}`,
          borderRadius: '27px',
          alignItems: 'center',
          justifyContent: 'center',
          width: 'fit-content',
          margin: '0 auto',
        }}
      >
        <Typography variant="badgeLabel">Vacant</Typography>
      </Box>
    ),
  }));

  return (
    <Box>
      <Box
        sx={{
          display: 'flex',
          marginTop: 4,
          alignItems: 'center',
          gap: 2,
        }}
      >
        {vacancyGroupNumber && (
          <Typography variant="h5" color={theme.palette.text.secondary} sx={{ paddingLeft: 2 }}>
            #{vacancyGroupNumber}
          </Typography>
        )}
        <Badge
          color={groupedVacancies[0].rank.color}
          label={groupedVacancies[0].rank.name}
          overrideMaxLength={64}
          isRound
          variant="bodyMRegular"
          fontWeight={400}
        />
        {onEditCallOrderClick && (
          <Box sx={{ height: '36px', display: 'flex', gap: 2, marginLeft: 'auto' }}>
            <Button variant="outlined" size="medium" onClick={() => onEditCallOrderClick(groupedVacancies[0].rank)}>
              Edit Call Order
            </Button>
            <Button variant="outlined" size="medium" onClick={() => {}} disabled>
              View Progress
            </Button>
          </Box>
        )}
      </Box>
      <CustomTable columns={columns} data={data} />
    </Box>
  );
};
