import { Box, Typography } from '@mui/material';
import { useDocumentTitle } from '../../hooks';
import { Button } from '../Button';

export const PageNotFound = () => {
  useDocumentTitle('Page Not Found');
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
      <Box component="main" sx={{ flex: 1, py: 8, px: 4 }}>
        <Typography variant="h1" component="h1" sx={{ mb: 3 }}>
          Oops!
        </Typography>
        <Typography variant="h5" component="h2" sx={{ mb: 3 }}>
          The page you are looking for does not exist.
        </Typography>
        <Button href="/" variant="contained" size="large">
          Go to Dashboard
        </Button>
      </Box>
      <Box component="footer" sx={(theme) => ({ background: theme.palette.gray[100], py: 3, px: 4 })}>
        <Typography variant="body1" sx={{ mb: 1.5 }}>
          Please contact us if you think this is an issue on our end.
        </Typography>
        <Button color="inherit" variant="outlined" href="mailto:support@stationwise.com">
          support@stationwise.com
        </Button>
      </Box>
    </Box>
  );
};
