import { ReactComponent as CloseSymbol } from '@material-symbols/svg-400/outlined/close.svg';
import { Box, Chip, FormControl, MenuItem, Select } from '@mui/material';
import React from 'react';
import { makeTestIdentifier } from '@stationwise/share-utils';
import { ChevronDownIcon16 } from '../../assets';
import { Button } from '../Button';
import { MultipleSelect } from '../MultipleSelect';
import { SearchInput } from '../SearchInput';
import { SvgIcon } from '../SvgIcon';
import { theme } from '../ThemeProvider';

interface FilterOption {
  name: string;
  options: { value: string | number; label: string }[];
  selected: string;
  selectedItems?: string[];
  onChange: (value: string) => void;
  onMultiChange?: (values: string[]) => void;
  isMulti?: boolean;
}

interface SearchAndFilterProps {
  searchText: string;
  setSearchText: React.Dispatch<React.SetStateAction<string>> | ((value: string) => void);
  filters: FilterOption[];
  clearAllFilters: () => void;
  searchPlaceholder?: string;
}

const FilterChip = ({ label, onDelete }: { label: string; onDelete: () => void }) => (
  <Chip
    sx={(theme) => ({
      padding: theme.spacing(0.5, 1),
      borderRadius: '100px',
      backgroundColor: theme.palette.gray[100],
      color: theme.palette.gray[600],
      typography: 'bodySRegular',
      '& .MuiChip-deleteIcon': {
        color: theme.palette.gray[400],
        fontSize: '13px',
      },
    })}
    deleteIcon={<SvgIcon component={CloseSymbol} />}
    label={label}
    onDelete={onDelete}
  />
);

export const SearchAndFilter: React.FC<SearchAndFilterProps> = ({
  searchText,
  setSearchText,
  filters,
  clearAllFilters,
  searchPlaceholder = 'Search',
}) => {
  const selectedFilters = filters.filter(
    (filter) => filter.selected || (filter.isMulti && filter.selectedItems && filter.selectedItems.length > 0),
  );
  return (
    <Box sx={{ width: 'fit-content' }}>
      <SearchInput value={searchText} setValue={setSearchText} color="white" placeHolder={searchPlaceholder} />
      <Box sx={{ display: 'flex', gap: '15px', marginTop: '20px' }}>
        {filters.map((filter) =>
          filter.isMulti ? (
            <Box width="auto" key={filter.name}>
              <MultipleSelect
                items={filter.options.map((opt) => ({ ...opt, value: String(opt.value) }))}
                selectedItems={filter.selectedItems || []}
                setSelectedItems={filter.onMultiChange || (() => {})}
                placeholderVariant="bodySRegular"
                placeholderColor={theme.palette.gray[900]}
                placeholder={filter.name}
                hideValue={true}
                showPlaceholderOnly={true}
              />
            </Box>
          ) : (
            <FormControl key={filter.name} variant="outlined" data-cy={`filter-${makeTestIdentifier(filter.name)}`}>
              <Select
                value=""
                onChange={(event) => filter.onChange(event.target.value)}
                displayEmpty
                renderValue={() => filter.name}
                sx={{
                  borderRadius: '8px',
                  typography: 'bodySRegular',
                }}
                MenuProps={{
                  autoFocus: false,
                  anchorOrigin: { vertical: 'bottom', horizontal: 'left' },
                  transformOrigin: { vertical: 'top', horizontal: 'left' },
                }}
                IconComponent={ChevronDownIcon16}
              >
                {filter.options.map((option) => (
                  <MenuItem
                    key={option.value}
                    value={option.value}
                    data-value={option.label}
                    sx={(theme) => ({
                      borderRadius: '8px',
                      color: theme.palette.gray[900],
                      typography: 'bodySRegular',
                    })}
                  >
                    {option.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          ),
        )}
      </Box>
      <Box sx={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center', marginTop: 2.5, gap: 1.5 }}>
        {selectedFilters.map((filter) => {
          if (filter.isMulti && filter.selectedItems) {
            return filter.selectedItems
              .map((selectedValue) => {
                const values = selectedValue.split(',');
                return values
                  .map((value) => {
                    const option = filter.options.find((opt) => opt.value === value.trim());
                    if (!option) return null;

                    return (
                      <FilterChip
                        key={`${filter.name}-${value}`}
                        label={option.label}
                        onDelete={() => {
                          const newSelectedItems = filter.selectedItems?.filter((item) => !item.includes(value)) || [];
                          filter.onMultiChange?.(newSelectedItems);
                        }}
                      />
                    );
                  })
                  .filter(Boolean);
              })
              .flat();
          } else {
            const chipLabel = filter.options.find((option) => option.value === filter.selected)?.label || '';
            return <FilterChip key={filter.name} label={chipLabel} onDelete={() => filter.onChange('')} />;
          }
        })}
        {selectedFilters.length > 0 && (
          <Button onClick={clearAllFilters} data-cy="clear-all-button" sx={{ minHeight: '32px' }}>
            Clear All
          </Button>
        )}
      </Box>
    </Box>
  );
};
