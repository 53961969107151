import { Box, TextField, useTheme, Popper, Paper, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { SearchIcon16 } from '@stationwise/component-module';
import { client } from '@stationwise/share-api';
import { EmployeePayroll } from '@stationwise/share-types';

interface EmployeeSearchProps {
  value: string;
  setValue: (value: string) => void;
}

export const EmployeeSearch = ({ value, setValue }: EmployeeSearchProps) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [searchResults, setSearchResults] = useState<EmployeePayroll[]>([]);

  useEffect(() => {
    const fetchSearchResults = async () => {
      if (value.length >= 1) {
        try {
          const response = await client.get('payroll/', {
            params: {
              search: value,
              latest: true,
            },
          });
          setSearchResults(response.data.results);
        } catch (error) {
          console.error('Error fetching search results:', error);
        }
      } else {
        setSearchResults([]);
      }
    };

    const timeoutId = setTimeout(fetchSearchResults, 300);
    return () => clearTimeout(timeoutId);
  }, [value]);

  const handleEmployeeClick = (employeePayroll: EmployeePayroll) => {
    navigate(`/app/payroll/employee/${employeePayroll.id}`);
    setValue('');
    setSearchResults([]);
  };

  return (
    <Box>
      <TextField
        value={value}
        onChange={(event) => {
          setValue(event.target.value);
          setAnchorEl(event.currentTarget);
        }}
        placeholder="Find any employee"
        sx={{
          minWidth: '280px',
          width: 'max-content',
          backgroundColor: 'white',
          borderRadius: theme.spacing(1),
        }}
        slotProps={{
          input: {
            startAdornment: (
              <Box sx={{ display: 'flex', svg: { color: theme.palette.gray[400] } }}>
                <SearchIcon16 />
              </Box>
            ),
          },
        }}
      />

      <Popper
        open={searchResults.length > 0}
        anchorEl={anchorEl}
        placement="bottom-start"
        style={{ width: anchorEl?.clientWidth, zIndex: theme.zIndex.modal }}
      >
        <Paper
          elevation={3}
          sx={{
            mt: theme.spacing(1),
            maxHeight: '300px',
            overflow: 'auto',
            borderRadius: theme.spacing(1),
          }}
        >
          {searchResults.map((result) => (
            <Box
              key={result.id}
              onClick={() => handleEmployeeClick(result)}
              sx={{
                p: theme.spacing(1),
                cursor: 'pointer',
                '&:hover': {
                  backgroundColor: theme.palette.gray[100],
                },
              }}
            >
              <Typography>{`${result.employee.firstName} ${result.employee.lastName}`}</Typography>
            </Box>
          ))}
        </Paper>
      </Popper>
    </Box>
  );
};
