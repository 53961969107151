import { EventInput } from '@fullcalendar/core';
import { EventImpl } from '@fullcalendar/core/internal';
import { Box } from '@mui/material';
import { format } from 'date-fns';
import React from 'react';
import {
  Button,
  RankBadge,
  SHIFT_OVERVIEW_OPTIONS,
  SHIFT_TITLES,
  theme,
  useLoadedAuthUserContext,
  useLoadedDepartmentInfoContext,
} from '@stationwise/component-module';
import { determineDateRange } from '../../../../../../../app/pages/Calendar/components/ShiftOverview/ShiftOverviewOptions';
import { EventCardLine } from './EventCardLine';
import { EventCardTitleSection } from './EventCardTitleSection';
import { executeSelectedAction, findDurationAsHourAndMinute, processTitle } from './EventCardUtils';
import { EventCardWrapper } from './EventCardWrapper';

export const RegularEventCard = ({
  shift,
  setClickedEventAsCalendarSelectedElement,
  todayEvents,
  openActionModal,
  handleShiftCardOverviewClose,
}: {
  shift: EventInput | EventImpl;
  setClickedEventAsCalendarSelectedElement?: (
    event: React.MouseEvent<HTMLElement>,
    shift: EventInput | EventImpl,
    design?: boolean,
  ) => void;
  todayEvents?: EventInput[];
  openActionModal?: (option: string) => void;
  handleShiftCardOverviewClose?: () => void;
}) => {
  const { hour: durationHour, minute: durationMin } = findDurationAsHourAndMinute(shift);
  const { state: authUserState } = useLoadedAuthUserContext();
  const hasShiftTrade = todayEvents && todayEvents.some((event) => event.title === SHIFT_TITLES.TRADE_REQUESTED);
  const disableTradeShift =
    (todayEvents && todayEvents.some((event) => event.title === SHIFT_TITLES.OVERTIME)) ||
    shift.extendedProps?.['isOvertime'] === true;
  const dateRange = determineDateRange(shift);
  const isShiftTrade = shift.title === SHIFT_TITLES.SHIFT_TRADE;

  const processedTitle = processTitle(shift, !isShiftTrade);
  const backgroundColor = isShiftTrade ? theme.palette.blue[900] : theme.palette.blue[500];

  const { state: departmentInfoState } = useLoadedDepartmentInfoContext();
  const additionalPaidTimeRequestsEnabled = departmentInfoState.departmentInfo.settings.additionalPaidTimeRequestsEnabled;

  return (
    <EventCardWrapper data-cy="event-card-regular">
      <EventCardTitleSection title={processedTitle} backgroundColor={backgroundColor} titleColor={theme.palette.common.white} />
      <Box
        sx={(theme) => ({
          display: 'flex',
          px: theme.spacing(2),
          flexDirection: 'column',
          alignItems: 'flex-start',
          alignSelf: 'stretch',
          '&:last-child': {
            borderBottom: 'none',
          },
        })}
      >
        <EventCardLine
          title={'Start Date'}
          value={shift.start ? format(shift.start as Date, 'MMM d, HH:mm') : '-'}
        ></EventCardLine>
        <EventCardLine title={'End Date'} value={shift.end ? format(shift.end as Date, 'MMM d, HH:mm') : '-'}></EventCardLine>

        <EventCardLine
          title={'Duration'}
          value={shift.end && shift.start ? durationHour + ' hrs ' + durationMin + ' mins' : '-'}
        ></EventCardLine>

        <EventCardLine
          title={'Pay Code'}
          value={
            shift.extendedProps && shift.extendedProps['eventPayCodes'] && shift.extendedProps['eventPayCodes'][0]
              ? shift.extendedProps['eventPayCodes'][0].code + ' | ' + shift.extendedProps['eventPayCodes'][0].name
              : '-'
          }
        ></EventCardLine>
        <EventCardLine
          title={'Rank'}
          value={
            shift.extendedProps ? <RankBadge maxLength={100} rankField={'name'} rank={shift.extendedProps['eventRank']} /> : '-'
          }
        ></EventCardLine>
        <EventCardLine title={'Station'} value={shift.extendedProps ? shift.extendedProps['eventStation'] : '-'}></EventCardLine>

        <EventCardLine
          title={'Apparatus'}
          value={shift.extendedProps ? shift.extendedProps['eventApparatus'] : '-'}
          isLastLine={!openActionModal && !(shift.extendedProps && shift.extendedProps['eventNote'])}
        ></EventCardLine>

        {shift.extendedProps && shift.extendedProps['eventNote'] && (
          <EventCardLine
            isLastLine={!openActionModal}
            title={'Note'}
            value={shift.extendedProps ? shift.extendedProps['eventNote'] : '-'}
          ></EventCardLine>
        )}
      </Box>
      <Box
        sx={(theme) => ({
          display: 'flex',
          p: openActionModal ? theme.spacing(2) : '',
          gap: theme.spacing(1),
          alignSelf: 'stretch',
          alignItems: 'center',
        })}
      >
        {dateRange !== 'future' && openActionModal && additionalPaidTimeRequestsEnabled && (
          <Button
            sx={(theme) => ({
              display: 'flex',
              py: theme.spacing(0.75),
              px: theme.spacing(2),
              justifyContent: 'center',
              alignItems: 'center',
              flex: '1 0 0',
            })}
            data-cy="request-additional-paid-time-button"
            color="primary"
            size="medium"
            variant="outlined"
            onClick={(event: React.MouseEvent<HTMLElement>) => {
              executeSelectedAction(
                SHIFT_OVERVIEW_OPTIONS.ADDITIONAL_PAID_TIME,
                event,
                shift,
                setClickedEventAsCalendarSelectedElement,
                openActionModal,
                handleShiftCardOverviewClose,
              );
            }}
          >
            Request Additional Paid Time
          </Button>
        )}
        {!authUserState.employee.isNonShift &&
          dateRange !== 'past' &&
          !hasShiftTrade &&
          !disableTradeShift &&
          openActionModal && (
            <Button
              sx={(theme) => ({
                display: 'flex',
                py: theme.spacing(0.75),
                px: theme.spacing(2),
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
                flex: '1 0 0',
              })}
              color="primary"
              size="medium"
              variant="contained"
              data-cy="trade-shift-button"
              onClick={(event: React.MouseEvent<HTMLElement>) => {
                executeSelectedAction(
                  SHIFT_OVERVIEW_OPTIONS.TRADE_SHIFT,
                  event,
                  shift,
                  setClickedEventAsCalendarSelectedElement,
                  openActionModal,
                  handleShiftCardOverviewClose,
                );
              }}
            >
              Trade Shift
            </Button>
          )}
        {dateRange !== 'past' && openActionModal && (
          <Button
            sx={(theme) => ({
              display: 'flex',
              py: theme.spacing(0.75),
              px: theme.spacing(2),
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'column',
              flex: '1 0 0',
              maxHeight: theme.spacing(4.75),
            })}
            data-cy="request-time-off-button"
            color="primary"
            size="medium"
            variant="outlined"
            onClick={(event: React.MouseEvent<HTMLElement>) => {
              executeSelectedAction(
                SHIFT_OVERVIEW_OPTIONS.REQUEST_TIME_OFF,
                event,
                shift,
                setClickedEventAsCalendarSelectedElement,
                openActionModal,
                handleShiftCardOverviewClose,
              );
            }}
          >
            Request Time Off
          </Button>
        )}
      </Box>
    </EventCardWrapper>
  );
};
