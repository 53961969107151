import {
  Autocomplete,
  Box,
  Dialog,
  FormControl,
  FormHelperText,
  TextField,
  FormControlLabel,
  Checkbox,
  Alert,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Button, SnackbarService, theme, useLoadedDepartmentInfoContext } from '@stationwise/component-module';
import { client, isAxiosError } from '@stationwise/share-api';
import { PayCode, TimeOffAccruals } from '@stationwise/share-types';

interface TimeOffAccrualsModalProps {
  onSaveSuccess: () => void;
  selectedAccrual?: TimeOffAccruals;
  setShowModal: (show: boolean) => void;
  showModal: boolean;
  existingPayCodes: PayCode[];
}

const newTimeOffAccrual: TimeOffAccruals = {
  id: -1,
  defaultAccrualAmount: 0,
  maxAccrualLimit: 0,
  payCodes: [],
  payCodesInput: [],
};

export const TimeOffAccrualsModal = ({
  existingPayCodes,
  onSaveSuccess,
  setShowModal,
  showModal,
  selectedAccrual,
}: TimeOffAccrualsModalProps) => {
  const { dispatch } = useLoadedDepartmentInfoContext();

  const title = selectedAccrual ? 'Edit Accrual Bank' : 'Create a new Accrual Bank';
  const [accrual, setAccrual] = useState<TimeOffAccruals>(selectedAccrual ? selectedAccrual : newTimeOffAccrual);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isUnique, setIsUnique] = useState(true);
  const disabled =
    !isUnique ||
    !accrual.maxAccrualLimit ||
    (!accrual.payCodesInput?.length && !accrual.payCodes?.length) ||
    !accrual.name?.trim() ||
    isSubmitting ||
    (accrual &&
      Boolean(accrual.defaultAccrualAmount) &&
      Boolean(accrual.maxAccrualLimit) &&
      accrual.defaultAccrualAmount! > accrual.maxAccrualLimit);

  useEffect(() => {
    const handler = setTimeout(async () => {
      if (!accrual?.name?.trim()) return;
      if (accrual?.name === selectedAccrual?.name) return;
      try {
        const response = await client.get(`payroll/time-off-accruals/check-bank-name-uniqueness/`, {
          params: { bankName: accrual.name },
        });
        setIsUnique(response.data.isUnique);
      } catch (error) {
        console.error('Error checking uniqueness', error);
      }
    }, 500);

    return () => clearTimeout(handler);
  }, [accrual?.name, selectedAccrual?.name]);

  const handleClose = () => {
    setIsSubmitting(false);
    setShowModal(false);
    setAccrual(newTimeOffAccrual);
  };

  const handleSuccess = () => {
    handleClose();
    onSaveSuccess();
    dispatch({ type: 'REFETCH_DEPARTMENT_INFO' });
  };

  const handleSave = async () => {
    setIsSubmitting(true);
    if (selectedAccrual) {
      //  UPDATE
      try {
        const response = await client.patch(`/payroll/time-off-accruals/${accrual.id}/`, {
          ...accrual,
          payCodesInput: accrual.payCodes?.map((code) => code.id),
        });
        SnackbarService.notify({
          content: `Successfully updated accrual bank: ${response.data.name}`,
          severity: 'success',
          duration: 5000,
        });
        handleSuccess();
      } catch (error) {
        SnackbarService.notify({
          content: `Failed to save accrual bank: ${isAxiosError(error) ? error.message : ''}`,
          severity: 'error',
          duration: 5000,
        });
        handleClose();
      }
    } else {
      // CREATE
      try {
        const response = await client.post('/payroll/time-off-accruals/', {
          ...accrual,
        });
        SnackbarService.notify({
          content: `Successfully added accrual bank: ${response.data.name}`,
          severity: 'success',
          duration: 5000,
        });
        handleSuccess();
      } catch (error) {
        SnackbarService.notify({
          content: `Failed to save new accrual bank: ${isAxiosError(error) ? error.message : ''}`,
          severity: 'error',
          duration: 5000,
        });
        handleClose();
      }
    }
  };
  return (
    <Dialog open={showModal} onClose={handleClose}>
      <Box
        sx={{
          display: 'flex',
          width: '100%',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Box
          sx={(theme) => ({
            backgroundColor: theme.palette.common.white,
            justifyContent: 'center',
            borderRadius: theme.spacing(1.5),
            p: theme.spacing(3),
            width: '496px',
            display: 'flex',
            flexDirection: 'column',
            '.MuiFormControl-root': {
              mb: 4,
              '.MuiInputLabel-root': {
                top: '-5px',
              },
            },
          })}
        >
          <Box
            sx={{
              typography: 'bodyXLSemibold',
              mb: 4,
            }}
          >
            {title}
          </Box>

          <TextField
            sx={{ mb: isUnique ? 'unset' : '8px !important' }}
            label="Name"
            onChange={(event) => {
              setAccrual({ ...accrual, name: event.currentTarget.value });
            }}
            required
            value={accrual.name}
            error={!accrual.name?.trim()}
          />
          {!isUnique && (
            <Box sx={(theme) => ({ mb: theme.spacing(4) })}>
              <Alert severity="error" sx={{ typography: 'bodyXSMedium' }}>
                This bank name is already in use
              </Alert>
            </Box>
          )}

          <FormControl>
            <Autocomplete
              multiple
              value={accrual.payCodes!.filter((code) => code.id !== -1)}
              options={existingPayCodes.filter(
                (code: PayCode) =>
                  code.payCodeType === 'TIME_OFF' &&
                  (selectedAccrual?.payCodes?.some((ap) => ap.id === code.id) ||
                    code.timeOffAccrual === null ||
                    code.timeOffAccrual === undefined),
              )}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              getOptionLabel={(option) => option.name}
              onChange={(_event, value) =>
                setAccrual({
                  ...accrual,
                  payCodesInput: value.map((code) => code.id),
                  payCodes: value,
                })
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Pay Codes of Accrual Bank"
                  error={accrual.payCodes?.length === 0}
                  helperText={accrual.payCodes?.length === 0 && 'Accrual Banks exist for all Time Off Pay Codes.'}
                  required
                  placeholder={'Select pay codes'}
                  onKeyDown={(event) => {
                    if (event.key === 'Backspace' && !params.inputProps.value) {
                      event.stopPropagation();
                    }
                  }}
                />
              )}
              renderTags={(tagValue) =>
                tagValue.map((option) => (
                  <Box
                    key={option.id + 'timeoff-accruals-modal'}
                    sx={[
                      {
                        backgroundColor: theme.palette.yellow[300],
                        color: theme.palette.purple[500],
                        borderRadius: '10px',
                        display: 'inline-flex',
                        padding: '2px 6px',
                        typography: 'tagline1',
                        textWrap: 'nowrap',
                        mx: theme.spacing(0.5),
                      },
                    ]}
                  >
                    {option.name.slice(0, 12)}
                  </Box>
                ))
              }
              sx={{
                '.MuiFormControl-root': {
                  mb: 0,
                  '.MuiInputLabel-root:not(.Mui-focused)': {
                    top: 0,
                  },
                },
              }}
            />
            <FormHelperText> The pay codes you selected will provide accruals from this accrual bank.</FormHelperText>
          </FormControl>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              '.MuiFormControl-root': {
                mt: 1,
                mb: 0,
              },
            }}
          >
            <FormControlLabel
              control={
                <Checkbox
                  checked={accrual.isMaxUnlimited}
                  onChange={(event) => {
                    setAccrual({
                      ...accrual,
                      isMaxUnlimited: event.target.checked,
                      maxAccrualLimit: event.target.checked ? 1e308 : 0,
                    });
                  }}
                  sx={{
                    '&.Mui-checked': {
                      color: (theme) => theme.palette.common.black,
                    },
                  }}
                />
              }
              label="Unlimited max accrual"
              sx={{ mb: 2 }} // Remove top margin
            />
            {!accrual.isMaxUnlimited && (
              <TextField
                label="Max Accrual Limit"
                helperText="Maximum amount that can be reached per employee for this bank"
                onChange={(event) => {
                  setAccrual({
                    ...accrual,
                    maxAccrualLimit: Number(Number(event.currentTarget.value) < 0 ? 0 : event.currentTarget.value),
                  });
                }}
                type="number"
                value={accrual.maxAccrualLimit === null ? undefined : accrual.maxAccrualLimit}
              />
            )}
          </Box>

          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              '.MuiFormControl-root': {
                mb: 0,
              },
            }}
          >
            <FormControlLabel
              control={
                <Checkbox
                  checked={accrual.isDefaultUnlimited}
                  onChange={(event) => {
                    setAccrual({
                      ...accrual,
                      isDefaultUnlimited: event.target.checked,
                      defaultAccrualAmount: event.target.checked ? 1e308 : 0,
                    });
                  }}
                  sx={{
                    '&.Mui-checked': {
                      color: (theme) => theme.palette.common.black,
                    },
                  }}
                />
              }
              label="Unlimited default accrual"
              sx={{ my: 2 }}
            />
            {!accrual.isDefaultUnlimited && (
              <TextField
                label="Default Accrual Amount"
                helperText="Default Accrual Amount per employee for this bank"
                onChange={(event) => {
                  setAccrual({
                    ...accrual,
                    defaultAccrualAmount: Number(Number(event.currentTarget.value) < 0 ? 0 : event.currentTarget.value),
                  });
                }}
                type="number"
                value={accrual.defaultAccrualAmount === null ? undefined : accrual.defaultAccrualAmount}
              />
            )}
          </Box>
          {accrual &&
            !!accrual.defaultAccrualAmount &&
            !!accrual.maxAccrualLimit &&
            accrual.defaultAccrualAmount > accrual.maxAccrualLimit && (
              <Alert severity={'error'}>
                {`Default accrual amount; ${accrual.defaultAccrualAmount} can not be greater than max accrual limit; ${accrual.maxAccrualLimit}`}
              </Alert>
            )}
          <Box
            sx={{
              justifyContent: 'space-between',
              display: 'flex',
              width: '100%',
              mt: 2,
            }}
          >
            <Button variant="outlined" size="large" sx={{ width: '216px' }} onClick={() => handleClose()}>
              Cancel
            </Button>
            <Button
              variant="contained"
              size="large"
              sx={{ width: '216px' }}
              onClick={() => handleSave()}
              disabled={disabled}
              loading={isSubmitting}
            >
              Save
            </Button>
          </Box>
        </Box>
      </Box>
    </Dialog>
  );
};
