import { Box, Typography } from '@mui/material';
import React, { FunctionComponent } from 'react';

interface PayrollValidationProps {
  leftIcon: FunctionComponent<React.SVGProps<SVGSVGElement>>;
  message: string;
  onClick?: () => void;
}

export const PayrollValidation: React.FC<PayrollValidationProps> = ({ leftIcon: LeftIcon, message, onClick }) => {
  return (
    <Box
      className="payroll-validation"
      sx={(theme) => ({
        paddingBottom: theme.spacing(2),
      })}
      onClick={onClick}
    >
      <Box
        sx={(theme) => ({
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          borderRadius: '12px',
          backgroundColor: theme.palette.common.white,
          boxShadow: '0px 1px 2px 0px rgba(0, 0, 0, 0.05) , 0px 1px 2px 0px rgba(0, 0, 0, 0.05)',
          border: '1px solid #DEE3ED',
          paddingY: theme.spacing(1.5),
          paddingX: theme.spacing(2),
          marginTop: theme.spacing(1),
        })}
      >
        <LeftIcon />

        <Box sx={{ flex: 1, display: 'flex', justifyContent: 'center' }}>
          <Typography variant="bodyMMedium" sx={(theme) => ({ color: theme.palette.gray[700] })}>
            {message}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};
