import { Box } from '@mui/material';
import { useEffect, useState } from 'react';
import { Select, SelectItem } from '@stationwise/component-module';
import { OvertimeRules } from './OvertimeRules';
import { TiebreakerList } from './TiebreakerList';
import { getFilterAndSortOptions } from './filterAndSortOptions';

interface SortsProps {
  selectedSortRule: string;
  setSelectedSortRule: (option: string) => void;
  dialogOpen: boolean;
  selectedTiebreakerRules: SelectItem[];
  setSelectedTiebreakerRules: (selected: SelectItem[]) => void;
  isMinHours: boolean;
  isAccHours: boolean;
  setIsMinHours: (isMinHours: boolean) => void;
  setIsAccHours: (isAccHours: boolean) => void;
  overtimeThreshold: number;
  setOvertimeThreshold: (threshold: number) => void;
  daysAgo: number;
  setDaysAgo: (days: number) => void;
  totalOvertimeIntervalStartDates: Date[];
  setTotalOvertimeIntervalStartDates: (dates: Date[]) => void;
}

export const Sorts = ({
  selectedSortRule,
  setSelectedSortRule,
  dialogOpen,
  selectedTiebreakerRules,
  setSelectedTiebreakerRules,
  isAccHours,
  isMinHours,
  setIsMinHours,
  setIsAccHours,
  daysAgo,
  setDaysAgo,
  totalOvertimeIntervalStartDates,
  setTotalOvertimeIntervalStartDates,
  overtimeThreshold,
  setOvertimeThreshold,
}: SortsProps) => {
  const { sortRules } = getFilterAndSortOptions();
  const [sortingByOvertimeCriteria, setSortingByOvertimeCriteria] = useState<boolean>(false);
  const [showTotalOvertimeFields, setShowTotalOvertimeFields] = useState<boolean>(false);

  useEffect(() => {
    if (!dialogOpen) {
      reset();
    }
  }, [dialogOpen]);

  // check which sections have to be displayed according to the current selections
  useEffect(() => {
    setSortingByOvertimeCriteria(checkIsSortingByOvertimeCriteria());
    if (selectedSortRule === 'TOTAL_OVERTIME' || selectedTiebreakerRules.map((tr) => tr.value).includes('TOTAL_OVERTIME')) {
      setShowTotalOvertimeFields(true);
    } else {
      setShowTotalOvertimeFields(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSortRule, selectedTiebreakerRules]);

  const reset = () => {
    setShowTotalOvertimeFields(false);
    setSortingByOvertimeCriteria(false);
  };

  const handleChangeIsAccHours = () => {
    setIsAccHours(!isAccHours);
    setIsMinHours(false);
  };

  const handleChangeIsMinHours = () => {
    setIsMinHours(!isMinHours);
    setIsAccHours(false);
  };

  // to decide if overtime rules section is displayed
  const checkIsSortingByOvertimeCriteria = () => {
    const selectedTiebreakerRulesValues = selectedTiebreakerRules.map((tr) => tr.value);
    return (
      selectedSortRule === 'LAST_OVERTIME_DATE' ||
      selectedSortRule === 'LAST_OVERTIME_STAFFED_AT' ||
      selectedSortRule === 'TOTAL_OVERTIME' ||
      selectedTiebreakerRulesValues.includes('LAST_OVERTIME_STAFFED_AT') ||
      selectedTiebreakerRulesValues.includes('LAST_OVERTIME_DATE') ||
      selectedTiebreakerRulesValues.includes('TOTAL_OVERTIME') ||
      selectedTiebreakerRulesValues.includes('SECOND_LAST_OVERTIME_DATE') ||
      selectedTiebreakerRulesValues.includes('THIRD_LAST_OVERTIME_DATE')
    );
  };

  return (
    <>
      {' '}
      <Box
        sx={(theme) => ({
          typography: 'bodyMSemibold',
          lineHeight: '20px',
          mt: 2,
          color: theme.palette.gray[700],
        })}
      >
        Sorts
      </Box>
      <Box sx={{ mt: 2 }}>
        <Select
          width="full"
          items={sortRules}
          selected={selectedSortRule}
          placeholder="Sort rule"
          onChange={(selectedOption: string) => setSelectedSortRule(selectedOption)}
        />
      </Box>
      {selectedSortRule && (
        <Box sx={{ mt: 2 }}>
          <TiebreakerList
            selectedSortRule={selectedSortRule}
            selectedTiebreakerRules={selectedTiebreakerRules}
            setSelectedTiebreakerRules={setSelectedTiebreakerRules}
            dialogOpen={dialogOpen}
          />
        </Box>
      )}
      {sortingByOvertimeCriteria && (
        <OvertimeRules
          showTotalOvertimeFields={showTotalOvertimeFields}
          isMinHours={isMinHours}
          isAccHours={isAccHours}
          overtimeThreshold={overtimeThreshold}
          setOvertimeThreshold={setOvertimeThreshold}
          handleChangeIsAccHours={handleChangeIsAccHours}
          handleChangeIsMinHours={handleChangeIsMinHours}
          daysAgo={daysAgo}
          setDaysAgo={setDaysAgo}
          totalOvertimeIntervalStartDates={totalOvertimeIntervalStartDates}
          setTotalOvertimeIntervalStartDates={setTotalOvertimeIntervalStartDates}
        />
      )}
    </>
  );
};
