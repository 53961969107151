/* eslint-disable @typescript-eslint/no-explicit-any */
import { Box } from '@mui/material';
import { captureException } from '@sentry/react';
import { useState, useEffect, useMemo } from 'react';
import {
  ErrorPage,
  LottieLogoPageLoader,
  PayPeriodPicker,
  SnackbarService,
  useFetchEmployeePayroll,
  useDocumentTitle,
  useLoadedDepartmentInfoContext,
  useFetchPayPeriods,
} from '@stationwise/component-module';
import { client } from '@stationwise/share-api';
import { AddColumnModal } from './AddNewColumnModal';
import { ChangeColumnNameModal } from './ChangeColumnNameModal';
import { EmployeeInfo } from './EmployeeInfo';
import { EmployeePayPeriodHistory } from './EmployeePayPeriodHistory';
import { EmployeePayrollTable } from './EmployeePayrollTable';
import { EmployeePayrollTableHeaderContent } from './EmployeePayrollTableHeaderContent';
import { EmployeeSearch } from './EmployeeSearch';
import { OverrideModal } from './OverrideModal';
import { SaveButtonContainer } from './SaveButtonContainer';
import { EntryAction } from './types';
import { Change, findChangedField, transformToDetailedPayroll } from './utils';

type EmployeePayrollContentProps = {
  employeePayrollId?: string;
};

export const EmployeePayrollContent = ({ employeePayrollId }: EmployeePayrollContentProps) => {
  const {
    employeePayroll,
    selectedPayPeriod,
    currentPayPeriod,
    selectedPeriodIndex,
    setSelectedPayPeriod,
    setSelectedPeriodIndex,
    isLoading,
    isError,
    forceRefetch,
  } = useFetchEmployeePayroll({ employeePayrollId });

  const [isAddColumnModalOpen, setIsAddColumnModalOpen] = useState(false);
  const [changeColumnNameOpen, setChangeColumnNameOpen] = useState(false);
  const [selectedColumnName, setSelectedColumnName] = useState('');
  const [isEditing, setIsEditing] = useState(false);
  const [originalData, setOriginalData] = useState(null);
  const [editingCell, setEditingCell] = useState<{ row: number; column: string } | null>(null);
  const [newData, setNewData] = useState<any | null>(null);
  const [overrideModalOpen, setOverrideModalOpen] = useState(false);
  const [change, setChange] = useState<Change | null>(null);
  const [note, setNote] = useState<string>('');
  const [openChangeLog, setOpenChangeLog] = useState(false);
  const [entryAction, setEntryAction] = useState<EntryAction | null>(null);
  const [searchValue, setSearchValue] = useState('');
  const { state: departmentInfoState } = useLoadedDepartmentInfoContext();
  const {
    payPeriods,
    isLoaded: arePayPeriodsLoaded,
    currentYear,
    setCurrentYear,
  } = useFetchPayPeriods({ employeeId: employeePayroll?.employee?.id });

  const payCodes = useMemo(() => {
    return departmentInfoState.departmentInfo.payCodes.map((payCode) => ({
      code: payCode.code,
      name: payCode.name || '',
      extCode: payCode.extCode || null,
    }));
  }, [departmentInfoState.departmentInfo.payCodes]);

  useEffect(() => {
    // Reset when employee payroll changes
    if (employeePayroll) {
      setOriginalData(employeePayroll.detailedPayroll);
      setIsEditing(false);
      setEditingCell(null);
    }
  }, [employeePayroll]);

  const handlePressCancel = () => {
    forceRefetch();
    setIsEditing(false);
  };

  const onConfirm = async () => {
    try {
      const params = { note: note, ...entryAction };

      if (params.columnName === 'duration') {
        params.newValue = Number(params.newValue);
      }

      const response = await client.post(`payroll/${employeePayroll?.id}/entry-action/`, params);

      if (response.status === 200) {
        SnackbarService.notify({
          content: 'Changes saved successfully',
          severity: 'success',
          duration: 5000,
        });
        setIsEditing(false);
        setEditingCell(null);
        setOverrideModalOpen(false);
        forceRefetch();
      } else {
        SnackbarService.notify({
          content: response.data?.error || 'Failed to save changes',
          severity: 'error',
          duration: 10000,
        });
        console.error('Failed to save changes', response);
        setOverrideModalOpen(false);
      }
    } catch (error) {
      captureException(error);
      console.error('An error occurred while saving changes', error);
      const errorMessage = (error as any).response?.data?.error || 'An unexpected error occurred while saving changes';

      SnackbarService.notify({
        content: errorMessage,
        severity: 'error',
        duration: 10000,
      });
    }
  };

  const handlePressSave = () => {
    const transformedData = transformToDetailedPayroll(newData);
    setChange(findChangedField(originalData, transformedData));
    setOverrideModalOpen(true);
  };

  const hasChanges = (newData: any) => {
    return JSON.stringify(newData) !== JSON.stringify(originalData);
  };

  useDocumentTitle(
    `${employeePayroll ? `${employeePayroll.employee.firstName} ${employeePayroll.employee.lastName} - ` : ''}Payroll`,
  );

  if (isLoading) return <LottieLogoPageLoader />;
  if (isError || !employeePayroll) return <ErrorPage />;

  return (
    <>
      <Box sx={{ minWidth: '768px', width: '85%', px: 5, mx: 'auto' }}>
        <AddColumnModal
          addColumnModalOpen={isAddColumnModalOpen}
          setAddColumnModalOpen={setIsAddColumnModalOpen}
          employeePayroll={employeePayroll}
          forceRefetch={forceRefetch}
        />
        <ChangeColumnNameModal
          changeColumnNameOpen={changeColumnNameOpen}
          setChangeColumnNameOpen={setChangeColumnNameOpen}
          selectedColumnName={selectedColumnName}
          forceRefetch={forceRefetch}
          employeePayroll={employeePayroll}
        />
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          {employeePayroll && selectedPayPeriod && (
            <PayPeriodPicker
              selectedPayPeriod={selectedPayPeriod}
              setSelectedPayPeriod={setSelectedPayPeriod}
              currentPayPeriod={currentPayPeriod}
              selectedPeriodIndex={selectedPeriodIndex}
              setSelectedPeriodIndex={setSelectedPeriodIndex}
              disabled={true}
              currentYear={currentYear}
              setCurrentYear={setCurrentYear}
              payPeriods={payPeriods}
              isLoaded={arePayPeriodsLoaded}
            />
          )}
          <EmployeeSearch value={searchValue} setValue={setSearchValue} />
        </Box>

        {employeePayroll && (
          <>
            <EmployeeInfo employeePayroll={employeePayroll} setSelectedPayPeriod={setSelectedPayPeriod} />
            <EmployeePayrollTableHeaderContent employeePayroll={employeePayroll} setOpenChangeLog={setOpenChangeLog} />
            <EmployeePayrollTable
              employeePayroll={employeePayroll}
              setChangeColumnNameOpen={setChangeColumnNameOpen}
              setSelectedColumnName={setSelectedColumnName}
              isEditing={isEditing}
              setIsEditing={setIsEditing}
              hasChanges={hasChanges}
              editingCell={editingCell}
              setEditingCell={setEditingCell}
              setNewData={setNewData}
              selectedPayPeriod={selectedPayPeriod}
              forceRefetch={forceRefetch}
              handlePressSave={handlePressSave}
              setIsAddColumnModalOpen={setIsAddColumnModalOpen}
              setEntryAction={setEntryAction}
              payCodes={payCodes}
            />
          </>
        )}
      </Box>
      {isEditing && <SaveButtonContainer handlePressCancel={handlePressCancel} handlePressSave={handlePressSave} />}
      <OverrideModal
        overrideModalOpen={overrideModalOpen}
        setOverrideModalOpen={setOverrideModalOpen}
        change={change}
        note={note}
        setNote={setNote}
        onConfirm={onConfirm}
        payCodes={payCodes}
      />

      {openChangeLog && (
        <EmployeePayPeriodHistory
          setOpenChangeLog={setOpenChangeLog}
          openChangeLog={openChangeLog}
          employeePayPeriodId={employeePayroll.id}
        />
      )}
    </>
  );
};
