import { Box, Typography, useTheme } from '@mui/material';
import React from 'react';

interface EventCardTitleSectionProps {
  title: string;
  backgroundColor: string | undefined;
  titleColor: string;
  truncate?: boolean;
  maxLength?: number;
}

export const EventCardTitleSection: React.FC<EventCardTitleSectionProps> = ({
  title,
  backgroundColor,
  titleColor,
  truncate = false,
  maxLength = 37,
}) => {
  const theme = useTheme();

  const truncatedTitle = truncate && title.length > maxLength ? `${title.slice(0, maxLength)}...` : title;

  return (
    <Box
      sx={{
        display: 'flex',
        padding: theme.spacing(2),
        justifyContent: 'center',
        alignItems: 'flex-start',
        alignSelf: 'stretch',
        overflow: 'hidden',
        background: backgroundColor ?? theme.palette.blue[500],
      }}
    >
      <Typography
        sx={{
          typography: 'bodyMMedium',
          textTransform: 'capitalize',
          color: titleColor,
        }}
      >
        {truncatedTitle}
      </Typography>
    </Box>
  );
};
