import { Box, useTheme, MenuItem, TextField } from '@mui/material';
import { getTeamColors } from '@stationwise/component-module';
import { ShiftPlanTeam, AllowedColors, allowedColorsList } from '@stationwise/share-types';

interface PatternGeneralInfoProps {
  currentShiftPlanTeamNames: string[];
  setShiftPlanTeam: (shiftPlanTeam: ShiftPlanTeam) => void;
  shiftPlanTeam: ShiftPlanTeam;
  setError: (error: { duplicatedTeam: boolean }) => void;
  error: { duplicatedTeam: boolean };
  selectedShiftPlanTeam: ShiftPlanTeam | null;
}

export const PatternGeneralInfo = ({
  currentShiftPlanTeamNames,
  shiftPlanTeam,
  setShiftPlanTeam,
  setError,
  error,
  selectedShiftPlanTeam,
}: PatternGeneralInfoProps) => {
  const theme = useTheme();

  {
    /* TODO display again when other than SHIFT_TEAM type is implemented */
  }
  // const teamTypeOptions = [
  //   { label: ShiftPlanTeamType.SHIFT_TEAM, value: ShiftPlanTeamType.SHIFT_TEAM },
  //   { label: ShiftPlanTeamType.NON_SHIFT_TEAM, value: ShiftPlanTeamType.NON_SHIFT_TEAM },
  //   { label: ShiftPlanTeamType.EVENT_TEAM, value: ShiftPlanTeamType.EVENT_TEAM },
  //   { label: ShiftPlanTeamType.LIGHT_DUTY_TEAM, value: ShiftPlanTeamType.LIGHT_DUTY_TEAM },
  //   { label: ShiftPlanTeamType.EXTENDED_LEAVE_TEAM, value: ShiftPlanTeamType.EXTENDED_LEAVE_TEAM },
  // ];

  const colorItems = allowedColorsList.map((color) => ({
    label: color.slice(0, 1).toUpperCase() + color.slice(1),
    value: color,
    color: color,
  }));

  return (
    <>
      <Box
        sx={{
          typography: 'bodyMRegular',
          textAlign: 'left',
          color: theme.palette.gray[700],
          mb: 1.5,
        }}
      >
        General info
      </Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          mt: 1,
          gap: 3,
        }}
      >
        <TextField
          label="Name"
          onChange={(event) => {
            setShiftPlanTeam({ ...shiftPlanTeam, name: event.target.value || '' });
            if (currentShiftPlanTeamNames.includes(event.currentTarget.value.toLowerCase().trim())) {
              setError({ ...error, duplicatedTeam: true });
            } else if (error.duplicatedTeam) {
              setError({ ...error, duplicatedTeam: false });
            }
          }}
          error={error.duplicatedTeam}
          helperText={error.duplicatedTeam ? 'This team already exists, please choose another name.' : ''}
          required
          value={shiftPlanTeam.name}
          data-cy="name"
        />
        <TextField
          label="Color"
          disabled={!!selectedShiftPlanTeam}
          onChange={(event) => {
            setShiftPlanTeam({ ...shiftPlanTeam, color: event.target.value as AllowedColors });
          }}
          select
          value={shiftPlanTeam.color}
          sx={{
            '& .MuiInputBase-input': {
              height: '24px',
            },
            '& .MuiInputBase-input.Mui-disabled': {
              WebkitTextFillColor: theme.palette.common.white,
            },
          }}
        >
          {colorItems.map((item) => (
            <MenuItem key={item.value} value={item.value}>
              <Box
                sx={{
                  backgroundColor: getTeamColors({ theme, color: String(item.value) }).background,
                  color: getTeamColors({ theme, color: String(item.value) }).color,
                  py: 0.5,
                  px: 1,
                  display: 'inline-block',
                  borderRadius: '100px',
                  typography: 'chipLabel',
                  '& .Mui-disabled': {
                    color: theme.palette.common.white,
                  },
                }}
              >
                {item.label}
              </Box>
            </MenuItem>
          ))}
        </TextField>
      </Box>
      {/* TODO display again when other than SHIFT_TEAM type is implemented */}
      {/* <Box sx={{ mt: 1 }}>
        <TextField
          label="Team type"
          disabled={!!selectedShiftPlanTeam}
          onChange={(event) => {
            setShiftPlanTeam({ ...shiftPlanTeam, teamType: event.target.value });
          }}
          select
          value={shiftPlanTeam.teamType || ShiftPlanTeamType.SHIFT_TEAM}
          sx={{
            '& .MuiInputBase-input': {
              height: '24px',
            },
          }}
        >
          {teamTypeOptions.map((item) => (
            <MenuItem key={item.value} value={item.value}>
              <Box
                sx={{
                  display: 'inline-block',
                  borderRadius: '100px',
                  typography: 'bodyMRegular',
                  '& .Mui-disabled': {
                    color: theme.palette.common.white,
                  },
                }}
              >
                {item.label}
              </Box>
            </MenuItem>
          ))}
        </TextField>
      </Box> */}
    </>
  );
};
