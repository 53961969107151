import { Switch } from '@mui/material';
import { ChangeEvent } from 'react';

interface CustomSwitchProps {
  onChange: (event: ChangeEvent<HTMLInputElement>, checked: boolean) => void;
  checked: boolean;
  disabled?: boolean;
}

export const CustomSwitch = ({ onChange, checked, disabled }: CustomSwitchProps) => {
  return (
    <Switch
      checked={checked}
      onChange={onChange}
      disabled={disabled}
      sx={(theme) => ({
        width: 40,
        height: 24,
        p: 0,
        '& .MuiSwitch-switchBase': {
          p: 0.5,
          '&:hover': { backgroundColor: 'transparent' },
          '&.Mui-checked': {
            transform: 'translateX(18px)',
            color: theme.palette.common.white,
            '& + .MuiSwitch-track': {
              backgroundColor: disabled ? theme.palette.gray[500] : theme.palette.purple[500],
              opacity: 1,
            },
          },
        },
        '& .MuiSwitch-thumb': {
          boxShadow: '0px 1px 2px 0px #0A0E160F, 0px 1px 3px #0A0E161A',
          width: 18,
          height: 18,
          '&::before': {
            content: "''",
            position: 'absolute',
            width: '100%',
            height: '100%',
            left: 0,
            top: 0,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
          },
        },
        '& .MuiSwitch-track': {
          borderRadius: '9999px',
          backgroundColor: theme.palette.gray[200],
          opacity: 1,
        },
      })}
    />
  );
};
