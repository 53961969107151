import { Box } from '@mui/material';
import { format, parse } from 'date-fns';
import { RosterStrikeTeamApparatus } from '@stationwise/share-types';
import { ToggleButton, ToggleButtonGroup } from '../../../Button';
import { InputLabel } from '../../../Input';

export enum PerformanceEndDateOption {
  ROSTER_DATE = 'ROSTER_DATE',
  DEACTIVATION_DATE = 'DEACTIVATION_DATE',
}

interface PerformanceEndDateProps {
  apparatus: RosterStrikeTeamApparatus;
  value: PerformanceEndDateOption | null;
  onChange: (value: PerformanceEndDateOption) => void;
}

export const PerformanceEndDate = ({ apparatus, value, onChange }: PerformanceEndDateProps) => {
  const deactivationDate = format(parse(apparatus.deactivationDate, 'yyyy-MM-dd', new Date()), 'MMM dd, yyyy');

  const options = [
    { label: 'Today only', value: PerformanceEndDateOption.ROSTER_DATE },
    { label: `Today - ${deactivationDate}`, value: PerformanceEndDateOption.DEACTIVATION_DATE },
  ];

  return (
    <Box sx={{ mt: 2 }}>
      <InputLabel>Performance dates</InputLabel>
      <ToggleButtonGroup
        exclusive
        color="primary"
        size="large"
        value={value}
        onChange={(_event, newValue) => newValue !== null && onChange(newValue)}
        aria-label="Performance dates"
        sx={{
          width: '100%',
          '& .MuiToggleButton-root': { flex: 1, p: 1 },
        }}
      >
        {options.map((option) => (
          <ToggleButton key={option.value} value={option.value}>
            {option.label}
          </ToggleButton>
        ))}
      </ToggleButtonGroup>
      <Box sx={(theme) => ({ color: theme.palette.gray[600], mt: 0.5, typography: 'bodyXSRegular' })}>
        {`${deactivationDate} is the last day of this event's performance`}
      </Box>
    </Box>
  );
};
