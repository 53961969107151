import { EventInput } from '@fullcalendar/core/index.js';
import { EventImpl } from '@fullcalendar/core/internal';
import { Box, Divider } from '@mui/material';
import { startOfDay } from 'date-fns';
import {
  theme,
  UmbrellaIcon24,
  SwitchHorizontal02Icon24,
  BankNoteIcon24,
  ChevronRightIcon24,
  XCircleIcon24,
  useLoadedAuthUserContext,
  hasTemporaryNonShiftEvent,
  hasKellyDay,
  SHIFT_TITLES,
  CALENDAR_EVENT_TYPES,
  SHIFT_OVERVIEW_OPTIONS,
  useLoadedDepartmentInfoContext,
} from '@stationwise/component-module';

interface ShiftOverviewOptionsProps {
  shift: EventInput | EventImpl;
  todayEvents: EventInput[];
  handleSelect: (option: string) => void;
  handleClose?: () => void;
}

type DateRange = 'past' | 'future' | 'today';

export const determineDateRange = (shift: EventInput | EventImpl): DateRange => {
  const today = startOfDay(new Date());
  const tomorrow = startOfDay(new Date(today));
  tomorrow.setDate(today.getDate() + 1);
  if (shift.start && new Date(shift.start as string) >= tomorrow) {
    return 'future';
  } else if (shift.start && new Date(shift.start as string) < today) {
    return 'past';
  } else {
    return 'today';
  }
};
export const ShiftOverviewOptions = ({ handleSelect, shift, todayEvents, handleClose }: ShiftOverviewOptionsProps) => {
  const { state: authUserState } = useLoadedAuthUserContext();

  const hasShiftTrade = todayEvents.some((event) => event.title === SHIFT_TITLES.TRADE_REQUESTED);
  const disableTradeShift =
    todayEvents.some((event) => event.title === SHIFT_TITLES.OVERTIME) || shift.extendedProps?.['isOvertime'] === true;
  const dateRange = determineDateRange(shift);

  const { state: departmentInfoState } = useLoadedDepartmentInfoContext();
  const additionalPaidTimeRequestsEnabled = departmentInfoState.departmentInfo.settings.additionalPaidTimeRequestsEnabled;

  const handleOnClick = (option: string) => {
    handleSelect(option);
    if (handleClose) {
      handleClose();
    }
  };

  if (hasTemporaryNonShiftEvent(todayEvents) || hasKellyDay(todayEvents)) {
    return null;
  }

  if (shift.extendedProps?.['eventType'] === CALENDAR_EVENT_TYPES.STAFFING_LIST_MARK) {
    return (
      <>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          onClick={() => handleOnClick(SHIFT_OVERVIEW_OPTIONS.CANCEL_OVERTIME_REQUEST)}
          sx={(theme) => ({
            py: theme.spacing(2),
            cursor: 'pointer',
          })}
        >
          <Box display="flex">
            <Box
              sx={(theme) => ({
                color: theme.palette.rose[400],
              })}
            >
              <XCircleIcon24 />
            </Box>
            <Box
              sx={{
                ml: theme.spacing(2),
                color: theme.palette.rose[600],
                typography: 'bodyLMedium',
              }}
            >
              {`Cancel ${shift.title?.toLowerCase()}`}
            </Box>
          </Box>

          <Box sx={(theme) => ({ color: theme.palette.gray[400] })}>
            <ChevronRightIcon24 />
          </Box>
        </Box>

        <Divider light />
      </>
    );
  }

  return (
    <>
      {!authUserState.employee.isNonShift && dateRange !== 'past' && !hasShiftTrade && !disableTradeShift ? (
        <>
          <Box
            data-cy="trade-shift-button"
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            onClick={() => handleOnClick(SHIFT_OVERVIEW_OPTIONS.TRADE_SHIFT)}
            sx={(theme) => ({
              py: theme.spacing(2),
              cursor: 'pointer',
            })}
          >
            <Box display="flex">
              <Box
                sx={(theme) => ({
                  '& svg': {
                    '& path': {
                      stroke: theme.palette.gray[400],
                    },
                  },
                })}
              >
                <SwitchHorizontal02Icon24 />
              </Box>
              <Box
                sx={{
                  ml: theme.spacing(2),
                  color: theme.palette.gray[700],
                  typography: 'bodyLMedium',
                }}
              >
                Trade shift
              </Box>
            </Box>

            <Box sx={(theme) => ({ color: theme.palette.gray[400] })}>
              <ChevronRightIcon24 />
            </Box>
          </Box>
          <Divider light />
        </>
      ) : null}

      {dateRange !== 'past' ? (
        <>
          <Box
            data-cy="request-time-off-button"
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            onClick={() => handleOnClick(SHIFT_OVERVIEW_OPTIONS.REQUEST_TIME_OFF)}
            sx={(theme) => ({
              py: theme.spacing(2),
              cursor: 'pointer',
            })}
          >
            <Box display="flex">
              <Box
                sx={(theme) => ({
                  '& svg': {
                    '& path': {
                      stroke: theme.palette.gray[400],
                    },
                  },
                })}
              >
                <UmbrellaIcon24 />
              </Box>
              <Box
                sx={{
                  ml: theme.spacing(2),
                  color: theme.palette.gray[700],
                  typography: 'bodyLMedium',
                }}
              >
                Request time off
              </Box>
            </Box>
            <Box sx={(theme) => ({ color: theme.palette.gray[400] })}>
              <ChevronRightIcon24 />
            </Box>
          </Box>
          <Divider light />
        </>
      ) : null}

      {dateRange !== 'future' ? (
        <>
          <Box
            data-cy="request-additional-paid-time-button"
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            onClick={() => handleOnClick(SHIFT_OVERVIEW_OPTIONS.ADDITIONAL_PAID_TIME)}
            sx={(theme) => ({
              py: theme.spacing(2),
              cursor: 'pointer',
            })}
          >
            <Box display="flex">
              <Box
                sx={(theme) => ({
                  '& svg': {
                    '& path': {
                      stroke: theme.palette.gray[400],
                    },
                  },
                })}
              >
                <BankNoteIcon24 />
              </Box>
              {additionalPaidTimeRequestsEnabled && (
                <Box
                  sx={{
                    ml: theme.spacing(2),
                    color: theme.palette.gray[700],
                    typography: 'bodyLMedium',
                  }}
                >
                  Request additional paid time
                </Box>
              )}
            </Box>
            <Box sx={(theme) => ({ color: theme.palette.gray[400] })}>
              <ChevronRightIcon24 />
            </Box>
          </Box>
          <Divider light />
        </>
      ) : null}
    </>
  );
};
