import { Box, Theme, useMediaQuery } from '@mui/material';
import { Link } from 'react-router-dom';
import { useLoadedAuthUserContext } from '@stationwise/component-module';
import { EmployeeTypes } from '@stationwise/share-types';
import { calendarOptions, navigationItems } from '../../../app/AppPageLayout';
import { CalendarDisplayOptions } from '../../../app/pages/Calendar/components/CalendarDisplayOptions';
import { useSelectMenu } from '../../hooks/useSelectMenu';
import { MenuIcon } from '../SideBar/MenuIcon';

export const SubNavigation = () => {
  const { state: authUserState } = useLoadedAuthUserContext();
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
  const { selectedMenu } = useSelectMenu();
  const menuItems = navigationItems.filter((item) => item.type === 'my-dashboard' || item.type === 'calendar');

  return (
    <Box
      display="flex"
      width="100%"
      sx={(theme) => ({
        flexDirection: 'column',
        height: '56px',
        justifyContent: 'center',
        alignItems: 'flex-start',
        background: theme.palette.common.white,
        borderBottom: {
          xs: `1px dashed ${theme.palette.gray[100]}`,
          md: 'none',
        },
        margin: '0 auto',
      })}
    >
      <Box
        alignItems="center"
        display="flex"
        sx={{
          gap: '6px',
          paddingLeft: '16px',
        }}
      >
        {isMobile &&
          authUserState.employee.employeeType !== EmployeeTypes.VOLUNTEER &&
          !authUserState.employee.isNonShift &&
          menuItems.map((item) => {
            return (
              <Box
                key={item.type}
                sx={(theme) => ({
                  display: 'flex',
                  alignItems: 'center',
                  width: '40px',
                  height: '40px',
                  borderRadius: '8px',
                  background: selectedMenu === item.type ? theme.palette.common.white : '',
                  border: selectedMenu === item.type ? `1px solid ${theme.palette.gray[100]}` : '',
                })}
              >
                <MenuIcon
                  type={item.type}
                  component={Link}
                  to={item.path}
                  isActive={selectedMenu === item.type}
                  placement="bottom"
                />
              </Box>
            );
          })}
        {(selectedMenu === 'calendar' || selectedMenu === 'you') && <CalendarDisplayOptions calendarOptions={calendarOptions} />}
      </Box>
    </Box>
  );
};
