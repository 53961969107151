import { Box, Typography, useTheme } from '@mui/material';
import { captureException } from '@sentry/react';
import { useEffect, useState } from 'react';
import { ErrorBanner } from '@stationwise/component-module';
import { client, isAxiosError } from '@stationwise/share-api';
import { WorkGroupPreview } from '@stationwise/share-types';
import { WorkGroupsMain } from './WorkGroupsMain';

export const WorkGroupsPage = () => {
  const [workGroups, setWorkGroups] = useState<WorkGroupPreview[]>([]);
  const [errorMessage, setErrorMessage] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const theme = useTheme();
  const fetchWorkGroupView = async (): Promise<WorkGroupPreview[]> => {
    try {
      const response = await client.get('/organization/workgroups/detailed-list/');
      setIsSubmitting(false);
      return response.data;
    } catch (error) {
      const message = isAxiosError(error) ? error.response?.data.error : '';
      if (!message) {
        captureException(error);
      }
      setErrorMessage(message || 'Something went wrong please try again later');
      setIsSubmitting(false);
      return [];
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      setIsSubmitting(true);
      setErrorMessage('');
      const response = await fetchWorkGroupView();
      setWorkGroups(response);
    };
    fetchData();
  }, []);

  const errorBanner = !isSubmitting && !!errorMessage && <ErrorBanner sx={{ mt: -1, mb: 2 }}>{errorMessage}</ErrorBanner>;

  return (
    <Box sx={{ py: theme.spacing(4), px: theme.spacing(5) }}>
      <Box sx={{ mb: theme.spacing(2) }}>
        <Typography sx={{ color: theme.palette.gray[900], typography: 'heading4' }}>Work Groups</Typography>
      </Box>
      {errorBanner}
      <WorkGroupsMain workGroupsView={workGroups} />
    </Box>
  );
};
