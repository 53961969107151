import { Box, Divider } from '@mui/material';
import { format } from 'date-fns';
import { BigArrowRightIcon, BlankCard, Chip, theme } from '@stationwise/component-module';
import { PayrollCode } from '@stationwise/share-types';
import { getColorForPayCodeType } from './utils';

interface AccrueCompTimeCardProps {
  children?: React.ReactNode;
  day: Date;
  endDate: Date;
  hour: number;
  convertRatio: number;
  isSelected: boolean;
  onClick?: () => void;
  payrollCode: PayrollCode | null;
}

export const AccrueCompTimeCard = ({
  children,
  day,
  endDate,
  hour,
  convertRatio,
  isSelected,
  onClick,
  payrollCode,
}: AccrueCompTimeCardProps) => {
  return (
    <BlankCard
      onClick={onClick}
      isSelected={isSelected}
      selectedColor={theme.palette.gray[600]}
      selectedBorderWidth="1px"
      sxProps={{
        gap: theme.spacing(1),
        mb: theme.spacing(2),
      }}
    >
      <Box
        typography="bodyLSemibold"
        display="flex"
        justifyContent="space-between"
        sx={{ mt: theme.spacing(1), mb: theme.spacing(1) }}
      >
        Overtime pay code
      </Box>
      {payrollCode && (
        <Box display="flex" justifyContent="space-between">
          <Chip
            label={payrollCode.name.toUpperCase()}
            chipSxProps={() => ({
              background: getColorForPayCodeType(payrollCode.payCodeType, theme),
              height: '24px',
              width: '110%',
              p: '4px 2px',
              borderRadius: '7px',
              '&:after': {
                position: 'absolute',
                height: '100%',
                width: '10px',
                right: '-4px',
              },
              '&:before': {
                position: 'absolute',
                height: '100%',
                width: '10px',
                left: '-4px',
              },
            })}
            variant="eyebrow"
          />
        </Box>
      )}
      <Box sx={{ display: 'flex', typography: 'bodyMRegular', justifyContent: 'space-between' }}>
        <Box display={'flex'}>
          <Box>{format(day, 'MM/dd/yy')}</Box>
          <Box sx={{ ml: '9px', mr: '9px' }}>
            <BigArrowRightIcon />
          </Box>
          <Box>{format(endDate, 'MM/dd/yy')}</Box>
        </Box>
        <Box>{hour.toFixed(1)} hrs</Box>
      </Box>
      <Divider
        sx={{
          mt: theme.spacing(1),
          mb: theme.spacing(1),
          backgroundColor: theme.palette.gray[200],
          height: '1px',
        }}
      />
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          typography: 'bodyMRegular',
          color: theme.palette.gray[500],
        }}
      >
        <Box>Total time to accrue</Box>
        <Box sx={{ color: theme.palette.gray[900], fontWeight: 600 }}>{(hour * convertRatio).toFixed(1)} hrs</Box>
      </Box>
      {children}
    </BlankCard>
  );
};
