import { Alert, Box, Dialog } from '@mui/material';
import { setHours } from 'date-fns';
import React, { useEffect, useState } from 'react';
import { ArrowRightIcon20, Button, DialogXButton, useRosterContext } from '@stationwise/component-module';
import { ForceShiftTradeEligibleEmployee } from '@stationwise/share-types';
import { forceShiftTrade } from '@stationwise/shift-summary-helper';
import { Footer } from './Footer';
import { SelectAvailableEmployee } from './SelectAvailableEmployee';
import { ShiftTradeHeader } from './ShiftTradeHeader';
import { ShiftTradeOptions, getShiftTradeDuration } from './ShiftTradeOptions';

export const ForceShiftTradeModal = () => {
  const {
    preferences,
    forceShiftTradeState,
    selectedFilledPositionState,
    shiftSummaryHelper,
    setShiftSummaryHelper,
    setUserHasMadeChanges,
    setLastChanges,
  } = useRosterContext();
  const isOvertimeShiftTrade = forceShiftTradeState.isOvertimeShiftTrade;
  const { employee } = selectedFilledPositionState;

  const [step, setStep] = useState(1);
  const [start, setStart] = useState(shiftSummaryHelper.shiftDuration.startTime);
  const [duration, setDuration] = useState(0);
  const end = setHours(start, start.getHours() + duration);
  const [receiverEmployee, setReceiverEmployee] = useState<ForceShiftTradeEligibleEmployee | null>(null);

  useEffect(() => {
    if (employee) {
      setStart(employee.startDateTime);
      setDuration(getShiftTradeDuration(employee.startDateTime, employee.endDateTime));
    }
  }, [employee]);

  const handleClose = () => {
    selectedFilledPositionState.closeDialog();
    forceShiftTradeState.setIsDialogOpen(false);
    setStep(1);
    employee && setStart(employee.startDateTime);
    employee && setDuration(getShiftTradeDuration(employee.startDateTime, employee.endDateTime));
    setReceiverEmployee(null);
  };

  const handleSubmit = () => {
    if (!employee || !receiverEmployee || !preferences.selectedBattalionId || !forceShiftTradeState.isDialogOpen) return;

    const { newShiftSummaryHelper, newEmployee, error } = forceShiftTrade(
      shiftSummaryHelper,
      employee.id,
      { ...receiverEmployee, name: `${receiverEmployee.firstName} ${receiverEmployee.lastName}` },
      start,
      end,
      isOvertimeShiftTrade,
    );
    setShiftSummaryHelper(newShiftSummaryHelper);
    setUserHasMadeChanges(true);
    newEmployee && error && setLastChanges({ shiftSummaryHelper, employee: newEmployee, error });
    handleClose();
  };

  if (!employee) {
    return null;
  }

  return (
    <Dialog
      sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
      open={forceShiftTradeState.isDialogOpen}
      onClose={() => handleClose()}
    >
      <DialogXButton onClose={() => handleClose()} />
      <Box
        sx={(theme) => ({
          backgroundColor: theme.palette.common.white,
          justifyContent: 'center',
          borderRadius: theme.spacing(1.5),
          p: theme.spacing(0),
          display: 'flex',
          flexDirection: 'column',
          flex: 1,
          maxWidth: '100%',
          minHeight: '1px',
        })}
      >
        <Box sx={{ width: '428px', maxWidth: '100%' }} />
        <>
          <Box
            sx={(theme) => ({
              px: theme.spacing(3),
              pt: theme.spacing(3),
              typography: 'bodyXLSemibold',
              display: 'flex',
            })}
          >
            {isOvertimeShiftTrade ? 'Force overtime trade' : 'Force shift trade'}
          </Box>
          {isOvertimeShiftTrade && (
            <Alert severity="info" sx={(theme) => ({ mt: 1, mb: 2, mx: 1, backgroundColor: theme.palette.blue[200] })}>
              • Trade responder will receive overtime pay
              <br />
              • Trade requester will be deprioritized for staffing list
              <br />• There will be no trade debt between the employees
            </Alert>
          )}

          <ShiftTradeHeader employee={employee} start={start} end={end} />

          {step === 1 && (
            <Box sx={(theme) => ({ p: theme.spacing(3) })}>
              <ShiftTradeOptions
                startDateTime={start}
                setStartDateTime={setStart}
                duration={duration}
                setDuration={setDuration}
                originalEndDateTime={employee.endDateTime}
                originalStartDateTime={employee.startDateTime}
              />
              <Box
                sx={(theme) => ({
                  mt: theme.spacing(3),
                })}
              >
                <Button
                  variant="contained"
                  size="large"
                  sx={{ gap: 1, width: '100%' }}
                  onClick={() => setStep(2)}
                  disabled={duration === 0}
                >
                  Select people to trade with
                  <ArrowRightIcon20 />
                </Button>
              </Box>
            </Box>
          )}
          {step === 2 && (
            <>
              <Box sx={(theme) => ({ flex: 1, p: theme.spacing(3), minHeight: '225px', overflowY: 'auto' })}>
                <SelectAvailableEmployee
                  employeeId={employee.id}
                  start={start}
                  end={end}
                  handleSubmit={handleSubmit}
                  receiverEmployee={receiverEmployee}
                  setReceiverEmployee={setReceiverEmployee}
                />
              </Box>
              <Footer handleSubmit={handleSubmit} setStep={setStep} selectedPerson={receiverEmployee?.id ?? ''} />
            </>
          )}
        </>
      </Box>
    </Dialog>
  );
};
