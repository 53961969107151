import { Box, Typography } from '@mui/material';
import { Dispatch, SetStateAction } from 'react';
import { Select, SelectItem, useLoadedDepartmentInfoContext, ToDoListIcon24, theme } from '@stationwise/component-module';

interface RightSideFiltersProps {
  daysAheadList: SelectItem[];
  selectedDaysAhead: string;
  setSelectedDaysAhead: (selectedOption: string) => void;
  shifts: string[];
  selectedShift: string;
  setSelectedShift: (selectedOption: string) => void;
  selectedBattalionId: number | undefined;
  setSelectedBattalionId: Dispatch<SetStateAction<number | undefined>>;
  setOpen: Dispatch<SetStateAction<boolean>>;
  open: boolean;
  count: number;
}

export const RightSideFilters = ({
  daysAheadList,
  selectedDaysAhead,
  setSelectedDaysAhead,
  shifts,
  selectedShift,
  setSelectedShift,
  selectedBattalionId,
  setSelectedBattalionId,
  setOpen,
  open,
  count,
}: RightSideFiltersProps) => {
  const { state: departmentInfoState } = useLoadedDepartmentInfoContext();
  const battalionsList: SelectItem[] = departmentInfoState.departmentInfo.battalions.map((battalion) => {
    return {
      label: battalion.name,
      value: `${battalion.id}`,
    };
  });
  const selectedBattalion = battalionsList.find((item) => item.value === `${selectedBattalionId}`) ?? battalionsList[0];

  const toggleDrawer = (newOpen: boolean) => () => {
    setOpen(newOpen);
  };

  return (
    <Box display="flex" sx={{ minWidth: 0 }}>
      <Select
        items={daysAheadList}
        onChange={(selectedOption: string) => setSelectedDaysAhead(selectedOption)}
        selected={selectedDaysAhead}
      />
      {shifts && selectedShift && (
        <Select items={shifts} onChange={(selectedOption: string) => setSelectedShift(selectedOption)} selected={selectedShift} />
      )}
      {battalionsList.length > 1 && (
        <Select
          items={battalionsList}
          onChange={(selectedOption: string) => setSelectedBattalionId(Number(selectedOption))}
          selected={selectedBattalion.value}
        />
      )}
      <Box sx={{ position: 'relative', cursor: 'pointer' }} data-cy="requests-panel-button">
        <Box
          sx={(theme) => ({
            color: open ? theme.palette.common.white : theme.palette.gray[900],
            backgroundColor: open ? theme.palette.gray[900] : theme.palette.common.white,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: '40px',
            height: '40px',
            borderRadius: '8px',
            border: 'solid 1px',
            borderColor: !open ? theme.palette.gray[200] : '',
            ml: theme.spacing(2),
            mr: theme.spacing(3),
            cursor: 'pointer',
            '&:hover': {
              backgroundColor: !open ? theme.palette.gray[100] : '',
              borderColor: !open ? theme.palette.gray[400] : '',
            },
          })}
          onClick={toggleDrawer(!open)}
        >
          <ToDoListIcon24 />
        </Box>

        {count > 0 && (
          <Box position="absolute" top="-8px" left="42px">
            <Box
              border={`2px solid  ${theme.palette.common.white}`}
              sx={(theme) => ({
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                borderRadius: '20px',
                background: theme.palette.electric[400],
                height: '20px',
                width: '20px',
                padding: '2px 6px 2px 6px',
              })}
              color={theme.palette.common.black}
            >
              <Typography sx={{ typography: 'bodyXSMedium' }}>{count}</Typography>
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  );
};
