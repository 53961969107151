import { Box } from '@mui/material';
import { useState } from 'react';
import {
  Badge,
  Button,
  AccrualsCard,
  Pencil01Icon24,
  useLoadedDepartmentInfoContext,
  useAuthUserCapabilities,
  CalendarModal,
} from '@stationwise/component-module';
import { EmployeeProfileDetailsView, EmployeeTimeOffs } from '@stationwise/share-types';
import { makeTestIdentifier } from '@stationwise/share-utils';
import { PromoteModal } from '../PromoteModal';
import { EditBasicInfo } from './Edit/EditBasicInfo';
import { TimeOffSection } from './TimeOffs/TimeOffSection';

type AccrualsSectionProps = {
  handleRefresh: () => void;
  employee: EmployeeProfileDetailsView;
  timeOffs: EmployeeTimeOffs;
};

export const AccrualsSection = ({ handleRefresh, employee, timeOffs }: AccrualsSectionProps) => {
  const [showModal, setShowModal] = useState(false);
  const [employeeCalendarModalOpen, setEmployeeCalendarModalOpen] = useState<boolean>(false);
  const [promoteEmployeeModalOpen, setPromoteEmployeeModalOpen] = useState<boolean>(false);
  const { state: departmentContext } = useLoadedDepartmentInfoContext();
  const capabilities = useAuthUserCapabilities();
  const canEditEmployeeProfile = capabilities.EDIT_EMPLOYEE_PROFILE;
  const canEditAccruals = capabilities.EDIT_ACCRUALS;
  const isAccrualsEnabled = departmentContext.departmentInfo.settings.accrualLogicEnabled;

  const showEditModal = () => {
    setShowModal(true);
  };
  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      width="100%"
      sx={(theme) => ({
        backgroundColor: theme.palette.common.white,
        borderRadius: 2,
        border: `1px solid ${theme.palette.gray[200]}`,
        p: theme.spacing(2, 3, 3, 3),
        gap: theme.spacing(3),
      })}
    >
      <Box
        sx={{
          display: 'flex',
          width: '100%',
          justifyContent: 'flex-start',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            width: '100%',
            alignItems: 'center',
            justifyContent: 'flex-start',
            gap: 1,
          }}
        >
          {employee.isActive ? (
            <Badge label="Active" isRound type="lg" color={'green'} variant="bodyMRegular" />
          ) : (
            <Badge label="Inactive" isRound type="lg" variant="bodyMRegular" />
          )}
        </Box>

        {canEditEmployeeProfile && (
          <Box sx={{ cursor: 'pointer', display: 'flex' }}>
            <Pencil01Icon24 onClick={showEditModal} data-cy="edit-employee-profile-button" />
          </Box>
        )}
      </Box>
      <Box
        sx={(theme) => ({
          borderRadius: '50%',
          backgroundColor: theme.palette.lightBlue[500],
          color: theme.palette.common.white,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          width: 112,
          height: 112,
          typography: 'heading1',
          lineHeight: '64px',
          textTransform: 'uppercase',
        })}
      >
        {employee.firstName.charAt(0)}
      </Box>
      <Box
        sx={{
          typography: 'heading4',
        }}
        data-cy={`employee-name-${makeTestIdentifier(employee.firstName)}-${makeTestIdentifier(employee.lastName)}`}
      >
        {employee.firstName} {employee.lastName}
      </Box>

      <Button
        variant="contained"
        sx={{ width: '100%' }}
        onClick={() => setEmployeeCalendarModalOpen(true)}
        data-cy="view-personal-schedule-button"
      >
        View personal schedule
      </Button>
      <Button variant="outlined" sx={{ width: '100%' }} onClick={() => setPromoteEmployeeModalOpen(true)}>
        Promote Employee
      </Button>
      <CalendarModal
        setModalOpen={setEmployeeCalendarModalOpen}
        modalOpen={employeeCalendarModalOpen}
        employee={{
          ...employee,
          name: `${employee.firstName} ${employee.lastName}`,
          ...(employee.plannedShiftDetails &&
            employee.plannedShiftDetails.length > 0 && {
              team: {
                id: Number(employee.plannedShiftDetails[0].teamId) ?? -1,
                name: employee.plannedShiftDetails[0].teamName,
                color: employee.plannedShiftDetails[0].shiftColor,
              },
            }),
        }}
      />
      <PromoteModal
        showModal={promoteEmployeeModalOpen}
        setShowModal={setPromoteEmployeeModalOpen}
        selectedRanks={[employee.rank]}
        selectedEmployeeIds={[employee.id]}
        employees={[employee]}
        handleRefresh={handleRefresh}
      />

      {employee.accruals && isAccrualsEnabled && (
        <AccrualsCard accruals={employee.accruals} handleRefresh={handleRefresh} showEditModal={canEditAccruals} />
      )}
      <EditBasicInfo
        showModal={showModal}
        setShowModal={setShowModal}
        employeeFirstName={employee.firstName}
        employeeLastName={employee.lastName}
      />
      <TimeOffSection timeOffs={timeOffs} />
    </Box>
  );
};
