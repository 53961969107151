import Box from '@mui/material/Box';
import { useState } from 'react';
import { ChevronRightIcon16 } from '@stationwise/component-module';
import { prettifyPhoneNumber } from '@stationwise/share-utils';
import { EditEmployeeInfo } from './Edit/EditEmployeeInfo';
import { InfoCard, InfoCardField } from './InfoCard';

export type EmployeeInfoSectionProps = {
  phone: string | null;
  email: string | null;
  payrollId: string | null;
  emergencyContactName: string | null;
  emergencyContactPhone: string | null;
  emergencyContactRelationship: string | null;
  optionalEmergencyContactName: string | null;
  optionalEmergencyContactPhone: string | null;
  optionalEmergencyContactRelationship: string | null;
};

export const EmployeeInfoSection = (props: EmployeeInfoSectionProps) => {
  const [showModal, setShowModal] = useState(false);
  const fieldDefs: InfoCardField[][] = [
    [
      {
        label: 'Phone',
        field: {
          value: props.phone ? prettifyPhoneNumber(props.phone) : 'None',
          type: 'text',
        },
      },
      {
        label: 'Email',
        field: {
          value: props.email ?? 'None',
          type: 'text',
        },
      },
    ],
    [
      {
        label: 'Employee ID',
        field: {
          value: props.payrollId,
          type: 'text',
        },
      },
      {
        label: 'Emergency Contact',
        field: {
          type: 'custom',
          extra: {
            custom:
              !props.emergencyContactPhone && !props.emergencyContactName && !props.emergencyContactRelationship ? (
                <Box
                  sx={{
                    typography: 'bodyMMedium',
                  }}
                >
                  None
                </Box>
              ) : (
                <Box
                  sx={(theme) => ({
                    display: 'flex',
                    alignItems: 'center',
                    flexWrap: 'wrap',
                    gap: theme.spacing(0.5),
                    typography: 'bodyMMedium',
                    '& svg path': {
                      stroke: theme.palette.gray[400],
                    },
                  })}
                >
                  <span>
                    {props.emergencyContactName}{' '}
                    {props.emergencyContactRelationship ? `(${props.emergencyContactRelationship})` : ''}
                  </span>
                  <ChevronRightIcon16 />
                  <span>{props.emergencyContactPhone ? prettifyPhoneNumber(props.emergencyContactPhone) : 'None'}</span>
                </Box>
              ),
          },
        },
      },
    ],
    props.optionalEmergencyContactName || props.optionalEmergencyContactPhone || props.optionalEmergencyContactRelationship
      ? [
          {
            label: 'Emergency Contact #2',
            field: {
              type: 'custom',
              extra: {
                custom: (
                  <Box
                    sx={(theme) => ({
                      display: 'flex',
                      alignItems: 'center',
                      flexWrap: 'wrap',
                      gap: theme.spacing(0.5),
                      typography: 'bodyMMedium',
                      '& svg path': {
                        stroke: theme.palette.gray[400],
                      },
                    })}
                  >
                    <span>
                      {props.optionalEmergencyContactName}{' '}
                      {props.optionalEmergencyContactRelationship ? `(${props.optionalEmergencyContactRelationship})` : ''}
                    </span>
                    <ChevronRightIcon16 />
                    <span>
                      {props.optionalEmergencyContactPhone ? prettifyPhoneNumber(props.optionalEmergencyContactPhone) : 'None'}
                    </span>
                  </Box>
                ),
              },
            },
          },
        ]
      : [],
  ];

  const handleEditModal = () => {
    setShowModal(true);
  };

  return (
    <>
      <EditEmployeeInfo showModal={showModal} setShowModal={setShowModal} employeeInfo={props} />
      <Box
        width="100%"
        sx={(theme) => ({
          backgroundColor: theme.palette.common.white,
          borderRadius: 2,
          border: `1px solid ${theme.palette.gray[200]}`,
          p: theme.spacing(4, 3, 3, 3),
          gap: theme.spacing(3),
        })}
      >
        <InfoCard fieldDefs={fieldDefs} title="Employee Info" handleEditModal={handleEditModal} />
      </Box>
    </>
  );
};
