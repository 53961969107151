import { ReactComponent as MoreHorizSymbol } from '@material-symbols/svg-400/outlined/more_horiz.svg';
import { Box, ClickAwayListener, Popper } from '@mui/material';
import { useState } from 'react';
import { SvgIcon } from '@stationwise/component-module';
import { SubMenu as MenuOptions } from '../../MessagesInbox/ConversationCard/SubMenu';

interface SubMenuProps {
  onCancelConversation?: (id: number) => void;
  conversationId: number;
}

export const SubMenu = ({ conversationId, onCancelConversation }: SubMenuProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [isOpen, setIsOpen] = useState(false);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    if (anchorEl) {
      setIsOpen(false);
      setAnchorEl(null);
    } else {
      setIsOpen(true);
      setAnchorEl(event.currentTarget);
    }
  };

  const handleClickAway = () => {
    setAnchorEl(null);
    setIsOpen(false);
  };

  return (
    <>
      <Box
        className="message-header-more-icon"
        sx={(theme) => ({
          mr: theme.spacing(2),
          backgroundColor: isOpen ? theme.palette.gray[100] : 'inherit',
          '&:hover': {
            cursor: 'pointer',
          },
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          width: '28px',
          height: '28px',
          borderRadius: '8px',
          padding: '2px',
        })}
        onClick={handleClick}
      >
        <SvgIcon
          component={MoreHorizSymbol}
          sx={{
            height: '18px',
            width: '18px',
            stroke: 'currentColor',
            strokeWidth: 80,
          }}
        />
      </Box>
      <ClickAwayListener onClickAway={handleClickAway}>
        <Popper id={'message-popper'} open={Boolean(anchorEl)} anchorEl={anchorEl} placement="bottom-end">
          <MenuOptions conversationId={conversationId} onCancelConversation={onCancelConversation} />
        </Popper>
      </ClickAwayListener>
    </>
  );
};
