import { ReactComponent as FullscreenSymbol } from '@material-symbols/svg-400/outlined/fullscreen.svg';
import { Box, IconButton, useTheme } from '@mui/material';
import { SvgIcon } from '@stationwise/component-module';
import { useShiftPlanContext } from '../ShiftPlanContext';
import { BattalionSelect } from './BattalionSelect';

export const RosterEditorHeader = () => {
  const theme = useTheme();
  const { selectedPersonnelStruct, setSelectedPersonnelStruct } = useShiftPlanContext();
  const isPersonnelOpen = !!(selectedPersonnelStruct.apparatus && !selectedPersonnelStruct.apparatus.isReserved);

  const renderCompactHeader = () => {
    return (
      <Box
        sx={{
          background: theme.palette.common.white,
          position: 'sticky',
          top: 0,
          left: 0,
          width: '100%',
          p: 1.5,
          zIndex: 1,
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Box sx={{ typography: 'bodyXXLRegular' }}>Your roster</Box>
          <Box sx={{ flex: 1 }} />
          <IconButton onClick={() => setSelectedPersonnelStruct({})}>
            <SvgIcon component={FullscreenSymbol} />
          </IconButton>
        </Box>
        <BattalionSelect sx={{ mt: 1, width: '100%' }} />
      </Box>
    );
  };

  const renderFullWidthHeader = () => {
    return (
      <Box sx={{ display: 'flex', alignItems: 'center', p: 1.5 }}>
        <Box sx={{ typography: 'bodyXXLRegular', mr: 3 }}>Your roster</Box>
        <BattalionSelect />
      </Box>
    );
  };

  return isPersonnelOpen ? renderCompactHeader() : renderFullWidthHeader();
};
