import { Box, Dialog } from '@mui/material';
import { ResponsibilityForm } from './ResponsibilityForm';

interface AddResponsibilityModalProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  currentDate: Date;
  forceFetch: () => void;
}

export const AddResponsibilityModal = ({ open, setOpen, currentDate, forceFetch }: AddResponsibilityModalProps) => {
  const onClose = () => {
    setOpen(false);
  };

  return (
    <Dialog open={open} onClose={onClose} sx={{ '& .MuiDialog-paper': { borderRadius: '16px', position: 'relative' } }}>
      <Box sx={(theme) => ({ width: '500px', padding: theme.spacing(2) })}>
        <Box
          sx={(theme) => ({
            typography: theme.typography.h6,
            color: theme.palette.gray[900],
            pb: theme.spacing(4),
          })}
        >
          Add New Responsibility
        </Box>
        <ResponsibilityForm setShowResponsibilityOpen={setOpen} currentDate={currentDate} forceFetch={forceFetch} />
      </Box>
    </Dialog>
  );
};
