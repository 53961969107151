import { Box } from '@mui/material';
import { format, parseISO } from 'date-fns';
import { TEMPORARY_NON_SHIFT_TITLES, TemporaryNonShiftAssignment } from '@stationwise/share-types';

interface NonShiftDetailsProps {
  assignment: TemporaryNonShiftAssignment;
}

export const NonShiftDetails = ({ assignment }: NonShiftDetailsProps) => {
  return (
    <Box
      sx={(theme) => ({
        backgroundColor: theme.palette.gray[800],
        borderRadius: 2,
        p: theme.spacing(1.5, 1),
        mb: 2,
      })}
    >
      <Box
        sx={(theme) => ({
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          color: theme.palette.common.white,
          typography: 'bodySRegular',
          mb: 2,
        })}
      >
        <Box>{`${TEMPORARY_NON_SHIFT_TITLES.get(assignment.temporaryNonShiftType)} details`}</Box>
      </Box>
      <Box
        sx={(theme) => ({
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          color: theme.palette.common.white,
          typography: 'bodySRegular',
          mb: 2,
        })}
      >
        <Box sx={(theme) => ({ color: theme.palette.gray[400] })}>Start Date</Box>
        <Box display="flex" gap={3}>
          {!!assignment.activationDate && <Box>{format(parseISO(assignment.activationDate), 'MMM d, yyyy')}</Box>}
        </Box>
      </Box>
      <Box
        sx={(theme) => ({
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          color: theme.palette.common.white,
          typography: 'bodySRegular',
          mb: 2,
        })}
      >
        <Box sx={(theme) => ({ color: theme.palette.gray[400] })}>End Date</Box>
        <Box display="flex" gap={3}>
          {!!assignment.deactivationDate && <Box>{format(parseISO(assignment.deactivationDate), 'MMM d, yyyy')}</Box>}
        </Box>
      </Box>
    </Box>
  );
};
