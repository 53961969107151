import { Box } from '@mui/material';
import { Fragment } from 'react';
import { ChevronRightIcon16, theme, useRosterContext } from '@stationwise/component-module';
import { EmployeeOffView, RosterStation, RosterApparatus, RosterEmployee } from '@stationwise/share-types';
import { getPositionSplits } from '@stationwise/shift-summary-helper';
import { Cell, COLUMNS } from './Cell';
import {
  OvertimeEmployeeAssignment,
  PositionRows,
  renderAbsentPersonnel,
  renderFloaters,
  renderOvertimeHires,
} from './PositionRows';
import { Row } from './Row';

interface PrintDayScheduleBoxProps {
  stations: RosterStation[];
  positionEmployeesOffMap: Map<string, EmployeeOffView[]>;
  isLastBox: boolean;
  overtimeEmployees: OvertimeEmployeeAssignment[];
  floaters: RosterEmployee[];
}

export const PrintDayScheduleBox = ({
  stations,
  positionEmployeesOffMap,
  isLastBox,
  overtimeEmployees,
  floaters,
}: PrintDayScheduleBoxProps) => {
  const { shiftSummaryHelper } = useRosterContext();

  return (
    <Box
      sx={(theme) => ({
        border: 'transparent',
        borderRadius: theme.spacing(1),
        height: 'fit-content',
        overflow: 'hidden',
      })}
    >
      <Row sx={{ borderTopWidth: 0 }}>
        {COLUMNS.map((column, colIndex) => (
          <Cell key={colIndex} colIndex={colIndex} sx={{ fontWeight: '400' }}>
            {colIndex === 0 ? <Box sx={{ color: '#B3B3B3' }}>{column.name}</Box> : column.name}
          </Cell>
        ))}
      </Row>

      {stations.map((station) => (
        <Fragment key={station.stationId + station.apparatuses[0].id}>
          {station.apparatuses.map((apparatus) => (
            <Fragment key={apparatus.id}>
              <ApparatusSection station={station} apparatus={apparatus} />
            </Fragment>
          ))}
        </Fragment>
      ))}

      {isLastBox && (
        <>
          {renderFloaters(shiftSummaryHelper, floaters)}
          {renderAbsentPersonnel(positionEmployeesOffMap)}
          {renderOvertimeHires(overtimeEmployees)}
        </>
      )}
    </Box>
  );
};

interface ApparatusSectionProps {
  station: RosterStation;
  apparatus: RosterApparatus;
}

export const ApparatusSection = ({ station, apparatus }: ApparatusSectionProps) => {
  let rowCounter = 0;
  return (
    <Box sx={{ paddingBottom: '8px' }}>
      <Row
        sx={{
          background: '#CACACA',
          borderColor: '#CACACA',
        }}
      >
        <Cell colIndex={0} sx={{ width: '100%', color: theme.palette.gray[900] }}>
          <Box
            sx={(theme) => ({
              display: 'flex',
              alignItems: 'center',
              gap: 0.5,
              pl: theme.spacing(0.5),
              fontWeight: '500',
            })}
          >
            {station.stationName}
            <ChevronRightIcon16 height={12} width={12} />
            {apparatus.name}
          </Box>
        </Cell>
      </Row>

      {apparatus.positions.map((position) => {
        const splits = getPositionSplits(position);
        rowCounter = rowCounter + splits.length;
        return <PositionRows key={position.id} position={position} rowNumber={rowCounter} splits={splits} />;
      })}
    </Box>
  );
};
