import { Box, Dialog } from '@mui/material';
import { format, parseISO } from 'date-fns';
import { useState } from 'react';
import {
  RankBadge,
  Popover,
  POPOVER_POSITION,
  PreviewButton,
  RefundOptType,
  ShiftTradeRefundOptions,
  ShiftTradeCancelButtons,
} from '@stationwise/component-module';
import { EmployeeOffView } from '@stationwise/share-types';
import { WarningModal } from '../../../../../components/Common';
import { NonShiftDetails } from './NonShiftDetails';
import { RequestType } from './RequestType';

interface CancellationDialogContentProps {
  modalOpen: boolean;
  employeeOffData: EmployeeOffView;
  cancelRequest: (opt: RefundOptType) => void;
  closePopover: () => void;
  setModalOpen: (open: boolean) => void;
}

const CancellationDialogContent = ({
  modalOpen,
  employeeOffData,
  cancelRequest,
  closePopover,
  setModalOpen,
}: CancellationDialogContentProps) => {
  const [selectedButton, setSelectedButton] = useState<RefundOptType>('REFUND_HOURS');
  const [step, setStep] = useState(1);

  const onCancelRequest = () => {
    if (!modalOpen) return;
    setModalOpen(false);
    closePopover();
    cancelRequest(selectedButton);
  };

  if (employeeOffData.requestType === 'ShiftTradeRequest' && step === 1) {
    return (
      <Box sx={{ p: 2, width: '448px', maxWidth: '100%' }}>
        <Box sx={{ typography: 'bodyXLSemibold', display: 'flex' }}>Cancel shift trade</Box>
        <ShiftTradeRefundOptions selectedButton={selectedButton} handleButtonClick={setSelectedButton} />
        <ShiftTradeCancelButtons onClose={() => setModalOpen(false)} onSubmit={() => setStep(2)} step={step} setStep={setStep} />
      </Box>
    );
  }

  return (
    <Box sx={{ p: 2, width: '448px', maxWidth: '100%' }}>
      <WarningModal setModalOpen={setModalOpen} onConfirm={onCancelRequest}>
        <Box sx={{ typography: 'bodyXXLSemibold' }}>
          {`Do you wish to cancel this approved ${employeeOffData.title.toLowerCase()}?`}
        </Box>
        <Box sx={(theme) => ({ typography: 'bodyMRegular', mt: theme.spacing(2) })}>
          {`User's ${employeeOffData.title.toLowerCase()} will be removed.`}
        </Box>
      </WarningModal>
    </Box>
  );
};

interface CancellationPopoverProps {
  employeeOffData: EmployeeOffView;
  anchorEl: HTMLElement | null;
  popoverOpen: boolean;
  handleClose: () => void;
  cancelRequest: (opt: RefundOptType) => void;
}

export const CancellationPopover = ({
  employeeOffData,
  anchorEl,
  popoverOpen,
  handleClose,
  cancelRequest,
}: CancellationPopoverProps) => {
  const [warningModalOpen, setWarningModalOpen] = useState(false);

  return (
    <>
      <Popover anchorEl={anchorEl} open={popoverOpen} onClose={handleClose} {...POPOVER_POSITION.RIGHT}>
        <Box sx={(theme) => ({ width: '296px', p: theme.spacing(2) })}>
          <Box sx={(theme) => ({ display: 'flex', mb: theme.spacing(2) })}>
            <RequestType employeeOffData={employeeOffData} />
            <Box sx={(theme) => ({ typography: 'bodySMedium', color: theme.palette.common.white, ml: theme.spacing(1) })}>
              {employeeOffData.title}
            </Box>
          </Box>
          {employeeOffData.approvedAt && employeeOffData.approvedBy && (
            <Box sx={(theme) => ({ backgroundColor: theme.palette.gray[800], p: 2, borderRadius: 2, mb: 2 })}>
              <Box display="flex" justifyContent="space-between">
                <Box sx={(theme) => ({ typography: 'bodySMedium', color: theme.palette.gray[300] })}>Approved</Box>
                <Box sx={(theme) => ({ typography: 'bodyXSMedium', color: theme.palette.gray[400] })}>
                  {format(parseISO(employeeOffData.approvedAt), 'MMM d, HH:mm')}
                </Box>
              </Box>
              <Box display="flex" sx={{ mt: '10px' }}>
                <RankBadge rank={employeeOffData.approvedBy.rank} rankField="name" dark={true} />
              </Box>
              <Box sx={(theme) => ({ typography: 'bodyMMedium', color: theme.palette.common.white, mt: '10px' })}>
                {`${employeeOffData.approvedBy.name}`}
              </Box>
            </Box>
          )}
          {employeeOffData.requestType === 'EXTENDED_LEAVE' && <NonShiftDetails employeeOffData={employeeOffData} />}
          <PreviewButton onClick={() => setWarningModalOpen(true)} variant="outlined" size="large" sx={{ width: '100%', mt: 2 }}>
            {`Cancel ${employeeOffData.title.toLowerCase()}`}
          </PreviewButton>
        </Box>
      </Popover>
      <Dialog open={warningModalOpen} onClose={() => setWarningModalOpen(false)}>
        <CancellationDialogContent
          modalOpen={warningModalOpen}
          employeeOffData={employeeOffData}
          cancelRequest={cancelRequest}
          closePopover={handleClose}
          setModalOpen={setWarningModalOpen}
        />
      </Dialog>
    </>
  );
};
