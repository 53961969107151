import { Box, useTheme } from '@mui/material';
import { CAPABILITIES, useDocumentTitle } from '@stationwise/component-module';
import { AppPageLayout } from '../../../AppPageLayout';
import { EmployeesTab } from '../../EmployeesTab';
import { StaffingListsContent } from './StaffingListsContent';

export const StaffingListsMain = () => {
  useDocumentTitle('Staffing Lists');
  const theme = useTheme();

  return (
    <AppPageLayout sx={{ background: theme.palette.common.white }} requiredCapability={CAPABILITIES.VIEW_STAFFING_LIST}>
      <EmployeesTab defaultTab="staffingLists">
        <Box sx={{ px: theme.spacing(5), pt: theme.spacing(4), mb: theme.spacing(2) }}>
          <Box component="h1" sx={{ m: 0, typography: 'heading4' }}>
            Staffing Lists
          </Box>
        </Box>
        <StaffingListsContent />
      </EmployeesTab>
    </AppPageLayout>
  );
};
