import { Box } from '@mui/material';
import { Button } from '@stationwise/component-module';
import { useShiftPlanContext } from '../ShiftPlanContext';

interface ShiftPlanDialogActionsProps {
  isCancelDisabled?: boolean;
  isSaveDisabled?: boolean;
  isSaveLoading?: boolean;
  cancelText?: string;
  saveText?: string;
  onCancel?: () => void;
  onSave: () => void;
}

export const ShiftPlanDialogActions = (props: ShiftPlanDialogActionsProps) => {
  const { selectedAction, savingAction, cancelSelectedAction } = useShiftPlanContext();

  return (
    <Box sx={{ display: 'flex', gap: 2, p: 3, '& > *': { flex: 1 } }}>
      <Button
        variant="outlined"
        size="large"
        disabled={props.isCancelDisabled ?? !!savingAction}
        onClick={props.onCancel ?? cancelSelectedAction}
      >
        {props.cancelText || 'Cancel'}
      </Button>
      <Button
        variant="contained"
        size="large"
        disabled={props.isSaveDisabled || !selectedAction}
        loading={props.isSaveLoading ?? !!savingAction}
        onClick={props.onSave}
      >
        {props.saveText || 'Save'}
      </Button>
    </Box>
  );
};
