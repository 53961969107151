import { Box, Typography, useTheme } from '@mui/material';
import { captureException } from '@sentry/react';
import { Button } from '@stationwise/component-module';
import { client } from '@stationwise/share-api';

interface PayrollValidationModalFooterProps {
  departmentName: string;
  userName: string;
  isButtonActive: boolean;
  setOpenPayrollValidationWarningModal: React.Dispatch<React.SetStateAction<boolean>>;
  payPeriodId: number | undefined;
  setOpenPayrollValidationModal: React.Dispatch<React.SetStateAction<boolean>>;
  setRefetchCounter: React.Dispatch<React.SetStateAction<number>>;
  refetchCounter: number;
}

export const PayrollValidationModalFooter = ({
  departmentName,
  userName,
  isButtonActive,
  setOpenPayrollValidationWarningModal,
  payPeriodId,
  setOpenPayrollValidationModal,
  setRefetchCounter,
  refetchCounter,
}: PayrollValidationModalFooterProps) => {
  const theme = useTheme();
  const handlePressSignAndSubmit = async () => {
    try {
      await client.post('/payroll/personal/validate-payroll/', {
        payPeriodId: payPeriodId,
      });
      setOpenPayrollValidationModal(false);
      setRefetchCounter(refetchCounter + 1);
    } catch (error) {
      captureException(error);
    }
  };
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        position: 'sticky',
        marginTop: theme.spacing(2),
        padding: theme.spacing(2),
        gap: theme.spacing(2),
        boxShadow: '0px -4px 6px -2px rgba(10, 14, 22, 0.05), 0px -10px 15px -3px rgba(10, 14, 22, 0.1)',
        borderRadius: '12px 12px 0 0',
      }}
    >
      <Button
        data-cy="sign-time-card"
        size="large"
        variant="contained"
        sx={{ width: '100%' }}
        onClick={handlePressSignAndSubmit}
        disabled={!isButtonActive}
      >
        Sign and submit
      </Button>

      <Button
        data-cy="sign-time-card"
        size="large"
        variant="outlined"
        sx={{ width: '100%' }}
        onClick={() => setOpenPayrollValidationWarningModal(true)}
      >
        Sign later
      </Button>

      <Typography variant="bodyXSRegular" sx={(theme) => ({ color: theme.palette.gray[600] })}>
        {`By pressing 'Sign and submit', I agree that this electronic signature represents me, ${userName}. `}
        {`I agree that the contents of this time card are in agreement with all ${departmentName} rules and policies.`}
      </Typography>
    </Box>
  );
};
