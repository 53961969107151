import { EventInput } from '@fullcalendar/core';
import { EventImpl } from '@fullcalendar/core/internal';
import { Box, useMediaQuery } from '@mui/material';
import { format } from 'date-fns';
import React, { useEffect } from 'react';
import {
  Button,
  Loader,
  RankBadge,
  SHIFT_OVERVIEW_OPTIONS,
  STATUS_TITLES,
  StatusBadge,
  theme,
} from '@stationwise/component-module';
import { Employee } from '@stationwise/share-types';
import { getStatus } from '../../../../../../../app/pages/Calendar/components/RequestSharedComponents/StatusCard';
import { useFetchRequestOverview } from '../../../../../../../app/pages/Calendar/components/RequestSharedComponents/hooks/useFetchRequestOverview';
import { EventCardLine } from './EventCardLine';
import { EventCardTitleSection } from './EventCardTitleSection';
import { executeSelectedAction, findDurationAsHourAndMinute, processTitle, truncateTitle } from './EventCardUtils';
import { EventCardWrapper } from './EventCardWrapper';
import { ShiftTradeReceiversModal } from './ShiftTradeReceiversModal';

export const ShiftTradeEventCard = ({
  shift,
  setClickedEventAsCalendarSelectedElement,
  openActionModal,
  handleShiftCardOverviewClose,
}: {
  shift: EventInput | EventImpl;
  setClickedEventAsCalendarSelectedElement?: (
    event: React.MouseEvent<HTMLElement>,
    shift: EventInput | EventImpl,
    design?: boolean,
  ) => void;
  openActionModal?: (option: string) => void;
  handleShiftCardOverviewClose?: () => void;
}) => {
  const isSmallScreen = useMediaQuery('(max-width:400px)');
  const { hour: durationHour, minute: durationMin } = findDurationAsHourAndMinute(shift);
  const isApproved = (shift as EventInput).status === STATUS_TITLES.APPROVED;
  const isDenied = (shift as EventInput).status === STATUS_TITLES.DENIED;
  const isPending = !isApproved && !isDenied;
  const status = getStatus((shift as EventInput).status);
  const [openModal, setOpenModal] = React.useState(false);
  const fetchUrl = `/request/shift-trade-request/${shift.id}/receivers/`;
  const [overview, setOverview] = React.useState<Employee[] | null>(null);

  const { data: employees } = useFetchRequestOverview<Employee[]>(isPending ? fetchUrl : '');

  useEffect(() => {
    if (isPending && !overview) {
      setOverview(employees);
    }
  }, [isPending, employees, overview]);

  return (
    <Box>
      {openModal && overview && (
        <ShiftTradeReceiversModal
          showModal={openModal}
          setShowModal={setOpenModal}
          employees={overview}
        ></ShiftTradeReceiversModal>
      )}
      <EventCardWrapper>
        <EventCardTitleSection
          title={truncateTitle(processTitle(shift, false), isSmallScreen ? 32 : 37)}
          backgroundColor={isPending ? theme.palette.blue[100] : isDenied ? theme.palette.red[500] : theme.palette.gray[100]}
          titleColor={isPending ? theme.palette.blue[800] : isDenied ? theme.palette.common.white : theme.palette.gray[800]}
          truncate
          maxLength={isSmallScreen ? 32 : 37}
        />

        <Box
          sx={(theme) => ({
            display: 'flex',
            px: theme.spacing(2),
            flexDirection: 'column',
            alignItems: 'flex-start',
            alignSelf: 'stretch',
            '&:last-child': {
              borderBottom: 'none',
            },
          })}
        >
          <EventCardLine
            title={'Start Date'}
            value={shift.start ? format(shift.start as Date, 'MMM d, HH:mm') : '-'}
          ></EventCardLine>
          <EventCardLine title={'End Date'} value={shift.end ? format(shift.end as Date, 'MMM d, HH:mm') : '-'}></EventCardLine>

          <EventCardLine
            title={'Duration'}
            value={shift.end && shift.start ? durationHour + ' hrs ' + durationMin + ' mins' : '-'}
          ></EventCardLine>

          <EventCardLine
            title={'Rank'}
            value={
              shift.extendedProps ? <RankBadge maxLength={100} rankField={'name'} rank={shift.extendedProps['eventRank']} /> : '-'
            }
          ></EventCardLine>

          <EventCardLine
            title={'Station'}
            value={shift.extendedProps ? shift.extendedProps['eventStation'] : '-'}
          ></EventCardLine>
          <EventCardLine
            title={'Apparatus'}
            value={shift.extendedProps ? shift.extendedProps['eventApparatus'] : '-'}
          ></EventCardLine>

          {isPending && (
            <EventCardLine
              title={overview && overview.length > 1 ? 'Recipients' : 'Recipient'}
              value={
                overview ? (
                  <Button variant="outlined" color="primary" size="small" onClick={() => setOpenModal(true)}>
                    {`Show ${overview.length} people`}
                  </Button>
                ) : (
                  <Loader />
                )
              }
            ></EventCardLine>
          )}
          {isApproved && (
            <EventCardLine
              title={'Traded Person'}
              value={shift.extendedProps ? shift.extendedProps['eventTradedPerson'] : '-'}
            ></EventCardLine>
          )}

          <EventCardLine title={'Status'} value={status ? <StatusBadge status={status} /> : '-'}></EventCardLine>
          {!isPending && (
            <EventCardLine
              title={isApproved ? 'Approved by' : 'Denied by'}
              value={shift.extendedProps ? shift.extendedProps['eventReviewedBy'] : '-'}
            ></EventCardLine>
          )}

          {!isPending && (
            <EventCardLine
              title={isApproved ? 'Approval Date' : 'Denial Date'}
              value={shift.extendedProps ? shift.extendedProps['eventReviewedAt'] : '-'}
            ></EventCardLine>
          )}

          {!isPending && (
            <EventCardLine
              isLastLine={!isPending}
              title={'Note'}
              value={shift.extendedProps ? shift.extendedProps['eventNote'] : '-'}
            ></EventCardLine>
          )}
        </Box>
        <Box
          sx={(theme) => ({
            display: 'flex',
            p: isPending && openActionModal ? theme.spacing(2) : '',
            gap: theme.spacing(1),
            alignSelf: 'stretch',
            alignItems: 'center',
          })}
        >
          {isPending && openActionModal && (
            <Button
              sx={(theme) => ({
                display: 'flex',
                py: theme.spacing(0.75),
                px: theme.spacing(2),
                justifyContent: 'center',
                alignItems: 'center',
                flex: '1 0 0',
              })}
              data-cy="cancel-shift-trade-request-button"
              color="error"
              size="medium"
              variant="outlined"
              onClick={(event: React.MouseEvent<HTMLElement>) => {
                executeSelectedAction(
                  SHIFT_OVERVIEW_OPTIONS.CANCEL_EVENT,
                  event,
                  shift,
                  setClickedEventAsCalendarSelectedElement,
                  openActionModal,
                  handleShiftCardOverviewClose,
                );
              }}
            >
              Cancel Request
            </Button>
          )}
        </Box>
      </EventCardWrapper>
    </Box>
  );
};
