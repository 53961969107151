import { Box } from '@mui/material';
import { ErrorPage, LottieLogo, useDocumentTitle, useFetchRosterData } from '@stationwise/component-module';
import { AppPageLayout } from '../../../AppPageLayout';
import { ScheduleContent } from './ScheduleContent';

export const ScheduleMain = () => {
  useDocumentTitle('Roster');
  const {
    isLoading,
    isError,
    shiftSummary,
    selectedBattalionState,
    currentDate,
    administrationStations,
    eventStations,
    temporaryNonShiftStation,
  } = useFetchRosterData();

  const renderContent = () => {
    const hasAllData = !!(shiftSummary && administrationStations && eventStations && temporaryNonShiftStation);
    if (isLoading && !hasAllData) {
      return (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          width="100%"
          height="100%"
          component="section"
          alignContent="center"
        >
          <Box>
            <LottieLogo height="200px" width="200px" />
          </Box>
        </Box>
      );
    }

    if (isError || !hasAllData) {
      return <ErrorPage />;
    }

    return (
      <ScheduleContent
        shiftSummary={shiftSummary}
        administrationStations={administrationStations}
        eventStations={eventStations}
        temporaryNonShiftStation={temporaryNonShiftStation}
        currentDate={currentDate}
        selectedBattalionState={selectedBattalionState}
      />
    );
  };

  return (
    <AppPageLayout>
      <Box
        display="flex"
        flexDirection="column"
        sx={{
          height: '100%',
          width: '100%',
          p: 0,
          m: 0,
        }}
      >
        {renderContent()}
      </Box>
    </AppPageLayout>
  );
};
