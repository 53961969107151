import { Box } from '@mui/material';
import { Key02Icon24 } from '@stationwise/component-module';

interface SecurityProps {
  setShowChangePasswordPage: (value: boolean) => void;
}
export const Security = ({ setShowChangePasswordPage }: SecurityProps) => {
  return (
    <Box
      sx={(theme) => ({
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        gap: theme.spacing(3),
        width: '100%',
      })}
    >
      <Box
        sx={(theme) => ({
          color: theme.palette.gray[900],
          typography: 'bodyLSemibold',
          height: theme.spacing(1.5),
        })}
      >
        Security
      </Box>
      <Box
        sx={(theme) => ({
          display: 'flex',
          p: theme.spacing(2),
          width: 'inherit',
          flexDirection: 'column',
          alignItems: 'flex-start',
          gap: theme.spacing(2),
          borderRadius: theme.spacing(1.5),
          background: theme.palette.common.white,
          boxShadow: '0px 1px 2px 0px rgba(10, 14, 22, 0.05)',
        })}
      >
        <Box
          sx={(theme) => ({
            gap: theme.spacing(2),
            display: 'flex',
            alignItems: 'center',
            alignSelf: 'stretch',
            cursor: 'pointer',
            width: 'inherit',
            height: '100%',
            color: theme.palette.gray[900],
            typography: 'bodyMMedium',
            '&:hover': {
              cursor: 'pointer',
              color: theme.palette.purple[500],
            },
          })}
          onClick={() => {
            setShowChangePasswordPage(true);
          }}
        >
          <Box sx={(theme) => ({ color: theme.palette.purple[500], display: 'flex', alignItems: 'center' })}>
            <Key02Icon24 />
          </Box>
          Change Password
        </Box>
      </Box>
    </Box>
  );
};
