import { Box, Typography, useTheme, TextField } from '@mui/material';
import { captureException } from '@sentry/react';
import { format } from 'date-fns';
import { useEffect, useState } from 'react';
import { RankBadge, Button, TeamFlag, ToggleButton, ToggleButtonGroup, ChevronDownIcon20 } from '@stationwise/component-module';
import { client } from '@stationwise/share-api';
import { ShiftTrade } from '@stationwise/share-types';
import { TimeSelector } from './TimeSelector';

export const ShiftTradeForgive = ({
  shiftTrade,
  onClose,
  onConfirmed,
}: {
  shiftTrade: ShiftTrade;
  onClose: () => void;
  onConfirmed: () => void;
}) => {
  const theme = useTheme();

  const [openTimeSelect, setOpenTimeSelect] = useState<boolean>(false);

  const openTimeSelectDrawer = () => {
    setOpenTimeSelect(!openTimeSelect);
    handleChipClick('Custom');
  };

  const [selectedChip, setSelectedChip] = useState<string | null>(null);
  const [debtForgiven, setDebtForgiven] = useState(0);
  const [currentNote, setCurrentNote] = useState('');
  const [isDisabled, setIsDisabled] = useState(true);

  useEffect(() => {
    setIsDisabled(selectedChip === null || currentNote === '' || debtForgiven === 0 || debtForgiven === null);
  }, [selectedChip, currentNote, debtForgiven]);

  const forgiveShiftTradeRequest = async (trade_duration: number, shiftTrade: ShiftTrade) => {
    try {
      await client.post('request/shift-trade-request/forgive/', {
        trade_duration: trade_duration,
        responder: shiftTrade.employeeId,
        note: currentNote,
      });
    } catch (error) {
      console.error('Error forgiving shift trade request:', error);
      captureException(error);
    }
    onConfirmed();
  };

  const handleChipClick = (label: string) => {
    setSelectedChip(label);

    const fullDebt = (shiftTrade.owedMinutes ?? 0) / 60 || 0;

    switch (label) {
      case 'Full':
        setDebtForgiven(fullDebt);
        break;
      case 'Half':
        setDebtForgiven(fullDebt / 2);
        break;
      case 'Quarter':
        setDebtForgiven(fullDebt / 4);
        break;
      case 'Custom':
        break;
      default:
        setDebtForgiven(0);
    }
  };

  return (
    <Box>
      <Box
        sx={(theme) => ({
          border: `solid 1px ${theme.palette.gray[100]}`,
          boxShadow: '0px 1px 2px 0px rgba(10, 14, 22, 0.05)',
          borderRadius: '8px',
          p: theme.spacing(2),
          margin: theme.spacing(2),
        })}
      >
        {/* Line 1 */}
        <Box display="flex" alignItems="center" justifyContent="space-between" sx={{ pb: theme.spacing(2) }}>
          <Box display="flex">
            <Typography variant="eyebrow" sx={{ color: theme.palette.gray[400], pt: '3px' }}>
              WHO
            </Typography>
          </Box>

          <Box display="flex" alignItems="center">
            <RankBadge rank={shiftTrade.employeeRank} />
            <Typography variant="bodyLMedium" sx={{ color: theme.palette.gray[900], ml: theme.spacing(1), pt: '3px' }}>
              {shiftTrade.employeeName}
            </Typography>
          </Box>
        </Box>

        {/* Line 2 */}
        <Box display="flex" alignItems="center" justifyContent="space-between" sx={{ pb: theme.spacing(2) }}>
          <Box display="flex">
            <Typography variant="eyebrow" sx={{ color: theme.palette.gray[400], pt: '3px' }}>
              SHIFT
            </Typography>
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <TeamFlag color={shiftTrade.shiftColor} />
            <Typography variant="bodyLMedium" sx={{ color: theme.palette.gray[900], ml: theme.spacing(1), pt: '3px' }}>
              {shiftTrade.shiftName}
            </Typography>
          </Box>
        </Box>

        {/* Line 3 */}
        <Box display="flex" sx={{ pb: theme.spacing(2) }} alignItems="center" justifyContent="space-between">
          <Typography variant="eyebrow" sx={{ color: theme.palette.gray[400], pt: '3px' }}>
            TRADES OWED
          </Typography>

          {shiftTrade.startDate && shiftTrade.endDate && (
            <Typography sx={{ color: theme.palette.gray[500], pt: '3px' }} variant="bodyLMedium">
              {format(`${shiftTrade.startDate}T${shiftTrade.startTime}`, 'MMM d, HHmm')}
              {'-'}
              {format(`${shiftTrade.endDate}T${shiftTrade.endTime}`, 'HHmm')}
            </Typography>
          )}

          <Typography
            sx={{
              color: shiftTrade.owesMe ? theme.palette.green[500] : theme.palette.yellow[500],
              pt: '3px',
            }}
            variant="bodyLMedium"
          >
            {`${shiftTrade.owesMe ? 'Owes me' : 'I owe'} ${((shiftTrade.owedMinutes ?? 0) / 60)?.toFixed(2)} h`}
          </Typography>
        </Box>

        {/* Button */}

        <TimeSelector
          openTimeSelect={openTimeSelect}
          setOpenTimeSelect={openTimeSelectDrawer}
          setDebtForgiven={setDebtForgiven}
          shiftTrade={shiftTrade}
          onClose={onClose}
          onConfirmed={onConfirmed}
        />
      </Box>
      <Box
        sx={{
          mt: theme.spacing(3),
          ml: theme.spacing(2),
          mr: theme.spacing(2),
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Typography variant="bodyLMedium" sx={{ color: theme.palette.gray[900], mb: theme.spacing(2) }}>
          Forgive debt
        </Typography>

        <ToggleButtonGroup
          exclusive
          color="primary"
          size="large"
          value={selectedChip}
          onChange={(_event, newValue) => {
            if (newValue === 'Custom') {
              openTimeSelectDrawer();
            } else if (newValue !== null) {
              handleChipClick(newValue);
            }
          }}
          aria-label="Forgive debt"
          sx={(theme) => ({
            mb: theme.spacing(1),
            width: '100%',
            '& .MuiToggleButton-root': { p: theme.spacing(1, 2) },
          })}
        >
          <ToggleButton value="Full">Full</ToggleButton>
          <ToggleButton value="Half">Half</ToggleButton>
          <ToggleButton value="Quarter">Quarter</ToggleButton>
          <ToggleButton value="Custom">Custom</ToggleButton>
        </ToggleButtonGroup>

        {selectedChip === 'Custom' && (
          <Box sx={{ display: 'flex', justifyContent: 'center', mb: theme.spacing(2) }}>
            <Box
              display="flex"
              justifyContent="center"
              sx={(theme) => ({
                border: `1px solid ${theme.palette.gray[200]}`,
                borderRadius: theme.spacing(1),
                p: '10px 13px 10px',
                width: '50%',
                cursor: 'pointer',
                justifyContent: 'space-between',
                alignItems: 'center',
                typography: 'bodySRegular',
              })}
              onClick={() => openTimeSelectDrawer()}
            >
              <Box>{Math.floor(debtForgiven)}</Box>
              <Box
                sx={(theme) => ({
                  color: theme.palette.gray[500],
                  '& svg': {
                    color: theme.palette.gray[400],
                  },
                  display: 'flex',
                  alignItems: 'center',
                  gap: theme.spacing(0.5),
                })}
              >
                HRS <ChevronDownIcon20 />
              </Box>
            </Box>
            <Box
              sx={(theme) => ({
                border: `1px solid ${theme.palette.gray[200]}`,
                borderRadius: theme.spacing(1),
                p: '10px 13px 10px',
                width: '50%',
                cursor: 'pointer',
                display: 'flex',
                marginLeft: theme.spacing(2),
                justifyContent: 'space-between',
                typography: 'bodySRegular',
              })}
              onClick={() => openTimeSelectDrawer()}
            >
              <Box>{(debtForgiven - Math.floor(debtForgiven)) * 60}</Box>
              <Box
                sx={(theme) => ({
                  color: theme.palette.gray[500],
                  '& svg': {
                    color: theme.palette.gray[400],
                  },
                  display: 'flex',
                  alignItems: 'center',
                  gap: theme.spacing(0.5),
                })}
              >
                MIN <ChevronDownIcon20 />
              </Box>
            </Box>
          </Box>
        )}

        <Typography variant="bodyMRegular" color={theme.palette.gray[500]}>
          Forgiven <span style={{ fontWeight: '600', color: theme.palette.gray[900] }}> {debtForgiven} hrs</span>, Remaining{' '}
          <span style={{ fontWeight: '600', color: theme.palette.gray[900] }}>
            {(shiftTrade.owedMinutes ?? 0) / 60 - debtForgiven} hr
          </span>
        </Typography>

        <Typography variant="bodyLMedium" sx={{ mt: theme.spacing(4), mb: theme.spacing(1) }}>
          Note *
        </Typography>
        <Box>
          <TextField
            fullWidth
            minRows={3}
            multiline
            maxRows={3}
            value={currentNote}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setCurrentNote(event.target.value);
            }}
          />
        </Box>
        <Box sx={{ height: '100px' }} />
      </Box>

      <Box
        sx={{
          position: 'sticky',
          bottom: 0,
          left: 0,
          right: 0,
          padding: theme.spacing(2),
          background: theme.palette.common.white,
          border: `solid 1px ${theme.palette.gray[200]}`,
          boxShadow: '0px -10px 10px -5px rgba(10, 14, 22, 0.04), 0px -20px 25px -5px rgba(10, 14, 22, 0.1)',
          borderRadius: 2,
        }}
      >
        <Button
          variant="contained"
          size="large"
          disabled={isDisabled}
          sx={{ width: '100%' }}
          onClick={() => {
            if (!isDisabled) {
              setIsDisabled(true);
              forgiveShiftTradeRequest(debtForgiven * 60, shiftTrade);
            }
          }}
        >
          Forgive debt
        </Button>
      </Box>
    </Box>
  );
};
