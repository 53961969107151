import { Navigate, useLocation } from 'react-router-dom';
import { getDepartmentFeatureFlagValue, useLoadedDepartmentInfoContext } from '@stationwise/component-module';
import { ShiftPlanMain } from './components/ShiftPlanMain';

export const ShiftPlan = () => {
  const { state: departmentInfoState } = useLoadedDepartmentInfoContext();
  const location = useLocation();
  if (!getDepartmentFeatureFlagValue(departmentInfoState, 'SHIFT_PLAN')) {
    return <Navigate to="/app/shift-template" state={location.state} />;
  }

  return <ShiftPlanMain />;
};
