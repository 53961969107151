import { captureException } from '@sentry/react';
import { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { client, isAxiosError } from '@stationwise/share-api';
import { EmployeePayroll, PayPeriod } from '@stationwise/share-types';

interface UseFetchEmployeePayrollProps {
  employeePayrollId?: string;
}

export const useFetchEmployeePayroll = ({ employeePayrollId }: UseFetchEmployeePayrollProps) => {
  const navigate = useNavigate();
  const [employeePayroll, setEmployeePayroll] = useState<EmployeePayroll | null>(null);
  const [selectedPayPeriod, setSelectedPayPeriod] = useState<PayPeriod | null>(null);
  const [currentPayPeriod, setCurrentPayPeriod] = useState<PayPeriod | null>(null);
  const [selectedPeriodIndex, setSelectedPeriodIndex] = useState<number>(0);
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [fetchNoRequest, setFetchNoRequest] = useState(0);
  const isFirstMount = useRef(true);
  useEffect(() => {
    const fetchEmployeePayroll = async () => {
      try {
        setIsLoading(true);
        const response = await client.get(`/payroll/${employeePayrollId}/`);
        isFirstMount.current = true;
        const payrollData = response.data;
        setEmployeePayroll(payrollData);

        const payPeriod: PayPeriod = {
          startDate: payrollData.payPeriod.startDate,
          endDate: payrollData.payPeriod.endDate,
          id: payrollData.payPeriod.id,
          payPeriodType: payrollData.payPeriod.payPeriodType,
          duration: payrollData.payPeriod.duration,
        };

        setSelectedPayPeriod(payPeriod);
        setCurrentPayPeriod(payPeriod);
        setIsLoading(false);
      } catch (err) {
        if (isAxiosError(err) && err.response?.status === 404) {
          navigate('/app/payroll');
        } else {
          setIsError(true);
          setIsLoading(false);
          captureException(err);
        }
      }
    };

    fetchEmployeePayroll();
  }, [employeePayrollId, navigate]);

  useEffect(() => {
    if (isFirstMount.current) {
      isFirstMount.current = false;
      return;
    }
    const fetchEmployeePayroll = async () => {
      if (employeePayroll && selectedPayPeriod) {
        try {
          setIsLoading(true);
          const params = {
            employeeId: employeePayroll.employee.id,
            payPeriodId: selectedPayPeriod.id,
          };
          const response = await client.get(`/payroll/`, { params });
          setEmployeePayroll(response.data.results[0]);

          setIsLoading(false);
        } catch (err) {
          setIsError(true);
          captureException(err);
          setIsLoading(false);
        }
      }
    };

    fetchEmployeePayroll();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPayPeriod, fetchNoRequest]);

  const forceRefetch = () => {
    setFetchNoRequest((prev) => prev + 1);
  };

  return {
    employeePayroll,
    selectedPayPeriod,
    currentPayPeriod,
    selectedPeriodIndex,
    setSelectedPayPeriod,
    setSelectedPeriodIndex,
    isLoading,
    isError,
    forceRefetch,
  };
};
