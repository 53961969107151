import { Box, Menu, MenuItem } from '@mui/material';
import { format } from 'date-fns';
import { MouseEvent, useId, useState } from 'react';
import { RosterEmployee, RosterPosition, RosterStrikeTeamApparatus } from '@stationwise/share-types';
import { unassignStrikeTeamEmployee } from '@stationwise/shift-summary-helper';
import { ChevronDownIcon16 } from '../../../../assets';
import { ViewProfileButton } from '../../../EmployeePreview';
import { PreviewButton } from '../../../EmployeePreview/PreviewButton';
import { useRosterContext } from '../RosterContext';

interface StrikeTeamActionsProps {
  apparatus: RosterStrikeTeamApparatus;
  position: RosterPosition;
  employee: RosterEmployee;
}

export const StrikeTeamActions = ({ apparatus, position, employee }: StrikeTeamActionsProps) => {
  const { selectedFilledPositionState, shiftSummaryHelper, setShiftSummaryHelper, setUserHasMadeChanges } = useRosterContext();
  const id = useId();
  const [unstaffAnchorEl, setUnstaffAnchorEl] = useState<HTMLElement | null>(null);

  const openUnstaffMenu = (event: MouseEvent<HTMLButtonElement>) => setUnstaffAnchorEl(event.currentTarget);
  const closeUnstaffMenu = () => setUnstaffAnchorEl(null);

  const unstaff = (toDate: string) => {
    const { newShiftSummaryHelper } = unassignStrikeTeamEmployee(shiftSummaryHelper, apparatus, position, employee, toDate);
    setShiftSummaryHelper(newShiftSummaryHelper);
    setUserHasMadeChanges(true);
    closeUnstaffMenu();
    selectedFilledPositionState.closeDialog();
  };

  const unstaffToday = () => unstaff(format(shiftSummaryHelper.shiftDuration.startTime, 'yyyy-MM-dd'));

  const unstaffAllDays = () => unstaff(apparatus.deactivationDate);

  return (
    <Box sx={{ display: 'flex', gap: 2, mt: 2, '& .MuiButton-root': { width: '100%' } }}>
      <Box sx={{ flex: 1 }}>
        <ViewProfileButton employeeId={employee.id} />
      </Box>
      <Box sx={{ flex: 1 }}>
        <PreviewButton
          variant="contained"
          id={`${id}unstaff-button`}
          aria-controls={unstaffAnchorEl ? `${id}unstaff-menu` : undefined}
          aria-haspopup="true"
          aria-expanded={unstaffAnchorEl ? 'true' : undefined}
          onClick={openUnstaffMenu}
          sx={{
            gap: 0.5,
            '& svg': { minWidth: '16px' },
            '&[aria-expanded="true"] svg': { transform: 'rotate(180deg)' },
          }}
        >
          <Box component="span" sx={{ ml: 0.25 }}>
            Unstaff
          </Box>
          <ChevronDownIcon16 />
        </PreviewButton>
      </Box>
      <Menu
        id={`${id}unstaff-menu`}
        anchorEl={unstaffAnchorEl}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: -8, horizontal: 'right' }}
        open={!!unstaffAnchorEl}
        onClose={closeUnstaffMenu}
        MenuListProps={{ 'aria-labelledby': `${id}unstaff-button` }}
        sx={(theme) => ({
          '& .MuiMenu-paper': {
            backgroundColor: theme.palette.gray[700],
            border: `1px solid ${theme.palette.gray[600]}`,
            borderRadius: '12px',
          },
          '& .MuiMenu-list': {
            p: theme.spacing(1.5),
          },
          '& .MuiMenuItem-root': {
            backgroundColor: theme.palette.gray[700],
            borderRadius: '8px',
            color: theme.palette.common.white,
            p: theme.spacing(1, 1.5),
            typography: 'bodySRegular',
          },
          '& .MuiMenuItem-root:hover': {
            backgroundColor: theme.palette.gray[800],
          },
        })}
      >
        <MenuItem onClick={unstaffToday}>Today only</MenuItem>
        <MenuItem onClick={unstaffAllDays}>Today and all future dates</MenuItem>
      </Menu>
    </Box>
  );
};
