import { EventInput } from '@fullcalendar/core';
import { EventImpl } from '@fullcalendar/core/internal';
import { differenceInMinutes } from 'date-fns';
import React from 'react';
import { GetIncidentOverview } from '@stationwise/share-types';

/*todo
hidden since they have differences with previous versions of the same function will use again after inspecting it
type DateRange = 'past' | 'future' | 'today';

export const determineDateRange = (shift: EventInput | EventImpl): DateRange => {
  const tomorrow = startOfTomorrow();
  const shiftStart = new Date(shift.start as string);
  return shiftStart >= tomorrow ? 'future' : isPast(shiftStart) ? 'past' : 'today';
};
 */

export const findDuration = (shift: EventInput | EventImpl): number => {
  return shift.end && shift.start ? differenceInMinutes(shift.end as Date, shift.start as Date) : 0;
};
export const findDurationAsHourAndMinute = (shift: EventInput | EventImpl): { hour: number; minute: number } => {
  const duration = findDuration(shift);
  return { hour: Math.floor(duration / 60), minute: duration % 60 };
};

export const processTitle = (shift: EventInput | EventImpl, addShiftSuffix: boolean): string => {
  return addShiftSuffix
    ? (shift.title + ' shift').toLowerCase().replace(/\b\w/g, (char) => char.toUpperCase())
    : shift.title!.toLowerCase().replace(/\b\w/g, (char) => char.toUpperCase());
};
export const truncateTitle = (title: string, maxLength: number) => {
  return title.length > maxLength ? title.substring(0, maxLength) + '...' : title;
};

export const executeSelectedAction = (
  option: string,
  event: React.MouseEvent<HTMLElement>,
  shift: EventInput | EventImpl,
  setClickedEventAsCalendarSelectedElement?: (
    event: React.MouseEvent<HTMLElement>,
    shift: EventInput | EventImpl,
    design?: boolean,
  ) => void,
  openActionModal?: (option: string) => void,
  handleShiftCardOverviewClose?: (incident?: GetIncidentOverview) => void,
) => {
  setClickedEventAsCalendarSelectedElement && setClickedEventAsCalendarSelectedElement(event, shift, true);
  if (openActionModal) {
    openActionModal(option);
    if (handleShiftCardOverviewClose) {
      handleShiftCardOverviewClose();
    }
  }
};
