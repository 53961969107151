import DragHandleIcon from '@mui/icons-material/DragHandle';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import { Alert, useTheme } from '@mui/material';

type AutoHireInfoAlertProps = {
  message: string;
};

export const AutoHireInfoAlert = ({ message }: AutoHireInfoAlertProps) => {
  const theme = useTheme();
  return (
    <Alert
      severity="info"
      variant="standard"
      sx={{
        borderRadius: '6px',
        border: `1px solid ${theme.palette.info.main}`,
      }}
    >
      {message}
    </Alert>
  );
};

interface DragIconProps {
  dragIndicator?: boolean;
}

export const DragIcon = ({ dragIndicator = false }: DragIconProps) => {
  const theme = useTheme();

  const styles = {
    visibility: 'visible',
    marginRight: theme.spacing(1),
    color: theme.palette.gray[500],
    '&:hover': {
      cursor: 'move',
      color: theme.palette.primary.dark,
    },
  };

  return dragIndicator ? <DragIndicatorIcon sx={styles} /> : <DragHandleIcon sx={styles} />;
};
