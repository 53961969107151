import { Box } from '@mui/material';
import { format, parseISO } from 'date-fns';
import { TeamBadge } from '@stationwise/component-module';
import { ShiftPlanTeam } from '@stationwise/share-types';
import { TeamPatternDays } from '../Calendar';
import { BorderedBoxes } from '../Grid';

interface StaffingPatternRowProps {
  team: ShiftPlanTeam;
  month: Date;
}

export const StaffingPatternRow = ({ team, ...props }: StaffingPatternRowProps) => {
  return (
    <BorderedBoxes sx={{ height: '40px' }}>
      <Box sx={{ flex: 0.5, px: 1.5 }}>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, width: '100%' }}>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
            <TeamBadge team={team} />
          </Box>
        </Box>
      </Box>
      <Box sx={{ flex: 0.5, px: 1.5 }}>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, width: '100%' }}>
          <Box
            sx={{
              typography: 'bodySMedium',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
            }}
          >
            {format(parseISO(team.patternStartDate), 'E, MMM dd, yyyy')}
          </Box>
        </Box>
      </Box>
      <BorderedBoxes sx={{ border: 0, width: '700px', minWidth: '60%' }}>
        <TeamPatternDays team={team} month={props.month} />
      </BorderedBoxes>
    </BorderedBoxes>
  );
};
