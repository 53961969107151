import { DateCalendar, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import { format, startOfMonth, endOfMonth } from 'date-fns';
import { useEffect, useState } from 'react';
import { ChevronDownIcon16, ChevronLeftIcon20, ChevronRightIcon20, useDateQueryParam } from '@stationwise/component-module';
import { client } from '@stationwise/share-api';
import { GetShiftTeamsView } from '@stationwise/share-types';
import { Day } from './Day';

export const MiniCalendar = () => {
  const [shiftColorsByDate, setShiftColorsByDate] = useState(new Map<string, string>());
  const { onDateChange, selectedDateAsDate } = useDateQueryParam();
  const [currentMonth, setCurrentMonth] = useState(startOfMonth(selectedDateAsDate));

  useEffect(() => {
    const fetchShiftColors = async () => {
      const response = await client.get<GetShiftTeamsView[]>('/shift/shift-teams/', {
        params: {
          startDate: format(currentMonth, 'MM/dd/yyyy'),
          endDate: format(endOfMonth(currentMonth), 'MM/dd/yyyy'),
        },
      });
      setShiftColorsByDate(new Map(response.data.map((sd) => [sd.date, sd.shiftColor])));
    };

    fetchShiftColors();
  }, [currentMonth]);

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DateCalendar
        value={currentMonth}
        onMonthChange={setCurrentMonth}
        sx={{ width: 324, height: 376 }}
        views={['day', 'month']}
        slots={{
          day: (props) => (
            <Day
              {...props}
              shiftColorsByDate={shiftColorsByDate}
              selectedDateAsDate={selectedDateAsDate}
              onDateChange={onDateChange}
            />
          ),
          leftArrowIcon: ChevronLeftIcon20,
          rightArrowIcon: ChevronRightIcon20,
          switchViewIcon: ChevronDownIcon16,
        }}
      />
    </LocalizationProvider>
  );
};
