import { Alert, Box, Input, Typography } from '@mui/material';
import { captureException } from '@sentry/react';
import { AxiosError } from 'axios';
import { useEffect, useState } from 'react';
import {
  formatPhoneNumber,
  GenericDrawerOrModal,
  SnackbarService,
  theme,
  useAuthUserContext,
} from '@stationwise/component-module';
import { client, refreshUserData } from '@stationwise/share-api';

interface VerifyPhoneNumberDrawerProps {
  drawerOpen: boolean;
  onClose: () => void;
  phoneNumber: string | null;
  setPhoneNumber: (value: string | null) => void;
}

export const VerifyPhoneNumberDrawer = ({ drawerOpen, phoneNumber, onClose, setPhoneNumber }: VerifyPhoneNumberDrawerProps) => {
  const { dispatch } = useAuthUserContext();
  const [confirmationCode, setConfirmationCode] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [timeLeft, setTimeLeft] = useState(120);

  useEffect(() => {
    if (!drawerOpen) {
      setTimeLeft(120);
      return;
    }

    const timer = setInterval(() => {
      setTimeLeft((prev) => {
        if (prev <= 0) {
          clearInterval(timer);
          return 0;
        }
        return prev - 1;
      });
    }, 1000);

    return () => clearInterval(timer);
  }, [drawerOpen]);

  const formatTime = (seconds: number) => {
    const mins = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${mins}:${secs.toString().padStart(2, '0')}`;
  };

  const disabled = !(confirmationCode?.length === 6) || timeLeft === 0;

  const handleSave = async () => {
    try {
      await client.post('/employee/verify-otp/verify-code/', {
        phone: formatPhoneNumber(phoneNumber),
        code: confirmationCode,
      });
    } catch (error) {
      captureException(error);
      const errorMessage =
        (error as AxiosError<{ error: string }>).response?.data?.error + ', your phone number is not changed' ||
        'An unexpected error occurred while saving changes';
      setConfirmationCode('');
      setErrorMessage(errorMessage);
      return;
    }

    try {
      const params = { phone: formatPhoneNumber(phoneNumber) };
      await client.patch('/employee/update/', params);
      await refreshUserData(dispatch);
      SnackbarService.notify({
        content: 'Your phone number has been updated successfully.',
        severity: 'success',
        duration: 3000,
      });
      setPhoneNumber(phoneNumber);
    } catch (error) {
      SnackbarService.notify({
        content: 'An error occurred while trying to change your phone number. Please try again later.',
        severity: 'error',
        duration: 5000,
      });
      captureException(error);
      return;
    }

    setConfirmationCode('');
    setErrorMessage('');
    onClose();
  };

  return (
    <GenericDrawerOrModal
      anchor={'bottom'}
      drawerOpen={drawerOpen}
      handleOnClose={() => {
        setConfirmationCode('');
        setErrorMessage('');
        onClose();
      }}
      loading={false}
      headerTitle={'Enter Confirmation Code'}
      showHeader={true}
      disableFooter={false}
      footerButtons={[
        {
          label: 'Verify Phone Number',
          onClick: handleSave,
          disabled,
        },
      ]}
      sxProps={{
        '.MuiDrawer-paper': {
          borderBottomLeftRadius: 0,
          borderBottomRightRadius: 0,
          px: 0.5,
        },
      }}
    >
      <Box sx={{ p: 2 }}>
        <Typography variant="body1" sx={{ mb: theme.spacing(1) }}>
          We have sent a 6-digit code to your phone number. <br />
          Please enter the code below to verify your phone number.
        </Typography>
        <Input value={confirmationCode} onChange={(e) => setConfirmationCode(e.target.value)} placeholder="Enter 6-digit code" />
        <Box
          sx={(theme) => ({
            mt: 1,
            color: timeLeft === 0 ? theme.palette.error.main : theme.palette.text.secondary,
            typography: 'caption',
          })}
        >
          {timeLeft === 0 ? 'Code expired' : `Code expires in ${formatTime(timeLeft)}`}
        </Box>
        {errorMessage && (
          <Alert severity="error" sx={{ mt: 1 }}>
            {errorMessage}
          </Alert>
        )}
      </Box>
    </GenericDrawerOrModal>
  );
};
