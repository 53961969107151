import { Box, Checkbox, CheckboxProps, DialogTitle, Tooltip, useTheme } from '@mui/material';
import { Dispatch, SetStateAction, memo } from 'react';
import { RankBadge, TeamBadge, getTooltipProps } from '@stationwise/component-module';
import { ShiftPlanChangeLog, ShiftPlanAssignment } from '@stationwise/share-types';
import { shortenEmployeeName } from '@stationwise/share-utils';
import { formatAssignment } from '../../helpers/readAssignments';
import { getAction } from '../../helpers/readChangeLog';
import { ShiftPlanDialogActions } from '../ShiftPlanDialog';

interface ChangeLogCheckboxProps extends CheckboxProps {
  disabledReason?: string;
}

const ChangeLogCheckbox = ({ disabledReason, ...props }: ChangeLogCheckboxProps) => {
  const checkbox = (
    <Box sx={{ display: 'flex', position: 'absolute', top: '4px', right: '4px' }}>
      <Checkbox {...props} />
    </Box>
  );
  if (!props.disabled || !disabledReason) {
    return checkbox;
  }

  return (
    <Tooltip arrow title={disabledReason} placement="bottom" slotProps={getTooltipProps()}>
      {checkbox}
    </Tooltip>
  );
};

interface StructLogsProps {
  changeLog: ShiftPlanChangeLog;
}

const StructLogs = memo((props: StructLogsProps) => {
  const theme = useTheme();
  const { removedStructs, upsertedStructs, changes } = props.changeLog;

  if (!removedStructs.length && !upsertedStructs.length) {
    return null;
  }

  return (
    <Box sx={{ border: `1px solid ${theme.palette.divider}`, borderRadius: 1, mt: 1, p: 2, position: 'relative' }}>
      <ChangeLogCheckbox checked={true} disabled={true} disabledReason="Department structure changes must be published" />
      <Box sx={{ pb: 2, pr: 4, typography: 'bodySMedium' }}>Department structure</Box>
      {[removedStructs, upsertedStructs].map((changedStructs) => {
        const isOriginal = changedStructs === removedStructs;
        const logs: string[] = [];
        changedStructs.forEach((station) => {
          const originalStationId = isOriginal ? station.stationId : station.copyOfId;
          const stationAction = getAction(originalStationId, changes.stations);
          if (stationAction !== 'Unchanged') {
            const stationLog = `${stationAction} ${station.stationName}`;
            logs.push(stationLog);
          }
          station.apparatuses.forEach((apparatus) => {
            const originalApparatusId = isOriginal ? apparatus.id : apparatus.copyOfId;
            const apparatusAction = getAction(originalApparatusId, changes.apparatuses);
            if (apparatusAction !== 'Unchanged') {
              const apparatusLog = `${apparatusAction} ${station.stationName} / ${apparatus.name}`;
              logs.push(apparatusLog);
            }
            apparatus.positions.forEach((position) => {
              const originalPositionId = isOriginal ? position.id : position.copyOfId;
              const positionAction = getAction(originalPositionId, changes.positions);
              if (positionAction !== 'Unchanged') {
                const positionLog = `${positionAction} ${station.stationName} / ${apparatus.name} / ${position.rank.code}`;
                logs.push(positionLog);
              }
            });
          });
        });
        return logs.map((log, i) => (
          <Box key={`${isOriginal}:${i}`} sx={{ color: theme.palette.text.secondary, typography: 'bodySMedium' }}>
            {log}
          </Box>
        ));
      })}
    </Box>
  );
});

StructLogs.displayName = 'Memo(StructLogs)';

interface AssignmentLogProps {
  changeLog: ShiftPlanChangeLog;
  employee: ShiftPlanAssignment['employee'];
  isSelected: boolean;
  setPublishEmployeeIds: Dispatch<SetStateAction<Set<string>>>;
}

const AssignmentLog = memo((props: AssignmentLogProps) => {
  const theme = useTheme();
  const { removedAssignmentGroups, upsertedAssignmentGroups, changes } = props.changeLog;
  const removedAssignments = removedAssignmentGroups.get(props.employee.id) || [];
  const upsertedAssignments = upsertedAssignmentGroups.get(props.employee.id) || [];

  if (!removedAssignments.length && !upsertedAssignments.length) {
    return null;
  }

  return (
    <Box
      sx={{
        border: `1px solid ${theme.palette.divider}`,
        borderRadius: 1,
        mt: 1,
        p: 2,
        position: 'relative',
        opacity: props.isSelected ? 1 : 0.5,
      }}
    >
      <ChangeLogCheckbox
        checked={props.isSelected}
        disabled={props.changeLog.readonlyEmployeeIds.has(props.employee.id)}
        disabledReason="Assignments affected by department structure changes must be published"
        onChange={() => {
          props.setPublishEmployeeIds((prevEmployeeIds) => {
            const newEmployeeIds = new Set(prevEmployeeIds);
            if (newEmployeeIds.has(props.employee.id)) {
              newEmployeeIds.delete(props.employee.id);
            } else {
              newEmployeeIds.add(props.employee.id);
            }
            return newEmployeeIds;
          });
        }}
      />
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5, pr: 4 }}>
        <RankBadge rank={props.employee.rank} />
        <Box sx={{ typography: 'bodySMedium' }}>{shortenEmployeeName(props.employee.name)}</Box>
      </Box>
      {[removedAssignments, upsertedAssignments].map((changedAssignments) => {
        const isOriginal = changedAssignments === removedAssignments;

        return changedAssignments.map((assignment) => {
          const originalAssignmentId = isOriginal ? assignment.id : assignment.copyOfId;
          const assignmentTexts = formatAssignment(assignment);
          const { team } = assignment;

          return (
            <Box key={`${isOriginal}:${assignment.id}`} sx={{ pt: 2, typography: 'bodySRegular' }}>
              <Box sx={{ color: theme.palette.text.secondary, typography: 'bodySMedium' }}>
                {`${getAction(originalAssignmentId, changes.assignments)} assignment`}
              </Box>
              <Box sx={{ display: 'flex' }}>
                {team && <TeamBadge team={team} sx={{ my: 0.25 }} />}
                {!team && <Box sx={{ typography: 'bodySMedium' }}>Unassigned</Box>}
              </Box>
              {`Effective ${assignmentTexts.activationDate} - ${assignmentTexts.deactivationDate}`}
              <br />
              {`${assignmentTexts.location}, ${assignmentTexts.startTime} - ${assignmentTexts.endTime}`}
              <br />
              {`Pay codes: ${assignmentTexts.payCodes}`}
              <br />
              {`Detail codes: ${assignmentTexts.detailCodes}`}
              <br />
              {`Priority: ${assignmentTexts.priority}`}
              <br />
              {`Full shift: ${assignmentTexts.fullShift}`}
            </Box>
          );
        });
      })}
    </Box>
  );
});

AssignmentLog.displayName = 'Memo(AssignmentLog)';

interface ChangeLogSelectProps {
  changeLog: ShiftPlanChangeLog;
  publishEmployeeIds: Set<string>;
  setPublishEmployeeIds: Dispatch<SetStateAction<Set<string>>>;
  setPublishStep: Dispatch<SetStateAction<number>>;
}

export const ChangeLogSelect = (props: ChangeLogSelectProps) => {
  const { removedStructs, upsertedStructs, affectedEmployees } = props.changeLog;

  const isSaveDisabled = !removedStructs.length && !upsertedStructs.length && !props.publishEmployeeIds.size;

  const onSave = () => props.setPublishStep((prevStep) => prevStep + 1);

  return (
    <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column', width: '495px', maxWidth: '100%', minHeight: '1px' }}>
      <DialogTitle sx={{ px: 3, pt: 3, pb: 2, typography: 'bodyXLSemibold' }}>Publish roster updates</DialogTitle>
      <Box sx={{ flex: 1, minHeight: '1px', overflowY: 'auto', px: 3, pt: 1 }}>
        <Box sx={{ typography: 'bodyMMedium' }}>Change log</Box>
        <StructLogs changeLog={props.changeLog} />
        {affectedEmployees.map((employee) => (
          <AssignmentLog
            key={employee.id}
            changeLog={props.changeLog}
            employee={employee}
            isSelected={props.publishEmployeeIds.has(employee.id)}
            setPublishEmployeeIds={props.setPublishEmployeeIds}
          />
        ))}
      </Box>
      <ShiftPlanDialogActions isSaveDisabled={isSaveDisabled} saveText="Continue" onSave={onSave} />
    </Box>
  );
};
