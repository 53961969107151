import { Box, Dialog, FormHelperText, useTheme } from '@mui/material';
import { format, isValid, setMinutes, startOfDay } from 'date-fns';
import { Dispatch, SetStateAction, useState } from 'react';
import {
  AdminAutocomplete,
  AdminSelectItem,
  asDepartmentDateTime,
  Button,
  DatePicker,
  DialogXButton,
  getTeamColors,
  InputLabel,
  RankBadge,
  SimpleTimePicker,
  SnackbarService,
  TeamBadge,
  useLoadedDepartmentInfoContext,
} from '@stationwise/component-module';
import { isAxiosError } from '@stationwise/share-api';
import { AllowedColors, ShiftPlanAction, ShiftPlanAssignment, ShiftPlanCandidate } from '@stationwise/share-types';
import { differenceInUTCMinutes, shortenEmployeeName } from '@stationwise/share-utils';
import { diffCycleMinutes, getDepartmentPayCodes } from '@stationwise/shift-summary-helper';
import { formatAssignment, initializeAssignments, wasAssignmentPublishedAt } from '../../../helpers/readAssignments';
import { useShiftPlanContext } from '../../ShiftPlanContext';
import { ShiftPlanDialogActions } from '../../ShiftPlanDialog';
import { AdjustPatternPriorityContent } from './AdjustPatternPriorityContent';
import { UpsertAssignmentConflictsMessage } from './UpsertAssignmentConflictsMessage';

interface UpsertAssignmentFormProps {
  person: ShiftPlanCandidate | ShiftPlanAssignment | null;
  team: ShiftPlanAssignment['team'] | null;
  setSelectedPersonPopoverAnchorEl: Dispatch<SetStateAction<HTMLElement | null>>;
  setCandidateAssignments: (candidateId: string, assignments: ShiftPlanAssignment[]) => void;
}

export interface TeamGroupedShiftPlanAssignment {
  teamId: number;
  priority: number;
  fullShift: boolean;
  assignments: ShiftPlanAssignment[];
}

const NULL_DATE = new Date('NULL_DATE');

export const UpsertAssignmentForm = ({ person, ...props }: UpsertAssignmentFormProps) => {
  const theme = useTheme();
  const { state: departmentInfoState } = useLoadedDepartmentInfoContext();
  const { departmentInfo } = departmentInfoState;
  const { shiftDuration, teams, selectedPersonnelStruct, selectedAction, savingAction, setSavingAction, saveAsDraft } =
    useShiftPlanContext();

  const today = startOfDay(asDepartmentDateTime(departmentInfoState, new Date()));
  const yesterday = setMinutes(today, today.getMinutes() - 24 * 60);
  const { station, apparatus, position } = selectedPersonnelStruct;
  const isFloaterPersonnelStruct = station?.stationId === 'floater-station';
  const assignment = person && 'employee' in person ? (person as ShiftPlanAssignment) : null;
  const wasAssignmentPublished = !!assignment && wasAssignmentPublishedAt(assignment, today);
  const assignmentTexts = wasAssignmentPublished ? formatAssignment(assignment) : null;
  const employee = person && 'employee' in person ? person.employee : person;
  const teamOptions = teams.map((t) => ({ color: t.color, label: t.name, value: t.name }));
  const payCodeOptions = departmentInfo.payCodes.map((pc) => ({ label: pc.name, value: pc.code, secondLabel: pc.code }));
  const detailCodeOptions = departmentInfo.detailCodes.map((dc) => ({ label: dc.name, value: dc.code, secondLabel: dc.code }));
  const isAddAction = selectedAction === ShiftPlanAction.ADD_ASSIGNMENT;
  const isEditAction = !!assignment && selectedAction === ShiftPlanAction.EDIT_ASSIGNMENT;

  const [startDate, setStartDate] = useState(() => {
    return isEditAction ? assignment.activationDate : today;
  });
  const [endDate, setEndDate] = useState<Date | null>(() => {
    return isEditAction ? assignment.deactivationDate : null;
  });
  const [startTime, setStartTime] = useState(() => {
    return isEditAction ? assignment.startDateTime : position?.startDateTime || shiftDuration.startTime;
  });
  const [endTime, setEndTime] = useState(() => {
    return isEditAction ? assignment.endDateTime : position?.endDateTime || shiftDuration.endTime;
  });
  const [selectedTeamOption, setSelectedTeamOption] = useState<AdminSelectItem | null>(() => {
    return teamOptions.find((o) => o.value === props.team?.name) || null;
  });
  const [selectedPayCodeOptions, setSelectedPayCodeOptions] = useState<AdminSelectItem[]>(() => {
    const codes = new Set(assignment?.payCodes.map((pc) => pc.code) || []);
    if (!assignment && employee) {
      getDepartmentPayCodes(departmentInfo, [employee.defaults.regularAssignmentPayCode]).forEach((pc) => codes.add(pc.code));
    }
    return payCodeOptions.filter((o) => codes.has(o.value));
  });
  const [selectedDetailCodeOptions, setSelectedDetailCodeOptions] = useState<AdminSelectItem[]>(() => {
    const codes = new Set(assignment?.detailCodes.map((pc) => pc.code) || []);
    return detailCodeOptions.filter((o) => codes.has(o.value));
  });
  const [conflictingAssignments, setConflictingAssignments] = useState<ShiftPlanAssignment[]>([]);
  const [futureAssignmentsByTeam, setFutureAssignmentsByTeam] = useState<TeamGroupedShiftPlanAssignment[]>([]);
  const [isConflictingAssignmentsDialogOpen, setIsConflictingAssignmentsDialogOpen] = useState(false);
  const [teamPriorities, setTeamPriorities] = useState<{ [index: number]: { priority: number; fullShift: boolean } }>({});
  const [isPatternPriorityDialogOpen, setIsPatternPriorityDialogOpen] = useState(false);
  const [isConfirming, setIsConfirming] = useState(false);

  if (!(isAddAction || isEditAction) || !station || !apparatus || !employee) {
    return null;
  }

  const getShiftTime = (key: 'start' | 'end', time: Date | null) => {
    if (!time || !isValid(time)) {
      return NULL_DATE;
    }

    const minutes = diffCycleMinutes(time, shiftDuration.startTime) || (key === 'end' ? 24 * 60 : 0);
    return setMinutes(shiftDuration.startTime, shiftDuration.startTime.getMinutes() + minutes);
  };

  const errors = (() => {
    const errors: Record<string, string> = {};
    if (!isValid(startDate)) {
      errors.startDate = '';
    } else if (!wasAssignmentPublished && startDate < today) {
      errors.startDate = 'Start date cannot be before today';
    }
    if (endDate) {
      if (!isValid(endDate) || errors.startDate !== undefined) {
        errors.endDate = '';
      } else if (endDate < startDate) {
        errors.endDate = 'End date cannot be before start date';
      } else if (endDate < yesterday) {
        errors.endDate = 'End date cannot be before yesterday';
      }
    }
    if (!isValid(startTime)) {
      errors.startTime = '';
    } else if (position && !(position.startDateTime <= startTime && startTime <= position.endDateTime)) {
      errors.startTime = 'Shift start time must be between position times';
    }
    if (!isValid(endTime) || errors.startTime !== undefined) {
      errors.endTime = '';
    } else if (position && !(startTime < endTime && endTime <= position.endDateTime)) {
      errors.endTime = 'Shift end time must be between position times and after shift start time';
    } else if (!position && !(startTime < endTime)) {
      errors.endTime = 'Shift end time must be after shift start time';
    }
    if (!selectedTeamOption) {
      errors.team = '';
    }
    if (selectedPayCodeOptions.length === 0) {
      errors.payCodes = '';
    }
    return errors;
  })();

  const canSave = Object.keys(errors).length === 0;
  const isSaveLoading = !!savingAction && !isConfirming;

  const onCancel = () => !savingAction && props.setSelectedPersonPopoverAnchorEl(null);

  const save = (isConfirmed: boolean) => {
    const selectedTeam = teams.find((t) => t.name === selectedTeamOption?.value);
    const selectedPayCodeValues = new Set(selectedPayCodeOptions.map((t) => t.value));
    const selectedPayCodes = departmentInfo.payCodes.filter((pc) => selectedPayCodeValues.has(pc.code));
    const selectedDetailCodeValues = new Set(selectedDetailCodeOptions.map((t) => t.value));
    const selectedDetailCodes = departmentInfo.detailCodes.filter((dc) => selectedDetailCodeValues.has(dc.code));
    const payload = {
      action: 'UPSERT_ASSIGNMENT',
      isConfirmed,
      assignmentId: isEditAction ? assignment.id : undefined,
      employeeId: employee.id,
      positionId: position?.id || null,
      apparatusId: (!position && !isFloaterPersonnelStruct && apparatus.id) || null,
      activationDate: format(startDate, 'yyyy-MM-dd'),
      deactivationDate: endDate ? format(endDate, 'yyyy-MM-dd') : null,
      startTime: differenceInUTCMinutes(startTime, shiftDuration.startTime),
      endTime: differenceInUTCMinutes(endTime, shiftDuration.startTime),
      shiftTeamId: selectedTeam?.id,
      payCodeIds: selectedPayCodes.map((pc) => pc.id),
      detailCodeIds: selectedDetailCodes.map((dc) => dc.id),
      teamPriorities: teamPriorities,
    };

    setIsConfirming(isConfirmed);
    saveAsDraft(
      payload,
      (response) => {
        if (response.data.employeeAssignments) {
          props.setCandidateAssignments(employee.id, initializeAssignments(departmentInfo, response.data.employeeAssignments));
        }
        setIsConflictingAssignmentsDialogOpen(false);
        props.setSelectedPersonPopoverAnchorEl(null);
      },
      (error) => {
        setIsConfirming(false);
        if (isAxiosError(error) && error.response) {
          if (error.response.data.error === 'CONFLICTING_ASSIGNMENTS') {
            setConflictingAssignments(initializeAssignments(departmentInfo, error.response.data.employeeAssignments));
            setIsConflictingAssignmentsDialogOpen(true);
            setSavingAction(null);
            return;
          } else if (error.response.data.error === 'OTHER_ASSIGNMENTS') {
            const assignments = initializeAssignments(departmentInfo, error.response.data.employeeAssignments);
            const assignmentsByTeam: Map<number, ShiftPlanAssignment[]> = new Map();
            assignments.forEach((assignment) => {
              let list = assignmentsByTeam.get(assignment.team.id) || [];
              list.push(assignment);
              assignmentsByTeam.set(assignment.team.id, list);
            });
            const teamGroupedAssignments = Array.from(assignmentsByTeam.entries()).map(([teamId, assignments]) => ({
              priority: assignments[0].priority,
              fullShift: assignments[0].fullShift,
              teamId,
              assignments,
            }));
            const sortedTeamGroupedAssignments = [...teamGroupedAssignments].sort((a, b) => a.priority - b.priority);
            sortedTeamGroupedAssignments.forEach((assignmentGroup, index) => {
              assignmentGroup.priority = index + 1;
            });
            setFutureAssignmentsByTeam(sortedTeamGroupedAssignments);
            // TODO: adjust this following lines and snackbar message to enable priority modal
            // setIsPatternPriorityDialogOpen(true);
            setIsPatternPriorityDialogOpen(false);
            setSavingAction(null);
            SnackbarService.notify({
              content: 'Overlapping time range for employee with same priority exists',
              severity: 'error',
              duration: 5000,
            });
            return;
          }
        }
        return error;
      },
    );
  };

  const onSave = () => save(false);

  const onCancelSave = () => {
    if (!isConfirming) {
      setIsConflictingAssignmentsDialogOpen(false);
      setSavingAction(null);
      setIsPatternPriorityDialogOpen(false);
      setTeamPriorities({});
    }
  };

  const onConfirmSave = () => save(true);

  return (
    <Box sx={{ px: 2, width: '376px', maxHeight: '670px' }}>
      <Box sx={{ pt: 2, typography: 'heading6' }}>{isEditAction ? 'Edit assignment' : 'New assignment'}</Box>
      <Box sx={{ color: theme.palette.text.secondary, typography: 'subtitle1' }}>
        {isFloaterPersonnelStruct ? station.stationName : `${station.stationName} / ${apparatus.name}`}
      </Box>
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5, pt: 1.5 }}>
        <RankBadge rank={employee.rank} />
        <Box sx={{ typography: 'bodySMedium' }}>{shortenEmployeeName(employee.name)}</Box>
      </Box>
      {wasAssignmentPublished && (
        <Box sx={{ pt: 1.5, color: theme.palette.gray[700], typography: 'bodySMedium' }}>
          Already published assignments can only be deactivated
        </Box>
      )}
      <Box sx={{ display: 'flex', flexDirection: 'column', pt: 1.25 }}>
        <InputLabel>Staffing pattern</InputLabel>
        {wasAssignmentPublished && <TeamBadge team={assignment.team} sx={{ alignSelf: 'flex-start' }} />}
        {!wasAssignmentPublished && (
          <AdminAutocomplete
            multiple={false}
            placeholder="Choose staffing pattern"
            options={teamOptions}
            value={selectedTeamOption}
            onChange={(_event, value) => setSelectedTeamOption(value)}
            getChipSx={(option) => ({
              ...getTeamColors({ theme, color: option.color }),
              '& .MuiChip-deleteIcon': {
                color: theme.palette.common.white,
                '&:hover': { color: theme.palette.gray[100] },
              },
            })}
            renderOptionValue={(option) => {
              return <TeamBadge team={{ name: option.value, color: (option.color || 'gray') as AllowedColors }} />;
            }}
          />
        )}
        {errors.team && <FormHelperText error={true}>{errors.team}</FormHelperText>}
      </Box>
      <Box sx={{ pt: 1.25 }}>
        <Box sx={{ display: 'flex', gap: 2 }}>
          <Box sx={{ display: 'inline-flex', flexDirection: 'column', flex: 1 }}>
            <InputLabel>Shift start</InputLabel>
            {wasAssignmentPublished && assignmentTexts?.startTime}
            {!wasAssignmentPublished && (
              <SimpleTimePicker
                value={startTime}
                setValue={(value) => setStartTime(getShiftTime('start', value))}
                sxProps={{ width: '100%' }}
              />
            )}
          </Box>
          <Box sx={{ display: 'inline-flex', flexDirection: 'column', flex: 1 }}>
            <InputLabel>Shift end</InputLabel>
            {wasAssignmentPublished && assignmentTexts?.endTime}
            {!wasAssignmentPublished && (
              <SimpleTimePicker
                value={endTime}
                setValue={(value) => setEndTime(getShiftTime('end', value))}
                sxProps={{ width: '100%' }}
              />
            )}
          </Box>
        </Box>
        {errors.startTime && <FormHelperText error={true}>{errors.startTime}</FormHelperText>}
        {errors.endTime && <FormHelperText error={true}>{errors.endTime}</FormHelperText>}
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'column', pt: 1.25 }}>
        <InputLabel>Assignment start</InputLabel>
        {wasAssignmentPublished && assignmentTexts?.activationDate}
        {!wasAssignmentPublished && (
          <DatePicker
            minDate={today}
            disabled={isEditAction && assignment.activationDate < today}
            value={startDate}
            onChange={(value) => setStartDate(value || NULL_DATE)}
            sx={{ width: '100%' }}
            slotProps={{ textField: { placeholder: 'Choose start' } }}
          />
        )}
        {errors.startDate && <FormHelperText error={true}>{errors.startDate}</FormHelperText>}
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'column', pt: 1.25 }}>
        <InputLabel>Assignment end (optional)</InputLabel>
        <DatePicker
          minDate={isValid(startDate) && startDate > yesterday ? startDate : yesterday}
          value={endDate}
          onChange={(value) => setEndDate(value)}
          sx={{ width: '100%' }}
          slotProps={{ textField: { placeholder: 'Choose end' } }}
        />
        {errors.endDate && <FormHelperText error={true}>{errors.endDate}</FormHelperText>}
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'column', pt: 1.25 }}>
        <InputLabel>Pay codes</InputLabel>
        {wasAssignmentPublished && assignmentTexts?.payCodes}
        {!wasAssignmentPublished && (
          <AdminAutocomplete
            multiple={true}
            placeholder="Choose pay codes"
            options={payCodeOptions}
            value={selectedPayCodeOptions}
            onChange={(_event, value) => setSelectedPayCodeOptions(value)}
          />
        )}
        {errors.payCodes && <FormHelperText error={true}>{errors.payCodes}</FormHelperText>}
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'column', pt: 1.25 }}>
        <InputLabel>Detail codes (optional)</InputLabel>
        {wasAssignmentPublished && assignmentTexts?.detailCodes}
        {!wasAssignmentPublished && (
          <AdminAutocomplete
            multiple={true}
            placeholder="Choose detail codes"
            options={detailCodeOptions}
            value={selectedDetailCodeOptions}
            onChange={(_event, value) => setSelectedDetailCodeOptions(value)}
          />
        )}
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 1, pt: 3, pb: 2 }}>
        <Button variant="outlined" disabled={isSaveLoading} onClick={onCancel}>
          Cancel
        </Button>
        <Button variant="contained" disabled={!canSave} loading={isSaveLoading} onClick={onSave}>
          Confirm
        </Button>
      </Box>
      <Dialog open={isConflictingAssignmentsDialogOpen} onClose={onCancelSave}>
        <DialogXButton onClose={onCancelSave} />
        <UpsertAssignmentConflictsMessage
          startDate={startDate}
          endDate={endDate}
          conflictingAssignments={conflictingAssignments}
        />
        <ShiftPlanDialogActions
          isCancelDisabled={isConfirming}
          isSaveLoading={isConfirming}
          saveText="Confirm"
          onCancel={onCancelSave}
          onSave={onConfirmSave}
        />
      </Dialog>
      <Dialog
        sx={{ '& .MuiDialog-paper': { width: '90%', maxWidth: 'none' } }}
        open={isPatternPriorityDialogOpen}
        onClose={onCancelSave}
      >
        <DialogXButton onClose={onCancelSave} />
        <Box sx={{ ml: 3 }}>
          <Box sx={{ pt: 2, typography: 'heading6' }}>New pattern priority</Box>
          <Box sx={{ color: theme.palette.text.secondary, typography: 'subtitle1' }}>
            You are assigning a new pattern to this person. Please set up its priority in relation to their current patterns.
          </Box>

          <Box sx={{ display: 'flex', mt: 2 }}>
            <RankBadge rank={employee.rank} />
            <Box
              sx={{
                typography: 'bodySMedium',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                ml: 1,
              }}
            >
              {shortenEmployeeName(employee.name)}
            </Box>
          </Box>

          <AdjustPatternPriorityContent
            setTeamPriorities={setTeamPriorities}
            futureTeamAssignments={futureAssignmentsByTeam}
            setFutureAssignmentsByTeam={setFutureAssignmentsByTeam}
            teams={teams}
          />

          <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 1, pt: 3, pb: 2 }}>
            <Button disabled={isConfirming} variant="outlined" onClick={onCancelSave}>
              Cancel
            </Button>
            <Button loading={isConfirming} variant="contained" onClick={onConfirmSave} sx={{ mr: 3 }}>
              Confirm
            </Button>
          </Box>
        </Box>
      </Dialog>
    </Box>
  );
};
