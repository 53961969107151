import { PriorityHighOutlined } from '@mui/icons-material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { Box, capitalize, Typography, Select, MenuItem, FormControl, SelectChangeEvent } from '@mui/material';
import { useState } from 'react';
import { Button, CustomTable, SearchAndFilter, TableProps, theme } from '@stationwise/component-module';
import { Employee, Rank } from '@stationwise/share-types';
import { camelCaseToTitle } from '@stationwise/share-utils';
import { FilterOptions } from '../../DepartmentSettings/components/DepartmentSettingsUtil';

export type GoodStandingSummaryRowProps = {
  employee: Employee;
  rank: Rank;
  credentials: string;
  month1: number;
  month2: number;
  month3: number;
  quarter: number;
  year: number;
  total: number;
  status: string;
  stipend: number;
};

export const GoodStandingSummaryContent = ({}) => {
  const [searchInput, setSearchInput] = useState('');
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [filterOptions, setFilterOptions] = useState<FilterOptions>({});
  const [selectedFilters, setSelectedFilters] = useState<Record<string, string>>({});

  const handleSearchChange = (value: string) => {
    setSearchInput(value);
  };

  const handleFilterChange = (filterName: string, value: string) => {
    setSelectedFilters((prev) => ({ ...prev, [filterName]: value }));
  };

  const filters = Object.entries(filterOptions).map(([filterName, options]) => ({
    name: camelCaseToTitle(capitalize(filterName)),
    options: options.map((option) => ({
      label: capitalize(option),
      value: capitalize(option),
    })),
    selected: selectedFilters[filterName],
    onChange: (value: string) => handleFilterChange(filterName, value),
  }));

  // TODO: Uncomment this when we have the API endpoint
  // useEffect(() => {
  //   const fetchFilterOptions = async () => {
  //     try {
  //       const response = await client.get('employee/good_standing/filter_options/');
  //       if (response.data) {
  //         setFilterOptions(response.data);
  //       }
  //     } catch (error) {
  //       captureException(error);
  //     }
  //   };
  //   fetchFilterOptions();
  // }, []);

  const getCurrentQuarter = () => {
    const now = new Date();
    const month = now.getMonth();
    const year = now.getFullYear();
    const quarter = Math.floor(month / 3) + 1;
    return `Q${quarter} ${year}`;
  };

  const getAvailableQuarters = () => {
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const currentMonth = currentDate.getMonth();
    const currentQuarter = Math.floor(currentMonth / 3) + 1;
    const quarters = [];

    for (let year = currentYear - 1; year <= currentYear; year++) {
      for (let quarter = 1; quarter <= 4; quarter++) {
        if (year < currentYear || (year === currentYear && quarter <= currentQuarter)) {
          quarters.push(`Q${quarter} ${year}`);
        }
      }
    }
    return quarters.reverse();
  };

  const [selectedQuarter, setSelectedQuarter] = useState<string>(getCurrentQuarter());

  const handleQuarterSelect = (event: SelectChangeEvent<string>) => {
    setSelectedQuarter(event.target.value);
  };

  const handleQuarterChange = (direction: 'prev' | 'next') => {
    const [quarter, year] = selectedQuarter.split(' ');
    const quarterNum = parseInt(quarter.substring(1));
    let newQuarterNum = quarterNum;
    let newYear = parseInt(year);

    if (direction === 'next') {
      if (quarterNum === 4) {
        newQuarterNum = 1;
        newYear += 1;
      } else {
        newQuarterNum += 1;
      }
    } else {
      if (quarterNum === 1) {
        newQuarterNum = 4;
        newYear -= 1;
      } else {
        newQuarterNum -= 1;
      }
    }

    setSelectedQuarter(`Q${newQuarterNum} ${newYear}`);
  };

  const isDisabled = (quarter: string) => {
    const currentQuarter = getCurrentQuarter();
    return quarter === currentQuarter;
  };

  const getQuarterMonths = (quarter: string) => {
    const quarterMonths = {
      Q1: [
        { id: 'month1', label: 'January', minWidth: 100, align: 'left' as const },
        { id: 'month2', label: 'February', minWidth: 80, align: 'left' as const },
        { id: 'month3', label: 'March', minWidth: 100, align: 'left' as const },
      ],
      Q2: [
        { id: 'month1', label: 'April', minWidth: 100, align: 'left' as const },
        { id: 'month2', label: 'May', minWidth: 80, align: 'left' as const },
        { id: 'month3', label: 'June', minWidth: 100, align: 'left' as const },
      ],
      Q3: [
        { id: 'month1', label: 'July', minWidth: 100, align: 'left' as const },
        { id: 'month2', label: 'August', minWidth: 80, align: 'left' as const },
        { id: 'month3', label: 'September', minWidth: 100, align: 'left' as const },
      ],
      Q4: [
        { id: 'month1', label: 'October', minWidth: 100, align: 'left' as const },
        { id: 'month2', label: 'November', minWidth: 80, align: 'left' as const },
        { id: 'month3', label: 'December', minWidth: 100, align: 'left' as const },
      ],
    };

    const currentQuarter = quarter.split(' ')[0];
    return quarterMonths[currentQuarter as keyof typeof quarterMonths];
  };

  const columns: TableProps['columns'] = [
    { id: 'name', label: 'Name', minWidth: 150, align: 'left' as const },
    { id: 'rank', label: 'Rank', minWidth: 100, align: 'left' as const },
    { id: 'credentials', label: 'Credentials', minWidth: 150, align: 'left' as const },
    ...getQuarterMonths(selectedQuarter),
    { id: 'total', label: 'Total ' + selectedQuarter.split(' ')[0], minWidth: 100, align: 'left' as const },
    { id: 'status', label: 'Status', minWidth: 80, align: 'left' as const },
    { id: 'stipend', label: 'Stipend', minWidth: 100, align: 'left' as const },
  ];

  const data: TableProps['data'] = [
    {
      name: 'John Doe',
      rank: (
        <Box
          sx={(theme) => ({
            color: theme.palette.gray[600],
            typography: 'bodySRegular',
          })}
        >
          <Box
            sx={(theme) => ({
              backgroundColor: theme.palette.gray[100],
              color: theme.palette.common.black,
              borderRadius: theme.spacing(2.5),
              padding: theme.spacing(0.25, 1),
              typography: 'bodySRegular',
              display: 'flex',
              alignItems: 'left',
              justifyContent: 'center',
            })}
          >
            {'Firefighter'}
          </Box>
        </Box>
      ),
      credentials: (
        <Box
          sx={(theme) => ({
            color: theme.palette.gray[600],
            typography: 'bodySRegular',
          })}
        >
          <Box
            sx={(theme) => ({
              typography: 'bodySRegular',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'left',
              gap: theme.spacing(1),
            })}
          >
            {'8/8'}
            <CheckCircleIcon sx={{ color: theme.palette.success.main }} />
          </Box>
        </Box>
      ),
      month1: 10,
      month2: 11,
      month3: 5,
      quarter: 1,
      year: 2025,
      total: 26,
      status: (
        <Box
          sx={(theme) => ({
            color: theme.palette.gray[600],
            typography: 'bodySRegular',
          })}
        >
          <Box
            sx={(theme) => ({
              backgroundColor: theme.palette.green[500],
              color: theme.palette.common.white,
              borderRadius: theme.spacing(2.5),
              padding: theme.spacing(0.25, 1),
              typography: 'bodySRegular',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            })}
          >
            {'OK'}
          </Box>
        </Box>
      ),
      stipend: 800,
    },
    {
      name: 'Jane Doe',
      rank: (
        <Box
          sx={(theme) => ({
            color: theme.palette.gray[600],
            typography: 'bodySRegular',
          })}
        >
          <Box
            sx={(theme) => ({
              backgroundColor: theme.palette.gray[100],
              color: theme.palette.common.black,
              borderRadius: theme.spacing(2.5),
              padding: theme.spacing(0.25, 1),
              typography: 'bodySRegular',
              display: 'flex',
              alignItems: 'left',
              justifyContent: 'center',
            })}
          >
            {'Firefighter'}
          </Box>
        </Box>
      ),
      credentials: (
        <Box
          sx={(theme) => ({
            color: theme.palette.gray[600],
            typography: 'bodySRegular',
          })}
        >
          <Box
            sx={(theme) => ({
              typography: 'bodySRegular',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'left',
              gap: theme.spacing(1),
            })}
          >
            {'8/8'}
            <CheckCircleIcon sx={{ color: theme.palette.success.main }} />
          </Box>
        </Box>
      ),
      month1: 10,
      month2: 10,
      month3: 1,
      quarter: 4,
      year: 2024,
      total: 21,
      status: (
        <Box
          sx={(theme) => ({
            color: theme.palette.gray[600],
            typography: 'bodySRegular',
          })}
        >
          <Box
            sx={(theme) => ({
              backgroundColor: theme.palette.green[500],
              color: theme.palette.common.white,
              borderRadius: theme.spacing(2.5),
              padding: theme.spacing(0.25, 1),
              typography: 'bodySRegular',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            })}
          >
            {'OK'}
          </Box>
        </Box>
      ),
      stipend: 1100,
    },
    {
      name: 'Dylan Johnson',
      rank: (
        <Box
          sx={(theme) => ({
            color: theme.palette.gray[600],
            typography: 'bodySRegular',
          })}
        >
          <Box
            sx={(theme) => ({
              backgroundColor: theme.palette.gray[100],
              color: theme.palette.common.black,
              borderRadius: theme.spacing(2.5),
              padding: theme.spacing(0.25, 1),
              typography: 'bodySRegular',
              display: 'flex',
              alignItems: 'left',
              justifyContent: 'center',
            })}
          >
            {'Firefighter'}
          </Box>
        </Box>
      ),
      credentials: (
        <Box
          sx={(theme) => ({
            color: theme.palette.gray[600],
            typography: 'bodySRegular',
          })}
        >
          <Box
            sx={(theme) => ({
              typography: 'bodySRegular',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'left',
              gap: theme.spacing(1),
            })}
          >
            {'6/8'}
            <PriorityHighOutlined
              sx={{
                backgroundColor: theme.palette.error.main,
                color: theme.palette.common.white,
                fontSize: '20px',
                borderRadius: '50%',
                padding: '3px',
              }}
            />
          </Box>
        </Box>
      ),
      month1: 11,
      month2: 11,
      month3: 12,
      quarter: 3,
      year: 2024,
      total: 34,
      status: (
        <Box
          sx={(theme) => ({
            color: theme.palette.gray[600],
            typography: 'bodySRegular',
          })}
        >
          <Box
            sx={(theme) => ({
              backgroundColor: theme.palette.gray[100],
              color: theme.palette.common.black,
              borderRadius: theme.spacing(2.5),
              padding: theme.spacing(0.25, 1),
              typography: 'bodySRegular',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            })}
          >
            {'N/A'}
          </Box>
        </Box>
      ),
      stipend: 1200,
    },
    {
      name: 'John Doe',
      rank: (
        <Box
          sx={(theme) => ({
            color: theme.palette.gray[600],
            typography: 'bodySRegular',
          })}
        >
          <Box
            sx={(theme) => ({
              backgroundColor: theme.palette.gray[100],
              color: theme.palette.common.black,
              borderRadius: theme.spacing(2.5),
              padding: theme.spacing(0.25, 1),
              typography: 'bodySRegular',
              display: 'flex',
              alignItems: 'left',
              justifyContent: 'center',
            })}
          >
            {'Firefighter'}
          </Box>
        </Box>
      ),
      credentials: (
        <Box
          sx={(theme) => ({
            color: theme.palette.gray[600],
            typography: 'bodySRegular',
          })}
        >
          <Box
            sx={(theme) => ({
              typography: 'bodySRegular',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'left',
              gap: theme.spacing(1),
            })}
          >
            {'5/8'}
            <PriorityHighOutlined
              sx={{
                backgroundColor: theme.palette.error.main,
                color: theme.palette.common.white,
                fontSize: '20px',
                borderRadius: '50%',
                padding: '3px',
              }}
            />
          </Box>
        </Box>
      ),
      month1: 10,
      month2: 10,
      month3: 14,
      quarter: 4,
      year: 2024,
      total: 34,
      status: (
        <Box
          sx={(theme) => ({
            color: theme.palette.gray[600],
            typography: 'bodySRegular',
          })}
        >
          <Box
            sx={(theme) => ({
              backgroundColor: theme.palette.gray[100],
              color: theme.palette.common.black,
              borderRadius: theme.spacing(2.5),
              padding: theme.spacing(0.25, 1),
              typography: 'bodySRegular',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            })}
          >
            {'N/A'}
          </Box>
        </Box>
      ),
      stipend: 1500,
    },
  ];

  const getFilteredData = () => {
    const [quarter, year] = selectedQuarter.split(' ');
    const quarterNum = parseInt(quarter.substring(1));
    const yearNum = parseInt(year);

    return data.filter((row) => row.quarter === quarterNum && row.year === yearNum);
  };

  const calculateTotalStipend = () => {
    return getFilteredData().reduce((sum, row) => sum + (row.stipend || 0), 0);
  };

  return (
    <Box sx={{ px: theme.spacing(5) }}>
      <Box sx={{ display: 'flex', alignItems: 'center', gap: theme.spacing(2) }}>
        <Button
          size="medium"
          variant="outlined"
          sx={{ textTransform: 'none', padding: '6px 16px' }}
          onClick={() => setSelectedQuarter(getCurrentQuarter())}
        >
          Current Quarter
        </Button>
        <ChevronLeftIcon sx={{ color: theme.palette.gray[500], cursor: 'pointer' }} onClick={() => handleQuarterChange('prev')} />
        <ChevronRightIcon
          sx={{
            color: isDisabled(selectedQuarter) ? theme.palette.gray[300] : theme.palette.gray[500],
            cursor: isDisabled(selectedQuarter) ? 'not-allowed' : 'pointer',
          }}
          onClick={() => !isDisabled(selectedQuarter) && handleQuarterChange('next')}
        />
        <FormControl sx={{ minWidth: 200 }}>
          <Select
            value={selectedQuarter}
            onChange={handleQuarterSelect}
            sx={{
              height: '40px',
              '& .MuiSelect-select': {
                paddingTop: '8px',
                paddingBottom: '8px',
              },
            }}
          >
            {getAvailableQuarters().map((quarter) => (
              <MenuItem key={quarter} value={quarter}>
                {quarter}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>

      <Box sx={{ py: theme.spacing(2), display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <Box
          sx={{
            borderRadius: 'var(--1, 8px)',
            border: `1px solid ${theme.palette.gray[200]}`,
            padding: theme.spacing(3),
            width: '30%',
          }}
        >
          <Typography variant="body2" color={theme.palette.text.secondary} sx={{ mb: theme.spacing(1) }}>
            Total members in good standing
          </Typography>
          <Typography variant="heading3">{getFilteredData().length}</Typography>
        </Box>
        <Box
          sx={{
            borderRadius: 'var(--1, 8px)',
            border: `1px solid ${theme.palette.gray[200]}`,
            padding: theme.spacing(3),
            width: '30%',
          }}
        >
          <Typography variant="body2" color={theme.palette.text.secondary} sx={{ mb: theme.spacing(1) }}>
            Total stipend amount owed
          </Typography>
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <Typography variant="heading3">${calculateTotalStipend().toLocaleString()}</Typography>
            <Typography variant="heading3" color={theme.palette.text.secondary}>
              {selectedQuarter.split(' ')[0]}
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{
            borderRadius: 'var(--1, 8px)',
            border: `1px solid ${theme.palette.gray[200]}`,
            padding: theme.spacing(3),
            width: '30%',
          }}
        >
          <Typography variant="body2" color={theme.palette.text.secondary} sx={{ mb: theme.spacing(1) }}>
            Sync status
          </Typography>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: theme.spacing(1), justifyContent: 'space-between' }}>
            <Typography variant="heading3">OK</Typography>
            <CheckCircleIcon sx={{ color: theme.palette.success.main }} />
          </Box>
        </Box>
      </Box>
      <SearchAndFilter
        searchText={searchInput}
        setSearchText={handleSearchChange}
        filters={filters}
        clearAllFilters={() => setSelectedFilters({})}
        searchPlaceholder="Search for people in your team"
      />
      <Box>
        <CustomTable columns={columns} data={getFilteredData()} />
      </Box>
    </Box>
  );
};
