import { Box, TextField, Typography } from '@mui/material';
import React, { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { client } from '@stationwise/share-api';
import { CheckIcon24, XIcon24 } from '../../assets';
import { useDocumentTitle } from '../../hooks';
import { Button } from '../Button';
import { theme } from '../ThemeProvider';
import { LogoWrapper } from './components/LogoWrapper';

interface PageWrapperProps {
  icon: React.ReactNode;
  iconBackgroundColor: string;
  children?: React.ReactNode;
  infoText?: React.ReactNode;
}

const PageWrapper: React.FC<PageWrapperProps> = ({ icon, iconBackgroundColor, infoText, children }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        textAlign: 'center',
      }}
    >
      <Box
        sx={{
          width: '80px',
          height: '80px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          borderRadius: '50%',
          color: theme.palette.common.white,
          backgroundColor: iconBackgroundColor,
        }}
      >
        {icon}
      </Box>
      <Typography
        sx={{
          color: theme.palette.gray[900],
          typography: 'bodyXXLMedium',
          textAlign: 'center',
          mt: 3,
        }}
      >
        {infoText}
      </Typography>
      {children}
    </Box>
  );
};

const ErrorPage: React.FC<{ retry: () => void }> = ({ retry }) => (
  <PageWrapper
    icon={<XIcon24 height={40} width={40} />}
    iconBackgroundColor={theme.palette.rose[500]}
    infoText={<span>{"Something went wrong, we weren't able to log you in."}</span>}
  >
    <Typography
      sx={{
        color: theme.palette.gray[900],
        typography: 'bodyLRegular',
        textAlign: 'center',
        mt: 1,
      }}
    >
      {'Tap on "generate new code" link on your other device and try again.'}
    </Typography>
    <Button onClick={retry} variant="contained" size="large" sx={{ mt: '40px', width: '100%', maxWidth: '300px' }}>
      Try Again
    </Button>
  </PageWrapper>
);

const SuccessPage: React.FC = () => (
  <PageWrapper
    icon={<CheckIcon24 height={40} width={40} />}
    iconBackgroundColor={theme.palette.green[500]}
    infoText={
      <span>
        Log in successful.
        <br />
        Check your other device.
      </span>
    }
  />
);

export const ApparatusLoginActivationPage: React.FC = () => {
  useDocumentTitle('Activate Apparatus Login');
  const [code, setCode] = useState(Array(6).fill(''));
  const inputRefs = useRef<Array<React.RefObject<HTMLInputElement>>>(Array.from({ length: 6 }, () => React.createRef()));
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState<'activation' | 'success' | 'error'>('activation');

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, index: number) => {
    const newCode = [...code];
    newCode[index] = e.target.value;
    setCode(newCode);
    if (e.target.value && index < 5) {
      inputRefs.current[index + 1].current?.focus();
    } else if (e.target.value === '' && index > 0) {
      inputRefs.current[index - 1].current?.focus();
    }
  };

  const navigate = useNavigate();

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const activationCode = urlParams.get('code');
    if (activationCode && code.every((char) => char === '')) {
      setCode([...activationCode].slice(0, 6));
      handleSubmit([...activationCode].slice(0, 6).join(''));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const handleSubmit = async (inputCode?: string) => {
    const finalCode = inputCode || code.join('');
    if (finalCode.length === 6) {
      setLoading(true);
      try {
        await client.put('/auth/activation-code/', {
          code: finalCode,
        });
        setLoading(false);
        setPage('success');
        setTimeout(() => navigate('/'), 2000); // Navigate after 2 seconds
      } catch {
        setLoading(false);
        setPage('error');
      }
    }
  };

  const retry = () => {
    setPage('activation');
    setCode(Array(6).fill('')); // Reset code
  };
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
        width: '100vw',
        px: '40px',
      }}
    >
      <LogoWrapper mb={6} top={72} />
      {page === 'activation' && (
        <>
          <Typography
            sx={{
              mb: 4,
              color: theme.palette.gray[900],
              typography: 'bodyXXLMedium',
              textAlign: 'center',
              maxWidth: '310px',
            }}
          >
            To log in, use the activation code you see on your other device
          </Typography>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              width: '100%',
              maxWidth: '300px',
            }}
          >
            {code.map((char, index) => (
              <TextField
                key={index}
                value={char}
                onChange={(e) => handleInputChange(e, index)}
                inputProps={{ maxLength: 1 }}
                inputRef={inputRefs.current[index]}
                sx={{
                  width: '48px',
                  '& input': {
                    textAlign: 'center',
                    fontSize: '1.25rem',
                  },
                }}
                autoFocus={index === 0}
              />
            ))}
          </Box>
          <Button
            variant="contained"
            size="large"
            onClick={() => handleSubmit()}
            disabled={loading || code.join('').length !== 6}
            sx={{ mt: 2, width: '100%', maxWidth: '300px' }}
          >
            Submit
          </Button>
        </>
      )}
      {page === 'error' && <ErrorPage retry={retry} />}
      {page === 'success' && <SuccessPage />}
    </Box>
  );
};
