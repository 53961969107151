import { Box, useTheme } from '@mui/material';
import { useEffect, useState } from 'react';
import { Link, matchPath, useLocation } from 'react-router-dom';
import { Logo, useAuthUserCapabilities } from '@stationwise/component-module';
import { INavMenu } from '../../../app/AppPageLayout';
import { ROUTES } from '../../../core/Routes';
import { MenuIcon } from './MenuIcon';
import { ProfileMenu } from './ProfileMenu';

interface SideBarProps {
  navItems: INavMenu[];
}

export const SideBar = ({ navItems }: SideBarProps) => {
  const theme = useTheme();
  const location = useLocation();
  const [selectedMenuIndex, setSelectedMenuIndex] = useState(-1);
  const capabilities = useAuthUserCapabilities();
  const canViewAdminDashboard = capabilities.VIEW_ADMIN_DASHBOARD;

  useEffect(() => {
    let isAnyMatching = false;
    navItems.forEach((navItem, index) => {
      const match = navItem.match.some((path) => {
        return matchPath(path, location.pathname);
      });
      if (match) {
        isAnyMatching = true;
        setSelectedMenuIndex(index);
      }
    });
    if (!isAnyMatching) {
      setSelectedMenuIndex(-1);
    }
  }, [location.pathname, navItems]);

  const changeLogIsActive = matchPath(ROUTES.CHANGELOG.fullRoute, location.pathname);
  const inboxIsActive = matchPath(ROUTES.INBOX.fullRoute, location.pathname);

  return (
    <Box
      sx={{
        display: { xs: 'none', lg: 'flex' },
        flexDirection: 'column',
        width: '72px',
        height: '100vh',
        justifyContent: 'space-between',
        alignItems: 'center',
        background: theme.palette.common.white,
        p: '12px 0',
        boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.05)',
        position: 'sticky',
        top: 0,
        left: 0,
        zIndex: theme.zIndex.drawer,
        overflowY: 'auto',
        scrollbarWidth: 'none',
        '&::-webkit-scrollbar': { width: 0 },
      }}
    >
      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: theme.spacing(0.5) }}>
        <Link to={canViewAdminDashboard ? ROUTES.DASHBOARD.fullRoute : ROUTES.MY_DASHBOARD.fullRoute}>
          <Logo sx={{ mb: theme.spacing(1) }} />
        </Link>
        {navItems.map((navItem, index) => (
          <MenuIcon
            key={`${navItem.type}-${navItem.path}`}
            type={navItem.type}
            component={Link}
            to={navItem.path}
            isActive={index === selectedMenuIndex}
            inSideBar={true}
          />
        ))}
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: theme.spacing(0.5) }}>
        <MenuIcon type="inbox" isActive={!!inboxIsActive} component={Link} to={ROUTES.INBOX.fullRoute} inSideBar={true} />

        {capabilities.VIEW_CHANGE_LOGS && (
          <MenuIcon
            type="change-log"
            isActive={!!changeLogIsActive}
            component={Link}
            to={ROUTES.CHANGELOG.fullRoute}
            inSideBar={true}
          />
        )}
        <ProfileMenu />
      </Box>
    </Box>
  );
};
