import { Typography } from '@mui/material';
import { theme, useDocumentTitle } from '@stationwise/component-module';
import { AppPageLayout } from '../../../AppPageLayout';
import { EmployeesTab } from '../../EmployeesTab';
import { GoodStandingSummaryContent } from './GoodStandingSummaryContent';

export const GoodStandingSummaryMain = () => {
  useDocumentTitle('Good Standing Summary');

  return (
    <AppPageLayout>
      <EmployeesTab defaultTab="goodStandingSummary">
        <Typography
          sx={{
            color: theme.palette.gray[900],
            typography: 'heading4',
            px: theme.spacing(5),
            pt: theme.spacing(4),
            mb: theme.spacing(2),
          }}
        >
          Good Standing Summary
        </Typography>
        <GoodStandingSummaryContent />
      </EmployeesTab>
    </AppPageLayout>
  );
};
