import { MenuItem, Select, SxProps, Theme, useTheme } from '@mui/material';
import { selectStyle, ChevronDownIcon16, useLoadedDepartmentInfoContext } from '@stationwise/component-module';
import { useShiftPlanContext } from '../ShiftPlanContext';

interface BattalionSelectProps {
  sx?: SxProps<Theme>;
}

export const BattalionSelect = ({ sx }: BattalionSelectProps) => {
  const theme = useTheme();
  const { state: departmentInfoState } = useLoadedDepartmentInfoContext();
  const { battalion: selectedBattalion, forceRefetch } = useShiftPlanContext();

  if (departmentInfoState.departmentInfo.battalions.length <= 1) {
    return null;
  }

  return (
    <Select
      value={`${selectedBattalion.id}`}
      onChange={(event) => forceRefetch(event.target.value)}
      variant="standard"
      IconComponent={ChevronDownIcon16}
      disableUnderline
      sx={[selectStyle(theme, '', undefined), ...(Array.isArray(sx) ? sx : sx ? [sx] : [])]}
    >
      {departmentInfoState.departmentInfo.battalions.map((battalion) => (
        <MenuItem key={battalion.id} value={`${battalion.id}`}>
          {battalion.name}
        </MenuItem>
      ))}
    </Select>
  );
};
