import { useTheme } from '@mui/material';
import { ReactNode } from 'react';
import { CardTooltip } from './CardTooltip';

interface DetailsPopoverProps {
  anchorContent: ReactNode;
  popoverContent: ReactNode;
}
export const DetailsPopover = ({ anchorContent, popoverContent }: DetailsPopoverProps) => {
  const theme = useTheme();

  return (
    <CardTooltip
      anchorContent={anchorContent}
      anchorSx={[{ gap: 0.5, position: 'relative', overflow: 'hidden' }]}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      transformOrigin={{ vertical: 'top', horizontal: 'left' }}
      popoverSx={{
        color: theme.palette.gray[900],
        flexDirection: 'column',
        alignItems: 'flex-start',
        gap: 1.5,
        p: 2,
      }}
      popoverContent={popoverContent}
    />
  );
};
