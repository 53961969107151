import { Box } from '@mui/material';
import React from 'react';

export interface AvatarEmailProps {
  name: string;
  email: string;
  userImg?: string;
  color?: string;
}

export const AvatarEmail = ({ name, email, userImg, color }: AvatarEmailProps) => {
  return (
    <Box sx={{ display: 'flex', gap: '8px' }}>
      {userImg ? (
        <Box
          component="img"
          sx={{
            width: '36px',
            height: '36px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            borderRadius: '32px',
          }}
          alt={`Picture of ${name}`}
          src={userImg}
        />
      ) : (
        <Box
          sx={(theme) => ({
            background: color || theme.palette.gray[500],
            width: '36px',
            height: '36px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            borderRadius: '32px',
            color: theme.palette.common.white,
            typography: 'bodySMedium',
          })}
        >
          {name.charAt(0).toUpperCase()}
        </Box>
      )}
      <Box>
        <Box sx={{ typography: 'bodySMedium' }}>{name}</Box>
        <Box sx={{ typography: 'bodySRegular' }}>{email}</Box>
      </Box>
    </Box>
  );
};
