import { Box, Typography, useTheme } from '@mui/material';
import { MessageCircle01Icon24 } from '@stationwise/component-module';

interface MessageNotificationProps {
  onClick?: () => void;
  count?: number;
}

export function MessageNotification({ onClick, count = 0 }: MessageNotificationProps) {
  const theme = useTheme();
  const iconData = {
    Component: <MessageCircle01Icon24 />,
    fill: theme.palette.common.white,
    stroke: theme.palette.common.white,
    height: '24px',
    width: '24px',
  };

  return (
    <Box sx={{ position: 'relative', cursor: 'pointer' }} onClick={onClick}>
      <Box
        data-cy="message-button"
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="36px"
        width="36px"
        borderRadius="360px"
        sx={(theme) => ({
          background: theme.palette.gray[900],
        })}
      >
        <Box
          sx={{
            height: iconData.height,
            width: iconData.width,
            '& path': {
              fill: iconData.fill,
              stroke: iconData.stroke,
            },
          }}
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          {iconData.Component}
        </Box>
      </Box>
      {count > 0 && (
        <Box position="absolute" top="-7px" left="20.5px">
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            height={{ xs: '20px' }}
            width={{ xs: '20px' }}
            borderRadius="200px"
            border={'2px solid' + theme.palette.common.white}
            sx={(theme) => ({
              background: theme.palette.electric[400],
            })}
            color={theme.palette.common.black}
          >
            <Typography variant="bodyXSSemibold">{count}</Typography>
          </Box>
        </Box>
      )}
    </Box>
  );
}
