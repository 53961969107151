import { Box, Typography, useTheme, SxProps, Theme } from '@mui/material';
import { format } from 'date-fns';
import { useState, Fragment } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { HirinEngineOptions, HiringEngineVacancyTable, XCloseIcon24, AutoHireBox, Button } from '@stationwise/component-module';
import { AutoHireView, HiringEngineVacancy, HiringEngineServices } from '@stationwise/share-types';
import { ROUTES } from '../../../core/Routes';
import { DayAutoHire } from './AutoHire/DayAutoHire';
import { HiringOptionsPopover } from './components/HiringOptionsPopover';
import { VacancyPositionController } from './components/VacancyPositionController';

interface HiringEngineState {
  groupedVacancies: Record<number, HiringEngineVacancy[]>;
  selectedDate: Date;
}

// #TODO: Remove mock data when hiring engine v2 is implemented: https://app.asana.com/0/1208964381923959/1209416869286205
export const HiringEngineMain = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const location = useLocation();
  const { groupedVacancies, selectedDate } = location.state as HiringEngineState;
  const [autoHireInstances, setAutoHireInstances] = useState<Pick<AutoHireView, 'id' | 'status'>[]>([]);
  const [filteredVacancies, setFilteredVacancies] = useState<Record<number, HiringEngineVacancy[]>>(groupedVacancies);
  const [selectedAutoHireInstanceId, setSelectedAutoHireInstanceId] = useState<number | null>(null);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const closeHiringEnginePage = () => {
    const date = selectedDate ? format(selectedDate, 'yyyy-MM-dd') : '';
    navigate(`${ROUTES.SCHEDULE.fullRoute}?date=${date}`);
  };

  const Divider = ({ isArrow, sx }: { isArrow?: boolean; sx?: SxProps<Theme> }) => (
    <Box
      sx={{
        backgroundColor: theme.palette.gray[400],
        width: isArrow ? '8px' : '1px',
        height: isArrow ? '8px' : '8px',
        alignSelf: 'stretch',
        margin: '0 auto',
        ...(isArrow && {
          border: 'solid currentColor',
          borderWidth: '0 2px 2px 0',
          transform: 'rotate(45deg)',
          backgroundColor: 'transparent',
          color: theme.palette.gray[400],
          margin: '-8px auto 0',
        }),
        ...sx,
      }}
    />
  );

  const handleHiringEngineAction = (type: HiringEngineServices) => {
    switch (type) {
      case HiringEngineServices.AUTO_HIRE:
        const newId = Date.now();
        setAutoHireInstances((prev) => [
          ...prev,
          {
            id: newId,
            status: 'Done',
          },
        ]);
        setSelectedAutoHireInstanceId(newId);
        setAnchorEl(null);
        break;
      case HiringEngineServices.INSTANT_HIRE:
        break;
      case HiringEngineServices.REQUEST_VOLUNTEERS:
        break;
      default:
        break;
    }
  };

  const handleNewHiringEngineClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  return (
    <Box sx={{ display: 'flex', height: '100vh' }}>
      <Box
        sx={{
          flex: 1,
          flexDirection: 'column',
          borderRight: `1px solid ${theme.palette.divider}`,
          display: 'flex',
        }}
      >
        <Box
          sx={{
            paddingX: 3,
            paddingY: 1.5,
            borderBottom: `1px solid ${theme.palette.divider}`,
          }}
        >
          <Typography variant="h5">Hiring Engine</Typography>
          <Typography variant="body1" color="text.secondary">
            {selectedDate ? format(selectedDate, 'MMM dd, yyyy') : ''}
          </Typography>
        </Box>

        <Box
          sx={{
            flex: 1,
            overflowY: 'auto',
            height: 'calc(100vh - 80px)',
            backgroundImage: `radial-gradient(circle at 1px 1px, ${theme.palette.text.disabled} 1px, transparent 0)`,
            backgroundSize: '20px 20px',
            bgcolor: theme.palette.gray[100],
            paddingX: '8px',
          }}
        >
          <Box
            sx={{
              p: 1,
            }}
          >
            {autoHireInstances.length === 0 && <HirinEngineOptions handleHiringEngineAction={handleHiringEngineAction} />}
          </Box>

          {autoHireInstances.length > 0 && (
            <Box sx={{ display: 'flex', gap: 2 }}>
              <Box sx={{ flex: 1 }}>
                {autoHireInstances.map((instance, index) => (
                  <Fragment key={instance.id}>
                    <AutoHireBox
                      key={instance.id}
                      instance={instance}
                      isSelected={instance.id === selectedAutoHireInstanceId}
                      onClick={() => {
                        console.log('Selected instance ID:', instance.id);
                        setSelectedAutoHireInstanceId(instance.id);
                      }}
                    />
                    {index < autoHireInstances.length - 1 && <Divider />}
                  </Fragment>
                ))}
                <>
                  <Divider />
                  <Button
                    variant="outlined"
                    fullWidth
                    onClick={handleNewHiringEngineClick}
                    sx={{
                      backgroundColor: 'transparent',
                    }}
                  >
                    Add new
                  </Button>
                  <Divider sx={{ height: '16px' }} />
                  <Divider isArrow />
                </>
              </Box>
            </Box>
          )}
        </Box>
      </Box>

      {!selectedAutoHireInstanceId && (
        <Box
          sx={{
            flex: 2,
            bgcolor: 'background.paper',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Box
            sx={{
              paddingX: 3,
              borderBottom: `1px solid ${theme.palette.divider}`,
              display: 'flex',
              justifyContent: 'space-between',
              gap: 2,
            }}
          >
            <Typography sx={{ paddingY: 3 }} variant="h5">
              Vacant Positions
            </Typography>
            <Box
              sx={{
                display: 'flex',
                gap: 2,
                paddingY: 1.5,
                alignItems: 'center',
              }}
            >
              <Box
                sx={{
                  height: '48px',
                  width: '48px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  cursor: 'pointer',
                  borderRadius: '50%',
                  '&:hover': {
                    bgcolor: theme.palette.gray[100],
                  },
                }}
                onClick={closeHiringEnginePage}
              >
                <XCloseIcon24 />
              </Box>
            </Box>
          </Box>

          <Box
            sx={{
              paddingX: 3,
              flex: 1,
              overflowY: 'auto',
            }}
          >
            <VacancyPositionController groupedVacancies={groupedVacancies} onVacanciesFiltered={setFilteredVacancies} />
            {Object.entries(filteredVacancies).map(([key, vacancies]) => (
              <HiringEngineVacancyTable key={key} groupedVacancies={vacancies} hideCheckbox={true} />
            ))}
          </Box>
        </Box>
      )}

      {selectedAutoHireInstanceId && (
        <DayAutoHire
          selectedDate={selectedDate}
          groupedVacancies={groupedVacancies}
          onClose={() => setSelectedAutoHireInstanceId(null)}
        />
      )}
      <HiringOptionsPopover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        handleHiringEngineAction={handleHiringEngineAction}
      />
    </Box>
  );
};
