import { Box, DialogTitle, useTheme } from '@mui/material';
import { useState } from 'react';
import { HornIcon24, Select, SnackbarService } from '@stationwise/component-module';
import { nowrap } from '@stationwise/share-utils';
import { useShiftPlanContext } from '../ShiftPlanContext';
import { IconCircle, ShiftPlanDialogActions } from '../ShiftPlanDialog';

export const MoveApparatusForm = () => {
  const theme = useTheme();
  const { stations, selectedStruct, setSelectedAction, saveAsDraft } = useShiftPlanContext();
  const [{ station: selectedFromStation, apparatus: selectedApparatus }] = useState(selectedStruct);

  const [toStations] = useState(() => {
    return Array.from(stations.values())
      .filter((s) => s.stationId !== selectedFromStation?.stationId && s.stationId !== 'floater-station')
      .map((s) => ({ label: s.stationName, value: s.stationId }));
  });

  const [selectedToStation, setSelectedToStation] = useState(stations.get(toStations[0]?.value || ''));

  const isSaveDisabled = !selectedToStation;

  const onSave = () => {
    if (selectedApparatus && selectedFromStation && selectedToStation) {
      const payload = {
        action: 'UPSERT_APPARATUS',
        apparatusId: selectedApparatus.id,
        stationId: selectedToStation.stationId,
      };
      saveAsDraft(payload, () => {
        setSelectedAction(null);
        SnackbarService.notify({
          content: `Moved ${nowrap(selectedApparatus.name)} from ${nowrap(selectedFromStation.stationName)} to ${nowrap(selectedToStation.stationName)}`,
          severity: 'success',
          duration: 5000,
        });
      });
    }
  };

  return (
    <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column', width: '398px', maxWidth: '100%', minHeight: '1px' }}>
      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', px: 3, pt: 3, pb: 2 }}>
        <IconCircle sx={{ color: theme.palette.indigo[600], mb: 3 }}>
          <HornIcon24 />
        </IconCircle>
        <DialogTitle sx={{ p: 0, textAlign: 'center' }}>
          <Box component="span" sx={{ display: 'block', typography: 'bodyXLSemibold' }}>
            {`Move ${nowrap(selectedApparatus?.name || '')}`}
          </Box>
          <Box component="span" sx={{ display: 'block', mt: 0.5, typography: 'bodyMRegular' }}>
            {`to ${nowrap(selectedToStation?.stationName || '')}`}
          </Box>
        </DialogTitle>
      </Box>
      <Box sx={{ flex: 1, minHeight: '1px', overflowY: 'auto', px: 3, py: 2 }}>
        <Select
          width="100%"
          items={toStations}
          onChange={(newValue) => setSelectedToStation(stations.get(newValue))}
          selected={selectedToStation?.stationId || ''}
        />
      </Box>
      <ShiftPlanDialogActions isSaveDisabled={isSaveDisabled} onSave={onSave} />
    </Box>
  );
};
