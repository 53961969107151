import { Box } from '@mui/material';
import { Users01Icon16 } from '../../assets';
import { getPalette } from '../../utils/colors';

interface UsersIconProps {
  showBorder?: boolean;
  color?: string;
}

export const UsersIcon = ({ showBorder, color }: UsersIconProps) => {
  return (
    <Box
      sx={(theme) => ({
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '24px',
        width: '24px',
        borderRadius: '50%',
        border: `1px solid ${showBorder ? theme.palette.gray[200] : 'transparent'}`,
        backgroundColor: color ? getPalette({ theme, color })[500] : undefined,
        '& svg': {
          stroke: theme.palette.common.white,
          height: 12,
          width: 12,
        },
        '& svg path': {
          stroke: theme.palette.common.white,
        },
      })}
    >
      <Users01Icon16 />
    </Box>
  );
};
