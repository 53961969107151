import { Box, useTheme, TextField } from '@mui/material';
import { isValid, parseISO } from 'date-fns';
import { DatePicker, getTeamColors } from '@stationwise/component-module';
import { ShiftPlanTeam } from '@stationwise/share-types';
import { PatternDayCheck } from './PatternDayCheck';

interface PatternDefinitionProps {
  setPatternLength: (length: number) => void;
  patternLength: number;
  setShiftPlanTeam: (shiftPlanTeam: ShiftPlanTeam) => void;
  shiftPlanTeam: ShiftPlanTeam;
  selectedShiftPlanTeam: ShiftPlanTeam | null;
  minPatternStartDate: Date;
  patternDays: { day: number; checked: boolean }[];
  setPatternDays: (patternDays: { day: number; checked: boolean }[]) => void;
}

export const PatternDefinition = ({
  setPatternLength,
  patternLength,
  setShiftPlanTeam,
  shiftPlanTeam,
  selectedShiftPlanTeam,
  minPatternStartDate,
  patternDays,
  setPatternDays,
}: PatternDefinitionProps) => {
  const theme = useTheme();

  const onToggleDay = (day: number, checked: boolean) => {
    const newPatternDays = patternDays.map((item: { day: number; checked: boolean }) =>
      item.day === day ? { ...item, checked } : item,
    );
    setPatternDays(newPatternDays);
  };

  return (
    <>
      <Box
        sx={{
          typography: 'bodyMRegular',
          textAlign: 'left',
          color: theme.palette.gray[700],
          mt: 1,
        }}
      >
        Pattern definition
      </Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          mt: 4,
          gap: 3,
        }}
      >
        <TextField
          label="Pattern length (days)"
          helperText="Valid range: 1 to 28"
          onChange={(event) => {
            const value = event.currentTarget.value;
            // Remove leading zeros if they exist
            const formattedValue = Number(value.replace(/^0+/, ''));
            if (formattedValue >= 1 && formattedValue <= 28) {
              setPatternLength(formattedValue);
            }
          }}
          slotProps={{ htmlInput: { min: 1, max: 28 } }}
          type="number"
          disabled={!!selectedShiftPlanTeam}
          value={patternLength}
        />
        <DatePicker
          value={parseISO(shiftPlanTeam.patternStartDate)}
          onChange={(newValue) => {
            setShiftPlanTeam({
              ...shiftPlanTeam,
              patternStartDate: newValue && isValid(newValue) ? newValue.toISOString() : shiftPlanTeam.patternStartDate,
            });
          }}
          minDate={minPatternStartDate}
          disabled={!!selectedShiftPlanTeam}
          slotProps={{ textField: { placeholder: 'Choose start' } }}
          label="Pattern start"
        />
      </Box>
      <Box sx={{ display: 'flex', mt: 1.5, width: '100%' }}>
        {patternDays.map((item, index) => (
          <PatternDayCheck
            dayNumber={item.day}
            onToggleDay={onToggleDay}
            checked={item.checked}
            totalDays={patternLength}
            color={getTeamColors({ theme, color: String(shiftPlanTeam.color) }).background}
            key={index}
            disabled={!!selectedShiftPlanTeam}
          />
        ))}
      </Box>
    </>
  );
};
