import { Box, GlobalStyles, useTheme } from '@mui/material';
import { ShiftPlanAction } from '@stationwise/share-types';
import { CreateButton } from '../../../../../components/Common/CreateButton';
import { useShiftPlanContext } from '../ShiftPlanContext';
import { PersonnelPanel } from './PersonnelPanel';
import { RosterEditorHeader } from './RosterEditorHeader';
import { ShiftPlanStation } from './ShiftPlanStation';
import { StructDialogs } from './StructDialogs';

export const RosterEditor = () => {
  const theme = useTheme();
  const {
    stations,
    assignmentGroups,
    setSelectedStruct,
    selectedPersonnelStruct,
    setSelectedPersonnelStruct,
    setSelectedAction,
  } = useShiftPlanContext();
  const isPersonnelOpen = !!(selectedPersonnelStruct.apparatus && !selectedPersonnelStruct.apparatus.isReserved);
  const stationsList = Array.from(stations.values());
  const regularStationsList = stationsList.filter((s) => s.stationId !== 'floater-station');

  const onAddStation = () => {
    setSelectedStruct({});
    setSelectedAction(ShiftPlanAction.UPSERT_STATION);
  };

  return (
    <Box sx={{ display: 'flex', height: '100%' }}>
      <Box
        className="SWShiftPlanRosterEditor-scroll-container"
        sx={{
          width: isPersonnelOpen ? '315px' : '100%',
          height: '100%',
          position: 'relative',
          overflowY: 'auto',
          scrollbarGutter: 'stable',
        }}
      >
        <RosterEditorHeader />
        <Box
          sx={{
            display: 'grid',
            gridTemplateColumns: 'repeat(auto-fit, 100%)',
            justifyContent: 'center',
            gap: theme.spacing(4.5, 0),
            p: theme.spacing(0, 1.5, 1.5, 1.5),
            [theme.breakpoints.up('sm')]: {
              gridTemplateColumns: 'repeat(auto-fit, minmax(275px, max-content))',
              gap: theme.spacing(4.5, 1.5),
            },
            '& .SWApparatus': { scrollMarginTop: theme.spacing(10) },
          }}
        >
          {stationsList.map((station) => (
            <ShiftPlanStation
              key={station.stationId}
              station={station}
              assignmentGroups={assignmentGroups}
              isMoveApparatusPossible={regularStationsList.length > 1}
              setSelectedStruct={setSelectedStruct}
              setSelectedPersonnelStruct={setSelectedPersonnelStruct}
              setSelectedAction={setSelectedAction}
            />
          ))}
        </Box>
      </Box>
      {isPersonnelOpen && <PersonnelPanel />}
      {!isPersonnelOpen && <CreateButton onClick={onAddStation}>Add station</CreateButton>}
      {isPersonnelOpen && <GlobalStyles styles={{ '.intercom-lightweight-app': { display: 'none' } }} />}
      <StructDialogs />
    </Box>
  );
};
