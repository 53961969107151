import { Box } from '@mui/material';

export const StaffingPatternsHeader = () => {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center', pt: 1.5, px: 2 }}>
      <Box sx={{ typography: 'bodyXXLRegular' }}>Staffing patterns</Box>
      <Box sx={{ flex: 1 }} />
    </Box>
  );
};
