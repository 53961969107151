import { captureException } from '@sentry/react';
import { useEffect, useState } from 'react';
import { client } from '@stationwise/share-api';
import { ListFieldsStaffingList } from '@stationwise/share-types';

interface UseFetchStaffingListsForHiringEngineProps {
  skip?: boolean;
}

export const useFetchStaffingListsForHiringEngine = ({ skip }: UseFetchStaffingListsForHiringEngineProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const [staffingLists, setStaffingLists] = useState<ListFieldsStaffingList[]>([]);

  useEffect(() => {
    if (skip) {
      return;
    }

    setIsLoading(true);
    setStaffingLists([]);

    client
      .get('/staffing-list/staffing-lists/')
      .then((response) => {
        setStaffingLists(response.data);
      })
      .catch((error) => {
        captureException(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [skip]);

  return { staffingLists, isLoading };
};
