import { EventInput } from '@fullcalendar/core/index.js';
import { EventImpl } from '@fullcalendar/core/internal';
import { Popover, Box } from '@mui/material';
import { useIOSPaddingBottom } from '../../../../../context/DeviceInfoContext';
import { ShiftOverviewOptions } from './ShiftOverviewOptions';

interface ShiftOverviewProps {
  shift: EventInput | EventImpl;
  handleSelect: (option: string) => void;
  todayEvents: EventInput[];
  anchorEl: null | HTMLElement;
  handleShiftCardOverviewClose: () => void;
}

export const ShiftOverview = ({
  shift,
  handleSelect,
  todayEvents,
  anchorEl,
  handleShiftCardOverviewClose,
}: ShiftOverviewProps) => {
  const iosWebViewPaddingBottom = useIOSPaddingBottom();

  return (
    <Popover
      open={Boolean(anchorEl)}
      anchorEl={anchorEl}
      onClose={handleShiftCardOverviewClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      sx={(theme) => ({
        '& .MuiPaper-root': {
          background: theme.palette.common.white,
          borderRadius: '8px',
          boxShadow: '0px 4px 16px rgba(0, 0, 0, 0.1)',
          width: '368px',
          paddingBottom: iosWebViewPaddingBottom,
        },
      })}
    >
      <Box
        sx={(theme) => ({
          pr: theme.spacing(2),
          pl: theme.spacing(2),
        })}
      >
        <ShiftOverviewOptions
          handleClose={handleShiftCardOverviewClose}
          shift={shift}
          handleSelect={handleSelect}
          todayEvents={todayEvents}
        />
      </Box>
    </Popover>
  );
};
