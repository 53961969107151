import { Box, useTheme } from '@mui/material';
import { useState } from 'react';
import { EmployeeOffView } from '@stationwise/share-types';
import {
  checkIsTemporaryNonShiftApparatus,
  checkIsTemporaryNonShiftPosition,
  checkIsShift,
  checkIsStrikeTeam,
  checkIsStrikeTeamApparatus,
  getMissingCertifications,
  checkIsAdministration,
} from '@stationwise/shift-summary-helper';
import { ChevronRightIcon16, OverrideCircleGrayIcon } from '../../../../assets';
import { useAuthUserCapabilities } from '../../../../utils/capabilities';
import { OvalChip } from '../../../Card';
import { ControlledPopup } from '../../../ControlledPopup';
import { EmployeePreview } from '../../../EmployeePreview';
import { useRosterContext } from '../RosterContext';
import { ShiftTradePopoverContent } from '../ShiftTrade/ShiftTradePopoverContent';
import { AdministrationActions } from './AdministrationActions';
import { DefaultStaffingSection } from './DefaultStaffingSection';
import { ManageShiftActions } from './ManageShiftActions';
import { AddNoteButton, EditNoteModal, NotePopover } from './Note';
import { NoteRow } from './NoteRow';
import { RosterPayAndDetailCodes } from './RosterPayAndDetailCodes';
import { StrikeTeamActions } from './StrikeTeamActions';
import { TemporaryNonShiftActions } from './TemporaryNonShiftActions';

export const RosterEmployeePreview = () => {
  const theme = useTheme();
  const { isReadonly, employeesOffState, selectedFilledPositionState } = useRosterContext();

  const [isEditingNote, setIsEditingNote] = useState(false);

  const { apparatus, position, employee } = selectedFilledPositionState;

  const capabilities = useAuthUserCapabilities();

  if (!employee) {
    return null;
  }

  let shiftTradeData: EmployeeOffView | undefined = undefined;
  if (employee.trade?.id) {
    shiftTradeData = employeesOffState.employeesOff.find((e) => {
      return e.requestType === 'ShiftTradeRequest' && e.id === employee.trade?.id;
    });
  }

  const isEditNoteEnabled = checkIsShift(employee) || checkIsStrikeTeam(employee);

  let addNoteButton: React.ReactNode = null;
  if (isEditNoteEnabled) {
    addNoteButton = <AddNoteButton disabled={!!employee.noteOverride || isEditingNote} onClick={() => setIsEditingNote(true)} />;
  }

  const defaultStaffingSection = <DefaultStaffingSection apparatus={apparatus} position={position} employee={employee} />;

  const payCodeAndDetailCodes = <RosterPayAndDetailCodes apparatus={apparatus} position={position} employee={employee} />;

  let actions: React.ReactNode = null;
  if (!isReadonly) {
    if (apparatus && position && capabilities.MANAGE_EVENTS && checkIsStrikeTeamApparatus(apparatus)) {
      actions = <StrikeTeamActions apparatus={apparatus} position={position} employee={employee} />;
    } else if (capabilities.EDIT_ROSTER_ASSIGNMENTS) {
      if (checkIsShift(employee)) {
        actions = <ManageShiftActions employee={employee} />;
      } else if (
        apparatus &&
        position &&
        checkIsTemporaryNonShiftApparatus(apparatus) &&
        checkIsTemporaryNonShiftPosition(position)
      ) {
        actions = <TemporaryNonShiftActions apparatus={apparatus} position={position} employee={employee} />;
      } else if (checkIsAdministration(employee)) {
        actions = <AdministrationActions employee={employee} />;
      }
    }
  }

  return (
    <EmployeePreview
      employee={employee}
      position={position}
      addNoteButton={addNoteButton}
      defaultStaffingSection={defaultStaffingSection}
      payCodeAndDetailCodes={payCodeAndDetailCodes}
      actions={actions}
    >
      {position && getMissingCertifications(position.certifications, employee.certifications).length > 0 && (
        <Box sx={{ mb: 2 }}>
          <NoteRow icon={<OverrideCircleGrayIcon />}>Certification error overridden</NoteRow>
        </Box>
      )}
      {shiftTradeData && (
        <>
          {(() => {
            const shiftTradeRow = (
              <Box
                sx={{
                  backgroundColor: theme.palette.gray[800],
                  borderRadius: 2,
                  display: 'flex',
                  justifyContent: 'space-between',
                  gap: 1,
                  p: theme.spacing(1.5, 1),
                  width: '100%',
                  ...(shiftTradeData.title && {
                    '&:hover': { background: theme.palette.gray[700] },
                  }),
                  typography: 'bodySMedium',
                  color: theme.palette.common.white,
                }}
              >
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <OvalChip label="TRADE" backgroundColor={theme.palette.lightBlue[500]} />
                  <Box sx={{ typography: 'bodySMedium', color: theme.palette.common.white, ml: theme.spacing(2) }}>
                    Shift trade
                  </Box>
                </Box>

                <Box sx={{ color: theme.palette.gray[400], display: 'flex' }}>
                  {shiftTradeData.title && <ChevronRightIcon16 />}
                </Box>
              </Box>
            );

            return shiftTradeData.title ? (
              <ControlledPopup
                delay={500}
                anchorComponent={shiftTradeRow}
                popupComponent={<ShiftTradePopoverContent employeeOffData={shiftTradeData} colorMode="dark" />}
              />
            ) : (
              shiftTradeRow
            );
          })()}
        </>
      )}
      {isEditNoteEnabled && employee.noteOverride && (
        <Box sx={{ mb: 2 }}>
          <NotePopover employee={employee} noteOverride={employee.noteOverride} onEdit={() => setIsEditingNote(true)} />
        </Box>
      )}
      {isEditNoteEnabled && <EditNoteModal employee={employee} isOpen={isEditingNote} onClose={() => setIsEditingNote(false)} />}
    </EmployeePreview>
  );
};
