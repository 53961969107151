import { EventInput } from '@fullcalendar/core';
import { EventImpl } from '@fullcalendar/core/internal';
import { Box } from '@mui/material';
import { format } from 'date-fns';
import React, { useEffect } from 'react';
import { Button, SHIFT_OVERVIEW_OPTIONS, theme, useAuthUserCapabilities } from '@stationwise/component-module';
import { GetIncidentOverview } from '@stationwise/share-types';
import { useFetchRequestOverview } from '../../../../../../../app/pages/Calendar/components/RequestSharedComponents/hooks/useFetchRequestOverview';
import { EventCardLine } from './EventCardLine';
import { EventCardTitleSection } from './EventCardTitleSection';
import { executeSelectedAction } from './EventCardUtils';
import { EventCardWrapper } from './EventCardWrapper';

export const IncidentCard = ({
  shift,
  setClickedEventAsCalendarSelectedElement,
  openActionModal,
  handleShiftCardOverviewClose,
  setSelectedIncident,
}: {
  shift: EventInput | EventImpl;
  setClickedEventAsCalendarSelectedElement?: (
    event: React.MouseEvent<HTMLElement>,
    shift: EventInput | EventImpl,
    design?: boolean,
  ) => void;
  openActionModal?: (option: string) => void;
  handleShiftCardOverviewClose?: () => void;
  setSelectedIncident?: (incident: GetIncidentOverview) => void;
}) => {
  const fetchUrl = `/incident/incidents/${shift.id}/`;
  const { isLoading, isError, error, data: overview } = useFetchRequestOverview<GetIncidentOverview>(fetchUrl);
  const capabilities = useAuthUserCapabilities();
  const canManageIncidents = capabilities.MANAGE_INCIDENT_REPORT;

  useEffect(() => {
    if (overview && setSelectedIncident) {
      setSelectedIncident(overview);
    }
  }, [overview, setSelectedIncident]);

  return (
    <EventCardWrapper>
      <EventCardTitleSection
        title={'Incident'}
        backgroundColor={overview && overview.isActive ? theme.palette.green[600] : theme.palette.red[500]}
        titleColor={theme.palette.common.white}
        truncate
      />

      <Box
        sx={(theme) => ({
          display: 'flex',
          px: theme.spacing(2),
          flexDirection: 'column',
          alignItems: 'flex-start',
          alignSelf: 'stretch',
          '&:last-child': {
            borderBottom: 'none',
          },
        })}
      >
        <EventCardLine
          title={'Start Date'}
          value={shift.start ? format(shift.start as Date, 'MMM d, HH:mm') : '-'}
        ></EventCardLine>
        <EventCardLine title={'End Date'} value={shift.end ? format(shift.end as Date, 'MMM d, HH:mm') : '-'}></EventCardLine>

        <EventCardLine title={'Incident Number'} value={overview ? overview.incidentNumber : '-'}></EventCardLine>
        {overview && overview.customData['incidentType'] && (
          <EventCardLine title={'Incident Type'} value={overview ? overview.customData['incidentType'] : '-'}></EventCardLine>
        )}
        {overview && overview.customData['callLocation'] && (
          <EventCardLine title={'Call Location'} value={overview ? overview.customData['callLocation'] : '-'}></EventCardLine>
        )}
      </Box>
      {!isLoading && !isError && !error && (
        <Box
          sx={(theme) => ({
            display: 'flex',
            p: overview && openActionModal ? theme.spacing(2) : '',
            gap: theme.spacing(1),
            alignSelf: 'stretch',
            alignItems: 'center',
          })}
        >
          {overview && openActionModal && canManageIncidents && (
            <Button
              sx={(theme) => ({
                display: 'flex',
                py: theme.spacing(0.75),
                px: theme.spacing(2),
                justifyContent: 'center',
                alignItems: 'center',
                flex: '1 0 0',
                maxHeight: theme.spacing(3),
              })}
              data-cy="view-incident-details"
              color="primary"
              size="medium"
              variant="outlined"
              onClick={(event: React.MouseEvent<HTMLElement>) => {
                executeSelectedAction(
                  SHIFT_OVERVIEW_OPTIONS.EDIT_INCIDENT,
                  event,
                  shift,
                  setClickedEventAsCalendarSelectedElement,
                  openActionModal,
                  handleShiftCardOverviewClose,
                );
              }}
            >
              {(overview.isActive ? 'Edit' : 'View') + ' details'}
            </Button>
          )}
          {overview && overview.isActive && openActionModal && (
            <Button
              data-cy="close-incident-button"
              sx={(theme) => ({
                display: 'flex',
                py: theme.spacing(0.75),
                px: theme.spacing(2),
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
                flex: '1 0 0',
              })}
              color="error"
              size="medium"
              variant="outlined"
              onClick={(event: React.MouseEvent<HTMLElement>) => {
                executeSelectedAction(
                  SHIFT_OVERVIEW_OPTIONS.CLOSE_INCIDENT,
                  event,
                  shift,
                  setClickedEventAsCalendarSelectedElement,
                  openActionModal,
                  handleShiftCardOverviewClose,
                );
              }}
            >
              Close Incident
            </Button>
          )}
        </Box>
      )}
    </EventCardWrapper>
  );
};
