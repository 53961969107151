import { captureException } from '@sentry/react';
import React, { createContext, useContext, ReactNode } from 'react';
import { SnackbarService } from '@stationwise/component-module';
import { client } from '@stationwise/share-api';
import { CustomField } from '@stationwise/share-types';

type EditBasicInfo = {
  firstName: string;
  lastName: string;
};
type EditEmployeeInfo = {
  email: string | null;
  emergencyContactRelationship: string | null;
  phone: string | null;
  emergencyContactPhone: string | null;
  emergencyContactName: string | null;
  optionalEmergencyContactName: string | null;
  optionalEmergencyContactPhone: string | null;
  optionalEmergencyContactRelationship: string | null;
};
type EditGroupInfo = {
  workGroups: string[];
};
type EditCareerInfo = {
  hireDate: string | null;
};
type EditPromotionHistory = {
  promotionHistory: { rank: string; date: string }[];
};
type EditExtraInfo = {
  customFields: CustomField[];
};
type EditManagersInfo = {
  managerOne: string;
  managerTwo: string;
  managerThree: string;
};
type EditQualifications = {
  rank: string | null;
  driversLicenseNumber: string | null;
  driversLicenseClasses: string[];
  certifications: string[];
};

type EditAccountControl = {
  isActive: boolean;
};

type EditNonShiftAssignment = {
  editNonShift: boolean;
  station: string;
  apparatus: string;
  startTime: number;
  endTime: number;
  activeWeekdays: number[];
  breakDuration: number | null;
  payCodes: number[];
};

type DeactivateNonShiftAssignment = {
  deactivateNonShift: boolean;
};

type EditData =
  | EditAccountControl
  | EditBasicInfo
  | EditEmployeeInfo
  | EditGroupInfo
  | EditCareerInfo
  | EditPromotionHistory
  | EditExtraInfo
  | EditManagersInfo
  | EditQualifications
  | EditNonShiftAssignment
  | DeactivateNonShiftAssignment;

const PatchRequestContext = createContext<
  | {
      handlePatchRequest: (data: EditData) => Promise<void>;
    }
  | undefined
>(undefined);

interface PatchRequestProviderProps {
  children: ReactNode;
  url: string;
  setRefresh: React.Dispatch<React.SetStateAction<boolean>>;
  name: string;
}

export const PatchRequestProvider: React.FC<PatchRequestProviderProps> = ({ children, url, setRefresh, name }) => {
  const handlePatchRequest = async (data: EditData): Promise<void> => {
    try {
      await client.patch(url, data);
      setRefresh((prevRefresh) => !prevRefresh);
      SnackbarService.notify({
        content: `You have successfully saved changes to ${name}`,
        severity: 'success',
        duration: 5000,
      });

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      captureException(error);
      const errorMessage = error.response?.data?.message || error.response?.data?.error || 'An unexpected error occurred';
      SnackbarService.notify({
        content: errorMessage,
        severity: 'error',
        duration: 10000,
      });
    }
  };

  return <PatchRequestContext.Provider value={{ handlePatchRequest }}>{children}</PatchRequestContext.Provider>;
};

export const usePatchRequest = () => {
  const context = useContext(PatchRequestContext);
  if (!context) {
    throw new Error('usePatchRequest must be used within a PatchRequestProvider');
  }
  return context;
};
