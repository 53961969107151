import { Box, Typography, useTheme } from '@mui/material';
import { RankBadge } from '@stationwise/component-module';
import { Rank, BasicEmployee } from '@stationwise/share-types';

export const ShiftPrincipals = ({
  shiftLeader,
  captain,
  captainRank,
  loggedInEmployeeId,
}: {
  shiftLeader: BasicEmployee;
  captain: BasicEmployee | string;
  captainRank: Rank;
  loggedInEmployeeId?: string;
}) => {
  const theme = useTheme();

  return (
    <>
      <Box
        sx={{
          borderTop: `solid 1px ${theme.palette.gray[100]}`,
          mb: theme.spacing(1),
        }}
      >
        <Box display="flex" alignItems="center" sx={{ mt: theme.spacing(2), justifyContent: { sm: 'space-between' } }}>
          <Box display="flex" sx={{ width: '40%' }}>
            <RankBadge
              rank={shiftLeader.name ? shiftLeader.rank : { name: 'Shift Leader', code: 'SL', color: 'gray' }}
              rankField="name"
            />
          </Box>
          <Box display="flex">
            <Typography variant="bodyMMedium">{shiftLeader.name ? shiftLeader.name : 'No leader assigned'}</Typography>
            {loggedInEmployeeId === shiftLeader.id && (
              <Box
                sx={{
                  ml: theme.spacing(1),
                  color: theme.palette.gray[400],
                }}
              >
                <Typography variant="bodyMMedium">(You)</Typography>
              </Box>
            )}
          </Box>
        </Box>
      </Box>
      {captain && typeof captain !== 'string' && captain.id !== loggedInEmployeeId && (
        <Box
          sx={{
            justifyContent: { sm: 'space-between' },
            mb: theme.spacing(1),
          }}
          display="flex"
          alignItems="center"
        >
          <Box display="flex" sx={{ width: '40%' }}>
            <RankBadge rank={{ name: 'Fire Captain', code: 'FC', color: captainRank?.color || 'gray' }} rankField="name" />
          </Box>
          <Box display="flex">
            <Typography variant="bodyMMedium">{captain.name ? captain.name : 'To Be Staffed'}</Typography>
            {loggedInEmployeeId === captain.id && (
              <Box
                sx={{
                  ml: theme.spacing(1),
                  color: theme.palette.gray[400],
                }}
              >
                <Typography variant="bodyMMedium">(You)</Typography>
              </Box>
            )}
          </Box>
        </Box>
      )}
    </>
  );
};
