import { Box } from '@mui/material';
import { ChevronRightIcon16 } from '@stationwise/component-module';

interface DetailsItemProps {
  icon: JSX.Element;
  onClick?: () => void;
  title: string | JSX.Element;
}

export const DetailsItem = ({ icon, onClick, title }: DetailsItemProps) => {
  return (
    <Box
      sx={(theme) => ({
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        cursor: onClick ? 'pointer' : 'default',
        width: 'inherit',
        height: '100%',
        color: theme.palette.gray[900],
        typography: 'bodyMMedium',
        borderBottom: `1px solid ${theme.palette.gray[200]}`,
        py: theme.spacing(2),
        '&:last-child': {
          borderBottom: 'none',
        },
        '&:hover': onClick
          ? {
              cursor: 'pointer',
              color: theme.palette.purple[500],
            }
          : {},
      })}
      onClick={onClick ? onClick : undefined}
    >
      <Box
        sx={(theme) => ({
          display: 'flex',
          alignItems: 'center',
          gap: theme.spacing(2),
        })}
      >
        <Box sx={(theme) => ({ color: theme.palette.purple[500], display: 'flex', alignItems: 'center' })}>{icon}</Box>
        {title}
      </Box>

      {onClick && <ChevronRightIcon16 />}
    </Box>
  );
};
