import { Box, Radio, useTheme } from '@mui/material';
import { Dispatch, SetStateAction } from 'react';
import { RankBadge, CardCertBadges, useLoadedDepartmentInfoContext } from '@stationwise/component-module';
import { ShiftPlanPosition, ShiftPlanStruct } from '@stationwise/share-types';
import { filterNonRankCertifications, formatShiftDuration } from '@stationwise/shift-summary-helper';

interface AssignmentGroupRowProps {
  position: ShiftPlanPosition | undefined;
  label: string;
  isSelected: boolean;
  resetCandidates: () => void;
  setSelectedPersonnelStruct: Dispatch<SetStateAction<ShiftPlanStruct>>;
}

export const AssignmentGroupRow = ({ position, ...props }: AssignmentGroupRowProps) => {
  const theme = useTheme();
  const { state: departmentInfoState } = useLoadedDepartmentInfoContext();
  const { departmentInfo } = departmentInfoState;

  const onSelect = () => {
    if (!props.isSelected) {
      props.resetCandidates();
      props.setSelectedPersonnelStruct((prevStruct) => ({ ...prevStruct, position }));
    }
  };

  return (
    <Box
      className="SWShiftPlanPersonnelPanel-assignment-group-row"
      onClick={onSelect}
      sx={{
        cursor: 'pointer',
        display: 'flex',
        alignItems: 'center',
        height: '40px',
        '& > div + div': { borderLeft: '1px solid transparent' },
      }}
    >
      <Box sx={{ width: '39px', height: '100%' }}>
        <Radio
          checked={props.isSelected}
          onChange={onSelect}
          onClick={(event) => event.stopPropagation()}
          value={`${position?.id}`}
          name="assignment-group"
          inputProps={{ 'aria-label': props.label }}
          sx={{ width: '39px', height: '39px', p: 0 }}
        />
      </Box>
      <Box sx={{ width: '40px' }} />
      <Box sx={{ flex: 7, minWidth: '240px', px: 1.5 }}>
        {!position && <Box sx={{ typography: 'bodySRegular' }}>{props.label}</Box>}
        {position && (
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5, width: '100%' }}>
            <RankBadge rank={position.rank} />
            <Box sx={{ flex: 1, minWidth: '1px', display: 'flex' }}>
              <CardCertBadges certs={filterNonRankCertifications(departmentInfo, position.certifications, position.rank)} />
            </Box>
            <Box sx={{ color: theme.palette.text.secondary, typography: 'bodyXSRegular' }}>
              {formatShiftDuration({ startTime: position.startDateTime, endTime: position.endDateTime })}
            </Box>
          </Box>
        )}
      </Box>
      <Box sx={{ flex: 3, minWidth: '1px', px: 1.5 }} />
      <Box sx={{ width: '700px', minWidth: '60%' }} />
    </Box>
  );
};
