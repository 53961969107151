import { EventInput } from '@fullcalendar/core';
import { EventImpl } from '@fullcalendar/core/internal';
import { Box } from '@mui/material';
import { format } from 'date-fns';
import React from 'react';
import { theme } from '@stationwise/component-module';
import { EventCardLine } from './EventCardLine';
import { EventCardTitleSection } from './EventCardTitleSection';
import { processTitle } from './EventCardUtils';
import { EventCardWrapper } from './EventCardWrapper';

export const ExemptionCard = ({ shift }: { shift: EventInput | EventImpl }) => {
  return (
    <EventCardWrapper>
      <EventCardTitleSection
        title={processTitle(shift, false)}
        backgroundColor={theme.palette.cyan[500]}
        titleColor={theme.palette.common.white}
        truncate
      />

      <Box
        sx={(theme) => ({
          display: 'flex',
          px: theme.spacing(2),
          flexDirection: 'column',
          alignItems: 'flex-start',
          alignSelf: 'stretch',
          '&:last-child': {
            borderBottom: 'none',
          },
        })}
      >
        <EventCardLine
          title={'Staffing List Name'}
          value={shift.extendedProps ? shift.extendedProps['staffingListName'] : '-'}
        ></EventCardLine>
        <EventCardLine
          title={'Start Date'}
          value={shift.start ? format(shift.start as Date, 'MMM d, HH:mm') : '-'}
        ></EventCardLine>
        <EventCardLine
          isLastLine={!(shift.extendedProps && shift.extendedProps['eventNote'])}
          title={'End Date'}
          value={shift.end ? format(shift.end as Date, 'MMM d, HH:mm') : '-'}
        ></EventCardLine>
        {shift.extendedProps && shift.extendedProps['eventNote'] && (
          <EventCardLine
            isLastLine={true}
            title={'Note'}
            value={shift.extendedProps ? shift.extendedProps['eventNote'] : '-'}
          ></EventCardLine>
        )}
      </Box>
    </EventCardWrapper>
  );
};
