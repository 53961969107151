import { ReactComponent as CloseSymbol } from '@material-symbols/svg-400/outlined/close.svg';
import { Box, IconButton } from '@mui/material';
import { SvgIcon, theme } from '@stationwise/component-module';

export const DrawerHeader = ({
  headerTitle,
  handleOnClose,
  noBorder,
  headerIcon,
  dividerBorder,
}: {
  headerTitle: string;
  handleOnClose: () => void;
  noBorder?: boolean;
  headerIcon?: React.ReactNode;
  dividerBorder?: boolean;
}) => {
  return (
    <Box
      display="flex"
      justifyContent="space-between"
      sx={(theme) => ({
        width: '100%',
        padding: '20px 16px 16px 16px',
        flexShrink: 0,
        borderBottom: dividerBorder
          ? `1px solid ${theme.palette.divider}`
          : noBorder
            ? 'none'
            : `1px solid ${theme.palette.divider}`,
        position: 'sticky',
        top: theme.spacing(-2),
        background: theme.palette.common.white,
        zIndex: theme.zIndex.appBar,
        mb: theme.spacing(1),
      })}
    >
      <Box
        sx={(theme) => ({
          typography: theme.typography.h6,
          color: theme.palette.text.primary,
        })}
      >
        {headerTitle}
      </Box>
      {headerIcon ? (
        <Box
          data-cy="close-button"
          onClick={handleOnClose}
          sx={(theme) => ({
            width: '28px',
            height: '28px',
            borderRadius: '20px',
            background: theme.palette.gray[100],
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            color: theme.palette.gray[500],
            ':hover': {
              cursor: 'pointer',
            },
          })}
        >
          {headerIcon}
        </Box>
      ) : (
        <IconButton
          data-cy="close-button"
          onClick={handleOnClose}
          sx={() => ({
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            top: theme.spacing(-0.6),

            ':hover': {
              cursor: 'pointer',
            },
          })}
        >
          {<SvgIcon component={CloseSymbol} />}
        </IconButton>
      )}
    </Box>
  );
};
