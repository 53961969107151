import { Box } from '@mui/material';
import { PickersDay, PickersDayProps } from '@mui/x-date-pickers/PickersDay';
import { format, isSameDay } from 'date-fns';
import { getTeamColors } from '@stationwise/component-module';

export const Day = ({
  day,
  outsideCurrentMonth,
  selected,
  shiftColorsByDate,
  selectedDateAsDate,
  onDateChange,
  ...other
}: PickersDayProps<Date> & {
  shiftColorsByDate: Map<string, string>;
  selectedDateAsDate: Date;
  onDateChange: (date: Date) => void;
}) => {
  const teamColor = shiftColorsByDate.get(format(day, 'yyyy-MM-dd'));
  const isSelectedDay = isSameDay(day, selectedDateAsDate) && !isSameDay(day, new Date());

  return (
    <Box
      sx={(theme) => ({
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'relative',
        '.MuiPickersDay-root': {
          borderRadius: '50%',
          border: isSelectedDay ? '1px solid #111827' : 'none',
          color: isSelectedDay ? 'white' : '#111827',
          backgroundColor: isSelectedDay ? theme.palette.grey[900] : 'transparent',
          '&:hover': {
            border: '1px solid #111827',
          },
        },
      })}
      onClick={outsideCurrentMonth ? undefined : () => onDateChange(day)}
    >
      <PickersDay {...other} outsideCurrentMonth={outsideCurrentMonth} day={day} />
      {!outsideCurrentMonth && teamColor && (
        <Box
          sx={(theme) => ({
            width: 4,
            height: 4,
            borderRadius: '50%',
            ...getTeamColors({ theme, color: teamColor, dark: isSelectedDay }),
            position: 'absolute',
            bottom: 4,
          })}
        />
      )}
    </Box>
  );
};
