import InfoOutlined from '@mui/icons-material/InfoOutlined';
import { Box, useTheme } from '@mui/material';
import { ShiftPlanTeam } from '@stationwise/share-types';
import { MonthDays, MonthSelector, TeamPatternDays } from '../../Calendar';
import { BorderedBoxes } from '../../Grid';

interface PatternPreviewProps {
  patternDays: { day: number; checked: boolean }[];
  selectedShiftPlanTeam: ShiftPlanTeam | null;
  shiftPlanTeam: ShiftPlanTeam;
  selectedMonth: Date;
  setSelectedMonth: (date: Date) => void;
}

export const PatternPreview = ({
  patternDays,
  selectedShiftPlanTeam,
  shiftPlanTeam,
  selectedMonth,
  setSelectedMonth,
}: PatternPreviewProps) => {
  const theme = useTheme();

  return (
    <>
      <Box
        sx={{
          typography: 'bodyMRegular',
          textAlign: 'left',
          color: theme.palette.gray[700],
          mt: 3,
        }}
      >
        Pattern preview
      </Box>
      {!selectedShiftPlanTeam && !patternDays.some((pd) => pd.checked) ? (
        <Box
          sx={{
            typography: 'bodySRegular',
            backgroundColor: '#E5F6FD',
            borderRadius: '8px',
            color: '#014361',
            mt: 1,
            height: '48px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            p: 1.5,
          }}
        >
          <InfoOutlined sx={{ color: theme.palette.lightBlue[700], mr: 1, fontSize: '20px' }} />
          Choose number of days, days on and pattern start to see preview in calendar.
        </Box>
      ) : (
        <Box>
          <Box
            sx={{
              borderTop: `1px solid ${theme.palette.divider}`,
              borderLeft: `1px solid ${theme.palette.divider}`,
              borderTopLeftRadius: `${theme.shape.borderRadius}px`,
              flex: 1,
              overflowX: 'hidden',
              overflowY: 'auto',
              mt: 2,
            }}
          >
            <Box
              sx={{
                background: theme.palette.common.white,
                position: 'sticky',
                top: 0,
                left: 0,
                width: '100%',
                minWidth: '700px',
                zIndex: 1,
              }}
            >
              <BorderedBoxes sx={{ borderTop: 0, borderLeft: 0 }}>
                <Box sx={{ flex: 1 }}></Box>
                <Box sx={{ justifyContent: 'center', px: 1.5, width: '100%', minWidth: '60%' }}>
                  <MonthSelector value={selectedMonth} onChange={(newValue: Date) => setSelectedMonth(newValue)} />
                </Box>
              </BorderedBoxes>
              <BorderedBoxes sx={{ borderLeft: 0 }}>
                <BorderedBoxes sx={{ border: 0, width: '100%', minWidth: '60%' }}>
                  <MonthDays month={selectedMonth} />
                </BorderedBoxes>
              </BorderedBoxes>
            </Box>
            <Box
              sx={{
                '& > div': { borderLeft: 0 },
                '& > div:last-of-type': { borderBottom: `1px solid ${theme.palette.divider}` },
              }}
            >
              <BorderedBoxes sx={{ height: '40px' }}>
                <BorderedBoxes sx={{ border: 0, width: '100%', minWidth: '60%' }}>
                  <TeamPatternDays team={shiftPlanTeam} month={selectedMonth} />
                </BorderedBoxes>
              </BorderedBoxes>
            </Box>
          </Box>
        </Box>
      )}
    </>
  );
};
