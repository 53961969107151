import { Box } from '@mui/material';
import { format } from 'date-fns';
import { useState } from 'react';
import { useRosterContext } from '@stationwise/component-module';
import { EmployeeOffView, RosterStation, RosterEmployee, RosterPosition } from '@stationwise/share-types';
import { checkHasOvertimePayCode, checkIsEvent, getPositionSplits } from '@stationwise/shift-summary-helper';
import { findOvertimeEmployees } from './PositionRows';
import { PrintDayScheduleBox } from './PrintDayScheduleBox';

export const MAX_BOX_HEIGHT = 1100;

export const hasEmployeeNotes = (employee: RosterEmployee, position: RosterPosition, totalSplits: number): boolean => {
  const hasTradeInfo = !!employee.trade;
  const isTemporaryPosition = position.isTemporary;
  const hasOvertimePayCode = checkHasOvertimePayCode(employee);
  const hasSplitTime = totalSplits > 1;
  const hasNotes = !!employee.noteOverride?.note;

  return hasTradeInfo || isTemporaryPosition || hasOvertimePayCode || hasSplitTime || hasNotes;
};

const calculateTotalRows = (station: RosterStation) => {
  let rowCount = 0;
  station.apparatuses.forEach((apparatus) => {
    rowCount += 1.4; // Add 1 for apparatus row and 0.4 for the 8px padding
    apparatus.positions.forEach((position) => {
      const splits = getPositionSplits(position);
      splits.forEach((split) => {
        rowCount += 1; // Position row
        if (split.employee && hasEmployeeNotes(split.employee, position, splits.length)) {
          rowCount += 1; // Note row
        }
      });
    });
  });
  return rowCount;
};

const BoxesContainer = ({ children }: { children: React.ReactNode[] }) => {
  // Arrange boxes into rows of three
  const rows = children.reduce((pairs: React.ReactNode[][], child, i) => {
    if (i % 3 === 0) {
      pairs.push([child]);
    } else {
      pairs[pairs.length - 1].push(child);
    }
    return pairs;
  }, []);

  return (
    <>
      {rows.map((row, index) => (
        <Box
          key={index}
          sx={{
            display: 'grid',
            gridTemplateColumns: 'repeat(3, 1fr)',
            gap: '16px',
            mb: 2,
            pageBreakInside: 'avoid',
            '@media print': {
              width: '210mm',
              minHeight: 'fit-content',
              margin: '0',
              padding: '0',
              '@page': {
                margin: '4mm',
              },
              '& > *': {
                minWidth: '270px',
                flex: 1,
              },
            },
          }}
        >
          {row}
        </Box>
      ))}
    </>
  );
};

export const PrintDaySchedule = () => {
  const { preferences, employeesOffState, shiftSummaryHelper } = useRosterContext();
  const [stations] = useState(() => Array.from(shiftSummaryHelper.allStationCards.values()));
  const floaters = shiftSummaryHelper.unassignedCards;
  const selectedStations = stations.filter(
    (station) =>
      (preferences.selectedStationsMap.get(preferences.selectedBattalionId || -1) || new Map()).get(station.stationName) ||
      (checkIsEvent(station) &&
        (preferences.selectedStationsMap.get(preferences.selectedBattalionId || -1) || new Map()).get('Event Groups')),
  );
  const [positionEmployeesOffMap] = useState(() => {
    const map = new Map<string, EmployeeOffView[]>();
    employeesOffState.employeesOff.forEach((employeeOffData) => {
      employeeOffData.positions.forEach((positionId) => {
        const key = positionId.toString();
        const value = map.get(key) || [];
        !map.has(key) && map.set(key, value);
        value.push(employeeOffData);
      });
    });
    return map;
  });

  const [overtimeEmployees] = useState(() => findOvertimeEmployees(selectedStations));

  const STATION_ROW_HEIGHT = 21;

  //split each apparatus into a box with their corresponding station information
  const splitStationsIntoBoxes = () => {
    const maxRowsPerBox = Math.floor(MAX_BOX_HEIGHT / STATION_ROW_HEIGHT);
    const boxes: RosterStation[][] = [];
    let currentBox: RosterStation[] = [];
    let currentRowCount = 0;

    // Calculate extra section rows
    const floaterRows = floaters.length > 0 ? floaters.length + 1 : 0;
    const absentRows = positionEmployeesOffMap.size > 0 ? positionEmployeesOffMap.size + 1 : 0;
    const overtimeRows = overtimeEmployees.length > 0 ? overtimeEmployees.length * 2 + 1 : 0;
    const extraSectionRows = floaterRows + absentRows + overtimeRows;

    selectedStations.forEach((station) => {
      station.apparatuses.forEach((apparatus) => {
        //create a new station object for one apparatus
        const stationWithSingleApparatus: RosterStation = {
          ...station,
          apparatuses: [apparatus],
        };

        const apparatusRows = calculateTotalRows(stationWithSingleApparatus);

        if (currentRowCount + Math.ceil(apparatusRows) > maxRowsPerBox) {
          boxes.push(currentBox);
          currentBox = [stationWithSingleApparatus];
          currentRowCount = apparatusRows;
        } else {
          currentBox.push(stationWithSingleApparatus);
          currentRowCount += apparatusRows;
        }
      });
    });

    if (currentBox.length > 0) {
      boxes.push(currentBox);
    }

    // If extra sections won't fit in last box, create a new box for them
    const lastBoxRows = currentBox.length > 0 ? currentRowCount : 0;
    if (lastBoxRows + extraSectionRows > maxRowsPerBox) {
      boxes.push([]);
    }

    return boxes;
  };

  const stationBoxes = splitStationsIntoBoxes();

  return (
    <Box sx={{ backgroundColor: 'white' }}>
      <Box sx={{ typography: 'h5', display: 'flex', alignItems: 'center', gap: 1, flexDirection: 'column', pb: 1 }}>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, width: '100%' }}>
          <Box sx={{ m: 0, display: 'flex', flexDirection: 'row' }}>
            {shiftSummaryHelper.currentTeams.length <= 3 && (
              <Box sx={{ marginRight: '8px' }}>{shiftSummaryHelper.currentTeams.map((team) => team.name).join(', ')}</Box>
            )}
            Daily Roster
          </Box>
          <Box sx={{ flex: 1 }} />
          {format(shiftSummaryHelper.shiftDuration.startTime, 'EEE, MMM dd, yyyy')}
        </Box>
      </Box>

      <BoxesContainer>
        {stationBoxes.map((stations, index) => (
          <PrintDayScheduleBox
            key={index}
            stations={stations}
            positionEmployeesOffMap={positionEmployeesOffMap}
            isLastBox={index === stationBoxes.length - 1}
            overtimeEmployees={overtimeEmployees}
            floaters={floaters}
          />
        ))}
      </BoxesContainer>
    </Box>
  );
};
