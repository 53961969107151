import { EventInput } from '@fullcalendar/core';
import { EventImpl } from '@fullcalendar/core/internal';
import { Box } from '@mui/material';
import { format } from 'date-fns';
import React from 'react';
import {
  Button,
  Chip,
  RankBadge,
  SHIFT_OVERVIEW_OPTIONS,
  SHIFT_TITLES,
  STATUS_TITLES,
  theme,
} from '@stationwise/component-module';
import { DetailCode } from '@stationwise/share-types';
import { determineDateRange } from '../../../../../../../app/pages/Calendar/components/ShiftOverview/ShiftOverviewOptions';
import { EventCardLine } from './EventCardLine';
import { EventCardTitleSection } from './EventCardTitleSection';
import { executeSelectedAction, findDurationAsHourAndMinute, processTitle } from './EventCardUtils';
import { EventCardWrapper } from './EventCardWrapper';

export const OvertimeEventCard = ({
  shift,
  setClickedEventAsCalendarSelectedElement,
  openActionModal,
  handleShiftCardOverviewClose,
}: {
  shift: EventInput | EventImpl;
  setClickedEventAsCalendarSelectedElement?: (
    event: React.MouseEvent<HTMLElement>,
    shift: EventInput | EventImpl,
    design?: boolean,
  ) => void;
  openActionModal?: (option: string) => void;
  handleShiftCardOverviewClose?: () => void;
}) => {
  const mandatory = shift.title === SHIFT_TITLES.OVERTIME;
  const { hour: durationHour, minute: durationMin } = findDurationAsHourAndMinute(shift);
  const dateRange = determineDateRange(shift);
  console.log('shift', shift);
  const isCancelled = (shift as EventInput)['status'] === STATUS_TITLES.CANCELLED;

  return (
    <EventCardWrapper>
      <EventCardTitleSection
        title={mandatory ? 'Overtime' : processTitle(shift, false)}
        backgroundColor={mandatory ? theme.palette.pink[500] : theme.palette.purple[100]}
        titleColor={mandatory ? theme.palette.common.white : theme.palette.purple[800]}
        truncate
      />

      <Box
        sx={(theme) => ({
          display: 'flex',
          px: theme.spacing(2),
          flexDirection: 'column',
          alignItems: 'flex-start',
          alignSelf: 'stretch',
          '&:last-child': {
            borderBottom: 'none',
          },
        })}
      >
        <EventCardLine
          title={'Start Date'}
          value={shift.start ? format(shift.start as Date, 'MMM d, HH:mm') : '-'}
        ></EventCardLine>
        <EventCardLine title={'End Date'} value={shift.end ? format(shift.end as Date, 'MMM d, HH:mm') : '-'}></EventCardLine>

        <EventCardLine
          title={'Duration'}
          value={shift.end && shift.start ? durationHour + ' hrs ' + durationMin + ' mins' : '-'}
        ></EventCardLine>

        <EventCardLine
          title={'Rank'}
          value={
            shift.extendedProps && shift.extendedProps['personalRank'] ? (
              <Box>
                <RankBadge maxLength={100} rankField={'name'} rank={shift.extendedProps['personalRank']} />
                {shift.extendedProps['personalRank'] !== shift.extendedProps['eventRank'] && (
                  <Box
                    sx={(theme) => ({
                      display: 'flex',
                      pt: theme.spacing(1.25),
                      flexDirection: 'wrap',
                      gap: theme.spacing(0.5),
                    })}
                  >
                    <Chip
                      chipSxProps={() => ({
                        alignItems: 'left',
                        borderRight: `1px solid ${theme.palette.gray[500]}`,
                        borderTopRightRadius: theme.spacing(50),
                        borderBottomRightRadius: theme.spacing(50),
                        position: 'center',
                        padding: '2px 6px',
                      })}
                      label="Acting as"
                    />
                    <RankBadge maxLength={100} rankField={'name'} rank={shift.extendedProps['eventRank']} />
                  </Box>
                )}
              </Box>
            ) : (
              <RankBadge maxLength={100} rankField={'name'} rank={shift.extendedProps!['eventRank']} />
            )
          }
        ></EventCardLine>
        {!mandatory && (
          <EventCardLine
            title={'List Position'}
            value={isCancelled ? 'Cancelled' : shift.extendedProps ? shift.extendedProps['eventOrder'] : '-'}
          ></EventCardLine>
        )}

        {mandatory && (
          <>
            <EventCardLine
              title={'Station'}
              value={shift.extendedProps ? shift.extendedProps['eventStation'] : '-'}
            ></EventCardLine>
            <EventCardLine
              title={'Apparatus'}
              value={shift.extendedProps ? shift.extendedProps['eventApparatus'] : '-'}
            ></EventCardLine>
            <EventCardLine
              title={'Pay Code'}
              value={
                shift.extendedProps && shift.extendedProps['eventPayCodes'] && shift.extendedProps['eventPayCodes'][0]
                  ? shift.extendedProps['eventPayCodes'][0].code + ' | ' + shift.extendedProps['eventPayCodes'][0].name
                  : '-'
              }
            ></EventCardLine>
            {shift.extendedProps &&
              shift.extendedProps['eventDetailCodes'] &&
              shift.extendedProps['eventDetailCodes'].map((detailCode: DetailCode, index: number) => (
                <EventCardLine
                  key={index}
                  title={'Detail Code ' + (index + 1)}
                  value={detailCode.code + ' | ' + detailCode.name}
                ></EventCardLine>
              ))}

            {shift.extendedProps && shift.extendedProps['eventNote'] && (
              <EventCardLine
                isLastLine={!openActionModal}
                title={'Note'}
                value={shift.extendedProps ? shift.extendedProps['eventNote'] : '-'}
              ></EventCardLine>
            )}
          </>
        )}
      </Box>
      <Box
        sx={(theme) => ({
          display: 'flex',
          p: openActionModal || isCancelled ? theme.spacing(2) : '',
          gap: theme.spacing(1),
          alignSelf: 'stretch',
          alignItems: 'center',
        })}
      >
        {dateRange === 'future' && openActionModal && !mandatory && !isCancelled && (
          <Button
            sx={(theme) => ({
              display: 'flex',
              py: theme.spacing(0.75),
              px: theme.spacing(2),
              justifyContent: 'center',
              alignItems: 'center',
              flex: '1 0 0',
            })}
            data-cy="Cancel Overtime Request"
            color="error"
            size="medium"
            variant="outlined"
            onClick={(event: React.MouseEvent<HTMLElement>) => {
              executeSelectedAction(
                SHIFT_OVERVIEW_OPTIONS.CANCEL_OVERTIME_REQUEST,
                event,
                shift,
                setClickedEventAsCalendarSelectedElement,
                openActionModal,
                handleShiftCardOverviewClose,
              );
            }}
          >
            Cancel Sign Up
          </Button>
        )}
        {dateRange !== 'past' && openActionModal && mandatory && (
          <Button
            sx={(theme) => ({
              display: 'flex',
              py: theme.spacing(0.75),
              px: theme.spacing(2),
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'column',
              flex: '1 0 0',
            })}
            color="primary"
            size="medium"
            variant="outlined"
            onClick={(event: React.MouseEvent<HTMLElement>) => {
              executeSelectedAction(
                SHIFT_OVERVIEW_OPTIONS.REQUEST_TIME_OFF,
                event,
                shift,
                setClickedEventAsCalendarSelectedElement,
                openActionModal,
                handleShiftCardOverviewClose,
              );
            }}
          >
            Time Off Request
          </Button>
        )}
      </Box>
    </EventCardWrapper>
  );
};
