import { Box } from '@mui/material';
import { PublishPreview } from '../../preview';
import { PublishingPreviewDetailDepartmentStructureBlock } from './PublishingPreviewDetailDepartmentStructureBlock';
import { PublishingPreviewDetailEmployeeBlock } from './PublishingPreviewDetailEmployeeBlock';

interface PublishingPreviewDetailProps {
  publishPreview: PublishPreview;
}

export const PublishingPreviewDetail = ({ publishPreview }: PublishingPreviewDetailProps) => {
  return (
    <Box
      sx={(theme) => ({
        textAlign: 'left',
        typography: 'bodyMRegular',
        border: `1px solid ${theme.palette.gray[100]}`,
        background: theme.palette.gray[50],
        p: '12px 12px 12px 12px',
        marginTop: '12px',
      })}
    >
      {publishPreview.employeeChanges.changedTeamEmployees.length !== 0 && (
        <PublishingPreviewDetailEmployeeBlock
          employees={publishPreview.employeeChanges.changedTeamEmployees}
          blockTitle={'Employees changing teams:'}
        />
      )}
      {publishPreview.employeeChanges.sameTeamEmployees.length !== 0 && (
        <PublishingPreviewDetailEmployeeBlock
          employees={publishPreview.employeeChanges.sameTeamEmployees}
          blockTitle={'Employees changing assignments on the same team:'}
        />
      )}
      {publishPreview.employeeChanges.unassignedEmployees.length !== 0 && (
        <PublishingPreviewDetailEmployeeBlock
          employees={publishPreview.employeeChanges.unassignedEmployees}
          blockTitle={'Employees completely unassigned:'}
        />
      )}
      {publishPreview.employeeChanges.indirectlyAffectedEmployees.length !== 0 && (
        <PublishingPreviewDetailEmployeeBlock
          employees={publishPreview.employeeChanges.indirectlyAffectedEmployees}
          blockTitle={'Indirectly affected employees:'}
        />
      )}
      {(publishPreview.stationChanges.newStations.length != 0 ||
        publishPreview.stationChanges.deletedStations.length != 0 ||
        publishPreview.stationChanges.editedStations.length != 0) && (
        <PublishingPreviewDetailDepartmentStructureBlock stationChanges={publishPreview.stationChanges} />
      )}
    </Box>
  );
};
