import { Box } from '@mui/material';
import { useState } from 'react';
import { Button, Modal, Select, SelectItem, HornIcon24 } from '@stationwise/component-module';
import { RosterApparatus, RosterStation } from '@stationwise/share-types';

interface MoveApparatusModalProps {
  selectedApparatusToMove: { apparatus: RosterApparatus; originalStation: RosterStation; availableStations: RosterStation[] };
  isOpen: boolean;
  setIsOpen: (open: boolean) => void;
  moveApparatus: (apparatus: RosterApparatus, originalStation: RosterStation, destinationStationId: string) => void;
}
export const MoveApparatusModal = ({ selectedApparatusToMove, isOpen, setIsOpen, moveApparatus }: MoveApparatusModalProps) => {
  const stationList: SelectItem[] = selectedApparatusToMove.availableStations.map((station) => ({
    label: station.stationName,
    value: station.stationId || station.stationName,
  }));
  const [selectedStationId, setSelectedStationId] = useState<string>(() => (stationList.length > 0 ? stationList[0].value : ''));

  const onConfirm = () => {
    setIsOpen(false);
    moveApparatus(selectedApparatusToMove.apparatus, selectedApparatusToMove.originalStation, selectedStationId);
  };

  return (
    <Modal open={isOpen} setOpen={setIsOpen}>
      <Box>
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <Box
            sx={(theme) => ({
              borderRadius: '80px',
              backgroundColor: theme.palette.common.white,
              p: theme.spacing(2),
              border: `1px solid ${theme.palette.gray[50]}`,
              boxShadow: '0px 4px 6px -1px rgba(10, 14, 22, 0.10), 0px 2px 4px -1px rgba(10, 14, 22, 0.06)',
              '& svg path': { stroke: theme.palette.indigo[600] },
              width: '56px',
              height: '56px',
              mb: theme.spacing(2),
            })}
          >
            <HornIcon24 />
          </Box>
        </Box>
        <Box sx={{ textAlign: 'center', typography: 'bodyXLSemibold' }}>{`Move ${selectedApparatusToMove.apparatus.name}`}</Box>
        <Box
          sx={(theme) => ({ textAlign: 'center', typography: 'bodyMRegular', mt: theme.spacing(0.5) })}
        >{`to ${selectedApparatusToMove.availableStations.find((station) => station.stationId === selectedStationId || station.stationName === selectedStationId)?.stationName}`}</Box>
        <Box sx={(theme) => ({ mt: theme.spacing(4), display: 'block' })}>
          {stationList.length > 0 && (
            <Select
              width="100%"
              items={stationList}
              onChange={(selectedOption: string) => setSelectedStationId(selectedOption)}
              selected={selectedStationId}
            />
          )}
        </Box>
        <Box
          sx={(theme) => ({
            display: 'flex',
            justifyContent: 'space-between',
            mt: theme.spacing(5),
            gap: theme.spacing(1.5),
            width: '100%',
          })}
        >
          <Button onClick={() => setIsOpen(false)} variant="outlined" size="large" sx={{ width: '100%' }}>
            Cancel
          </Button>
          <Button onClick={onConfirm} variant="contained" size="large" sx={{ width: '100%' }}>
            Confirm
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};
