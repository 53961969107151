import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableRowProps,
  Paper,
  TypographyProps,
  Tooltip,
} from '@mui/material';
import * as React from 'react';
import { getTooltipProps } from '../../utils';

interface TableColumn {
  id: string;
  label: string | React.ReactElement;
  minWidth?: number;
  align?: 'inherit' | 'left' | 'center' | 'right' | 'justify';
  font?: TypographyProps['variant'];
  fontcolor?: string;
  tooltip?: string;
  width?: number;
}

interface TableDataItem {
  // eslint-disable-next-line
  [key: string]: any;
}

export interface TableProps {
  columns: TableColumn[];
  data: TableDataItem[];
  // eslint-disable-next-line
  getRowProps?: (row: TableDataItem, rowIndex: number) => TableRowProps & { [key: string]: any };
}

export const CustomTable: React.FC<TableProps> = ({ columns, data, getRowProps }) => {
  return (
    <Paper sx={{ border: 'none', boxShadow: 'none' }}>
      <TableContainer>
        <Table stickyHeader aria-label="customized table" sx={{ borderCollapse: 'collapse' }}>
          <TableHead>
            <TableRow>
              {columns.map((column) =>
                column.tooltip ? (
                  <Tooltip key={column.id} title={column.tooltip} slotProps={getTooltipProps()} placement={'top'} arrow>
                    <TableCell
                      align={column.align || 'left'}
                      sx={(theme) => ({
                        padding: '16px',
                        backgroundColor: 'background.paper',
                        color: column.fontcolor || theme.palette.gray[400],
                        boxShadow: 'none',
                        width: column.width,
                        minWidth: column.minWidth,
                        typography: column.font || 'tagline1',
                      })}
                    >
                      {column.label}
                    </TableCell>
                  </Tooltip>
                ) : (
                  <TableCell
                    key={column.id}
                    align={column.align || 'left'}
                    sx={(theme) => ({
                      padding: '16px',
                      backgroundColor: 'background.paper',
                      color: column.fontcolor || theme.palette.gray[400],
                      boxShadow: 'none',
                      width: column.width,
                      minWidth: column.minWidth,
                      typography: column.font || 'tagline1',
                    })}
                  >
                    {column.label}
                  </TableCell>
                ),
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((row, index) => (
              <TableRow
                data-cy={`table-row-${index}`}
                hover
                role="checkbox"
                tabIndex={-1}
                key={index}
                sx={{ borderBottom: 'none' }}
                {...getRowProps?.(row, index)}
              >
                {columns.map((column) => {
                  const value = row[column.id];
                  return (
                    <TableCell
                      key={column.id}
                      align={column.align || 'left'}
                      sx={{
                        padding: '16px',
                      }}
                    >
                      {value}
                    </TableCell>
                  );
                })}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};
