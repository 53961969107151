import { Box, Chip, InputAdornment, TextField, Theme } from '@mui/material';
import { format, isValid } from 'date-fns';
import { useState } from 'react';
import { DatePicker, XCloseIcon16 } from '@stationwise/component-module';

interface TotalOvertimeProps {
  daysAgo: number;
  setDaysAgo: (days: number) => void;
  totalOvertimeIntervalStartDates: Date[];
  setTotalOvertimeIntervalStartDates: (intervalStarts: Date[]) => void;
}

const getChipStyles = (theme: Theme) => {
  return {
    paddingLeft: theme.spacing(0.5),
    borderRadius: '24px',
    border: `1px solid ${theme.palette.gray[200]}`,
    pr: theme.spacing(1),
    '& .MuiChip-label': {
      flex: 1,
      minWidth: '1px',
      pl: '10px',
      pr: theme.spacing(2),
    },
    '& .MuiChip-deleteIcon path': {
      stroke: theme.palette.gray[400],
    },
  };
};

export const TotalOvertime = ({
  daysAgo,
  setDaysAgo,
  totalOvertimeIntervalStartDates,
  setTotalOvertimeIntervalStartDates,
}: TotalOvertimeProps) => {
  const [datePickerKey, setDatePickerKey] = useState<string>('');

  const onChangeStartIntervalDate = (value: Date | null) => {
    if (
      value &&
      isValid(value) &&
      !totalOvertimeIntervalStartDates.map((date) => format(date, 'MMM, dd')).includes(format(value, 'MMM, dd'))
    ) {
      // add newly selected date to the chips
      setTotalOvertimeIntervalStartDates([...totalOvertimeIntervalStartDates, value]);
      // to reset and clear the datepicker input after each selection
      setDatePickerKey(new Date().getTime().toString());
    }
  };

  return (
    <>
      <Box
        sx={(theme) => ({
          typography: 'bodyMSemibold',
          lineHeight: '20px',
          mt: 3,
          mb: 2,
          color: theme.palette.gray[700],
        })}
      >
        Total overtime rules
      </Box>
      <Box
        sx={(theme) => ({
          typography: 'bodyXSRegular',
          mb: 3,
          color: theme.palette.gray[500],
          background: theme.palette.gray[100],
          p: 1,
          borderRadius: '12px',
        })}
      >
        <Box>{`The configured option closest to the date of the staffing list will be selected to start the calculation.`}</Box>
      </Box>
      <Box display="flex" gap={2} justifyContent="space-between">
        <Box
          sx={{
            width: '100%',
            '.MuiFormControl-root': {
              '.MuiInputLabel-root': {
                top: '-5px',
              },
            },
          }}
        >
          <TextField
            label="Days ago"
            onChange={(e) => setDaysAgo(Number(e.target.value))}
            type="number"
            variant="outlined"
            size="medium"
            fullWidth={true}
            value={daysAgo === 0 ? '' : daysAgo}
            inputProps={{ min: 0, max: Number.MAX_SAFE_INTEGER }}
            slotProps={{
              input: {
                endAdornment: <InputAdornment position="end">d</InputAdornment>,
                inputMode: 'decimal', // For mobile devices
              },
            }}
          />
          <Box sx={(theme) => ({ ml: 0.5, mt: 1, typography: 'bodyXSRegular', color: theme.palette.gray[500] })}>
            {`* Number of days ago from the staffing list date.`}
          </Box>
        </Box>
        <Box sx={{ width: '100%' }}>
          <DatePicker
            key={datePickerKey}
            value={null}
            onChange={onChangeStartIntervalDate}
            sx={{ width: '100%' }}
            slotProps={{ textField: { placeholder: 'Choose dates' } }}
          />
          <Box sx={(theme) => ({ ml: 0.5, mt: 1, typography: 'bodyXSRegular', color: theme.palette.gray[500] })}>
            {`* Possible start dates for the overtime interval that ends on the staffing list date.`}
          </Box>
          <Box sx={{ mt: 1 }}>
            {totalOvertimeIntervalStartDates.map((date, index) => (
              <Chip
                key={index}
                sx={(theme) => getChipStyles(theme)}
                variant="outlined"
                label={<Box>{format(date, 'MMM, dd')}</Box>}
                deleteIcon={<XCloseIcon16 />}
                onDelete={(_e) =>
                  setTotalOvertimeIntervalStartDates(totalOvertimeIntervalStartDates.filter((_, i) => i !== index))
                }
              />
            ))}
          </Box>
        </Box>
      </Box>
    </>
  );
};
