import { Box, Drawer, IconButton, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import { captureException } from '@sentry/react';
import { useEffect, useState } from 'react';
import { RankBadge, formatDate, SnackbarService, useRosterContext, XIcon24 } from '@stationwise/component-module';
import { client } from '@stationwise/share-api';
import { Responsibility } from '@stationwise/share-types';

interface ResponsibilityDrawerProps {
  isResponsibilityOpen: boolean;
  setIsResponsibilityOpen: (value: boolean) => void;
}

export const ResponsibilityDrawer = ({ isResponsibilityOpen, setIsResponsibilityOpen }: ResponsibilityDrawerProps) => {
  const { shiftSummaryHelper } = useRosterContext();
  const currentDate = shiftSummaryHelper.shiftDuration.startTime;

  const [responsibilities, setResponsibilities] = useState<Responsibility[]>([]);

  useEffect(() => {
    const fetchResponsibility = async (shiftDate: string) => {
      try {
        const response = await client.get('/shift/responsibility/', {
          params: {
            date: shiftDate,
          },
        });
        setResponsibilities(response.data);
      } catch (err) {
        captureException(err);
        SnackbarService.notify({
          content: 'Unexpected error. Try again later.',
          severity: 'error',
          showCloseButton: true,
          duration: 5000,
        });
      }
    };

    fetchResponsibility(formatDate(currentDate));
  }, [setResponsibilities, currentDate]);

  return (
    <Drawer
      anchor="bottom"
      open={isResponsibilityOpen}
      onClose={() => {
        setIsResponsibilityOpen(false);
      }}
      sx={(theme) => ({
        '& .MuiDrawer-paper': {
          width: '100vw',
          height: '100vh',
          margin: 0,
          padding: theme.spacing(2),
          borderRadius: theme.spacing(0),
        },
      })}
    >
      <Box>
        <Box
          sx={(theme) => ({
            display: 'flex',
            justifyContent: 'space-between',
            typography: theme.typography.h6,
          })}
        >
          Responsibilities
          <IconButton
            sx={(theme) => ({ color: theme.palette.gray[500], mt: -1, mr: -1 })}
            onClick={() => setIsResponsibilityOpen(false)}
          >
            <XIcon24 />
          </IconButton>
        </Box>
      </Box>
      <Box
        sx={(theme) => ({
          display: 'flex',
          gap: 2,
          overflow: 'auto',
          flexDirection: 'column',
          mb: theme.spacing(0),
        })}
      >
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Person</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {responsibilities.map((responsibility) => (
              <TableRow key={responsibility.id}>
                <TableCell>{responsibility.name}</TableCell>
                <TableCell>
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: 1.5,
                      textAlign: 'left',
                      width: '100%',
                    }}
                  >
                    {responsibility.employee && <RankBadge rank={responsibility.employee.rank} />}
                    <Box sx={{ flex: 1, typography: 'body2' }}>{responsibility.employee?.name}</Box>
                  </Box>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Box>
    </Drawer>
  );
};
