import { captureException } from '@sentry/react';
import { addDays, format, max, parseISO, startOfDay } from 'date-fns';
import { useMemo, useRef, useState } from 'react';
import { SnackbarService, useLoadedDepartmentInfoContext } from '@stationwise/component-module';
import { client, AxiosResponse, isAxiosError } from '@stationwise/share-api';
import {
  ShiftPlanAPIData,
  ShiftPlanChipType,
  ShiftPlanAction,
  ShiftPlanStruct,
  SaveAsDraftShiftPlanResponse,
  ShiftPlanTeam,
  ShiftPlanAssignment,
} from '@stationwise/share-types';
import { compareEndDates, makeDateInterval } from '@stationwise/share-utils';
import { getRepeatingTeamPatternDates } from '../helpers/parseTeamPattern';
import { initializeAssignments, getAssignmentGroups } from '../helpers/readAssignments';
import { initializeStations, findCopyStruct } from '../helpers/readStations';

interface UseShiftPlanProps {
  data: ShiftPlanAPIData;
  forceRefetch: (battalionId: string) => void;
}

export const useShiftPlan = (props: UseShiftPlanProps) => {
  const { state: departmentInfoState } = useLoadedDepartmentInfoContext();
  const { departmentInfo } = departmentInfoState;
  const { battalion } = props.data;
  const shiftDuration = makeDateInterval(parseISO(props.data.date), departmentInfo.shiftStart, 24);
  const ccVersionRef = useRef(props.data.ccVersion);
  const [hasDraft, setHasDraft] = useState(props.data.hasDraft);
  const [teams, setTeams] = useState(props.data.teams);
  const [stations, setStations] = useState(() => initializeStations(props.data.stations));
  const [assignments, setAssignments] = useState(() => initializeAssignments(departmentInfo, props.data.assignments));
  const [selectedChipType, setSelectedChipType] = useState(ShiftPlanChipType.ROSTER_EDITOR);
  const [selectedAction, setSelectedAction] = useState<ShiftPlanAction | null>(null);
  const [selectedStruct, setSelectedStruct] = useState<ShiftPlanStruct>({});
  const [selectedPersonnelStruct, setSelectedPersonnelStruct] = useState<ShiftPlanStruct>({});
  const [savingAction, setSavingAction] = useState<ShiftPlanAction | null>(null);

  const patternDatesMap = useMemo(() => {
    let ret = new Map();
    teams.forEach((team) => {
      ret.set(team.id, new Set(team.patternDates));
    });
    return ret;
  }, [teams]);

  const getTeamPatternDates = (
    team: ShiftPlanTeam,
    fromDateString: string,
    toDateString: string,
    assignment?: ShiftPlanAssignment,
  ) => {
    if (team.pattern) {
      return getRepeatingTeamPatternDates(team, fromDateString, toDateString, assignment);
    }

    const patternDates = new Set<string>();
    const patternDatesForTeam = patternDatesMap.get(team.id);
    if (!patternDatesForTeam) {
      return patternDates;
    }
    const fromDate = parseISO(fromDateString);
    const toDate = parseISO(toDateString);

    const patternStartDate = team.patternStartDate ? parseISO(team.patternStartDate) : fromDate;

    let date = max([patternStartDate, fromDate]);

    while (startOfDay(date) <= startOfDay(toDate)) {
      const dateString = format(date, 'yyyy-MM-dd');
      if (
        (!assignment || (date >= assignment.activationDate && compareEndDates(date, assignment.deactivationDate) <= 0)) &&
        patternDatesForTeam.has(dateString)
      ) {
        patternDates.add(dateString);
      }
      date = addDays(date, 1);
    }

    return patternDates;
  };

  const assignmentGroups = useMemo(() => getAssignmentGroups(assignments), [assignments]);

  const cancelSelectedAction = () => !savingAction && setSelectedAction(null);

  const softRefetch = async () => {
    const response = await client.get('/shift/shift-plan/', {
      params: { battalionId: battalion.id },
    });
    ccVersionRef.current = response.data.ccVersion;
    setHasDraft(response.data.hasDraft);
    setTeams(response.data.teams);
    setStations(initializeStations(response.data.stations));
    setAssignments(initializeAssignments(departmentInfo, response.data.assignments));
  };

  const saveAsDraft = async (
    payload: Record<string, unknown>,
    onSuccess?: (data: AxiosResponse<SaveAsDraftShiftPlanResponse>) => void,
    onError?: (error: unknown) => unknown,
  ) => {
    try {
      setSavingAction((prevSavingAction) => prevSavingAction || selectedAction);
      const ccVersion = ccVersionRef.current;
      const response = await client.post('/shift/shift-plan/', { ccVersion, battalionId: battalion.id, ...payload });
      // Expect the caller to setSavingAction(null). We often want to keep it open while a dialog is animating away.
      onSuccess?.(response);
      if (typeof response.data.ccVersion === 'number') {
        ccVersionRef.current = response.data.ccVersion;
      }
      if (response.data.hasDraft !== undefined) {
        setHasDraft(response.data.hasDraft);
      }
      if (response.data.stations) {
        const newStations = initializeStations(response.data.stations);
        setStations(newStations);
        setSelectedStruct((prevStruct) => findCopyStruct(newStations, prevStruct));
        setSelectedPersonnelStruct((prevStruct) => findCopyStruct(newStations, prevStruct, 'POSITION'));
      }
      if (response.data.assignments) {
        setAssignments(initializeAssignments(departmentInfo, response.data.assignments));
      }
    } catch (error) {
      !isAxiosError(error) && captureException(error);
      if (isAxiosError(error) && typeof error.response?.data.ccVersion === 'number') {
        ccVersionRef.current = error.response.data.ccVersion;
      }
      if (!onError || onError(error)) {
        setSavingAction(null);

        let message = 'There was an issue saving your changes. Please try again.';
        if (isAxiosError(error)) {
          message = error.response?.data?.nonFieldErrors?.[0] || message;
        }

        SnackbarService.notify({ content: message, severity: 'error', duration: 5000 });
      }
    }
  };

  const publish = async (payload: Record<string, unknown>) => {
    try {
      setSavingAction((prevSavingAction) => prevSavingAction || selectedAction);
      const ccVersion = ccVersionRef.current;
      await client.put('/shift/shift-plan/', { ccVersion, ...payload });
      props.forceRefetch(`${battalion.id}`);
    } catch (error) {
      !isAxiosError(error) && captureException(error);
      setSavingAction(null);

      let message = 'There was an issue publishing your changes. Please try again.';
      if (isAxiosError(error)) {
        message = error.response?.data?.nonFieldErrors?.[0] || message;
      }

      SnackbarService.notify({ content: message, severity: 'error', duration: 5000 });
    }
  };

  return {
    shiftDuration,
    battalion,
    teams,
    setTeams,
    getTeamPatternDates,
    stations,
    setStations,
    assignments,
    setAssignments,
    selectedChipType,
    setSelectedChipType,
    selectedAction,
    setSelectedAction,
    cancelSelectedAction,
    selectedStruct,
    setSelectedStruct,
    selectedPersonnelStruct,
    setSelectedPersonnelStruct,
    assignmentGroups,
    forceRefetch: props.forceRefetch,
    softRefetch,
    savingAction,
    setSavingAction,
    hasDraft,
    saveAsDraft,
    publish,
  };
};

export type UseShiftPlanReturnType = ReturnType<typeof useShiftPlan>;
