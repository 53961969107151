import { ReactComponent as ChevronRightSymbol } from '@material-symbols/svg-400/outlined/chevron_right.svg';
import { ReactComponent as SearchSymbol } from '@material-symbols/svg-400/outlined/search.svg';
import { Box, InputAdornment, Popover, TextField, useTheme } from '@mui/material';
import { startOfMonth } from 'date-fns';
import { useDeferredValue, useEffect, useState } from 'react';
import {
  InfiniteScroll,
  Loader,
  SvgIcon,
  asDepartmentDateTime,
  useLoadedDepartmentInfoContext,
} from '@stationwise/component-module';
import { ShiftPlanAction, ShiftPlanCandidate, ShiftPlanPosition, ShiftPlanAssignment } from '@stationwise/share-types';
import { makeTestIdentifier } from '@stationwise/share-utils';
import { useShiftPlanCandidates } from '../../../hooks/useShiftPlanCandidates';
import { MonthDays, MonthSelector, renderMonthDayOverlay, renderToday } from '../../Calendar';
import { BorderedBoxes } from '../../Grid';
import { useShiftPlanContext } from '../../ShiftPlanContext';
import { ShiftPlanDialog, ShiftPlanCloseDialogResetContextEffect } from '../../ShiftPlanDialog';
import { AssignmentGroupRow } from './AssignmentGroupRow';
import { CandidateInfo } from './CandidateInfo';
import { PersonRow } from './PersonRow';
import { RemoveAssignmentForm } from './RemoveAssignmentForm';
import { UpsertAssignmentForm } from './UpsertAssignmentForm';

export const PersonnelPanel = () => {
  const theme = useTheme();
  const { state: departmentInfoState } = useLoadedDepartmentInfoContext();
  const { assignments, selectedPersonnelStruct, savingAction, setSelectedPersonnelStruct, setSelectedAction } =
    useShiftPlanContext();
  const isFloaterPersonnelStruct = selectedPersonnelStruct.station?.stationId === 'floater-station';
  const [searchValue, setSearchValue] = useState('');
  const deferredSearchValue = useDeferredValue(searchValue);
  const [selectedMonth, setSelectedMonth] = useState(startOfMonth(asDepartmentDateTime(departmentInfoState, new Date())));
  const deferredSelectedMonth = useDeferredValue(selectedMonth);
  const [selectedPerson, setSelectedPerson] = useState<ShiftPlanCandidate | ShiftPlanAssignment | null>(null);
  const [selectedPersonTeam, setSelectedPersonTeam] = useState<ShiftPlanAssignment['team'] | null>(null);
  const [selectedPersonPopoverAnchorEl, setSelectedPersonPopoverAnchorEl] = useState<HTMLElement | null>(null);
  const candidates = useShiftPlanCandidates({
    positionId: selectedPersonnelStruct.position?.id || null,
    searchValue: deferredSearchValue,
  });

  useEffect(() => {
    const { apparatus } = selectedPersonnelStruct;
    const apparatusEl = document.querySelector(`[data-cy="apparatus-${makeTestIdentifier(apparatus?.name || '')}"]`);
    apparatusEl?.scrollIntoView();

    return () => {
      document.querySelector('.SWShiftPlanRosterEditor-scroll-container')?.scrollTo({ top: 0, left: 0 });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getPersonRowProps = (person: ShiftPlanCandidate | ShiftPlanAssignment) => ({
    person,
    position: selectedPersonnelStruct.position,
    month: deferredSelectedMonth,
    setSelectedAction,
    setSelectedPerson,
    setSelectedPersonTeam,
    setSelectedPersonPopoverAnchorEl,
  });

  const renderAssignmentGroup = (
    position: ShiftPlanPosition | undefined,
    label: string,
    isFirstGroup: boolean,
    isLastGroup: boolean,
  ) => {
    const isSelected = selectedPersonnelStruct.position === position;

    let groupAssignments: ShiftPlanAssignment[] = [];
    if (isSelected) {
      groupAssignments = assignments.filter((a) => {
        if (selectedPersonnelStruct.position) {
          return `${a.reference.position?.id}` === selectedPersonnelStruct.position.id;
        } else if (isFloaterPersonnelStruct) {
          return !a.reference.position && !a.reference.apparatus;
        } else if (selectedPersonnelStruct.apparatus) {
          return !a.reference.position && `${a.reference.apparatus?.id}` === selectedPersonnelStruct.apparatus.id;
        } else {
          return false;
        }
      });
    }

    return (
      <Box
        key={`${position?.id}`}
        sx={{
          '& > div': { borderLeft: 0, borderRight: 0 },
          '& > div:last-of-type': { borderBottom: 0 },
          '& > .SWShiftPlanPersonnelPanel-assignment-group-row': {
            background: isSelected ? theme.palette.gray[300] : theme.palette.gray[200],
            borderTop: `1px dashed ${theme.palette.gray[500]}`,
            ...(!!(isSelected && groupAssignments.length > 0) && {
              background: theme.palette.common.white,
              ...(isFirstGroup && { borderTop: `1px solid ${theme.palette.divider}` }),
            }),
          },
          ...(isLastGroup && {
            '& > div:last-of-type': { borderBottom: `1px solid ${theme.palette.divider}` },
            '& > .SWShiftPlanPersonnelPanel-assignment-group-row:last-of-type': {
              borderBottom: `1px dashed ${theme.palette.gray[500]}`,
            },
          }),
        }}
      >
        <AssignmentGroupRow
          position={position}
          label={label}
          isSelected={isSelected}
          resetCandidates={candidates.resetData}
          setSelectedPersonnelStruct={setSelectedPersonnelStruct}
        />
        {groupAssignments.map((assignment) => {
          return <PersonRow key={assignment.id} {...getPersonRowProps(assignment)} position={position} />;
        })}
      </Box>
    );
  };

  return (
    <Box
      className={selectedMonth !== deferredSelectedMonth ? 'SWShiftPlan-switching-month' : ''}
      sx={{ flex: 1, minWidth: '1px', overflowX: 'auto' }}
    >
      <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%', minWidth: '1142px', pt: 1.5, pl: 2 }}>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5, mb: 1.5 }}>
          {selectedPersonnelStruct.station?.stationName}
          {!isFloaterPersonnelStruct && <SvgIcon component={ChevronRightSymbol} sx={{ fontSize: '18px' }} />}
          {!isFloaterPersonnelStruct && selectedPersonnelStruct.apparatus?.name}
        </Box>
        <TextField
          fullWidth={true}
          value={searchValue}
          onChange={(event) => setSearchValue(event.target.value)}
          placeholder="Search all personnel"
          type="search"
          slotProps={{
            input: {
              startAdornment: (
                <InputAdornment position="start" sx={{ mr: -0.5, pointerEvents: 'none' }}>
                  <SvgIcon component={SearchSymbol} sx={{ color: theme.palette.action.active }} />
                </InputAdornment>
              ),
            },
          }}
          sx={{
            '& .MuiOutlinedInput-root': { borderTopRightRadius: 0, borderBottomRightRadius: 0 },
          }}
        />
        <InfiniteScroll
          onLoadMore={candidates.loadMore}
          loader={candidates.hasNextPage && <Loader sx={{ my: 2 }} />}
          className="SWShiftPlan-today-line-container"
          sx={{
            borderTop: `1px solid ${theme.palette.divider}`,
            borderLeft: `1px solid ${theme.palette.divider}`,
            borderTopLeftRadius: `${theme.shape.borderRadius}px`,
            borderRight: `1px solid ${theme.palette.divider}`,
            flex: 1,
            overflowX: 'hidden',
            overflowY: 'auto',
            mt: 1.5,
          }}
        >
          <Box sx={{ background: theme.palette.common.white, position: 'sticky', top: 0, left: 0, width: '100%', zIndex: 1 }}>
            <BorderedBoxes sx={{ borderTop: 0, borderLeft: 0, borderRight: 0 }}>
              <Box sx={{ flex: 1, px: 1.5, typography: 'bodySMedium' }}>Personnel</Box>
              <Box sx={{ justifyContent: 'center', px: 1.5, width: '700px', minWidth: '60%' }}>
                <MonthSelector value={selectedMonth} onChange={(newValue) => setSelectedMonth(newValue)} />
              </Box>
            </BorderedBoxes>
            <BorderedBoxes sx={{ borderLeft: 0, borderRight: 0 }}>
              <Box sx={{ width: '39px' }} />
              <Box sx={{ width: '40px' }} />
              <Box sx={{ flex: 7, minWidth: '240px', px: 1.5, typography: 'bodySMedium' }}>Name</Box>
              <Box sx={{ flex: 3, minWidth: '1px', px: 1.5, typography: 'bodySMedium' }}>Pattern</Box>
              <BorderedBoxes sx={{ border: 0, width: '700px', minWidth: '60%' }}>
                <MonthDays month={selectedMonth} renderOverlay={renderMonthDayOverlay} renderToday={renderToday} />
              </BorderedBoxes>
            </BorderedBoxes>
          </Box>
          <BorderedBoxes sx={{ border: 0, height: '32px' }}>
            <Box sx={{ px: 1.5, typography: 'bodySMedium' }}>{selectedPersonnelStruct.apparatus?.name}</Box>
          </BorderedBoxes>
          {selectedPersonnelStruct.apparatus?.positions.map((position, positionIndex) => {
            return renderAssignmentGroup(position, position.rank.code, positionIndex === 0, false);
          })}
          {renderAssignmentGroup(
            undefined,
            isFloaterPersonnelStruct ? 'Floaters' : 'Excess capacity',
            !selectedPersonnelStruct.apparatus?.positions.length,
            true,
          )}
          <BorderedBoxes sx={{ borderLeft: 0, borderRight: 0, borderTop: 0, height: '32px' }}>
            <Box sx={{ px: 1.5, typography: 'bodySMedium' }}>Eligible personnel</Box>
          </BorderedBoxes>
          {!candidates.isLoading && candidates.data.length === 0 && (
            <Box sx={{ my: 2, textAlign: 'center', typography: 'bodySMedium' }}>No eligible personnel</Box>
          )}
          <Box sx={{ '& > div': { borderLeft: 0, borderRight: 0 }, '& > div:first-of-type': { borderTop: 0 } }}>
            {candidates.data.map((candidate) => {
              if (candidate.assignments.length === 0) {
                return <PersonRow key={candidate.id} {...getPersonRowProps(candidate)} />;
              }

              const teamIds = new Set<number>();
              return candidate.assignments.map((assignment) => {
                const { team } = assignment;
                if (teamIds.has(team.id)) {
                  return null;
                }

                teamIds.add(team.id);
                return <PersonRow key={`${candidate.id}|${team.id}`} {...getPersonRowProps(candidate)} team={team} />;
              });
            })}
          </Box>
        </InfiniteScroll>
        <Popover
          open={!!selectedPersonPopoverAnchorEl}
          onClose={() => !savingAction && setSelectedPersonPopoverAnchorEl(null)}
          anchorEl={selectedPersonPopoverAnchorEl}
          anchorOrigin={{ vertical: 'center', horizontal: 'right' }}
          transformOrigin={{ vertical: 'center', horizontal: -4 }}
        >
          <ShiftPlanCloseDialogResetContextEffect />
          <UpsertAssignmentForm
            person={selectedPerson}
            team={selectedPersonTeam}
            setSelectedPersonPopoverAnchorEl={setSelectedPersonPopoverAnchorEl}
            setCandidateAssignments={candidates.setCandidateAssignments}
          />
          <CandidateInfo person={selectedPerson} team={selectedPersonTeam} />
        </Popover>
        <ShiftPlanDialog action={ShiftPlanAction.REMOVE_ASSIGNMENT}>
          <RemoveAssignmentForm person={selectedPerson} setCandidateAssignments={candidates.setCandidateAssignments} />
        </ShiftPlanDialog>
      </Box>
    </Box>
  );
};
