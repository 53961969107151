import { useEffect, useState } from 'react';

declare global {
  interface Navigator {
    userAgent?: string;
  }
}

export const useBrowser = () => {
  const [isSafari, setIsSafari] = useState(false);
  const [isChrome, setIsChrome] = useState(false);
  const [isFireFox, setIsFireFox] = useState(false);

  useEffect(() => {
    if (navigator && navigator.userAgent) {
      const data = navigator.userAgent.toLowerCase();
      setIsChrome(data.match(/(?:chrome|crios)\/(\d+)/) ? true : false);
      setIsSafari(data.match(/version\/(\d+).+?safari/) ? true : false);
      setIsFireFox(data.match(/(?:firefox|fxios)\/(\d+)/) ? true : false);
    }
  }, []);

  return {
    isChrome,
    isFireFox,
    isSafari,
  };
};
