import { Box, TextField } from '@mui/material';
import React from 'react';
import { SearchIcon16 } from '../../assets';

interface SearchInputProps {
  value: string;
  setValue: React.Dispatch<React.SetStateAction<string>> | ((value: string) => void);
  color?: 'default' | 'white' | 'dark' | 'lightGray';
  placeHolder?: string;
}

export const SearchInput = ({ value, setValue, color = 'default', placeHolder = 'Search' }: SearchInputProps) => {
  return (
    <Box>
      <TextField
        data-cy="search-input"
        InputProps={{
          startAdornment: (
            <Box
              sx={(theme) => ({
                display: 'flex',
                svg: {
                  color: theme.palette.gray[400],
                  height: '14px',
                  width: '14px',
                },
              })}
            >
              <SearchIcon16 />
            </Box>
          ),
        }}
        onChange={(event) => setValue(event.target.value)}
        placeholder={placeHolder}
        sx={(theme) => ({
          width: '100%',
          borderRadius: theme.spacing(1),
          border: color === 'white' ? `1px solid ${theme.palette.gray[200]}` : 'none',
          background: (() => {
            if (color === 'white') {
              return theme.palette.common.white;
            }
            if (color === 'lightGray') {
              return theme.palette.gray[100];
            }
            if (color === 'dark') {
              return theme.palette.gray[700];
            }
            return '#F3F7FB';
          })(),
          input: {
            color: color === 'dark' || color === 'lightGray' ? theme.palette.gray[400] : theme.palette.gray[500],
            fontWeight: 500,
          },
          '& .MuiOutlinedInput-root': {
            border: 'none',
            height: '100%',
            '& .MuiOutlinedInput-notchedOutline': {
              borderWidth: '0px',
              boxShadow: 'none',
            },
            '& ::placeholder': {
              color: color === 'white' ? theme.palette.gray[500] : theme.palette.gray[400],
            },
          },
          '& .MuiOutlinedInput-root.Mui-focused': {
            border: 'none',
            '& > fieldset': {
              border: 'none',
            },
          },
        })}
      >
        {value}
      </TextField>
    </Box>
  );
};
