import { Box, BoxProps as RowProps } from '@mui/material';
import { alpha } from '@mui/material/styles';

export const Row = ({ children, sx, ...props }: RowProps<'div'>) => {
  return (
    <Box
      {...props}
      sx={[
        (theme) => ({
          minHeight: '20px',
          borderWidth: 0,
          borderBottomWidth: '1px',
          borderStyle: 'solid',
          borderColor: '#CACACA',
          color: theme.palette.gray[900],
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          '&.SWPrintDaySchedule-vacant-row': {
            background: `repeating-linear-gradient(
              135deg,
              transparent,
              transparent 6px,
              ${alpha('#D3D3D3', 0.2)} 6px,
              ${alpha('#D3D3D3', 0.2)} 12px
            )`,
          },
          '&.SWPrintDaySchedule-split-row': {
            borderTopWidth: '0px',
          },
        }),
        ...(Array.isArray(sx) ? sx : sx ? [sx] : []),
      ]}
    >
      {children}
    </Box>
  );
};
