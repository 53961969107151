import { Box, Modal, Typography, Divider, CircularProgress } from '@mui/material';
import { captureException } from '@sentry/react';
import { format, parseISO } from 'date-fns';
import React, { useEffect, useState } from 'react';
import { Button, XIcon24, PlusIcon24, Edit02Icon24, Trash04Icon24, theme } from '@stationwise/component-module';
import { client } from '@stationwise/share-api';
import { Employee, ShiftTrade } from '@stationwise/share-types';
import { AddEditModal } from './AddEditModal';
import { DeleteModal } from './DeleteModal';

interface DetailsModalProps {
  detailsOpen: boolean;
  setDetailsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  selectedEmployee: Employee | undefined;
  tradeEmployee: string | undefined;
  tradeEmployeeId: number | undefined;
  onRefetch: () => void;
}

export interface TradeHistory {
  id: number;
  tradeRequest: ShiftTrade | null;
  requester: Employee | null;
  responder: Employee | null;
  tradeDuration: number;
  note: string;
}

export const DetailsModal = ({
  detailsOpen,
  setDetailsOpen,
  selectedEmployee,
  tradeEmployee,
  tradeEmployeeId,
  onRefetch,
}: DetailsModalProps) => {
  const [tradeHistory, setTradeHistory] = useState<TradeHistory[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [openAddEditModal, setOpenAddEditModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [selectedTradeHistory, setSelectedTradeHistory] = useState<TradeHistory | null>(null);

  const fetchTradeHistory = async () => {
    if (selectedEmployee && tradeEmployee) {
      try {
        setIsLoading(true);
        const response = await client.get(
          `/request/shift-trade-history/?employee_one=${selectedEmployee?.id}&employee_two=${tradeEmployeeId}`,
        );
        onRefetch();
        setTradeHistory(response.data);
      } catch (error) {
        captureException(error);
      } finally {
        setIsLoading(false);
      }
    }
  };

  useEffect(() => {
    if (!detailsOpen) {
      setTradeHistory([]);
    } else if (selectedEmployee) {
      fetchTradeHistory();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [detailsOpen, selectedEmployee]);

  return (
    <Modal open={detailsOpen} onClose={() => setDetailsOpen(false)} data-cy="shift-trade-modal">
      <Box
        sx={{
          display: 'flex',
          width: '100%',
          height: '90vh',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Box
          sx={(theme) => ({
            backgroundColor: theme.palette.common.white,
            justifyContent: 'center',
            borderRadius: theme.spacing(1.5),
            width: '56rem',
            display: 'flex',
            flexDirection: 'column',
          })}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              padding: '24px',
            }}
          >
            <Typography variant="bodyXLSemibold">Balance details</Typography>
            <Box
              data-cy="close-button"
              onClick={() => setDetailsOpen(false)}
              sx={(theme) => ({
                color: theme.palette.gray[400],
                cursor: 'pointer',
                '&:hover': {
                  color: theme.palette.gray[600],
                },
              })}
            >
              <XIcon24 />
            </Box>
          </Box>

          {isLoading ? (
            <Box sx={{ display: 'flex', justifyContent: 'center', py: 4 }}>
              <CircularProgress />
            </Box>
          ) : (
            <>
              <Box sx={(theme) => ({ mb: theme.spacing(2), px: '24px' })}>
                <Typography variant="bodyLRegular" sx={(theme) => ({ color: theme.palette.gray[700] })}>
                  Trade History between{' '}
                  <Typography component="span" variant="bodyLSemibold">
                    {selectedEmployee?.name}
                  </Typography>{' '}
                  and{' '}
                  <Typography component="span" variant="bodyLSemibold">
                    {tradeEmployee}
                  </Typography>
                </Typography>
              </Box>

              <Box
                sx={(theme) => ({
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  px: '24px',
                  color: theme.palette.gray[400],
                })}
              >
                <Typography variant="tagline1" sx={(theme) => ({ width: '100px', padding: theme.spacing(2) })}>
                  DIRECTION
                </Typography>
                <Typography variant="tagline1" sx={(theme) => ({ width: '160px', padding: theme.spacing(2) })}>
                  {'HOURS (SUM)'}
                </Typography>
                <Typography variant="tagline1" sx={(theme) => ({ flexGrow: 1, padding: theme.spacing(2) })}>
                  TRADED
                </Typography>
                <Typography
                  variant="tagline1"
                  sx={(theme) => ({ width: '152px', padding: theme.spacing(2), textAlign: 'right' })}
                >
                  TIMESTAMP
                </Typography>
              </Box>

              <Divider sx={{ mx: '24px' }} />

              {tradeHistory
                .filter((item) => item.tradeRequest !== null)
                .map((item, index) => (
                  <React.Fragment key={index}>
                    <Box
                      sx={(theme) => ({
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        px: theme.spacing(3),
                        color: theme.palette.gray[900],
                      })}
                    >
                      <Typography
                        variant="bodySRegular"
                        sx={(theme) => ({
                          width: '100px',
                          padding: theme.spacing(2),
                          color:
                            selectedEmployee?.id === item.responder?.id ? theme.palette.success.main : theme.palette.error.main,
                        })}
                      >
                        {selectedEmployee?.id === item.responder?.id ? 'is owed' : 'owes'}
                      </Typography>
                      <Typography variant="bodySSemibold" sx={(theme) => ({ width: '160px', padding: theme.spacing(2) })}>
                        {(item.tradeDuration / 60).toFixed(2)} {item.tradeDuration / 60 === 1 ? 'hour' : 'hours'}
                      </Typography>
                      <Typography variant="bodySRegular" sx={(theme) => ({ flexGrow: 1, padding: theme.spacing(2) })}>
                        {(item.tradeDuration / 60).toFixed(2)} hours (
                        {item.tradeRequest?.startDate ? format(parseISO(item.tradeRequest?.startDate), 'dd/MM/yyyy') : ''}{' '}
                        {item.tradeRequest?.startTime} {'-'}{' '}
                        {item.tradeRequest?.shiftEndDate ? format(parseISO(item.tradeRequest?.shiftEndDate), 'dd/MM/yyyy') : ''}{' '}
                        {item.tradeRequest?.endTime})
                      </Typography>
                      <Typography
                        variant="bodySRegular"
                        sx={(theme) => ({ width: '152px', padding: theme.spacing(2), textAlign: 'right' })}
                      >
                        {item.tradeRequest?.createdAt ? format(parseISO(item.tradeRequest.createdAt), 'MMM dd, hh:mm a') : ''}
                      </Typography>
                    </Box>

                    <Divider sx={{ mx: '24px' }} />
                  </React.Fragment>
                ))}
              <Box sx={(theme) => ({ mb: theme.spacing(2), mt: theme.spacing(6) })}>
                <Typography variant="bodyLRegular" sx={(theme) => ({ px: theme.spacing(3), color: theme.palette.gray[700] })}>
                  Manual Adjustments
                </Typography>
              </Box>

              <Box
                sx={(theme) => ({
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  px: theme.spacing(3),
                  color: theme.palette.gray[400],
                })}
              >
                <Typography variant="tagline1" sx={(theme) => ({ width: '100px', padding: theme.spacing(2) })}>
                  DIRECTION
                </Typography>
                <Typography variant="tagline1" sx={(theme) => ({ width: '160px', padding: theme.spacing(2) })}>
                  HOURS
                </Typography>
                <Typography variant="tagline1" sx={(theme) => ({ flexGrow: 1, padding: theme.spacing(2) })}>
                  NOTE
                </Typography>
                <Typography
                  variant="tagline1"
                  sx={(theme) => ({ width: '128px', padding: theme.spacing(2), textAlign: 'right' })}
                >
                  ACTIONS
                </Typography>
              </Box>

              <Divider sx={(theme) => ({ mx: theme.spacing(3) })} />

              {tradeHistory
                .filter((item) => item.tradeRequest === null)
                .map((item, index) => (
                  <React.Fragment key={index}>
                    <Box
                      sx={(theme) => ({
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        px: theme.spacing(3),
                        color: theme.palette.gray[900],
                      })}
                    >
                      <Typography
                        variant="bodySRegular"
                        sx={(theme) => ({
                          width: '100px',
                          padding: theme.spacing(2),
                          color:
                            selectedEmployee?.id === item.responder?.id ? theme.palette.success.main : theme.palette.error.main,
                        })}
                      >
                        {selectedEmployee?.id === item.responder?.id ? 'is owed' : 'owes'}
                      </Typography>
                      <Typography
                        variant="bodySSemibold"
                        sx={{
                          width: '160px',
                          padding: '16px',
                          color: theme.palette.gray[900],
                        }}
                      >
                        {(item.tradeDuration / 60).toFixed(2)} {item.tradeDuration / 60 === 1 ? 'hour' : 'hours'}
                      </Typography>
                      <Typography variant="bodySRegular" sx={(theme) => ({ flexGrow: 1, padding: theme.spacing(2) })}>
                        {item.note}
                      </Typography>
                      <Box
                        sx={(theme) => ({
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          px: theme.spacing(2),
                        })}
                      >
                        <Edit02Icon24
                          data-cy="edit-entry-button"
                          color={theme.palette.gray[500]}
                          style={{ marginRight: '10px', cursor: 'pointer' }}
                          onClick={() => {
                            setOpenAddEditModal(true);
                            setSelectedTradeHistory(item);
                          }}
                        />
                        <Trash04Icon24
                          data-cy="delete-entry-icon"
                          color={theme.palette.rose[500]}
                          style={{ cursor: 'pointer' }}
                          onClick={() => {
                            setOpenDeleteModal(true);
                            setSelectedTradeHistory(item);
                          }}
                        />
                      </Box>
                    </Box>

                    <Divider sx={(theme) => ({ mx: theme.spacing(3) })} />
                  </React.Fragment>
                ))}

              <Button
                data-cy="add-entry-button"
                variant="outlined"
                size="large"
                startIcon={<PlusIcon24 />}
                sx={(theme) => ({ margin: theme.spacing(3) })}
                onClick={() => {
                  setOpenAddEditModal(true);
                  setSelectedTradeHistory(null);
                }}
              >
                Add Entry
              </Button>
            </>
          )}
        </Box>
        {openAddEditModal && (
          <AddEditModal
            open={openAddEditModal}
            onClose={() => setOpenAddEditModal(false)}
            tradeHistoryItem={selectedTradeHistory}
            selectedEmployeeId={selectedEmployee?.id}
            tradeEmployeeId={tradeEmployeeId}
            onRefetch={fetchTradeHistory}
            selectedEmployeeName={selectedEmployee?.name}
            tradeEmployeeName={tradeEmployee}
          />
        )}
        <DeleteModal
          open={openDeleteModal}
          onClose={() => setOpenDeleteModal(false)}
          tradeHistoryItem={selectedTradeHistory}
          onRefetch={fetchTradeHistory}
        />
      </Box>
    </Modal>
  );
};
