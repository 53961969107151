import { parse } from 'date-fns';
import { useCallback, useEffect, useState } from 'react';
import { useDateQueryParam, useFetchStaffingLists, useSelectedBattalionPreference } from '../../../hooks';
import { useAuthUserCapabilities } from '../../../utils/capabilities';
import { useLoadedAuthUserContext } from '../../Auth';
import { useFetchHiringEngineData } from '../../HiringEngine';
import { SnackbarService } from '../../Snackbar';
import { useFetchAdministrationStations } from './useFetchAdministrationStations';
import { useFetchEmployeesOff } from './useFetchEmployeesOff';
import { useFetchEventStation } from './useFetchEventStation';
import { useFetchShiftSummary } from './useFetchShiftSummary';
import { useFetchTemporaryNonShiftAssignments } from './useFetchTemporaryNonShiftEmployees';

export const useFetchRosterData = () => {
  const { state: authUserState } = useLoadedAuthUserContext();
  const capabilities = useAuthUserCapabilities();
  const [selectedDate, setSelectedDate] = useState<string | null | undefined>(null);
  const [selectedDateAsDate, setSelectedDateAsDate] = useState<Date>(new Date());
  const { selectedDate: paramsSelectedDate } = useDateQueryParam();

  const selectedBattalionState = useSelectedBattalionPreference();
  const [selectedBattalionId, setSelectedBattalionId] = selectedBattalionState;

  const { isApparatusLogin } = authUserState.employee;

  const {
    isLoading: isLoadingShiftSummary,
    isError: isErrorShiftSummary,
    data: dataShiftSummary,
    setData: setDataShiftSummary,
    forceRefetch: forceRefetchShiftSummary,
  } = useFetchShiftSummary({
    selectedDate: selectedDate || '',
    battalionId: selectedBattalionId,
    skip: !selectedDate,
  });

  const {
    isLoading: isLoadingAdministrationStations,
    isError: isErrorAdministrationStations,
    data: dataAdministrationStations,
    setData: setDataAdministrationStations,
    forceRefetch: forceRefetchAdministrationStations,
  } = useFetchAdministrationStations({
    selectedDate: selectedDate || '',
    skip: !selectedDate || isApparatusLogin,
  });

  const {
    isLoading: isLoadingTemporaryNonShift,
    isError: isErrorTemporaryNonShift,
    data: dataTemporaryNonShift,
    setData: setDataTemporaryNonShift,
    forceRefetch: forceRefetchTemporaryNonShift,
  } = useFetchTemporaryNonShiftAssignments({
    selectedDate: selectedDate || '',
    skip: !selectedDate || isApparatusLogin,
  });

  const {
    isLoading: isLoadingEventStation,
    isError: isErrorEventStation,
    data: dataEventStations,
    setData: setDataEventStations,
    forceRefetch: forceRefetchEventStation,
  } = useFetchEventStation({
    selectedDate: selectedDate || '',
    skip: !selectedDate || isApparatusLogin,
  });

  const {
    data: dataStaffingLists,
    isError: isErrorStaffingLists,
    forceRefetch: forceRefetchStaffingLists,
  } = useFetchStaffingLists({
    skip: isApparatusLogin || !capabilities.MANAGE_ROSTER_HIRING,
  });

  const {
    isLoading: isLoadingEmployeesOff,
    isError: isErrorEmployeesOff,
    employeesOff: dataEmployeesOff,
    setEmployeesOff: setDataEmployeesOff,
    forceRefetch: forceRefetchEmployeesOff,
  } = useFetchEmployeesOff({
    battalionId: selectedBattalionId,
    skip: isApparatusLogin,
  });

  const { isLoadingHiringEngine, isErrorHiringEngine, requestVolunteers, autoHireInfo, forceRefetchHiringEngine, instantHires } =
    useFetchHiringEngineData({
      selectedDate: selectedDate || '',
      skip: !selectedDate || isApparatusLogin || !capabilities.MANAGE_ROSTER_HIRING,
      battalionId: selectedBattalionId,
    });

  const forceRefetchAll = useCallback((battalionId?: number, resetData = true) => {
    battalionId && setSelectedBattalionId(battalionId);
    forceRefetchShiftSummary(resetData);
    forceRefetchAdministrationStations(resetData);
    forceRefetchEventStation(resetData);
    forceRefetchStaffingLists();
    forceRefetchEmployeesOff(resetData);
    forceRefetchTemporaryNonShift(resetData);
    forceRefetchHiringEngine();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (paramsSelectedDate !== selectedDate) {
      SnackbarService.clearQueue();
      setSelectedDate(() => paramsSelectedDate);
      setSelectedDateAsDate(() => {
        if (!paramsSelectedDate) {
          return new Date();
        }
        return parse(paramsSelectedDate, 'MM/dd/yyyy', new Date());
      });
      setDataShiftSummary(null);
      setDataAdministrationStations(null);
      setDataTemporaryNonShift(null);
      setDataEventStations(null);
      setDataEmployeesOff(null);
    }
  }, [
    paramsSelectedDate,
    selectedDate,
    setDataShiftSummary,
    setDataAdministrationStations,
    setDataTemporaryNonShift,
    setDataEventStations,
    setDataEmployeesOff,
  ]);

  return {
    isLoading:
      isLoadingShiftSummary ||
      isLoadingAdministrationStations ||
      isLoadingEventStation ||
      isLoadingEmployeesOff ||
      isLoadingTemporaryNonShift,
    isError:
      isErrorShiftSummary ||
      isErrorAdministrationStations ||
      isErrorEventStation ||
      isErrorEmployeesOff ||
      isErrorTemporaryNonShift,
    selectedBattalionState,
    isErrorStaffingLists,
    shiftSummary: dataShiftSummary,
    staffingLists: dataStaffingLists,
    requestVolunteers,
    autoHireInfo,
    instantHires,
    isLoadingHiringEngine,
    isErrorHiringEngine,
    forceRefetchAll,
    forceRefetchHiringEngine,
    currentDate: selectedDateAsDate,
    administrationStations: dataAdministrationStations,
    temporaryNonShiftStation: dataTemporaryNonShift,
    eventStations: dataEventStations,
    dataEmployeesOff,
  };
};
