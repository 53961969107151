import { Box, useTheme } from '@mui/material';
import { startOfMonth, lastDayOfMonth, parseISO } from 'date-fns';
import { useDeferredValue, useState } from 'react';
import { asDepartmentDateTime, useLoadedDepartmentInfoContext, SearchInput, Button } from '@stationwise/component-module';
import { ShiftPlanAction, ShiftPlanTeam } from '@stationwise/share-types';
import { MonthDays, MonthSelector, renderMonthDayOverlay, renderToday } from '../Calendar';
import { BorderedBoxes } from '../Grid';
import { useShiftPlanContext } from '../ShiftPlanContext';
import { StaffingPatternDialog } from './StaffingPatternDialog/index';
import { StaffingPatternRow } from './StaffingPatternRow';

export const StaffingPatternsPanel = () => {
  const theme = useTheme();
  const { state: departmentInfoState } = useLoadedDepartmentInfoContext();
  const { teams, setSelectedAction } = useShiftPlanContext();
  const [selectedShiftPlanTeam, setSelectedShiftPlanTeam] = useState<ShiftPlanTeam | null>(null);

  const [selectedMonth, setSelectedMonth] = useState(startOfMonth(asDepartmentDateTime(departmentInfoState, new Date())));
  const deferredSelectedMonth = useDeferredValue(selectedMonth);
  const [searchValue, setSearchValue] = useState('');

  const selectShiftPlanTeam = (team: ShiftPlanTeam) => {
    setSelectedShiftPlanTeam(team);
    setSelectedAction(ShiftPlanAction.UPSERT_STAFFING_PATTERN);
  };

  const onCreatePatternClick = () => {
    setSelectedShiftPlanTeam(null);
    setSelectedAction(ShiftPlanAction.UPSERT_STAFFING_PATTERN);
  };

  return (
    <Box
      className={selectedMonth !== deferredSelectedMonth ? 'SWShiftPlan-switching-month' : ''}
      sx={{ flex: 1, minWidth: '1px', overflowX: 'auto' }}
    >
      <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%', minWidth: '1150px', pt: 1.5, pb: 2, pl: 2, pr: 2 }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Box sx={{ mt: 2, mb: 2, width: '300px' }}>
            <SearchInput value={searchValue || ''} setValue={setSearchValue} color="white" placeHolder="Search pattern name" />
          </Box>
          <Box sx={{ pt: 2 }}>
            <Button
              data-cy="create-pattern-button"
              sx={{ borderRadius: '8px' }}
              variant="contained"
              size="medium"
              onClick={onCreatePatternClick}
            >
              + Create pattern
            </Button>
          </Box>
        </Box>
        <Box
          sx={{
            borderTop: `1px solid ${theme.palette.divider}`,
            borderLeft: `1px solid ${theme.palette.divider}`,
            borderBottom: `1px solid ${theme.palette.divider}`,
            borderRadius: `${theme.shape.borderRadius}px`,
            flex: 1,
            overflow: 'hidden',
            mt: 1.5,
          }}
        >
          <Box sx={{ background: theme.palette.common.white, position: 'sticky', top: 0, left: 0, width: '100%', zIndex: 1 }}>
            <BorderedBoxes sx={{ borderTop: 0, borderLeft: 0 }}>
              <Box sx={{ flex: 1 }}></Box>
              <Box sx={{ justifyContent: 'center', px: 1.5, width: '700px', minWidth: '60%' }}>
                <MonthSelector value={selectedMonth} onChange={(newValue) => setSelectedMonth(newValue)} />
              </Box>
            </BorderedBoxes>
            <BorderedBoxes sx={{ borderLeft: 0, borderBottom: 0 }}>
              <Box sx={{ flex: 0.5, px: 1.5, typography: 'bodySMedium' }}>Team</Box>
              <Box sx={{ flex: 0.5, width: '100px', px: 1.5, typography: 'bodySMedium' }}>Start date</Box>
              <BorderedBoxes sx={{ border: 0, width: '700px', minWidth: '60%' }}>
                <MonthDays month={selectedMonth} renderOverlay={renderMonthDayOverlay} renderToday={renderToday} />
              </BorderedBoxes>
            </BorderedBoxes>
          </Box>
          {teams
            .filter((team) => parseISO(team.patternStartDate) <= lastDayOfMonth(selectedMonth))
            .filter((team) => !searchValue.trim() || team.name.toLowerCase().includes(searchValue.toLowerCase()))
            .map((team) => (
              <Box
                onClick={() => selectShiftPlanTeam(team)}
                key={team.id}
                sx={{
                  cursor: 'pointer',
                  '& > div': { borderLeft: 0 },
                  '& > div:last-of-type': { borderBottom: 0 },
                }}
              >
                <StaffingPatternRow team={team} month={deferredSelectedMonth} />
              </Box>
            ))}
        </Box>
      </Box>
      <StaffingPatternDialog
        selectedShiftPlanTeam={selectedShiftPlanTeam}
        initialMonth={selectedMonth}
        currentShiftPlanTeamNames={teams.map((team) => team.name.toLowerCase().trim())}
      />
    </Box>
  );
};
