import { Box } from '@mui/material';
import { LocalizationProvider, MobileDatePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import { format, isAfter, isBefore, isSameDay } from 'date-fns';
import { useState } from 'react';
import { ChevronDownIcon20, TimePicker } from '@stationwise/component-module';
import { useIOSPaddingBottom } from '../../../../../context/DeviceInfoContext';

interface DutyFormTimeSelectProps {
  handleTime: (value: Date) => void;
  label?: string;
  minTime: Date;
  maxTime: Date;
  manualTime: Date;
}

export const DutyFormTimeSelect = ({ minTime, maxTime, handleTime, manualTime, label = 'test' }: DutyFormTimeSelectProps) => {
  const [openTimeSelect, setOpenTimeSelect] = useState(false);
  const iosWebViewPaddingBottom = useIOSPaddingBottom();

  const handleDateSelect = (value: Date) => {
    handleTime(value);
  };

  return (
    <>
      <Box
        sx={(theme) => ({
          p: theme.spacing(2),
          mt: theme.spacing(2),
          typography: 'bodyLMedium',
        })}
      >
        {label}
      </Box>
      <Box
        sx={(theme) => ({
          display: 'flex',
          px: theme.spacing(2),
          width: '100%',
        })}
      >
        <Box>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <MobileDatePicker
              sx={{
                '& .MuiInputBase-input': {
                  typography: 'bodySRegular',
                },
                '& .MuiOutlinedInput-notchedOutline': {
                  boxShadow: 'none',
                },
              }}
              value={manualTime}
              shouldDisableDate={(day) => {
                if (minTime && isSameDay(day, minTime)) return false;
                if (minTime && isBefore(day, minTime)) return true;
                if (maxTime && isAfter(day, maxTime)) return true;
                return false;
              }}
              onChange={(value) => value && handleDateSelect(value)}
            />
          </LocalizationProvider>
        </Box>
        <Box
          sx={(theme) => ({
            border: `1px solid ${theme.palette.gray[200]}`,
            borderRadius: theme.spacing(1),
            p: '10px 13px 10px',
            width: '50%',
            cursor: 'pointer',
            marginLeft: theme.spacing(2),
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            typography: 'bodySRegular',
            '& svg': {
              color: theme.palette.gray[400],
            },
          })}
          onClick={() => setOpenTimeSelect(true)}
        >
          <Box sx={(theme) => ({ mr: theme.spacing(2) })}>{format(manualTime.toString(), 'HHmm')}</Box>
          <ChevronDownIcon20 />
        </Box>
      </Box>

      <TimePicker
        openTimeSelect={openTimeSelect}
        setOpenTimeSelect={setOpenTimeSelect}
        handleTime={(value: Date) => {
          setOpenTimeSelect(false);
          handleTime(value);
        }}
        paddingBottom={iosWebViewPaddingBottom}
        defaultValue={manualTime}
        minTime={minTime && isSameDay(manualTime, minTime) ? minTime : undefined}
      />
    </>
  );
};
