import { Box, BoxProps } from '@mui/material';

type ErrorBannerProps = BoxProps<'div'>;

export const ErrorBanner = ({ children, sx, ...props }: ErrorBannerProps) => (
  <Box
    {...props}
    data-cy="error-banner"
    sx={[
      (theme) => ({
        backgroundColor: theme.palette.rose[100],
        border: `1px solid ${theme.palette.rose[200]}`,
        borderRadius: '8px',
        color: theme.palette.rose[700],
        p: 1.5,
        typography: 'bodySRegular',
      }),
      ...(Array.isArray(sx) ? sx : sx ? [sx] : []),
    ]}
  >
    {children}
  </Box>
);
