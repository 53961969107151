import { Box, Typography } from '@mui/material';
import { captureException } from '@sentry/react';
import { differenceInHours } from 'date-fns';
import { useState } from 'react';
import { SuccessIcon } from '@stationwise/component-module';
import { ConversationInput, ConversationListView } from '@stationwise/share-types';
import { useAudience } from '../../hooks/useAudience';
import { ChipProps } from './Chip';
import { Filters } from './Filters';
import { InfoBannerAdapter } from './InfoBannerAdapter';
import { Title } from './Title';
import { UserMessageStatus } from './UserMessageStatus';

export const Audience = ({
  closeAudience,
  conversation,
  handleCreateConversation,
  refetchConversations,
}: {
  closeAudience: () => void;
  conversation: ConversationListView;
  handleCreateConversation: (conversation: ConversationInput) => Promise<ConversationListView | null>;
  refetchConversations: () => void;
}) => {
  // for now we are allowing multiple resends
  // const hasBeenResent = differenceInHours(conversation.messages[0].createdAt, conversation.messages[0].sentAt) > 24;

  // we are disabling buttons if the conversation is cancelled or if the last message was sent less than 24 hours ago
  const disableButton = conversation.cancelledAt !== null || differenceInHours(new Date(), conversation.messages[0].sentAt) <= 24;
  const [isResending, setIsResending] = useState<boolean>(false);
  const [resendRecipients, setResendRecipients] = useState<number>(0);
  const [showSuccessBanner, setShowSuccessBanner] = useState<boolean>(false);
  const [clickedButton, setClickedButton] = useState<string | null>(null);

  const handleResend = async (buttonType: string, recipients: string[]) => {
    try {
      setIsResending(true);
      setClickedButton(buttonType);
      const newConversation: ConversationInput = {
        subject: `[Resend] ${conversation.subject}`,
        link: conversation.link,
        category: conversation.category,
        status: 'Sent',
        messages: [...conversation.messages].reverse().map((message) => ({
          body: message.body,
        })),
        recipients: recipients.map((id) => ({ employee: Number(id) })),
      };
      await handleCreateConversation(newConversation);
      setResendRecipients(recipients.length);
      setShowSuccessBanner(true);
      setTimeout(() => {
        setResendRecipients(0);
        setShowSuccessBanner(false);
      }, 3000);

      refetchConversations();
    } catch (error) {
      captureException(error);
    } finally {
      setIsResending(false);
      setClickedButton(null);
    }
  };

  const { messages, searchValue, setSearchValue, filters, prompts } = useAudience({
    recipients: conversation.recipients,
    isToDo: conversation.category === 'To-Do',
    handleResend: handleResend,
  });
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
      }}
    >
      <Title title="Audience" onClick={closeAudience} />
      <Box
        sx={(theme) => ({
          borderBottom: `1px solid ${theme.palette.gray[100]}`,
        })}
      >
        {showSuccessBanner && (
          <Box
            sx={(theme) => ({
              display: 'flex',
              justifyContent: 'center',
              alignContent: 'center',
              alignItems: 'center',
              gap: '12px',
              backgroundColor: theme.palette.green[100],
              padding: '12px 24px',
            })}
          >
            <Box sx={{ height: '20px', width: '20px' }}>
              <SuccessIcon />
            </Box>
            <Box>
              <Typography
                sx={(theme) => ({
                  color: theme.palette.green[700],
                  typography: 'bodySRegular',
                })}
              >
                {`The message has been re-sent to ${resendRecipients} people`}
              </Typography>
            </Box>
          </Box>
        )}
        {!showSuccessBanner &&
          prompts.map((prompt) => (
            <InfoBannerAdapter
              key={prompt.type}
              type={prompt.type}
              amount={prompt.amount}
              onClick={prompt.onClick}
              disableButton={disableButton}
              isLoading={isResending && clickedButton === prompt.type}
            />
          ))}
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          padding: '24px',
        }}
      >
        <Filters searchValue={searchValue} setSearchValue={setSearchValue} filters={filters} />
        <Box
          sx={(theme) => ({
            display: 'flex',
            flexDirection: 'column',
            gap: theme.spacing(1),
          })}
        >
          {messages.map((message) => (
            <UserMessageStatus
              key={message.id}
              name={message.employee.firstName}
              email={message.employee.email}
              userImg={message.employee.image}
              type={message.status as ChipProps['type']}
              color={message.color}
            />
          ))}
        </Box>
      </Box>
    </Box>
  );
};
