import Box from '@mui/material/Box';
import { useState } from 'react';
import { Manager, MANAGER_NAMES } from '@stationwise/share-types';
import { EditManagerInfo } from './Edit/EditManagerInfo';
import { InfoCard, InfoCardField } from './InfoCard';

type ManagersSectionProps = {
  managerOne: Manager;
  managerTwo: Manager;
  managerThree: Manager;
};

export const ManagersSection = (props: ManagersSectionProps) => {
  const [showModal, setShowModal] = useState(false);
  const fieldDefs: InfoCardField[][] = [];

  const handleEditModal = () => {
    setShowModal(true);
  };

  const managersSection: InfoCardField[] = [
    getManagerField(MANAGER_NAMES.MANAGER_ONE, props.managerOne),
    getManagerField(MANAGER_NAMES.MANAGER_TWO, props.managerTwo),
    getManagerField(MANAGER_NAMES.MANAGER_THREE, props.managerThree),
  ];

  fieldDefs.push(managersSection);
  return (
    <>
      <EditManagerInfo
        showModal={showModal}
        setShowModal={setShowModal}
        managerOne={props.managerOne}
        managerTwo={props.managerTwo}
        managerThree={props.managerThree}
      />
      <Box
        width="100%"
        sx={(theme) => ({
          backgroundColor: theme.palette.common.white,
          borderRadius: theme.spacing(2),
          border: `1px solid ${theme.palette.gray[200]}`,
          p: theme.spacing(4, 3, 3, 3),
          gap: theme.spacing(3),
        })}
      >
        <InfoCard fieldDefs={fieldDefs} title="Managers" handleEditModal={handleEditModal} />
      </Box>
    </>
  );
};

const getManagerField = (title: string, manager: Manager) => {
  const field: InfoCardField = {
    label: title,
    field: {
      type: 'custom',
      extra: {
        custom: (
          <Box
            sx={(theme) => ({
              display: 'flex',
              gap: theme.spacing(1),
              flexWrap: 'wrap',
            })}
          >
            <Box
              display="flex"
              sx={(theme) => ({
                gap: theme.spacing(1),
                alignItems: 'center',
              })}
            >
              <Box sx={{ typography: 'bodyMRegular', fontWeight: 500 }}>{manager?.name ?? 'None'}</Box>
            </Box>
          </Box>
        ),
      },
    },
  };
  return field;
};
