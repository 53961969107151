import { Box } from '@mui/material';
import { HireBackCard } from '@stationwise/component-module';
import { DetailFieldsStaffingList, StaffingListItem, TimePreferenceChoice } from '@stationwise/share-types';

export const OvertimeList = ({ overtimeInfo }: { overtimeInfo: DetailFieldsStaffingList }) => {
  const getStatusInfo = (item: StaffingListItem) => {
    if (!item.employee.timePreference || item.employee.timePreference === TimePreferenceChoice.ANY) {
      return;
    }

    let timePreference = '';

    if (item.employee.timePreference === TimePreferenceChoice.ONLY_PARTIAL) {
      timePreference = 'Only partial shifts';
    } else if (item.employee.timePreference === TimePreferenceChoice.ONLY_FULL) {
      timePreference = 'Only full shifts';
    }
    return (
      <Box component="span" sx={{ typography: 'bodySRegular', whiteSpace: 'pre' }}>
        {timePreference}
      </Box>
    );
  };

  return (
    <>
      {overtimeInfo.items.map((item) => (
        <Box
          key={item.employee.id}
          sx={(theme) => ({
            boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.05)',
            border: `solid 1px ${theme.palette.gray[50]}`,
          })}
        >
          <HireBackCard item={item} type="default" statusInfo={getStatusInfo(item)} />
        </Box>
      ))}
    </>
  );
};
