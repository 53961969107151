import { Box, Typography, Checkbox } from '@mui/material';
import { format } from 'date-fns';
import { useEffect, useState } from 'react';
import {
  HiringEngineEmployeeInfoCard,
  HiringEngineHeader,
  HiringEngineStaffingListController,
  HiringEngineVacancyTable,
  Button,
} from '@stationwise/component-module';
import { EligibleEmployeeForHiringEngine, HiringEngineVacancy, HiringEngineSettingsInfo, Rank } from '@stationwise/share-types';
import { DayRankCallOrderModal } from '../DayRankCallOrderModal';
import { VacancyDayFillOrderModal } from '../VacancyDayFillOrderModal';
import { VacancyPositionController } from '../components/VacancyPositionController';

export interface DayAutoHireProps {
  groupedVacancies: Record<number, HiringEngineVacancy[]>;
  onClose: () => void;
  selectedDate: Date;
}

export const DayAutoHire = ({ groupedVacancies, onClose, selectedDate }: DayAutoHireProps) => {
  const [selectedVacancies, setSelectedVacancies] = useState<string[]>([]);
  const [filteredVacancies, setFilteredVacancies] = useState<Record<number, HiringEngineVacancy[]>>(groupedVacancies);
  const [isAutoHireActive, setIsAutoHireActive] = useState(false);
  const [isVacancyFillModalOpen, setVacancyFillModalOpen] = useState(false);
  const initialVacancyRankOrder = Object.keys(groupedVacancies).map(Number);
  const [isDayRankCallOrderModalOpen, setDayRankCallOrderModalOpen] = useState(false);
  const [selectedVacancyRank, setSelectedVacancyRank] = useState<Rank | null>(null);

  //mock hiring engine settings info, remove this when api is implemented
  const [hiringEngineSettingsInfo, setHiringEngineSettingsInfo] = useState<HiringEngineSettingsInfo | null>({
    autoHireDefaultRankProcessOrder: initialVacancyRankOrder,
    hiringEngineDefaultRankCallOrders: {},
    autoHireDefaultNotificationInterval: 30,
  });

  const onStartClick = () => {
    //send api request to start auto hire
    setIsAutoHireActive(true);
  };

  const onStopClick = () => {
    //send api request to stop auto hire
    setIsAutoHireActive(false);
  };

  const onEditCallOrderClick = (rank: Rank) => {
    setSelectedVacancyRank(rank);
    setDayRankCallOrderModalOpen(true);
  };

  const mockEmployeeInfo = (
    <HiringEngineEmployeeInfoCard
      employee={mockEligibleEmployee}
      startDate={format(selectedDate, 'MMM dd, HH:mm')}
      endDate={format(selectedDate, 'MMM dd, HH:mm')}
    />
  );

  useEffect(() => {
    setSelectedVacancies((prev) => {
      return prev.filter((selectedId) => {
        const [vacancyId] = selectedId.split('_');
        return Object.values(filteredVacancies).some((vacancies) => vacancies.some((vacancy) => vacancy.id === vacancyId));
      });
    });
  }, [filteredVacancies]);

  const handleVacancySelect = (vacancy: Pick<HiringEngineVacancy, 'id' | 'startDateTime'>) => {
    setSelectedVacancies((prev) => {
      const combinedId = `${vacancy.id}_${vacancy.startDateTime.getTime()}`;

      if (prev.includes(combinedId)) {
        return prev.filter((id) => id !== combinedId);
      }
      return [...prev, combinedId];
    });
  };

  const handleSelectAll = (selected: boolean, groupKey: string) => {
    const rankId = Number(groupKey);
    if (selected) {
      const groupVacancyIds = filteredVacancies[rankId].map(
        (vacancy: HiringEngineVacancy) => `${vacancy.id}_${vacancy.startDateTime.getTime()}`,
      );
      setSelectedVacancies((prev) => {
        const otherRankSelections = prev.filter((id) => {
          const [vacancyId] = id.split('_');
          return !groupedVacancies[rankId].some((v) => v.id === vacancyId);
        });
        return [...otherRankSelections, ...groupVacancyIds];
      });
    } else {
      const groupVacancyIds = new Set(
        filteredVacancies[rankId].map((vacancy: HiringEngineVacancy) => `${vacancy.id}_${vacancy.startDateTime.getTime()}`),
      );
      setSelectedVacancies((prev) => prev.filter((id) => !groupVacancyIds.has(id)));
    }
  };

  return (
    <Box
      sx={{
        flex: 2,
        bgcolor: 'background.paper',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <HiringEngineHeader icon={'autoHire'} label="Auto Hire" onClose={onClose} onStart={onStartClick} onStop={onStopClick} />
      <Box
        sx={{
          paddingX: 3,
          flex: 1,
          overflowY: 'auto',
          paddingBottom: 10,
        }}
      >
        <HiringEngineStaffingListController selectedDate={selectedDate} isDisabled={isAutoHireActive} />

        <VacancyPositionController
          isDisabled={isAutoHireActive}
          groupedVacancies={groupedVacancies}
          onVacanciesFiltered={setFilteredVacancies}
        />

        <Box
          sx={{
            height: '78px',
            paddingY: 2,
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            borderBottom: '1px solid #E0E0E0',
          }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, paddingLeft: 2 }}>
            <Checkbox
              disabled={isAutoHireActive}
              checked={Object.values(filteredVacancies).every((vacancies) =>
                vacancies.every((vacancy) => selectedVacancies.includes(`${vacancy.id}_${vacancy.startDateTime.getTime()}`)),
              )}
              onChange={(e) => {
                const isChecked = e.target.checked;
                if (isChecked) {
                  const allVacancyIds = Object.values(filteredVacancies).flatMap((vacancies) =>
                    vacancies.map((vacancy) => `${vacancy.id}_${vacancy.startDateTime.getTime()}`),
                  );
                  setSelectedVacancies(allVacancyIds);
                } else {
                  setSelectedVacancies([]);
                }
              }}
            />
            <Typography variant="bodyMRegular">
              {' '}
              Select All({Object.keys(filteredVacancies).length}/{Object.keys(filteredVacancies).length})
            </Typography>
          </Box>
          <Button
            variant="outlined"
            disabled={isAutoHireActive}
            sx={{
              height: '42px',
              padding: ' 6px 16px',
              typography: 'buttonMedium',
              textTransform: 'none',
            }}
            onClick={() => setVacancyFillModalOpen(true)}
          >
            Change Hiring Engine Settings
          </Button>
        </Box>

        {Object.entries(filteredVacancies).map(([key, vacancies], index) => {
          const mockLastColumnValues = vacancies.map(() => mockEmployeeInfo);
          return (
            <HiringEngineVacancyTable
              key={key}
              vacancyGroupNumber={index + 1}
              groupedVacancies={vacancies}
              checkDisabled={isAutoHireActive}
              lastColumnValues={mockLastColumnValues}
              onVacancySelect={handleVacancySelect}
              onSelectAllRankGroup={(selected) => handleSelectAll(selected, key)}
              selectedVacancies={selectedVacancies}
              hideCheckbox={false}
              onEditCallOrderClick={onEditCallOrderClick}
            />
          );
        })}
      </Box>

      {isVacancyFillModalOpen && hiringEngineSettingsInfo && (
        <VacancyDayFillOrderModal
          open={isVacancyFillModalOpen}
          onClose={() => setVacancyFillModalOpen(false)}
          hiringEngineSettingsInfo={hiringEngineSettingsInfo}
          setHiringEngineSettingsInfo={setHiringEngineSettingsInfo}
        />
      )}
      {isDayRankCallOrderModalOpen && hiringEngineSettingsInfo && selectedVacancyRank && (
        <DayRankCallOrderModal
          open={isDayRankCallOrderModalOpen}
          onClose={() => setDayRankCallOrderModalOpen(false)}
          hiringEngineSettingsInfo={hiringEngineSettingsInfo}
          setHiringEngineSettingsInfo={setHiringEngineSettingsInfo}
          selectedVacancyRank={selectedVacancyRank}
        />
      )}
    </Box>
  );
};

const mockEligibleEmployee: EligibleEmployeeForHiringEngine = {
  id: 'emp-123',
  rank: {
    id: 1,
    name: 'Captain',
    code: 'CPT',
    color: 'blue',
    isOfficer: false,
    sortOrder: 1,
    payPeriodType: 'monthly',
  },
  name: 'John Smith',
  certifications: [
    {
      id: 1,
      name: 'EMT',
      code: 'EMT',
      color: 'green',
      childCertifications: [],
    },
    {
      id: 2,
      name: 'Paramedic',
      code: 'PM',
      color: 'red',
      childCertifications: [],
    },
  ],
  plannedShiftDetails: [
    {
      station: 'Station 1',
      apparatus: 'Engine 1',
      shiftColor: 'blue',
      teamName: 'A Shift',
    },
  ],
};
