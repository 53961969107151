import { Box } from '@mui/material';
import { captureException } from '@sentry/react';
import { useState } from 'react';
import {
  formatPhoneNumber,
  GenericDrawerOrModal,
  PhoneInput,
  SnackbarService,
  unformatPhoneNumber,
} from '@stationwise/component-module';
import { client } from '@stationwise/share-api';
import { VerifyPhoneNumberDrawer } from './VerifyPhoneNumber';

interface EditPhoneNumberDrawerProps {
  drawerOpen: boolean;
  onClose: () => void;
  phoneNumber: string | null;
  setPhoneNumber: (value: string | null) => void;
}

export const EditPhoneNumberDrawer = ({ drawerOpen, phoneNumber, onClose, setPhoneNumber }: EditPhoneNumberDrawerProps) => {
  const [phoneValue, setPhoneValue] = useState(phoneNumber ? phoneNumber : '');
  const disabled = !(phoneValue?.length === 10 || phoneValue?.length === 0) || phoneValue === unformatPhoneNumber(phoneNumber);

  const [openVerifyPhoneNumberDrawer, setOpenVerifyPhoneNumberDrawer] = useState(false);

  const handleSendVerificationCode = async () => {
    try {
      await client.post('/employee/verify-otp/send-code/', {
        phone: formatPhoneNumber(phoneValue),
      });
      setOpenVerifyPhoneNumberDrawer(true);
    } catch (error) {
      SnackbarService.notify({
        content: 'Failed to send confirmation code. Please try again.',
        severity: 'error',
        duration: 5000,
      });
      captureException(error);
    }
  };

  return (
    <>
      <GenericDrawerOrModal
        anchor={'bottom'}
        drawerOpen={drawerOpen}
        handleOnClose={onClose}
        loading={false}
        headerTitle={'Edit Phone Number'}
        showHeader={true}
        disableFooter={false}
        footerButtons={[
          {
            label: 'Send Confirmation Code',
            onClick: handleSendVerificationCode,
            disabled,
          },
        ]}
        sxProps={{
          '.MuiDrawer-paper': {
            borderBottomLeftRadius: 0,
            borderBottomRightRadius: 0,
            px: 0.5,
          },
        }}
      >
        <Box sx={{ p: 2 }}>
          <PhoneInput handleChange={(value) => setPhoneValue(value)} phone={phoneValue} />
        </Box>
      </GenericDrawerOrModal>
      <VerifyPhoneNumberDrawer
        drawerOpen={openVerifyPhoneNumberDrawer}
        onClose={() => {
          setOpenVerifyPhoneNumberDrawer(false);
          onClose();
        }}
        phoneNumber={phoneValue}
        setPhoneNumber={setPhoneNumber}
      />
    </>
  );
};
