import { Box, Menu } from '@mui/material';
import { format, parseISO } from 'date-fns';
import { useEffect, useMemo, useState } from 'react';
import { LottieLogo, theme, useLoadedDepartmentInfoContext } from '@stationwise/component-module';
import {
  AutoHireInfoView,
  RequestVolunteersDetailsView,
  InstantHireView,
  HiringEngineVacancy,
  VacantStation,
} from '@stationwise/share-types';
import { PUSHER_EVENT_TYPES, PUSHER_UPDATE_MESSAGE, RefreshEventCallback } from '@stationwise/share-utils';
import { AutoHireMain } from './AutoHire/AutoHireMain';
import { HiringEngineServices } from './HiringEngineServices';
import { CreateInstantHireModal } from './InstantHire/CreateInstantHireModal';
import { InstantHireHistory } from './InstantHire/InstantHireHistory';
import { RequestVolunteersEligibleEmployeesMain } from './RequestVolunteers/RequestVolunteersEligibleEmployeesMain';
import { RequestVolunteersHistoryModal } from './RequestVolunteers/RequestVolunteersHistoryModal';
import { RequestVolunteersMain } from './RequestVolunteers/RequestVolunteersMain';

interface HiringEngineModalProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  anchorEl: HTMLElement | null;
  forceRefetch: () => void;
  vacantStations: VacantStation[];
  selectedDate: string | null | undefined;
  selectedBattalionId: number | undefined;
  isDayLockedForHiring: boolean;
  groupedVacancies: Record<number, HiringEngineVacancy[]>;
  hasRosterChanges: boolean;
  hiringEngineResponse: {
    isLoading: boolean;
    requestVolunteers: RequestVolunteersDetailsView[];
    autoHireInfo: AutoHireInfoView | null;
    instantHires: InstantHireView[];
    forceRefetchHiringEngine: () => void;
  };
}

export const HiringEngineModal = ({
  open,
  setOpen,
  anchorEl,
  forceRefetch,
  vacantStations,
  selectedDate,
  selectedBattalionId,
  isDayLockedForHiring,
  groupedVacancies,
  hiringEngineResponse,
  hasRosterChanges,
}: HiringEngineModalProps) => {
  const [requestVolunteersIsOpen, setRequestVolunteersIsOpen] = useState(false);
  const [isRequestVolunteersHistoryModalOpen, setIsRequestVolunteersHistoryModalOpen] = useState(false);
  const [requestVolunteersIsLoading, setRequestVolunteersIsLoading] = useState(false);
  const [showRequestVolunteersEligibleEmployees, setShowRequestVolunteersEligibleEmployees] = useState(false);
  const [autoHireModalOpen, setAutoHireModalOpen] = useState(false);
  const [autoHireIsActive, setAutoHireIsActive] = useState(false);
  const [createInstantHireModalOpen, setCreateInstantHireModalOpen] = useState(false);
  const [instantHireHistoryOpen, setInstantHireHistoryOpen] = useState(false);
  const { state: departmentContext } = useLoadedDepartmentInfoContext();
  const refreshTriggerChannel = departmentContext.refreshTriggerChannel;
  const requestVolunteersIsActive = hiringEngineResponse.requestVolunteers.length > 0;
  const instantHireIsActive = hiringEngineResponse.instantHires.length > 0;
  useEffect(() => {
    const autoHireIsActive = hiringEngineResponse.autoHireInfo && hiringEngineResponse.autoHireInfo.autoHires.length > 0;
    setAutoHireIsActive(autoHireIsActive ? autoHireIsActive : false);
  }, [hiringEngineResponse.autoHireInfo]);
  const onClose = () => {
    setOpen(false);
  };

  const withClose = (handler: () => void) => () => {
    handler();
    onClose();
  };

  const allVacancies = useMemo(
    () =>
      vacantStations.flatMap((station) =>
        station.apparatuses.flatMap((apparatus) =>
          apparatus.vacancies.map((vacancy) => ({
            ...vacancy,
            stationName: station.stationName,
            apparatusName: apparatus.name,
          })),
        ),
      ),
    [vacantStations],
  );

  const HIRING_ENGINE_OPTIONS = [
    {
      title: 'Auto Hire',
      description: 'Employees will receive a notification to choose their assignment. Similar to bidding process.',
      isActive: autoHireIsActive,
      disabled: !autoHireIsActive && (hasRosterChanges || allVacancies.length === 0 || isDayLockedForHiring),
      tooltip: hasRosterChanges
        ? 'You should save your roster changes before starting an auto hire'
        : allVacancies.length === 0
          ? 'There are no vacancies for auto hire'
          : isDayLockedForHiring
            ? 'There is no history for auto hire'
            : '',
      onClick: withClose(() => {
        setAutoHireModalOpen(true);
      }),
    },
    {
      title: 'Instant Hire',
      description: "Instantly assign employees to positions. They'll get a notification to acknowledge. No sign-up needed.",
      isActive: instantHireIsActive,
      disabled: !instantHireIsActive && (hasRosterChanges || allVacancies.length === 0 || isDayLockedForHiring),
      tooltip: hasRosterChanges
        ? 'You should save your roster changes before starting an instant hire'
        : allVacancies.length === 0
          ? 'There are no vacancies for instant hire'
          : isDayLockedForHiring
            ? 'There is no history for instant hire'
            : '',
      onClick: withClose(() => {
        if (!instantHireIsActive) {
          setCreateInstantHireModalOpen(true);
        } else {
          setInstantHireHistoryOpen(true);
        }
      }),
    },
    {
      title: 'Request volunteers',
      description:
        "Employees will receive a notification to sign up for a shift. Once signed up, they'll appear on the volunteer list.",
      isActive: requestVolunteersIsActive,
      disabled: !requestVolunteersIsActive && (hasRosterChanges || allVacancies.length === 0 || isDayLockedForHiring),
      tooltip: hasRosterChanges
        ? 'You should save your roster changes before requesting volunteers'
        : allVacancies.length === 0
          ? 'There are no vacancies for request volunteers'
          : isDayLockedForHiring
            ? 'There is no history for request volunteers'
            : '',
      onClick: withClose(() => {
        if (requestVolunteersIsActive) {
          setIsRequestVolunteersHistoryModalOpen(true);
        } else {
          setRequestVolunteersIsOpen(true);
        }
      }),
    },
  ];

  useEffect(() => {
    if (!refreshTriggerChannel) return;

    const handlePusherUpdate: RefreshEventCallback = async (data) => {
      if (
        data.triggerAll ||
        (data.message === PUSHER_UPDATE_MESSAGE &&
          (!data.data?.shiftDate || format(parseISO(data.data.shiftDate), 'MM/dd/yyyy') === selectedDate))
      ) {
        hiringEngineResponse.forceRefetchHiringEngine();
      }
    };

    const EVENT_TYPES_LISTENED = [PUSHER_EVENT_TYPES.HIRING_ENGINE];

    refreshTriggerChannel.bind_many(EVENT_TYPES_LISTENED, handlePusherUpdate);

    return () => {
      if (refreshTriggerChannel) {
        refreshTriggerChannel.unbind_many(EVENT_TYPES_LISTENED);
      }
    };
  }, [refreshTriggerChannel, hiringEngineResponse, selectedDate]);

  if (!selectedDate || !selectedBattalionId) return;
  return (
    <>
      <Menu
        open={open}
        onClose={onClose}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        style={{ marginTop: theme.spacing(2) }}
      >
        {hiringEngineResponse.isLoading ? (
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
            <LottieLogo height="200px" width="200px" />
          </Box>
        ) : (
          <Box
            sx={(theme) => ({
              display: 'flex',
              flexDirection: 'column',
              p: theme.spacing(2),
              gap: theme.spacing(2),
            })}
          >
            {HIRING_ENGINE_OPTIONS.map((option) => (
              <HiringEngineServices
                key={option.title}
                title={option.title}
                description={option.description}
                isActive={option.isActive}
                onClick={option.onClick}
                disabled={option.disabled}
                tooltip={option.tooltip}
              />
            ))}
          </Box>
        )}
      </Menu>
      {requestVolunteersIsOpen && (
        <RequestVolunteersMain
          isOpen={requestVolunteersIsOpen}
          vacantStations={vacantStations}
          requestVolunteersIsLoading={requestVolunteersIsLoading}
          setRequestVolunteersIsOpen={setRequestVolunteersIsOpen}
          setShowRequestVolunteersEligibleEmployees={setShowRequestVolunteersEligibleEmployees}
        />
      )}
      {isRequestVolunteersHistoryModalOpen && (
        <RequestVolunteersHistoryModal
          modalOpen={isRequestVolunteersHistoryModalOpen}
          setIsRequestVolunteersHistoryModalOpen={setIsRequestVolunteersHistoryModalOpen}
          requestVolunteers={hiringEngineResponse.requestVolunteers}
          setRequestVolunteersIsOpen={setRequestVolunteersIsOpen}
          hasRosterChanges={hasRosterChanges}
          hasVacancies={allVacancies.length > 0}
        />
      )}
      {showRequestVolunteersEligibleEmployees && (
        <RequestVolunteersEligibleEmployeesMain
          selectedDate={selectedDate}
          showRequestVolunteersEligibleEmployees={showRequestVolunteersEligibleEmployees}
          setShowRequestVolunteersEligibleEmployees={setShowRequestVolunteersEligibleEmployees}
          requestVolunteersIsLoading={requestVolunteersIsLoading}
          setRequestVolunteersIsLoading={setRequestVolunteersIsLoading}
          forceRefetch={forceRefetch}
          setRequestVolunteersIsOpen={setRequestVolunteersIsOpen}
        />
      )}
      {autoHireModalOpen && (
        <AutoHireMain
          autoHireModalOpen={autoHireModalOpen}
          setAutoHireModalOpen={setAutoHireModalOpen}
          isDayLockedForHiring={isDayLockedForHiring}
          autoHireInfo={hiringEngineResponse.autoHireInfo}
          groupedVacancies={groupedVacancies}
          forceRefetch={forceRefetch}
          selectedBattalionId={selectedBattalionId}
          selectedDate={selectedDate}
          hasRosterChanges={hasRosterChanges}
          forceRefetchHiringEngine={hiringEngineResponse.forceRefetchHiringEngine}
          isRefetching={hiringEngineResponse.isLoading}
        />
      )}
      {createInstantHireModalOpen && (
        <CreateInstantHireModal
          createInstantHireModalOpen={createInstantHireModalOpen}
          setCreateInstantHireModalOpen={setCreateInstantHireModalOpen}
          allVacancies={allVacancies}
          selectedDate={selectedDate}
          selectedBattalionId={selectedBattalionId}
          defaultRankOrders={hiringEngineResponse.autoHireInfo?.autoHireDefaultRankOrders || null}
          forceRefetch={forceRefetch}
        />
      )}
      {instantHireHistoryOpen && (
        <InstantHireHistory
          open={instantHireHistoryOpen}
          setOpen={setInstantHireHistoryOpen}
          setCreateInstantHireModalOpen={setCreateInstantHireModalOpen}
          instantHires={hiringEngineResponse.instantHires}
          hasVacancies={allVacancies.length > 0}
          hasRosterChanges={hasRosterChanges}
          isRefetching={hiringEngineResponse.isLoading}
        />
      )}
    </>
  );
};
