import { Box } from '@mui/material';
import { format, parseISO } from 'date-fns';
import { EmployeeOffView } from '@stationwise/share-types';
import { shortenEmployeeName } from '@stationwise/share-utils';
import { RequestType } from './RequestType';

export const Header = ({ employeeOffData }: { employeeOffData: EmployeeOffView }) => {
  return (
    <Box
      sx={(theme) => ({
        height: employeeOffData.note ? '71px' : '41px',
        borderBottom: `1px solid ${theme.palette.gray[200]}`,
        typography: 'bodySRegular',
      })}
    >
      <Box display="flex" justifyContent="space-between">
        <Box display="flex" sx={(theme) => ({ mt: theme.spacing(1.5), ml: theme.spacing(2) })}>
          <RequestType employeeOffData={employeeOffData} />
          <Box
            sx={(theme) => ({
              color: theme.palette.gray[900],
              ml: theme.spacing(1),
              fontWeight: 500,
              whiteSpace: 'nowrap',
            })}
          >
            {employeeOffData.title.toUpperCase()}
          </Box>
          <Box
            sx={(theme) => ({
              color: theme.palette.gray[500],
              ml: theme.spacing(1.5),
            })}
          >
            {format(`${employeeOffData.shiftDate}T${employeeOffData.startTime}`, 'HHmm')}
            {' - '}
            {format(`${employeeOffData.shiftDate}T${employeeOffData.endTime}`, 'HHmm')}
          </Box>
        </Box>
        <Box display="flex" sx={(theme) => ({ mt: theme.spacing(1.5), mr: theme.spacing(2) })}>
          <Box sx={(theme) => ({ color: theme.palette.gray[900] })} display="flex">
            <Box>Approved by</Box>
            <Box sx={(theme) => ({ fontWeight: 600, ml: theme.spacing(0.5) })}>
              {shortenEmployeeName(employeeOffData.approvedBy.name)}
            </Box>
          </Box>
          <Box
            sx={(theme) => ({
              color: theme.palette.gray[500],
              ml: theme.spacing(1),
            })}
          >
            {format(parseISO(employeeOffData.approvedAt), 'M/d/yyyy, HHmm')}
          </Box>
        </Box>
      </Box>
      {employeeOffData.note && (
        <Box
          sx={(theme) => ({
            color: theme.palette.gray[500],
            ml: theme.spacing(2),
            mt: theme.spacing(1),
          })}
        >
          {`"${employeeOffData.note}"`}
        </Box>
      )}
    </Box>
  );
};
