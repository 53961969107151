import { Box } from '@mui/material';
import { useDocumentTitle } from '@stationwise/component-module';
import { AppPageLayout } from '../../AppPageLayout';
import { PersonalTabs } from '../PersonalTabs';
import { PersonalStaffingListsMain } from './components/PersonalStaffingListsMain';

export const PersonalStaffingLists = () => {
  useDocumentTitle('My Staffing Lists');

  return (
    <AppPageLayout>
      <PersonalTabs defaultTab="staffingLists">
        <Box sx={{ height: '100%', width: '100%', maxWidth: '768px', margin: '0 auto' }}>
          <PersonalStaffingListsMain />
        </Box>
      </PersonalTabs>
    </AppPageLayout>
  );
};
