import {
  DepartmentInfo,
  ShiftPlanChangeLogAPIData,
  ShiftPlanStation,
  ShiftPlanApparatus,
  ShiftPlanPosition,
  ShiftPlanAssignment,
} from '@stationwise/share-types';
import { initializeAssignments } from './readAssignments';
import { initializeStations } from './readStations';

export const getAction = (originalId: number | string | null, changes: Record<string, string[]>) => {
  if (!originalId) {
    return 'Added';
  } else if (changes[originalId]?.includes('REMOVED')) {
    return 'Removed';
  } else if (changes[originalId]?.length) {
    return 'Edited';
  } else {
    return 'Unchanged';
  }
};

export const getChangeLogStructs = (
  stations: Map<string, ShiftPlanStation>,
  filterStation: (station: ShiftPlanStation) => boolean,
  filterApparatus: (apparatus: ShiftPlanApparatus) => boolean,
  filterPosition: (position: ShiftPlanPosition) => boolean,
) => {
  const structs: ShiftPlanStation[] = [];
  stations.forEach((station) => {
    if (station.stationId === 'floater-station') {
      return;
    }

    const includedApparatusIds = new Set<string>();
    const includedPositionIds = new Set<string>();

    const newStation = { ...station };
    newStation.apparatuses = newStation.apparatuses.map((apparatus) => {
      if (filterApparatus(apparatus)) {
        includedApparatusIds.add(apparatus.id);
      }

      const newApparatus = { ...apparatus };
      newApparatus.positions = newApparatus.positions.map((position) => {
        if (filterPosition(position)) {
          includedApparatusIds.add(apparatus.id);
          includedPositionIds.add(position.id);
        }
        return position;
      });
      newApparatus.positions = newApparatus.positions.filter((p) => includedPositionIds.has(p.id));
      return newApparatus;
    });
    newStation.apparatuses = newStation.apparatuses.filter((a) => includedApparatusIds.has(a.id));

    if (filterStation(station) || includedApparatusIds.size) {
      structs.push(newStation);
    }
  });
  return structs;
};

export const getChangeLogAssignmentGroups = (
  assignments: ShiftPlanAssignment[],
  filterAssignment: (assignment: ShiftPlanAssignment) => boolean,
) => {
  const assignmentGroups = new Map<string, ShiftPlanAssignment[]>();
  assignments.forEach((assignment) => {
    if (filterAssignment(assignment)) {
      const group = assignmentGroups.get(assignment.employee.id) || [];
      group.push(assignment);
      assignmentGroups.set(assignment.employee.id, group);
    }
  });
  return assignmentGroups;
};

export const getChangeLog = (departmentInfo: DepartmentInfo, data: ShiftPlanChangeLogAPIData) => {
  const originalApparatusIds = new Set<string>();
  const originalPositionIds = new Set<string>();
  const draftApparatusIds = new Set<string>();
  const draftPositionIds = new Set<string>();
  [data.originalStations, data.stations].forEach((stations) => {
    const apparatusIds = stations === data.originalStations ? originalApparatusIds : draftApparatusIds;
    const positionIds = stations === data.originalStations ? originalPositionIds : draftPositionIds;
    stations.forEach((station) => {
      station.apparatuses.forEach((apparatus) => {
        apparatusIds.add(`${apparatus.id}`);
        apparatus.positions.forEach((position) => positionIds.add(`${position.id}`));
      });
    });
  });

  const movedApparatusIds = new Set(
    Object.entries(data.changes.apparatuses)
      .filter(([_apparatusId, changedKeys]) => changedKeys.includes('station_id'))
      .map(([apparatusId]) => apparatusId),
  );

  const removedStructs = getChangeLogStructs(
    initializeStations(data.originalStations),
    (station) => getAction(station.stationId, data.changes.stations) === 'Removed',
    (apparatus) => getAction(apparatus.id, data.changes.apparatuses) === 'Removed' || movedApparatusIds.has(apparatus.id),
    (position) => getAction(position.id, data.changes.positions) === 'Removed',
  );
  const upsertedStructs = getChangeLogStructs(
    initializeStations(data.stations),
    (station) => getAction(station.copyOfId, data.changes.stations) !== 'Unchanged',
    (apparatus) => getAction(apparatus.copyOfId, data.changes.apparatuses) !== 'Unchanged',
    (position) => getAction(position.copyOfId, data.changes.positions) !== 'Unchanged',
  );

  const removedAssignmentGroups = getChangeLogAssignmentGroups(
    initializeAssignments(departmentInfo, data.originalAssignments),
    (assignment) => getAction(assignment.id, data.changes.assignments) === 'Removed',
  );
  const upsertedAssignmentGroups = getChangeLogAssignmentGroups(
    initializeAssignments(departmentInfo, data.assignments),
    (assignment) => getAction(assignment.copyOfId, data.changes.assignments) !== 'Unchanged',
  );

  const affectedEmployeeIds = new Set<string>();
  const affectedEmployees: Array<ShiftPlanAssignment['employee']> = [];
  [removedAssignmentGroups, upsertedAssignmentGroups].forEach((groups) => {
    groups.forEach((group) => {
      if (!affectedEmployeeIds.has(group[0].employee.id)) {
        affectedEmployeeIds.add(group[0].employee.id);
        affectedEmployees.push(group[0].employee);
      }
    });
  });
  affectedEmployees.sort((a, b) => a.name.localeCompare(b.name));

  const readonlyEmployeeIds = new Set<string>();
  [data.originalAssignments, data.assignments].forEach((assignments) => {
    const apparatusIds = assignments === data.originalAssignments ? originalApparatusIds : draftApparatusIds;
    const positionIds = assignments === data.originalAssignments ? originalPositionIds : draftPositionIds;
    assignments.forEach((assignment) => {
      const { apparatus, position } = assignment.reference;
      const hasChangedStruct = !!(
        (apparatus?.id && apparatusIds.has(`${apparatus.id}`)) ||
        (position?.id && positionIds.has(`${position.id}`))
      );
      if (affectedEmployeeIds.has(assignment.employee.id) && hasChangedStruct) {
        // If the struct changed, the assignment must be published. The employee cannot be unselected in the Change Log.
        readonlyEmployeeIds.add(assignment.employee.id);
      }
    });
  });

  const hasChanges = !!(
    removedStructs.length ||
    upsertedStructs.length ||
    removedAssignmentGroups.size ||
    upsertedAssignmentGroups.size
  );

  return {
    hasChanges,
    changes: data.changes,
    removedStructs,
    upsertedStructs,
    removedAssignmentGroups,
    upsertedAssignmentGroups,
    affectedEmployees,
    readonlyEmployeeIds,
  };
};
