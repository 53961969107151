import { Box, Typography } from '@mui/material';
import { AutoHireView } from '@stationwise/share-types';
import { theme } from '../../../../component-module';
import { HIRING_ENGINE_ICONS } from '../../constants';

interface HiringEngineBoxProps {
  isSelected: boolean;
  onClick: () => void;
}

interface AutoHireBoxProps extends HiringEngineBoxProps {
  instance: Pick<AutoHireView, 'id' | 'status'>;
}

const hiringEngineBoxStyles = (isSelected: boolean) => ({
  p: 2,
  mb: 0,
  borderRadius: 1,
  cursor: 'pointer',
  bgcolor: 'background.paper',
  border: '1px solid',
  borderColor: isSelected ? 'primary.main' : 'divider',
});

export const AutoHireBox = ({ instance, isSelected, onClick }: AutoHireBoxProps) => {
  const IconComponent = HIRING_ENGINE_ICONS.autoHire;

  return (
    <Box onClick={onClick} sx={hiringEngineBoxStyles(isSelected)}>
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: '12px' }}>
          <Box
            sx={{
              display: 'flex',
              borderRadius: 1,
              height: '40px',
              width: '40px',
              justifyContent: 'center',
              alignItems: 'center',
              backgroundColor: theme.palette.gray[100],
            }}
          >
            <IconComponent />
          </Box>
          <Typography variant="body1">Auto Hire</Typography>
        </Box>
        <Box
          sx={{
            height: '40px',
            maxHeight: '40px',
            borderRadius: '38px',
            padding: '8px 16px',
            backgroundColor: theme.palette.green[600],
            color: theme.palette.common.white,
            typography: 'badgeLabel',
            lineHeight: 'normal',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          {instance.status}
        </Box>
      </Box>
    </Box>
  );
};
