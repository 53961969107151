import { Box, Typography } from '@mui/material';
import { ShiftDetails } from './ShiftDetails';
import { ShiftName } from './ShiftName';

interface RequestVolunteersSignUpTopBarProps {
  shiftName: string;
  shiftColor?: string;
  startDate: string;
  endDate: string;
  startTime: string;
  endTime: string;
  applyBorderStyles: boolean;
}

export const RequestVolunteersSignUpTopBar = ({
  shiftName,
  shiftColor,
  startDate,
  endDate,
  startTime,
  endTime,
  applyBorderStyles = false,
}: RequestVolunteersSignUpTopBarProps) => {
  return (
    <Box
      sx={(theme) => ({
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        alignItems: 'center',
        borderBottomLeftRadius: applyBorderStyles ? theme.spacing(1.5) : theme.spacing(0),
        borderBottomRightRadius: applyBorderStyles ? theme.spacing(1.5) : theme.spacing(0),
        boxShadow: applyBorderStyles ? '0px 4px 6px -1px rgba(10, 14, 22, 0.102)' : 'none',
        backgroundColor: theme.palette.common.white,
      })}
    >
      <Box
        sx={{
          width: '92%',
          mt: '20px',
          mb: '12px',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Box>
          <Typography
            variant="bodyLSemibold"
            sx={(theme) => ({
              color: theme.palette.gray[900],
            })}
          >
            Voluntary overtime sign up
          </Typography>
        </Box>
        <Box>
          <ShiftName shiftName={shiftName} shiftColor={shiftColor} />
        </Box>
      </Box>
      <Box
        sx={{
          width: '92%',
          display: 'flex',
          mb: '12px',
          alignItems: 'flex-start',
        }}
      >
        <ShiftDetails startDate={startDate} endDate={endDate} startTime={startTime} endTime={endTime} />
      </Box>
    </Box>
  );
};
