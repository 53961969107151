import { Box } from '@mui/material';
import { useDocumentTitle } from '@stationwise/component-module';
import { AppPageLayout } from '../../AppPageLayout';
import { PersonalTabs } from '../PersonalTabs';
import { PreferencesContent } from './components/IndexMain';

export const Preferences = () => {
  useDocumentTitle('Preferences');

  return (
    <AppPageLayout>
      <PersonalTabs defaultTab="preferences">
        <Box sx={{ height: '100%', width: '100%', maxWidth: '768px', margin: '0 auto' }}>
          <PreferencesContent />
        </Box>
      </PersonalTabs>
    </AppPageLayout>
  );
};
