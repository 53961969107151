import { Box, Paper } from '@mui/material';
import React from 'react';
import { ChipFilterProps } from '../../ChipFilter';
import { Filters } from './Filters';
import { Footer } from './Footer';
import { Header } from './Header';
import { SelectContactOptionProps } from './Options';
import { SelectContact } from './SelectContact';

interface RecipientsCardProps {
  amountSelected?: number;
  setSearchValue: React.Dispatch<React.SetStateAction<string>>;
  searchValue?: string;
  filters?: ChipFilterProps[];
  onClear?: () => void;
  options: SelectContactOptionProps[];
  isAllSelected?: boolean;
  onClose?: () => void;
  onAllSelect?: () => void;
  onAddRecipients?: () => void;
  selectedFilter?: string;
}

export const RecipientsCard = ({
  amountSelected,
  setSearchValue,
  searchValue,
  filters,
  onClear,
  onClose,
  options,
  isAllSelected,
  onAllSelect,
  onAddRecipients,
  selectedFilter,
}: RecipientsCardProps) => {
  return (
    <Paper
      sx={(theme) => ({
        border: `1px solid ${theme.palette.gray[200]} !important`,
        boxShadow:
          '0px 10px 10px -5px rgba(10, 14, 22, 0.05), 0px 78px 25px -5px rgba(10, 14, 22, 0.05), 0px 32px 21px 0px rgba(10, 14, 22, 0.06)',
        position: 'relative',
      })}
    >
      <Box sx={{ padding: '24px' }}>
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <Box sx={(theme) => ({ mb: theme.spacing(2) })}>
            <Header title="Select contacts" onClose={onClose} />
          </Box>
          <Filters setSearchValue={setSearchValue} searchValue={searchValue} filters={filters} onClear={onClear} />
          <SelectContact
            options={options}
            isAllSelected={isAllSelected}
            onAllSelect={onAllSelect}
            selectedFilter={selectedFilter}
          />
        </Box>
      </Box>
      <Footer onAddRecipients={onAddRecipients} amountSelected={amountSelected} onCloseChip={onClear} />
    </Paper>
  );
};
