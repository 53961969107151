import { Box, Checkbox, Divider, ListItem, Menu, Theme, useMediaQuery } from '@mui/material';
import { useState } from 'react';
import { makeTestIdentifier } from '@stationwise/share-utils';
import { Button } from '../../../Button';
import { useRosterContext } from '../RosterContext';

interface StationsMenuProps {
  anchorEl: null | HTMLElement;
  handleClose: () => void;
}

export const StationsMenu = ({ anchorEl, handleClose }: StationsMenuProps) => {
  const { forceRefetch, preferences } = useRosterContext();
  const [selectedStationMap, setSelectedStationMap] = useState(preferences.selectedStationsMap);
  const isMultiBattalion = preferences.battalions.length > 1;
  const [isBattalionMode, setIsBattalionMode] = useState(isMultiBattalion);
  const open = Boolean(anchorEl);
  const isMobileApp = useMediaQuery((theme: Theme) => theme.breakpoints.down('lg'));

  const getSelectedStations = (battalionId: number) => {
    return selectedStationMap.get(battalionId) || new Map();
  };

  const handleChangeSelectedBattalion = (battalionId: number) => {
    if (battalionId !== preferences.selectedBattalionId) {
      preferences.setSelectedBattalionId(battalionId);
      forceRefetch(battalionId);
    }
  };

  const handleChangeSelectedStation = (battalionId: number, stationName: string, isSelected: boolean) => {
    const selectedStationStates = getSelectedStations(battalionId);
    const newSelectedStationStates = new Map(selectedStationStates);
    newSelectedStationStates.set(stationName, isSelected);
    const newSelectedStationMap = new Map(selectedStationMap);
    newSelectedStationMap.set(battalionId, newSelectedStationStates);
    setSelectedStationMap(newSelectedStationMap);
  };

  const handleEditDefaultView = () => {
    setIsBattalionMode(false);
  };

  const handleCancel = () => {
    setSelectedStationMap(preferences.selectedStationsMap);
    isMultiBattalion ? setIsBattalionMode(true) : handleClose();
  };

  const handleSave = () => {
    preferences.setSelectedStationsMap(selectedStationMap);
    isMultiBattalion ? setIsBattalionMode(true) : handleClose();
  };

  const handleSelectAll = (battalionId: number) => {
    const stationNames =
      preferences.battalions.find((battalion) => battalion.id === battalionId)?.stations.map((station) => station.name) || [];
    const newSelectedStationStates = new Map();
    stationNames.forEach((stationName) => {
      newSelectedStationStates.set(stationName, true);
    });

    const newSelectedStationMap = new Map(preferences.selectedStationsMap);
    newSelectedStationMap.set(battalionId, newSelectedStationStates);
    setSelectedStationMap(newSelectedStationMap);
  };

  const handleUnselectAll = (battalionId: number) => {
    const newSelectedStationMap = new Map(selectedStationMap);
    const stationNames =
      preferences.battalions.find((battalion) => battalion.id === battalionId)?.stations.map((station) => station.name) || [];
    const newSelectedStationStates = new Map();
    stationNames.forEach((stationName) => {
      newSelectedStationStates.set(stationName, false);
    });
    newSelectedStationMap.set(battalionId, newSelectedStationStates);
    setSelectedStationMap(newSelectedStationMap);
  };

  return (
    <Menu
      anchorOrigin={!isMobileApp ? { vertical: 'bottom', horizontal: 'center' } : undefined} // Conditional anchorOrigin
      transformOrigin={!isMobileApp ? { vertical: -8, horizontal: 'center' } : undefined} // Conditional transformOrigin
      open={open}
      onClose={handleClose}
      anchorEl={anchorEl}
      MenuListProps={isMobileApp ? undefined : { component: 'div' }}
      anchorReference={isMobileApp ? 'none' : 'anchorEl'}
      sx={(theme) => ({
        top: isMobileApp ? theme.spacing(9) : theme.spacing(0),
        '& .MuiMenu-paper': isMobileApp
          ? {
              maxWidth: '100%',
              maxHeight: '100%',
              width: '100vw',
              height: '100vh',
              margin: 0,
            }
          : {
              border: `1px solid ${theme.palette.gray[100]}`,
              borderRadius: 4,
              boxShadow: '0px 10px 10px -5px #0A0E160A, 0px 20px 25px -5px #0A0E161A',
              color: theme.palette.gray[900],
              p: 2.5,
            },
        '& .MuiMenu-list': { p: 0 },
        '& .MuiListItem-root': isMobileApp ? {} : { backgroundColor: 'transparent', display: 'block', p: 0 },
      })}
    >
      <Box
        sx={(theme) => ({
          display: isMobileApp ? 'block' : 'flex',
          gap: 2,
          overflow: 'auto',
          mb: isMobileApp ? theme.spacing(9) : theme.spacing(0),
        })}
      >
        {preferences.battalions.map((battalion) => (
          <Box
            key={battalion.id}
            component="ul"
            onClick={isBattalionMode ? () => handleChangeSelectedBattalion(battalion.id) : undefined}
            sx={[
              (theme) => ({
                backgroundColor: isBattalionMode ? undefined : theme.palette.gray[50],
                borderRadius: 2,
                cursor: isBattalionMode ? 'pointer' : undefined,
                listStyle: 'none',
                minWidth: 240,
                p: theme.spacing(1, 0),
                m: isMobileApp ? theme.spacing(2) : 0,
                mt: 0,
              }),
              (theme) => {
                if (isBattalionMode && battalion.id === preferences.selectedBattalionId) {
                  return { border: `2px solid ${theme.palette.gray[900]}` };
                } else if (isBattalionMode && battalion.id !== preferences.selectedBattalionId) {
                  return { border: `2px solid ${theme.palette.gray[100]}` };
                }
                return { border: `2px solid ${theme.palette.gray[200]}` };
              },
            ]}
          >
            <ListItem>
              <Box
                sx={[
                  (theme) => ({ display: 'flex', gap: 1, p: theme.spacing(1.5, 2.5), typography: 'bodyLMedium' }),
                  (theme) => {
                    if (isBattalionMode && battalion.id !== preferences.selectedBattalionId) {
                      return { color: theme.palette.gray[500] };
                    }
                    return {};
                  },
                ]}
              >
                {(() => {
                  const values = Array.from(getSelectedStations(battalion.id).values());
                  const having = values.some((x) => x);
                  const missing = values.some((x) => !x);
                  return (
                    <Checkbox
                      checked={having && !missing}
                      indeterminate={having && missing}
                      disabled={isBattalionMode}
                      onChange={(event) => {
                        event.target.checked || event.target.indeterminate
                          ? handleSelectAll(battalion.id)
                          : handleUnselectAll(battalion.id);
                      }}
                      sx={(theme) => ({
                        color: theme.palette.gray[600],
                        p: 0,
                        '&:hover': { backgroundColor: 'transparent' },
                        '&.Mui-checked': {
                          color: isMobileApp ? theme.palette.purple[600] : theme.palette.blue[600],
                        },
                        '&.Mui-disabled': { color: theme.palette.gray[300] },
                      })}
                    />
                  );
                })()}
                {battalion.name}
              </Box>
              <Divider
                sx={(theme) => ({
                  marginLeft: theme.spacing(2),
                  marginRight: theme.spacing(2),
                })}
              />
            </ListItem>
            {battalion.stations.map((station) => (
              <ListItem key={station.id}>
                <Box
                  component="label"
                  sx={[
                    (theme) => ({
                      cursor: 'pointer',
                      display: 'flex',
                      alignItems: 'center',
                      gap: 1,
                      p: theme.spacing(1.25, 2.5),
                      typography: 'bodyMMedium',
                      userSelect: 'none',
                    }),
                    (theme) => {
                      if (isBattalionMode && battalion.id === preferences.selectedBattalionId) {
                        return { color: theme.palette.gray[700] };
                      } else if (isBattalionMode && battalion.id !== preferences.selectedBattalionId) {
                        return { color: theme.palette.gray[400] };
                      }
                      return {};
                    },
                  ]}
                >
                  <Checkbox
                    checked={(selectedStationMap.get(battalion.id) || new Map()).get(station.name)}
                    disabled={isBattalionMode}
                    disableRipple={true}
                    onChange={(event) => handleChangeSelectedStation(battalion.id, station.name, event.target.checked)}
                    data-cy={`station-select-${makeTestIdentifier(station.name)}`}
                    sx={(theme) => ({
                      color: theme.palette.gray[600],
                      p: 0,
                      '&:hover': { backgroundColor: 'transparent' },
                      '&.Mui-checked': {
                        color: isMobileApp ? theme.palette.purple[600] : theme.palette.blue[600],
                      },
                      '&.Mui-disabled': { color: theme.palette.gray[300] },
                    })}
                  />
                  {station.name}
                </Box>
              </ListItem>
            ))}
          </Box>
        ))}
      </Box>
      <Box
        sx={(theme) => ({
          display: 'flex',
          gap: 2,
          ...(isMobileApp && {
            position: 'fixed',
            width: '100%',
            alignItems: 'center',
            justifyContent: isBattalionMode ? 'center' : 'flex-end',
            height: theme.spacing(9),
            backgroundColor: theme.palette.common.white,
            left: 0,
            bottom: 0,
            boxShadow: '0px -4px 6px -2px rgba(10, 14, 22, 0.05)',
            p: 2,
          }),
          ...(!isMobileApp && { justifyContent: 'flex-end', mt: 2 }),
        })}
      >
        {isBattalionMode && (
          <Button
            color={isMobileApp ? 'secondary' : 'primary'}
            variant={isMobileApp ? 'text' : 'outlined'}
            onClick={handleEditDefaultView}
            data-cy="edit-default-view-button"
          >
            Edit default view
          </Button>
        )}
        {!isBattalionMode && (
          <>
            <Button variant="outlined" onClick={handleCancel} data-cy="cancel-button">
              Cancel
            </Button>
            <Button variant="contained" onClick={handleSave} data-cy="apply-button">
              Save
            </Button>
          </>
        )}
      </Box>
    </Menu>
  );
};
