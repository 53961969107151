import { Alert, Box, Typography } from '@mui/material';
import { Fragment } from 'react';
import {
  RankBadge,
  Button,
  ChevronRightIcon16,
  StatCounter,
  CertBadge,
  Loader,
  useFetchStaffingStats,
  useRosterContext,
} from '@stationwise/component-module';
import { ShiftSummaryBattalion, Stat } from '@stationwise/share-types';

interface StaffingStatsDrawerProps {
  selectedDate: Date;
}

export const StaffingStatsDrawer = ({ selectedDate }: StaffingStatsDrawerProps) => {
  const { preferences, forceRefetch, userHasMadeChanges } = useRosterContext();
  const { isLoading, data: staffingStats } = useFetchStaffingStats({ selectedDate, skip: !selectedDate });

  const handleBattalionChange = (battalionId: number) => {
    if (battalionId !== preferences.selectedBattalionId) {
      preferences.setSelectedBattalionId(battalionId);
      forceRefetch(battalionId);
    }
  };

  return (
    <Box
      sx={{
        py: 3,
        pr: 1,
        pl: 2,
        display: 'flex',
        flexDirection: 'column',
        gap: 1,
      }}
    >
      {isLoading || !staffingStats ? (
        <Box display="flex" alignItems="center" justifyContent="center" sx={{ height: '100%', width: '100%' }}>
          <Loader></Loader>
        </Box>
      ) : (
        staffingStats.map((stats) => (
          <StatsCard
            key={`stats-${stats.battalion.id}`}
            isSelectedBattalion={preferences.selectedBattalionId === stats.battalion.id}
            handleBattalionChange={handleBattalionChange}
            shouldRefresh={userHasMadeChanges}
            battalion={stats.battalion}
            rankStats={stats.rankStats}
            certificationStats={stats.certificationStats}
          ></StatsCard>
        ))
      )}
    </Box>
  );
};

interface StatsCardProps {
  battalion: ShiftSummaryBattalion;
  rankStats: Stat[];
  certificationStats: Stat[];
  isSelectedBattalion: boolean;
  shouldRefresh: boolean;
  handleBattalionChange: (_: number) => void;
}

const StatsCard = ({
  battalion,
  rankStats,
  certificationStats,
  isSelectedBattalion,
  shouldRefresh,
  handleBattalionChange,
}: StatsCardProps) => {
  return (
    <Box
      sx={(theme) => ({
        display: 'flex',
        flexDirection: 'column',
        gap: 1,
        borderWidth: 1,
        borderRadius: 2,
        borderStyle: 'solid',
        borderColor: theme.palette.gray[200],
        p: 1,
        px: 1,
      })}
    >
      <Box
        sx={{
          px: 2,
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Typography variant="bodyMMedium" sx={{ py: 1 }}>
          {battalion.name}
        </Typography>
        {!isSelectedBattalion && battalion.id !== -1 && (
          <Button variant="outlined" size="small" sx={{ gap: 1 }} onClick={() => handleBattalionChange(battalion.id)}>
            Go to battalion
            <ChevronRightIcon16></ChevronRightIcon16>
          </Button>
        )}
        {isSelectedBattalion && shouldRefresh && (
          <Alert severity="warning" sx={{ py: 0.2 }}>
            Does not reflect latest changes.
          </Alert>
        )}
      </Box>
      <Box
        sx={(theme) => ({
          backgroundColor: theme.palette.gray[200],
          height: '1px',
          width: '100%',
        })}
      ></Box>
      <Box
        sx={{
          p: 1,
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'flex-start',
          gap: 3,
        }}
      >
        <StatsTable stats={rankStats} variant="rank"></StatsTable>
        <StatsTable stats={certificationStats} variant="cert"></StatsTable>
      </Box>
    </Box>
  );
};

interface StatsTableProps {
  stats: Stat[];
  variant: 'rank' | 'cert';
}

const StatsTable = ({ stats, variant }: StatsTableProps) => {
  return (
    <Box
      sx={(theme) => ({
        display: 'grid',
        alignItems: 'center',
        gridTemplateColumns: 'min-content auto min-content',
        rowGap: theme.spacing(2),
        columnGap: theme.spacing(1),
        width: '50%',
      })}
    >
      {stats.map((stat) => (
        <Fragment key={`stat-${stat.obj.code}`}>
          {variant === 'cert' ? (
            <CertBadge cert={stat.obj} sx={{ mx: 'auto' }}></CertBadge>
          ) : (
            <RankBadge rank={stat.obj} sx={{ mr: 'auto' }}></RankBadge>
          )}
          <Typography
            component="p"
            variant="bodySRegular"
            sx={{
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              textWrap: 'nowrap',
              mr: 3,
            }}
          >
            {stat.obj.name}
          </Typography>
          <Box sx={{ display: 'inline-flex', ml: 'auto' }}>
            <StatCounter
              amount={stat.amount}
              total={stat.total}
              overrideIcon
              overrideIconToShow={stat.requirementsMet ? 'inGoal' : 'belowGoal'}
            ></StatCounter>
          </Box>
        </Fragment>
      ))}
    </Box>
  );
};
