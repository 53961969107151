import { Box } from '@mui/material';
import { FormEvent, useState } from 'react';
import { To, useNavigate } from 'react-router-dom';
import { CheckIcon24, XIcon24 } from '../../../assets';
import { useDocumentTitle } from '../../../hooks';
import { ResetPasswordResultPageBody } from './ResetPasswordResultPageBody';

interface ResetPasswordResultPageProps {
  isSuccess?: boolean;
  navigate: To;
  tryAgainFunction?: () => void;
  errorMessage?: string;
}

export const ResetPasswordResultPage = ({
  isSuccess,
  navigate,
  tryAgainFunction,
  errorMessage,
}: ResetPasswordResultPageProps) => {
  useDocumentTitle('Reset Password');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const navigateTo = useNavigate();

  const handleSubmit = async () => {
    if (isSubmitting) {
      return;
    }
    setIsSubmitting(true);
    if (tryAgainFunction) {
      tryAgainFunction();
    } else {
      navigateTo(navigate);
    }
    setIsSubmitting(false);
  };

  const handleFormSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    await handleSubmit();
  };

  return (
    <Box
      sx={(theme) => ({
        px: 2,
        py: 4,
        [theme.breakpoints.up('sm')]: {
          py: 6,
        },
      })}
    >
      <Box sx={{ mx: 'auto', width: 328, maxWidth: '100%' }}>
        <Box
          sx={(theme) => ({
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            mx: 'auto',
            mb: 3,
            borderRadius: '50%',
            width: 48,
            height: 48,
            flexShrink: 0,
            border: theme.palette.gray[50],
            background: theme.palette.common.white,
            boxShadow: '0px 2px 4px -1px rgba(10, 14, 22, 0.06), 0px 4px 6px -1px rgba(10, 14, 22, 0.10)',
            color: isSuccess ? theme.palette.teal[500] : theme.palette.rose[500],
            [theme.breakpoints.up('sm')]: {
              width: 80,
              height: 80,
            },
            '& svg': {
              width: 24,
              height: 24,
              [theme.breakpoints.up('sm')]: {
                width: 40,
                height: 40,
              },
            },
          })}
        >
          {isSuccess ? <CheckIcon24 /> : <XIcon24 />}
        </Box>
        <Box
          component="h1"
          sx={(theme) => ({
            color: theme.palette.gray[900],
            mt: 0,
            mb: 2,
            typography: 'heading4',
            textAlign: 'center',
            [theme.breakpoints.up('sm')]: {
              fontSize: '32px',
              mb: 3,
            },
          })}
        >
          {'Password reset'}
        </Box>
        <Box
          component="p"
          data-cy="reset-password-result-message"
          sx={(theme) => ({
            color: theme.palette.gray[600],
            mt: 0,
            mb: 5,
            typography: 'bodyMRegular',
            textAlign: 'center',
            [theme.breakpoints.up('sm')]: {
              mb: 7,
            },
          })}
        >
          {isSuccess
            ? 'Your password has been successfully reset. Click below to log in with your new password.'
            : errorMessage ?? 'An error occurred while trying to reset your password. Please try again'}
        </Box>
        {<ResetPasswordResultPageBody onSubmit={handleFormSubmit} isSubmitting={isSubmitting} isSuccess={isSuccess ?? false} />}
      </Box>
    </Box>
  );
};
