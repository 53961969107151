import { Box } from '@mui/material';
import { RosterApparatus } from '@stationwise/share-types';
import { makeTestIdentifier } from '@stationwise/share-utils';
import { checkIsEvent } from '@stationwise/shift-summary-helper';

interface ApparatusProps {
  apparatus: RosterApparatus;
  children?: React.ReactNode;
  isOver?: boolean;
  actions?: React.ReactNode;
}

export const Apparatus = ({ apparatus, isOver, children, actions }: ApparatusProps) => {
  const isEvent = checkIsEvent(apparatus);

  return (
    <Box
      className="SWApparatus"
      data-cy={`apparatus-${makeTestIdentifier(apparatus.name)}`}
      sx={[
        (theme) => {
          const background = isEvent ? theme.palette.teal[100] : theme.palette.gray[200];
          return {
            background: isOver ? theme.palette.gray[300] : background,
            borderRadius: 2,
            border: `2px solid ${theme.palette.gray[400]}`,
            mb: 1.5,
            p: theme.spacing(1.5, 1),
            opacity: apparatus.isReserved ? 0.5 : 'inherit',
            [theme.breakpoints.up('sm')]: { maxWidth: '275px' },
          };
        },
      ]}
    >
      <Box
        sx={(theme) => ({
          color: theme.palette.gray[600],
          position: 'relative',
          typography: 'bodySSemibold',
          lineHeight: '14px',
          [theme.breakpoints.up('sm')]: { typography: 'buttonS', fontWeight: 400 },
          height: !apparatus.name ? '16px' : 'unset',
        })}
      >
        {apparatus.name.toUpperCase()}
        {actions}
      </Box>
      {children}
    </Box>
  );
};
