import { ReactComponent as InfoSymbol } from '@material-symbols/svg-400/outlined/info.svg';
import { Box, useTheme } from '@mui/material';
import { ReactNode } from 'react';
import { StaffingListItem } from '@stationwise/share-types';
import { shortenEmployeeName } from '@stationwise/share-utils';
import { RankBadge } from '../Badge';
import { BlankCard } from '../BlankCard';
import { CardProps, CardCertBadges, DetailsPopover } from '../Card';
import { SvgIcon } from '../SvgIcon/index';
import { FieldDetails } from './FieldDetails';

type CardPropNames = 'borderColor' | 'type' | 'cardSxProps';

export interface HireBackCardProps extends Pick<CardProps, CardPropNames> {
  item: StaffingListItem;
  statusInfo?: ReactNode;
}

export const HireBackCard = ({ item, type, cardSxProps, borderColor, statusInfo }: HireBackCardProps) => {
  const theme = useTheme();
  const nameLabel = shortenEmployeeName(item.employee.name);
  const relevantFields = item.relevantFields.filter((field) => field.value);

  return (
    <BlankCard
      type={type}
      borderColor={borderColor}
      sxProps={[{ position: 'relative' }, ...(Array.isArray(cardSxProps) ? cardSxProps : cardSxProps ? [cardSxProps] : [])]}
    >
      <Box display="flex" justifyContent="space-between">
        <Box display="flex" flexDirection="column">
          <Box display="flex" alignItems="center">
            {statusInfo && (
              <DetailsPopover
                anchorContent={
                  <SvgIcon component={InfoSymbol} sx={{ fontSize: '26px', mr: 0.5, color: theme.palette.gray[500] }} />
                }
                popoverContent={statusInfo}
              />
            )}
            <button
              type="button"
              style={{
                borderRadius: '50%',
                position: 'relative',
                width: '25px',
                height: '24px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                border: 'none',
                cursor: 'pointer',
                backgroundColor: theme.palette.common.black,
              }}
            >
              <Box
                sx={{
                  position: 'absolute',
                  zIndex: 99,
                }}
              >
                <Box
                  sx={(theme) => ({
                    color: theme.palette.common.white,
                    typography: 'bodyXSRegular',
                  })}
                >
                  {String(item.order)}
                </Box>
              </Box>
            </button>
            <Box mr={1} ml={1}>
              <RankBadge rank={item.employee.rank} rankField="name" sx={{ alignSelf: 'flex-start' }} />
            </Box>
          </Box>
          <Box
            mt={1.5}
            ml={0.5}
            sx={{
              '&:after':
                nameLabel.length > 11
                  ? {
                      position: 'absolute',
                      background: 'linear-gradient(90deg, transparent 2%, white)',
                      width: '15px',
                      height: '30px',
                      marginLeft: '85px',
                      marginTop: '-20px',
                      content: '""',
                    }
                  : '',
            }}
          >
            <Box
              sx={(theme) => ({
                color: theme.palette.gray[900],
                whiteSpace: 'nowrap',
                maxWidth: '95px',
                width: '95px',
                overflow: 'hidden',
                typography: 'bodyMMedium',
              })}
            >
              {nameLabel}
            </Box>
          </Box>
          {item.attributes.availableForAllStations && (
            <Box
              sx={{
                typography: 'bodyXSRegular',
                mt: 0.5,
                ml: 0.5,
                color: theme.palette.gray[700],
              }}
            >
              Available for all stations
            </Box>
          )}
          <Box sx={{ display: 'flex', mt: 1.5, justifyContent: 'space-between' }}>
            <Box
              display="flex"
              gap={0.5}
              sx={{
                width: { xs: '137px', md: '51%' },
                minWidth: { xs: '137px', md: '51%' },
              }}
            >
              <CardCertBadges
                certs={item.employee.certifications}
                sx={{
                  maxWidth: '140px',
                  '&:after': {
                    display: item.employee.certifications.length > 3 ? 'block' : 'none',
                    position: 'absolute',
                    background: 'linear-gradient(90deg, transparent 2%, white)',
                    top: 0,
                    right: 0,
                    width: '55px',
                    height: '100%',
                    content: '""',
                  },
                }}
              />
            </Box>
          </Box>
        </Box>
        <Box display="flex" flexDirection="column" width="50%">
          {relevantFields.slice(0, 3).map((field) => (
            <Box display="flex" justifyContent="space-between" gap={1.5} key={field.label}>
              <Box
                sx={(theme) => ({
                  color: theme.palette.gray[400],
                  typography: 'bodySRegular',
                  mt: 0.5,
                  mr: 1,
                  minWidth: '50%',
                })}
              >
                {field.label}
              </Box>
              <Box
                sx={(theme) => ({
                  color: theme.palette.gray[900],
                  typography: 'bodySRegular',
                  mt: '5px',
                  minWidth: '50%',
                })}
              >
                {field.value}
              </Box>
            </Box>
          ))}
          {relevantFields.length > 3 && <FieldDetails fields={relevantFields} />}
        </Box>
      </Box>
    </BlankCard>
  );
};
