import { Box, useTheme, TextField } from '@mui/material';
import { useState } from 'react';
import { Button, XCloseIcon24 } from '@stationwise/component-module';
import { HIRING_ENGINE_ICONS, HiringEngineIconType } from '../../constants';

interface HiringEngineHeaderProps {
  icon: HiringEngineIconType;
  label: string;
  isLoading?: boolean;
  onStart?: () => void;
  onClose?: () => void;
  onStop?: () => void;
}

export const HiringEngineHeader = ({ icon, label, onStart, onClose, onStop, isLoading = false }: HiringEngineHeaderProps) => {
  const theme = useTheme();
  const IconComponent = HIRING_ENGINE_ICONS[icon];
  const [isStarted, setIsStarted] = useState(false);
  const showButton = onStart !== undefined || onStop !== undefined;

  const handleButtonClick = () => {
    if (isStarted) {
      onStop?.();
      setIsStarted(false);
    } else {
      onStart?.();
      setIsStarted(true);
    }
  };

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: theme.spacing(3),
        borderBottom: `1px solid ${theme.palette.divider}`,
        gap: theme.spacing(2),
      }}
    >
      <Box
        sx={{
          display: 'flex',
          borderRadius: 1,
          minWidth: '56px',
          height: '56px',
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: icon === 'flow' ? theme.palette.blue[600] : '#F2F2F2',
        }}
      >
        <IconComponent />
      </Box>
      <Box sx={{ display: 'flex', width: '100%', flexDirection: 'column', gap: theme.spacing(1) }}>
        <TextField
          sx={{
            width: '100%',
            height: '48px',
            '& .MuiInputBase-input.Mui-disabled': {
              WebkitTextFillColor: theme.palette.common.black,
              typography: 'h5',
            },
          }}
          disabled
          label="Title"
          value={label}
        />
      </Box>

      {showButton && (
        <Button
          variant="contained"
          onClick={handleButtonClick}
          disabled={isLoading}
          sx={{
            height: '56px',
            maxHeight: '56px',
            borderRadius: '56px',
            padding: '12px 32px',
            backgroundColor: isStarted ? theme.palette.red[100] : theme.palette.green[600],
            color: isStarted ? theme.palette.red[800] : theme.palette.common.white,
            typography: 'heading6',
            lineHeight: 'normal',
            '&:hover': {
              backgroundColor: isStarted ? theme.palette.red[200] : theme.palette.green[700],
            },
          }}
        >
          {isStarted ? 'Stop' : 'Start'}
        </Button>
      )}

      <Box
        sx={{
          height: '48px',
          width: '48px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          cursor: 'pointer',
          borderRadius: '50%',
          '&:hover': {
            bgcolor: theme.palette.gray[100],
          },
        }}
        onClick={onClose}
      >
        <XCloseIcon24 />
      </Box>
    </Box>
  );
};
