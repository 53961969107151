import { Box, Checkbox, FormControlLabel, InputAdornment, TextField } from '@mui/material';
import { TotalOvertime } from './TotalOvertime';

interface OvertimeRulesProps {
  showTotalOvertimeFields: boolean;
  isMinHours: boolean;
  isAccHours: boolean;
  overtimeThreshold: number;
  setOvertimeThreshold: (threshold: number) => void;
  handleChangeIsMinHours: () => void;
  handleChangeIsAccHours: () => void;
  daysAgo: number;
  setDaysAgo: (days: number) => void;
  totalOvertimeIntervalStartDates: Date[];
  setTotalOvertimeIntervalStartDates: (intervalStarts: Date[]) => void;
}
export const OvertimeRules = ({
  showTotalOvertimeFields,
  isMinHours,
  isAccHours,
  overtimeThreshold,
  setOvertimeThreshold,
  handleChangeIsMinHours,
  handleChangeIsAccHours,
  daysAgo,
  setDaysAgo,
  totalOvertimeIntervalStartDates,
  setTotalOvertimeIntervalStartDates,
}: OvertimeRulesProps) => {
  return (
    <>
      {showTotalOvertimeFields && (
        <TotalOvertime
          daysAgo={daysAgo}
          setDaysAgo={setDaysAgo}
          totalOvertimeIntervalStartDates={totalOvertimeIntervalStartDates}
          setTotalOvertimeIntervalStartDates={setTotalOvertimeIntervalStartDates}
        />
      )}
      {showTotalOvertimeFields && daysAgo === 0 && totalOvertimeIntervalStartDates.length === 0 && (
        <Box sx={(theme) => ({ ml: 0.5, mt: 1, typography: 'bodyXSRegular', color: theme.palette.red[500] })}>
          {`* Please configure the possible start dates.`}
        </Box>
      )}
      <Box
        sx={{
          display: 'flex',
          mt: 3,
        }}
      >
        <Box sx={(theme) => ({ typography: 'bodyMSemibold', lineHeight: '20px', color: theme.palette.gray[700] })}>
          Overtime threshold
        </Box>

        <Box sx={(theme) => ({ mt: '1px', ml: 0.5, typography: 'bodyXSRegular', color: theme.palette.gray[500] })}>
          {`(How overtime dates are counted)`}
        </Box>
      </Box>
      <Box display="flex" gap={2} sx={{ mt: 1 }}>
        <FormControlLabel
          control={<Checkbox onChange={handleChangeIsMinHours} checked={isMinHours} />}
          label="Min worked hours on date"
        />
        <FormControlLabel
          control={<Checkbox onChange={handleChangeIsAccHours} checked={isAccHours} />}
          label="Min accumulated worked hours"
        />
      </Box>
      {(isMinHours || isAccHours) && (
        <Box
          sx={{
            mt: 2,
            '.MuiFormControl-root': {
              '.MuiInputLabel-root': {
                top: '-5px',
              },
            },
          }}
        >
          <TextField
            label={isAccHours ? 'Acc hours threshold' : 'Min hours threshold'}
            onChange={(e) => setOvertimeThreshold(Number(e.target.value))}
            type="number"
            variant="outlined"
            size="medium"
            fullWidth={true}
            value={overtimeThreshold === 0 ? '' : overtimeThreshold}
            inputProps={{ max: Number.MAX_SAFE_INTEGER }}
            slotProps={{
              input: {
                endAdornment: <InputAdornment position="end">h</InputAdornment>,
                inputMode: 'decimal', // For mobile devices
              },
            }}
          />
        </Box>
      )}
    </>
  );
};
