import { format, isValid } from 'date-fns';
import { useDeferredValue, useCallback, useEffect, useMemo, useState } from 'react';
import { RosterShiftDuration, RosterStrikeTeamApparatus, RosterPosition, StrikeTeamCandidate } from '@stationwise/share-types';
import { assignStrikeTeamEmployee, checkStrikeTeamCandidateMaxConsecutiveHours } from '@stationwise/shift-summary-helper';
import { getCertColors } from '../../../../utils/colors';
import { AssignmentForm } from '../../../Candidate';
import { CandidateCard } from '../../../Candidate/CandidateCard';
import { useLoadedDepartmentInfoContext } from '../../../Department';
import { Filter } from '../../../Filter';
import { AdminSelectItem } from '../../../MultipleSelect';
import { useRosterContext } from '../RosterContext';
import { AssignmentConfirmation } from './AssignmentConfirmation';
import { PayAndDetailCodes } from './PayAndDetailCodes';
import { PerformanceEndDate, PerformanceEndDateOption } from './PerformanceEndDate';
import { StrikeTeamAssignmentTimes } from './StrikeTeamAssignmentTimes';
import { useStrikeTeamCandidates } from './useStrikeTeamCandidates';

interface StrikeTeamAssignmentFormProps {
  apparatus: RosterStrikeTeamApparatus;
  position: RosterPosition | null;
  onClose: () => void;
}

export const StrikeTeamAssignmentForm = (props: StrikeTeamAssignmentFormProps) => {
  const { state: departmentInfoState } = useLoadedDepartmentInfoContext();
  const { shiftSummaryHelper, setShiftSummaryHelper, setUserHasMadeChanges, setLastChanges } = useRosterContext();

  const [{ apparatus, position }] = useState(() => ({ apparatus: props.apparatus, position: props.position }));

  const startDate = format(shiftSummaryHelper.shiftDuration.startTime, 'yyyy-MM-dd');
  const [endDateOption, setEndDateOption] = useState(PerformanceEndDateOption.ROSTER_DATE);
  const endDate = endDateOption === PerformanceEndDateOption.ROSTER_DATE ? startDate : apparatus.deactivationDate;

  const positionStartDateTime = position?.startDateTime || shiftSummaryHelper.shiftDuration.startTime;
  const positionEndDateTime = position?.endDateTime || shiftSummaryHelper.shiftDuration.endTime;
  const [startDateTime, setStartDateTime] = useState<Date | null>(positionStartDateTime);
  const [endDateTime, setEndDateTime] = useState<Date | null>(positionEndDateTime);
  const isDurationValid = !!(
    startDateTime &&
    isValid(startDateTime) &&
    endDateTime &&
    isValid(endDateTime) &&
    startDateTime >= positionStartDateTime &&
    startDateTime <= positionEndDateTime &&
    endDateTime > startDateTime &&
    endDateTime <= positionEndDateTime
  );

  const [hasDutyDayPayCodes, setHasDutyDayPayCodes] = useState(false);
  const [selectedPayCodeOptions, setSelectedPayCodeOptions] = useState<AdminSelectItem[]>([]);
  const [selectedDutyDayPayCodeOptions, setSelectedDutyDayPayCodeOptions] = useState<AdminSelectItem[]>([]);
  const [selectedDetailCodeOptions, setSelectedDetailCodeOptions] = useState<AdminSelectItem[]>([]);

  const mapPayCodeOptions = (options: AdminSelectItem[]) => {
    const codes = new Set(options.map((o) => o.value));
    return departmentInfoState.departmentInfo.payCodes.filter((pc) => codes.has(pc.code));
  };

  const mapDetailCodeOptions = (options: AdminSelectItem[]) => {
    const codes = new Set(options.map((o) => o.value));
    return departmentInfoState.departmentInfo.detailCodes.filter((dc) => codes.has(dc.code));
  };

  const [searchValue, setSearchValue] = useState('');
  const deferredSearchValue = useDeferredValue(searchValue);

  const [selectedCertCodes, setSelectedCertCodes] = useState(new Set(position?.certifications.map((cert) => cert.code) || []));

  const [selectedCandidate, setSelectedCandidate] = useState<StrikeTeamCandidate | null>(null);
  const candidates = useStrikeTeamCandidates({
    strikeTeam: apparatus.strikeTeam,
    position,
    fromDate: startDate,
    toDate: endDate,
    startDateTime: isDurationValid ? startDateTime : null,
    endDateTime: isDurationValid ? endDateTime : null,
    searchValue: deferredSearchValue,
    certCodes: selectedCertCodes,
  });

  const candidateMaxConsecutiveHourErrors = useMemo(() => {
    const map = new Map<number, RosterShiftDuration[]>();
    if (isDurationValid) {
      candidates.data.forEach((candidate) => {
        const hours = checkStrikeTeamCandidateMaxConsecutiveHours(
          shiftSummaryHelper,
          apparatus,
          { startDateTime, endDateTime },
          { id: `${candidate.id}`, maybeConsecutiveWorkDurations: candidate.maybeConsecutiveWorkDurations },
          endDate,
        );
        map.set(candidate.id, hours);
      });
    }
    return map;
  }, [shiftSummaryHelper, apparatus, isDurationValid, startDateTime, endDateTime, endDate, candidates.data]);

  useEffect(() => {
    setSelectedCandidate(null);
  }, [endDate, deferredSearchValue, selectedCertCodes]);

  const [isConfirmationShown, setIsConfirmationShown] = useState(false);

  const [submitting, setSubmitting] = useState(false);
  const canSubmit = !!(
    endDateOption &&
    isDurationValid &&
    selectedPayCodeOptions.length &&
    (!hasDutyDayPayCodes || selectedDutyDayPayCodeOptions.length) &&
    selectedCandidate &&
    !submitting
  );

  const onSelectCandidate = useCallback(
    (id: number) => {
      const selected = candidates.data.find((candidate) => candidate.id === id);
      if (selected) {
        setSelectedCandidate(selected);
      }
    },
    [candidates.data],
  );

  const onConfirmSubmit = () => {
    if (canSubmit) {
      setSubmitting(true);
      const { newShiftSummaryHelper, newEmployee, error } = assignStrikeTeamEmployee(
        shiftSummaryHelper,
        apparatus,
        position,
        selectedCandidate,
        startDateTime,
        endDateTime,
        endDate,
        mapPayCodeOptions(selectedPayCodeOptions),
        hasDutyDayPayCodes ? mapPayCodeOptions(selectedDutyDayPayCodeOptions) : null,
        mapDetailCodeOptions(selectedDetailCodeOptions),
      );
      setShiftSummaryHelper(newShiftSummaryHelper);
      setUserHasMadeChanges(true);
      error && setLastChanges({ shiftSummaryHelper, employee: newEmployee, error });
      props.onClose();
    }
  };

  const onSubmit = () => {
    if (canSubmit) {
      const { blockers, vacancyDates } = selectedCandidate.conflicts;
      const maxConsecutiveHourErrors = candidateMaxConsecutiveHourErrors.get(selectedCandidate.id) || [];
      if (!blockers.length && !maxConsecutiveHourErrors.length && !vacancyDates.length) {
        onConfirmSubmit();
      } else {
        setIsConfirmationShown(true);
      }
    }
  };

  if (canSubmit && isConfirmationShown) {
    return (
      <AssignmentConfirmation
        candidate={selectedCandidate}
        maxConsecutiveHourErrors={candidateMaxConsecutiveHourErrors.get(selectedCandidate.id) || []}
        onCancel={() => setIsConfirmationShown(false)}
        onConfirm={selectedCandidate.conflicts.blockers.length ? undefined : onConfirmSubmit}
      />
    );
  }

  const aboveSearchContent = (
    <>
      <PerformanceEndDate apparatus={apparatus} value={endDateOption} onChange={setEndDateOption} />
      <StrikeTeamAssignmentTimes
        isDurationValid={isDurationValid}
        startDateTime={startDateTime}
        setStartDateTime={setStartDateTime}
        endDateTime={endDateTime}
        setEndDateTime={setEndDateTime}
      />
      <PayAndDetailCodes
        hasDutyDayPayCodes={hasDutyDayPayCodes}
        selectedPayCodeOptions={selectedPayCodeOptions}
        selectedDutyDayPayCodeOptions={selectedDutyDayPayCodeOptions}
        selectedDetailCodeOptions={selectedDetailCodeOptions}
        setHasDutyDayPayCodes={setHasDutyDayPayCodes}
        setSelectedPayCodeOptions={setSelectedPayCodeOptions}
        setSelectedDutyDayPayCodeOptions={setSelectedDutyDayPayCodeOptions}
        setSelectedDetailCodeOptions={setSelectedDetailCodeOptions}
      />
    </>
  );

  const filters = (
    <Filter
      value={selectedCertCodes}
      onChange={setSelectedCertCodes}
      filters={departmentInfoState.departmentInfo.certifications}
      getFilterColors={getCertColors}
    />
  );

  const searchResults = candidates.data.map((candidate) => {
    const maxConsecutiveHourErrors = candidateMaxConsecutiveHourErrors.get(candidate.id) || [];
    return (
      <CandidateCard
        key={candidate.id}
        candidate={candidate}
        isSelected={candidate.id === selectedCandidate?.id}
        onClick={onSelectCandidate}
        subdued={candidate.conflicts.blockers.length > 0 || maxConsecutiveHourErrors.length > 0}
      />
    );
  });

  return (
    <AssignmentForm
      searchValue={searchValue}
      setSearchValue={setSearchValue}
      title={position ? 'Staff to event' : 'Add employee to event'}
      subtitle={apparatus.name}
      aboveSearchContent={aboveSearchContent}
      belowSearchContent={filters}
      onCancel={props.onClose}
      onSubmit={onSubmit}
      isLoading={candidates.isLoading}
      hasNextPage={candidates.hasNextPage}
      loadMore={candidates.loadMore}
      disableSubmit={!canSubmit}
      searchResults={searchResults}
      noResults={candidates.data.length === 0}
    />
  );
};
