import { Box, BoxProps, useTheme } from '@mui/material';
import { Flag02Icon16 } from '../../assets';
import { getTeamColors } from '../../utils/colors';

interface TeamFlagProps extends Omit<BoxProps<'div'>, 'color'> {
  color?: string;
  dark?: boolean;
}

export const TeamFlag = ({ color, dark, sx, ...props }: TeamFlagProps) => {
  const theme = useTheme();

  return (
    <Box
      {...props}
      sx={[
        {
          display: 'inline-flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '24px',
          width: '24px',
          borderRadius: '50%',
          border: `1px solid ${dark ? theme.palette.gray[800] : theme.palette.gray[200]}`,
          color: getTeamColors({ theme, color, dark }).background,
          '& svg': { width: '12px', height: '12px' },
        },
        ...(Array.isArray(sx) ? sx : sx ? [sx] : []),
      ]}
    >
      <Flag02Icon16 />
    </Box>
  );
};
