import { Box } from '@mui/material';
import { CircleTickBIcon20, CircleTickCIcon20, CircleWarningIcon20 } from '../../assets';

export interface StatCounterProps {
  total: number;
  amount: number;
  mode?: 'dark' | 'light';
  overrideIcon?: boolean;
  overrideIconToShow?: 'inGoal' | 'aboveGoal' | 'belowGoal';
}

export const StatCounter = ({
  amount,
  total,
  mode = 'light',
  overrideIcon = false,
  overrideIconToShow = 'belowGoal',
}: StatCounterProps) => {
  return (
    <Box
      display="flex"
      sx={(theme) => ({
        color: mode === 'light' ? theme.palette.gray[900] : theme.palette.common.white,
      })}
    >
      <Box sx={{ typography: 'bodySRegular', fontWeight: 600, textWrap: 'nowrap' }}>
        {total ? `${amount} of ${total}` : amount}
      </Box>
      <Box
        sx={{
          height: '20px',
          width: '20px',
          ml: 1,
        }}
      >
        {(() => {
          if (total > 0 && overrideIcon && overrideIconToShow === 'belowGoal') {
            return <CircleWarningIcon20 />;
          } else if (total > 0 && overrideIcon && overrideIconToShow === 'inGoal') {
            return (
              <Box>
                {mode === 'dark' ? (
                  <CircleTickBIcon20 />
                ) : (
                  <Box
                    sx={(theme) => ({
                      '& path': {
                        fill: theme.palette.teal[500],
                      },
                    })}
                  >
                    <CircleTickCIcon20 />
                  </Box>
                )}
              </Box>
            );
          } else {
            return;
          }
        })()}
      </Box>
    </Box>
  );
};
