import { ReactComponent as ChevronLeftSymbol } from '@material-symbols/svg-400/outlined/chevron_left.svg';
import { Box, Typography, useTheme } from '@mui/material';
import { SvgIcon } from '@stationwise/component-module';
import { ConversationListView } from '@stationwise/share-types';
import { UpdateMessage } from './UpdateMessage';
import { getCategoryColor, getCategoryColoredIcon } from '.';

interface MessageTopBarProps {
  selectedConversation: ConversationListView;
  setSelectedConversation: (conversation: ConversationListView | null) => void;
  updateConversation: (conversationId: number, toggleRead: boolean, markAsCompleted: boolean) => Promise<void>;
  refetchConversations: () => void;
}
export const MessageTopBar = ({
  selectedConversation,
  setSelectedConversation,
  updateConversation,
  refetchConversations,
}: MessageTopBarProps) => {
  const theme = useTheme();

  const backToInbox = () => {
    setSelectedConversation(null);
    refetchConversations();
  };

  return (
    <Box display="flex" sx={(theme) => ({ pl: theme.spacing(2), pt: '20px', pb: '10px', display: 'flex', alignItems: 'center' })}>
      <Box data-cy="back-to-inbox-button" sx={{ width: '24px', height: '24px' }} onClick={backToInbox}>
        <SvgIcon component={ChevronLeftSymbol} />
      </Box>
      <Box sx={(theme) => ({ ml: theme.spacing(4) })}>
        <Typography variant="bodyLSemibold">Messages</Typography>
      </Box>

      <Box
        sx={(theme) => ({
          position: 'absolute',
          right: theme.spacing(8),
          color: theme.palette.gray[500],
          borderRadius: theme.spacing(1),
          border: `1px solid ${theme.palette.gray[200]}`,
          pr: theme.spacing(0.5),
          pl: theme.spacing(0.5),
          boxShadow: '0 0 4px rgba(0, 0, 0, 0.1)',
        })}
      >
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          {getCategoryColoredIcon(selectedConversation.category, theme)}
          <Typography
            variant="bodyMMedium"
            sx={(theme) => ({
              color: getCategoryColor(selectedConversation.category, theme),
              textTransform: 'uppercase',
              pl: theme.spacing(0.5),
            })}
          >
            {selectedConversation.category}
          </Typography>
        </Box>
      </Box>
      <UpdateMessage updateConversation={updateConversation} conversation={selectedConversation} />
    </Box>
  );
};
