import { Box } from '@mui/material';
import { parseISO, startOfDay } from 'date-fns';
import { useState } from 'react';
import { asDepartmentDateTime, useLoadedDepartmentInfoContext } from '@stationwise/component-module';
import { PromotionHistory } from '@stationwise/share-types';
import { EditCareerInfo } from './Edit/EditCareerInfo';
import { EditCareerRankHistory } from './Edit/EditCareerRankHistory';
import { InfoCard, InfoCardField } from './InfoCard';

type CareerSectionProps = {
  promotionHistory: PromotionHistory[];
  hireDate: string | null;
};

export const CareerSection = (props: CareerSectionProps) => {
  const [showHireDateModal, setShowHireDateModal] = useState(false);
  const [showRankHistoryModal, setShowRankHistoryModal] = useState(false);
  const { state: departmentInfoState } = useLoadedDepartmentInfoContext();
  const today = startOfDay(asDepartmentDateTime(departmentInfoState, new Date()));
  const fieldDefs: InfoCardField[][] = [
    [
      {
        label: 'Hire Date',
        field: props.hireDate
          ? {
              value: props.hireDate,
              type: 'date',
            }
          : {},
      },
    ],
    props.promotionHistory.length !== 0
      ? [
          {
            label: 'Last Promotion',
            field: {
              label: props.promotionHistory.find((promo) => parseISO(promo.promotionDate) <= today)?.rank.name,
              value: props.promotionHistory.find((promo) => parseISO(promo.promotionDate) <= today)?.promotionDate,
              type: 'date',
            },
          },
          {
            label: 'All Promotion Dates',
            field: props.promotionHistory.map((promotion) => {
              return {
                label: promotion.rank.name,
                value: promotion.promotionDate,
                type: 'date',
              };
            }),
          },
        ]
      : [],
  ];

  const handleEditModal = () => {
    return;
  };

  const handleEditHireDate = () => {
    setShowHireDateModal(true);
  };

  const handleEditRankHistory = () => {
    setShowRankHistoryModal(true);
  };

  const editMenuButtons = [
    { onClick: handleEditHireDate, buttonText: 'Edit hire date' },
    { onClick: handleEditRankHistory, buttonText: 'Edit rank history' },
  ];

  return (
    <Box
      width="100%"
      sx={(theme) => ({
        backgroundColor: theme.palette.common.white,
        borderRadius: 2,
        border: `1px solid ${theme.palette.gray[200]}`,
        p: theme.spacing(4, 3, 3, 3),
        gap: theme.spacing(3),
      })}
    >
      <EditCareerInfo
        showModal={showHireDateModal}
        setShowModal={setShowHireDateModal}
        hireDate={props.hireDate}
        promotionHistory={props.promotionHistory}
      />
      <EditCareerRankHistory
        showModal={showRankHistoryModal}
        setShowModal={setShowRankHistoryModal}
        promotionHistory={props.promotionHistory}
        hireDate={props.hireDate}
      />
      <InfoCard fieldDefs={fieldDefs} title="Career" handleEditModal={handleEditModal} editMenuButtons={editMenuButtons} />
      {props.promotionHistory.length === 0 && <Box sx={(theme) => ({ mt: theme.spacing(2) })}>No promotion history</Box>}
    </Box>
  );
};
