import { Box } from '@mui/material';
import { CAPABILITIES, ErrorPage, LottieLogo, useDocumentTitle } from '@stationwise/component-module';
import { AppPageLayout } from '../../../AppPageLayout';
import { useFetchCurrentShiftTemplate } from '../hooks/useFetchCurrentShiftTemplate';
import { ShiftTemplateContent } from './ShiftTemplateContent';

export const ShiftTemplateMain = () => {
  useDocumentTitle('Shift Template Editor');
  const { isLoading, setIsLoading, isError, data: shiftTemplate, forceRefetch } = useFetchCurrentShiftTemplate();

  const renderContent = () => {
    if (isLoading) {
      return (
        <Box display="flex" alignItems="center" justifyContent="center" sx={{ height: '100%', width: '100%' }}>
          <LottieLogo height="200px" width="200px" />
        </Box>
      );
    }

    if (isError || !shiftTemplate) {
      return <ErrorPage />;
    }

    return <ShiftTemplateContent shiftTemplateSummary={shiftTemplate} forceRefetch={forceRefetch} setIsLoading={setIsLoading} />;
  };

  return (
    <AppPageLayout className="SwShiftTemplate-main" requiredCapability={CAPABILITIES.EDIT_SHIFT_TEMPLATE}>
      <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%', width: '100%' }}>{renderContent()}</Box>
    </AppPageLayout>
  );
};
