import { Vacancy } from './roster';

export interface HiringEngineVacancy extends Vacancy {
  stationName: string;
  apparatusName: string;
}

export enum HiringEngineServices {
  HIRING_FLOW = 'HIRING_FLOW',
  INSTANT_HIRE = 'INSTANT_HIRE',
  AUTO_HIRE = 'AUTO_HIRE',
  REQUEST_VOLUNTEERS = 'REQUEST_VOLUNTEERS',
}

export const hiringEngineServicesInfo = {
  [HiringEngineServices.HIRING_FLOW]: {
    title: 'Hiring Flow',
    description: 'Call down the list of employees and allow them to choose their assignments',
  },
  [HiringEngineServices.AUTO_HIRE]: {
    title: 'Auto Hire',
    description: 'Employees will receive a notification to choose their assignment. Similar to bidding process',
  },
  [HiringEngineServices.INSTANT_HIRE]: {
    title: 'Instant Hire',
    description: "Instantly assign employees to positions. They'll get a notification to acknowledge. No sign-up needed",
  },
  [HiringEngineServices.REQUEST_VOLUNTEERS]: {
    title: 'Request Volunteers',
    description:
      "Employees will receive a notification to sign up for a position. Once signed up, they'll appear on the selected staffing list.",
  },
};
