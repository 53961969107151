import { Box, Typography, TypographyProps, SxProps } from '@mui/material';

export interface ChipProps {
  label: string;
  chipSxProps?: SxProps;
  variant?: TypographyProps['variant'];
  typographyStyles?: React.CSSProperties;
}

export const Chip = ({ label, variant, chipSxProps, typographyStyles }: ChipProps) => {
  return (
    <Box
      sx={{
        width: 'fit-content',
      }}
    >
      <Box
        sx={(theme) => ({
          display: 'flex',
          width: 'fit-content',
          justifyContent: 'center',
          alignItems: 'center',
          background: theme.palette.gray[500],
          position: 'relative',
          height: theme.spacing(2.5),
          pl: theme.spacing(0.5),
          pr: '3px',
          mr: '4px',
          borderRadius: '5px',
          ...(typeof chipSxProps === 'function' ? chipSxProps(theme) : { chipSxProps }),
        })}
      >
        <Typography
          component="p"
          variant={variant ? variant : 'bodyXSRegular'}
          lineHeight={variant ? '' : '16px'}
          sx={(theme) => ({
            color: theme.palette.common.white,
          })}
          style={typographyStyles}
        >
          {label}
        </Typography>
      </Box>
    </Box>
  );
};
