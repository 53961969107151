import { Box } from '@mui/material';
import { format } from 'date-fns';
import { RosterEmployee } from '@stationwise/share-types';
import { differenceInUTCMinutes } from '@stationwise/share-utils';
import { getTeamColors } from '../../utils/colors';

interface AssignmentDetailsProps {
  employee: RosterEmployee;
  payCodeAndDetailCodes?: React.ReactNode;
  hideDates?: boolean;
  teamName: string;
}

export const AssignmentDetails = ({ employee, payCodeAndDetailCodes, hideDates, teamName }: AssignmentDetailsProps) => {
  let durationMinutes = differenceInUTCMinutes(employee.endDateTime, employee.startDateTime) - (employee.breakDuration || 0);
  durationMinutes = durationMinutes <= 0 ? durationMinutes + 24 * 60 : durationMinutes;

  return (
    <Box
      sx={(theme) => ({
        backgroundColor: theme.palette.gray[800],
        borderRadius: 2,
        p: theme.spacing(1.5, 1),
        mb: 2,
      })}
    >
      <Box display="flex" alignItems="center" justifyContent="space-between" mb={2}>
        <Box display="flex" alignItems="center">
          <Box
            sx={(theme) => ({
              ...getTeamColors({ theme, color: employee.team?.color, dark: true }),
              width: '4px',
              height: '14px',
              mr: theme.spacing(1),
              borderRadius: '200px',
            })}
          ></Box>

          <Box sx={(theme) => ({ color: theme.palette.common.white, typography: 'bodyMMedium' })}>{teamName}</Box>
        </Box>
        <Box sx={(theme) => ({ typography: 'bodyXS', lineHeight: '16px', color: theme.palette.gray[400] })}>
          {`${(durationMinutes / 60).toFixed(durationMinutes % 60 ? 1 : 0)}h shift`}
        </Box>
      </Box>

      <Box
        sx={(theme) => ({
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          color: theme.palette.common.white,
          typography: 'bodySRegular',
          mb: 2,
        })}
      >
        <Box sx={(theme) => ({ color: theme.palette.gray[400] })}>Start</Box>
        <Box display="flex" gap={3}>
          {!hideDates && <Box>{format(employee.startDateTime, 'MMM d, yyyy')}</Box>}
          <Box>{format(employee.startDateTime, 'HHmm')}</Box>
        </Box>
      </Box>
      <Box
        sx={(theme) => ({
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          color: theme.palette.common.white,
          typography: 'bodySRegular',
          mb: 2,
        })}
      >
        <Box sx={(theme) => ({ color: theme.palette.gray[400] })}>End</Box>
        <Box display="flex" gap={3}>
          {!hideDates && <Box>{format(employee.endDateTime, 'MMM d, yyyy')}</Box>}
          <Box>{format(employee.endDateTime, 'HHmm')}</Box>
        </Box>
      </Box>
      {payCodeAndDetailCodes}
    </Box>
  );
};
