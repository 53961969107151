import { Box } from '@mui/material';
import { useState } from 'react';
import { CustomField } from '@stationwise/share-types';
import { EditExtraInfo } from './Edit/EditExtraInfo';
import { InfoCard, InfoCardField } from './InfoCard';

type ExtraInfoSectionProps = {
  customFields: CustomField[];
};

export const ExtraInfoSection = (props: ExtraInfoSectionProps) => {
  const [showModal, setShowModal] = useState(false);
  const fieldDefs: InfoCardField[][] = props.customFields.map((customField) => {
    return [
      {
        label: customField.name,
        field: {
          value:
            customField.type == 'BOOLEAN'
              ? customField.value
                ? 'Yes'
                : 'No'
              : customField.value || customField.value === 0
                ? customField.value.toString()
                : 'N/A',
          type: customField.type == 'DATE' && customField.value ? 'date' : 'text',
        },
      },
    ];
  });

  const handleEditModal = () => {
    setShowModal(true);
  };

  return (
    <Box
      width="100%"
      sx={(theme) => ({
        backgroundColor: theme.palette.common.white,
        borderRadius: 2,
        border: `1px solid ${theme.palette.gray[200]}`,
        p: theme.spacing(4, 3, 3, 3),
        gap: theme.spacing(3),
      })}
    >
      <EditExtraInfo showModal={showModal} setShowModal={setShowModal} customFields={props.customFields} />
      <InfoCard fieldDefs={fieldDefs} title="Miscellaneous" handleEditModal={handleEditModal} />
    </Box>
  );
};
