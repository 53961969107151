import { Box, Divider, MenuItem, Modal, TextField } from '@mui/material';
import { format, parseISO } from 'date-fns';
import { useState } from 'react';
import { Button, DatePicker, Trash04Icon24, useLoadedDepartmentInfoContext } from '@stationwise/component-module';
import { PromotionHistory } from '@stationwise/share-types';
import { usePatchRequest } from './PatchRequestProvider';

interface EditCareerRankHistoryProps {
  showModal: boolean;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  promotionHistory: PromotionHistory[];
  hireDate: string | null;
}

export const EditCareerRankHistory = ({ showModal, setShowModal, promotionHistory, hireDate }: EditCareerRankHistoryProps) => {
  const { state: department } = useLoadedDepartmentInfoContext();
  const { handlePatchRequest } = usePatchRequest();
  const ranks = department.departmentInfo?.ranks.map((rank) => rank.name);
  const initialPromotions = promotionHistory.map((promotion) => ({
    rank: promotion.rank.name,
    date: parseISO(promotion.promotionDate),
  }));
  const [promotions, setPromotions] = useState(initialPromotions);

  const handleAddPromotion = () => {
    setPromotions([...promotions, { rank: '', date: new Date() }]);
  };

  const handleRemovePromotion = (index: number) => {
    setPromotions(promotions.filter((_, i) => i !== index));
  };

  const handleChange = (index: number, key: 'rank' | 'date', value: string | Date | null) => {
    setPromotions((prev) => prev.map((promotion, i) => (i === index ? { ...promotion, [key]: value } : promotion)));
  };

  const handleSave = () => {
    const promotionData = promotions.map((promotion) => ({
      rank: promotion.rank,
      date: format(promotion.date, 'yyyy-MM-dd'),
    }));
    const data = {
      promotionHistory: promotionData,
    };
    handlePatchRequest(data);
    setShowModal(false);
  };

  const handleClose = () => {
    setShowModal(false);
    setPromotions(
      promotionHistory.map((promotion) => ({
        rank: promotion.rank.name,
        date: parseISO(promotion.promotionDate),
      })),
    );
  };

  const isDisabled = promotions.length === 0;

  return (
    <Modal open={showModal}>
      <Box
        sx={{
          display: 'flex',
          width: '100%',
          height: '90vh',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Box
          sx={(theme) => ({
            backgroundColor: theme.palette.common.white,
            justifyContent: 'center',
            borderRadius: theme.spacing(1.5),
            p: theme.spacing(3),
            display: 'flex',
            flexDirection: 'column',
            minWidth: '350px',
          })}
        >
          <Box
            sx={{
              typography: 'bodyXLSemibold',
              textAlign: 'left',
            }}
          >
            Career
          </Box>

          <Box
            sx={(theme) => ({
              typography: 'bodySMedium',
              textAlign: 'left',
              py: theme.spacing(1),
              color: theme.palette.gray[500],
            })}
          >
            Manage promotions
          </Box>

          <Box
            sx={(theme) => ({
              width: '100%',
              mt: theme.spacing(2),
            })}
          >
            {promotions.map((promotion, index) => (
              <Box
                key={index}
                sx={(theme) => ({ display: 'flex', gap: theme.spacing(2), alignItems: 'center', marginBottom: theme.spacing(2) })}
              >
                <TextField
                  select
                  label="Rank"
                  value={promotion.rank}
                  onChange={(e) => handleChange(index, 'rank', e.target.value)}
                  fullWidth
                >
                  {ranks.map((rank) => (
                    <MenuItem key={rank} value={rank}>
                      {rank}
                    </MenuItem>
                  ))}
                </TextField>
                <DatePicker
                  sx={{ width: '100%' }}
                  label="Acquired date"
                  value={promotion.date}
                  onChange={(date) => handleChange(index, 'date', date)}
                  minDate={hireDate ? parseISO(hireDate) : undefined}
                />
                <Box sx={(theme) => ({ display: 'flex', color: theme.palette.gray[500] })}>
                  <Trash04Icon24 onClick={() => handleRemovePromotion(index)} />
                </Box>
              </Box>
            ))}
            <Divider />
            <Button onClick={handleAddPromotion} sx={(theme) => ({ marginTop: theme.spacing(1) })}>
              + Add Promotion
            </Button>
          </Box>

          <Box
            sx={(theme) => ({
              display: 'flex',
              justifyContent: 'flex-end',
              mt: theme.spacing(5),
              gap: theme.spacing(1),
              width: '100%',
            })}
          >
            <Button variant="outlined" size="large" onClick={() => handleClose()}>
              Cancel
            </Button>
            <Button disabled={isDisabled} variant="contained" size="large" onClick={() => handleSave()}>
              Save
            </Button>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};
