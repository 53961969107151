import { Box } from '@mui/material';
import { CheckIcon16 } from '../../../assets';

interface ValidationRulesCheckProps {
  check: boolean;
  text: string;
}

export const ValidationRulesCheck = ({ check, text }: ValidationRulesCheckProps) => {
  return (
    <Box
      sx={(theme) => ({
        typography: 'bodySRegular',
        color: theme.palette.gray[700],
        display: 'flex',
        alignItems: 'center',
        marginTop: theme.spacing(1),
      })}
    >
      {check ? (
        <Box
          sx={(theme) => ({
            color: theme.palette.common.white,
            background: theme.palette.green[500],
            width: theme.spacing(2.5),
            height: theme.spacing(2.5),
            borderRadius: theme.spacing(2.5),
            display: 'flex',
            alignItems: 'center',
            marginRight: theme.spacing(1),
          })}
        >
          <CheckIcon16
            style={{
              marginLeft: '2px',
            }}
          />
        </Box>
      ) : (
        '• '
      )}
      {text}
    </Box>
  );
};
