import { Box } from '@mui/material';
import { ExclamationMarkIcon24, Button } from '@stationwise/component-module';

export const WarningModal = ({
  setModalOpen,
  onConfirm,
  children,
  hideButtons,
  confirmButtonText,
}: {
  setModalOpen: (open: boolean) => void;
  onConfirm: () => void;
  children: React.ReactNode;
  hideButtons?: boolean;
  confirmButtonText?: string;
}) => {
  return (
    <Box sx={{ textAlign: 'center' }}>
      <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
        <Box
          sx={(theme) => ({
            '& svg path': { stroke: theme.palette.orange[500] },
            borderRadius: '80px',
            borderColor: theme.palette.gray[50],
            boxShadow: '0px 2px 4px -1px rgba(10, 14, 22, 0.06), 0px 4px 6px -1px rgba(10, 14, 22, 0.10)',
            width: '56px',
            height: '56px',
            p: theme.spacing(2),
            mb: theme.spacing(3),
          })}
        >
          <ExclamationMarkIcon24 />
        </Box>
      </Box>
      {children}
      {!hideButtons && (
        <Box
          sx={(theme) => ({
            display: 'flex',
            justifyContent: 'space-between',
            mt: theme.spacing(5),
            gap: theme.spacing(1.5),
            width: '100%',
          })}
        >
          <Button variant="outlined" size="large" sx={{ width: '100%' }} onClick={() => setModalOpen(false)}>
            Cancel
          </Button>
          <Button variant="contained" size="large" sx={{ width: '100%' }} onClick={onConfirm}>
            {confirmButtonText || 'Confirm'}
          </Button>
        </Box>
      )}
    </Box>
  );
};
