import { useMemo } from 'react';
import {
  EmployeeOffView,
  RosterPosition,
  RosterEmployee,
  RosterEmployeeOffPayloads,
  TemporaryNonShiftType,
} from '@stationwise/share-types';
import {
  IShiftSummaryHelper,
  checkIsShift,
  formatShiftTime,
  getDepartmentPayCodes,
  getEmployeeSplits,
} from '@stationwise/shift-summary-helper';

interface UseEmployeesOffInput {
  shiftSummaryHelper: IShiftSummaryHelper;
  employeeOffPayloads: RosterEmployeeOffPayloads;
  employeesOff: EmployeeOffView[];
}

export const useEmployeesOff = (input: UseEmployeesOffInput) => {
  const employeesOff = useMemo(() => {
    const positions = new Map<string, RosterPosition>();
    input.shiftSummaryHelper.allStationCards.forEach((station) => {
      if (checkIsShift(station)) {
        station.apparatuses.forEach((apparatus) => {
          apparatus.positions.forEach((p) => positions.set(p.id, p));
        });
      }
    });

    const employeeSplits = getEmployeeSplits({ ...input.employeeOffPayloads, shiftSummaryHelper: input.shiftSummaryHelper });

    const employeesOff = input.employeesOff.filter((employeeOff) => {
      if (employeeOff.requestType === 'ShiftTradeRequest') {
        return !input.employeeOffPayloads.cancelShiftTradePayloads.some((p) => p.shiftTradeId === employeeOff.id);
      } else if (employeeOff.requestType === 'TimeOffRequest') {
        return !input.employeeOffPayloads.cancelTimeOffPayloads.some((p) => p.timeOffId === employeeOff.id);
      } else if (Object.values(TemporaryNonShiftType).some((t) => t === employeeOff.requestType)) {
        return !input.employeeOffPayloads.cancelTemporaryNonShiftAssignmentPayloads.includes(employeeOff.id);
      } else {
        return true;
      }
    });

    employeesOff.forEach((employeeOff, index) => {
      const off = (employeeSplits.get(`${employeeOff.employeeOff.id}`) || []).find((split) => {
        if (employeeOff.requestType === 'ShiftTradeRequest') {
          return split.reference.type === 'SHIFT_TRADE_REQUEST' && split.reference.id === employeeOff.id;
        } else if (employeeOff.requestType === 'TimeOffRequest') {
          return split.reference.type === 'TIME_OFF_REQUEST' && split.reference.id === employeeOff.id;
        } else if (Object.values(TemporaryNonShiftType).some((t) => t === employeeOff.requestType)) {
          return split.reference.type === 'TEMPORARY_NON_SHIFT' && split.reference.id === employeeOff.id;
        } else if (employeeOff.requestType === 'Holiday') {
          return split.reference.type === 'HOLIDAY' && split.reference.id === employeeOff.id;
        } else {
          return false;
        }
      });
      if (off?.reference.type === 'TIME_OFF_REQUEST') {
        employeesOff[index] = { ...employeeOff };
        employeesOff[index].startTime = formatShiftTime(off.startDateTime);
        employeesOff[index].endTime = formatShiftTime(off.endDateTime);
        const [payCode] = getDepartmentPayCodes(input.shiftSummaryHelper.departmentInfo, [{ id: off.reference.payCodeId }]);
        const position = positions.get(`${off.reference.positionId}`);
        if (payCode && payCode.id > 0) {
          employeesOff[index].title = payCode.name.toUpperCase();
          employeesOff[index].timeOffType = payCode.name;
        }
        if (position) {
          employeesOff[index].replacementEmployees = position.employees
            .filter((e) => e.startDateTime < off.endDateTime && e.endDateTime > off.startDateTime)
            .map(makeReplacementEmployee);
        }
      }
    });

    return employeesOff;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [input.employeesOff, ...Object.values(input.employeeOffPayloads), input.shiftSummaryHelper]);

  return { employeesOff };
};

const makeReplacementEmployee = (employee: RosterEmployee) => {
  const [firstName, ...lastNames] = employee.name.split(' ');
  return {
    id: Number(employee.id),
    rank: employee.rank,
    firstName,
    lastName: lastNames.join(' '),
    startTime: formatShiftTime(employee.startDateTime),
    endTime: formatShiftTime(employee.endDateTime),
    certifications: employee.certifications,
    defaults: employee.defaults,
    team: employee.team,
  };
};
