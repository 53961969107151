import { Box, TextField } from '@mui/material';

interface DutyFormTextFieldProps {
  label: string;
  text: string;
  setText: (value: string) => void;
}

export const DutyFormTextField = ({ label, text, setText }: DutyFormTextFieldProps) => {
  return (
    <Box
      sx={(theme) => ({
        display: 'block',
        width: '100%',
        marginTop: theme.spacing(3),
      })}
    >
      <Box
        sx={(theme) => ({
          marginBottom: theme.spacing(2),
          px: theme.spacing(2),
          typography: 'bodyLMedium',
        })}
      >
        {label}
      </Box>
      <TextField
        value={text}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          setText(event.target.value);
        }}
        sx={(theme) => ({
          width: '100%',
          px: theme.spacing(2),
        })}
        multiline
        rows={3}
      />
    </Box>
  );
};
