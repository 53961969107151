import { ReactComponent as CancelSymbol } from '@material-symbols/svg-400/outlined/cancel-fill.svg';
import { ReactComponent as FilterAltFillSymbol } from '@material-symbols/svg-400/outlined/filter_alt-fill.svg';
import DeleteIcon from '@mui/icons-material/Delete';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import {
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Badge,
  IconButton,
  Typography,
  Tooltip,
  Chip,
  SelectChangeEvent,
} from '@mui/material';
import { captureException } from '@sentry/react';
import { format } from 'date-fns';
import React, { ReactNode, useEffect, useMemo, useRef, useState } from 'react';
import { client } from '@stationwise/share-api';
import { DetailFieldsStaffingList, ListFieldsStaffingList, StaffingListItem } from '@stationwise/share-types';
import { Button } from '../../../Button';
import { useStaffingListFilterGroup } from '../../../Roster';
import { SvgIcon } from '../../../SvgIcon';
import { HiringEngineStaffinListFilterPopover } from './HiringEngineStaffingListFilterPopover';
import { LoadingRow } from './StaffingListLoadingRow';

const MAX_DISPLAYED_FILTER_OPTIONS = 3;

interface StaffingListRowProps {
  selectedDate: Date;
  index: number;
  selectedId: number;
  staffingLists: ListFieldsStaffingList[];
  selectedStaffingListIds: number[];
  setSelectedStaffingListIds: React.Dispatch<React.SetStateAction<number[]>>;
  items: StaffingListItem[];
  setStaffingListDetails: React.Dispatch<React.SetStateAction<DetailFieldsStaffingList[]>>;
  filteredItems: StaffingListItem[];
  setFilteredStaffingListDetails: React.Dispatch<React.SetStateAction<DetailFieldsStaffingList[]>>;
  isOptionChanging: boolean;
  setIsOptionChanging: React.Dispatch<React.SetStateAction<boolean>>;
  isDisabled?: boolean;
}

export const StaffingListRow = ({
  selectedDate,
  index,
  selectedId,
  staffingLists,
  selectedStaffingListIds,
  setSelectedStaffingListIds,
  items,
  setStaffingListDetails,
  filteredItems,
  setFilteredStaffingListDetails,
  isOptionChanging,
  setIsOptionChanging,
  isDisabled = false,
}: StaffingListRowProps) => {
  const [filterPopoverAnchorEl, setFilterPopoverAnchorEl] = useState<HTMLElement | null>(null);
  const [selectedTeamOptions, setSelectedTeamOptions] = useState(new Set<string>());
  const [selectedBattalionOptions, setSelectedBattalionOptions] = useState(new Set<string>());
  const [selectedStationOptions, setSelectedStationOptions] = useState(new Set<string>());
  const teamFilterState = useStaffingListFilterGroup(selectedTeamOptions, setSelectedTeamOptions);
  const battalionFilterState = useStaffingListFilterGroup(selectedBattalionOptions, setSelectedBattalionOptions);
  const stationFilterState = useStaffingListFilterGroup(selectedStationOptions, setSelectedStationOptions);

  const filterStates = useMemo(
    () => [teamFilterState, battalionFilterState, stationFilterState],
    [teamFilterState, battalionFilterState, stationFilterState],
  );

  const filterCount = useMemo(
    () => filterStates.map((state) => state.appliedOptions.size).reduce((acc, curr) => acc + curr, 0),
    [filterStates],
  );

  useEffect(() => {
    const fetchStaffingListDetails = async () => {
      if (isOptionChanging) {
        try {
          const response = await client.get(
            `/staffing-list/staffing-lists/${selectedId}/?date=${format(selectedDate, 'MM/dd/yyyy')}`,
          );
          setStaffingListDetails((prevDetails) => {
            const newDetails = [...prevDetails];
            newDetails[index] = response.data;
            return newDetails;
          });
          setFilteredStaffingListDetails((prevDetails) => {
            const newDetails = [...prevDetails];
            newDetails[index] = response.data;
            return newDetails;
          });
        } catch (error) {
          captureException(error);
        } finally {
          setIsOptionChanging(false);
        }
      }
    };
    fetchStaffingListDetails();
  }, [
    isOptionChanging,
    selectedId,
    selectedDate,
    index,
    setStaffingListDetails,
    setFilteredStaffingListDetails,
    setIsOptionChanging,
  ]);

  const handleSelectChange = (e: SelectChangeEvent<number>) => {
    const newSelectedIds = [...selectedStaffingListIds];
    newSelectedIds[index] = e.target.value as number;
    setSelectedStaffingListIds(newSelectedIds);
    setIsOptionChanging(true);
  };

  const handleRemoveStaffingList = (id: number) => {
    setSelectedStaffingListIds((prev) => prev.filter((item) => item !== id));
    setStaffingListDetails((prev) => prev.filter((detail) => detail.id !== id));
    setFilteredStaffingListDetails((prev) => prev.filter((detail) => detail.id !== id));
  };

  const renderFilterChips = useMemo(() => {
    return (
      <Box sx={{ display: 'flex' }}>
        {(() => {
          const chips: ReactNode[] = [];
          filterStates.forEach((state, stateIndex) => {
            state.appliedOptions.forEach((option) => {
              if (chips.length < MAX_DISPLAYED_FILTER_OPTIONS) {
                chips.push(
                  <Chip
                    key={`${stateIndex}:${option}`}
                    label={option}
                    sx={{ '& .MuiChip-label': { maxWidth: 68 } }}
                    deleteIcon={<SvgIcon component={CancelSymbol} />}
                    onDelete={() => {
                      const newAppliedOptions = new Set(state.appliedOptions);
                      newAppliedOptions.delete(option);
                      state.setAppliedOptions(newAppliedOptions);
                    }}
                  />,
                );
              }
            });
          });
          if (chips.length < filterCount) {
            chips.push(<Chip key="more" label={`+${filterCount - chips.length}`} />);
          }
          return <Box sx={{ flex: 1, display: 'flex', gap: 0.25 }}>{chips}</Box>;
        })()}
        <Button
          sx={{ p: 0.5, minHeight: '24px', whiteSpace: 'nowrap', marginLeft: 1 }}
          onClick={() => filterStates.forEach((state) => state.setAppliedOptions(new Set()))}
        >
          Clear all
        </Button>
      </Box>
    );
  }, [filterStates, filterCount]);

  const selectOptions = useMemo(() => {
    return staffingLists.map((item) => (
      <MenuItem key={item.id} value={item.id} disabled={selectedStaffingListIds.includes(item.id) && selectedId !== item.id}>
        {item.name}
      </MenuItem>
    ));
  }, [staffingLists, selectedStaffingListIds, selectedId]);

  const filteredStaffingListItems = useMemo(() => {
    return filteredItems.filter((item) => {
      if (selectedTeamOptions.size && !selectedTeamOptions.has(item.employee.team?.name || '')) return false;
      if (selectedBattalionOptions.size && !selectedBattalionOptions.has(item.employee.battalion?.name || '')) return false;
      if (selectedStationOptions.size && !selectedStationOptions.has(item.employee.station?.name || '')) return false;
      return true;
    });
  }, [filteredItems, selectedTeamOptions, selectedBattalionOptions, selectedStationOptions]);

  const prevFilteredItemsRef = useRef<StaffingListItem[]>([]);

  useEffect(() => {
    if (JSON.stringify(prevFilteredItemsRef.current) !== JSON.stringify(filteredStaffingListItems)) {
      setFilteredStaffingListDetails((prevDetails) => {
        const newDetails = [...prevDetails];
        if (newDetails[index]) {
          newDetails[index] = {
            ...newDetails[index],
            items: filteredStaffingListItems,
          };
        }
        return newDetails;
      });

      prevFilteredItemsRef.current = filteredStaffingListItems;
    }
  }, [filteredStaffingListItems, index, setFilteredStaffingListDetails]);

  return (
    <>
      {isOptionChanging ? (
        <LoadingRow selectedId={selectedId} index={index} />
      ) : (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
            gap: 2,
            paddingY: 1,
          }}
          key={selectedId}
        >
          <Typography
            variant="bodyXXLMedium"
            sx={{
              color: '#B4B4B4',
            }}
          >
            #{index + 1}
          </Typography>
          <FormControl fullWidth sx={{ flex: 1 }}>
            <InputLabel>Staffing list</InputLabel>
            <Select
              label="Staffing List"
              variant="outlined"
              notched
              displayEmpty
              value={selectedId}
              onChange={handleSelectChange}
              disabled={isDisabled}
            >
              {selectOptions}
            </Select>
          </FormControl>
          <Tooltip title={items.length === 0 ? 'No employees in this staffing list' : ''} arrow>
            <Badge color="primary">
              <IconButton
                data-cy="open-staffing-list-filters-button"
                aria-label="Filters"
                onClick={(event) => setFilterPopoverAnchorEl(event.currentTarget)}
                disabled={items.length === 0 || isDisabled}
              >
                <SvgIcon component={FilterAltFillSymbol} />
              </IconButton>
            </Badge>
          </Tooltip>
          <Box sx={{ flex: 1 }}>{!!filterCount && renderFilterChips}</Box>
          <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <IconButton
              disabled={selectedStaffingListIds.length === 1 || isDisabled}
              aria-label="Delete"
              onClick={() => handleRemoveStaffingList(selectedId)}
            >
              <SvgIcon component={DeleteIcon} />
            </IconButton>
            <IconButton aria-label="Drag" onClick={() => {}} disabled={isDisabled}>
              <SvgIcon component={DragIndicatorIcon} />
            </IconButton>
          </Box>
          <HiringEngineStaffinListFilterPopover
            items={items || []}
            filterPopoverAnchorEl={filterPopoverAnchorEl}
            setFilterPopoverAnchorEl={setFilterPopoverAnchorEl}
            filterStates={filterStates}
          />
        </Box>
      )}
    </>
  );
};
