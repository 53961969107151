import { EventInput } from '@fullcalendar/core';
import { EventImpl } from '@fullcalendar/core/internal';
import { Box, Popover, Theme, useMediaQuery } from '@mui/material';
import { GenericDrawerOrModal, XCloseIcon24 } from '@stationwise/component-module';
import { GetIncidentOverview } from '@stationwise/share-types';
import { IncidentOverviewContent } from './IncidentOverviewContent';

interface IncidentOverviewProps {
  drawerOpen: boolean;
  handleOnClose: () => void;
  shift: EventInput | EventImpl;
  handleSelect: (option: string) => void;
  setSelectedIncident: (incident: GetIncidentOverview) => void;
  setIsCloseIncident: (isCloseIncident: boolean) => void;
  anchorEl: null | HTMLElement;
}

export const IncidentOverview = ({
  anchorEl,
  drawerOpen,
  handleOnClose,
  shift,
  handleSelect,
  setSelectedIncident,
  setIsCloseIncident,
}: IncidentOverviewProps) => {
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

  if (isMobile) {
    return (
      <GenericDrawerOrModal
        anchor="bottom"
        drawerOpen={drawerOpen}
        handleOnClose={handleOnClose}
        loading={false}
        showHeader={true}
        headerTitle="Incident"
        disableFooter={true}
        noBorderOnHeader={true}
      >
        <IncidentOverviewContent
          handleSelect={handleSelect}
          shift={shift}
          setSelectedIncident={setSelectedIncident}
          setIsCloseIncident={setIsCloseIncident}
        />
      </GenericDrawerOrModal>
    );
  } else {
    return (
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleOnClose}
        anchorReference="anchorPosition"
        anchorPosition={{ top: 200, left: window.innerWidth - 430 }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        sx={(theme) => ({
          '& .MuiPaper-root': {
            background: theme.palette.common.white,
            borderRadius: '16px',
            boxShadow: '0px 4px 16px rgba(0, 0, 0, 0.1)',
            width: '368px',
          },
        })}
      >
        <Box
          sx={(theme) => ({
            height: '48px',
            pr: theme.spacing(2),
            color: theme.palette.gray[400],
            cursor: 'pointer',
            display: 'flex',
            justifyContent: 'right',
            alignItems: 'center',
          })}
          onClick={handleOnClose}
        >
          <XCloseIcon24 />
        </Box>
        <IncidentOverviewContent
          handleSelect={handleSelect}
          shift={shift}
          setSelectedIncident={setSelectedIncident}
          setIsCloseIncident={setIsCloseIncident}
        />
      </Popover>
    );
  }
};
