import { Box } from '@mui/material';
import { addHours, parse } from 'date-fns';
import { useEffect, useState } from 'react';
import { useIOSPaddingBottom } from '../../../../../context/DeviceInfoContext';
import { Button, TimePicker } from '../../../../../libs/component-module/src/lib/components';
import { PayCode } from '../../../../../libs/share-types/src/views';
import { AdditionalPaidTimeDropDown } from '../AdditionalPaidTime/AdditionalPaidTimeDropDown';
import { AdditionalPaidTimeDuration } from '../AdditionalPaidTime/AdditionalPaidTimeDuration';
import { AddressAutocomplete } from '../Incident/AddressAutoComplete';
import { DutyFormTextField } from './DutyFormTextField';
import { DutyFormTimeSelect } from './DutyFormTimeSelect';

interface TimeRange {
  start: Date;
  end: Date;
}
interface DutyFormContentProps {
  handleDutySave: (additionalPaidTime: TimeRange, note: string, eventLocation: string) => void;
  selectedDate: string;
  handleSelectPayCodeOpen: () => void;
  payCode: PayCode | null;
  handleClose: () => void;
}
export const DutyFormContent = ({
  handleDutySave,
  handleClose,
  selectedDate,
  handleSelectPayCodeOpen,
  payCode,
}: DutyFormContentProps) => {
  const [note, setNote] = useState<string>('');
  const [eventLocation, setEventLocation] = useState<string>('');
  const [openTimeSelect, setOpenTimeSelect] = useState<boolean>(false);
  const [time, setTime] = useState<Date>(new Date('2022-04-17T00:15'));
  const [manualTime, setManualTime] = useState<Date>(parse(selectedDate, 'MM/dd/yy', new Date()));
  const [endTime, setEndTime] = useState<Date>(parse(selectedDate, 'MM/dd/yy', new Date()));
  const minTime = new Date(parse(selectedDate, 'MM/dd/yy', new Date()).setHours(0, 0, 0, 0));
  const maxTime = addHours(minTime, 24);

  const [additionalPaidTime, setAdditionalPaidTime] = useState<TimeRange>({
    start: new Date(selectedDate),
    end: new Date(selectedDate),
  });

  useEffect(() => {
    prepareShiftTime();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [manualTime, endTime]);

  const handleManualTime = (value: Date) => {
    setManualTime(value);
  };

  const handleEndTime = (value: Date) => {
    setEndTime(value);
  };

  const handleTime = (value: Date) => {
    setOpenTimeSelect(false);
    setTime(value);
    prepareShiftTime();
  };

  const openTimeSelectDrawer = () => {
    setOpenTimeSelect(!openTimeSelect);
  };

  const prepareShiftTime = () => {
    setAdditionalPaidTime({
      start: manualTime,
      end: endTime,
    });
  };

  const handleSave = () => {
    handleDutySave(additionalPaidTime, note, eventLocation);
    handleClose();
  };

  const iosWebViewPaddingBottom = useIOSPaddingBottom();

  return (
    <Box>
      <DutyFormTimeSelect
        manualTime={manualTime}
        handleTime={handleManualTime}
        label="Start time"
        minTime={minTime}
        maxTime={maxTime}
      />
      <DutyFormTimeSelect
        manualTime={endTime}
        handleTime={handleEndTime}
        label="End time"
        minTime={manualTime}
        maxTime={maxTime}
      />
      <AdditionalPaidTimeDuration
        additionalPaidTime={additionalPaidTime}
        header={'Duration'}
        openTimeSelectDrawer={openTimeSelectDrawer}
        time={time}
        disabled
      />

      <AdditionalPaidTimeDropDown
        header="Duty Type"
        openEventTypeDrawer={handleSelectPayCodeOpen}
        option={payCode}
        placeholder="Select Duty type"
        code={payCode?.code}
      />

      <DutyFormTextField label="Event description" text={note} setText={setNote} />
      <Box
        sx={(theme) => ({
          mt: theme.spacing(3),
          px: theme.spacing(2),
          typography: 'bodyLMedium',
        })}
      >
        Event Location
        <Box sx={(theme) => ({ my: theme.spacing(2) })}>
          <AddressAutocomplete defaultValue={eventLocation as string} onChange={(value) => setEventLocation(value)} />
        </Box>
      </Box>
      <TimePicker
        openTimeSelect={openTimeSelect}
        setOpenTimeSelect={openTimeSelectDrawer}
        handleTime={handleTime}
        paddingBottom={iosWebViewPaddingBottom}
      />

      <Box
        sx={(theme) => ({
          justifyContent: 'space-between',
          display: 'flex',
          mt: theme.spacing(5),
          width: '100%',
          gap: theme.spacing(2),
          px: theme.spacing(2),
        })}
      >
        <Button variant="outlined" size="large" sx={{ width: '100%' }} onClick={() => handleClose()}>
          Cancel
        </Button>
        <Button
          data-cy="save-duty-form-button"
          variant="contained"
          size="large"
          sx={{ width: '100%' }}
          onClick={() => handleSave()}
          disabled={note === '' || eventLocation === '' || payCode === null || manualTime >= endTime}
        >
          Save
        </Button>
      </Box>
    </Box>
  );
};
