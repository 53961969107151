import { ReactComponent as CloseSymbol } from '@material-symbols/svg-400/outlined/close.svg';
import { Dialog, DialogContent, DialogTitle, IconButton, Box } from '@mui/material';
import { WhiteStationIcon20 } from '../../../../assets';
import { SvgIcon } from '../../../SvgIcon';
import { useRosterContext } from '../RosterContext';
import { RosterPosition as Position } from '../RosterPosition';

export const EmployeeActionsDialog = () => {
  const { selectedFilledPositionState } = useRosterContext();
  const { station, apparatus, position, isEmployeeActionsOpen, closeDialog } = selectedFilledPositionState;

  return (
    <Dialog
      open={isEmployeeActionsOpen}
      onClose={closeDialog}
      PaperProps={{
        sx: {
          position: 'fixed',
          bottom: 0,
          borderTopLeftRadius: '16px',
          borderTopRightRadius: '16px',
          borderBottomLeftRadius: '0px',
          borderBottomRightRadius: '0px',
          width: '100%',
          maxWidth: 'none',
          margin: '0 auto',
          boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)',
        },
      }}
      TransitionProps={{
        style: {
          transition: 'all 0.3s ease-out',
        },
      }}
    >
      <DialogTitle
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          padding: '16px 24px',
        }}
      >
        <Box sx={{ typography: 'bodyLSemibold' }}>Employee Actions</Box>

        <IconButton
          aria-label="close"
          onClick={closeDialog}
          sx={(theme) => ({
            position: 'absolute',
            right: theme.spacing(1),
            top: theme.spacing(1),
            color: '#000',
          })}
        >
          <SvgIcon
            component={CloseSymbol}
            sx={(theme) => ({
              width: theme.spacing(3.5),
              height: theme.spacing(3.5),
              color: '#707A85',
              borderRadius: '20px',
              border: `1px  ${theme.palette.gray[600]}`,
              background: '#DEE3ED',
              padding: theme.spacing(0.5),
            })}
          />
        </IconButton>
      </DialogTitle>

      <DialogContent
        sx={{
          padding: '88px 16px 0 16px',
          bgcolor: 'background.paper',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          borderRadius: '12px',
          gap: '8px',
          border: '1px',
        }}
      >
        {station && apparatus && (
          <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
            <Box
              sx={{
                marginRight: '12px',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <WhiteStationIcon20 style={{ fontSize: '1em' }} />
            </Box>
            <Box sx={{ color: '#A3ADB5', typography: 'bodyMRegular' }}>{`${station.stationName} | ${apparatus.name}`}</Box>
          </Box>
        )}
        {apparatus && position && (
          <Box mb={1.5}>
            <Position apparatus={apparatus} position={position} isCollapsed={false} />
          </Box>
        )}
      </DialogContent>
    </Dialog>
  );
};
